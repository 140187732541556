import { SET_CURRENT_TAB } from '../actions/tab';
import { COMPONENT_DETAILS } from '../../components/utils/get-tab-options';

const initialState = COMPONENT_DETAILS;

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return action.currentTab;
    }
    default:
      return state;
  }
}
