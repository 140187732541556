import { DraftLogoInfo, LogoInfo } from '@cbinsights/logoservice/logoservice';
import { useQuery } from 'react-query';
import { getCompanyLogos } from '../apis/GetCompanyLogos';
import { getEntityDraftLogos } from '../apis/GetEntityDraftLogos';
import { getInvestorLogos } from '../apis/GetInvestorLogos';
import { EntityServicesKeys } from './UseSearchForEntity';

export const useGetEntityLogos = ({
  enabled,
  idEntityDraft,
  idCompany,
  idInvestor,
}: {
  enabled: boolean;
  idEntityDraft?: string;
  idCbiEntity?: number;
  idCompany?: number;
  idInvestor?: number;
}) => {
  return useQuery<
    DraftLogoInfo | LogoInfo,
    Error,
    DraftLogoInfo | LogoInfo,
    [EntityServicesKeys, number, number, string]
  >(
    ['GetEntityLogo', idCompany, idInvestor, idEntityDraft],
    async () => {
      let logoData;
      if (idEntityDraft) {
        const response = await getEntityDraftLogos({
          getMeta: false,
          height: 100,
          idEntityDrafts: [idEntityDraft],
        });
        logoData = response.logoInfo[0].logoInfo;
      }

      if (idCompany && !logoData) {
        const companyLogo = await getCompanyLogos({
          getMeta: false,
          height: 100,
          idCompanies: [idCompany],
          onlyGetDefault: true,
        });
        if (companyLogo.logoInfo[0].logos[0].noLogoFound && idInvestor) {
          const investorLogo = await getInvestorLogos({
            getMeta: false,
            height: 100,
            idInvestors: [idInvestor],
            onlyGetDefault: true,
          });
          logoData = investorLogo.logoInfo[0].logos[0];
        }
        logoData = logoData || companyLogo.logoInfo[0].logos[0];
      }

      if (idInvestor && !logoData) {
        const investorLogo = await getInvestorLogos({
          getMeta: false,
          height: 100,
          idInvestors: [idInvestor],
          onlyGetDefault: true,
        });
        logoData = investorLogo.logoInfo[0].logos[0];
      }

      return logoData;
    },
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
};
