// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anticon svg {
    vertical-align: baseline !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
} 
`, "",{"version":3,"sources":["webpack://./client/app/containers/global-antd.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;AACvC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[":global .anticon svg {\n    vertical-align: baseline !important;\n}\n\n:global .ant-table-tbody > tr.ant-table-row:hover > td {\n    background: none;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
