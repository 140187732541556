import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { object as yObject } from 'yup';
import {
  ALL_LANGUAGE_OPTION,
  CLASSIFIER_OPTIONS,
  SOURCE_TYPE_OPTIONS,
} from 'client/modules/news/queue/utils/constants';
import { FormikContainerOld } from 'client/modules/common/form/containers/FormikContainerOld';
import { getNewsClusters } from 'client/modules/news/queue/apis/GetNewsClusters';
import { clearNewsClusters } from 'client/modules/news/queue/apis/ClearNewsClusters';
import { getFilterOptions } from 'client/modules/news/queue/apis/GetFilterOptions';
import { useUserEvents } from 'client/utils/user-event/hooks/useUserEvent';
import { useNewsQueueSelector } from '../hooks/useNewsQueueSelector';
import { NewsQueue } from './NewsQueue';
import {
  archiveCluster,
  blockCikCluster,
  discardCluster,
} from '../apis/finalizeCluster';
import {
  addOrUpdateClusterEntity,
  removeArticle,
  removeClusterEntity,
} from '../utils/stateManagement';
import { removeArticleFromCluster } from '../apis/removeArticleFromCluster';
import { ClusterInfo } from '../redux/model/GetClusters';
import { LanguageOptions } from '../redux/model/FilterOptionsForNews';

type NewsQueueConProps = {
  activeFilters?: Record<string, unknown>;
};

interface ClusterDataOptions {
  clusters?: ClusterInfo[];
  totalCount?: number;
  isLoading?: boolean;
}

const FilterSchema = yObject().shape({});

export const defaultFilters = {
  sourceType: SOURCE_TYPE_OPTIONS[0],
  classifier: CLASSIFIER_OPTIONS[1],
  languages: [ALL_LANGUAGE_OPTION],
};

export const NewsQueueContainer = ({
  activeFilters = defaultFilters,
}: NewsQueueConProps): ReactElement => {
  const [clusterData, setClusterData] = useState<ClusterDataOptions>({
    isLoading: true,
  });
  const [languageOptions, setLanguageOptions] = useState<LanguageOptions>();
  const { user } = useNewsQueueSelector();
  const { recordUserEvent } = useUserEvents();

  const options = {
    sourceOptions: SOURCE_TYPE_OPTIONS,
    classifierOptions: CLASSIFIER_OPTIONS,
  };

  const memoOptions = useMemo(
    () => ({ ...options, ...languageOptions }),
    [options, languageOptions]
  );

  const handleRemoveArticle = ({ id }, clusterType, clusterId) => {
    removeArticleFromCluster({ id, clusterType }).then((newCluster) => {
      const updatedClusters = removeArticle(
        clusterData.clusters,
        clusterId,
        id
      );

      setClusterData({
        ...clusterData,
        clusters: [].concat(updatedClusters, newCluster),
        totalCount: clusterData.totalCount + 1,
      });
    });
  };

  const updateOrAddEntityToClusterState = (cluster, entity) => {
    setClusterData((state) => ({
      ...state,
      clusters: addOrUpdateClusterEntity(state.clusters, cluster, entity),
    }));
  };

  const handleRemoveEntityFromClusterState = (cluster, entity) => {
    setClusterData((state) => ({
      ...state,
      clusters: removeClusterEntity(state.clusters, cluster, entity),
    }));
  };

  const fetchClusters = (filters) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !clusterData.isLoading && setClusterData({ isLoading: true });
    getNewsClusters(filters)
      .then(setClusterData)
      .catch(() => setClusterData({ isLoading: false }));
  };

  const onDiscard = (cluster, filters) =>
    discardCluster(cluster).then(() => fetchClusters(filters));

  const onArchive = (cluster, filters) =>
    archiveCluster(cluster).then(() => fetchClusters(filters));

  const onBlockCik = (cluster, filters) =>
    blockCikCluster(cluster).then(() => fetchClusters(filters));

  useEffect(() => {
    fetchClusters(activeFilters);
    getFilterOptions().then(setLanguageOptions);
  }, []);

  const onFilterSubmit = (filters) => {
    setClusterData({ isLoading: true });

    recordUserEvent({
      type: 'admin.nq.fetchNews',
      details: filters,
    });

    return getNewsClusters(filters)
      .then(setClusterData)
      .catch(() => {
        setClusterData({ isLoading: false });
      });
  };

  const onClearQueue = () => {
    recordUserEvent({ type: 'admin.nq.clearQueue' });
    setClusterData({ isLoading: true });

    return clearNewsClusters().then((success) => {
      if (success) {
        setClusterData({ clusters: [], totalCount: 0 });
      }
      setClusterData({ isLoading: false });
    });
  };

  return (
    <div className="container mx-auto mt-8 mb-4">
      <div className="text-center text-3xl text-black font-medium py-2">
        News Queue
      </div>
      <div className="mt-4">
        <FormikContainerOld
          enableReinitialize
          Form={NewsQueue}
          schema={FilterSchema}
          onSubmit={onFilterSubmit}
          handleClear={onClearQueue}
          initialValues={{
            ...activeFilters,
            languages: languageOptions?.languageOptions ?? [],
          }}
          user={user}
          options={memoOptions}
          clusterData={clusterData}
          // TODO: we should be using formik state for all data
          customContext={{
            handleRemoveArticle,
            updateOrAddEntityToClusterState,
            handleRemoveEntityFromClusterState,
            onDiscard,
            onArchive,
            onBlockCik,
          }}
        />
      </div>
    </div>
  );
};

export default NewsQueueContainer;
