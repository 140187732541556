import { requestAllAdminUsers } from '../utils/api-request-helper';

export const FETCH_ALL_ADMIN_USERS =
  'client/modules/admin-user-admin/actions/FETCH_ALL_ADMIN_USERS';

export const fetchAllAdminUsersHelper = (adminInfo, fetchInactiveUsers) => {
  return {
    type: FETCH_ALL_ADMIN_USERS,
    adminInfo,
    fetchInactiveUsers,
  };
};

export const fetchAllAdminUsers = (fetchParameters = { fetchInactiveUsers: false }) => {
  return (dispatch, getState) => {
    if (getState().adminUsers.userInfo.length) {
      return null;
    }
    return dispatch(requestAllAdminUsers()).then((data) => {
      return dispatch(
        fetchAllAdminUsersHelper(data.userInfo, fetchParameters.fetchInactiveUsers)
      );
    });
  };
};
