export const SOURCE_TYPE_OPTIONS = [
  { label: 'All', value: 0 },
  { label: 'Funding', value: 1 },
  { label: 'M&A', value: 2 },
  { label: 'SEC Funds', value: 3 },
  { label: 'SEC News', value: 4 },
];

export const CLASSIFIER_OPTIONS = [
  { label: 'View All', value: 0 },
  { label: 'Relevant (Classifier On)', value: 1 },
  { label: 'Irrelevant + Unclassified', value: 2 },
];

export const ALL_LANGUAGE_OPTION = { value: 0, label: 'All' };

export const ITEM_TYPE_MAPPING = {
  NEWS_TYPE_UNSPECIFIED: 'Unspecified',
  FUNDING: 'Funding',
  MA: 'M&A',
  SEC_FUND: 'SEC Funds',
  SEC_NEWS: 'SEC News',
};
