import { map, forEach } from 'lodash';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import {
  updateDashboardSessionData,
  resetAllSessionData,
} from './all-session-data';
import { updateUserSuspiciousSessionsData } from './user-session-data';
import {
  searchForPaidUsers,
  searchForTeams,
  getAllCsms,
} from '../utils/api-request-helper';
import { ACCOUNT_SHARING_DASHBOARD_TAB_NAME } from '../../utils/get-tab-options';

export const INITIALIZE_SEARCH_INFO =
  'client/modules/account-sharing-detection/search-bar/INITIALIZE_SEARCH_INFO';
export const UPDATE_SEARCH_INFO =
  'client/modules/account-sharing-detection/search-bar/UPDATE_SEARCH_INFO';
export const UPDATE_SEARCH_TYPE =
  'client/modules/account-sharing-detection/search-bar/UPDATE_SEARCH_TYPE';
export const UPDATE_SUGGESTIONS =
  'client/modules/account-sharing-detection/search-bar/UPDATE_SUGGESTIONS';
export const UPDATE_SEARCH_OPTIONS =
  'client/modules/account-sharing-detection/search-bar/UPDATE_SEARCH_OPTIONS';
export const SET_SELECTED_SEARCH_INFO =
  'client/modules/account-sharing-detection/search-bar/SET_SELECTED_SEARCH_INFO';
export const SET_START_DATE =
  'client/modules/account-sharing-detection/search-bar/SET_START_DATE';
export const SET_END_DATE =
  'client/modules/account-sharing-detection/search-bar/SET_END_DATE';
export const SET_ALL_CSMS =
  'client/modules/account-sharing-detection/search-bar/SET_ALL_CSMS';

export function updateSuggestionsHelper(suggestions, searchTerm) {
  return { type: UPDATE_SUGGESTIONS, suggestions, searchTerm };
}

export function setAllCsms(allCsms) {
  return { type: SET_ALL_CSMS, allCsms };
}

export function setStartDate(startDate) {
  return { type: SET_START_DATE, startDate };
}

export function setEndDate(endDate) {
  return { type: SET_END_DATE, endDate };
}

export function initializeSearchInfo(
  searchValue,
  searchTerm,
  searchType,
  startDate,
  endDate
) {
  return {
    type: INITIALIZE_SEARCH_INFO,
    searchValue,
    searchTerm,
    searchType,
    startDate,
    endDate,
  };
}

export function setSearchType(searchType) {
  return { type: UPDATE_SEARCH_TYPE, searchType };
}

export function updateSearchInfo(searchId, searchTerm) {
  return { type: UPDATE_SEARCH_INFO, searchId, searchTerm };
}

export function setSelectedSearchInfo(searchId, searchTerm, searchType) {
  return { type: SET_SELECTED_SEARCH_INFO, searchId, searchTerm, searchType };
}

export function updateSearchOptions(currentTab) {
  return { type: UPDATE_SEARCH_OPTIONS, currentTab };
}

export function getAndSetAllCsms() {
  return (dispatch, getState) => {
    const {allCsms} = getState().accountSharingDetection.searchBar;
    if (allCsms.length) {
      return Promise.resolve();
    }
    return dispatch(getAllCsms()).then((response) => {
      return dispatch(setAllCsms(response.csms));
    });
  };
}

export function initializeSearch(
  searchValue,
  searchTerm,
  searchType,
  startDate,
  endDate
) {
  return (dispatch, getState) => {
    const currentTab = getState().accountSharingDetection.tab;
    if (currentTab === ACCOUNT_SHARING_DASHBOARD_TAB_NAME) {
      return dispatch(
        updateDashboardSessionData(
          searchValue,
          searchTerm,
          searchType,
          startDate,
          endDate
        )
      );
    }
    return dispatch(
      updateUserSuspiciousSessionsData(
        searchValue,
        searchType,
        startDate,
        endDate
      )
    );
  };
}

export function updateStartDate(startDate) {
  return (dispatch, getState) => {
    dispatch(setStartDate(startDate));
    const currentTab = getState().accountSharingDetection.tab;
    if (currentTab === ACCOUNT_SHARING_DASHBOARD_TAB_NAME) {
      return dispatch(updateDashboardSessionData());
    }
    return dispatch(updateUserSuspiciousSessionsData());
  };
}

export function updateEndDate(endDate) {
  return (dispatch, getState) => {
    dispatch(setEndDate(endDate));
    const currentTab = getState().accountSharingDetection.tab;
    if (currentTab === ACCOUNT_SHARING_DASHBOARD_TAB_NAME) {
      return dispatch(updateDashboardSessionData());
    }
    return dispatch(updateUserSuspiciousSessionsData());
  };
}

export function updateSearchType(searchType) {
  return (dispatch) => {
    dispatch(resetAllSessionData());
    dispatch(setSearchType(searchType));
    updateUrlParams('searchType', searchType);
  };
}

export function onSelectOrEnterSearch(searchId, searchTerm, searchType) {
  return (dispatch, getState) => {
    dispatch(setSelectedSearchInfo(searchId, searchTerm, searchType));
    const currentTab = getState().accountSharingDetection.tab;
    if (currentTab === ACCOUNT_SHARING_DASHBOARD_TAB_NAME) {
      return dispatch(
        updateDashboardSessionData(searchId, searchTerm, searchType)
      );
    }
    return dispatch(updateUserSuspiciousSessionsData(searchId, searchType));
  };
}

export function filterByCsm(searchTerm) {
  return (dispatch, getState) => {
    const {allCsms} = getState().accountSharingDetection.searchBar;
    const filteredCsmSuggestions = [];
    let counter = 1;
    const loweredSearchTerm = searchTerm.toLowerCase();
    forEach(allCsms, (csm) => {
      if (csm.toLowerCase().indexOf(loweredSearchTerm) > -1) {
        filteredCsmSuggestions.push({ name: csm, id: counter });
        counter += 1;
      }
    });
    return dispatch(
      updateSuggestionsHelper(filteredCsmSuggestions, searchTerm)
    );
  };
}

export function formatUserSuggestions(allUserInfo) {
  return map(allUserInfo, (userInfo) => {
    return { ...userInfo, name: userInfo.email };
  });
}

export function formatTeamSuggestions(allTeamInfo) {
  return map(allTeamInfo, (teamInfo) => {
    return { ...teamInfo, id: teamInfo.idTeam };
  });
}

export function updateSuggestions(searchTerm, currentSearchType) {
  return (dispatch) => {
    if (!searchTerm) {
      return null;
    }

    if (currentSearchType === 1) {
      return dispatch(searchForPaidUsers(searchTerm)).then((response) => {
        const formattedUsers = formatUserSuggestions(response.userInfo);
        return dispatch(updateSuggestionsHelper(formattedUsers, searchTerm));
      });
    } if (currentSearchType === 2) {
      return dispatch(searchForTeams(searchTerm)).then((response) => {
        const formattedTeams = formatTeamSuggestions(response.teams);
        return dispatch(updateSuggestionsHelper(formattedTeams, searchTerm));
      });
    }
    return dispatch(getAndSetAllCsms()).then(() => {
      return dispatch(filterByCsm(searchTerm));
    });
  };
}
