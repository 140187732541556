// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-Messages-FailureNotification-___failure-notification-style__failureNotification___yd5bw {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["red"]} !important;
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/Messages/FailureNotification/failure-notification-style.css"],"names":[],"mappings":"AAGA;EACE,yEAAgC;AAClC","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value red from colors;\n\n.failureNotification {\n  background-color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"red": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["red"]}`,
	"failureNotification": `client-modules-common-components-Messages-FailureNotification-___failure-notification-style__failureNotification___yd5bw`
};
export default ___CSS_LOADER_EXPORT___;
