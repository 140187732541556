import React from 'react';
import { Modal, message } from 'antd';
import { buildEnvURL } from 'client/utils/env';
import { useImpersonateUser } from '../hooks/useImpersonateUser';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
};

const ImpersonateUser = ({ isOpen, handleClose, email }: Props) => {
  const { onSubmit } = useImpersonateUser();

  const handleOk = () => {
    onSubmit(email)
      .then(() => {
        window.location.href = buildEnvURL('');
      })
      .catch((e) => {
        handleClose();
        if (e.status === 403) {
          message.error('You do not have permission to switch accounts');
        } else if (e.status === 400) {
          message.error('You cannot switch to a cbi user.');
        } else if (e.status === 500) {
          message.error('User Not Found');
        } else if (e.status === 401) {
          message.error('Unauthorized');
        }
      });
  };

  return (
    <Modal
      title="Login as User"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      width={750}
    >
      <div>
        <p>{`Are you sure you want to login as ${email} ?`}</p>
      </div>
    </Modal>
  );
};

export default ImpersonateUser;
