import { FormContainerWithErrorBoundary } from 'client/modules/common/form/containers/FormContainerWithErrorBoundary';
import { AntPageLayoutWithHeader } from 'client/modules/permissions/components/AntPageLayoutWithHeader';
import React from 'react';
import { MarketReportsForm } from '../components/MarketReportsForm';
import {
  initialNewMarketStatus,
  initialValuesNew,
} from '../hooks/useGetMarketDetailsValues';
import { useSubmitMarketDetails } from '../hooks/useSubmitMarketDetails';

export const NewMarketReport = () => {
  const { handleSubmit, handleReject } = useSubmitMarketDetails();

  return (
    <AntPageLayoutWithHeader title="Market reports">
      <FormContainerWithErrorBoundary
        Form={MarketReportsForm}
        enableReinitialize
        initialStatus={initialNewMarketStatus}
        optionalFormProps={{ isDataLoading: false }}
        onSubmit={handleSubmit}
        onReject={handleReject}
        initialValues={initialValuesNew}
        errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
      />
    </AntPageLayoutWithHeader>
  );
};
