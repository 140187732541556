export const UPDATE_ACCESS_BY_STORY = 'updateAccessByStory';
export const UPDATE_ACCESS_BY_TEAM = 'updateAccessByTeam';
export const MANAGE_SLUGS = 'manageSlugs';

export const tabOptions = [
  {
    tabName: UPDATE_ACCESS_BY_STORY,
    label: 'Update Access By Story',
    route: '/stories/update-access-by-story',
  },
  {
    tabName: UPDATE_ACCESS_BY_TEAM,
    label: 'Update Access By Team',
    route: '/stories/update-access-by-team',
  },
  {
    tabName: MANAGE_SLUGS,
    label: 'Manage Slugs',
    route: '/stories/manage-slugs',
  },
];
