import {
  CBIEntityFormModel,
  SectionFieldName,
} from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import {
  GetAdminFinancialDataResponse,
  QuartrCompany,
} from '@cbinsights/publicfinancialservice/publicfinancialservice';
import {
  useGetAdminFinancialData,
  useListExchanges,
  useListQuartrCompanies,
} from 'client/modules/cbi-entity/services/financials/hooks/usePublicFinancialService';

import { DefaultOptionType } from 'antd/lib/select';
import { GetEntityInfoResponse } from '@cbinsights/cbientityservice/cbientityservice';
import { useGetEntityInfo } from 'client/modules/cbi-entity/services/general-info/hooks/useGetEntityInfo';
import { useParams } from 'react-router-dom';

export const US_SHORT_CODE = 'US';

export const FinancialFormFields = {
  Company: 'company',
  ShortExchangeCode: 'shortExchangeCode',
  Ticker: 'ticker',
  Exchange: 'exchange',
  MarketCap: 'marketCap',
  PreviousClose: 'previousClose',
  QuartrCompany: 'quartrCompany',
} as const;

export type FinancialFormStatus = {
  [FinancialFormFields.Exchange]: string;
};
export type FinancialFormValues = {
  [FinancialFormFields.Company]: Array<{
    [FinancialFormFields.ShortExchangeCode]: DefaultOptionType;
    [FinancialFormFields.Ticker]: DefaultOptionType & { key: string };
    [FinancialFormFields.PreviousClose]: number;
    [FinancialFormFields.MarketCap]: number;
    [FinancialFormFields.QuartrCompany]: DefaultOptionType & { key: number };
  }>;
} & CBIEntityFormModel;

export const getExchangeTicker = ({
  shortExchange,
  exchange,
  ticker,
}: {
  shortExchange: string;
  exchange: string;
  ticker: string;
}) =>
  exchange && shortExchange === US_SHORT_CODE
    ? `${exchange}-${ticker}`
    : ticker;

export const mapInitialFinacialsFormValues = (
  financialData: GetAdminFinancialDataResponse,
  entityInfo: Partial<GetEntityInfoResponse>,
  quartrCompanies: QuartrCompany[]
): FinancialFormValues => {
  const tickerKey = getExchangeTicker({
    shortExchange: financialData?.exchangeShortName,
    exchange: financialData?.exchangeName,
    ticker: financialData?.symbol,
  });
  let quartrCompany: QuartrCompany | undefined;
  if (financialData?.idQuartrCompany) {
    quartrCompany = quartrCompanies.find(
      (el) => Number(el.idQuartrCompany) === financialData?.idQuartrCompany
    );
  }
  return {
    [FinancialFormFields.Company]:
      financialData?.symbol !== ''
        ? [
            {
              [FinancialFormFields.Ticker]: {
                value: tickerKey,
                key: tickerKey,
                label: undefined,
              },
              [FinancialFormFields.ShortExchangeCode]: {
                value: financialData?.exchangeShortName,
                label: undefined,
              },
              [FinancialFormFields.MarketCap]: financialData?.marketCap,
              [FinancialFormFields.PreviousClose]: financialData?.previousClose,
              [FinancialFormFields.QuartrCompany]:
                financialData?.idQuartrCompany !== 0
                  ? {
                      value: `${quartrCompany?.companyTicker} (${quartrCompany?.companyName}, ${quartrCompany?.companyUrl})`,
                      key: financialData?.idQuartrCompany,
                      label: undefined,
                      idQuartrCompany: financialData?.idQuartrCompany,
                    }
                  : {
                      value: undefined,
                      key: undefined,
                      label: undefined,
                      idQuartrCompany: undefined,
                    },
            },
          ]
        : [],
    [SectionFieldName.IdCbiEntity]: entityInfo?.entityInfo?.idCbiEntity,
    [SectionFieldName.IdEntityDraft]: undefined,
    [SectionFieldName.EntityName]:
      entityInfo?.entityInfo?.entityNameInfo?.primaryName,
    [SectionFieldName.Url]: entityInfo?.entityInfo?.urlInfo?.primaryUrl,
    [SectionFieldName.Meta]: {
      idCompany: entityInfo?.entityInfo.idCompany,
      idInvestor: entityInfo?.entityInfo.idInvestor,
      isCompany: !!entityInfo?.entityInfo.idCompany,
      isInvestor: !!entityInfo?.entityInfo.idInvestor,
      idType: undefined,
      isNew: false,
      draftStatus: undefined,
      draftVersion: undefined,
    },
  };
};

export const useFinancials = () => {
  const { isFetched: exchangedListIsFetched } = useListExchanges({
    enabled: true,
  });
  const { id } = useParams<{ id: string }>();
  const idCbiEntity = !Number.isNaN(+id) ? +id : undefined;

  const { data: entityInfo } = useGetEntityInfo(false, {
    idEntityDraft: undefined,
    idCbiEntity,
  });
  const { data: financialData, isFetched } = useGetAdminFinancialData({
    idCbiEntity,
  });

  const { data: quartrCompanies } = useListQuartrCompanies({
    enabled: true,
  });

  const initialValues = mapInitialFinacialsFormValues(
    financialData,
    entityInfo,
    quartrCompanies?.quartrCompanies || []
  );

  const initialStatusValues: FinancialFormStatus = {
    [FinancialFormFields.Exchange]: financialData?.exchangeName || undefined,
  };

  return {
    idCbiEntity,
    exchangedListIsFetched,
    initialValues,
    initialStatusValues,
    financialsIsFetched: isFetched,
  };
};
