import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { EditMarketReport } from './containers/EditMarketReport';
import { MarketReportsHome } from './containers/MarketReportsHome';
import { MarketList } from './containers/MarketList';
import { NewMarketReport } from './containers/NewMarketReport';
import { MarketReportsRoutes } from './RouterHelper';
import { MARKET_REPORTS_PERMISSIONS } from './permissions/MarketReports';

export const marketInfrastructureRoutes: RouteObject = {
  path: MarketReportsRoutes.MarketReports,
  element: (
    <PermissionsProtectedRoute
      pageId={MARKET_REPORTS_PERMISSIONS.pageId}
      currentPath={MarketReportsRoutes.MarketReports}
    />
  ),
  children: [
    {
      path: '',
      element: (
        <React.Suspense fallback={undefined}>
          <MarketReportsHome />
        </React.Suspense>
      ),
      children: [
        {
          path: '',
          element: (
            <React.Suspense fallback={undefined}>
              <MarketList />
            </React.Suspense>
          ),
        },
        {
          path: `${MarketReportsRoutes.New}/:${MarketReportsRoutes.MarketActiveTab}`,
          element: (
            <React.Suspense fallback={undefined}>
              <NewMarketReport />
            </React.Suspense>
          ),
        },
        {
          path: `:${MarketReportsRoutes.MarketId}/:${MarketReportsRoutes.MarketActiveTab}`,
          element: (
            <React.Suspense fallback={undefined}>
              <EditMarketReport />
            </React.Suspense>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ],
};
