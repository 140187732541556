import moment from 'moment';
import { ACCOUNT_SHARING_DASHBOARD_TAB_NAME } from '../../utils/get-tab-options';
import * as actions from '../actions/search-bar';

export const searchTypesToNames = {
  3: 'CSMs',
  2: 'Teams',
  1: 'Users',
};

export const initialSearchTypes = [
  { id: 3, text: 'CSMs' },
  { id: 2, text: 'Teams' },
  { id: 1, text: 'Users' },
];

export const getSearchTypes = (currentTab) => {
  if (currentTab === ACCOUNT_SHARING_DASHBOARD_TAB_NAME) {
    return initialSearchTypes;
  }
  return [{ id: 1, text: 'Users' }];
};

const initialState = {
  currentSearchType: 3,
  searchId: 0,
  searchTerm: '',
  searchTypes: initialSearchTypes,
  selectedSearchTerm: '',
  selectedSearchId: 0,
  selectedSearchType: 3,
  suggestions: [],
  startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  allCsms: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_SEARCH_INFO: {
      return {
        ...state,
        startDate: action.startDate || initialState.startDate,
        endDate: action.endDate || initialState.endDate,
        currentSearchType: parseInt(action.searchType, 10) || 3,
        searchId: parseInt(action.searchValue, 10) || 0,
        searchTerm: action.searchTerm || '',
        selectedSearchTerm: action.searchTerm || '',
        selectedSearchId: action.searchValue,
        selectedSearchType: parseInt(action.searchType, 10) || 3,
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchId: parseInt(action.searchId, 10) || 0,
      };
    }
    case actions.UPDATE_SUGGESTIONS: {
      return {
        ...state,
        suggestions: action.suggestions,
        searchTerm: action.searchTerm,
      };
    }
    case actions.SET_SELECTED_SEARCH_INFO: {
      return {
        ...state,
        selectedSearchTerm: action.searchTerm,
        selectedSearchId: parseInt(action.searchId, 10) || 0,
        selectedSearchType: action.searchType,
      };
    }
    case actions.UPDATE_SEARCH_TYPE: {
      return {
        ...initialState,
        currentSearchType: action.searchType,
        startDate: state.startDate,
        endDate: state.endDate,
        allCsms: state.allCsms,
      };
    }
    case actions.UPDATE_SEARCH_OPTIONS: {
      return {
        ...state,
        searchTypes: getSearchTypes(action.currentTab),
      };
    }
    case actions.SET_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case actions.SET_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
      };
    }
    case actions.SET_ALL_CSMS: {
      return {
        ...state,
        allCsms: action.allCsms,
      };
    }
    default:
      return state;
  }
}
