// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-component-library-Input-___input__componentContainer___A7MQo {
  width: 100%;
}

.client-component-library-Input-___input__optionsContainer___mXguW {
  background: white;
  box-shadow: 2px 3px 7px 0px #00000061;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: scroll;
  max-width: 500px;
}

.client-component-library-Input-___input__optionsContainer___mXguW > li:hover {
  color: white !important;
  background-color: var(--color-blue) !important;
  cursor: pointer;
}

.client-component-library-Input-___input__iconClassPossitionHack___C_jOA {
  margin-top: -4px;
  margin-bottom: 3px;
  overflow: inherit !important;
}

.client-component-library-Input-___input__white___AKkys {
  color: white !important;
}

.client-component-library-Input-___input__componentSearch___mVtBl {
  margin: 0px !important;
  margin-top: 3px !important;
  padding-left: 15px !important;
  width: 200px !important;
}

.client-component-library-Input-___input__modalClassName___mECRP {
  width: 100% !important;
  margin: 10px !important;
  max-width: none !important;
}
`, "",{"version":3,"sources":["webpack://./client/component-library/Input/input.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,8CAA8C;EAC9C,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,0BAA0B;EAC1B,6BAA6B;EAC7B,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,0BAA0B;AAC5B","sourcesContent":[".componentContainer {\n  width: 100%;\n}\n\n.optionsContainer {\n  background: white;\n  box-shadow: 2px 3px 7px 0px #00000061;\n  border-radius: 4px;\n  max-height: 300px;\n  overflow-y: scroll;\n  max-width: 500px;\n}\n\n.optionsContainer > li:hover {\n  color: white !important;\n  background-color: var(--color-blue) !important;\n  cursor: pointer;\n}\n\n.iconClassPossitionHack {\n  margin-top: -4px;\n  margin-bottom: 3px;\n  overflow: inherit !important;\n}\n\n.white {\n  color: white !important;\n}\n\n.componentSearch {\n  margin: 0px !important;\n  margin-top: 3px !important;\n  padding-left: 15px !important;\n  width: 200px !important;\n}\n\n.modalClassName {\n  width: 100% !important;\n  margin: 10px !important;\n  max-width: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentContainer": `client-component-library-Input-___input__componentContainer___A7MQo`,
	"optionsContainer": `client-component-library-Input-___input__optionsContainer___mXguW`,
	"iconClassPossitionHack": `client-component-library-Input-___input__iconClassPossitionHack___C_jOA`,
	"white": `client-component-library-Input-___input__white___AKkys`,
	"componentSearch": `client-component-library-Input-___input__componentSearch___mVtBl`,
	"modalClassName": `client-component-library-Input-___input__modalClassName___mECRP`
};
export default ___CSS_LOADER_EXPORT___;
