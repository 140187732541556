import { message } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { User } from '@cbinsights/adminservice/adminservice';
import { useGetPortals } from '../services/hooks/useGetPortals';

type Props = {
  userId: number;
};

export const useChangePortal = ({ userId }: Props) => {
  const { data } = useGetPortals({ enabled: true });

  const options =
    data?.portals.map<DefaultOptionType>((portal) => ({
      label: portal.name,
      value: portal.id_portal,
    })) || [];

  const handleChange = (
    newPortal: number,
    updateUser: (id: number, data: Partial<User>) => Promise<unknown>,
    refetchUser: () => void
  ) => {
    updateUser(userId, { id_portal: newPortal })
      .then(() => {
        message.success('User successfully updated');
        refetchUser();
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later');
      });
  };

  return {
    portals: data?.portals || [],
    options,
    handleChange,
  };
};

export default useChangePortal;
