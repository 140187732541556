import * as actions from '../actions/request-status';

export interface RequestStatusState {
  isSaving: boolean;
  isLoading: boolean;
}
const initialState: RequestStatusState = {
  isSaving: false,
  isLoading: false,
};

/** @deprecated */
export function requestStatusReducer(
  state = initialState,
  action
): RequestStatusState {
  switch (action.type) {
    case actions.SET_IS_SAVING_STATUS: {
      return { ...state, isSaving: action.isSaving };
    }
    case actions.SET_IS_LOADING_STATUS: {
      return { ...state, isLoading: action.isLoading };
    }
    default:
      return state;
  }
}
