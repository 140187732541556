import React from 'react';
import PropTypes from 'prop-types';
import filterListItems from './filterListItems';
import DROPDOWN_LIMIT from './utils/constants';
import Autocomplete from './Autocomplete';

const getNumberOfVisibleResults = (
  filteredItems,
  maxNumberOfVisibleResults,
  showFixedItem
) => {
  const numResults = Math.min(filteredItems.length, maxNumberOfVisibleResults);
  return showFixedItem ? numResults + 1 : numResults;
};

/**
 * @deprecated
 * It filters the items based on the value, then it adds a placeholder if needed, then it returns the
 * number of visible results
 * @param props - The props passed to the component.
 * @returns A function that returns an Autocomplete component.
 */
const AutocompleteDropdown = (props) => {
  const rest = props;
  const {
    items,
    value,
    filterFieldName,
    useCaseSensitiveSearch,
    maxNumberOfVisibleResults,
    showFixedItem,
    showFixedItemAtTheTop,
    showAllItems,
  } = rest;

  let filteredItems = showAllItems
    ? items
    : filterListItems(items, value, filterFieldName, useCaseSensitiveSearch);

  // Add a placeholder, then we will show the dropdown when there are no items
  if (showFixedItem && showFixedItemAtTheTop) {
    filteredItems = [
      { dummy_value: 'fixed_item_placeholder' },
      ...filteredItems,
    ];
  }
  const numberOfVisibleResults = getNumberOfVisibleResults(
    filteredItems,
    maxNumberOfVisibleResults,
    showFixedItem
  );

  return (
    <Autocomplete
      {...rest}
      items={filteredItems}
      numberOfVisibleResults={numberOfVisibleResults}
    />
  );
};

AutocompleteDropdown.propTypes = {
  autoFocus: PropTypes.bool,
  customDropdownInput: PropTypes.node,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownItemClassName: PropTypes.string,
  filterFieldName: PropTypes.string.isRequired,
  fixedItem: PropTypes.func,
  fixedItemClassName: PropTypes.string,
  handleFixedItemClick: PropTypes.func,
  helperText: PropTypes.string,
  initialSelectedIndex: PropTypes.number,
  inputClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxNumberOfVisibleResults: PropTypes.number,
  numOfCharsNeededToDisplayResults: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showAllItems: PropTypes.bool,
  showFixedItem: PropTypes.bool,
  showFixedItemAtTheTop: PropTypes.bool,
  useCaseSensitiveSearch: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  dropdownType: PropTypes.string,
  wrapperWidth: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

AutocompleteDropdown.defaultProps = {
  autoFocus: false,
  customDropdownInput: null,
  dataTest: '',
  disabled: false,
  dropdownItemClassName: '',
  fixedItem: () => {},
  fixedItemClassName: '',
  handleFixedItemClick: () => {},
  helperText: '',
  initialSelectedIndex: -1,
  inputClassName: '',
  maxNumberOfVisibleResults: DROPDOWN_LIMIT,
  numOfCharsNeededToDisplayResults: 0,
  onBlur: () => {},
  onEnter: () => {},
  placeholder: '',
  showAllItems: false,
  showFixedItem: false,
  showFixedItemAtTheTop: false,
  useCaseSensitiveSearch: false,
  width: 200,
  value: '',
};

export default AutocompleteDropdown;
