import React, { useEffect } from 'react';
import { Form, FormItemProps, Input, Select } from 'antd';

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (
    record: Item,
    setFieldValue: (name: string, value: unknown) => void
  ) => void;
  inputType: 'Input' | 'Select';
  selectOptions: Array<unknown> | ((record: Item) => Array<unknown>);
  rules?: FormItemProps['rules'];
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType = 'Input',
  selectOptions,
  rules,
  ...restProps
}) => {
  const form = Form.useFormInstance();

  const setFieldValue = (field, value) => {
    form.setFieldValue(field, value);
    form.validateFields();
  };

  useEffect(() => {
    if (!record) {
      return;
    }
    form.setFieldValue(dataIndex, record[dataIndex]);
  }, []);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values }, setFieldValue);
    } catch (errInfo) {
      const fieldValues = form.getFieldsValue();
      handleSave({ ...record, ...fieldValues }, setFieldValue);
    }
  };

  const getInputType = () => {
    if (inputType === 'Input') {
      return (
        <Input
          onPressEnter={save}
          onBlur={save}
          defaultValue={record[dataIndex]}
          value={record[dataIndex]}
        />
      );
    }
    if (inputType === 'Select') {
      const options =
        typeof selectOptions === 'function'
          ? selectOptions(record)
          : selectOptions;
      return (
        <Select
          showSearch
          optionFilterProp="label"
          options={options}
          onSelect={save}
          value={form.getFieldValue(dataIndex)}
        />
      );
    }
    return null;
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
          ...(rules || []),
        ]}
      >
        {getInputType()}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
