import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'client/component-library/buttons/Button';
import { MaterialUILink } from 'client/component-library/Link';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';
import { softDeletePipeline } from '../redux/actions/search';

class RemovePipelineLink extends Component {
  state = {
    confirmationOpen: false,
  };

  onClickComponent = () => {
    this.setState({ confirmationOpen: true });
  };

  closeConfirmation = () => {
    this.setState({ confirmationOpen: false });
  };

  removePipeline = () => {
    this.props.softDeletePipeline({
      pipelineName: this.props.componentId,
      sortField: this.props.sortField,
      sortDirection: this.props.sortDirection,
      recordsPerPage: this.props.recordsPerPage,
      pageNumber: this.props.pageNumber,
      filterByCallingUser: this.props.filterByCallingUser,
    });
    this.setState({ confirmationOpen: false });
  };

  render() {
    return (
      <div>
        <MaterialUILink onClick={this.onClickComponent}>remove</MaterialUILink>
        <Dialog
          open={this.state.confirmationOpen}
          onClose={this.closeConfirmation}
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Once you have deleted a pipeline it cannot be restored without
              assistance from an administrator.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.removePipeline} color="primary">
              Ok
            </Button>
            <Button onClick={this.closeConfirmation} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    softDeletePipeline: dataPlatform.search.softDeletePipeline,
    recordsPerPage: dataPlatform.details.recordsPerPage,
    pageNumber: dataPlatform.details.pageNumber,
    filterByCallingUser: dataPlatform.search.filterByCallingUser,
  };
}

RemovePipelineLink.propTypes = {
  componentId: PropTypes.string.isRequired,
  softDeletePipeline: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  recordsPerPage: PropTypes.number,
  filterByCallingUser: PropTypes.bool.isRequired,
};

RemovePipelineLink.defaultProps = {
  pageNumber: 0,
  sortField: 'pipeline_name',
  sortDirection: 'asc',
  recordsPerPage: 5,
  filterByCallingUser: false,
};

export default connect(mapStateToProps, { softDeletePipeline })(
  RemovePipelineLink
);
