import { keyBy } from 'lodash';
import { mapAdminPagesResponse } from '../utils/records-helper';
import { FETCH_ALL_ADMIN_PAGES } from '../actions/admin-pages';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_ADMIN_PAGES: {
      const pageInfo = mapAdminPagesResponse(action.pageInfo);
      return keyBy(pageInfo, 'id');
    }
    default:
      return state;
  }
}
