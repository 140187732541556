import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { MaterialUILink } from 'client/component-library/Link';
import Grid from 'client/component-library/Grid';
import styles from './styles/dropdownItem.css';

const conditionallApplySelectedStyle = (isSelected) => {
  return isSelected ? styles.selected : '';
};

const conditiallyApplyHighlight = (showHighlight) => {
  return showHighlight ? styles.highlightedDropdownItem : styles.dropdownItem;
};

const shouldHighlight = (highlightCompanies, highlightInvestors, item) => {
  if (highlightCompanies && !item.idInvestor) {
    return true;
  }
  if (highlightInvestors && !item.idCompany) {
    return true;
  }
  return false;
};

const createDropdownStyle = (height) => {
  return {
    height: height ? `${height}px` : 'inherit',
    margin: 0,
    width: '100%',
  };
};

const createEllipsisStyle = (isSelected) => {
  return isSelected ? styles.selectedEllipsis : styles.notSelectedEllipsis;
};

/**
 * @deprecated
 * It renders a dropdown item for the autocomplete search bar
 * @returns A React component
 */
const HighlightEntityDropdownItem = ({
  item,
  text,
  selected,
  onMouseOver,
  onMouseDown,
  height,
  className,
  highlightCompanies,
  highlightInvestors,
  dropdownItemId,
}) => {
  const dataTestName = item.id ? item.id : text;
  const companyUrl = item.url || item.profileUrl;
  const { alias } = item;
  const { altUrl } = item;
  const investorCategory = get(item, 'investorCategoryName');
  const showHighlight = shouldHighlight(
    highlightCompanies,
    highlightInvestors,
    item
  );
  return (
    <Grid
      container
      spacing={1}
      className={`${className} ${conditiallyApplyHighlight(
        showHighlight
      )} ${conditionallApplySelectedStyle(selected)}`}
      onMouseOver={onMouseOver}
      onMouseDown={onMouseDown}
      data-test={`admin-${dataTestName}`}
      style={createDropdownStyle(height)}
    >
      <Grid
        item
        xs={12}
        style={selected ? { padding: 0, maxWidth: '100%' } : { padding: 0 }}
      >
        <div style={showHighlight ? { color: 'white' } : {}}>
          <span id={dropdownItemId}>
            <b>{item.name}</b>
          </span>
          <span style={{ marginLeft: '5px' }}>
            <MaterialUILink
              href={`//${companyUrl}`}
              className={selected ? styles.selected : styles.notSelected}
              onMouseDown={onMouseDown}
            >
              {companyUrl}
            </MaterialUILink>
          </span>
          <div>
            {alias ? (
              <span>
                <b>alias:</b> {alias}
              </span>
            ) : null}
            {altUrl ? (
              <span>
                <b>alternate url:</b> {altUrl}{' '}
              </span>
            ) : null}
          </div>
          <span
            style={showHighlight ? { color: 'white' } : {}}
            className={createEllipsisStyle(selected)}
          >
            {investorCategory ? (
              <div className={selected ? 'text-white' : 'text-gray-700'}>
                <span>{investorCategory}</span>
              </div>
            ) : null}
            {item.description}
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

HighlightEntityDropdownItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  height: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  highlightCompanies: PropTypes.bool,
  highlightInvestors: PropTypes.bool,
  dropdownItemId: PropTypes.string,
};

HighlightEntityDropdownItem.defaultProps = {
  item: {},
  selected: false,
  onMouseOver: () => {},
  onMouseDown: () => {},
  height: 0,
  className: '',
  text: '',
  highlightCompanies: false,
  highlightInvestors: false,
  dropdownItemId: '',
};

export default HighlightEntityDropdownItem;
