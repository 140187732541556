export const updateMarketSuggestions = (
  searchObj,
  marketSuggestions,
  searchId
) => {
  return {
    ...searchObj,
    marketSuggestions,
    searchId: parseInt(searchId, 10) || 0,
  };
};

export const updateSearchInfo = (searchObj, searchTerm, searchId) => {
  return { ...searchObj, searchTerm, searchId: parseInt(searchId, 10) || 0 };
};
