import {
  GetEntityDraftLogosRequest,
  GetEntityDraftLogosResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const getEntityDraftLogos = (
  req: Partial<GetEntityDraftLogosRequest>
): Promise<GetEntityDraftLogosResponse> => {
  return request({
    url: '/service/logoservice/GetEntityDraftLogos',
    body: { ...req },
  }).then((r): GetEntityDraftLogosResponse => r.body);
};
