import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import ActionsArrowRightIcon from '@cbinsights/fds/lib/icons/react/ActionsArrowRightIcon';
import ActionsArrowDownIcon from '@cbinsights/fds/lib/icons/react/ActionsArrowDownIcon';
import ExternalLinkIcon from '@cbinsights/fds/lib/icons/react/ExternalLinkIcon';
import { useUserEvents } from 'client/utils/user-event/hooks/useUserEvent';
import { Card } from '../../../../component-library/Cards/Card';
import { ITEM_TYPE_MAPPING } from '../utils/constants';
import { Article } from './Article';
import { ClusterActionButtons } from './ClusterActionButtons';
import { SECActionButtons } from './SECActionButtons';

export const ClusterCard = ({
  articles = [],
  entities = [],
  date,
  type,
  id,
  onRemoveArticle,
  onArchive,
  onDiscard,
  onBlockCik,
  toggleSlideOver,
}) => {
  const [showAllArticles, setShowAllArticles] = useState(true);
  const { recordUserEvent } = useUserEvents();

  const _toggleSlideOver = () => toggleSlideOver(id);
  const _onArchive = () => onArchive({ id, type });
  const _onDiscard = () => onDiscard({ id, type });
  const _onBlockCik = () => onBlockCik({ id, type });

  const toggleShowArticles = () => {
    setShowAllArticles(!showAllArticles);
  };

  // opens the fundings tab for each entity
  function onAddFundingData() {
    recordUserEvent({
      type: 'admin.nq.cluster.addFundingClick',
      details: {
        idCluster: id,
      },
    });

    const _entities = entities.filter(
      ({ isSuggestedEntity }) => !isSuggestedEntity
    );

    for (let i = 0; i < _entities.length; i++) {
      if (!_entities[i].id) {
        return;
      }
      const currentWindow = window.open(
        `/cbi-entity/${_entities[i].id}/fundings?id_cluster=${id}`,
        '_blank'
      );
      if (!currentWindow) {
        window.alert(
          'Could not open all articles, please make sure you do not have a popup blocker enabled'
        );
        break;
      }
    }
  }

  const createEntity = () => {
    recordUserEvent({
      type: 'admin.nq.cluster.createEntityClick',
      details: {
        idCluster: id,
      },
    });
  };

  function openAllArticles() {
    for (let i = 0; i < articles.length; i++) {
      const currentWindow = window.open(articles[i].url, '_blank');
      if (!currentWindow) {
        window.alert(
          'Could not open all articles, please make sure you do not have a popup blocker enabled'
        );
        break;
      }
    }
  }

  const clusterEntities = entities.filter(
    ({ isSuggestedEntity }) => !isSuggestedEntity
  );
  const suggestedEntities = entities.filter(
    ({ isSuggestedEntity }) => isSuggestedEntity
  );
  const isSecType = type === 'SEC_FUND' || type === 'SEC_NEWS';

  return (
    <Card>
      <div className="flex flex-row w-full">
        <div
          className="w-1/12 px-1 text-sm flex justify-center"
          data-test="cluster-card-date"
        >
          {date}
        </div>
        <div
          className="w-1/12 px-1 text-sm flex justify-center"
          data-test="funding-type-cell"
        >
          {ITEM_TYPE_MAPPING[type] || 'Unspecified'}
        </div>
        <div
          className="w-1/12 px-1 text-sm flex justify-center"
          data-test="cluster-card-id"
        >
          {id}
        </div>
        <div className="w-7/12 px-6 flex justify-start">
          <div className="flex flex-col w-full">
            <div className="flex flex-row w-full">
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <div className="flex flex-col">
                    {clusterEntities.length ? (
                      clusterEntities.map(({ name }) => {
                        return (
                          <span className="font-bold" key={name}>
                            {name}
                          </span>
                        );
                      })
                    ) : (
                      <span className="text-red-400">No entity assigned</span>
                    )}
                  </div>
                  <span className="pl-4 text-xs">
                    (
                    {`${articles.length} ${
                      articles.length > 1 ? 'articles' : 'article'
                    }`}
                    )
                    <span className="pl-2">
                      <Button
                        onClick={toggleShowArticles}
                        theme="ghost"
                        data-test="toggle-show-rows"
                        inline
                      >
                        {showAllArticles ? (
                          <ActionsArrowDownIcon customSize="15" />
                        ) : (
                          <div className="flex flex-row">
                            <ActionsArrowRightIcon customSize="15" />
                          </div>
                        )}
                      </Button>
                    </span>
                  </span>
                </div>
                {suggestedEntities.length > 0 && (
                  <div className="text-sm font-bold text-gray-700">
                    <span>Suggested Entities:&nbsp;</span>
                    {suggestedEntities.map(({ name }, i) => {
                      return (
                        <span
                          key={name}
                          data-test={`${name.replace(
                            ' ',
                            '-'
                          )}-suggested-entity`}
                          className="font-bold"
                        >
                          {name}
                          {i !== suggestedEntities.length - 1 && ', '}
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div>
              {!clusterEntities.length && (
                <Link
                  className="pr-4 text-sm text-blue-500 underline"
                  to="/cbi-entity/0/general-info"
                  target="_blank"
                  onClick={createEntity}
                >
                  Create Entity
                </Link>
              )}
              {!isSecType && (
                <React.Fragment>
                  <button
                    data-test="manage-entities"
                    type="button"
                    className="pr-4 text-sm text-blue-500 underline"
                    onClick={_toggleSlideOver}
                  >
                    Manage entities
                  </button>
                  <button
                    className="text-sm text-blue-500 underline"
                    type="button"
                    onClick={openAllArticles}
                  >
                    Open all articles
                    <span className="pl-1">
                      <ExternalLinkIcon customSize="10" />
                    </span>
                  </button>
                </React.Fragment>
              )}
            </div>
            {showAllArticles && (
              <div className="flex flex-col text-xs rounded-md py-2 w-full">
                {articles.map((article, i) => (
                  <Article
                    index={i}
                    key={article.id}
                    article={article}
                    onRemoveArticle={onRemoveArticle}
                    canRemove={articles.length > 1}
                    clusterId={id}
                    clusterType={type}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {isSecType ? (
          <SECActionButtons onArchive={_onArchive} onBlock={_onBlockCik} />
        ) : (
          <ClusterActionButtons
            onAddFundingData={onAddFundingData}
            onArchive={_onArchive}
            onDiscard={_onDiscard}
            disabledAddFunding={!clusterEntities.length}
          />
        )}
      </div>
    </Card>
  );
};

ClusterCard.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  date: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.number,
  onRemoveArticle: PropTypes.func.isRequired,
  onBlockCik: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  toggleSlideOver: PropTypes.func.isRequired,
};
