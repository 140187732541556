import {
  AdminPage,
  AdminSection,
  GetAdminPermissionsForUserResponse,
} from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { useQuery } from 'react-query';
import { getAdminPermissionsForUser } from '../api/GetAdminPermissionsForUser';
import {
  PermissionQueries,
  PermissionQueryTypes,
} from './PermissionQueryTypes';

export const getAdminPage = (
  data: GetAdminPermissionsForUserResponse,
  pageId: number
): AdminPage => data.adminPages.filter((el) => el.idAdminPage === pageId)[0];

export const getSectionPage = (
  data: GetAdminPermissionsForUserResponse,
  pageId: number,
  sectionId: number
): AdminSection =>
  getAdminPage(data, pageId).adminSections.filter(
    (el) => el.idAdminSection === sectionId
  )[0];

export const useGetAdminPermissionsForUser = (enabled = false) => {
  return useQuery<
    GetAdminPermissionsForUserResponse,
    Error,
    GetAdminPermissionsForUserResponse,
    [PermissionQueryTypes]
  >(
    [PermissionQueries.GetAdminPermissionsForUser],
    () => getAdminPermissionsForUser(),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetched'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
};
