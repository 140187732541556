import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import styles from './buttonBaseStyle.css';

/**
 * @deprecated
 * It's a React component that renders a Material UI Button component with some custom styles and props
 * @returns A button component that takes in children, className, dataTest, variant, and rest as props.
 */
const Button = ({ children, className, dataTest, variant, ...rest }) => {
  return (
    <MuiButton
      {...rest}
      variant={variant === 'raised' ? 'contained' : variant}
      className={`${styles.buttonBase}
      ${className}`}
      data-test={dataTest}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  className: null,
  dataTest: 'button-base',
  variant: 'text',
};

export default Button;
