export const entityType = {
  UNKNOWN: 0,
  COMPANY: 1,
  INVESTOR: 2,
  ENTITY: 3,
};

export const suggestionTypes = {
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  INVESTORS: 'investors',
  BOARD_OF_DIRECTORS: 'boardOfDirector',
  BOD_FIRMS: 'bodInvestor',
  ENTITIES: 'entities',
};

export const DEFAULT_LOGO_URL =
  'https://s3-us-west-2.amazonaws.com/cbi-image-service-prd/original/no_logo.jpg';

export const EARLIEST_FOUNDED_YEAR = 1970;

const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear();
};

export const CURRENT_YEAR = getCurrentYear();

export const EDITED = true;
