export const storiesTableColumnNames = [
  {
    name: '',
    width: '24px',
    key: '',
    includes: [{ type: 'stories-team-expand-icon', key: 'expandIcon' }],
  },
  {
    name: '',
    width: '24px',
    key: 'id',
    includes: [{ type: 'checkbox', key: 'id' }],
  },
  {
    name: 'Story ID',
    width: '80px',
    key: 'idStory',
    includes: [{ type: 'stories-story-id', key: 'idStory' }],
  },
  {
    name: 'Team Name',
    width: '110px',
    key: 'teamName',
    includes: [{ type: 'stories-team-name', key: 'teamName' }],
  },
  {
    name: '# of Users',
    width: '62px',
    key: 'numUsers',
    includes: [{ type: 'stories-num-of-users', key: 'numUsers' }],
  },
  {
    name: 'CSM',
    width: '120px',
    key: 'CSM',
    includes: [{ type: 'stories-csm', key: 'CSM', defaultValue: 'N/A' }],
  },
  {
    name: 'Renewal Date',
    width: '120px',
    key: 'renewalDate',
    includes: [{ type: 'stories-renewal-date', key: 'renewalDate' }],
  },
  {
    name: 'Package Name',
    width: '120px',
    key: 'packageName',
    includes: [{ type: 'stories-package-name', key: 'packageName' }],
  },
  {
    name: 'Content Tier',
    width: '100px',
    key: 'contentTier',
    includes: [{ type: 'stories-content-tier', key: 'contentTier' }],
  },
  {
    name: 'B-D Account Sector',
    width: '150px',
    key: 'BDAccountSector',
    includes: [
      {
        type: 'stories-bd-acount-sector',
        key: 'BDAccountSector',
        defaultValue: 'N/A',
      },
    ],
  },
  {
    name: 'Customer Use Case',
    width: '150px',
    key: 'customerUseCase',
    includes: [
      {
        type: 'stories-customer-user-case',
        key: 'customerUseCase',
        defaultValue: 'N/A',
      },
    ],
  },
];

export const storiesInnerTableColumnNames = [
  {
    name: 'No Access',
    width: '60px',
    headerWidth: '67px',
    key: 'noAccess',
    includes: [
      { type: 'stories-radio', key: 'permission', checkValue: 'noAccess' },
    ],
  },
  {
    name: 'View',
    width: '60px',
    headerWidth: '67px',
    key: 'view',
    includes: [
      { type: 'stories-radio', key: 'permission', checkValue: 'view' },
    ],
  },
  {
    name: 'View/Edit',
    width: '60px',
    headerWidth: '67px',
    key: 'viewEdit',
    includes: [
      { type: 'stories-radio', key: 'permission', checkValue: 'viewEdit' },
    ],
  },
  {
    name: 'Name',
    width: '100px',
    key: 'name',
    sort: true,
    sortKey: 'name',
    includes: [{ type: 'text', key: 'name' }],
  },
  {
    name: 'Client Email',
    width: '200px',
    key: 'clientEmail',
    sort: true,
    sortKey: 'clientEmail',
    includes: [{ type: 'text', key: 'clientEmail' }],
  },
  {
    name: 'Content Owner',
    width: '120px',
    key: 'contentOwner',
    sort: true,
    sortKey: 'contentOwner',
    includes: [{ type: 'radio', key: 'contentOwner', checkValue: true }],
  },
  {
    name: 'Provisioned By',
    width: '304px',
    key: 'provisionedBy',
    sort: true,
    sortKey: 'provisionedBy',
    includes: [{ type: 'text', key: 'provisionedBy' }],
  },
];

export const hiddenRowColumns = [
  {
    name: '',
    width: '0px',
    key: 'story-inner-table',
    includes: [{ type: 'story-inner-table', key: 'story-inner-table' }],
  },
];

export const slugsTableColumnNames = [
  {
    name: 'Story ID',
    width: '80px',
    key: 'idStory',
    includes: [{ type: 'stories-story-id', key: 'idStory' }],
  },
  {
    name: 'Story Name',
    width: '250px',
    key: 'storyName',
    includes: [{ type: 'slugs-story-name', key: 'storyName' }],
  },
  {
    name: 'Created By',
    width: '140px',
    key: 'createdBy',
    includes: [{ type: 'slugs-created-by', key: 'createdBy' }],
  },
  {
    name: 'Created Date',
    width: '150px',
    key: 'createdDate',
    includes: [{ type: 'slugs-created-date', key: 'createdDate' }],
  },
  {
    name: 'Number of Slugs',
    width: '100px',
    key: 'numSlugs',
    includes: [{ type: 'text', key: 'numSlugs' }],
  },
  {
    name: 'URL (app.cbinsights.com/stories/)',
    width: '400px',
    key: 'url',
    includes: [{ type: 'stories-slugs', key: 'url' }],
  },
];

export const teamsTableColumnNames = [
  {
    name: '',
    width: '24px',
    key: '',
    includes: [{ type: 'stories-team-expand-icon', key: 'expandIcon' }],
  },
  {
    name: '',
    width: '24px',
    key: 'id',
    includes: [{ type: 'checkbox', key: 'id' }],
  },
  {
    name: 'Story ID',
    width: '100px',
    key: 'idStory',
    includes: [{ type: 'stories-story-id', key: 'idStory' }],
  },
  {
    name: 'Story Name',
    width: '450px',
    key: 'storyName',
    includes: [{ type: 'teams-story-name', key: 'storyName' }],
  },
  {
    name: '# of Users',
    width: '100px',
    key: 'numUsers',
    includes: [{ type: 'text', key: 'numUsers' }],
  },
  {
    name: 'Created Date',
    width: '150px',
    key: 'createdDate',
    includes: [{ type: 'text', key: 'createdDate' }],
  },
];

export default storiesTableColumnNames;
