import { requestAllAdminPages } from '../utils/api-request-helper';

export const FETCH_ALL_ADMIN_PAGES =
  'client/modules/admin-user-admin/actions/FETCH_ALL_ADMIN_PAGES';

export const fetchAllAdminPagesHelper = (pageInfo) => {
  return {
    type: FETCH_ALL_ADMIN_PAGES,
    pageInfo,
  };
};

export const fetchAllAdminPages = () => {
  return (dispatch, getState) => {
    if (getState().adminUsers.pageInfo.length) {
      return null;
    }
    return dispatch(requestAllAdminPages()).then((data) => {
      dispatch(fetchAllAdminPagesHelper(data.pageInfo));
    });
  };
};
