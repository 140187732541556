const labelToProps = {
  processor: [
    'service_name',
    'job_mode',
    'status',
    'git_repo',
    'container_name',
    'job_type',
    'kibana_link',
    'consul_link',
    'jenkins_link',
    'type',
    'memory_mb',
    'create_date',
    'last_updated_date',
  ],
  topic: ['topic_name', 'arn', 'url', 'create_date', 'last_updated_date'],
  queue: [
    'queue_name',
    'arn',
    'queue_url',
    'messages_received',
    'messages_sent',
    'dead_letter_target_arn',
    'max_receive_count',
    'create_date',
    'last_updated_date',
  ],
  s3_bucket: [
    'bucket_name',
    'bucket_creation_date',
    'create_date',
    'last_updated_date',
  ],
  table: [
    'table_name',
    'database',
    'database_type',
    'schema',
    'row_count',
    'create_date',
    'last_updated_date',
  ],
  pipeline: ['pipeline_name', 'create_date', 'last_updated_date'],
};

export default labelToProps;
