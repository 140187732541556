import PropTypes from 'prop-types';
import React from 'react';

const UserStatsTable = ({ children }) => (
  <div className="container">
    <div className="table-header-row flex border-b-4 border-double border-black text-black mb-2">
      <div className="w-1/12" />
      <div className="table-header-col w-1/6 text-center">Name</div>
      <div className="table-header-col w-1/6 text-center">User ID</div>
      <div className="table-header-col w-1/6 text-center">Completed</div>
      <div className="table-header-col w-1/6 text-center">Discarded</div>
      <div className="table-header-col w-1/6 text-center">Recovered</div>
      <div className="w-1/12" />
    </div>
    {children}
  </div>
);

UserStatsTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserStatsTable;
