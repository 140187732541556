import { SuggestUsersAndTeamsResponse } from '@cbinsights/adminservice/adminservice';
import { UseQueryResult, useQuery } from 'react-query';
import { SuggestUsersAndTeams } from '.';
import { getSuggestUsersAndTeams } from '../api/SuggestUsersAndTeams';

type Params = Parameters<typeof getSuggestUsersAndTeams>[0];
type Return = SuggestUsersAndTeamsResponse;

export const useGetSuggestUsersAndTeams = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [SuggestUsersAndTeams, Params]>(
    ['SuggestUsersAndTeams', params],
    () => getSuggestUsersAndTeams(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
