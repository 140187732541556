export const INITIALIZE_INPUT_INFO =
  'client/modules/common/components/table/INITIALIZE_INPUT_INFO';
export const UPDATE_INPUT_INFO =
  'client/modules/common/components/table/UPDATE_INPUT_INFO';
export const UPDATE_INPUT_INFO_TERM_ID =
  'client/modules/common/components/table/UPDATE_INPUT_INFO_TERM_ID';
export const UPDATE_INPUT_SUGGESTIONS =
  'client/modules/common/components/table/UPDATE_INPUT_SUGGESTIONS';
export const UPDATE_MULTIPLE_INPUTS_INFO =
  'client/modules/common/components/table/UPDATE_MULTIPLE_INPUTS_INFO';
export const UPDATE_SUGGESTIONS_ERROR =
  'client/modules/common/components/table/UPDATE_SUGGESTIONS_ERROR';

export const initializeInputInfo = (inputValues) => {
  return { type: INITIALIZE_INPUT_INFO, inputValues };
};

export const updateInputInfo = (inputId, term, termId) => {
  return {
    type: UPDATE_INPUT_INFO,
    inputId,
    inputInfo: { term, termId: termId.toString() },
  };
};

export const updateInputInfoTermId = (inputId, term, termId, entityType) => {
  return {
    type: UPDATE_INPUT_INFO_TERM_ID,
    inputId,
    inputInfo: { term, termId: termId.toString(), entityType },
  };
};

export const updateMultipleInputsInfo = (inputValues) => {
  return { type: UPDATE_MULTIPLE_INPUTS_INFO, inputValues };
};

export const updateSuggestionsHelper = (term, inputId, suggestions) => {
  return { type: UPDATE_INPUT_SUGGESTIONS, term, inputId, suggestions };
};

export const updateSuggestionTypeError = () => {
  return { type: UPDATE_SUGGESTIONS_ERROR };
};
