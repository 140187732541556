import * as actions from 'client/modules/markets/redux/actions/data';

export type RowFieldsMessageValidation = Record<string, string>;
export interface RecordValidationState {
  [index: string]: RowFieldsMessageValidation;
}
const initialState: RecordValidationState = {};

export function recordsValidationReducer(
  state = initialState,
  action
): RecordValidationState {
  switch (action.type) {
    case actions.SET_RECORDS_VALIDATION:
      return { ...action.recordsValidation };
    default:
      return state;
  }
}
