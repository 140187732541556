/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */
import crypto from 'crypto';

export const COMPANY_ENTITY = 'company';
export const INVESTOR_ENTITY = 'investor';
export const INVESTOR_PERSON = 'investor_person';

const prefix = {
  [COMPANY_ENTITY]: 'c',
  [INVESTOR_ENTITY]: 'i',
  [INVESTOR_PERSON]: 'ip',
};

const md5 = (str) => crypto.createHash('md5').update(`${str}`).digest('hex');
const getURL = (id, type) => `/${prefix[type]}-${md5(id)}`;

export const getCompanyURL = (id) => getURL(id, COMPANY_ENTITY);
export const getInvestorURL = (id) => getURL(id, INVESTOR_ENTITY);
export const getInvPerson = (id) => getURL(id, INVESTOR_PERSON);

export const getUrlWithHttp = (url) => {
  return url.toLowerCase().indexOf('http') !== -1 ? url : `https://${url}`;
};

export const getHostName = (url) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    return match[2];
  }
  return null;
};

export function getDomainName(url) {
  // https://codepen.io/martinkrulltott/pen/GWWWQj
  let domain;

  // find & remove protocol (http, ftp, etc.) and get domain
  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }

  // find & remove www
  if (domain.indexOf('www.') > -1) {
    domain = domain.split('www.')[1];
  }

  domain = domain.split(':')[0]; // find & remove port number
  domain = domain.split('?')[0]; // find & remove url params
  domain = domain.split('.')[0]; // find & remove top-level domain

  return domain;
}

export default getURL;
