/* eslint-disable no-undef */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { map } from 'lodash';

function parse(event) {
  const { value } = event.target;

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

/** 
 * @deprecated
 * It renders a select element with options that are either passed in as props or are generated from
the props 
 */
export default class Select extends Component {
  static propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    valueField: PropTypes.string,
    textField: PropTypes.string,
    styleName: PropTypes.string,
    dataTest: PropTypes.string,
    isDisabledField: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  static defaultProps = {
    id: '1',
    disabled: false,
    value: '',
    onBlur: () => {},
    onChange: () => {},
    valueField: undefined,
    textField: undefined,
    isDisabledField: '',
    placeholder: '',
    defaultValue: '',
    styleName: '',
    dataTest: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  /**
   * @deprecated
   * It renders a select element with options that are either passed in as props or are generated from
   * the props
   * @returns A select element with a disabled attribute, an id of "one", and a className of styleName.
   */
  render() {
    const {
      value,
      defaultValue,
      options,
      onBlur,
      onChange,
      valueField,
      textField,
      isDisabledField,
      placeholder,
      styleName,
      dataTest,
      disabled,
      ...rest
    } = this.props;

    if (!onChange) {
      rest.defaultValue = defaultValue;
    } else {
      rest.value = value;
      rest.onChange = (e) => onChange(parse(e), e);
    }

    return (
      <select
        disabled={disabled}
        id="one"
        {...rest}
        onBlur={(e) => onBlur(parse(e))}
        className={styleName}
        data-test={dataTest}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {map(options, (option, index) => {
          let optionValue;
          if (valueField) {
            optionValue = option[valueField];
          } else {
            optionValue = option;
          }
          const optionLabel = textField ? option[textField] : option;
          return (
            <option
              value={optionValue}
              disabled={option[isDisabledField]}
              key={index}
            >
              {optionLabel}
            </option>
          );
        })}
      </select>
    );
  }
}
