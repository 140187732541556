import {
  GeneralCbiEntitiesInfo,
  RepeatedInfoRequest,
} from '@cbinsights/cbientityservice/cbientityservice';
import { request } from 'client/modules/common/utils/request';

export const getGeneralCbiEntitiesInfo = (
  req: IndexSignature<RepeatedInfoRequest>
): Promise<GeneralCbiEntitiesInfo> =>
  request({
    url: '/service/cbientityservice/GetGeneralCbiEntitiesInfo',
    body: req,
  }).then((r) => r.body);
