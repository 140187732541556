import React from 'react';
import { map, get } from 'lodash';
import PropTypes from 'prop-types';
import AppBar from 'client/component-library/AppBar';
import { Tabs as MuiTabs, Tab } from 'client/component-library/Tabs';

const isNotDisabled = () => false;

/**
 * @deprecated
 * It renders a tab bar with a list of tabs
 * @returns A component that renders a tab bar with a list of tabs.
 */
const Tabs = ({
  current,
  options,
  classes,
  onChange,
  isTabable,
  rootClass,
  entityTypes,
}) => {
  const { appBar, tab, label, textColorPrimary, selected } = classes;
  return (
    <div className={classes[rootClass]}>
      <AppBar className={appBar} position="static">
        <MuiTabs
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          value={current}
          onChange={onChange}
        >
          {map(options, (option) => {
            return (
              <Tab
                className={tab}
                classes={{ wrapper: label, textColorPrimary, selected }}
                key={option.route}
                value={option.route}
                label={option.label}
                data-test={option.label}
                disabled={get(option, 'disabled', isNotDisabled)(entityTypes)}
                tabIndex={isTabable ? 0 : -1}
              />
            );
          })}
        </MuiTabs>
      </AppBar>
    </div>
  );
};

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  current: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  entityTypes: PropTypes.object,
  isTabable: PropTypes.bool,
  rootClass: PropTypes.string,
};

Tabs.defaultProps = {
  isTabable: true,
  rootClass: 'tabsRoot',
  entityTypes: {},
};

export default Tabs;
