import React from 'react';
import { logInfo } from 'client/modules/common/utils/logInfo';
import PropTypes from 'prop-types';
import { DEFAULT_OPTION } from 'client/component-library/Select/SimpleSelect';
import cx from 'classnames';
import { VALIDATIONS } from './validations';

let tempId = -1;

export const getUuid = () => --tempId;

export const checkIfValidUuid = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};

export const generateNewOption = (newValue) => ({
  id: --tempId,
  name: newValue || DEFAULT_OPTION.name,
  value: tempId,
  label: newValue || DEFAULT_OPTION.name,
  isTemp: !newValue,
  isNew: !!newValue, // this refers to if its a VALID new option
});

export function getDefaultValue({ initialValue, value }, placeholder = '') {
  const _initialValue = initialValue || value;
  if (Array.isArray(_initialValue)) {
    return [];
  }

  if (_initialValue === null) {
    return null;
  }

  switch (typeof _initialValue) {
    case 'number':
      return 0;
    case 'string':
      return '';
    case 'object':
      return placeholder
        ? { ...DEFAULT_OPTION, label: '', name: '' }
        : DEFAULT_OPTION;
    default:
      return '';
  }
}

const FIELD_SIZE_MAP = {
  AUTO: 'w-auto',
  FULL: 'w-full',
  HALF: 'w-1/2',

  COL_1: 'w-1/12',
  COL_2: 'w-2/12',
  COL_3: 'w-3/12',
  COL_4: 'w-4/12',
  COL_5: 'w-5/12',
  COL_6: 'w-6/12',
  COL_7: 'w-7/12',
  COL_8: 'w-8/12',
  COL_9: 'w-9/12',
  COL_10: 'w-10/12',
  COL_11: 'w-11/12',
};

/* Allowed field sizes */
const FIELD_SIZE_KEYS = Object.keys(FIELD_SIZE_MAP).reduce(
  (map, val) => ({ ...map, [val]: val }),
  {}
);

/**
 * @deprecated
 * It renders a div with a className that is a combination of the props passed in
 * @returns A React Fragment with a title and a div with a className and style.
 */
const Section = ({
  children,
  hasDivider,
  direction,
  size,
  customSize,
  verticalPadding,
  className,
  title,
}) => {
  const sectionClassNames = cx(
    `flex my-2 text-xs py-${verticalPadding} flex-${direction}${
      className === '' ? '' : ` ${className}`
    }`,
    {
      [FIELD_SIZE_MAP[size]]: !customSize,
      'border-b': hasDivider,
    }
  );
  return (
    <React.Fragment>
      {title && <div className="text-sm pl-1">{title}</div>}
      <div
        className={sectionClassNames}
        style={customSize ? { width: `${customSize}%` } : {}}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

/**
 * @deprecated
 * It takes a size prop and returns a div with a className that is a combination of the size prop and
 * some other classes
 */
const FormField = ({ children, size }) => (
  <div
    className={
      size === false ? '' : `${FIELD_SIZE_MAP[size]} px-1 mb-1 text-xs`
    }
    data-test="form-field"
  >
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  hasDivider: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(FIELD_SIZE_MAP)),
  direction: PropTypes.oneOf([
    'col',
    'row',
    'col-reverse',
    'row-reverse',
    'wrap',
  ]),
  verticalPadding: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  customSize: PropTypes.string,
};

Section.defaultProps = {
  hasDivider: false,
  title: '',
  className: '',
  direction: 'wrap',
  size: FIELD_SIZE_KEYS.AUTO,
  verticalPadding: '0',
  customSize: '',
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(FIELD_SIZE_MAP)),
};

FormField.defaultProps = {
  size: FIELD_SIZE_KEYS.FULL,
};

/**
 * @summary we could be getting large/small numbers in Scientific Notation
 */
export function expandScientificNotation(num) {
  if (!num) {
    return num;
  }

  try {
    const _num = num.toString();
    if (_num.includes('e-')) {
      const leadingZeros = _num.split('e-').pop();
      return num.toFixed(leadingZeros);
    }
  } catch (error) {
    logInfo({
      error,
      message: 'Failed To expandScientificNotation',
      method: 'expandScientificNotation',
    });
  }

  return num;
}

export { FIELD_SIZE_KEYS, Section, FormField, VALIDATIONS };
