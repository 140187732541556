import { keyBy, map, omit } from 'lodash';
import createFullName from 'client/modules/common/utils/createFullName';
import {
  mapAdminServiceUsersHelper,
  setDisplayVals,
  filterAdminUsers,
  resetPageNumber,
  REMOVE_USER_TYPE,
  ADD_USER_TYPE,
} from '../utils/admin-user-helpers';
import {
  FETCH_ALL_DATA_PLATFORM_USERS,
  UPDATE_ADMIN_USER_PAGE_NUMBER,
  UPDATE_ADMIN_USER_RECORDS_PER_PAGE,
  UPDATE_NEW_USER_PAGE_NUMBER,
  UPDATE_NEW_USER_RECORDS_PER_PAGE,
  SET_DISPLAY_NEW_USERS,
  UPDATE_PIPELINE_USER_PAGE_NUMBER,
  UPDATE_PIPELINE_USER_RECORDS_PER_PAGE,
  SET_DISPLAY_PIPELINE_USERS,
  SET_DISPLAY_ADMIN_USERS,
  ADD_NEW_ADMIN_USER,
  REMOVE_EXISTING_PIPELINE_USER,
  CLEAR_NEW_USERS,
  INITIALIZE_PIPELINE_USERS,
  REMOVE_NEW_USER,
  SET_ADMIN_USER_FILTER_TERM,
} from '../actions/users';

const initialState = {
  adminUsers: {},
  pipelineUsers: {},
  newUsers: {},
  adminUserPageNumber: 0,
  adminUserRecordsPerPage: 3,
  pipelineUserPageNumber: 0,
  pipelineUserRecordsPerPage: 3,
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
  adminUserFilterTerm: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_DATA_PLATFORM_USERS: {
      const adminUsers = mapAdminServiceUsersHelper(action.users);
      return {
        ...state,
        adminUsers: keyBy(adminUsers, 'idUser'),
      };
    }
    case UPDATE_ADMIN_USER_PAGE_NUMBER:
      return { ...state, adminUserPageNumber: action.pageNumber };
    case UPDATE_ADMIN_USER_RECORDS_PER_PAGE:
      return { ...state, adminUserRecordsPerPage: action.recordsPerPage };
    case UPDATE_NEW_USER_PAGE_NUMBER:
      return { ...state, newUserPageNumber: action.pageNumber };
    case UPDATE_NEW_USER_RECORDS_PER_PAGE:
      return { ...state, newUserRecordsPerPage: action.recordsPerPage };
    case SET_DISPLAY_NEW_USERS: {
      const newUsers = action.users;
      const {pageNumber} = action;
      const {recordsPerPage} = action;
      const {
        objectRecords,
        objectRecordOrder,
        objectRecordCellStyle,
        objectLength,
      } = setDisplayVals({ object: newUsers, pageNumber, recordsPerPage });
      return {
        ...state,
        newUsers,
        newUserRecords: objectRecords,
        newUserRecordOrder: objectRecordOrder,
        newUserRecordCellStyle: objectRecordCellStyle,
        numNewUsers: objectLength,
      };
    }
    case ADD_NEW_ADMIN_USER: {
      let { newUsers, adminUsers } = action;
      const {
        idUser,
        adminUserPageNumber,
        adminUserRecordsPerPage,
        newUserPageNumber,
        newUserRecordsPerPage,
        adminUserFilterTerm,
        pipelineUsers,
      } = action;
      const newUser = adminUsers[idUser];
      adminUsers = omit(adminUsers, idUser);
      newUsers = { ...newUsers, [idUser]: newUser };

      const newProps = setDisplayVals({
        object: newUsers,
        pageNumber: newUserPageNumber,
        recordsPerPage: newUserRecordsPerPage,
      });
      const tempAdminUsers = filterAdminUsers({
        adminUsers,
        pipelineUsers,
        newUsers,
        filterTerm: adminUserFilterTerm,
      });
      const adminProps = setDisplayVals({
        object: tempAdminUsers,
        pageNumber: adminUserPageNumber,
        recordsPerPage: adminUserRecordsPerPage,
      });
      return {
        ...state,
        newUsers,
        adminUsers,
        newUserRecords: newProps.objectRecords,
        newUserRecordOrder: newProps.objectRecordOrder,
        newUserRecordCellStyle: newProps.objectRecordCellStyle,
        numNewUsers: newProps.objectLength,
        adminUserRecords: adminProps.objectRecords,
        adminUserRecordOrder: adminProps.objectRecordOrder,
        adminUserRecordCellStyle: adminProps.objectRecordCellStyle,
        numAdminUsers: adminProps.objectLength,
      };
    }
    case REMOVE_EXISTING_PIPELINE_USER: {
      let { newUsers, pipelineUsers } = action;
      const {
        idUser,
        pipelineUserPageNumber,
        pipelineUserRecordsPerPage,
        newUserPageNumber,
        newUserRecordsPerPage,
      } = action;
      const newUser = pipelineUsers[idUser];
      pipelineUsers = omit(pipelineUsers, idUser);
      newUsers = { ...newUsers, [idUser]: newUser };

      const newProps = setDisplayVals({
        object: newUsers,
        pageNumber: newUserPageNumber,
        recordsPerPage: newUserRecordsPerPage,
      });
      const pipelineProps = setDisplayVals({
        object: pipelineUsers,
        pageNumber: pipelineUserPageNumber,
        recordsPerPage: pipelineUserRecordsPerPage,
      });
      return {
        ...state,
        newUsers,
        pipelineUsers,
        newUserRecords: newProps.objectRecords,
        newUserRecordOrder: newProps.objectRecordOrder,
        newUserRecordCellStyle: newProps.objectRecordCellStyle,
        numNewUsers: newProps.objectLength,
        pipelineUserRecords: pipelineProps.objectRecords,
        pipelineUserRecordOrder: pipelineProps.objectRecordOrder,
        pipelineUserRecordCellStyle: pipelineProps.objectRecordCellStyle,
        numPipelineUsers: pipelineProps.objectLength,
      };
    }
    case REMOVE_NEW_USER: {
      let { newUsers, pipelineUsers, adminUsers } = action;
      const {
        idUser,
        pipelineUserPageNumber,
        pipelineUserRecordsPerPage,
        adminUserPageNumber,
        adminUserRecordsPerPage,
        newUserPageNumber,
        newUserRecordsPerPage,
        adminUserFilterTerm,
      } = action;
      const newUser = newUsers[idUser];

      newUsers = omit(newUsers, idUser);
      if (newUser.actionType === ADD_USER_TYPE) {
        adminUsers = { ...adminUsers, [idUser]: newUser };
      } else if (newUser.actionType === REMOVE_USER_TYPE) {
        pipelineUsers = { ...pipelineUsers, [idUser]: newUser };
      }

      const newProps = setDisplayVals({
        object: newUsers,
        pageNumber: newUserPageNumber,
        recordsPerPage: newUserRecordsPerPage,
      });
      const pipelineProps = setDisplayVals({
        object: pipelineUsers,
        pageNumber: pipelineUserPageNumber,
        recordsPerPage: pipelineUserRecordsPerPage,
      });
      const tempAdminUsers = filterAdminUsers({
        adminUsers,
        pipelineUsers,
        newUsers,
        filterTerm: adminUserFilterTerm,
      });
      const adminProps = setDisplayVals({
        object: tempAdminUsers,
        pageNumber: adminUserPageNumber,
        recordsPerPage: adminUserRecordsPerPage,
      });
      return {
        ...state,
        newUsers,
        pipelineUsers,
        adminUsers,
        newUserRecords: newProps.objectRecords,
        newUserRecordOrder: newProps.objectRecordOrder,
        newUserRecordCellStyle: newProps.objectRecordCellStyle,
        numNewUsers: newProps.objectLength,
        pipelineUserRecords: pipelineProps.objectRecords,
        pipelineUserRecordOrder: pipelineProps.objectRecordOrder,
        pipelineUserRecordCellStyle: pipelineProps.objectRecordCellStyle,
        numPipelineUsers: pipelineProps.objectLength,
        adminUserRecords: adminProps.objectRecords,
        adminUserRecordOrder: adminProps.objectRecordOrder,
        adminUserRecordCellStyle: adminProps.objectRecordCellStyle,
        numAdminUsers: adminProps.objectLength,
      };
    }
    case SET_DISPLAY_ADMIN_USERS: {
      const {
        adminUsers,
        pipelineUsers,
        newUsers,
        pageNumber,
        recordsPerPage,
        adminUserFilterTerm,
      } = action;
      const tempAdminUsers = filterAdminUsers({
        adminUsers,
        pipelineUsers,
        newUsers,
        filterTerm: adminUserFilterTerm,
      });
      const adminUserPageNumber = resetPageNumber(
        Object.keys(tempAdminUsers).length,
        pageNumber,
        recordsPerPage
      );
      const {
        objectRecords,
        objectRecordOrder,
        objectRecordCellStyle,
        objectLength,
      } = setDisplayVals({
        object: tempAdminUsers,
        pageNumber: adminUserPageNumber,
        recordsPerPage,
      });
      return {
        ...state,
        adminUserRecords: objectRecords,
        adminUserRecordOrder: objectRecordOrder,
        adminUserRecordCellStyle: objectRecordCellStyle,
        numAdminUsers: objectLength,
        adminUserPageNumber,
      };
    }
    case UPDATE_PIPELINE_USER_PAGE_NUMBER:
      return { ...state, pipelineUserPageNumber: action.pageNumber };
    case UPDATE_PIPELINE_USER_RECORDS_PER_PAGE:
      return { ...state, pipelineUserRecordsPerPage: action.recordsPerPage };
    case SET_DISPLAY_PIPELINE_USERS: {
      const { users, pageNumber, recordsPerPage } = action;
      const {
        objectRecords,
        objectRecordOrder,
        objectRecordCellStyle,
        objectLength,
      } = setDisplayVals({ object: users, pageNumber, recordsPerPage });
      return {
        ...state,
        pipelineUsers: users,
        pipelineUserRecords: objectRecords,
        pipelineUserRecordOrder: objectRecordOrder,
        pipelineUserRecordCellStyle: objectRecordCellStyle,
        numPipelineUsers: objectLength,
      };
    }
    case CLEAR_NEW_USERS: {
      return {
        ...state,
        newUsers: {},
        newUserRecords: {},
        newUserRecordOrder: [],
        newUserRecordCellStyle: {},
        numNewUsers: 0,
        newUserPageNumber: 0,
      };
    }
    case INITIALIZE_PIPELINE_USERS: {
      const pipelineUsers = keyBy(
        map(action.users, (user) => {
          return {
            ...user,
            actionType: REMOVE_USER_TYPE,
            fullName: createFullName(
              user.firstName,
              user.lastName,
              user.emailAddress
            ),
          };
        }),
        'idUser'
      );
      return {
        ...state,
        pipelineUsers,
      };
    }
    case SET_ADMIN_USER_FILTER_TERM:
      return { ...state, adminUserFilterTerm: action.filterTerm };
    default:
      return state;
  }
}
