import {
  HasPendingEntityDraftsRequest,
  HasPendingEntityDraftsResponse,
} from '@cbinsights/cbientityservice/cbientityservice';

import { request } from 'client/modules/common/utils/request';

export const hasPendingEntityDrafts = (
  req: HasPendingEntityDraftsRequest
): Promise<HasPendingEntityDraftsResponse> =>
  request({
    url: '/service/cbientityservice/HasPendingEntityDrafts',
    body: { ...req },
  }).then((r) => r.body);
