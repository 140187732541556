import { RemoveUsersFromTeamRequest } from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const removeUsersFromTeam = (
  req: Partial<RemoveUsersFromTeamRequest>
): Promise<void> =>
  request({
    url: '/service/adminservice/RemoveUsersFromTeam',
    body: req,
  }).then((r) => r.body);
