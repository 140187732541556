import {
  GetNewsSourceRequest,
  GetNewsSourceResponse,
  ListNewsSourcesResponse,
} from '@cbinsights/newsservice/newsservice';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import {
  createNewsSource,
  getNewsSource,
  listNewsSources,
  updateNewsSource,
} from './api';

const NewsSourcesKeys = {
  ListNewsSources: 'ListNewsSources',
  GetNewsSource: 'GetNewsSource',
} as const;

type NewsSourcesKeysType = keyof typeof NewsSourcesKeys;

export const useListNewsSources = (): UseQueryResult<
  ListNewsSourcesResponse,
  Error
> =>
  useQuery<
    ListNewsSourcesResponse,
    Error,
    ListNewsSourcesResponse,
    [NewsSourcesKeysType]
  >([NewsSourcesKeys.ListNewsSources], () => listNewsSources(), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

export const useGetNewsSource = (
  req: GetNewsSourceRequest
): UseQueryResult<GetNewsSourceResponse, Error> =>
  useQuery<
    GetNewsSourceResponse,
    Error,
    GetNewsSourceResponse,
    [NewsSourcesKeysType, number]
  >([NewsSourcesKeys.GetNewsSource, req.id_source], () => getNewsSource(req), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

export const useUpdateNewsSource = () => {
  const { mutateAsync } = useMutation(updateNewsSource);
  return { mutateUpdateNewsSource: mutateAsync };
};

export const useCreateNewsSource = () => {
  const { mutateAsync } = useMutation(createNewsSource);
  return { mutateCreateNewsSource: mutateAsync };
};
