import React, { ReactNode } from 'react';
import { Layout, PageHeader, PageHeaderProps } from 'antd';

export type AntPageLayoutWithHeaderProps = {
  children: ReactNode;
} & PageHeaderProps;

export const AntPageLayoutWithHeader = ({
  children,
  ...header
}: AntPageLayoutWithHeaderProps) => {
  return (
    <Layout>
      <Layout className="min-h-screen container mx-auto px-4">
        <PageHeader
          className={`px-0 ${header.className ? header.className : ''}`}
          {...header}
        />
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
};
