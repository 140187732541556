import { TeamFeatureUsage } from '@cbinsights/userv2service/userv2service';
import { useMutation } from 'react-query';
import { setTeamFeatureUsage } from '../services/api/SetTeamFeatureUsage';

type Props = {
  feature: TeamFeatureUsage;
};

const useSetTeamFeatureUsage = () => {
  const setTeamFeatureUsageQuery = useMutation(setTeamFeatureUsage);

  const onSubmit = ({ feature }: Props) => {
    return setTeamFeatureUsageQuery.mutateAsync({
      team_feature_usages: [feature],
    });
  };

  return {
    onSubmit,
    isLoading: setTeamFeatureUsageQuery.isLoading,
  };
};

export default useSetTeamFeatureUsage;
