const styles = () => ({
  label: {
    width: '100%',
    display: 'block',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  root: {
    padding: 0,
  },
});

export default styles;
