import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { revokePermission } from 'client/modules/admin-user-admin/redux/actions/tab-data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';

class RevokeIconCell extends Component {
  handleRevoke = (id) => () => {
    const [recordId] = id.split('-');
    this.props.revokePermission(parseInt(recordId, 10) || 0);
  };

  render() {
    const { id } = this.props;
    return (
      <div style={{ position: 'relative', top: '-9px' }}>
        <BaseIcon
          handleOnClick={this.handleRevoke(id)}
          dataTest={`${id}-revoke-icon`}
          iconText="Revoke"
          iconClassName="revokeIcon"
          iconName="Delete"
          wrapperStyles="revokeIconWrapper"
          isIconBlock={false}
        />
      </div>
    );
  }
}

RevokeIconCell.propTypes = {
  revokePermission: PropTypes.func.isRequired,
  id: PropTypes.string,
};

RevokeIconCell.defaultProps = {
  id: '',
};

export default connect(null, { revokePermission })(RevokeIconCell);
