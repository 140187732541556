import {
  GetUserFeatureUsageRequest,
  GetUserFeatureUsageResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUserFeatureUsage = (
  req: Partial<GetUserFeatureUsageRequest>
): Promise<GetUserFeatureUsageResponse> =>
  request({
    url: '/service/adminservice/GetUserFeatureUsage',
    body: req,
  }).then((r) => r.body);
