/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import { Field } from 'formik';
import {
  FIELD_SIZE_KEYS,
  Section,
} from 'client/modules/common/form/fields/UtilFields';
import { SelectSearchField } from 'client/modules/common/form/fields/SelectSearchField';
import {
  ClassifierOptions,
  LanguageOptions,
  SourceOptions,
} from '../redux/model/FilterOptionsForNews';

type FieldsMap = {
  COL_2?: string;
};

type NewsQueueFilterProps = {
  handleSubmit: () => void;
  handleClear: () => void;
  totalCount: number;
  options: {
    languageOptions: LanguageOptions;
    sourceOptions: SourceOptions;
    classifierOptions: ClassifierOptions;
  };
  formValues;
  isLoading: boolean;
};

const { COL_2 }: FieldsMap = FIELD_SIZE_KEYS;

// TODO call clear queue api, pass down as handleReset?
export const NewsQueueFilter = (props: NewsQueueFilterProps) => {
  const {
    handleSubmit,
    handleClear,
    totalCount = 0,
    options: { languageOptions, sourceOptions, classifierOptions },
    formValues,
    isLoading,
  } = props;

  const [isClassifierDisabled, setIsClassifierDisabled] = useState(false);

  useEffect(() => {
    if (totalCount > 0) {
      handleClear();
    }
    if (
      formValues.sourceType.value === 3 ||
      formValues.sourceType.value === 4
    ) {
      setIsClassifierDisabled(true);
    } else {
      setIsClassifierDisabled(false);
    }
  }, [formValues]);

  const handleChangeLanguageFilter = (
    selectedOptions,
    state,
    optionsInSelect
  ) => {
    const allWasSelected = !!state.oldValue.find((el) => el.value === 0);
    const allIsSelected = !!selectedOptions.find((el) => el.value === 0);

    if (
      !allIsSelected &&
      !allWasSelected &&
      selectedOptions.filter((el) => el.value !== 0).length ===
        optionsInSelect.filter((el) => el.value !== 0).length
    ) {
      state.setFieldValue('languages', optionsInSelect);
    }

    if (
      allIsSelected &&
      allWasSelected &&
      selectedOptions.length < optionsInSelect.length
    ) {
      state.setFieldValue(
        'languages',
        selectedOptions.filter((el) => el.value !== 0)
      );
    }

    if (allWasSelected && !allIsSelected) {
      state.setFieldValue('languages', []);
    }

    if (allIsSelected && !allWasSelected) {
      state.setFieldValue('languages', optionsInSelect);
    }
  };

  return (
    <Section>
      <div className="flex flex-row w-full justify-end items-center flex-wrap">
        <label
          htmlFor="status"
          className="flex items-center mr-2 ml-4 pt-4 text-sm"
        >
          FILTER BY:
        </label>

        <div style={{ width: 120 }}>
          <Field
            excludeDefaultOption
            component={SelectSearchField}
            label="Source Type"
            placeholder="Source Type"
            name="sourceType"
            options={sourceOptions}
            data-test="sourceTypeSelect"
          />
        </div>

        <Field
          size={COL_2}
          component={SelectSearchField}
          placeholder="Languages"
          label="Languages"
          name="languages"
          options={languageOptions}
          isMulti
          isClearable
          excludeDefaultOption
          data-test="filterGroupNamesSelect"
          onChange={handleChangeLanguageFilter}
          value={formValues.languages}
        />

        <Field
          size={COL_2}
          excludeDefaultOption
          component={SelectSearchField}
          placeholder="Classifier"
          label="Classifier"
          name="classifier"
          options={classifierOptions}
          data-test="classifierSelect"
          disabled={isClassifierDisabled}
        />

        <div className="ml-2 pt-3 w-2/12">
          <Button
            onClick={handleClear}
            data-test="clear-queue-button"
            theme="gray"
            disabled={isLoading}
          >
            Clear Queue
          </Button>
        </div>
        <div className="ml-2 pt-3 w-2/12">
          <Button
            onClick={handleSubmit}
            data-test="fetch-news-button"
            disabled={totalCount > 0 || isLoading}
          >
            Fetch News
          </Button>
        </div>
      </div>
    </Section>
  );
};
