import React from 'react';
import PropTypes from 'prop-types';
import ImageEditor from 'client/component-library/modals/ImageEditor';
import Modal from 'client/component-library/modals/MaterialUIModal';

/**
 * @deprecated
 * It's a modal that contains an image editor
 * @returns A modal with an image editor.
 */
const ImageUploader = ({
  showModal,
  onCancel,
  onSave,
  isImage = false,
  isGoogleSearchEnabled = false,
  canPasteURL = true,
  companyLogo,
}) => {
  return (
    <div>
      <Modal open={showModal} handleCloseModal={onCancel}>
        <ImageEditor
          isImage={isImage}
          onCancel={onCancel}
          onSave={onSave}
          isGoogleSearchEnabled={isGoogleSearchEnabled}
          canPasteURL={canPasteURL}
          companyLogo={companyLogo}
        />
      </Modal>
    </div>
  );
};

ImageUploader.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  isImage: PropTypes.bool,
  isGoogleSearchEnabled: PropTypes.bool,
  canPasteURL: PropTypes.bool,
  companyLogo: PropTypes.shape({
    companyName: PropTypes.string,
    primaryUrl: PropTypes.string,
  }),
};

export default ImageUploader;
