import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Popconfirm, Tag, message } from 'antd';
import {
  CBI_PORTAL_NAME,
  useGetPortals,
} from 'client/modules/users/services/hooks/useGetPortals';
import { batchCreateUsers } from 'client/modules/users/services/api/BatchCreateUsers';
import { useTeamMembers } from './useTeamMembers';
import useChangePackage from './useChangePackage';

export interface DataType {
  key: React.Key;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  portal?: number;
  package?: number;
  status?: string;
  message?: string;
  error?: string;
}

type Props = {
  idTeam?: number;
  onClose: () => void;
};

const getStatus = (entry: DataType) => {
  if (entry.status === 'Success') {
    return <Tag color="green">{entry.message ? entry.message : 'Added'}</Tag>;
  }

  if (entry.error) {
    return <Tag color="red">{entry.error}</Tag>;
  }
  return null;
};

export const useAddNewUsers = ({ idTeam, onClose }: Props) => {
  const [dataSource, setDataSource] = useState<DataType[]>([
    {
      key: 1,
      email: '',
      company: '',
      firstName: '',
      lastName: '',
      message: '',
    },
  ]);

  const getKey = () => {
    let key;
    try {
      key = crypto?.randomUUID();
    } catch {
      key = dataSource.length + 1;
    }
    return key;
  };

  type EntryResponse = {
    email: string;
    status: string;
    error: string;
  };

  type SaveUsersResponse = {
    errors: Array<EntryResponse>;
    success: Array<EntryResponse>;
  };

  const defaultEntry: DataType = {
    key: getKey(),
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    message: '',
  };

  const createNewUsersQuery = useMutation(batchCreateUsers);

  const isLoadingCreateUsers = createNewUsersQuery.isLoading;

  const { refetchGetTeamMembers } = useTeamMembers(idTeam);

  const { data: portals } = useGetPortals({ enabled: true });
  const { fields, packagesData } = useChangePackage();

  const portalOptions = useMemo(() => {
    if (!portals?.portals) {
      return [];
    }

    return portals.portals
      .filter(
        (portal) => portal.id_package !== 0 || portal.name === CBI_PORTAL_NAME
      )
      .map((portal) => ({ label: portal.name, value: portal.id_portal }));
  }, [portals]);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const getPackageOptions = (record) => {
    if (!record.portal) {
      return fields.package.options;
    }

    const portalDetail = portals.portals.find(
      (portal) => portal.id_portal === record.portal
    );

    if (!portalDetail || portalDetail.id_package === 0) {
      return fields.package.options;
    }

    return fields.package.options.filter(
      (option) => option.value === portalDetail.id_package
    );
  };

  const getDefaultColumns = () => {
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        editable: true,
        rules: [{ type: 'email', message: 'Invalid email.' }],
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        editable: true,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        editable: true,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        editable: true,
      },
      {
        title: 'Portal',
        dataIndex: 'portal',
        width: '20%',
        key: 'portal',
        inputType: 'Select',
        editable: true,
        selectOptions: portalOptions,
        hideInTeam: true,
      },
      {
        title: 'Package',
        dataIndex: 'package',
        key: 'package',
        width: '20%',
        inputType: 'Select',
        editable: true,
        selectOptions: getPackageOptions,
        hideInTeam: true,
      },
      {
        title: 'Status',
        dataIndex: 'error',
        key: 'error',
        editable: false,
        render: (_, entry) => <>{getStatus(entry)}</>,
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (_, record: { key: React.Key }) =>
          dataSource.length >= 1 ? (
            <Popconfirm
              title="Clear entry"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type="link">Clear</Button>
            </Popconfirm>
          ) : null,
      },
    ];
    return columns.filter((column) => {
      if (idTeam === null) {
        return true;
      }
      return !column.hideInTeam;
    });
  };

  const defaultColumns = getDefaultColumns();

  const pendingItems = useMemo(() => {
    return dataSource.filter((entry) => entry.status !== 'Success');
  }, [dataSource]);

  const handleAdd = () => {
    setDataSource([...dataSource, defaultEntry]);
  };

  const mapErrorMessage = (error: string) => {
    if (error?.includes('unknown')) {
      return 'Failed to add user';
    }

    if (error === 'Invalid email domain') {
      return 'Invalid email domain';
    }

    return error;
  };

  const mapSuccessMessage = (reason: string) => {
    if (reason === 'Already on team') {
      return 'User Already on Team';
    }

    if (reason === 'Was already on a team') {
      return 'Added to Team';
    }

    return 'Added';
  };

  const submitAddNewUsersToTeam = (
    users: DataType[],
    skipWelcomeEmail?: boolean
  ): Promise<SaveUsersResponse> => {
    return createNewUsersQuery
      .mutateAsync({
        id_team: idTeam,
        users: users.map((entry) => ({
          company: entry.company,
          email: entry.email,
          first_name: entry.firstName,
          last_name: entry.lastName,
          title: '',
        })),
        skip_welcome_email: skipWelcomeEmail,
      })
      .then((result) => {
        let errors = [];
        let success = [];
        if (result.failed_users?.length > 0) {
          message.error(
            'We encountered errors while adding users. Please review and fix them before trying it again'
          );
          errors = result.failed_users.map((entry) => {
            return {
              email: entry.user.email,
              status: 'Failure',
              error: mapErrorMessage(entry.reason),
            };
          });
        }

        if (result.successful_users) {
          success = result.successful_users.map((entry) => ({
            email: entry.user.email,
            status: 'Success',
            error: '',
            message: mapSuccessMessage(entry.reason),
          }));
        }
        return { errors, success };
      })
      .catch(() => {
        message.error(
          'We encountered errors while adding users. Please try again later'
        );
        return { errors: [], success: [] };
      });
  };

  const submitCreateNewUsers = (
    users: DataType[],
    skipWelcomeEmail?: boolean
  ): Promise<SaveUsersResponse> => {
    return createNewUsersQuery
      .mutateAsync({
        users: users.map((entry) => ({
          company: entry.company,
          email: entry.email,
          first_name: entry.firstName,
          last_name: entry.lastName,
          title: '',
          package: { id: entry.package, name: '' },
          id_portal: entry.portal,
        })),
        skip_welcome_email: skipWelcomeEmail,
      })
      .then((result) => {
        let errors = [];
        let success = [];
        if (result.failed_users?.length > 0) {
          message.error(
            'We encountered errors while adding users. Please review and fix them before trying it again'
          );
          errors = result.failed_users.map((entry) => ({
            email: entry.user.email,
            status: 'Failue',
            error: mapErrorMessage(entry.reason),
          }));
        }

        if (result.successful_users) {
          success = result.successful_users.map((entry) => ({
            email: entry.user.email,
            status: 'Success',
            error: '',
          }));
        }
        return { errors, success };
      })
      .catch(() => {
        message.error(
          'We encountered errors while adding users. Please try again later'
        );
        return { errors: [], success: [] };
      });
  };

  const saveUsers = (
    users: DataType[],
    skipWelcomeEmail?: boolean
  ): Promise<SaveUsersResponse> => {
    if (idTeam) {
      return submitAddNewUsersToTeam(users, skipWelcomeEmail);
    }
    return submitCreateNewUsers(users, skipWelcomeEmail);
  };

  async function batchSaveUsers(skipWelcomeEmail?: boolean) {
    const users = dataSource.filter(
      (entry) =>
        entry.status !== 'Success' && entry.status !== 'validation-error'
    );

    if (users.length === 0) {
      return;
    }

    const batchSize = 50;
    const numBatches = Math.ceil(users.length / batchSize);
    const batchResponse: SaveUsersResponse = { errors: [], success: [] };

    for (let batchNum = 0; batchNum < numBatches; batchNum++) {
      const startIndex = batchNum * batchSize;
      const endIndex = Math.min((batchNum + 1) * batchSize, users.length);
      const batchUsers = users.slice(startIndex, endIndex);
      /**
       * We want to wait for the previous saveUsers operation before trying to save more
       */
      // eslint-disable-next-line no-await-in-loop
      const { errors, success } = await saveUsers(batchUsers, skipWelcomeEmail);

      batchResponse.errors = batchResponse.errors.concat(errors);
      batchResponse.success = batchResponse.success.concat(success);
    }

    if (batchResponse.errors.length === 0) {
      message.success('The users have been added successfully to the team');
    }

    const result = batchResponse.errors.concat(batchResponse.success);

    setDataSource(
      dataSource.map((source) => {
        const sourceResult = result.find(
          (entry) =>
            entry.email === source.email && source.status !== 'validation-error'
        );
        if (sourceResult) {
          return {
            ...source,
            ...sourceResult,
          };
        }
        return source;
      })
    );

    if (idTeam && batchResponse.success.length > 0) {
      refetchGetTeamMembers();
    }
  }

  const handleSave = (
    row: DataType,
    setFieldValue: (name: string, value: unknown) => void
  ) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const rowDetail = { ...row };

    if (row.portal && row.portal !== item.portal) {
      const portalDetail = portals?.portals.find(
        (portal) => portal.id_portal === row.portal
      );

      if (
        portalDetail &&
        portalDetail.id_package !== row.package &&
        portalDetail.name !== CBI_PORTAL_NAME
      ) {
        rowDetail.package = portalDetail.id_package;
        setFieldValue('package', portalDetail.id_package);
      }
    }

    newData.splice(index, 1, {
      ...item,
      ...rowDetail,
      status: '',
      error: '',
      message: '',
    });
    setDataSource(newData);
  };

  const handleClose = () => {
    setDataSource([defaultEntry]);
    onClose();
  };

  return {
    defaultColumns,
    handleSave,
    dataSource,
    handleAdd,
    handleClose,
    saveUsers: batchSaveUsers,
    isLoadingCreateUsers,
    pendingItems,
    setDataSource,
    portals,
    packagesData,
  };
};

export default useAddNewUsers;
