import PropTypes from 'prop-types';
import React from 'react';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

const UserQueueActions = ({
  onArchive,
  onDiscard,
  onBlock,
  queueSourceType,
  isResolvingNews,
}) => (
  <div className="flex flex-col justify-between">
    <Button
      onClick={onArchive}
      disabled={isResolvingNews}
      data-test="archive"
      theme="success"
    >
      Archive
    </Button>
    {queueSourceType !== 'sec' && (
      <Button
        onClick={onDiscard}
        disabled={isResolvingNews}
        data-test="discard"
        theme="danger"
        style={{ marginTop: '8px' }}
      >
        Discard
      </Button>
    )}
    {(function blockButton() {
      if (queueSourceType === 'sec') {
        return (
          <Button
            onClick={onBlock}
            data-test="block"
            style={{
              color: 'white',
              backgroundColor: 'black',
              marginTop: '8px',
            }}
            disabled={isResolvingNews}
            theme="success"
          >
            Block CIK
          </Button>
        );
      }
      return null;
    })()}
  </div>
);

UserQueueActions.propTypes = {
  onArchive: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  queueSourceType: PropTypes.string.isRequired,
  isResolvingNews: PropTypes.bool.isRequired,
};

export default UserQueueActions;
