import { each } from 'lodash';

/**
 * @deprecated
 * It takes a list of items, a filter value, a list of fields to filter on, and a boolean indicating
 * whether to use case sensitive search, and returns a list of items that match the filter value
 * @param allItems - The list of items to filter.
 * @param inputValue - The value that the user has typed into the input field.
 * @param filterFieldNames - An array of field names to filter by.
 * @param useCaseSensitiveSearch - boolean
 */
export const filterListItemsByMultipleKeys = (
  allItems,
  inputValue,
  filterFieldNames,
  useCaseSensitiveSearch
) => {
  if (!allItems || !inputValue) {
    return [];
  }
  const filteredListItems = [];
  const filterValue = useCaseSensitiveSearch
    ? inputValue
    : inputValue.toLowerCase();
  each(allItems, (item) => {
    each(filterFieldNames, (fieldName) => {
      const itemValue = useCaseSensitiveSearch
        ? item[fieldName]
        : item[fieldName].toLowerCase();
      if (itemValue.includes(filterValue)) {
        filteredListItems.push(item);
        return false;
      }
      return true;
    });
  });
  return filteredListItems;
};

/**
 * @deprecated
 * It takes an array of objects, a string to filter by, a string or array of strings to filter by, and
 * a boolean to determine if the search should be case sensitive
 * @param allItems - The list of items to filter
 * @param inputValue - The value of the input field
 * @param filterFieldName - The name of the field in the list item that you want to filter by.
 * @param useCaseSensitiveSearch - boolean
 * @returns An array of objects that have a property that matches the inputValue.
 */
const filterListItems = (
  allItems,
  inputValue,
  filterFieldName,
  useCaseSensitiveSearch
) => {
  if (!allItems || !inputValue) {
    return [];
  }
  if (filterFieldName instanceof Array) {
    return filterListItemsByMultipleKeys(
      allItems,
      inputValue,
      filterFieldName,
      useCaseSensitiveSearch
    );
  }

  const filterValue = useCaseSensitiveSearch
    ? inputValue
    : inputValue.toLowerCase();
  const filteredList = allItems.filter((item) => {
    const itemValue = useCaseSensitiveSearch
      ? item[filterFieldName]
      : item[filterFieldName].toLowerCase();
    return itemValue.includes(filterValue);
  });

  return filteredList;
};

export default filterListItems;
