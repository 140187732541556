import React from 'react';
import PropTypes from 'prop-types';
import Cell from '@material-ui/core/TableCell';
import { withStyles } from 'client/component-library/styles';
import styles from './style';

/**
 * @deprecated
 * It's a React component that renders a table cell
 * @returns A table cell with the children, width, classes, and cellStyle props.
 */
const TableCell = ({ children, width, classes, cellStyle }) => {
  return (
    <Cell className={classes.tableCell} style={{ width, ...cellStyle }}>
      {' '}
      {children}{' '}
    </Cell>
  );
};

TableCell.propTypes = {
  cellStyle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
    PropTypes.object,
  ]),
  width: PropTypes.string,
};

TableCell.defaultProps = {
  cellStyle: {},
  children: '',
  width: '100px',
};

/** @deprecated */
export default withStyles(styles)(TableCell);
