import React from 'react';
import PropTypes from 'prop-types';
import MuiIcon from '@material-ui/core/Icon';
import iconStyles from './icon.css';

/**
 * @deprecated
 * It returns a Material UI icon if the family is material, otherwise it returns a Font Awesome icon
 * @param props - This is the props object that is passed to the component.
 * @returns A React component
 */
const Icon = (props) => {
  const { name, family, className, styles, color, dataTest } = props;
  if (family === 'material') {
    const normalizedName = name.replace(/[-\s]+/g, '_').toLowerCase();
    return (
      <MuiIcon
        className={className}
        style={styles}
        data-test={dataTest}
        color={`${color || 'inherit'}`}
      >
        {normalizedName}
      </MuiIcon>
    );
  }

  return (
    <i
      className={`${family} ${family}-${name} ${iconStyles[family]} ${className}`}
      aria-hidden="true"
      style={styles}
      data-test={dataTest}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  family: PropTypes.oneOf(['fa', 'admin-frontend-icon', 'material']),
  styles: PropTypes.object,
  color: PropTypes.string,
  dataTest: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  family: 'fa',
  styles: {},
  color: 'inherit',
  dataTest: '',
};

export default Icon;
