import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const SuspiciousUserSessions = React.lazy(
  () => import('./containers/SuspiciousUserSessions')
);
const AccountSharingDetectionDashboard = React.lazy(
  () => import('./containers/AccountSharingDetectionDashboard')
);

export const accountSharingDetectionRoutes: RouteObject = {
  path: 'account-sharing-detection',
  children: [
    {
      path: '/account-sharing-detection',
      element: (
        <Navigate to="/account-sharing-detection/account-sharing-dashboard" />
      ),
    },
    {
      path: 'account-sharing-dashboard',
      element: (
        <React.Suspense fallback={undefined}>
          <AccountSharingDetectionDashboard />
        </React.Suspense>
      ),
    },
    {
      path: 'suspicious-user-sessions',
      element: (
        <React.Suspense fallback={undefined}>
          <SuspiciousUserSessions />
        </React.Suspense>
      ),
    },
  ],
};
