import get from 'lodash/get';
import {
  updateSuggestionsHelper,
  updateInputInfoTermId,
  updateSuggestionTypeError,
} from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/autocomplete-input';
import { setFailureNotification } from 'client/modules/common/redux/actions/status-notification';
import { getMultiKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import { parseInvestorPersonSearchResults } from 'client/modules/cbi-entity/redux/utils/entity-helpers';
import { ltrim } from 'client/modules/common/utils/truncateString';
import { entityType, suggestionTypes } from '../utils/constants';
import {
  entitySearchRequest,
  investorPersonSearch,
} from '../utils/api-request-helper';

export const cbiEntitySuggestionHandler = (
  dispatch,
  getState,
  inputId,
  term,
  suggestionType
) => {
  if (suggestionType === suggestionTypes.INVESTORS) {
    const formattedTerm = ltrim(term);
    if (!formattedTerm) {
      return null;
    }

    return dispatch(entitySearchRequest(formattedTerm, entityType.INVESTOR))
      .then((results) => {
        const investorCategories = getState().cbiEntity.generalInfo
          .allInvestorCategoryValues;
        const suggestions = results.entitySearchResults.map((suggestion) => {
          if (suggestion.idInvestorCategory !== 0) {
            const investorCategory = investorCategories.find(
              (value) =>
                value.idInvestorCategory === suggestion.idInvestorCategory
            );
            return {
              ...suggestion,
              investorCategoryName: get(investorCategory, 'name'),
            };
          }
          return suggestion;
        });
        const [termId, eType] = getMultiKeyValueFromListViaOtherKey(
          suggestions,
          'name',
          term,
          ['id']
        );
        if (termId) {
          dispatch(updateInputInfoTermId(inputId, term, termId, eType));
        }
        return dispatch(updateSuggestionsHelper(term, inputId, suggestions));
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Something went wrong: ${error}`)
        );
      });
  } if (suggestionType === suggestionTypes.BOARD_OF_DIRECTORS) {
    return dispatch(investorPersonSearch(term))
      .then((results) => {
        const suggestions = results.investorPerson;
        const [termId, eType] = parseInvestorPersonSearchResults(
          suggestions,
          term
        );
        if (termId) {
          dispatch(updateInputInfoTermId(inputId, term, termId, eType));
        }
        return dispatch(updateSuggestionsHelper(term, inputId, suggestions));
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Something went wrong: ${error}`)
        );
      });
  } if (suggestionType === suggestionTypes.BOD_FIRMS) {
    const formattedTerm = ltrim(term);
    if (!formattedTerm) {
      return null;
    }
    return dispatch(entitySearchRequest(formattedTerm, entityType.INVESTOR))
      .then((results) => {
        const suggestions = results.entitySearchResults;
        const [termId, eType] = getMultiKeyValueFromListViaOtherKey(
          suggestions,
          'name',
          term,
          ['id']
        );
        if (termId) {
          dispatch(updateInputInfoTermId(inputId, term, termId, eType));
        }
        return dispatch(updateSuggestionsHelper(term, inputId, suggestions));
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Something went wrong: ${error}`)
        );
      });
  } if (suggestionType === suggestionTypes.ENTITIES) {
    const formattedTerm = ltrim(term);
    if (!formattedTerm) {
      return null;
    }
    return dispatch(entitySearchRequest(formattedTerm, entityType.ENTITY))
      .then((results) => {
        const suggestions = results.entitySearchResults;
        const [termId, eType] = getMultiKeyValueFromListViaOtherKey(
          suggestions,
          'name',
          term,
          ['id']
        );
        if (termId) {
          dispatch(updateInputInfoTermId(inputId, term, termId, eType));
        }
        return dispatch(updateSuggestionsHelper(term, inputId, suggestions));
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Something went wrong: ${error}`)
        );
      });
  }
  return dispatch(updateSuggestionTypeError());
};

export default cbiEntitySuggestionHandler;
