import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import ResetPassword from './ResetPassword';
import ImpersonateUser from './ImpersonateUser';
import useUserPermissions from '../hooks/useUserPermissions';
import ResetWelcomeEmail from './ResetWelcomeEmail';

type Actions = 'ResetPassword' | 'LoginAsUser' | 'ResetWelcomeEmail';
type Props = {
  email: string;
  idUser: number;
};

export const UserActions = ({ email, idUser }: Props) => {
  const [showAction, setShowAction] = useState<Actions>(null);

  const handleClose = () => {
    setShowAction(null);
  };

  const { hasLoginAsUserPermission } = useUserPermissions();

  return (
    <>
      <div>
        <Tooltip
          title={
            hasLoginAsUserPermission
              ? null
              : 'You do not have permission to login as user'
          }
        >
          <Button
            disabled={!hasLoginAsUserPermission}
            className="mr-2"
            onClick={() => {
              setShowAction('LoginAsUser');
            }}
          >
            Login as User
          </Button>
        </Tooltip>
        <Button
          className="mr-2"
          onClick={() => {
            setShowAction('ResetPassword');
          }}
        >
          Reset Password
        </Button>
        <Tooltip title="Resending the welcome email will invalidate any existing reset password links.">
          <Button
            onClick={() => {
              setShowAction('ResetWelcomeEmail');
            }}
          >
            Resend Welcome Email
          </Button>
        </Tooltip>
      </div>
      <ResetPassword
        email={email}
        handleClose={handleClose}
        isOpen={showAction === 'ResetPassword'}
      />
      <ImpersonateUser
        email={email}
        handleClose={handleClose}
        isOpen={showAction === 'LoginAsUser'}
      />
      <ResetWelcomeEmail
        handleClose={handleClose}
        isOpen={showAction === 'ResetWelcomeEmail'}
        idUser={idUser}
      />
    </>
  );
};

export default UserActions;
