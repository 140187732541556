// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.3
// source: cbinsights/adminpermissionservice/adminpermissionservice.proto

/* eslint-disable */

export const protobufPackage = "adminpermissionservice";

/** version 2.3.0 */

export enum PermissionType {
  NO_ACCESS = "NO_ACCESS",
  READ_ONLY = "READ_ONLY",
  DRAFT = "DRAFT",
  PUBLISH = "PUBLISH",
  ACCESS = "ACCESS",
  AUDIT = "AUDIT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DraftTypeFilter {
  ENTITY_DRAFT = "ENTITY_DRAFT",
  FUNDING_DRAFT = "FUNDING_DRAFT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DraftStatusFilter {
  NO_STATUS = "NO_STATUS",
  READY_FOR_ENRICHMENT = "READY_FOR_ENRICHMENT",
  READY_FOR_QA = "READY_FOR_QA",
  PUBLISHED = "PUBLISHED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum TeamFilter {
  NO_TEAM = "NO_TEAM",
  CURRENT_TEAM = "CURRENT_TEAM",
  ALL_TEAMS = "ALL_TEAMS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface Empty {
}

export interface SuccessStatus {
  success: boolean;
}

export interface AdminUser {
  idUser: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AdminRole {
  idAdminRole: number;
  adminRole: string;
}

export interface AdminTeam {
  idAdminTeam: number;
  adminTeam: string;
}

export interface AdminPage {
  idAdminPage: number;
  adminPage: string;
  adminSections: AdminSection[];
  permissionTypes: PermissionType[];
}

export interface AdminSection {
  idAdminSection: number;
  adminSection: string;
  permissionTypes: PermissionType[];
}

export interface AdminNavPageInfo {
  idPage: number;
  title: string;
  url: string;
  section: string;
  isNewAdmin: number;
}

export interface UserAdminRoles {
  idUser: number;
  adminRoles: AdminRole[];
}

export interface UserAdminTeams {
  idUser: number;
  adminTeams: AdminTeam[];
}

export interface DraftQueueAdminPageDetail {
  draftTypeFilter: DraftTypeFilter[];
  draftStatusFilter: DraftStatusFilter[];
  teamFilter: TeamFilter[];
  draftSources: number[];
}

export interface GetAdminRoleInfoRequest {
  idAdminRole: number;
}

export interface GetAdminRolesRequest {
  limit: number;
  offset: number;
}

export interface GetAdminUsersRequest {
  idAdminRolesFilter: number[];
  searchTerm: string;
  idUsersFilter: number[];
  idAdminTeamsFilter: number[];
}

export interface CreateAdminRoleRequest {
  adminRole: AdminRole | undefined;
  adminUsers: AdminUser[];
  adminPages: AdminPage[];
  draftQueueAdminPageDetail: DraftQueueAdminPageDetail | undefined;
}

export interface UpdateAdminRoleRequest {
  adminRole: AdminRole | undefined;
  adminUsers: AdminUser[];
  adminPages: AdminPage[];
  idUsersToDelete: number[];
  draftQueueAdminPageDetail: DraftQueueAdminPageDetail | undefined;
}

export interface DeleteAdminRoleRequest {
  idAdminRole: number;
}

export interface DeleteAdminTeamRequest {
  idAdminTeam: number;
}

export interface ValidatePermissionsByUserRequest {
  idAdminPage: number;
  idAdminSection: number;
  permissionTypes: PermissionType[];
}

export interface GetAdminTeamsRequest {
  limit: number;
  offset: number;
}

export interface GetAdminTeamInfoRequest {
  idAdminTeam: number;
}

export interface CreateAdminTeamRequest {
  adminTeam: AdminTeam | undefined;
  adminUsers: AdminUser[];
}

export interface UpdateAdminTeamRequest {
  adminTeam: AdminTeam | undefined;
  idUsersToAdd: number[];
  idUsersToDelete: number[];
}

export interface SetAdminInfoForAdminUserRequest {
  idUser: number;
  idAdminRolesToAdd: number[];
  idAdminRolesToDelete: number[];
  idAdminTeamsToAdd: number[];
  idAdminTeamsToDelete: number[];
}

export interface GetAdminNavigationResponse {
  sections: string[];
  adminPageInfo: AdminNavPageInfo[];
}

export interface GetAdminRoleInfoResponse {
  adminRole: AdminRole | undefined;
  adminPages: AdminPage[];
  adminUsers: AdminUser[];
  draftQueueAdminPageDetail: DraftQueueAdminPageDetail | undefined;
}

export interface GetAdminRolesResponse {
  adminRoles: AdminRole[];
  idAdminRoleToUserCount: { [key: number]: number };
}

export interface GetAdminRolesResponse_IdAdminRoleToUserCountEntry {
  key: number;
  value: number;
}

export interface GetAdminTeamInfoResponse {
  adminTeam: AdminTeam | undefined;
  adminUsers: AdminUser[];
}

export interface GetAdminTeamsResponse {
  adminTeams: AdminTeam[];
  idAdminTeamToUserCount: { [key: number]: number };
}

export interface GetAdminTeamsResponse_IdAdminTeamToUserCountEntry {
  key: number;
  value: number;
}

export interface GetAdminPagesResponse {
  adminPages: AdminPage[];
}

export interface GetAdminPermissionsForUserResponse {
  adminPages: AdminPage[];
  draftQueueAdminPageDetail: DraftQueueAdminPageDetail | undefined;
}

export interface GetAdminUsersResponse {
  adminUsers: AdminUser[];
  idUserToAdminRoles: { [key: number]: UserAdminRoles };
  idUserToAdminTeams: { [key: number]: UserAdminTeams };
}

export interface GetAdminUsersResponse_IdUserToAdminRolesEntry {
  key: number;
  value: UserAdminRoles | undefined;
}

export interface GetAdminUsersResponse_IdUserToAdminTeamsEntry {
  key: number;
  value: UserAdminTeams | undefined;
}

export interface IdAdminRoleResponse {
  idAdminRole: number;
}

export interface CreateAdminTeamResponse {
  idAdminTeam: number;
}
