import { RecordEventRequest } from '@cbinsights/usereventsservice/usereventsservice';
import { request } from 'client/modules/common/utils/request';

export const recordEvent = async (
  recordEventRequest: Partial<RecordEventRequest>
) => {
  await request({
    url: '/service/usereventsservice/RecordEvent',
    body: recordEventRequest,
  });
};
