import { AppState } from 'client/root-reducer';
import { shallowEqual, useSelector } from 'react-redux';

export const useNewsQueueSelector = () => {
  const userData = useSelector(
    ({ user }: AppState) => ({
      user: user?.data,
    }),
    shallowEqual
  );
  return userData;
};
