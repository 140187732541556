import { ENTITY_ADMIN_PERMISSIONS } from 'client/modules/cbi-entity/permissions/EntityAdmin';
import { useLocation } from 'react-router-dom';

export const evalEntityType = ({
  isCompany,
  isInvestor,
  sectionId,
  isDraft,
}: {
  isCompany: boolean;
  isInvestor: boolean;
  isDraft: boolean;
  sectionId: number;
}): boolean => {
  if ([2, 8].some((id) => id === sectionId)) {
    if (sectionId === 2 && isDraft) {
      return false;
    }
    return isCompany === false;
  }
  if (sectionId === 3) {
    return isInvestor === false;
  }
  if ([4, 5].some((id) => id === sectionId)) {
    return isCompany === false && isInvestor === false;
  }
  return false;
};
export type SectionBlockedForEntityInfo = {
  isSectionBlocked: boolean;
  sectionId: number;
};
export const useSectionBlockedForEntityInfo = (entityInfo: {
  isInvestor: boolean;
  isCompany: boolean;
  isDraft: boolean;
}): SectionBlockedForEntityInfo => {
  const location = useLocation();
  const { sectionId } = Object.keys(ENTITY_ADMIN_PERMISSIONS.sections)
    .map((el) => ENTITY_ADMIN_PERMISSIONS.sections[el])
    .filter((section) => location.pathname.includes(section.path))[0];

  return {
    isSectionBlocked: evalEntityType({ ...entityInfo, sectionId }),
    sectionId,
  };
};
