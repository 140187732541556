export const SET_ARTICLE_CONTENT =
  'client/modules/markets/actions/article-content/SET_ARTICLE_CONTENT';
export const SET_SELECTED_MARKET_ROW_ID =
  'client/modules/markets/actions/article-content/SET_SELECTED_MARKET_ROW_ID';

export const setArticleContent = (idNews) => ({
  types: [null, SET_ARTICLE_CONTENT],
  service: {
    endpoint: 'getNewsArticle',
    name: 'marketservice',
    body: {
      idNews: parseInt(idNews, 10) || 0,
    },
  },
});

export const setSelectedArticleMarketRowId = (idRecord) => {
  return { type: SET_SELECTED_MARKET_ROW_ID, idRecord };
};
