import {
  GetUserRequest,
  GetUserResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUser = (
  req: Partial<GetUserRequest>
): Promise<GetUserResponse> =>
  request({
    url: '/service/adminservice/GetUser',
    body: req,
  }).then((r) => r.body);
