import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ROUTES } from './constants';

const ServiceProviderNav = React.lazy(
  () => import('./components/ServiceProviderNav')
);

const ServiceProviderProfile = React.lazy(
  () => import('./components/ServiceProviderProfile')
);

const { root, profilePage } = ROUTES;

export const serviceProviderRoutes: RouteObject = {
  path: root.path,
  element: (
    <React.Suspense fallback={undefined}>
      <ServiceProviderNav />
    </React.Suspense>
  ),
  children: [
    {
      path: `${profilePage.path}/*`,
      element: (
        <React.Suspense fallback={undefined}>
          <ServiceProviderProfile />
        </React.Suspense>
      ),
    },
  ],
};
