import cbiUtils, {
  handleAsyncErrorWithRedirect,
} from 'client/modules/common/utils/cbi';

/**
 * Taxonomy action types
 */

export const SET_SECTORS = 'cbi/common/SET_SECTORS';
export const SET_INDUSTRIES = 'cbi/common/SET_INDUSTRIES';
export const SET_SUB_INDUSTRIES = 'cbi/common/SET_SUB_INDUSTRIES';

/**
 * Taxonomy reducer
 */

export default function (
  state = {
    sectors: [],
    industries: [],
    subIndustries: [],
  },
  action
) {
  switch (action.type) {
    case SET_SECTORS:
      return { ...state, sectors: action.payload };
    case SET_INDUSTRIES:
      return { ...state, industries: action.payload };
    case SET_SUB_INDUSTRIES:
      return { ...state, subIndustries: action.payload };
    default:
      return state;
  }
}

/**
 * Taxonomy action functions
 */
export const fetchSectors = () => (dispatch, getState) => {
  const state = getState();

  if (state.taxonomy.sectors.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/taxonomy/sectors',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_SECTORS,
        payload: json.sectors,
      });
    });
};

export const fetchIndustries = () => (dispatch, getState) => {
  const state = getState();

  if (state.taxonomy.industries.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/taxonomy/industries',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_INDUSTRIES,
        payload: json.industries,
      });
    });
};

export const fetchSubIndustries = () => (dispatch, getState) => {
  const state = getState();

  if (state.taxonomy.subIndustries.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/taxonomy/subIndustries',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_SUB_INDUSTRIES,
        payload: json.subindustries,
      });
    });
};
