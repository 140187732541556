import { reduce, values, sortBy, keyBy, omit, filter } from 'lodash';
import { createUserRecordCellStyles } from 'client/modules/data-platform/components/Table/utils/records-helper';
import createFullName from 'client/modules/common/utils/createFullName';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';

export const REMOVE_USER_TYPE = 'Remove';
export const ADD_USER_TYPE = 'Add';

const userReducer = (result, user) => {
  const pbUser = omit(user, ['fullName', 'actionType']);
  const newResult = result;
  if (user.actionType === ADD_USER_TYPE) {
    newResult.addUsers = newResult.addUsers.concat([pbUser]);
  } else if (user.actionType === REMOVE_USER_TYPE) {
    newResult.removeUsers = newResult.removeUsers.concat([pbUser]);
  }
  return newResult;
};

export const mapCandidatesToAddRemoveVertexes = (candidates) => {
  return reduce(candidates, userReducer, { addUsers: [], removeUsers: [] });
};

export const mapAdminServiceUsersHelper = (users) => {
  return reduce(
    users,
    (results, user) => {
      if (typeof user.isActive === 'undefined' || user.isActive) {
        results.push({
          idUser: user.idUser,
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.email,
          fullName: createFullName(user.firstName, user.lastName, user.email),
          actionType: ADD_USER_TYPE,
        });
      }
      return results;
    },
    []
  );
};

export const setDisplayVals = ({ object, pageNumber, recordsPerPage }) => {
  const objectLength = Object.keys(object).length;
  let offset = pageNumber * recordsPerPage;
  if (offset >= objectLength) {
    offset = Math.floor(objectLength / recordsPerPage) - 1; // jumps back to beginning of last page
  }
  const objectSlice = sortBy(values(object), 'fullName').slice(
    offset,
    offset + recordsPerPage
  );
  const objectRecords = keyBy(objectSlice, 'idUser');
  const objectRecordOrder = Object.keys(objectRecords);
  const objectRecordCellStyle = createUserRecordCellStyles(objectRecords);
  return {
    objectRecords,
    objectRecordOrder,
    objectRecordCellStyle,
    objectLength,
  };
};

export const resetPageNumber = (numRecords, pageNumber, recordsPerPage) => {
  if (numRecords === 0) {
    return 0;
  }
  const offset = pageNumber * recordsPerPage;
  if (offset >= numRecords) {
    return pageNumber - 1;
  }
  return pageNumber;
};

export const filterAdminUsers = ({
  adminUsers,
  pipelineUsers,
  newUsers,
  filterTerm,
}) => {
  let tempAdminUsers = values(adminUsers);
  if (filterTerm !== '') {
    tempAdminUsers = filterListOfObjects({
      value: filterTerm,
      listOfItems: values(adminUsers),
      fields: ['fullName', 'emailAddress'],
    });
  }
  if (Object.keys(pipelineUsers).length) {
    tempAdminUsers = filter(
      tempAdminUsers,
      (x) => !(pipelineUsers[x.idUser] || newUsers[x.idUser])
    );
  }
  return keyBy(tempAdminUsers, 'idUser');
};
