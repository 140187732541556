import { SET_CURRENT_TAB } from '../actions/tab';

const initialState = 'generalInfo';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return action.currentTab;
    }
    default:
      return state;
  }
}
