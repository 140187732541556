import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  outerStyle,
  headerStyle,
  modalHeaderTextStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import ExcludeLabelsSelect from './ExcludeLabelsSelect';

class GraphDisplaySettingsSideBar extends Component {
  addExcludeLabel = (event) => {
    this.props.addExcludeLabel(event.target);
  };

  render() {
    return (
      <div style={outerStyle}>
        <div className="flex flex-col">
          <div className="flex flex-shrink">
            <div style={headerStyle}>
              <div className="flex">
                <div>
                  <p style={modalHeaderTextStyle}>Graph Display Settings</p>
                </div>
              </div>
            </div>
          </div>
          <ExcludeLabelsSelect
            excludeLabels={this.props.excludeLabels}
            addExcludeLabel={this.props.addExcludeLabel}
            clearExcludeLabels={this.props.clearExcludeLabels}
            allExcludeLabels={this.props.allExcludeLabels}
            filter={this.props.filter}
          />
        </div>
      </div>
    );
  }
}

GraphDisplaySettingsSideBar.propTypes = {
  addExcludeLabel: PropTypes.func.isRequired,
  clearExcludeLabels: PropTypes.func.isRequired,
  allExcludeLabels: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  excludeLabels: PropTypes.arrayOf(PropTypes.string),
};

GraphDisplaySettingsSideBar.defaultProps = {
  excludeLabels: [],
};

export default GraphDisplaySettingsSideBar;
