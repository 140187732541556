const styles = (theme) => {
  return {
    dpTabs: {
      boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.2)',
      height: '84px',
    },
    tabsRoot: {
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
    },
    tab: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    label: {
      fontSize: '19px',
      fontFamily: 'Roboto',
    },
    textColorPrimary: {
      color: '#404040',
    },
    selected: {
      color: '#006699',
    },
    containedPrimary: {
      backgroundColor: '#F57C00',
      color: 'white',
    },
  };
};

export default styles;
