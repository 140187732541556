import {
  GetAdminFinancialDataRequest,
  GetAdminFinancialDataResponse,
  GetEntityForSymbolAndExchangeRequest,
  GetEntityForSymbolAndExchangeResponse,
  GetTickersForExchangeRequest,
  GetTickersForExchangeResponse,
  ListExchangesResponse,
  ListQuartrCompaniesResponse,
} from '@cbinsights/publicfinancialservice/publicfinancialservice';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import {
  createCbiEntityEODMapping,
  deleteCbiEntityEODMappings,
  getAdminFinancialData,
  getEntityForSymbolAndExchange,
  getGetTickersForExchange,
  listExchanges,
  listQuartrCompanies,
  updateCbiEntityEODMapping,
} from '../api/publicFinancialService';

export const PublicFinancialServiceKeys = {
  GetLastDayFinancialData: 'GetLastDayFinancialData',
  GetTickersForExchange: 'GetTickersForExchange',
  ListExchanges: 'ListExchanges',
  GetEntityForSymbolAndExchange: 'GetEntityForSymbolAndExchange',
  GetAdminFinancialData: 'GetAdminFinancialData',
  ListQuartrCompanies: 'ListQuartrCompanies',
} as const;

type PublicFinancialServiceType = keyof typeof PublicFinancialServiceKeys;

export const useGetAdminFinancialData = ({
  idCbiEntity,
}: GetAdminFinancialDataRequest): UseQueryResult<
  GetAdminFinancialDataResponse,
  Error
> =>
  useQuery<
    GetAdminFinancialDataResponse,
    Error,
    GetAdminFinancialDataResponse,
    [PublicFinancialServiceType, number]
  >(
    [PublicFinancialServiceKeys.GetAdminFinancialData, idCbiEntity],
    () => getAdminFinancialData({ idCbiEntity }),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: !!idCbiEntity,
    }
  );

export const useGetTickersForExchange = ({
  exchangeShortCode,
  enabled,
}: GetTickersForExchangeRequest & { enabled: boolean }): UseQueryResult<
  GetTickersForExchangeResponse,
  Error
> =>
  useQuery<
    GetTickersForExchangeResponse,
    Error,
    GetTickersForExchangeResponse,
    [PublicFinancialServiceType, string]
  >(
    [PublicFinancialServiceKeys.GetTickersForExchange, exchangeShortCode],
    () => getGetTickersForExchange({ exchangeShortCode }),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );

export const useGetEntityForSymbolAndExchange = (
  request: GetEntityForSymbolAndExchangeRequest
): UseQueryResult<GetEntityForSymbolAndExchangeResponse, Error> =>
  useQuery<
    GetEntityForSymbolAndExchangeResponse,
    Error,
    GetEntityForSymbolAndExchangeResponse,
    [PublicFinancialServiceType, GetEntityForSymbolAndExchangeRequest]
  >(
    [PublicFinancialServiceKeys.GetEntityForSymbolAndExchange, request],
    () => getEntityForSymbolAndExchange(request),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: !!request.symbol && !!request.exchangeShortCode,
    }
  );

export const useListExchanges = ({
  enabled = false,
}: {
  enabled: boolean;
}): UseQueryResult<ListExchangesResponse, Error> =>
  useQuery<
    ListExchangesResponse,
    Error,
    ListExchangesResponse,
    [PublicFinancialServiceType]
  >([PublicFinancialServiceKeys.ListExchanges], () => listExchanges(), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
  });

export const useListQuartrCompanies = ({
  enabled = false,
}: {
  enabled: boolean;
}): UseQueryResult<ListQuartrCompaniesResponse, Error> =>
  useQuery<
    ListQuartrCompaniesResponse,
    Error,
    ListQuartrCompaniesResponse,
    [PublicFinancialServiceType]
  >(
    [PublicFinancialServiceKeys.ListQuartrCompanies],
    () => listQuartrCompanies(),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );

export const useCreateCbiEntityEODMapping = () =>
  useMutation(createCbiEntityEODMapping);

export const useUpdateCbiEntityEODMappings = () =>
  useMutation(updateCbiEntityEODMapping);

export const useDeleteCbiEntityEODMappings = () =>
  useMutation(deleteCbiEntityEODMappings);
