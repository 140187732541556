import React from 'react';

export type DataCellProps = {
  children?: React.ReactNode;
  className?: string;
  borderPosition?: 'start' | 'end' | 'between' | 'all' | 'none';
  colSpan?: number;
  padding?: 'vertical' | 'horizontal' | 'all' | 'none';
};
export const DataCell = ({
  children,
  className = '',
  borderPosition = 'none',
  padding = 'vertical',
}: DataCellProps) => {
  return (
    <div
      className={`${className} border-gray-200 ${
        padding === 'vertical' ? 'py-2' : ''
      } ${padding === 'all' ? 'p-2' : ''} ${
        padding === 'horizontal' ? 'px-2' : ''
      } ${borderPosition === 'start' ? 'border-t border-b border-l' : ''} ${
        borderPosition === 'end' ? 'border-t border-b border-r' : ''
      } ${borderPosition === 'between' ? 'border-t border-b ' : ''} ${
        borderPosition === 'all' ? 'border' : ''
      }
      `}
    >
      {children}
    </div>
  );
};
