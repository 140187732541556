import { requestAllCompanyEmployees } from '../utils/api-request-helper';

const EXTERNAL_ADMIN_PACKAGE = 651;

export const FETCH_ALL_COMPANY_EMPLOYEES =
  'client/modules/admin-user-admin/actions/FETCH_ALL_COMPANY_EMPLOYEES';

export const fetchAllCompanyEmployeesHelper = (employeeInfo) => {
  return { type: FETCH_ALL_COMPANY_EMPLOYEES, employeeInfo };
};

export const fetchAllCompanyEmployees = () => {
  return (dispatch, getState) => {
    if (getState().adminUsers.companyEmployees.length) {
      return null;
    }
    const dataToPost = {
      includeUserFilters: [
        {
          domainFilter: {
            domains: ['cbinsights.com'],
          },
        },
        {
          idPackageFilter: {
            idPackages: [EXTERNAL_ADMIN_PACKAGE],
          },
          operation: 'OR',
        },
      ],
    };
    return dispatch(requestAllCompanyEmployees(dataToPost)).then((data) => {
      dispatch(fetchAllCompanyEmployeesHelper(data.userInfo));
    });
  };
};
