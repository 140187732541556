import React, { ReactElement, useState } from 'react';
import { Button, Empty, Modal, Select, Spin, Tag } from 'antd';
import { useAddUserForm } from '../../hooks/useAddUserForm';
import AddNewUsers from './AddNewUsers';

type Props = {
  idTeam: number;
  refetchTeamMembers: () => void;
};

export const AddExistingUser = ({
  idTeam,
  refetchTeamMembers,
}: Props): ReactElement => {
  const {
    onChangeSearch,
    userOptions,
    onSelect,
    onDeselect,
    selectedUser,
    addUsers,
    resetSelection,
    addUserMutation,
    getUsersQuery,
    searchTerm,
  } = useAddUserForm(idTeam);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenAddNewUser, setIsOpenAddNewUser] = useState(false);

  const openAddNewUserModal = () => {
    setIsModalOpen(false);
    setIsOpenAddNewUser(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    resetSelection();
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    await addUsers();
    hideModal();
    refetchTeamMembers();
    getUsersQuery.refetch();
  };

  let notFoundContent = null;
  if (searchTerm.length > 2) {
    notFoundContent = getUsersQuery.isLoading ? (
      <Spin size="small" />
    ) : (
      <Empty />
    );
  }

  return (
    <div className="w-full">
      <Button onClick={showModal} className="float-left">
        Add user to Team
      </Button>
      <AddNewUsers
        open={isOpenAddNewUser}
        onClose={() => setIsOpenAddNewUser(false)}
        idTeam={idTeam}
      />
      <Modal
        title="Find Users"
        open={isModalOpen}
        onOk={hideModal}
        onCancel={hideModal}
        width={1000}
        footer={
          <div className="flex justify-between items-center">
            <div className="ml-5">
              <Button onClick={openAddNewUserModal} type="link">
                Create New User/s
              </Button>
            </div>
            <div>
              <Button onClick={hideModal} loading={addUserMutation.isLoading}>
                Cancel
              </Button>
              <Button
                onClick={handleOk}
                disabled={selectedUser?.length === 0}
                loading={addUserMutation.isLoading}
              >
                Add Users to Team
              </Button>
            </div>
          </div>
        }
      >
        <div className="p-8">
          <Select
            mode="multiple"
            className="w-full"
            showSearch
            placeholder="+ User to Team"
            filterOption={false}
            optionLabelProp="label"
            notFoundContent={notFoundContent}
            onSearch={onChangeSearch}
            onSelect={onSelect}
            onDeselect={onDeselect}
            value={selectedUser}
          >
            {userOptions.map((item) => (
              <Select.Option
                key={item.value}
                value={item.value}
                label={item.label}
              >
                <div>
                  <div className="flex justify-between">
                    <span>{item.name}</span>
                    <span>{item.label}</span>
                  </div>
                  <div className="flex">
                    {item.name && <div>Team: {item.team}</div>}
                    {item.teamId === idTeam && (
                      <div className="ml-2">
                        <Tag color="orange">Already in the team</Tag>
                      </div>
                    )}
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default AddExistingUser;
