import { combineReducers } from 'redux';
import search from './search';
import tab from './tab';
import details from './details';
import users from './users';

const dataPlatform = combineReducers({
  search,
  tab,
  details,
  users,
});

export default dataPlatform;
