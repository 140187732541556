export const GET_ALL_TEAMS = 'client/modules/stories/actions/GET_ALL_TEAMS';

export const getAllTeamsRequest = () => ({
  types: [null, GET_ALL_TEAMS],
  service: {
    endpoint: 'GetAllTeams',
    name: 'adminservice',
  },
});

export const getAllTeams = () => {
  return (dispatch, getState) => {
    const teams = getState().stories.allTeams;
    if (teams.length) {
      return Promise.resolve();
    }
    return dispatch(getAllTeamsRequest());
  };
};
