import {
  GetPasswordResetTokenRequest,
  GetPasswordResetTokenResponse,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const getPasswordResetToken = (
  req: Partial<GetPasswordResetTokenRequest>
): Promise<GetPasswordResetTokenResponse> =>
  request({
    url: '/service/userv2service/GetPasswordResetToken',
    body: req,
  }).then((r) => r.body);
