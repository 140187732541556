/* eslint-disable max-len */
import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import { MaterialUILink } from 'client/component-library/Link';
import {
  outerStyle,
  headerStyle,
  modalHeaderTextStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import { removePipelineVertexes } from '../redux/actions/search';
import { componentSideBarColumns } from './Table/utils/table-columns';
import HealthIcon from './HealthIcon';
import getLabelToSideBarProps from '../redux/utils/component-props-order-sidebar';

class ComponentSideBar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  removeVertex = () => {
    const {
      dispatch,
      graphElement,
      pipelineName,
      graphExcludeLabels,
    } = this.props;
    const cleanNode = [
      {
        id: graphElement.id,
        label: graphElement.label,
        health: 0,
        properties: [],
      },
    ];
    dispatch(
      removePipelineVertexes(cleanNode, pipelineName, graphExcludeLabels)
    );
  };

  render() {
    const {
      graphElement,
      sideBarRows,
      pipelineName,
      recordCellStyle,
    } = this.props;

    const recordOrder = getLabelToSideBarProps(
      graphElement.label,
      Object.keys(sideBarRows)
    );
    let displayLabel = '';
    if (
      !!graphElement.label &&
      !!graphElement.displayLabel &&
      !!graphElement.displayLabel.length
    ) {
      displayLabel = `${graphElement.label.toUpperCase()} - ${
        graphElement.displayLabel
      }`;
    } else if (graphElement.label) {
      displayLabel = `${graphElement.label.toUpperCase()}`;
    }

    return (
      <div style={outerStyle}>
        <div className="flex flex-col">
          <div className="flex flex-shrink">
            <div style={headerStyle}>
              {graphElement.label ? (
                <div>
                  <div className="flex">
                    <div className="flex flex-shrink justify-center">
                      <HealthIcon health={graphElement.health} />
                    </div>
                    <div>
                      <p style={modalHeaderTextStyle}>{displayLabel}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <p style={modalHeaderTextStyle}>
                  Hover over any node or link in the graph
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-shrink justify-start">
            <div style={{ overflowX: 'auto' }}>
              <Table>
                <TableHeader columnNames={componentSideBarColumns} />
                <TableBody
                  records={sideBarRows}
                  columnNames={componentSideBarColumns}
                  recordsOrder={recordOrder}
                  recordCellStyles={recordCellStyle}
                />
              </Table>
            </div>
          </div>
          <div>
            {graphElement.id &&
              pipelineName !== 'All Components in Data Platform' &&
              graphElement.label !== 'Link' && (
                <MaterialUILink
                  onClick={this.removeVertex}
                  style={{ padding: '15px' }}
                >
                  Remove From Pipeline
                </MaterialUILink>
              )}
          </div>
        </div>
      </div>
    );
  }
}

ComponentSideBar.propTypes = {
  sideBarRows: PropTypes.object.isRequired,
  graphElement: PropTypes.object,
  pipelineName: PropTypes.string.isRequired,
  recordCellStyle: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  graphExcludeLabels: PropTypes.arrayOf(PropTypes.string),
};

ComponentSideBar.defaultProps = {
  graphElement: {},
  recordCellStyle: {},
  graphExcludeLabels: [],
};

export default connect()(ComponentSideBar);
