import { ProgressProps } from 'antd';

export type Feature = {
  id: number;
  name: string;
  description?: string;
  usage: number;
  limit?: number;
  packageLimit: number;
  additionalQuota?: number;
};

const getFeatureLimit = (feature: Feature) => {
  const additionalQuota = feature.additionalQuota || 0;

  if (feature.limit) {
    return Math.min(feature.limit, feature.packageLimit) + additionalQuota;
  }

  if (feature.packageLimit) {
    return feature.packageLimit + additionalQuota;
  }

  return feature.limit + additionalQuota;
};

export const getProgressInformation = (
  feature: Feature
): ProgressProps & { usage: string; colorClassName: string } => {
  const usedLimit = getFeatureLimit(feature);

  const usage = `Used: ${feature.usage}`;

  if (usedLimit === 0) {
    return {
      percent: 100,
      status: 'exception',
      usage,
      colorClassName: 'text-cbi-red/80 font-semibold',
    };
  }

  if (!usedLimit) {
    return {
      percent: 100,
      status: 'success',
      usage: 'Unlimited',
      colorClassName: 'text-cbi-green/90 font-semibold',
    };
  }

  const percent = Math.floor((feature.usage / usedLimit) * 100);

  if (percent >= 100) {
    return {
      percent: 100,
      status: 'exception',
      usage,
      colorClassName: 'text-cbi-red/80 font-semibold',
    };
  }

  return {
    percent,
    status: 'normal',
    usage,
    colorClassName: 'text-gray-700',
  };
};
