import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const UpdateAccessByStory = React.lazy(
  () => import('./containers/UpdateAccessByStory')
);
const UpdateAccessByTeam = React.lazy(
  () => import('./containers/UpdateAccessByTeam')
);
const ManageSlugs = React.lazy(() => import('./containers/ManageSlugs'));

export const storiesRoutes: RouteObject = {
  path: 'stories',
  children: [
    {
      path: '/stories',
      element: <Navigate to="/stories/update-access-by-story" />,
    },
    {
      path: 'update-access-by-story',
      element: (
        <React.Suspense fallback={undefined}>
          <UpdateAccessByStory />
        </React.Suspense>
      ),
    },
    {
      path: 'update-access-by-team',
      element: (
        <React.Suspense fallback={undefined}>
          <UpdateAccessByTeam />
        </React.Suspense>
      ),
    },
    {
      path: 'manage-slugs',
      element: (
        <React.Suspense fallback={undefined}>
          <ManageSlugs />
        </React.Suspense>
      ),
    },
  ],
};
