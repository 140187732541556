import {
  AnnualReport,
  COI,
  Document,
} from '@cbinsights/thirdpartyadminservice/thirdpartyadminservice';
import { request } from 'client/modules/common/utils/request';

export const RECORD_TYPES = {
  COI: 'coi',
  AFR: 'annualReport',
} as const;

/* Map Document to endpoint */
export const ENDPOINT_META = {
  [RECORD_TYPES.COI]: {
    uri: 'GetCOI',
    idKey: 'idCOI',
  },
  [RECORD_TYPES.AFR]: {
    uri: 'GetAnnualReport',
    idKey: 'idAnnualReport',
  },
};

export type GetDocumentRecordResponse =
  | {
      docType: typeof RECORD_TYPES.COI;
      data: COI;
    }
  | {
      docType: typeof RECORD_TYPES.AFR;
      data: AnnualReport;
    };

export type GetDocumentRequest = {
  docType: typeof RECORD_TYPES.COI | typeof RECORD_TYPES.AFR;
  docId: string;
};

export const isCOI = (record: COI | AnnualReport | $TSFixMe): record is COI => {
  return (record as COI).idCOI !== undefined;
};

export const isDocument = (
  record: COI | AnnualReport | Document
): record is Document => {
  return (
    (record as Document).annualReport !== undefined ||
    (record as Document).coi !== undefined
  );
};

/* 3 endpoints with 3 different structures so normalizing it all here */
export const getDocumentRecord = ({
  docType,
  docId,
}: Partial<GetDocumentRequest>): Promise<GetDocumentRecordResponse> => {
  const { uri, idKey } = docType
    ? ENDPOINT_META[docType]
    : { uri: 'GetDocument', idKey: null };

  return request({
    url: `/service/thirdpartyadminservice/${uri}`,
    body: { [idKey]: docId },
  })
    .then((res) => res.body)
    .then((body: COI | AnnualReport | Document) => {
      if (isDocument(body)) {
        if (body?.annualReport) {
          return { docType: RECORD_TYPES.AFR, data: body?.annualReport };
        }
        if (body?.coi) {
          return { docType: RECORD_TYPES.COI, data: body?.coi };
        }
      } else {
        if (isCOI(body)) {
          return { docType: RECORD_TYPES.COI, data: body };
        }
        return { docType: RECORD_TYPES.AFR, data: body };
      }
      return null;
    });
};
