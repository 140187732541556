// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-assets-styles-___common-styles__adminPageHeaderText___AIVHm {
  height: 34px;
  width: 332px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["black"]};
  font-family: 'Roboto';
  font-size: 31px;
  font-weight: bold;
  line-height: 37px;
  margin: 20px 0px;
}

.client-assets-styles-___common-styles__afsInput___oyzcI {
  box-sizing: border-box !important;
}

.client-assets-styles-___common-styles__highlight___v4x2X {
  background-color: #feebc8 !important;
}

.client-assets-styles-___common-styles__afsInput___oyzcI[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  display: none !important;
  margin: 0;
}

.client-assets-styles-___common-styles__cleanInput___NiGEr {
  border: none !important;
  padding: 0px;
}

.client-assets-styles-___common-styles__cleanInput___NiGEr::-webkit-inner-spin-button,
.client-assets-styles-___common-styles__cleanInput___NiGEr::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-assets-styles-___common-styles__cleanInput___NiGEr:focus {
  outline: none;
}

.client-assets-styles-___common-styles__expandableTextArea___LTbPv {
  transition: all 0.3s ease-out;
  height: 25px !important;
}

.client-assets-styles-___common-styles__expandableTextArea___LTbPv:focus {
  height: 100px !important;
}
`, "",{"version":3,"sources":["webpack://./client/assets/styles/common-styles.css"],"names":[],"mappings":"AAGA;EACE,YAAY;EACZ,YAAY;EACZ,mDAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mCAAmC;EACnC,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value black from colors;\n\n.adminPageHeaderText {\n  height: 34px;\n  width: 332px;\n  color: black;\n  font-family: 'Roboto';\n  font-size: 31px;\n  font-weight: bold;\n  line-height: 37px;\n  margin: 20px 0px;\n}\n\n.afsInput {\n  box-sizing: border-box !important;\n}\n\n.highlight {\n  background-color: #feebc8 !important;\n}\n\n.afsInput[type='date']::-webkit-inner-spin-button {\n  -webkit-appearance: none !important;\n  display: none !important;\n  margin: 0;\n}\n\n.cleanInput {\n  border: none !important;\n  padding: 0px;\n}\n\n.cleanInput::-webkit-inner-spin-button,\n.cleanInput::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.cleanInput:focus {\n  outline: none;\n}\n\n.expandableTextArea {\n  transition: all 0.3s ease-out;\n  height: 25px !important;\n}\n\n.expandableTextArea:focus {\n  height: 100px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"black": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["black"]}`,
	"adminPageHeaderText": `client-assets-styles-___common-styles__adminPageHeaderText___AIVHm`,
	"afsInput": `client-assets-styles-___common-styles__afsInput___oyzcI`,
	"highlight": `client-assets-styles-___common-styles__highlight___v4x2X`,
	"cleanInput": `client-assets-styles-___common-styles__cleanInput___NiGEr`,
	"expandableTextArea": `client-assets-styles-___common-styles__expandableTextArea___LTbPv`
};
export default ___CSS_LOADER_EXPORT___;
