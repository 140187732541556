/* eslint-disable default-param-last */
/* eslint-disable func-names */
import { get, range } from 'lodash';
import * as actions from '../actions/general-info';
import { CURRENT_YEAR, EARLIEST_FOUNDED_YEAR } from '../utils/constants';

// Need this until we move the gen info tab away from redux;
let updateCounter = 0;

export const initialState = {
  isLoading: true,
  allStatusValues: [],
  taxonomyTree: [],
  allCompanyCategoryValues: [{ idCompanyCategory: 0, name: 'Select One' }],
  allInvestorCategoryValues: [{ idInvestorCategory: 0, name: 'Select One' }],
  allFoundedYears: [
    'Select One',
    ...range(CURRENT_YEAR, EARLIEST_FOUNDED_YEAR, -1),
  ],
  allCountryValues: [],
  allStatesForSelectedCountries: {},
  savedCiks: [], // these are the Ciks saved in the database. when we do an update we are reconciling on the frontend
  validationErrors: {},
  logo: {},
  entityInfo: {
    isNewEntity: false,
    idCompany: 0,
    idInvestor: 0,
    idCbiEntity: 0,
    idEntityDraft: '',
    idPrimaryCbiEntityType: '',
    addresses: [],
    ciks: [],
    description: '',
    edited: false,
    email: '',
    entityNameInfo: {
      companyName: '',
      investorName: '',
      primaryName: '',
      aliases: [],
    },
    founded: '',
    idChildEntities: [],
    idParentEntities: [],
    status: { idStatus: 0 },
    taxonomy: {
      category: {},
      industry: {},
      sector: {},
      subIndustry: {},
      investorCategory: {},
    },
    urlInfo: {
      primaryUrl: '',
      additionalUrls: [],
    },
    originalUrlInfo: {
      primaryUrl: '',
      additionalUrls: [],
    },
    parentEntities: [],
    childEntities: [],
  },
  isHydrated: false,
  personTitles: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_IS_LOADING_STATUS: {
      return { ...state, isLoading: action.isLoading };
    }
    case actions.SET_TITLE_VALUES: {
      return {
        ...state,
        personTitles: {
          boardTitles: get(
            action,
            'allTitleValues.personTitleMaps[2].personTitles',
            []
          ).map((person) => ({
            value: person.idPersonTitle,
            label: person.title,
            abbreviation: person.titleCode,
          })),

          workTitles: get(
            action,
            'allTitleValues.personTitleMaps[1].personTitles',
            []
          ).map((person) => ({
            value: person.idPersonTitle,
            label: person.title,
            abbreviation: person.titleCode,
          })),
        },
      };
    }
    case actions.MARK_AS_NEW_ENTITY: {
      // We may populate fields via duplicate functionality
      if (state.isHydrated) {
        return {
          ...state,
          isHydrated: false,
        };
      }

      return {
        ...state,
        entityInfo: {
          updateCounter: updateCounter++,
          ...initialState.entityInfo,
          isNewEntity: true,
          entityNameInfo: {
            ...initialState.entityInfo.entityNameInfo,
            primaryName:
              // preserves the searchTerm if the user is creating a new entity
              action.entityName || state.entityInfo.entityNameInfo.primaryName,
          },
        },
      };
    }
    case actions.SET_ENTITY_LOGO: {
      return { ...state, logo: action.logo };
    }
    case actions.SET_STATUS_VALUES: {
      return {
        ...state,
        allStatusValues: [
          ...initialState.allStatusValues,
          ...action.allStatusValues.allEntityStatusValues,
        ],
      };
    }
    case actions.SET_TAXONOMY_TREE: {
      return {
        ...state,
        taxonomyTree: action.taxonomyTree,
      };
    }
    case actions.SET_INVESTOR_CATEGORY_VALUES: {
      return {
        ...state,
        allInvestorCategoryValues: [
          ...initialState.allInvestorCategoryValues,
          ...action.allInvestorCategoryValues.allInvestorCategoryValues,
        ],
      };
    }
    case actions.SET_ALIAS_TYPE_VALUES: {
      return { ...state, allAliasTypeValues: action.allAliasTypeValues };
    }
    case actions.SET_COUNTRY_INFO: {
      return { ...state, allCountryValues: action.countries };
    }
    case actions.SET_STATE_INFO: {
      return {
        ...state,
        allStatesForSelectedCountries: {
          ...state.allStatesForSelectedCountries,
          [action.idCountry]: action.states,
        },
      };
    }
    case actions.HYDRATE_GENERAL_ENTITY_INFO: {
      return action.generalEntityInfo;
    }
    case actions.SET_GENERAL_ENTITY_INFO: {
      return {
        ...state,
        savedCiks: action.generalEntityInfo.ciks,
        entityInfo: {
          updateCounter: updateCounter++,
          ...action.generalEntityInfo.entityInfo,
          isDuplicate: action.isDuplicate,
          isNewEntity: action.isDuplicate,
          addresses: action.generalEntityInfo.addresses,
          idCbiEntity: action.isDuplicate
            ? 0
            : action.generalEntityInfo.idCbiEntity,
          idEntityDraft: '',
          idCompany: action.isDuplicate
            ? 0
            : action.generalEntityInfo.idCompany,
          idInvestor: action.isDuplicate
            ? 0
            : action.generalEntityInfo.idInvestor,
          idPrimaryCbiEntityType:
            action.generalEntityInfo.idPrimaryCbiEntityType,
          ciks: action.generalEntityInfo.ciks,
          description: action.generalEntityInfo.description,
          edited: false,
          email: action.generalEntityInfo.email,
          entityNameInfo: action.generalEntityInfo.entityNameInfo,
          idChildEntities: action.generalEntityInfo.idChildEntities,
          idParentEntities: action.generalEntityInfo.idParentEntities,
          status: action.generalEntityInfo.status,
          taxonomy: action.generalEntityInfo.taxonomy,
          urlInfo: action.generalEntityInfo.urlInfo,
          originalUrlInfo: action.generalEntityInfo.urlInfo,
          founded: action.generalEntityInfo.foundedYear || '',
          parentEntities: action.generalEntityInfo.parentEntities,
          childEntities: action.generalEntityInfo.childEntities,
          isCompany: action.generalEntityInfo.isCompany,
          isInvestor: action.generalEntityInfo.isInvestor,
        },
      };
    }
    case actions.UPDATE_ENTITY_PRIMARY_NAME: {
      return {
        ...state,
        entityInfo: {
          ...state.entityInfo,
          entityNameInfo: {
            ...state.entityInfo.entityNameInfo,
            primaryName: action.entityPrimaryName,
            companyName: action.entityPrimaryName,
            investorName: action.entityPrimaryName,
          },
        },
      };
    }
    case actions.SET_VALIDATION_ERRORS: {
      return {
        ...state,
        validationErrors: action.errors,
      };
    }
    case actions.RESET_ENTITY_INFO: {
      return {
        ...state,
        logo: initialState.logo,
        entityInfo: initialState.entityInfo,
      };
    }
    case actions.RESET_ENTITY_EDIT_STATUS: {
      return {
        ...state,
        entityInfo: {
          ...state.entityInfo,
          edited: false,
        },
      };
    }
    case actions.RESET_ENTITY_LOGO: {
      return {
        ...state,
        logo: initialState.logo,
      };
    }
    case actions.SET_LIST_ENTITY_DRAFTS: {
      return {
        ...state,
        savedCiks: action.entityDraftInfo.ciks,
        logo: { ...initialState.logo },
        entityInfo: {
          ...initialState.entityInfo,
          isNewEntity: true,
          idCbiEntity: action.entityDraftInfo.idCbiEntity,
          idEntityDraft: action.entityDraftInfo.idEntityDraft,
          idPrimaryCbiEntityType: action.entityDraftInfo.idPrimaryCbiEntityType,
          addresses: action.entityDraftInfo.addresses,
          ciks: action.entityDraftInfo.ciks,
          description: action.entityDraftInfo.description,
          email: action.entityDraftInfo.email,
          entityNameInfo: action.entityDraftInfo.entityNameInfo,
          status: action.entityDraftInfo.status,
          taxonomy: action.entityDraftInfo.taxonomy,
          urlInfo: action.entityDraftInfo.urlInfo ?? {
            ...initialState.urlInfo,
          },
          originalUrlInfo: action.entityDraftInfo.urlInfo ?? {
            ...initialState.originalUrlInfo,
          },
          founded: action.entityDraftInfo.foundedYear,
        },
      };
    }
    default:
      return state;
  }
}
