import { map, omit, pick } from 'lodash/fp';

import cbiUtils, {
  handleAsyncErrorWithRedirect,
} from 'client/modules/common/utils/cbi';

/**
 * Geo action types
 */

export const SET_COUNTRIES = 'cbi-site/common/SET_COUNTRIES';
export const SET_STATES = 'cbi-site/common/SET_STATES';
export const SET_CITIES = 'cbi-site/common/SET_CITIES';

/**
 * Geo reducer
 */

export default function (
  state = { countries: [], states: [], cities: [] },
  action
) {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        countries: map(omit(['idContinent']), action.payload),
      };
    case SET_STATES:
      return {
        ...state,
        states: map(pick(['idCountry', 'idState', 'state']), action.payload),
      };
    case SET_CITIES:
      return { ...state, cities: action.payload };
    default:
      return state;
  }
}

/**
 * Geo action functions
 */

export const fetchCountries = () => (dispatch, getState) => {
  const state = getState();

  if (state.geography.countries.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/geography/countries',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_COUNTRIES,
        payload: json,
      });
    });
};

export const fetchStates = () => (dispatch, getState) => {
  const state = getState();

  if (state.geography.states.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/geography/states',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_STATES,
        payload: json,
      });
    });
};

export const fetchCities = () => (dispatch, getState) => {
  const state = getState();

  if (state.geography.cities.length) {
    return Promise.resolve();
  }

  return cbiUtils
    .apiCall({
      url: '/geography/cities',
      method: 'GET',
      data: {},
    })
    .then((response) => {
      return dispatch(handleAsyncErrorWithRedirect(response));
    })
    .then((json) => {
      return dispatch({
        type: SET_CITIES,
        payload: json,
      });
    });
};
