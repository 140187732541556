import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Graph } from '@cbinsights/react-d3-graph';
import compose from 'recompose/compose';
import { keyBy } from 'lodash';

import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import IconButton from 'client/component-library/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Tooltip from 'client/component-library/Tooltip/Tooltip';
import SelectWithSearch from 'client/component-library/Select/SearchableSelect/SelectWithSearch';
import { createRecordCellStyles } from 'client/modules/data-platform/components/Table/utils/records-helper';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import styles from 'client/component-library/Input/input.css';

import { withReactRouter } from 'client/routes/withReactRouter';
import iconButtonStyle from './styles/material-ui/buttonStyle';

import DataPlatformWrapper from '../components/DataPlatformWrapper';
import ComponentSideBar from '../components/ComponentSideBar';
import AddVertex from '../components/AddVertex';
import ManagePermissions from '../components/ManagePermissions';
import {
  updateSearchTerm,
  fetchPipelineGraph,
  togglePipelineComponentSideBar,
  togglePipelineSettingsSideBar,
  toggleEmailAlerts,
  viewComponent,
  setComponent,
  setGraphExcludeLabels,
  addGraphExcludeLabel,
  clearGraphExcludeLabels,
  allGraphExcludeLabels,
  getAllIds,
} from '../redux/actions/search';
import { PIPELINE_GRAPH_CONFIG } from '../components/utils/get-graph-config';
import { graphContainerStyle } from '../components/styles/material-ui/dataPlatformCommonStyles';
import HealthIcon from '../components/HealthIcon';
import GraphDisplaySettingsSideBar from '../components/GraphDisplaySettingsSideBar';
import convertStringToArray from './utils/data_utils';
import { componentPropsColumns } from '../components/Table/utils/table-columns';

const { iconClassPossitionHack } = styles;

class PipelineGraph extends Component {
  toggleSideBarButtonStyle = {
    ...iconButtonStyle,
    backgroundColor: '#006699',
  };

  toggleMailOnButtonStyle = {
    ...iconButtonStyle,
    backgroundColor: '#006699',
  };

  toggleMailOffButtonStyle = {
    ...iconButtonStyle,
    backgroundColor: '#a2a0a0',
  };

  togglePipelineSettingsSideBarStyle = {
    ...iconButtonStyle,
    backgroundColor: '#006699',
  };

  constructor(props){
    super(props);
    this.state = {
      searchTerm: '',
      sideBarRows: {},
      graphConfig: PIPELINE_GRAPH_CONFIG,
      sideBarGraphElement: {},
    };
  }

  UNSAFE_componentWillMount() {
    const {queryParams} = this.props;
    let excludeLabels = this.props.graphExcludeLabels;
    if (queryParams.graphExcludeLabels && queryParams.graphExcludeLabels.length) {
      excludeLabels = convertStringToArray(queryParams.graphExcludeLabels);
      this.props.setGraphExcludeLabels(excludeLabels);
    }
    if (queryParams.pipelineName && queryParams.pipelineName.length) {
      this.fetchPipelineGraph(queryParams.pipelineName, excludeLabels);
    }
    if (queryParams.componentId && queryParams.componentId.length) {
      this.props.setComponent(queryParams.componentId);
    }
    this.props.getAllIds({ labels: ['pipeline'] });
  }

  onClickNode = (nodeId) => {
    this.props.viewComponent(nodeId, this.props.navigation);
  };

  setSearchTerm = (e) => {
    this.setState({ searchTerm: e.id });
    this.props.updateSearchTerm(e.id.replace('-pipeline', ''));
  };

  fetchPipelineGraph = (pipelineName, excludeLabels) => {
    this.props.fetchPipelineGraph(pipelineName, excludeLabels);
    updateUrlParams('pipelineName', pipelineName);
    updateUrlParams('graphExcludeLabels', excludeLabels);
  };

  searchTermFetchPipelineGraph = () => {
    this.fetchPipelineGraph(
      this.props.searchTerm,
      this.props.graphExcludeLabels
    );
  };

  defaultFetchPipelineGraph = () => {
    this.fetchPipelineGraph(
      this.props.pipelineName,
      this.props.graphExcludeLabels
    );
  };

  handleMouseOverNode = (nodeId) => {
    // TODO we could add more info here but for now it is mostly a place holder for control plane
    const node = this.props.nodeMap.get(nodeId);
    const records = keyBy(node.properties, 'label');
    const sideBarCellStyle = createRecordCellStyles(records);
    this.setState({
      sideBarRows: records,
      sideBarGraphElement: node,
      sideBarCellStyle,
    });
  };

  handleMouseOverLink = (source, target) => {
    const rd3gLinkId = `${source}:${target}`;
    const link = { ...this.props.linkMap.get(rd3gLinkId) };
    link.label = 'Link';
    const records = keyBy(link.properties, 'label');
    const sideBarCellStyle = createRecordCellStyles(records);
    this.setState({
      sideBarRows: records,
      sideBarGraphElement: link,
      sideBarCellStyle,
    });
  };

  toggleDetails = () => {
    this.props.togglePipelineComponentSideBar();
  };

  toggleSettings = () => {
    this.props.togglePipelineSettingsSideBar();
  };

  // This will send an action to update email alerting status
  // And the background color will also be updated
  toggleEmailAlerting = () => {
    if (this.props.emailAlerts === true) {
      this.props.toggleEmailAlerts(this.props.pipelineName, false);
    } else {
      this.props.toggleEmailAlerts(this.props.pipelineName, true);
    }
  };

  clearExcludeLabels = () => {
    this.props.clearGraphExcludeLabels();
    this.fetchPipelineGraph(this.props.pipelineName, []);
  };

  allExcludeLabels = () => {
    this.props.allGraphExcludeLabels();
    this.fetchPipelineGraph(this.props.pipelineName, this.props.labels);
  };

  render() {
    this.state.graphConfig.width = this.props.pipelineGraphWidth;

    return (
      <DataPlatformWrapper>
        <div className="flex flex-col">
          <div className="flex justify-center">
            <div className="flex flex-col">
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'row',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="flex">
                    <div>
                      <label
                        htmlFor="data-platform-pipeline-search-input"
                        style={{ width: '170px', display: 'inline-block' }}
                      >
                        Get Pipeline by Name:
                      </label>
                      <SelectWithSearch
                        value={this.state.searchTerm}
                        options={this.props.labelsIds.map((label) => ({
                          id: label,
                          name: label,
                        }))}
                        onChange={this.setSearchTerm}
                        className={styles.componentSearch}
                      />
                      <button
                        variant="contained"
                        onClick={this.searchTermFetchPipelineGraph}
                      >
                        Search
                      </button>
                    </div>
                    <Tooltip title="Show/Hide Node Details">
                      <IconButton
                        style={this.toggleSideBarButtonStyle}
                        onClick={this.toggleDetails}
                      >
                        <Icon
                          className={iconClassPossitionHack}
                          name="input"
                          family="material"
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Show/Hide Graph Settings">
                      <IconButton
                        style={this.togglePipelineSettingsSideBarStyle}
                        onClick={this.toggleSettings}
                      >
                        {this.props.showPipelineSettingsSideBar ? (
                          <Icon
                            className={iconClassPossitionHack}
                            name="settings"
                            family="material"
                          />
                        ) : (
                          <Icon
                            className={iconClassPossitionHack}
                            name="settings_applications"
                            family="material"
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                    {this.props.pipelineName && (
                      <AddVertex pipelineName={this.props.pipelineName} />
                    )}
                    {this.props.pipelineName && (
                      <ManagePermissions
                        pipelineName={this.props.pipelineName}
                      />
                    )}
                    <Tooltip title="On/Off Email Alerts">
                      <IconButton
                        style={
                          this.props.emailAlerts
                            ? this.toggleMailOnButtonStyle
                            : this.toggleMailOffButtonStyle
                        }
                        onClick={this.toggleEmailAlerting}
                      >
                        {this.props.emailAlerts ? (
                          <Icon
                            className={iconClassPossitionHack}
                            name="email"
                            family="material"
                          />
                        ) : (
                          <Icon
                            className={iconClassPossitionHack}
                            name="mail_outline"
                            family="material"
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>

                  {this.props.pipelineName.length > 0 && (
                    <div className="flex">
                      <div className="flex flex-shrink justify-center">
                        <HealthIcon health={this.props.pipelineHealth} />
                      </div>
                      <div>
                        <h3>{this.props.pipelineName}</h3>
                      </div>
                    </div>
                  )}
                  {this.props.pipelineName &&
                    this.props.graphNodes.length === 0 && (
                      <p>Nodes not found for this pipeline</p>
                    )}
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        ...graphContainerStyle,
                        width: this.props.pipelineGraphWidth,
                      }}
                    >
                      {!!this.props.graphNodes.length && (
                        <Graph
                          id="pipeline-graph" // id is mandatory, if no id is defined rd3g will throw an error
                          config={this.state.graphConfig}
                          data={{
                            nodes: this.props.graphNodes,
                            links: this.props.graphEdges,
                          }}
                          onClickNode={this.onClickNode}
                          onMouseOverNode={this.handleMouseOverNode}
                          onMouseOverLink={this.handleMouseOverLink}
                        />
                      )}
                    </div>
                    {this.props.showPipelineComponentSideBar && (
                      <div style={{ marginLeft: '15px' }}>
                        <ComponentSideBar
                          pipelineName={this.props.pipelineName}
                          graphElement={this.state.sideBarGraphElement}
                          sideBarRows={this.state.sideBarRows}
                          recordCellStyle={this.state.sideBarCellStyle}
                          graphExcludeLabels={this.props.graphExcludeLabels}
                        />
                      </div>
                    )}
                    {this.props.showPipelineSettingsSideBar && (
                      <div style={{ marginLeft: '15px' }}>
                        <GraphDisplaySettingsSideBar
                          excludeLabels={this.props.graphExcludeLabels}
                          addExcludeLabel={this.props.addGraphExcludeLabel}
                          clearExcludeLabels={this.clearExcludeLabels}
                          allExcludeLabels={this.allExcludeLabels}
                          filter={this.defaultFetchPipelineGraph}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.props.pipelineName && this.props.graphNodes.length > 0 && (
                <Table>
                  <TableHeader columnNames={componentPropsColumns} />
                  <TableBody
                    records={this.props.pipelineProps.records}
                    columnNames={componentPropsColumns}
                    recordsOrder={this.props.pipelineProps.recordOrder}
                  />
                </Table>
              )}
            </div>
          </div>
        </div>
      </DataPlatformWrapper>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    showPipelineComponentSideBar:
      dataPlatform.search.showPipelineComponentSideBar,
    pipelineGraphWidth: dataPlatform.search.pipelineGraphWidth,
    searchTerm: dataPlatform.search.searchTerm,
    graphNodes: dataPlatform.search.pipelineGraphNodes,
    graphEdges: dataPlatform.search.pipelineGraphEdges,
    pipelineName: dataPlatform.search.pipelineName,
    pipelineHealth: dataPlatform.search.pipelineHealth,
    nodeMap: dataPlatform.search.nodeMap,
    linkMap: dataPlatform.search.linkMap,
    emailAlerts: dataPlatform.search.pipelinEmailAlerts,
    graphExcludeLabels: dataPlatform.search.graphExcludeLabels,
    labels: dataPlatform.search.labels,
    labelsIds: dataPlatform.search.labelsIds,
    showPipelineSettingsSideBar:
      dataPlatform.search.showPipelineSettingsSideBar,
    pipelineProps: dataPlatform.search.pipelineProps,
  };
}

PipelineGraph.propTypes = {
  searchTerm: PropTypes.string,
  updateSearchTerm: PropTypes.func.isRequired,
  fetchPipelineGraph: PropTypes.func.isRequired,
  togglePipelineComponentSideBar: PropTypes.func.isRequired,
  toggleEmailAlerts: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  navigation: PropTypes.func.isRequired,
  graphNodes: PropTypes.arrayOf(PropTypes.object),
  graphEdges: PropTypes.arrayOf(PropTypes.object),
  pipelineName: PropTypes.string,
  pipelineHealth: PropTypes.string,
  nodeMap: PropTypes.object,
  linkMap: PropTypes.object,
  showPipelineComponentSideBar: PropTypes.bool,
  pipelineGraphWidth: PropTypes.number,
  emailAlerts: PropTypes.bool,
  setComponent: PropTypes.func.isRequired,
  viewComponent: PropTypes.func.isRequired,
  graphExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  labelsIds: PropTypes.arrayOf(PropTypes.object),
  addGraphExcludeLabel: PropTypes.func.isRequired,
  clearGraphExcludeLabels: PropTypes.func.isRequired,
  showPipelineSettingsSideBar: PropTypes.bool,
  togglePipelineSettingsSideBar: PropTypes.func.isRequired,
  getAllIds: PropTypes.func.isRequired,
  setGraphExcludeLabels: PropTypes.func.isRequired,
  allGraphExcludeLabels: PropTypes.func.isRequired,
  pipelineProps: PropTypes.object,
};

PipelineGraph.defaultProps = {
  searchTerm: '',
  graphNodes: [],
  graphEdges: [],
  pipelineName: '',
  pipelineHealth: '',
  nodeMap: {},
  linkMap: {},
  showPipelineComponentSideBar: true,
  showPipelineSettingsSideBar: true,
  pipelineGraphWidth: 900,
  emailAlerts: false,
  graphExcludeLabels: [],
  labels: [],
  labelsIds: ['Select Label First'],
  pipelineProps: {},
};

export default compose(
  connect(mapStateToProps, {
    updateSearchTerm,
    fetchPipelineGraph,
    viewComponent,
    togglePipelineComponentSideBar,
    toggleEmailAlerts,
    setComponent,
    setGraphExcludeLabels,
    addGraphExcludeLabel,
    clearGraphExcludeLabels,
    allGraphExcludeLabels,
    togglePipelineSettingsSideBar,
    getAllIds,
  }),
  withReactRouter
)(PipelineGraph);
