/**
 * @summary This can house all the form field types and generic configs. We can enhance this at a later time
 *          to map default validation methods to field types or any other form field relationships we need.
 */
const FIELD_TYPES = {
  DROPDOWN: 1,
  TEXT_INPUT: 2,
};

const defaultProps = {
  required: false,
  onChange: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
};

/**
 * @summary This will return an object of props that can be injected into
 *          a <TextField /> Component and normalize the config strucutre.
 * @returns Object
 */
function generateSelectMenuConfigs(selectFieldConfigs) {
  const {
    options = [],
    optionLabelKey,
    optionIdKey,
    errorMessage,
    fieldId,
    chipConfigs = {},
    classes = {},
    ...otherProps
  } = selectFieldConfigs || {};

  return {
    compProps: {
      ...defaultProps,
      ...otherProps,
      helperText: errorMessage,
      select: true,
      className: classes.textField,
      margin: 'normal',
      SelectProps: {
        MenuProps: {
          className: classes.menu,
        },
      },
    },
    options,
    optionLabelKey,
    optionIdKey,
    fieldId,
    fieldType: FIELD_TYPES.DROPDOWN,
    chipConfigs,
  };
}

/**
 * @summary This will return an object of props that can be injected into
 *          a <TextField /> Component and normalize the config strucutre.
 * @returns Object
 */
function generateTextInputConfigs(selectFieldConfigs) {
  const {
    errorMessage,
    fieldId,
    chipConfigs = {},
    classes = {},
    ...otherProps
  } = selectFieldConfigs || {};

  return {
    compProps: {
      ...defaultProps,
      ...otherProps,
      className: classes.textField,
      margin: 'normal',
      helperText: errorMessage,
    },
    fieldId,
    fieldType: FIELD_TYPES.TEXT_INPUT,
    chipConfigs,
  };
}

const GENERAL_CONFIG_MAP = {
  [FIELD_TYPES.DROPDOWN]: generateSelectMenuConfigs,
  [FIELD_TYPES.TEXT_INPUT]: generateTextInputConfigs,
};

export { GENERAL_CONFIG_MAP, FIELD_TYPES };
