import { UserSession } from 'client/modules/user/redux/reducers/user';
import { AppState } from 'client/root-reducer';
import { shallowEqual, useSelector } from 'react-redux';

export const useUser = (): Pick<
  UserSession,
  'idUser' | 'firstName' | 'lastName' | 'email'
> => {
  const userSession = useSelector(
    ({
      user: {
        data: { idUser, firstName, lastName, email },
      },
    }: AppState) => {
      return {
        idUser,
        firstName,
        lastName,
        email,
      };
    },
    shallowEqual
  );

  return userSession;
};
