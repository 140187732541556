import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { InputAdornment } from 'client/component-library/Input';
import Icon from 'client/component-library/icons/Icon';

/** @deprecated It renders an icon that links to the news article's URL */
class NewsArticleInputAdornment extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { showInputAdornment, value, news } = this.props;
    if (!showInputAdornment) {
      return null;
    }

    let newsUrl = value;
    if (newsUrl.slice(0, 3) === 'www' || newsUrl.slice(0, 4) !== 'http') {
      newsUrl = `http://${newsUrl}`;
    }

    let iconName = 'open in new';
    if (news.tsLinkTested.length && news.linkStatusCode !== 200) {
      iconName = 'error';
    }
    return (
      <InputAdornment position="start">
        <a
          href={newsUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ position: 'relative', top: '3px' }}
        >
          <Icon
            name={iconName}
            family="material"
            styles={{ fontSize: '16px' }}
          />
        </a>
      </InputAdornment>
    );
  }
}

NewsArticleInputAdornment.propTypes = {
  showInputAdornment: PropTypes.bool,
  value: PropTypes.string,
  news: PropTypes.object.isRequired,
};

NewsArticleInputAdornment.defaultProps = {
  showInputAdornment: false,
  value: '',
};

export default NewsArticleInputAdornment;
