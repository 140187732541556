import { createContext, ReactNode } from 'react';

export interface FormUtilsHighlightProps {
  shouldHighlight?: boolean,
  noHighlightFields?: Array<unknown>,
  isHighlightable?: (args?: unknown) => true,
}
export interface FormUtilsHighlightFields {
  setHighlightMap?: (key: string, value: unknown) => void,  
  highlightMap?: Record<string, unknown>,
}
export interface FormUtilsExternalContext {
  customContext?: Record<string, unknown>;
}

export type FormModalProps = Partial<{
  isOpen: boolean;
  title: ReactNode;
  body: ReactNode;
  onCancel: <T>(args?:T[]) => void;
  onSubmit: <T>(args?:T[]) => void;
  cancelActText: ReactNode;
  submitActText: ReactNode;
  width: number;
  shouldRenderActions: boolean;
}>;

export interface FormUtilsModal {
  setConfirmationModal?: (props: FormModalProps) => void,
}

export interface FormUtils extends 
  FormUtilsHighlightFields,
  FormUtilsModal,
  FormUtilsHighlightProps,
  FormUtilsExternalContext {}

export const FormUtilsContext = createContext<FormUtils>({
  shouldHighlight: false,
  noHighlightFields: [],
  isHighlightable: () => true,
  highlightMap: {},    
  customContext: {},
});
