import { request } from 'client/modules/common/utils/request';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  stage: string;
  user: {
    idUser: string;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export const userLogin = (req: LoginRequest): Promise<LoginResponse> =>
  request({
    url: '/auth/login',
    body: {
      url: '/',
      data: req,
      ...req,
    },
  }).then((r) => r.body);
