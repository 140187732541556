import {
  useGetEntityForSymbolAndExchange,
  useGetTickersForExchange,
} from 'client/modules/cbi-entity/services/financials/hooks/usePublicFinancialService';

import { AntdSearchableField } from 'client/modules/common/antDesign/form/fields/AntdSearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import { GetTickersForExchangeResponse } from '@cbinsights/publicfinancialservice/publicfinancialservice';
import { SearchOutlined } from '@ant-design/icons';
import { SectionFieldName } from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { IsTickerInUse } from './IsTickerInUse';
import {
  FinancialFormFields,
  FinancialFormValues,
  US_SHORT_CODE,
  getExchangeTicker,
} from './hooks/useFinancials';

import './SearchableTickerField.css';

export const mapTickersToOptions = (
  tickersForExchange: GetTickersForExchangeResponse,
  selectedShortExchange: string
) => {
  const tickerOptions: (DefaultOptionType & { key: string })[] = [];
  const tickerData: Record<
    string,
    { label: string; isPrimary: boolean; exchange: string }
  > = {};

  tickersForExchange?.tickersForExchange?.forEach((el) => {
    const label = `${
      el.exchange && selectedShortExchange === US_SHORT_CODE
        ? `${el.exchange} - `
        : ''
    }${el.ticker} (${el.companyName})`;

    const tickerKey = getExchangeTicker({
      shortExchange: selectedShortExchange,
      exchange: el.exchange,
      ticker: el.ticker,
    });

    tickerOptions.push({
      label,
      value: tickerKey,
      key: tickerKey,
    });

    tickerData[tickerKey] = {
      label,
      isPrimary: el.isPrimary,
      exchange: el.exchange,
    };
  });

  return {
    tickerOptions,
    tickerData,
  };
};

export const SearchableTickerField = ({
  index,
  onChange,
}: {
  index: number;
  onChange: (selected: boolean) => void;
}) => {
  const { values, initialValues, setStatus } =
    useFormikContext<FinancialFormValues>();

  const selectedExchangeTicker = String(values?.company[0]?.ticker.key);
  const selectedShortCodeExchange = String(
    values.company[0]?.shortExchangeCode?.value
  );
  let symbol = String(values?.company[0]?.ticker.value);
  if (selectedShortCodeExchange === US_SHORT_CODE) {
    [, symbol] = symbol.split('-');
  }

  const { data: tickerExist = { idCbiEntity: undefined }, isFetched } =
    useGetEntityForSymbolAndExchange({
      symbol,
      exchangeShortCode: selectedShortCodeExchange,
    });

  const { data: tickersForExchange } = useGetTickersForExchange({
    exchangeShortCode: selectedShortCodeExchange,
    enabled: !!String(selectedShortCodeExchange),
  });

  const { tickerOptions, tickerData } = mapTickersToOptions(
    tickersForExchange,
    selectedShortCodeExchange
  );

  useEffect(() => {
    if (values?.company.length > 0) {
      setStatus({ exchange: tickerData[selectedExchangeTicker]?.exchange });
    }
  }, [tickerData[selectedExchangeTicker]?.exchange]);

  useEffect(() => {
    if (
      values?.company.length > 0 &&
      values?.company[0]?.ticker &&
      values?.company[0]?.ticker.value !==
        initialValues?.company[0]?.ticker?.value
    ) {
      onChange(!!values?.company[0]?.ticker);
    }
  }, [values?.company[0]?.ticker]);

  if (!tickersForExchange) {
    return (
      <div className="flex justify-center">
        <Spin />
      </div>
    );
  }

  const exchangeTickerOption = tickerOptions?.map((el) => ({
    label: (
      <span
        className={`overflow-auto whitespace-normal break-words ${
          tickerData[el.key].isPrimary ? 'is-primary' : 'not-primary'
        }`}
        key={String(el.label)}
      >
        {el.label}
      </span>
    ),
    value: el.value,
    key: el.key,
  }));

  const filterTickers = (input, option) =>
    tickerData[option.key].label.toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <AntdSearchableField
        debounceSearch
        suffixIcon={<SearchOutlined />}
        className={`min-w-[500px] searchable-ticker-field ${
          tickerData[selectedExchangeTicker]?.isPrimary
            ? 'is-primary'
            : 'not-primary'
        }`}
        options={exchangeTickerOption}
        filterOption={filterTickers}
        validateOnBlur
        name={`${FinancialFormFields.Company}.${index}.${FinancialFormFields.Ticker}`}
      />

      {isFetched && (
        <IsTickerInUse
          idCbiEntity={values[SectionFieldName.IdCbiEntity]}
          selectedIdCBiEntity={tickerExist.idCbiEntity}
        />
      )}
    </>
  );
};
