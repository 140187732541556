import {
  GeneralCbiEntitiesInfo,
  RepeatedInfoRequest,
} from '@cbinsights/cbientityservice/cbientityservice';
import { UseQueryResult, useQuery } from 'react-query';
import { getGeneralCbiEntitiesInfo } from '../api/GetGeneralCbiEntitiesInfo';
import { MarketForAdminServiceKeys } from './useGetMarketDetails';

export const useGeneralCbiEntitiesInfo = (
  req: RepeatedInfoRequest,
  enabled: boolean
): UseQueryResult<GeneralCbiEntitiesInfo, Error> =>
  useQuery<
    GeneralCbiEntitiesInfo,
    Error,
    GeneralCbiEntitiesInfo,
    [MarketForAdminServiceKeys, RepeatedInfoRequest]
  >({
    queryKey: ['GetGeneralCbiEntitiesInfo', req],
    queryFn: () => getGeneralCbiEntitiesInfo(req),
    notifyOnChangeProps: [
      'data',
      'isFetching',
      'isFetched',
      'isFetchedAfterMount',
    ],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
  });
