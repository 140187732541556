import { UseQueryResult, useQuery } from 'react-query';
import { GetUserEmailEventsResponse } from '@cbinsights/adminservice/adminservice';
import { GetUserEmailEvents } from '.';
import { getUserEmailEvents } from '../api/GetUserEmailEvents';

type Params = Parameters<typeof getUserEmailEvents>[0];
type Return = GetUserEmailEventsResponse;

export const useGetUserEmailEvents = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUserEmailEvents, Params]>(
    ['GetUserEmailEvents', params],
    () => getUserEmailEvents(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
