import { GetTeamFeatureUsageResponse } from '@cbinsights/userv2service/userv2service';
import { UseQueryResult, useQuery } from 'react-query';
import { GetTeamFeatureUsage } from '.';
import { getTeamFeatureUsage } from '../api/GetTeamFeatureUsage';

type Params = Parameters<typeof getTeamFeatureUsage>[0];
type Return = GetTeamFeatureUsageResponse;

export const useGetTeamFeatureUsage = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetTeamFeatureUsage, Params]>(
    ['GetTeamFeatureUsage', params],
    () => getTeamFeatureUsage(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
