import { UpdateUserTrialRequest } from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const updateUserTrial = (
  req: IndexSignature<UpdateUserTrialRequest>
): Promise<unknown> =>
  request({
    url: '/service/userv2service/UpdateUserTrial',
    body: req,
  }).then((r) => r.body);
