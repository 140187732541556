import { useQuery } from 'react-query';
import { initialAppValidation } from '../api/initialAppValidation';

const setMetadataId: Readonly<'setMetadata'> = 'setMetadata';
export const useSetMetadataQuery = (enabled = false) => {
  return useQuery<boolean, Error, boolean, [typeof setMetadataId]>(
    [setMetadataId],
    () => initialAppValidation(setMetadataId),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetched'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
};
