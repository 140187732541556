import moment from 'moment';
import { useMutation } from 'react-query';
import { createTeam } from '../services/api/CreateTeam';
import { extractDomain } from '../components/DomainWhitelist';

export const useCreateTeamForm = () => {
  const createTeamMutation = useMutation(createTeam);
  const onSubmit = (teamName, packageId, packageStart, packageEnd, domains) => {
    const startDate = moment(packageStart).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(packageEnd).format('YYYY-MM-DD HH:mm:ss');
    let uniqueDomains = [];

    if (domains) {
      const domainList = domains.split(',');
      const domainWhitelist = domainList.map((domain) => {
        return extractDomain(domain);
      });
      uniqueDomains = Array.from(new Set(domainWhitelist));
    }

    return createTeamMutation.mutateAsync({
      name: teamName,
      id_package: packageId,
      package_start: startDate,
      package_end: endDate,
      domains: uniqueDomains,
    });
  };

  return {
    onSubmit,
  };
};
