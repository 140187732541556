import { map, forEach, filter } from 'lodash';

export const saveRecordsOrder = (rows) => map(rows, (row) => row.id);

export const saveOriginalRecords = (originalRecords, recordId, oldRecord) => {
  if (originalRecords[recordId]) {
    return originalRecords;
  }
  return {
    ...originalRecords,
    [recordId]: { ...oldRecord },
  };
};

export const updateSelectionStateOfAllRecords = (records, isSelected) => {
  const selectedRecords = {};
  forEach(records, (record) => {
    selectedRecords[record.id] = isSelected;
  });
  return selectedRecords;
};

export const checkIfAllRecordsSelected = (selectedRecords) => {
  return (
    filter(selectedRecords, (isSelected) => {
      return !isSelected;
    }).length === 0
  );
};

export const updateRecordInArray = (records, recordId, field, value) =>
  records.map((record) => {
    return record.id !== recordId
      ? record
      : {
          ...record,
          [field]: typeof value === 'function' ? value(record[field]) : value,
          edited: true,
        };
  });
