import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Tooltip, Typography } from 'antd';
import { useGetUserKeyEvents } from '../services/hooks/useGetUserKeyEvents';

const { Paragraph } = Typography;

type EntryType = {
  event_key: string;
  timestamp: string;
  details: string;
};

const columns: ColumnsType<EntryType> = [
  {
    title: 'Event Key',
    key: 'event_key',
    dataIndex: 'event_key',
  },
  {
    title: 'Details',
    key: 'details',
    dataIndex: 'details',
    render: (details: string) => {
      try {
        const parsedDetails = JSON.parse(details);
        const formattedDetails = JSON.stringify(parsedDetails, null, 2);
        return (
          <Tooltip
            title={
              <pre
                style={{
                  maxHeight: '400px',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {formattedDetails}
              </pre>
            }
            overlayStyle={{ maxWidth: '600px' }}
          >
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
              {formattedDetails}
            </Paragraph>
          </Tooltip>
        );
      } catch (error) {
        return <span>Invalid JSON</span>;
      }
    },
  },
  {
    title: 'Timestamp',
    key: 'timestamp',
    dataIndex: 'timestamp',
  },
];

const useUserKeyEventsTable = (idUser: number, enabled: boolean) => {
  const { data, isLoading } = useGetUserKeyEvents({
    enabled,
    params: { idUser },
  });

  const dataSource = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.userKeyEvents;
  }, [data]);

  return {
    columns,
    dataSource,
    isLoading,
  };
};

export default useUserKeyEventsTable;
