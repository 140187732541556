/**
 * action types
 */

export const SET_PAGE = 'SET_PAGE';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const SETUP_PAGINATION = 'SETUP_PAGINATION';

/**
 * action creators
 */

export function setPage(page) {
  return { type: SET_PAGE, page };
}

export function setItemsPerPage(itemsPerPage) {
  return { type: SET_ITEMS_PER_PAGE, itemsPerPage };
}

export function setupPagination(page, itemsPerPage, totalItems) {
  return {
    type: SETUP_PAGINATION,
    page,
    itemsPerPage,
    totalItems,
  };
}
