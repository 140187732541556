import { request } from 'client/modules/common/utils/request';
import asyncDebounce from 'client/modules/common/utils/debounce';
import get from 'lodash/get';
import { ltrim } from 'client/modules/common/utils/truncateString';
import { logInfo } from 'client/modules/common/utils/logInfo';

function getAliasTypeDisplayVal(type) {
  switch (type) {
    case 'LEGAL_NAME':
      return 'Legal Name';
    case 'DBA':
      return 'DBA';
    case 'FKA':
      return 'FKA';
    case 'NATIVE_LANGUAGE_NAME':
      return 'Native Name';
    case 'PRODUCT':
      return 'Product';
    default:
      return 'Alias';
  }
}

/*
  UNKNOWN = 0;
  COMPANY = 1;
  INVESTOR = 2;
  CBI_ENTITY = 3;
  TEAM_ENTITY = 4;
*/
export const getEntityByName = asyncDebounce(
  (
    term,
    entityType = 1,
    serviceProvidersOnly = false,
    searchDrafts = false
  ) => {
    const searchTerm = ltrim(term);

    if (!searchTerm) {
      logInfo({
        error: new Error('Searching With No Search Term'),
        message: 'Search Term not provided',
        method: 'getEntityByName',
      });
      return Promise.resolve([]);
    }

    return request({
      url: '/service/cbientityservice/SearchForEntity',
      body: {
        searchTerm,
        limit: 10,
        entityType,
        serviceProvidersOnly,
        searchDrafts,
      },
    }).then((res) => {
      const results = get(res, 'body.entitySearchResults', []);
      const spKey = 'serviceProvider.serviceProviderType';
      return results.map((entity) => ({
        id: entity.idCbiEntity,
        idServiceProvider: get(entity, 'serviceProvider.idServiceProvider', 0),
        isInvestor: !!get(entity, 'idInvestor', 0) || entity.isDraftInvestor,
        isCompany: !!get(entity, 'idCompany', 0),
        idInvestor: get(entity, 'idInvestor', 0),
        idCompany: get(entity, 'idCompany', 0),
        idInvestorCategory: get(entity, 'idInvestorCategory', 0),
        name: entity.name,
        value: entity.idCbiEntity || entity.idEntityDraft,
        label: entity.name,
        alias: entity.alias,
        aliasType: getAliasTypeDisplayVal(entity.aliasType),
        url: `https://${entity.url}`,
        altUrl: entity.altUrl ? `https://${entity.altUrl}` : '',
        description: entity.description,
        serviceProviderType: {
          value: get(entity, `${spKey}.idServiceProviderType`, 0),
          label: get(entity, `${spKey}.serviceProviderType`, ''),
          isInvestmentBank:
            get(entity, `${spKey}.idServiceProviderType`, 0) === 3,
        },
        idCbiEntity: entity.idCbiEntity,
        idEntityDraft: entity.idEntityDraft,
      }));
    });
  }
);
