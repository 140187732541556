import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'client/component-library/styles';
import styles from '../styles/material-ui/InnerTableNewsArticleCell';

class InnerTableNewsArticleCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, classes, record, parentId } = this.props;
    const [recordId] = id.split('-');

    return (
      <div className={classes.wrap}>
        {record.sentence && (
          <div
            className={classes.textBlock}
            style={{ width: '800px' }}
            data-test={`${parentId}-${recordId}-sentence`}
          >
            {record.sentence}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');
  const {parentId} = ownProps;
  const innerTable = markets.tabData.marketSizingDetails.innerTable[parentId];

  return {
    record: innerTable.innerTableRecords[recordId],
  };
}

InnerTableNewsArticleCell.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps)(InnerTableNewsArticleCell)
);
