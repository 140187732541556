import { ApprovalStatus } from '@cbinsights/thirdpartyadminservice/thirdpartyadminservice';

export const NO_VALUE_ERROR = 'Please enter a value';

export const SEARCH_FILTER_VALUES = {
  TEMP_ID: 'profileId',
  NAME: 'entityName',
  URL: 'entityUrl',
};

export const ROUTES = {
  home: {
    path: 'home',
    uri: '/profile-queue/home',
  },
  review: {
    path: 'review',
    uri: '/profile-queue/review',
  },
  pending: {
    path: 'pending',
    uri: '/profile-queue/pending',
  },
  qa: {
    path: 'qa',
    uri: '/profile-queue/qa',
  },
};

export const isReviewQueue = () =>
  window.location.pathname === ROUTES.review.uri;
export const isPendingQueue = () =>
  window.location.pathname === ROUTES.pending.uri;
export const isQAQueue = () => window.location.pathname === ROUTES.qa.uri;

// Limit Filter
type FILTER_LIMIT_1 = '1';
type FILTER_LIMIT_10 = '10';
type FILTER_LIMIT_20 = '20';
type FILTER_LIMIT_50 = '50';
type FILTER_LIMIT_100 = '100';

export type LIMIT_FILTER =
  | FILTER_LIMIT_1
  | FILTER_LIMIT_10
  | FILTER_LIMIT_20
  | FILTER_LIMIT_50
  | FILTER_LIMIT_100;

export const LIMIT_FILTER_OPTIONS: [
  { label: FILTER_LIMIT_10; value: FILTER_LIMIT_10 },
  { label: FILTER_LIMIT_20; value: FILTER_LIMIT_20 },
  { label: FILTER_LIMIT_50; value: FILTER_LIMIT_50 },
  { label: FILTER_LIMIT_100; value: FILTER_LIMIT_100 }
] = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

// Search Filter
export const FILTER_SEARCH_OPTIONS = [
  {
    label: 'Temporary ID',
    value: SEARCH_FILTER_VALUES.TEMP_ID,
    inputType: 'number',
  },
  { label: 'Entity Name', value: SEARCH_FILTER_VALUES.NAME, inputType: 'text' },
  { label: 'Entity URL', value: SEARCH_FILTER_VALUES.URL, inputType: 'text' },
];

export const FILTER_SEARCH_INDEXES = {
  [SEARCH_FILTER_VALUES.TEMP_ID]: 0,
  [SEARCH_FILTER_VALUES.NAME]: 1,
  [SEARCH_FILTER_VALUES.URL]: 2,
};

// Status Filter
export const STATUS_FILTER_OPTIONS = [
  { label: 'Pending Approval', value: ApprovalStatus.PENDING_APPROVAL },
  { label: 'Pending Rejection', value: ApprovalStatus.PENDING_REJECTION },
];

// Url Filter/Search Restrictions
export const FILTER_RESTRICTIONS = {
  reviewers: ['entityStatus', 'limit'],
  entityStatus: ['reviewers', 'limit'],
};

// New Software Categories Taxonomy Field
export const SOFTWARE_CATEGORIES = new Map([
  ['MOBILE', { label: 'Mobile', value: 'MOBILE' }],
  ['CLOUD', { label: 'Cloud', value: 'CLOUD' }],
  ['BOTH', { label: 'Both', value: 'BOTH' }],
  ['NEITHER', { label: 'Neither', value: 'NEITHER' }],
]);

export const SOFTWARE_CATEGORIES_OPTIONS = Array.from(
  SOFTWARE_CATEGORIES.values()
);
