import React, { useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { NEW_FEATURE_IDS } from '../../constants/features';
import useSetTeamFeatureUsage from '../../hooks/useSetTeamFeatureUsage';
import useSetTeamAdditionalQuota from '../../hooks/useSetTeamAdditionalQuota';

type FormFields = {
  usage: number;
  additionalQuota: number;
};

type Props = {
  refetchTeamFeatureUsage: () => void;
  fields: FormFields;
  idFeature: number;
  idTeam: number;
  packageLimit: number;
};

const UpdateFeatureUsage = ({
  refetchTeamFeatureUsage,
  fields,
  idFeature,
  idTeam,
  packageLimit,
}: Props) => {
  const { onSubmit: updateTeamFeatureUsage, isLoading } =
    useSetTeamFeatureUsage();

  const {
    onSubmit: updateTeamFeatureAdditionalQuota,
    isLoading: isLoadingAdditionalQuota,
  } = useSetTeamAdditionalQuota();

  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm<FormFields>();

  const closeModal = () => {
    form.resetFields();
    setModalOpen(false);
  };

  const isNewFeature = Object.values(NEW_FEATURE_IDS).includes(idFeature);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (isNewFeature) {
          return updateTeamFeatureAdditionalQuota({
            idTeam,
            idFeature,
            additionalQuota: parseInt(
              form.getFieldValue('additionalQuota'),
              10
            ),
          })
            .then(() => {
              message.success(`Team Feature updated successfully`);
              closeModal();
              refetchTeamFeatureUsage();
            })
            .catch(() => {
              message.error(`Unable to update team feature. Please try again.`);
            });
        }

        return updateTeamFeatureUsage({
          feature: {
            id_team: idTeam,
            additional_quota: parseInt(
              form.getFieldValue('additionalQuota'),
              10
            ),
            id_feature: idFeature,
            package_quota: packageLimit,
            total: form.getFieldValue('usage'),
          },
        })
          .then(() => {
            message.success(`Team Feature updated successfully`);
            closeModal();
            refetchTeamFeatureUsage();
          })
          .catch(() => {
            message.error(`Unable to update team feature. Please try again.`);
          });
      })
      .catch(() => {});
  };

  const openModal = () => {
    setModalOpen(true);
    form.setFieldsValue({
      usage: fields.usage,
      additionalQuota: fields.additionalQuota,
    });
  };

  const validateInput = (_, value) =>
    value >= 0
      ? Promise.resolve()
      : Promise.reject(new Error('Input should be a positive number'));

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={openModal} />
      <Modal
        title={<span className="capitalize">Update team usage</span>}
        centered
        open={modalOpen}
        footer={[
          <Button
            key="cancel"
            onClick={closeModal}
            loading={isLoading || isLoadingAdditionalQuota}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading || isLoadingAdditionalQuota}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
        okText="Save"
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={() => {
            setModalOpen(false);
          }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="usage"
            label="Usage"
            rules={[{ required: true }, { validator: validateInput }]}
            help={false}
            initialValue={fields.usage}
          >
            <Input type="number" disabled={isNewFeature} />
          </Form.Item>

          <Form.Item
            name="additionalQuota"
            label="Additional quota"
            rules={[{ required: true }, { validator: validateInput }]}
            help={false}
            initialValue={fields.additionalQuota}
          >
            <Input type="number" disabled={!isNewFeature} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateFeatureUsage;
