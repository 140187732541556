export function parseJSON(response) {
  return response.json();
}

/** @deprecated */
export function handleAsyncError(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const { statusText } = response;
  return parseJSON(response).then((error) => {
    const responseError = new Error(statusText);
    responseError.response = response;
    responseError.details = error.details;
    responseError.parsedResponse = error;
    throw responseError;
  });
}
