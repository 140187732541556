import React, { ReactElement } from 'react';
import { useField, useFormikContext, FieldHookConfig } from 'formik';
import { TextAreaProps, InputProps, InputRef } from 'antd/lib/input';
import { Input } from 'antd';

export type AntdInputFieldProps = {
  name: string;
  disabled?: boolean;
  textarea?: boolean;
  ref?: React.Ref<InputRef>;
  onChange?: (event) => void;
  validateOnBlur?: boolean;
} & FieldHookConfig<string> &
  TextAreaProps &
  InputProps;

export const AntdInputField = ({
  disabled = false,
  textarea = false,
  onChange = () => {},
  onBlur = () => {},
  validateOnBlur = false,
  ...props
}: AntdInputFieldProps): ReactElement => {
  const [field, , helpers] = useField(props);
  const { isSubmitting, validateField } = useFormikContext();

  const TypedInput = textarea ? Input.TextArea : Input;

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
    onChange(event);
  };

  const handleBlur = (event) => {
    /*
     * Formik does not support mix of field and form level validation.
     * We have form validate disabled on blur at top level so that config holds true for all
     */
    if (validateOnBlur) {
      validateField(props.name);
    }

    onBlur(event);

    // Formik does not set the field touched by default
    helpers.setTouched(true);
  };

  return (
    <TypedInput
      {...field}
      disabled={isSubmitting || disabled}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
};
