/* eslint-disable react/default-props-match-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'client/component-library/buttons/Button';
import Input from 'client/component-library/Input';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import TableFooter from 'client/modules/common/components/Table/TableComponents/TableFooter';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from 'client/component-library/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  newUserColumns,
  pipelineUserColumns,
  adminUserColumns,
} from './Table/utils/table-columns';
import {
  updateNewUserRecordsPerPage,
  updateNewUserPageNumber,
  setDisplayNewUsers,
  updatePipelineUserRecordsPerPage,
  updatePipelineUserPageNumber,
  setDisplayPipelineUsers,
  updateAdminUserRecordsPerPage,
  updateAdminUserPageNumber,
  setDisplayAdminUsers,
  setAdminUserFilterTerm,
} from '../redux/actions/users';

class PipelinePermissionsFlex extends Component {
  setAdminUserFilterTerm = (e) => {
    this.props.setAdminUserFilterTerm(e.target.value);
  };

  setDisplayAdminUsers = (pageNumber, recordsPerPage, filterTerm) => {
    const props = {
      adminUsers: this.props.adminUsers,
      pipelineUsers: {},
      newUsers: this.props.newUsers,
      pageNumber,
      recordsPerPage,
      filterTerm,
    };
    if (this.props.showPipelineUsers) {
      props.pipelineUsers = this.props.pipelineUsers;
    }
    this.props.setDisplayAdminUsers(props);
  };

  handleUpdateAdminUserPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.numAdminUsers) {
      this.props.updateAdminUserPageNumber(page);
      this.setDisplayAdminUsers(
        page,
        this.props.adminUserRecordsPerPage,
        this.props.adminUserFilterTerm
      );
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  handleUpdateAdminUsersRecordsPerPage = (event) => {
    if (this.props.numAdminUsers) {
      this.props.updateAdminUserRecordsPerPage(event.target.value);
      this.props.updateAdminUserPageNumber(0);
      this.setDisplayAdminUsers(
        0,
        event.target.value,
        this.props.adminUserFilterTerm
      );
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  handleUpdateNewUserPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.numNewUsers) {
      this.props.updateNewUserPageNumber(page);
      this.props.setDisplayNewUsers(
        this.props.newUsers,
        page,
        this.props.newUserRecordsPerPage
      );
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  handleUpdateNewUsersRecordsPerPage = (event) => {
    if (this.props.numNewUsers) {
      this.props.updateNewUserRecordsPerPage(event.target.value);
      this.props.updateNewUserPageNumber(0);
      this.props.setDisplayNewUsers(this.props.newUsers, 0, event.target.value);
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  handleUpdatePipelineUserPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.numPipelineUsers) {
      this.props.updatePipelineUserPageNumber(page);
      this.props.setDisplayPipelineUsers(
        this.props.pipelineUsers,
        page,
        this.props.pipelineUserRecordsPerPage
      );
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  handleUpdatePipelineUsersRecordsPerPage = (event) => {
    if (this.props.numPipelineUsers) {
      this.props.updatePipelineUserRecordsPerPage(event.target.value);
      this.props.updatePipelineUserPageNumber(0);
      this.props.setDisplayPipelineUsers(
        this.props.pipelineUsers,
        0,
        event.target.value
      );
    } else {
      window.alert(`${this.props.noUsersAlertMessage}`);
    }
  };

  filterAdminUsers = () => {
    this.setDisplayAdminUsers(
      0,
      this.props.adminUserRecordsPerPage,
      this.props.adminUserFilterTerm
    );
  };

  clearFilterAdminUsers = () => {
    this.props.setAdminUserFilterTerm('');
    this.setDisplayAdminUsers(0, this.props.adminUserRecordsPerPage, '');
  };

  render() {
    return (
      <div className="flex justify-center">
        <div>
          {this.props.manageHeader.length > 0 && (
            <p>{this.props.manageHeader}</p>
          )}
          {this.props.showPipelineUsers && (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p style={{ fontFamily: 'Roboto' }}>Pipeline Users</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="flex flex-shrink">
                  <Table>
                    <TableHeader columnNames={pipelineUserColumns} />
                    <TableBody
                      records={this.props.pipelineUserRecords}
                      columnNames={pipelineUserColumns}
                      recordsOrder={this.props.pipelineUserRecordOrder}
                      recordCellStyles={this.props.pipelineUserRecordCellStyle}
                    />
                    <TableFooter
                      totalRecords={this.props.numPipelineUsers}
                      recordsPerPage={this.props.pipelineUserRecordsPerPage}
                      pageNumber={this.props.pipelineUserPageNumber}
                      handleChangePageNumber={
                        this.handleUpdatePipelineUserPageNumber
                      }
                      handleUpdateRecordsPerPage={
                        this.handleUpdatePipelineUsersRecordsPerPage
                      }
                      rowsPerPageOptions={[3, 6, 9]}
                    />
                  </Table>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <p style={{ fontFamily: 'Roboto' }}>Add Admin Users</p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="flex flex-shrink">
                <div>
                  <Input
                    onChange={this.setAdminUserFilterTerm}
                    id="data-platform-admin-user-filter"
                    placeholder="Filter by User"
                    value={this.props.adminUserFilterTerm}
                  />
                  <Button onClick={this.filterAdminUsers}>Filter</Button>
                  <Button onClick={this.clearFilterAdminUsers}>Clear</Button>
                </div>
                <Table>
                  <TableHeader columnNames={adminUserColumns} />
                  <TableBody
                    records={this.props.adminUserRecords}
                    columnNames={adminUserColumns}
                    recordsOrder={this.props.adminUserRecordOrder}
                    recordCellStyles={this.props.adminUserRecordCellStyle}
                  />
                  <TableFooter
                    totalRecords={this.props.numAdminUsers}
                    recordsPerPage={this.props.adminUserRecordsPerPage}
                    pageNumber={this.props.adminUserPageNumber}
                    handleChangePageNumber={
                      this.handleUpdateAdminUserPageNumber
                    }
                    handleUpdateRecordsPerPage={
                      this.handleUpdateAdminUsersRecordsPerPage
                    }
                    rowsPerPageOptions={[3, 6, 9]}
                  />
                </Table>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div>
          {this.props.newHeader.length > 0 && (
            <p style={{ fontFamily: 'Roboto' }}>{this.props.newHeader}</p>
          )}
          <Table>
            <TableHeader columnNames={newUserColumns} />
            <TableBody
              records={this.props.newUserRecords}
              columnNames={newUserColumns}
              recordsOrder={this.props.newUserRecordOrder}
              recordCellStyles={this.props.newUserRecordCellStyle}
            />
            <TableFooter
              totalRecords={this.props.numNewUsers}
              recordsPerPage={this.props.newUserRecordsPerPage}
              pageNumber={this.props.newUserPageNumber}
              handleChangePageNumber={this.handleUpdateNewUserPageNumber}
              handleUpdateRecordsPerPage={
                this.handleUpdateNewUsersRecordsPerPage
              }
              rowsPerPageOptions={[3, 6, 9]}
            />
          </Table>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    pipelineUsers: dataPlatform.users.pipelineUsers,
    numPipelineUsers: dataPlatform.users.numPipelineUsers,
    pipelineUserPageNumber: dataPlatform.users.pipelineUserPageNumber,
    pipelineUserRecordsPerPage: dataPlatform.users.pipelineUserRecordsPerPage,
    pipelineUserRecords: dataPlatform.users.pipelineUserRecords,
    pipelineUserRecordOrder: dataPlatform.users.pipelineUserRecordOrder,
    pipelineUserRecordCellStyle: dataPlatform.users.pipelineUserRecordCellStyle,
    newUsers: dataPlatform.users.newUsers,
    numNewUsers: dataPlatform.users.numNewUsers,
    newUserPageNumber: dataPlatform.users.newUserPageNumber,
    newUserRecordsPerPage: dataPlatform.users.newUserRecordsPerPage,
    newUserRecords: dataPlatform.users.newUserRecords,
    newUserRecordOrder: dataPlatform.users.newUserRecordOrder,
    newUserRecordCellStyle: dataPlatform.users.newUserRecordCellStyle,
    adminUsers: dataPlatform.users.adminUsers,
    numAdminUsers: dataPlatform.users.numAdminUsers,
    adminUserPageNumber: dataPlatform.users.adminUserPageNumber,
    adminUserRecordsPerPage: dataPlatform.users.adminUserRecordsPerPage,
    adminUserRecords: dataPlatform.users.adminUserRecords,
    adminUserRecordOrder: dataPlatform.users.adminUserRecordOrder,
    adminUserRecordCellStyle: dataPlatform.users.adminUserRecordCellStyle,
    adminUserFilterTerm: dataPlatform.users.adminUserFilterTerm,
  };
}

PipelinePermissionsFlex.propTypes = {
  updateNewUserRecordsPerPage: PropTypes.func.isRequired,
  updateNewUserPageNumber: PropTypes.func.isRequired,
  setDisplayNewUsers: PropTypes.func.isRequired,
  updatePipelineUserRecordsPerPage: PropTypes.func.isRequired,
  updatePipelineUserPageNumber: PropTypes.func.isRequired,
  setDisplayPipelineUsers: PropTypes.func.isRequired,
  updateAdminUserRecordsPerPage: PropTypes.func.isRequired,
  updateAdminUserPageNumber: PropTypes.func.isRequired,
  setDisplayAdminUsers: PropTypes.func.isRequired,
  pipelineUsers: PropTypes.object,
  numPipelineUsers: PropTypes.number,
  pipelineUserPageNumber: PropTypes.number.isRequired,
  pipelineUserRecordsPerPage: PropTypes.number.isRequired,
  pipelineUserRecords: PropTypes.object,
  pipelineUserRecordOrder: PropTypes.arrayOf(PropTypes.string),
  pipelineUserRecordCellStyle: PropTypes.object,
  newUsers: PropTypes.object,
  numNewUsers: PropTypes.number,
  newUserPageNumber: PropTypes.number.isRequired,
  newUserRecordsPerPage: PropTypes.number.isRequired,
  newUserRecords: PropTypes.object,
  newUserRecordOrder: PropTypes.arrayOf(PropTypes.string),
  newUserRecordCellStyle: PropTypes.object,
  adminUsers: PropTypes.object,
  numAdminUsers: PropTypes.number,
  adminUserPageNumber: PropTypes.number.isRequired,
  adminUserRecordsPerPage: PropTypes.number.isRequired,
  adminUserRecords: PropTypes.object,
  adminUserRecordOrder: PropTypes.arrayOf(PropTypes.string),
  adminUserRecordCellStyle: PropTypes.object,
  showPipelineUsers: PropTypes.bool,
  manageHeader: PropTypes.string,
  newHeader: PropTypes.string,
  noUsersAlertMessage: PropTypes.string,
  adminUserFilterTerm: PropTypes.string,
  setAdminUserFilterTerm: PropTypes.func.isRequired,
};

PipelinePermissionsFlex.defaultProps = {
  showPipelineUsers: false,
  manageHeader: '',
  newHeader: '',
  noUsersAlertMessage: 'There are no users in the pipeline!',
  pipelineUsers: {},
  numPipelineUsers: 0,
  pipelineUserPageNumber: 0,
  pipelineUserRecordsPerPage: 3,
  pipelineUserRecords: {},
  pipelineUserRecordOrder: [],
  pipelineUserRecordCellStyle: {},
  newUsers: {},
  numNewUsers: 0,
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
  newUserRecords: {},
  newUserRecordOrder: [],
  newUserRecordCellStyle: {},
  adminUsers: {},
  numAdminUsers: 0,
  adminUserPageNumber: 0,
  adminUserRecordsPerPage: 3,
  adminUserRecords: {},
  adminUserRecordOrder: [],
  adminUserRecordCellStyle: {},
  adminUserFilterTerm: '',
};

export default connect(mapStateToProps, {
  updateNewUserRecordsPerPage,
  updateNewUserPageNumber,
  setDisplayNewUsers,
  updatePipelineUserRecordsPerPage,
  updatePipelineUserPageNumber,
  setDisplayPipelineUsers,
  updateAdminUserRecordsPerPage,
  updateAdminUserPageNumber,
  setDisplayAdminUsers,
  setAdminUserFilterTerm,
})(PipelinePermissionsFlex);
