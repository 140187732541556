import {
  MarketEntityInReview,
  MarketEntityInReviewStatus,
  MarketInReview,
  UpsertMarketForAdminResponse,
  UpsertMarketInReviewForAdminResponse,
} from '@cbinsights/espmatrixservice/espmatrixservice';

import React from 'react';
import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { FormikOnSubmit } from 'client/modules/common/form/containers/FormikContainer';
import { MarketReportsRoutes } from '../RouterHelper';
import {
  useUpsertMarket,
  useUpsertMarketInReviewForAdmin,
} from '../service/hooks/useUpsertMarket';
import {
  MarketFormProps,
  MarketFormStatus,
  MarketFormValues,
} from './useGetMarketDetailsValues';

export const useSubmitMarketDetails = () => {
  const { mutateAsync: upsertMarketInReview } =
    useUpsertMarketInReviewForAdmin();
  const { mutateAsync: upsertMarket } = useUpsertMarket();

  const queryClient = useQueryClient();
  const { activeTab } = useParams<{ activeTab: string }>();

  const navigate = useNavigate();
  const handleSubmit: FormikOnSubmit<
    MarketFormValues,
    MarketFormStatus
  > = async (values, { status }) => {
    const marketEntities: MarketEntityInReview[] = values.companies.map(
      (el) => ({
        id_cbi_entity: el.idCbiEntity,
        status:
          el.status ||
          MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_CURRENT,
        consider_for_scoring: el.considerForScoring,
      })
    );

    const request: Partial<MarketInReview> = {
      id_market: Number(values[MarketFormProps.MarketId]),
      id_market_review: Number(values[MarketFormProps.MarketReviewId]),
      market_name: values[MarketFormProps.MarketName],
      market_description: values[MarketFormProps.MarketDescription],
      id_industries: [
        values[MarketFormProps.Industry]?.value,
        values[MarketFormProps.SubIndustry1]?.value,
        values[MarketFormProps.SubIndustry2]?.value,
      ]
        .filter((industries) => !!industries)
        .map((industries) => Number(industries)),
      market_entities_in_review: marketEntities,
    };

    const getMutationParams = (
      successCreatedMessage: string,
      successUpdatedMessage: string,
      getRedirectURL: (
        response:
          | UpsertMarketInReviewForAdminResponse
          | UpsertMarketForAdminResponse
      ) => string
    ) => ({
      onSuccess: async (response) => {
        if (response.created) {
          message.success(successCreatedMessage);
          navigate(getRedirectURL(response));
          await queryClient.invalidateQueries(['GetMarketDetailsForAdmin']);
          await queryClient.invalidateQueries(['GetMarketsForAdmin']);
        } else {
          message.success(successUpdatedMessage);
          navigate(getRedirectURL(response));
          await queryClient.invalidateQueries(['GetMarketDetailsForAdmin']);
          await queryClient.invalidateQueries(['GetMarketsForAdmin']);
        }
      },
      onError: (error: $TSFixMe) => {
        message.error(
          <>
            <span>An error ocurred: </span>
            <br />
            <span>
              {error?.response?.body?.error?.details || error.message}
            </span>
          </>
        );
      },
    });
    if (status.submitType === 'REVIEW') {
      return upsertMarketInReview(
        request,
        getMutationParams(
          'Market saved successfully!',
          'Market updated successfully!',
          (response: UpsertMarketInReviewForAdminResponse) => {
            return `/${MarketReportsRoutes.MarketReports}/${
              request.id_market || response.id_market_review
            }/${activeTab}${
              !request.id_market
                ? `?${MarketReportsRoutes.IsPendingReview}=true`
                : ''
            }`;
          }
        )
      );
    }
    if (status.submitType === 'PUBLISH') {
      return upsertMarket(
        request,
        getMutationParams(
          'Market created successfully!',
          'Market published successfully!',
          (response: UpsertMarketForAdminResponse) => {
            return `/${MarketReportsRoutes.MarketReports}/${response.id_market}/${activeTab}`;
          }
        )
      );
    }

    return Promise.reject(new Error('No Submit type defined'));
  };

  const handleReject = () => {};

  return { handleSubmit, handleReject };
};
