import { GetAdminPermissionsForUserResponse } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { request } from 'client/modules/common/utils/request';

export const getAdminPermissionsForUser =
  (): Promise<GetAdminPermissionsForUserResponse> =>
    request({
      url: '/service/adminpermissionservice/getAdminPermissionsForUser',
    }).then((r) => {
      return r.body as GetAdminPermissionsForUserResponse;
    });
