import {
  setUserAuthenticationInfo,
  validateAuthenticationResponse,
} from 'client/modules/user/redux/actions/user';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCheckUserAuthQuery } from './UseCheckUserAuth';
import { useGetAdminPermissionsForUser } from './UseGetAdminPermissionsForUserQuery';
import { useSetMetadataQuery } from './UseSetMetadata';

export const UseAppInitialValidations = (): boolean => {
  const dispatch = useDispatch();
  const userAuthQuery = useCheckUserAuthQuery(true);
  useEffect(() => {
    if (userAuthQuery.isFetched) {
      validateAuthenticationResponse(userAuthQuery.data);
    }
  }, [userAuthQuery.isFetched]);

  const metadataQuery = useSetMetadataQuery(userAuthQuery.isFetched);

  const adminPermissionsQuery = useGetAdminPermissionsForUser(
    metadataQuery.isFetched
  );
  useEffect(() => {
    if (metadataQuery.isFetched && adminPermissionsQuery.isFetched) {
      dispatch(setUserAuthenticationInfo(userAuthQuery.data));
    }
  }, [metadataQuery.isFetched, adminPermissionsQuery.isFetched]);

  return metadataQuery.isFetched && adminPermissionsQuery.isFetched;
};
