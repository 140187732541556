import { ApprovalStatus } from '@cbinsights/thirdpartyadminservice/thirdpartyadminservice';
import { RECORD_TYPES } from './components/apis/getDocumentRecord';

export const PAGEID = 59;

export const NO_DATE_RANGES = 'NO_DATE_RANGES';

export const ROUTES = {
  root: {
    path: 'valuation',
    uri: '/valuation',
  },
  documentTable: {
    path: 'home',
    uri: '/valuation/home',
    requiredFeature: 'ValuationsTableView',
    fallback: '/valuation/document-queue',
  },
  documentQueue: {
    path: 'document-queue',
    uri: '/valuation/document-queue',
    requiredFeature: 'ValuationsReviewAccess',
    getDocUri: (docType, docId) =>
      `/valuation/document-queue?docType=${docType}&docId=${docId}`,
  },
};

// fields to not highlight
export const NO_HIGHLIGHT = {
  [RECORD_TYPES.COI]: {
    fields: ['recordType.name'],
  },
};

export const FIELDS = {
  RECORD_TYPE: 'recordType',
  FILING_DATE: 'filingDate',
  FISCAL_YEAR: 'fiscalYear',
  DOC_ID: 'id',
  STATUS: 'status',
  DOC_SRC: 'docSrc',
  EU_REVIEWER: 'reviewer',
  IPU_NOTE: 'ipuNote',
  EU_NOTE: 'euNote',
  GRADE: 'grade',
  LEGAL_NAME: 'legalName',
  COMMON_STOCK: 'commonStock',
  PREF_STOCK: 'preferredStock',
  ROUNDS: 'fundingRounds',
  ORIGINAL_ISSUE: 'originalIssue',
  DIV_PREF: 'dividenPreferance',
  DIV_AMT: 'dividendAmount',
  LIQ: 'liquidation',
  PARTICIPATION: 'participation',
  CONVERSION: 'conversion',
  ANTI_DI: 'antiDilution',
  EMP_STOCK: 'authEmployeeStock',
  IPU_REVIEWER: 'ipuApprover',
  PRIORITY: 'priority',
  GEN_VOTE_RIGHTS: 'generalVotingRights',
  BOARD_VOTE_RIGHTS: 'boardVotingRights',
  DELETED_ROUNDS: 'deletedRounds',
  STOCK_TYPES: 'stockTypes',
  HAS_DATE_RANGES: 'hasDateRanges',
  DATE_RANGE_VOLUMES: 'dateRangeVolumes',
  TOTAL_STOCK_VOLUME: 'totalStockVolume',
  DELETED_STOCK_TYPES: 'deletedStockTypes',
  DELETED_DATE_RANGES: 'deletedDateRanges',
  META: 'meta',
  GROUP: 'idGroup',
} as const;

export const PAR_VALUES = ['PREFERRED_WITH_PAR', 'COMMON_WITH_PAR'];

/* NOTE: for radio button {label, value} for Dropdowns {id, name} */
export const OPTIONS = {
  [FIELDS.RECORD_TYPE]: [
    {
      id: RECORD_TYPES.COI,
      name: 'Certificate of Incorporation',
      label: 'COI',
      value: RECORD_TYPES.COI,
    },
    {
      id: RECORD_TYPES.AFR,
      name: 'Annual Report',
      label: 'Annual Report',
      value: RECORD_TYPES.AFR,
    },
  ],
  [FIELDS.DIV_AMT]: [
    { id: 1, name: '%', apiValue: 'PERCENTAGE' },
    { id: 2, name: '$', apiValue: 'DOLLAR' },
    { id: 3, name: 'N/A', apiValue: 'NA_AMOUNT_FORMAT' },
  ],
  [FIELDS.ANTI_DI]: [
    { label: 'Full Ratchet', value: 'FULL_RATCHET' },
    { label: 'Weighted Average', value: 'WEIGHTED_AVERAGE' },
  ],
  [FIELDS.PARTICIPATION]: [
    { label: 'No', value: 'NO_PARTICIPATION' },
    { label: 'Full', value: 'FULL_PARTICIPATION' },
    { label: 'Capped', value: 'CAPPED_PARTICIPATION' },
  ],
  [FIELDS.LIQ]: [
    { label: 'Senior', value: 'SENIOR' },
    { label: 'Junior', value: 'JUNIOR' },
    { label: 'Pari Passu', value: 'PARI_PASSU' },
    { label: 'Tiered', value: 'TIERED' },
  ],
  [FIELDS.DIV_PREF]: [
    { label: 'Cumulative', value: 'CUMULATIVE' },
    { label: 'Non-cumulative', value: 'NON_CUMULATIVE' },
    { label: 'N/A', value: 'NA_DIVIDEND_PREFERENCE' },
  ],
  [FIELDS.STOCK_TYPES]: [
    { name: 'Common', id: 'COMMON' },
    { name: 'Common With Par (CWP)', id: 'COMMON_WITH_PAR' },
    { name: 'Common No Par (CNP)', id: 'COMMON_NO_PAR' },
    { name: 'Preferred', id: 'PREFERRED' },
    { name: 'Preferred With Par (PWP)', id: 'PREFERRED_WITH_PAR' },
    { name: 'Preferred No Par (PNP)', id: 'PREFERRED_NO_PAR' },
  ],
  [FIELDS.GRADE]: [
    { label: 'Correct', value: 'CORRECT' },
    { label: 'Incorrect', value: 'INCORRECT' },
  ],
  [FIELDS.PRIORITY]: [
    { label: 'High', value: 'high' },
    { label: 'Low', value: 'low' },
  ],
  [FIELDS.STATUS]: [
    { label: 'Pending Approval', value: ApprovalStatus.PENDING_APPROVAL },
    { label: 'Pending Rejection', value: ApprovalStatus.PENDING_REJECTION },
    { label: 'Needs Review', value: ApprovalStatus.NEEDS_REVIEW, isBulk: true },
    { label: 'Approved', value: ApprovalStatus.PUBLISHED },
    { label: 'Archived', value: ApprovalStatus.ARCHIVED, isBulk: true },
    { label: 'Initial Check', value: ApprovalStatus.INITIAL_CHECK },
  ],
  defaultRadio: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
} as const;

// Mapping for Updating Record Status
const {
  UNKNOWN_STATUS,
  NEEDS_REVIEW,
  PENDING_APPROVAL,
  PENDING_REJECTION,
  PUBLISHED,
  ARCHIVED,
  INITIAL_CHECK,
} = ApprovalStatus;

export const IPU_USER = 1;
export const EU_USER = 3;

export const STATUS_ACTION_MAP = {
  [UNKNOWN_STATUS]: {
    approve: {
      btnText: 'N/A',
      nextStatus: UNKNOWN_STATUS,
    },
    reject: {
      btnText: 'N/A',
      nextStatus: UNKNOWN_STATUS,
    },
  },
  [NEEDS_REVIEW]: {
    approve: {
      btnText: 'Submit',
      nextStatus: PENDING_APPROVAL,
    },
    reject: {
      btnText: 'Reject',
      nextStatus: PENDING_REJECTION,
    },
  },
  [PENDING_APPROVAL]: {
    approve: {
      btnText: 'Publish',
      nextStatus: PUBLISHED,
    },
    reject: {
      btnText: 'Archive',
      nextStatus: ARCHIVED,
    },
  },
  [PENDING_REJECTION]: {
    approve: {
      btnText: 'Publish',
      nextStatus: PUBLISHED,
    },
    reject: {
      btnText: 'Archive',
      nextStatus: ARCHIVED,
    },
  },
  [PUBLISHED]: {
    approve: {
      btnText: 'Save',
      nextStatus: PUBLISHED,
    },
    reject: {
      btnText: 'Archive',
      nextStatus: ARCHIVED,
    },
  },
  [ARCHIVED]: {
    approve: {
      btnText: 'Publish',
      nextStatus: PUBLISHED,
    },
    reject: {
      btnText: 'Save',
      nextStatus: ARCHIVED,
    },
  },
  [INITIAL_CHECK]: {
    approve: {
      btnText: 'Needs Review',
      nextStatus: NEEDS_REVIEW,
    },
    reject: {
      btnText: 'Archive',
      nextStatus: ARCHIVED,
    },
  },
} as const;
