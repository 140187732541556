/* eslint-disable no-else-return */
// Include the functions that map custom table components here
import mapMarketComponents from 'client/modules/markets/components/Table/utils/map-markets-table-components';
import mapAdminTableComponents from 'client/modules/admin-user-admin/components/Table/utils/map-admin-table-components';
import mapCbiEntityTableComponents from 'client/modules/cbi-entity/components/Table/utils/map-cbi-entity-table-components';
import mapStoriesTableComponents from 'client/modules/stories/components/tables/utils/map-stories-table-components';
import mapDataPlatformTableComponents from 'client/modules/data-platform/components/Table/utils/map-data-platform-table-components';

// Add custom components here
/**
 * @deprecated
 * It maps the table components based on the type of the table
 * @param type - the type of component you want to render
 * @param props - the props passed to the component
 * @param commonProps - This is the props object that is passed to the table component.
 * @returns A function that takes in a type, props, and commonProps.
 */
export const mapCustomTableComponents = (type, props, commonProps) => {
  const marketsComponent = mapMarketComponents(type, props, commonProps);
  const adminComponent = mapAdminTableComponents(type, props);
  const cbiEntityComponent = mapCbiEntityTableComponents(
    type,
    props,
    commonProps
  );

  const storiesComponent = mapStoriesTableComponents(type, props, commonProps);
  const dataPlatformComponent = mapDataPlatformTableComponents(
    type,
    props,
    commonProps
  );
  if (marketsComponent) {
    return marketsComponent;
  } else if (adminComponent) {
    return adminComponent;
  } else if (cbiEntityComponent) {
    return cbiEntityComponent;
  } else if (storiesComponent) {
    return storiesComponent;
  } else if (dataPlatformComponent) {
    return dataPlatformComponent;
  }
  return null;
};
