import { request } from 'client/modules/common/utils/request';
import { batchRequest } from 'client/modules/third-party-admin/profile-queue/utils';

const ASSOCIATION_ROUND_MAP = {};

function _getFullFundingRoundsDetails(
  idCbiEntities = [],
  includeEntityInfo = false,
  idEntityDrafts = [],
  includeFundingDrafts = false
) {
  return request({
    url: '/service/cbientityservice/GetEntitiesFundings',
    body: {
      idCbiEntities,
      includeEntityInfo,
      idEntityDrafts,
      includeFundingDrafts,
    },
  }).then(({ body }) => {
    return body.fundingInfos.reduce(
      (map, info) => ({
        ...map,
        [info.idCbiEntity !== 0 ? info.idCbiEntity : info.idEntityDraft]:
          info.fundingInfo
            .map(
              ({
                investors,
                fundingRound,
                fundingDate,
                amount,
                exitIndicator,
                minValuation,
                maxValuation,
              }) => ({
                isDraft: false,
                status: info.status && {
                  value: info.status.idStatus,
                  label: info.status.status,
                },
                amount,
                idFunding: fundingRound.idFunding,
                label: fundingRound.name,
                value: fundingRound.idRound,
                date: fundingDate,
                isExit: exitIndicator,
                investors: investors.map((inv) => ({
                  id: inv.idCbiEntity,
                  name: inv.name,
                })),
                description: info.description,
                maxValuation,
                minValuation,
              })
            )
            .concat(
              info.fundingDraftInfo
                .map(
                  ({
                    investors,
                    fundingRound,
                    fundingDate,
                    amount,
                    exitIndicator,
                    minValuation,
                    maxValuation,
                  }) => ({
                    isDraft: true,
                    status: info.status && {
                      value: info.status.idStatus,
                      label: info.status.status,
                    },
                    amount,
                    idFunding: fundingRound.idFunding,
                    label: fundingRound.name,
                    value: fundingRound.idRound,
                    date: fundingDate,
                    isExit: exitIndicator,
                    investors: investors.map((inv) => ({
                      id: inv.idCbiEntity,
                      name: inv.name,
                    })),
                    description: info.description,
                    maxValuation,
                    minValuation,
                  })
                )
                .reverse()
            ),
      }),
      {}
    );
  });
}

export const getFullFundingRoundsDetails = batchRequest(
  _getFullFundingRoundsDetails
);

export function getEntityFundingRounds(entityIds, includeEntityInfo, draftIds) {
  return _getFullFundingRoundsDetails(
    entityIds,
    includeEntityInfo,
    draftIds,
    true
  );
}

export function getAssociatedRounds(entityId, associatedEntityId) {
  const cacheKey = `${associatedEntityId}_${entityId}`;

  if (ASSOCIATION_ROUND_MAP[cacheKey]) {
    return Promise.resolve(ASSOCIATION_ROUND_MAP[cacheKey]);
  }

  return getFullFundingRoundsDetails(associatedEntityId).then((roundInfos) => {
    const roundInfo = roundInfos[associatedEntityId];

    const availableRounds =
      entityId === associatedEntityId
        ? roundInfo
        : roundInfo.filter((round) =>
            round.investors.some((inv) => inv.id === entityId)
          );

    ASSOCIATION_ROUND_MAP[cacheKey] = availableRounds;

    return ASSOCIATION_ROUND_MAP[cacheKey];
  });
}
