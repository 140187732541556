/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
import * as tabName from 'client/modules/cbi-entity/utils/tab-names';
import { keyBy, omit, findIndex, clone } from 'lodash';
import {
  // getKeyValueFromListViaOtherKey,
  updateObjectInListWithKeyValue,
} from 'client/modules/common/utils/objectHelper';
import { saveRecordsOrder } from 'client/modules/common/utils/recordsHelper';
import {
  addRecordSchema,
  emptyHqAddressObject,
} from 'client/modules/cbi-entity/redux/utils/entity-helpers';
import {
  mapFundingInfo,
  mapAddressInfo,
  mapVcFundingInfo,
  saveOriginalRecords,
  addNewInvestor,
  addNewBoardOfDirectorsPerson,
  addNewMention,
  addNewVcSource,
  addNewVcCik,
  addSalesMetricSource,
  deleteInvestor,
  deleteBoardOfDirectorsPerson,
  deleteMention,
  deleteRecordId,
  deleteVcSource,
  deleteVcCik,
  mapSalesMetricInfo,
  newEmployee,
} from '../utils/records-helper';
import * as actions from '../actions/tab-data';
import {
  SET_ADDRESS_INFO,
  RESET_ENTITY_INFO,
  HYDRATE_ADDRESS_INFO,
} from '../actions/general-info';
import { SET_CURRENT_TAB } from '../actions/tab';

const appendCompetitorToState = (state, action) => {
  const currentRecords = state[tabName.COMPETITORS].records;

  // will be null if nothing was updated
  const records = actions.updateCompetitorRecord(
    currentRecords,
    action.entity.idCbiEntity,
    { isDeleted: false }
  );

  const newRecord = {
    ...action.entity,
    idCbiEntityCompetitor: action.entity.idCbiEntity,
    twoWay: true,
    isNew: true,
    isDeleted: false,
  };

  return {
    ...state,
    [tabName.COMPETITORS]: {
      ...state[tabName.COMPETITORS],
      records: records || [...currentRecords, newRecord],
      edited: true,
    },
  };
};

const removeCompetitor = (state, action) => {
  const currentRecords = state[tabName.COMPETITORS].records;

  const records = actions.updateCompetitorRecord(
    currentRecords,
    action.entity.idCbiEntityCompetitor,
    { isDeleted: true }
  );

  // records put the updated item at the end of the array, so we can get that and check if it was just added
  if (records.slice(-1).pop().isNew) {
    records.pop();
  }

  return {
    ...state,
    [tabName.COMPETITORS]: {
      ...state[tabName.COMPETITORS],
      records,
      edited: true,
    },
  };
};

export const initialState = {
  [tabName.GENERAL_INFO]: {
    records: [],
    selectedRecords: {},
    originalRecords: [],
    recordsOrder: [],
    idsToDelete: {
      idAddresses: [],
    },
    edited: false,
  },
  [tabName.FUNDINGS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    idsToDelete: {
      idFunding: [],
      fundingInvestors: [],
      fundingMentions: [],
      fundingBoardOfDirectors: [],
    },
    shouldUpdateCompanyStatus: false,
    edited: false,
  },
  [tabName.COMPETITORS]: {
    records: [],
    suggestions: [],
    edited: false,
  },
  [tabName.VC_FUNDS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    errors: {},
  },
  [tabName.EMPLOYEES]: {
    records: [],
  },
  [tabName.SALES_METRICS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    idsToDelete: {
      idMetrics: [],
      idSources: [],
    },
    edited: false,
  },
  currentTab: '',
};

function updateFundingLocal(state, { deletedIds, newIdFundMap }) {
  const originalRecords = { ...state[tabName.FUNDINGS].originalRecords };
  const records = { ...state[tabName.FUNDINGS].records };
  const recordsOrder = state[tabName.FUNDINGS].recordsOrder.filter(
    (id) => !deletedIds.includes(id)
  );

  // remove all deleted records
  deletedIds.forEach((id) => {
    delete originalRecords[id];
    delete records[id];
  });

  // replace all tempIds to newIds
  newIdFundMap.forEach(({ tempId, newId }) => {
    originalRecords[newId] = records[tempId];
    records[newId] = records[tempId];
    recordsOrder[recordsOrder.indexOf(tempId)] = newId;

    delete records[tempId];
    delete originalRecords[tempId];
  });

  // All Changes that the GET API would do go here
  Object.keys(records).forEach((key) => {
    records[key] = {
      ...records[key],
      // update fundingRound with id
      fundingRound: {
        ...records[key].fundingRound,
        idFunding: key,
      },
      // update mentions with id
      mentions: records[key].mentions.map((mention) => ({
        ...mention,
        idFunding: key,
        isNew: false,
      })),
      id: key, // add new id from BE
      edited: false,
    };
  });

  return {
    ...state,
    [tabName.FUNDINGS]: {
      ...state[tabName.FUNDINGS],
      originalRecords,
      records,
      recordsOrder,
      idsToDelete: initialState[tabName.FUNDINGS].idsToDelete,
    },
  };
}

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.currentTab,
        [action.currentTab]: {
          ...state[action.currentTab],
          edited: false,
        },
      };
    }
    case actions.EMPLOYEES_TAB_DATA_MUTATION: {
      return {
        ...state,
        [tabName.EMPLOYEES]: {
          ...state[tabName.EMPLOYEES],
          ...action.mutation(state[tabName.EMPLOYEES]),
        },
      };
    }
    case actions.ADDRESS_TAB_DATA_MUTATION: {
      return {
        ...state,
        [tabName.GENERAL_INFO]: {
          ...state[tabName.GENERAL_INFO],
          ...action.mutation(state[tabName.GENERAL_INFO]),
        },
      };
    }
    case actions.GET_ENTITY_FUNDINGS: {
      const records = mapFundingInfo(action.fundingInfo);
      const recordsObj = keyBy(records, 'id');
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          originalRecords: recordsObj,
          records: recordsObj,
          recordsOrder: saveRecordsOrder(records),
          idsToDelete: initialState[action.currentTab].idsToDelete,
        },
      };
    }
    case actions.GET_ENTITY_VC_FUNDINGS: {
      const records = mapVcFundingInfo(action.vcFundInfo);
      const recordsObj = keyBy(records, 'id');
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          originalRecords: recordsObj,
          records: recordsObj,
          recordsOrder: saveRecordsOrder(records),
        },
      };
    }
    case actions.DELETE_VC_FUND: {
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const records = state[tabName.VC_FUNDS].records;
      const selectedRecord = records[recordId];

      let newRecords = {
        ...records,
        [recordId]: {
          ...selectedRecord,
          isDeleted: true,
          edited: true,
        },
      };

      // if its a newly added one just remove from store
      newRecords = selectedRecord.isNew
        ? omit(newRecords, recordId)
        : newRecords;

      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          records: newRecords,
          recordsOrder: deleteRecordId(
            state[tabName.VC_FUNDS].recordsOrder,
            recordId
          ),
        },
      };
    }
    case HYDRATE_ADDRESS_INFO: {
      return {
        ...state,
        [action.currentTab]: action.addressInfo,
      };
    }
    case SET_ADDRESS_INFO: {
      if (!action.addressInfo || action.addressInfo.idAddress) {
        return state;
      }
      const records = mapAddressInfo(action.addressInfo);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          originalRecords: records,
          records,
        },
      };
    }
    case RESET_ENTITY_INFO: {
      return {
        ...state,
        [tabName.GENERAL_INFO]: initialState[tabName.GENERAL_INFO],
      };
    }
    case actions.ADD_EMPTY_RECORD: {
      const timeStamp = Date.now();
      const tsKey = `new_${timeStamp}`;
      const { tab } = action;
      const schema = addRecordSchema(tab, { id: tsKey, tabData: state[tab] });

      return {
        ...state,
        [tab]: {
          ...state[tab],
          records: {
            ...state[tab].records,
            [tsKey]: {
              ...schema,
              tempId: tsKey,
            },
          },
          recordsOrder: [tsKey, ...state[tab].recordsOrder],
        },
      };
    }
    case actions.UPDATE_RECORD: {
      const tab = action.tableOverRide
        ? action.tableOverRide
        : state.currentTab;
      const nextRecords = updateObjectInListWithKeyValue({
        objects: state[tab].records,
        id: action.recordId,
        key: action.fieldName,
        value: action.newValue,
      });

      const x = {
        ...state,
        [tab]: {
          ...state[tab],
          records: {
            ...nextRecords,
            [action.recordId]: {
              ...nextRecords[action.recordId],
              edited: true,
            },
          },
          originalRecords: saveOriginalRecords(
            state[tab].originalRecords,
            action.recordId,
            state[tab].records[action.recordId]
          ),
          edited: true,
        },
      };
      return x;
    }
    case actions.SET_COMPETITORS: {
      return {
        ...state,
        [tabName.COMPETITORS]: {
          ...state[tabName.COMPETITORS],
          records: action.competitors.map((competitor) => ({
            ...competitor,
            isNew: false,
          })),
        },
      };
    }
    case actions.SET_SUGGESTED_COMPETITORS: {
      return {
        ...state,
        [tabName.COMPETITORS]: {
          ...state[tabName.COMPETITORS],
          suggestions: action.suggestions,
          suggestionsForEntityId: action.cbiEntityId,
        },
      };
    }
    case actions.RESET_SUGGESTED_COMPETITORS: {
      // For when user navigates to other tabs
      if (
        action.cbiEntityId === state[tabName.COMPETITORS].suggestionsForEntityId
      ) {
        return state;
      }
      return {
        ...state,
        [tabName.COMPETITORS]: {
          ...state[tabName.COMPETITORS],
          suggestions: [],
          suggestionsForEntityId: action.cbiEntityId,
        },
      };
    }
    case actions.APPEND_COMPETITORS: {
      return appendCompetitorToState(state, action);
    }
    case actions.REMOVE_COMPETITORS: {
      return removeCompetitor(state, action);
    }
    case actions.SET_EMPLOYEES: {
      const otherDataLoadedWhileInteracting = state[
        tabName.EMPLOYEES
      ].records.reduce(
        (map, { id, isCollapsed, firms, boardMembers }) => ({
          ...map,
          [id]: { isCollapsed, firms, boardMembers },
        }),
        {}
      );

      // to maintain state after refreshing the table
      const preservedData = (id) => {
        return typeof otherDataLoadedWhileInteracting[id] === 'undefined'
          ? { isCollapsed: true, firms: [], boardMembers: [] }
          : otherDataLoadedWhileInteracting[id];
      };

      return {
        ...state,
        [tabName.EMPLOYEES]: {
          ...state[tabName.EMPLOYEES],
          records: action.employees.map((employee) => ({
            ...newEmployee(employee),
            ...preservedData(employee.idPerson),
            isNew: false,
          })),
          edited: false,
        },
      };
    }
    case actions.ADD_NEW_INVESTOR: {
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: addNewInvestor(
            state[tabName.FUNDINGS].records,
            action.recordId,
            action.idInvestor,
            action.name,
            action.idCbiEntity
          ),
          edited: true,
        },
      };
    }
    case actions.SET_PRIMARY_INVESTOR: {
      const { idFunding, idInvestor } = action;
      const investors = clone(
        state[tabName.FUNDINGS].records[idFunding].investors
      );
      const index = findIndex(investors, (investor) => {
        return investor.idInvestor === idInvestor;
      });
      investors[index] = {
        ...investors[index],
        leadInvestor: !investors[index].leadInvestor,
        isNew: true,
      };
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: {
            ...state[tabName.FUNDINGS].records,
            [idFunding]: {
              ...state[tabName.FUNDINGS].records[idFunding],
              edited: true,
              investors,
            },
          },
          edited: true,
        },
      };
    }
    case actions.ADD_NEW_BOARD_OF_DIRECTORS_PERSON: {
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: addNewBoardOfDirectorsPerson(
            state[tabName.FUNDINGS].records,
            action.recordId,
            action.id,
            action.name
          ),
          edited: true,
        },
      };
    }
    case actions.ADD_MENTION: {
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: addNewMention(
            state[tabName.FUNDINGS].records,
            action.recordId,
            action.value
          ),
          edited: true,
        },
      };
    }
    case actions.ADD_VC_SOURCE: {
      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          records: addNewVcSource(
            state[tabName.VC_FUNDS].records,
            action.recordId,
            action.value
          ),
        },
      };
    }
    case actions.ADD_VC_CIK: {
      const currentCiks = state[tabName.VC_FUNDS].records[action.recordId].ciks;
      const newCik = parseInt(action.value, 10);
      const isCikAdded = currentCiks.find(
        (cik) => parseInt(cik.value, 10) === newCik
      );

      if (isCikAdded || isNaN(newCik)) {
        return state;
      }

      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          records: addNewVcCik(
            state[tabName.VC_FUNDS].records,
            action.recordId,
            action.value
          ),
        },
      };
    }
    case actions.DELETE_ENTITY_INVESTOR: {
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const idInvestor = parseInt(action.investorId, 10) || action.investorId;
      const idInvestorsToDelete =
        !recordId || isNaN(recordId) || !idInvestor || isNaN(idInvestor)
          ? state[tabName.FUNDINGS].idsToDelete.fundingInvestors
          : [
              ...state[tabName.FUNDINGS].idsToDelete.fundingInvestors,
              { idInvestor, idFunding: recordId },
            ];
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: deleteInvestor(
            state[tabName.FUNDINGS].records,
            action.recordId,
            action.investorId
          ),
          idsToDelete: {
            ...state[tabName.FUNDINGS].idsToDelete,
            fundingInvestors: idInvestorsToDelete,
          },
          edited: true,
        },
      };
    }
    case actions.DELETE_BOARD_OF_DIRECTORS_PERSON: {
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const idBoardOfDirector =
        parseInt(action.boardOfDirectorPersonId, 10) ||
        action.boardOfDirectorPersonId;
      const idBoardOfDirectorsToDelete =
        !recordId || isNaN(recordId)
          ? state[tabName.FUNDINGS].idsToDelete.fundingBoardOfDirectors
          : [
              ...state[tabName.FUNDINGS].idsToDelete.fundingBoardOfDirectors,
              { idBoardOfDirector, idFunding: recordId },
            ];
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: deleteBoardOfDirectorsPerson(
            state[tabName.FUNDINGS].records,
            action.recordId,
            action.boardOfDirectorPersonId
          ),
          idsToDelete: {
            ...state[tabName.FUNDINGS].idsToDelete,
            fundingBoardOfDirectors: idBoardOfDirectorsToDelete,
          },
          edited: true,
        },
      };
    }
    case actions.DELETE_ENTITY_MENTIONS: {
      const idFundingSource =
        parseInt(action.mentionId, 10) || action.mentionId;
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const idMentionsToDelete =
        !idFundingSource ||
        isNaN(idFundingSource) ||
        !recordId ||
        isNaN(recordId)
          ? state[tabName.FUNDINGS].idsToDelete.fundingMentions
          : [
              ...state[tabName.FUNDINGS].idsToDelete.fundingMentions,
              { idFundingSource, idFunding: recordId },
            ];
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: deleteMention(
            state[tabName.FUNDINGS].records,
            recordId,
            idFundingSource
          ),
          idsToDelete: {
            ...state[tabName.FUNDINGS].idsToDelete,
            fundingMentions: idMentionsToDelete,
          },
          edited: true,
        },
      };
    }
    case actions.DELETE_FUNDING: {
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const idFundingsToDelete =
        !recordId || isNaN(recordId)
          ? state[tabName.FUNDINGS].idsToDelete.idFunding
          : [...state[tabName.FUNDINGS].idsToDelete.idFunding, recordId];
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          records: omit(state[tabName.FUNDINGS].records, recordId),
          recordsOrder: deleteRecordId(
            state[tabName.FUNDINGS].recordsOrder,
            recordId
          ),
          idsToDelete: {
            ...state[tabName.FUNDINGS].idsToDelete,
            idFunding: idFundingsToDelete,
          },
          edited: true,
        },
      };
    }
    case actions.DELETE_SALES_METRIC: {
      const recordId = parseInt(action.recordId, 10) || action.recordId;
      const idMetricsToDelete =
        !recordId || isNaN(recordId)
          ? state[tabName.SALES_METRICS].idsToDelete.idMetrics
          : [...state[tabName.SALES_METRICS].idsToDelete.idMetrics, recordId];
      return {
        ...state,
        [tabName.SALES_METRICS]: {
          ...state[tabName.SALES_METRICS],
          records: omit(state[tabName.SALES_METRICS].records, recordId),
          recordsOrder: deleteRecordId(
            state[tabName.SALES_METRICS].recordsOrder,
            recordId
          ),
          idsToDelete: {
            ...state[tabName.SALES_METRICS].idsToDelete,
            idMetrics: idMetricsToDelete,
          },
          edited: true,
        },
      };
    }
    case actions.DELETE_VC_SOURCE: {
      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          records: deleteVcSource(
            state[tabName.VC_FUNDS].records,
            action.recordId,
            action.sourceId
          ),
        },
      };
    }
    case actions.DELETE_VC_CIK: {
      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          records: deleteVcCik(
            state[tabName.VC_FUNDS].records,
            action.recordId,
            action.cikId
          ),
        },
      };
    }
    case actions.SET_SALES_METRICS: {
      const records = mapSalesMetricInfo(action.salesMetricInfo);
      const recordsObj = keyBy(records, 'id');
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          originalRecords: recordsObj,
          records: recordsObj,
          recordsOrder: saveRecordsOrder(records),
          idsToDelete: initialState[action.currentTab].idsToDelete,
        },
      };
    }
    case actions.ADD_SALES_METRIC_SOURCE: {
      const nextRecords = addSalesMetricSource(
        state[tabName.SALES_METRICS].records,
        action.recordId,
        action.value
      );
      return {
        ...state,
        [tabName.SALES_METRICS]: {
          ...state[tabName.SALES_METRICS],
          records: {
            ...nextRecords,
            [action.recordId]: {
              ...nextRecords[action.recordId],
              edited: true,
            },
          },
          edited: true,
        },
      };
    }
    case actions.INITIALIZE_HQ_ADDRESS: {
      if (state[tabName.GENERAL_INFO].isHydrated) return state;

      const timeStamp = Date.now();
      const tsKey = `new_${timeStamp}`;
      return {
        ...state,
        [tabName.GENERAL_INFO]: {
          ...state[tabName.GENERAL_INFO],
          records: [emptyHqAddressObject(tsKey)],
          recordsOrder: [tsKey],
        },
      };
    }
    case actions.UPDATE_COMPANY_STATUS_FROM_FUNDING: {
      return {
        ...state,
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          shouldUpdateCompanyStatus: action.value,
        },
      };
    }
    case actions.RESET_TABLES_EDIT_STATUS: {
      return {
        ...state,
        [tabName.GENERAL_INFO]: {
          ...state[tabName.GENERAL_INFO],
          edited: false,
        },
        [tabName.FUNDINGS]: {
          ...state[tabName.FUNDINGS],
          edited: false,
        },
        [tabName.SALES_METRICS]: {
          ...state[tabName.SALES_METRICS],
          edited: false,
        },
        [tabName.COMPETITORS]: {
          ...state[tabName.COMPETITORS],
          edited: false,
        },
      };
    }
    case actions.SET_VC_FUNDS_TABLE_ERRORS: {
      return {
        ...state,
        [tabName.VC_FUNDS]: {
          ...state[tabName.VC_FUNDS],
          recordsOrder: [...state[tabName.VC_FUNDS].recordsOrder],
          errors: action.errors,
        },
      };
    }
    case actions.UDPATE_FUNDING_LOCAL: {
      return updateFundingLocal(state, action);
    }
    default:
      return state;
  }
}
