const styles = (theme) => ({
  iconWrapperRed: {
    marginTop: '15px',
    color: '#f50057',
  },
  iconWrapperOrange: {
    marginTop: '15px',
    color: 'orange',
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '1.2rem',
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontWeight: 'bold',
  },
  text: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#000',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  root: {
    marginLeft: '5px',
  },
  marketName: {
    paddingTop: '10px',
    fontWeight: 'bold',
    color: '#000',
  },
  textBlock: {
    paddingTop: '10px',
  },
});

export default styles;
