import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { filter, map } from 'lodash';

import Button from 'client/component-library/buttons/Button';
import MenuItem from 'client/component-library/Menu/MenuItem';
import Checkbox from 'client/component-library/Checkbox/Checkbox';
import Input from 'client/component-library/Input/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'client/component-library/Select/MuiSelect/MuiSelect';
import ListItemText from 'client/component-library/List/ListItemText/ListItemText';
import FormControl from '@material-ui/core/FormControl';

import {
  selectStyle,
  formControlStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import { getAllLabels } from '../redux/actions/search';

class ExcludeLabelsSelect extends Component {
  UNSAFE_componentWillMount() {
    this.props.getAllLabels();
  }

  addExcludeLabel = (event) => {
    this.props.addExcludeLabel(event.target);
  };

  render() {
    return (
      <div>
        <div className="flex flex-col">
          <div className="flex flex-shrink">
            <FormControl style={formControlStyle} onBlur={this.props.filter}>
              <InputLabel shrink htmlFor="select-multiple-checkbox">
                Filter By Label
              </InputLabel>
              <Select
                multiple
                displayEmpty
                value={this.props.excludeLabels}
                onChange={this.addExcludeLabel}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={(selected) =>
                  filter(
                    this.props.labels,
                    (x) => selected.indexOf(x) === -1
                  ).join(', ')
                }
                style={selectStyle}
              >
                {map(this.props.labels, (label) => (
                  <MenuItem key={label} value={label}>
                    <Checkbox
                      checked={this.props.excludeLabels.indexOf(label) === -1}
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <div className="flex">
              <div className="flex flex-shrink">
                <Button onClick={this.props.filter}>Filter</Button>
              </div>
              <div className="flex flex-shrink">
                <Button onClick={this.props.clearExcludeLabels}>All</Button>
              </div>
              <div>
                <Button onClick={this.props.allExcludeLabels}>None</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    labels: dataPlatform.search.labels,
  };
}

ExcludeLabelsSelect.propTypes = {
  addExcludeLabel: PropTypes.func.isRequired,
  getAllLabels: PropTypes.func.isRequired,
  clearExcludeLabels: PropTypes.func.isRequired,
  allExcludeLabels: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  excludeLabels: PropTypes.arrayOf(PropTypes.string),
};

ExcludeLabelsSelect.defaultProps = {
  labels: [],
  excludeLabels: [],
};

export default connect(mapStateToProps, { getAllLabels })(ExcludeLabelsSelect);
