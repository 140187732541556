export const fetchApprovedStoriesSuggestions = (searchTerm) => ({
  types: [null, null],
  service: {
    endpoint: 'SearchAdminStoryByName',
    name: 'storyservice',
    body: {
      query: searchTerm,
      limit: 10,
    },
  },
});

export const getTeamsByStoryRequest = ({
  idStory,
  filterInfo,
  sortInfo,
  renewalDateRange,
  limit,
  offset,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'GetTeamsByStory',
    name: 'storyservice',
    body: {
      idStory,
      filterInfo,
      sortInfo,
      renewalDateRange,
      limit: limit || 10,
      offset,
    },
  },
});

export const getStoriesByTeamRequest = ({
  idTeam,
  sortInfo,
  filterInfo,
  limit,
  offset,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'GetStoriesByTeam',
    name: 'storyservice',
    body: {
      idTeam,
      sortInfo,
      filterInfo,
      limit: limit || 10,
      offset,
    },
  },
});

export const getClientTeamMembersStoryServiceRequest = (idStory, idTeam) => ({
  types: [null, null],
  service: {
    endpoint: 'GetTeamPermissions',
    name: 'storyservice',
    body: {
      idStory,
      idTeam,
    },
  },
});

export const getClientTeamMembersAdminServiceRequest = (idTeam) => ({
  types: [null, null],
  service: {
    endpoint: 'GetTeamMembers',
    name: 'adminservice',
    body: {
      idTeams: [idTeam],
    },
  },
});

export const getSlugs = ({
  query,
  sortInfo,
  filterInfo,
  createdDateRange,
  limit,
  offset,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'GetSlugs',
    name: 'storyservice',
    body: {
      query,
      sortInfo,
      filterInfo,
      createdDateRange,
      limit: limit || 10,
      offset,
    },
  },
});

export const createSlug = ({ idStory, slug }) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateSlug',
    name: 'storyservice',
    body: {
      idStory,
      slug,
    },
  },
});

export const cloneStory = ({ idStory, newOwnerIdUser }) => ({
  types: [null, null],
  service: {
    endpoint: 'CloneStory',
    name: 'storyservice',
    body: {
      idStory,
      newOwnerIdUser,
    },
  },
});

export const updatePermissions = ({ updatePermissionsInfo }) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePermissions',
    name: 'storyservice',
    body: {
      updatePermissionsInfo,
    },
  },
});

export const bulkUpdatePermissions = ({
  parentIdStory,
  accessLevel,
  teamStories,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'BulkEditAccess',
    name: 'storyservice',
    body: {
      parentIdStory,
      accessLevel,
      teamStories,
    },
  },
});

export default fetchApprovedStoriesSuggestions;
