import React from 'react';
import {
  FilterGroupContainer,
  NewFilterGroupContainer,
} from 'client/modules/news/filter-groups/components/FilterGroupForm';
import { useLocation } from 'react-router-dom';
import { FilterGroupMenu } from '../components/FilterGroupMenu';

export const ManageFilterGroups = () => {
  const location = useLocation();
  const isNewPage = location.pathname.includes('add');
  return (
    <div className="flex container mx-auto mt-8 mb-4 filter-groups-container">
      <div className="w-2/12">
        <FilterGroupMenu />
      </div>
      <div className="w-10/12">
        {isNewPage ? <NewFilterGroupContainer /> : <FilterGroupContainer />}
      </div>
    </div>
  );
};
