import moment from 'moment';

export const formatDate = (date, format = 'M/D/YYYY') => {
  return date && date.length > 0 ? moment(date).format(format) : '';
};

export const formatUtcDateToLocal = (date, format = 'M/D/YYYY') => {
  return date && date.length > 0 ? moment.utc(date).local().format(format) : '';
};

export const getRelativeUtcTimeFromNow = (date) => {
  return date && date.length > 0 ? moment.utc(date).fromNow() : '';
};

// TODO do we actually need this or will one of the above funtions work?
export const formatDateWithFormat = (
  date,
  currentFormat,
  format = 'M/D/YYYY'
) => {
  return date && date.length > 0
    ? moment(date, currentFormat).format(format)
    : '';
};

export const formatDateToUnix = (date) => {
  return date && date.length > 0 ? moment(date).valueOf() : '';
};

export const formatLocalDateStartToUtcTime = (
  date,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (date) {
    const dateStart = moment(date).startOf('day').format(format);
    const utcTime = new Date(dateStart).toUTCString();
    return moment.utc(utcTime).format(format);
  }
  return '';
};

export const formatLocalDateEndToUtcTime = (
  date,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (date) {
    const dateEnd = moment(date).endOf('day').format(format);
    const utcTime = new Date(dateEnd).toUTCString();
    return moment.utc(utcTime).format(format);
  }
  return '';
};
