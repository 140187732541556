import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

/**
 * @deprecated
 * It takes a `props` object, and returns a `<components.Option>` component with the `props` object
 * passed to it, and with an additional `data-test` attribute
 * @param props - The props passed to the component.
 * @returns A component that renders an option.
 */
export function BasicOption(props) {
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-test': 'simple-select-option',
      }}
    />
  );
}

BasicOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
};
