import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { formatUtcDateToLocal } from 'client/modules/common/utils/timeFormattingMethods';
import createFullName from 'client/modules/common/utils/createFullName';
import styles from '../styles/table-styles.css';

class ReviewerCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { reviewer, reviewDate, id } = this.props;
    if (reviewer.firstName || reviewer.lastName) {
      const fullName = createFullName(reviewer.firstName, reviewer.lastName);
      return (
        <div data-test={`${id}`}>
          <span className={styles.spanBold}>Reviewer: </span>
          {fullName} {formatUtcDateToLocal(reviewDate, 'YYYY-MM-DD HH:mm:ss')}
        </div>
      );
    }
    return (
      <div>
        <span className={styles.spanBold}>Reviewer: </span>none
      </div>
    );
  }
}

ReviewerCell.propTypes = {
  reviewer: PropTypes.object,
  reviewDate: PropTypes.string,
  id: PropTypes.string,
};

ReviewerCell.defaultProps = {
  reviewer: {},
  reviewDate: '',
  id: '',
};

export default ReviewerCell;
