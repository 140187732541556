import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'client/component-library/Tooltip/Tooltip';

const HealthIcon = (props) => {
  const circleStyle = {
    display: 'inline-flex',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'white',
  };

  const convertHealthToColor = (health) => {
    switch (health) {
      case 'GREEN': {
        circleStyle.backgroundColor = '#00986b';
        break;
      }
      case 'YELLOW': {
        circleStyle.backgroundColor = '#f4eb42';
        break;
      }
      case 'RED': {
        circleStyle.backgroundColor = '#e5323e';
        break;
      }
      default: {
        circleStyle.backgroundColor = '#757575';
        break;
      }
    }
  };
  convertHealthToColor(props.health);
  return (
    <Tooltip title={`Health Status ${props.health}`}>
      <div style={circleStyle} />
    </Tooltip>
  );
};

HealthIcon.propTypes = {
  health: PropTypes.string,
};

HealthIcon.defaultProps = {
  health: '',
};

export default HealthIcon;
