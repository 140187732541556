import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from '../tab-names';
import {
  storiesTableColumnNames,
  storiesInnerTableColumnNames,
  hiddenRowColumns,
  slugsTableColumnNames,
  teamsTableColumnNames,
} from './stories-table';

const getTableStructure = (currentTab) => {
  switch (currentTab) {
    case UPDATE_ACCESS_BY_STORY:
      return storiesTableColumnNames;
    case 'storiesInnerTable':
      return storiesInnerTableColumnNames;
    case 'hiddenRowColumns':
      return hiddenRowColumns;
    case MANAGE_SLUGS:
      return slugsTableColumnNames;
    case UPDATE_ACCESS_BY_TEAM:
      return teamsTableColumnNames;
    default:
      return [];
  }
};

export default getTableStructure;
