import { map } from 'lodash';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import * as actions from '../actions/record-status-filters';

export const formatFilters = (filters) => {
  if (!filters) {
    return [];
  }
  return map(filters, (value, key) => {
    return { id: parseInt(key, 10), text: value };
  });
};

const initialState = {
  selectedRecordStatusFilterName: actions.NEEDS_APPROVAL,
  selectedRecordStatusFilterId: 2,
  recordStatusFilterOptions: [],
};

export default function (state = {}, action) {
  switch (action.type) {
    case actions.INITIALIZE_RECORD_STATUS_FILTERS: {
      const {recordStatusFilters} = action;
      const recordStatusFilterOptions = formatFilters(
        action.recordStatusFilters
      );
      const selectedFilterId = parseInt(action.selectedFilterId, 10);

      let filterName = initialState.selectedRecordStatusFilterName;
      if (selectedFilterId) {
        filterName = getKeyValueFromListViaOtherKey(
          recordStatusFilterOptions,
          'id',
          selectedFilterId,
          'text'
        );
      }

      return {
        recordStatusFilters,
        recordStatusFilterOptions,
        selectedRecordStatusFilterId:
          selectedFilterId || initialState.selectedRecordStatusFilterId,
        selectedRecordStatusFilterName: filterName,
      };
    }
    case actions.UPDATE_RECORD_STATUS_FILTER: {
      // Only allow users to filter on filter status's they have access to.
      if (!state.recordStatusFilters[action.selectedRecordStatusFilterId]) {
        return state;
      }
      updateUrlParams(
        'selectedRecordStatusFilterId',
        action.selectedRecordStatusFilterId
      );
      const filterName = getKeyValueFromListViaOtherKey(
        state.recordStatusFilterOptions,
        'id',
        action.selectedRecordStatusFilterId,
        'text'
      );
      return {
        ...state,
        selectedRecordStatusFilterId: action.selectedRecordStatusFilterId,
        selectedRecordStatusFilterName: filterName,
      };
    }
    default:
      return state;
  }
}
