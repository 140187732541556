import React from 'react';
import PropTypes from 'prop-types';
import { logInfo } from 'client/modules/common/utils/logInfo';

/**
 * @deprecated
 * ErrorBoundary is a React component that catches JavaScript errors anywhere in its child component
tree, logs those errors, and displays a fallback UI instead of the component tree that crashed */
class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  componentDidCatch(error, info) {
    logInfo({
      error,
      message: this.props.errorMessage,
      method: info.componentStack,
    });
  }

  render() {
    const { errorMessage } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>{errorMessage}</h1>
          <h3>{error && error.toString()}</h3>
          <h4>Please see console for more info!</h4>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  errorMessage: 'Somthing Went Wrong.',
};

export default ErrorBoundary;
