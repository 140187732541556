export const teamPermissions = {
  pageId: 6,
  name: 'User/Team Management',
  path: 'teams',
  sections: {
    1: {
      name: 'Change Package',
      sectionId: 11,
      path: 'change-package',
    },
    2: {
      name: 'Login as User',
      sectionId: 12,
      path: 'login-as-user',
    },
    3: {
      name: 'SSO Admin',
      sectionId: 13,
      path: 'sso-admin',
    },
    4: {
      name: 'Purge User Data',
      sectionId: 14,
      path: 'purge-user-data',
    },
  },
} as const;
