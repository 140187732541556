import { marketSuggestions } from 'client/modules/markets/redux/utils/api-requests-helper';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import {
  updateSuggestionsHelper,
  updateInputInfoTermId,
  updateSuggestionTypeError,
} from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/autocomplete-input';

export const updateAnalystSuggestions = (term, offset, limit) => ({
  types: [null, null],
  service: {
    endpoint: 'suggestAnalyst',
    name: 'marketservice',
    body: {
      searchTerm: term ? term.trim() : '',
      offset: offset || 0,
      limit: limit || 10,
    },
  },
});

export const updateGeoSuggestions = (term, offset, limit) => ({
  types: [null, null],
  service: {
    endpoint: 'suggestMarketGeos',
    name: 'marketservice',
    body: {
      searchTerm: term ? term.trim() : '',
      offset: offset || 0,
      limit: limit || 10,
    },
  },
});

export const marketSuggestionHandler = (
  dispatch,
  inputId,
  term,
  suggestionType
) => {
  if (suggestionType === 'market') {
    return dispatch(marketSuggestions(term)).then((results) => {
      const markets = results ? results.markets : [];
      const termId =
        getKeyValueFromListViaOtherKey(markets, 'name', term, 'id') || 0;
      dispatch(updateSuggestionsHelper(term, inputId, markets));
      return dispatch(updateInputInfoTermId(inputId, term, termId));
    });
  } if (suggestionType === 'geography') {
    return dispatch(updateGeoSuggestions(term)).then((results) => {
      return dispatch(
        updateSuggestionsHelper(term, inputId, results ? results.geos : [])
      );
    });
  } if (suggestionType === 'analyst') {
    return dispatch(updateAnalystSuggestions(term)).then((results) => {
      return dispatch(
        updateSuggestionsHelper(term, inputId, results ? results.analysts : [])
      );
    });
  }
  return dispatch(updateSuggestionTypeError());
};

export default marketSuggestionHandler;
