import { useGetUserInfoQuery } from 'client/utils/permissions-validation/hooks/UseGetUserInfoQuery';
import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Not granular permission system
 * For generally non-DIO page permissions that uses admin-service
 * If the user doesn't have access to the path, redirect them to previous level in location.pathname
 * @param  - pageId - the id of the adminPage you want to check for access
 * @returns A ReactElement
 */
export const UserProtectedRoute = ({
  pageId,
  children,
}: {
  pageId: number;
  children?: ReactElement;
}): ReactElement => {
  const getUserInfoQuery = useGetUserInfoQuery();

  if (getUserInfoQuery.isFetching) {
    return null;
  }

  if (getUserInfoQuery.data?.adminPagePermissions?.[pageId] !== 1) {
    return <Navigate to="/403" replace />;
  }

  if (!children) {
    return <Outlet />;
  }

  return children;
};
