import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTES } from './constants';

const ValuationsNav = React.lazy(() => import('./components/ValuationsNav'));
const DocumentQueue = React.lazy(() => import('./components/DocumentQueue'));
const DocumentTable = React.lazy(() => import('./components/DocumentTable'));

const { documentQueue, documentTable } = ROUTES;

export const valuationRoutes: RouteObject = {
  path: ROUTES.root.path,
  children: [
    {
      path: '/valuation',
      element: (
        <Navigate to={`/${ROUTES.root.path}/${ROUTES.documentTable.path}`} />
      ),
    },
    {
      element: (
        <React.Suspense fallback={undefined}>
          <ValuationsNav />
        </React.Suspense>
      ),
      children: [
        {
          path: documentQueue.path,
          element: (
            <React.Suspense fallback={undefined}>
              <DocumentQueue />
            </React.Suspense>
          ),
        },
        {
          path: documentTable.path,
          element: (
            <React.Suspense fallback={undefined}>
              <DocumentTable />
            </React.Suspense>
          ),
        },
      ],
    },
  ],
};
