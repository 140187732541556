import { request } from 'client/modules/common/utils/request';

export interface UserAuth {
  isLoggedIn: boolean;
  isAdminUser: boolean;
}

export const initialAppValidation = <TResponse = boolean | UserAuth>(
  validationType: 'checkIfUserIsAuthenticated' | 'setMetadata'
): Promise<TResponse> =>
  request({
    url: `/${validationType}`,
    body: {
      url: '/',
    },
  }).then((r) => {
    return r.body as TResponse;
  });
