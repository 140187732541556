import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const ProprietaryInsightsPage = React.lazy(
  () => import('./containers/ProprietaryInsights')
);

export const proprietaryInsightsRoutes: RouteObject[] = [
  {
    path: 'propietary-insights',
    element: <Navigate to="/proprietary-insights" />,
  },
  {
    path: 'proprietary-insights',
    children: [
      {
        path: '',
        element: (
          <React.Suspense fallback={undefined}>
            <ProprietaryInsightsPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
