import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { find, compact, unescape } from 'lodash';
import UploadScreen from './UploadScreen';
import EditScreen from './EditScreen';
import getImageDimensionsFromSrc from './getImageDimensionsFromSrc';
import styles from './image-editor.css';

const makeImg = (imgSrc) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = imgSrc;
  });

const canvasToImage = (cnvs) =>
  new Promise((resolve, reject) => {
    const imgDest = new Image();
    cnvs.toBlob((blob) => {
      try {
        const url = URL.createObjectURL(blob);
        imgDest.onload = () => {
          resolve(imgDest);
        };
        imgDest.src = url;
      } catch (e) {
        reject(e);
      }
    });
  });

const finalizeCrop = (img, crop) =>
  new Promise((resolve) => {
    makeImg(img.preview).then((loadedImg) => {
      const imageWidth = loadedImg.naturalWidth;
      const imageHeight = loadedImg.naturalHeight;
      const scaled = (num) => num / 100;
      const cropX = scaled(crop.x * imageWidth);
      const cropY = scaled(crop.y * imageHeight);
      const cropWidth = scaled(crop.width * imageWidth);
      const cropHeight = scaled(crop.height * imageHeight);
      const canvas = document.createElement('canvas');
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        loadedImg,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      return canvasToImage(canvas).then(resolve, () => {
        resolve(img);
      });
    });
  });

/**
 * @deprecated
 * It's a React component that renders a screen for uploading an image, and a screen for editing an
 * image
 */
const ImageEditor = ({
  isImage = false,
  onSave,
  error = null,
  onCancel,
  isGoogleSearchEnabled = false,
  canPasteURL = true,
  companyLogo,
}) => {
  const [imageEditorData, setImageEditorData] = useState({
    image: null,
    screen: 0,
    error: null,
    url: '',
  });

  const getItemsFromEvent = (event) => {
    if (event.clipboardData) {
      return event.clipboardData.items[0];
    }
    if (event.dataTransfer) {
      const textItem = find(event.dataTransfer.items, (i) => {
        return i.type === 'text/html';
      });
      return textItem;
    }
    return false;
  };

  const extractImgSrcFromGoogleImageUrl = (imgUrl) => {
    try {
      const url = new URL(imgUrl);
      if (url.hostname.match('google')) {
        return url.searchParams.get('imgurl');
      }
    } catch (e) {
      setImageEditorData({ ...imageEditorData, error: 'invalidGoogleImg' });
    }
    return unescape(imgUrl);
  };

  const onPaste = (event) => {
    const item = getItemsFromEvent(event);
    if (!item) {
      return;
    }

    if (item.kind === 'string') {
      item.getAsString((result) => {
        const srcRegex =
          /<img[^>]+src="(http|https:\/\/[^">]+)"|<img[^>]+src="(data:image[^">]+)"/g;
        const srcMatches = srcRegex.exec(result);
        const srcMatch = compact(srcMatches).pop();
        let imgSrcUrl = result;
        if (srcMatch) {
          imgSrcUrl = extractImgSrcFromGoogleImageUrl(srcMatch);
        }
        let src = `/reflect/${imgSrcUrl}`;
        const nonReflectedProtocols = ['blob:', 'data:image'];

        // don't reflect if a data:image
        const shouldNotReflect = nonReflectedProtocols.some((protocol) =>
          imgSrcUrl.startsWith(protocol)
        );
        if (shouldNotReflect) {
          src = imgSrcUrl;
        }

        if (src.includes('.svg')) {
          alert(
            'Is this an SVG file? If so please note we do not support SVGs.'
          );
        }

        getImageDimensionsFromSrc(src).then(
          ([width, height]) =>
            setImageEditorData({
              ...imageEditorData,
              image: {
                width,
                height,
                src,
                preview: src,
              },
              error: null,
            }),
          () => {
            setImageEditorData({ ...imageEditorData, error: 'invalidType' });
          }
        );
      });
    } else if (item.kind === 'file' && item.type.match('^image/')) {
      const file = item.getAsFile();
      file.preview = URL.createObjectURL(file);

      setImageEditorData({ ...imageEditorData, image: file, error: null });
    }
  };

  const onUrlChange = (url) => {
    setImageEditorData({ ...imageEditorData, url, error: null });
  };

  const formatErrorMessage = (err) => {
    const ErrNode = ({ text }) => (
      <span className="image-upload-error">{text}</span>
    );

    ErrNode.propTypes = {
      text: PropTypes.string,
    };

    switch (err) {
      case 'imgSize':
        return (
          <ErrNode text="Your upload failed because the image size is > 3MB; try uploading a smaller image" />
        );
      case 'invalidType':
        return (
          <ErrNode text="The url you provided is not a valid url. Try downloading the image." />
        );
      case 'invalidGoogleImg':
        return (
          <ErrNode text="The google image cannot be processed. Try downloading the image." />
        );
      default:
        return (
          <ErrNode text="Hey! Your image didn't upload correctly. Please try again" />
        );
    }
  };

  const handleNext = () => {
    if (!imageEditorData.image) {
      return;
    }
    setImageEditorData({ ...imageEditorData, screen: 1, error: null });
  };

  const handlePrev = () =>
    setImageEditorData({ ...imageEditorData, screen: 0, error: null });

  const handleSelectImage = ([image]) => {
    if (!image) {
      return;
    }
    setImageEditorData({ ...imageEditorData, image, error: null });
  };

  const updateCrop = (crop, updatePreview = true) =>
    finalizeCrop(imageEditorData.image, crop).then((editedImage) => {
      if (editedImage.src !== imageEditorData.image.src) {
        if (updatePreview) {
          editedImage.preview = editedImage.src; // eslint-disable-line no-param-reassign
        } else {
          editedImage.preview = imageEditorData.image.preview; // eslint-disable-line no-param-reassign
        }
        URL.revokeObjectURL(imageEditorData.image.preview);
        setImageEditorData({ ...imageEditorData, image: editedImage });
      }
    });

  const uploadType = isImage ? 'Image' : 'Logo';
  let content;
  if (imageEditorData.screen === 0) {
    content = (
      <UploadScreen
        url={imageEditorData.url}
        image={imageEditorData.image}
        onUrlChange={onUrlChange}
        onSave={onSave}
        onNext={handleNext}
        onSelectImage={handleSelectImage}
        onPaste={onPaste}
        onCancel={onCancel}
        isGoogleSearchEnabled={isGoogleSearchEnabled}
        canPasteURL={canPasteURL}
        companyLogo={companyLogo}
      />
    );
  } else {
    content = (
      <EditScreen
        updateCrop={updateCrop}
        onSave={onSave}
        onCancel={handlePrev}
        image={imageEditorData.image}
      />
    );
  }
  const errorType = error || imageEditorData.error;
  const errorMessage = errorType ? formatErrorMessage(errorType) : null;

  return (
    <div className={styles['image-editor-inner-wrap']}>
      <h2 className={styles['image-editor-title']}>
        {imageEditorData.screen === 0
          ? `${uploadType} Upload`
          : `${uploadType} Upload`}
      </h2>
      {errorMessage}
      <Divider />
      {content}
    </div>
  );
};

ImageEditor.propTypes = {
  isImage: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isGoogleSearchEnabled: PropTypes.bool,
  canPasteURL: PropTypes.bool,
  companyLogo: PropTypes.shape({
    companyName: PropTypes.string,
    primaryUrl: PropTypes.string,
  }),
};

export default ImageEditor;
