import {
  PublicFinancialServiceKeys,
  useCreateCbiEntityEODMapping,
  useDeleteCbiEntityEODMappings,
  useUpdateCbiEntityEODMappings,
} from 'client/modules/cbi-entity/services/financials/hooks/usePublicFinancialService';

import { FormikOnSubmit } from 'client/modules/common/form/containers/FormikContainer';
import { message } from 'antd';
import { useQueryClient } from 'react-query';
import {
  FinancialFormFields,
  FinancialFormStatus,
  FinancialFormValues,
  US_SHORT_CODE,
} from './useFinancials';

const handleCreateSuccess =
  (msg: string, refreshLastFinancialData: () => void) => () => {
    message.success(msg);
    refreshLastFinancialData();
  };

const handleError = (error: $TSFixMe) => {
  message.error({
    content:
      error?.response?.body?.error?.details ||
      error?.message ||
      'An error has ocurred, please contact an Administrator',
  });
};

const mutationOptions = (successMsg: string, refreshLastFinancialData) => ({
  onSuccess: handleCreateSuccess(successMsg, refreshLastFinancialData),
  onError: handleError,
});

export const useFinancialForm = () => {
  const createMutation = useCreateCbiEntityEODMapping();
  const updateMutation = useUpdateCbiEntityEODMappings();
  const deleteMutation = useDeleteCbiEntityEODMappings();
  const queryClient = useQueryClient();
  const invalidateAdminFinancialData = () =>
    queryClient.invalidateQueries([
      PublicFinancialServiceKeys.GetAdminFinancialData,
    ]);

  const handleDelete = (idCbiEntity: number) => {
    return deleteMutation.mutateAsync(
      { idCbiEntity },
      mutationOptions(
        'Financials deleted successfully',
        invalidateAdminFinancialData
      )
    );
  };

  const handleCreateOrUpdate = (
    { idCbiEntity, company }: FinancialFormValues,
    initialValues: FinancialFormValues,
    { exchange }: FinancialFormStatus
  ) => {
    let symbol = String(company[0].ticker.value);
    const idQuartrCompany = company[0]?.quartrCompany?.key;
    const exchangeShortCode = String(company[0].shortExchangeCode.value);
    if (exchangeShortCode === US_SHORT_CODE) {
      [, symbol] = symbol.split('-');
    }

    const mutationParams = {
      idCbiEntity,
      symbol,
      exchange,
      exchangeShortCode,
      idQuartrCompany,
    };

    if (initialValues.company.length === 0 && company.length > 0) {
      return createMutation.mutateAsync(
        mutationParams,
        mutationOptions(
          'Financials created successfully',
          invalidateAdminFinancialData
        )
      );
    }

    return updateMutation.mutateAsync(
      mutationParams,
      mutationOptions(
        'Financials updated successfully',
        invalidateAdminFinancialData
      )
    );
  };

  const formIsEqual = (values, initialValues) => {
    const companyValues = values[FinancialFormFields.Company][0];
    const companyInitialValues = initialValues[FinancialFormFields.Company][0];

    const tickerMatch =
      companyValues &&
      companyInitialValues &&
      companyValues[FinancialFormFields.Ticker]?.value ===
        companyInitialValues[FinancialFormFields.Ticker]?.value;
    const exchangeCodeMatch =
      companyValues &&
      companyInitialValues &&
      companyValues[FinancialFormFields.ShortExchangeCode]?.value ===
        companyInitialValues[FinancialFormFields.ShortExchangeCode]?.value;

    const quartrCompanyMatch =
      companyValues &&
      companyInitialValues &&
      companyValues[FinancialFormFields.QuartrCompany]?.key ===
        companyInitialValues[FinancialFormFields.QuartrCompany]?.key;

    return tickerMatch && exchangeCodeMatch && quartrCompanyMatch;
  };

  const handleSubmit: FormikOnSubmit<
    FinancialFormValues,
    FinancialFormStatus
  > = (values, { initialValues, status }) => {
    if (values?.company?.length === 0) {
      return handleDelete(values?.idCbiEntity);
    }

    return formIsEqual(values, initialValues)
      ? Promise.resolve(true)
      : handleCreateOrUpdate(values, initialValues, status);
  };

  return { handleSubmit };
};
