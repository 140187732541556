import React from 'react';
import PropTypes from 'prop-types';
import { Description } from 'client/modules/service-provider/components/EntityInfoHeader';

export function Article({
  article,
  onRemoveArticle,
  canRemove,
  clusterId,
  clusterType,
  index,
}) {
  const _onRemoveArticle = () => {
    onRemoveArticle(article, clusterType, clusterId);
  };

  return (
    <div className="bg-gray-200 shadow overflow-hidden rounded-md py-2 my-2">
      <div className="pl-2">
        <div className="py-2">
          <a
            className="text-blue-500 text-lg underline"
            href={article.url}
            target="_blank"
            rel="noreferrer"
          >
            {article.title}
          </a>
        </div>
        <Description description={article.description} className="text-sm" />
      </div>
      {canRemove && (
        <div className="p-1">
          <button
            type="button"
            onClick={_onRemoveArticle}
            className="pl-1 text-red-500"
            data-test={`remove-article-from-cluster-button-${index}`}
          >
            Remove from cluster
          </button>
        </div>
      )}
    </div>
  );
}

Article.propTypes = {
  article: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onRemoveArticle: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  clusterId: PropTypes.number.isRequired,
  clusterType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
