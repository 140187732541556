import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { adminTheme } from './styles/adminMaterialTheme';

export const adminStyles = createMuiTheme(adminTheme as $TSFixMe);

const ProdRoot = ({ children, store }) => {
  return (
    <MuiThemeProvider theme={adminStyles}>
      <Provider store={store}>{children}</Provider>
    </MuiThemeProvider>
  );
};

ProdRoot.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export { ProdRoot };
