import * as actions from '../actions/current-tab';

const initialState = 'marketSizingDetails';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_TAB_NAME:
      return action.tabName || initialState;
    default:
      return state;
  }
}
