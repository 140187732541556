import { getCurrentTab } from '../utils/records-helper';

export const UPDATE_PAGE_NUMBER =
  'client/modules/markets/actions/data-details/UPDATE_PAGE_NUMBER';
export const UPDATE_RECORDS_PER_PAGE =
  'client/modules/markets/actions/data-details/UPDATE_RECORDS_PER_PAGE';
export const INITIALZE_DATA_DETAILS =
  'client/modules/markets/actions/data-details/INITIALZE_DATA_DETAILS';
export const CLEAR_DATA_DETAILS =
  'client/modules/markets/actions/data-details/CLEAR_DATA_DETAILS';
export const UPDATE_SORT_ORDER =
  'client/modules/markets/actions/data-details/UPDATE_SORT_ORDER';
export const UPDATE_TOTAL_RECORDS =
  'client/modules/markets/actions/data-details/UPDATE_TOTAL_RECORDS';
export const DISABLE_EDITING_ROWS =
  'client/modules/markets/actions/data-details/DISABLE_EDITING_ROWS';
export const ENABLE_EDITING_ROWS =
  'client/modules/markets/actions/data-details/ENABLE_EDITING_ROWS';
export const UPDATE_IS_CHILD_MARKET =
  'client/modules/markets/actions/data-details/UPDATE_IS_CHILD_MARKET';

// DataDetails // Note: all of this stuff should be reflected in the url
//   CurrentTab
//   TabDetails:
//     - This is a map, with keys being the tabNames, fields being...
//       - limit
//       - offset (page number)
//       - sort direction & sort type
//       - status (e.g. need review, rejected, approved)

export function initializeDataDetailsFromUrlParams(urlParams) {
  return getCurrentTab((currentTab) => {
    return {
      type: INITIALZE_DATA_DETAILS,
      ...urlParams,
      currentTab,
    };
  });
}

export function clearDataDetails() {
  return getCurrentTab((currentTab) => {
    return {
      type: CLEAR_DATA_DETAILS,
      currentTab,
    };
  });
}

export function updatePageNumber(pageNumber) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_PAGE_NUMBER,
      pageNumber,
      currentTab,
    };
  });
}

export function updateRecordsPerPage(recordsPerPage) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_RECORDS_PER_PAGE,
      recordsPerPage,
      currentTab,
    };
  });
}

export function updateSortOrder(sortField, sortDirection) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SORT_ORDER,
      sortField,
      sortDirection,
      currentTab,
    };
  });
}

export function updateTotalRecords(totalRecords) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_TOTAL_RECORDS,
      totalRecords,
      currentTab,
    };
  });
}

export function disableEditingRows() {
  return getCurrentTab((currentTab) => {
    return {
      type: DISABLE_EDITING_ROWS,
      currentTab,
    };
  });
}

export function enableEditingRows() {
  return getCurrentTab((currentTab) => {
    return {
      type: ENABLE_EDITING_ROWS,
      currentTab,
    };
  });
}

export function updateIsChildMarket(isChild) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_IS_CHILD_MARKET,
      isChild,
      currentTab,
    };
  });
}
