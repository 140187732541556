import { UseQueryResult, useQuery } from 'react-query';
import { GetUserLoginHistoryResponse } from '@cbinsights/adminservice/adminservice';
import { GetUserLoginHistory } from '.';
import { getUserLoginHistory } from '../api/GetUserLoginHistory';

type Params = Parameters<typeof getUserLoginHistory>[0];
type Return = GetUserLoginHistoryResponse;

export const useGetUserLoginHistory = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUserLoginHistory, Params]>(
    ['GetUserLoginHistory', params],
    () => getUserLoginHistory(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
