import { keyBy, omit } from 'lodash';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import {
  updateMarketSuggestions,
  updateSearchInfo,
} from '../utils/market-search-helper';
import * as actions from '../actions/merge-markets';

const initialState = {
  selectedMarkets: {},
  marketToMergeSearch: {
    marketSuggestions: [],
    searchTerm: '',
    searchId: 0,
  },
  targetMarket: {
    marketSuggestions: [],
    searchTerm: '',
    searchId: 0,
  },
};

export const addSelectedMarket = (selectedMarketsObj, searchTerm, searchId) => {
  const id = parseInt(searchId, 10) || 0;
  return { ...selectedMarketsObj, [id]: { id, name: searchTerm } };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_MARKET_TO_MERGE_SUGGESTIONS: {
      return {
        ...state,
        marketToMergeSearch: updateMarketSuggestions(
          state.marketToMergeSearch,
          action.markets,
          getKeyValueFromListViaOtherKey(
            action.markets,
            'name',
            action.searchTerm,
            'id'
          )
        ),
      };
    }
    case actions.UPDATE_MARKET_TO_MERGE_SEARCH: {
      return {
        ...state,
        marketToMergeSearch: updateSearchInfo(
          state.marketToMergeSearch,
          action.searchTerm,
          action.searchId
        ),
      };
    }
    case actions.UPDATE_TARGET_MARKET_SUGGESTIONS: {
      return {
        ...state,
        targetMarket: updateMarketSuggestions(
          state.targetMarket,
          action.markets,
          getKeyValueFromListViaOtherKey(
            action.markets,
            'name',
            action.searchTerm,
            'id'
          )
        ),
      };
    }
    case actions.UPDATE_TARGET_MARKET: {
      return {
        ...state,
        targetMarket: updateSearchInfo(
          state.targetMarket,
          action.searchTerm,
          action.searchId
        ),
      };
    }
    case actions.ADD_SELECTED_MARKET: {
      return {
        ...state,
        marketToMergeSearch: updateSearchInfo(state.marketToMergeSearch, '', 0),
        selectedMarkets: addSelectedMarket(
          state.selectedMarkets,
          action.searchTerm,
          action.searchId
        ),
      };
    }
    case actions.DELETE_SELECTED_MARKET: {
      return {
        ...state,
        selectedMarkets: omit(state.selectedMarkets, action.id),
      };
    }
    case actions.SELECT_TARGET_MARKET: {
      return {
        ...state,
        targetMarket: updateSearchInfo(
          state.targetMarket,
          action.marketName,
          action.marketId
        ),
      };
    }
    case actions.CLEAR_MERGE_MARKETS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case actions.ADD_SELECTED_MARKETS_BY_CHECKBOX: {
      return {
        ...state,
        selectedMarkets: keyBy(action.selectedMarkets, 'id'),
      };
    }
    default:
      return state;
  }
}
