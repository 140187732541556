import { UseQueryResult, useQuery } from 'react-query';
import { GetTeamInvitesResponse } from '@cbinsights/usermanagementservice/usermanagementservice';
import { GetTeamInvites } from '.';
import { getTeamInvites } from '../api/GetTeamInvites';

type Params = Parameters<typeof getTeamInvites>[0];
type Return = GetTeamInvitesResponse;

export const useGetTeamInvites = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetTeamInvites, Params]>(
    ['GetTeamInvites', params],
    () => getTeamInvites(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
