import React from 'react';
import { RouteObject } from 'react-router-dom';

const DataFeedCreditPage = React.lazy(
  () => import('./components/DataFeedCreditPage')
);

export const dataFeedCreditRoutes: RouteObject = {
  path: 'data-feed-credit',
  element: (
    <React.Suspense fallback={<></>}>
      <DataFeedCreditPage />
    </React.Suspense>
  ),
};
