import PropTypes from 'prop-types';
import React from 'react';

const QueueTable = ({ children }) => (
  <table className="text-black">
    <thead data-test="tableHeader" className="">
      <tr>
        <th className="px-4 py-2 w-1/12">DATE</th>
        <th className="px-4 py-2 w-1/12">TYPE</th>
        <th className="px-4 py-2 w-1/6">TITLE</th>
        <th className="px-4 py-2 w-1/2">CONTENT</th>
        <th className="px-4 py-2 w-1/6">ACTIONS</th>
      </tr>
    </thead>
    <tbody className="align-baseline">{children}</tbody>
  </table>
);

QueueTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QueueTable;
