export const SET_SUCCESS_NOTIFICATION =
  'client/modules/common/status-notification/SET_SUCCESS_NOTIFICATION';
export const SET_FAILURE_NOTIFICATION =
  'client/modules/common/status-notification/SET_FAILURE_NOTIFICATION';
export const CLOSE_NOTIFICATION =
  'client/modules/common/status-notification/CLOSE_NOTIFICATION';
export const SET_CUSTOM_NOTIFICATION =
  'client/modules/common/status-notification/SET_CUSTOM_NOTIFICATION';
export const CLOSE_CUSTOM_NOTIFICATION =
  'client/modules/common/status-notification/CLOSE_CUSTOM_NOTIFICATION';

export const setSuccessNotification = (
  message,
  autoDisappear,
  isMessageHtml
) => {
  return {
    type: SET_SUCCESS_NOTIFICATION,
    message,
    autoDisappear,
    isMessageHtml,
  };
};

export const setFailureNotification = (
  message,
  autoDisappear,
  isMessageHtml
) => {
  return {
    type: SET_FAILURE_NOTIFICATION,
    message,
    autoDisappear,
    isMessageHtml,
  };
};

export const closeNotification = () => {
  return { type: CLOSE_NOTIFICATION };
};

export const setCustomNotification = (
  message,
  notificationType,
  autoDisappear,
  isMessageHtml
) => {
  return {
    type: SET_CUSTOM_NOTIFICATION,
    message,
    notificationType,
    autoDisappear,
    isMessageHtml,
  };
};

export const closeCustomNotification = (id) => {
  return { type: CLOSE_CUSTOM_NOTIFICATION, id };
};
