import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import ComponentDetails from './containers/ComponentDetails';
import PipelineList from './containers/PipelineList';
import PipelineGraph from './containers/PipelineGraph';
import {
  PIPELINE_LIST_ROUTE,
  PIPELINE_GRAPH_ROUTE,
  COMPONENT_DETAILS_ROUTE
} from './components/utils/get-tab-options';

export const dataPlatformRoutes: RouteObject = {
  path: 'data-platform',
  children: [
    {
      path: '/data-platform',
      element: <Navigate to={`/data-platform/${PIPELINE_LIST_ROUTE}`} />
    },
    {
      path: PIPELINE_LIST_ROUTE,
      element: <PipelineList />
    },
    {
      path: PIPELINE_GRAPH_ROUTE,
      element: <PipelineGraph />
    },
    {
      path: COMPONENT_DETAILS_ROUTE,
      element: <ComponentDetails />
    }
  ]
};
