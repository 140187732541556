import { useEffect, useState } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

type Props<T> = {
  data: Array<T>;
  options: IFuseOptions<T>;
};

const useFuseSearch = <T,>({ data, options }: Props<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState(data);

  useEffect(() => {
    if (searchQuery === '') {
      setResults(data);
    } else {
      const fuse = new Fuse(data, { ...options, includeScore: true });
      const searchResults = fuse
        .search(searchQuery)
        .map((result) => result.item);
      setResults(searchResults);
    }
  }, [searchQuery, data]);

  return { searchQuery, setSearchQuery, results };
};

export default useFuseSearch;
