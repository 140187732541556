import React, { Component } from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { InputAdornment } from 'client/component-library/Input';
import Icon from 'client/component-library/icons/Icon';

/** 
 * @deprecated
 * It's a React component that renders an InputAdornment if the showInputAdornment prop is true and the
currentSearchTermId prop is '0' */
class AutocompleteInputAdornment extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { showInputAdornment, currentSearchTermId } = this.props;

    // currentSearchTermId !== '0' means there are market id exist.
    if (!showInputAdornment || currentSearchTermId !== '0') {
      return null;
    }
    return (
      <InputAdornment position="start">
        <Icon
          name="fiber_new"
          family="material"
          styles={{ fontSize: '20px' }}
          color="secondary"
        />
      </InputAdornment>
    );
  }
}

AutocompleteInputAdornment.propTypes = {
  currentSearchTermId: PropTypes.string.isRequired,
  showInputAdornment: PropTypes.bool,
};

AutocompleteInputAdornment.defaultProps = {
  showInputAdornment: false,
};

function mapStateToProps({ table }, ownProps) {
  const stateValues = table.autocompleteInput;
  return {
    items: stateValues.suggestions[ownProps.id],
    currentSearchTermId: stateValues.inputValues[ownProps.id]
      ? stateValues.inputValues[ownProps.id].termId
      : '',
  };
}

/** @deprecated */
export default connect(mapStateToProps)(AutocompleteInputAdornment);
