import {
  GetTeamFeatureUsageRequest,
  GetTeamFeatureUsageResponse,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const getTeamFeatureUsage = (
  req: Partial<GetTeamFeatureUsageRequest>
): Promise<GetTeamFeatureUsageResponse> =>
  request({
    url: '/service/userv2service/GetTeamFeatureUsage',
    body: req,
  }).then((r) => r.body);
