import { Button } from 'antd';
import { CBISectionForm } from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import { FinancialFormValues } from './hooks/useFinancials';

type FinancialsActionsProps = CBISectionForm<
  FinancialFormValues,
  {
    formIsLoading: boolean;
    onLeavePage: (args: { redirect: () => void }) => void;
  },
  Record<string, string>
>;

export const FinancialsActions = ({
  submitForm,
  dirty,
}: FinancialsActionsProps) => {
  return (
    <Button
      type="primary"
      onClick={() => submitForm()}
      disabled={!dirty}
      data-testid="submit-financials-form"
    >
      Publish
    </Button>
  );
};
