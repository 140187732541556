import React from 'react';
import { Empty, Select, Spin } from 'antd';
import { useSearchTeam } from '../../hooks/useSearchTeam';

export const TeamSearch = () => {
  const {
    handleTeamSearch,
    searchOptions,
    isLoadingSuggestList,
    teamSearchValue,
  } = useSearchTeam();

  let notFoundContent = null;
  if (teamSearchValue?.length > 2) {
    notFoundContent = isLoadingSuggestList ? <Spin size="small" /> : <Empty />;
  }

  return (
    <Select
      className="w-full"
      showSearch
      placeholder="Search for a user or team, min 3 characters"
      filterOption={false}
      optionLabelProp="label"
      notFoundContent={notFoundContent}
      onSearch={handleTeamSearch}
      options={searchOptions}
    />
  );
};
