import React from 'react';
import { message } from 'antd';
import { useMutation } from 'react-query';
import { debounce } from 'lodash';
import { TeamMember } from '@cbinsights/adminservice/adminservice';
import { useGetUsersByEmailQuery } from '../services/hooks/useGetUsersByEmailQuery';
import { addUserToTeam } from '../services/api/AddUserToTeam';

export const useAddUserForm = (idTeam: number) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState([]);

  const debouncedChangeHandler = React.useMemo(
    () =>
      debounce((newSearchTerm: string) => setSearchTerm(newSearchTerm), 1000),
    []
  );

  const addUserMutation = useMutation(addUserToTeam);
  const onChangeSearch = (newSearchTerm: string) => {
    if (newSearchTerm?.length < 2) {
      return;
    }
    debouncedChangeHandler(newSearchTerm);
  };

  const addUsers = () => {
    const usersList = selectedUser.map((userId) => {
      return <TeamMember>{
        idTeam,
        idUser: userId,
        isOwner: false,
        adminPriv: false,
      };
    });

    return addUserMutation.mutateAsync(
      {
        users: usersList,
        force: true,
      },
      {
        onSuccess: () => {
          message.success(
            'Success! The users have been successfully added to the team.'
          );
          setSearchTerm('');
          setSelectedUser([]);
        },
        onError: () => {
          message.error(
            'Error! Failed to add the users to the team. Please try again later.'
          );
          setSearchTerm('');
          setSelectedUser([]);
        },
      }
    );
  };

  const getUsersQuery = useGetUsersByEmailQuery({
    enabled: Boolean(searchTerm),
    params: { searchTerm, getExpiredUsers: true },
  });

  const onSelect = (userId: number) => {
    const user = getUsersQuery?.data?.userInfo?.find(
      (entry) => entry.idUser === userId
    );

    if (user && user.teamInfo?.id === idTeam) {
      return;
    }

    setSelectedUser([...selectedUser, userId]);
  };

  const onDeselect = (userId: number) => {
    setSelectedUser(selectedUser.filter((id) => userId !== id));
  };

  const userOptions =
    getUsersQuery?.data?.userInfo.map((user) => {
      return {
        name: `${user.firstName} ${user.lastName}`,
        team: user.teamInfo?.name,
        teamId: user.teamInfo?.id,
        label: user.email,
        value: user.idUser,
      };
    }) || [];

  const resetSelection = () => {
    setSelectedUser([]);
  };

  return {
    onSelect,
    onDeselect,
    addUsers,
    onChangeSearch,
    userOptions,
    getUsersQuery,
    addUserMutation,
    selectedUser,
    resetSelection,
    searchTerm,
  };
};
