import {
  ListPackagesRequest,
  ListPackagesResponse,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const listPackages = (
  req: Partial<ListPackagesRequest>
): Promise<ListPackagesResponse> =>
  request({
    url: '/service/userv2service/ListPackages',
    body: req,
  }).then((r) => r.body);
