import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React, { ReactElement, ReactNode, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';

import { DRAFT_STATUS_COLORS } from 'client/modules/draft-queue/components/DraftOverview';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { GetAdminPermissionsForUserResponse } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { checkIfValidUuid } from 'client/modules/common/form/fields/UtilFields';
import { TabBarAdditionalContentProps } from './SectionLayout';
import { CbiEntityRoutes } from '../../RoutesHelpers';
import {
  evalEntityType,
  useSectionBlockedForEntityInfo,
} from './hooks/useSectionBlockedForEntityInfo';
import {
  HasPendingDrafts,
  useHasPendingDrafts,
} from '../../services/general-info/hooks/useHasPendingDrafts';
import {
  CbiEntityTabPathType,
  ENTITY_ADMIN_PERMISSIONS,
} from '../../permissions/EntityAdmin';
import { useGetEntityInfo } from '../../services/general-info/hooks/useGetEntityInfo';

import './CBIEntityTabs.css';

const { TabPane } = Tabs;
const GENERAL_INFO_SECTION = 1;
const FUNDING_SECTION = 2;

const evalHasPendingDraft = (
  hasPendingDrafts: HasPendingDrafts,
  sectionId: number
): boolean => {
  if (sectionId === GENERAL_INFO_SECTION) {
    return hasPendingDrafts?.hasPendingEntityDraftsResponse?.hasPendingDrafts;
  }
  if (sectionId === FUNDING_SECTION) {
    return hasPendingDrafts?.hasPendingFundingDraftsResponse?.hasPendingDrafts;
  }
  return false;
};

export const disabledTabsForEntityDraft = (
  sectionId: number,
  isDraftId: boolean
): boolean => {
  if (
    isDraftId &&
    [GENERAL_INFO_SECTION, FUNDING_SECTION].includes(sectionId)
  ) {
    return false;
  }
  return isDraftId;
};

const cbiEntityTabsOrder = [1, 2, 8, 3, 4, 5, 10] as const;

export const mapSectionsToTabs = (
  permissions: GetAdminPermissionsForUserResponse,
  entityInfo: {
    isCompany: boolean;
    isInvestor: boolean;
  },
  hasPendingDrafts: HasPendingDrafts,
  isDraftId: boolean
): {
  tab: string;
  key: CbiEntityTabPathType;
  disabled: boolean;
  hasPendingDraft: boolean;
}[] => {
  return cbiEntityTabsOrder.map((sectionId) => ({
    tab: ENTITY_ADMIN_PERMISSIONS.sections[sectionId].name,
    key: ENTITY_ADMIN_PERMISSIONS.sections[sectionId].path,
    disabled:
      getSectionPage(
        permissions,
        ENTITY_ADMIN_PERMISSIONS.pageId,
        ENTITY_ADMIN_PERMISSIONS.sections[sectionId].sectionId
      ).permissionTypes.length === 0 ||
      evalEntityType({ ...entityInfo, sectionId, isDraft: isDraftId }) ||
      disabledTabsForEntityDraft(sectionId, isDraftId),
    hasPendingDraft: evalHasPendingDraft(hasPendingDrafts, sectionId),
  }));
};

export type CBIEntityTabsProps = {
  TabContent: ReactNode;
  onLeavePage: (args: { redirect: () => void }) => void;
  TabBarAdditionalContent: TabBarAdditionalContentProps[];
};

export const CBIEntityTabs = ({
  TabContent,
  onLeavePage,
  TabBarAdditionalContent,
}: CBIEntityTabsProps): ReactElement => {
  const location = useLocation();
  const navigation = useNavigate();
  const params = useParams<'id'>();
  const isDraftId = checkIfValidUuid(params.id);

  const { data: permissions } = useGetAdminPermissionsForUser(false);

  const { data: entityInfo } = useGetEntityInfo(false, {
    idEntityDraft: Number.isNaN(+params.id) ? params.id : undefined,
    idCbiEntity: !Number.isNaN(+params.id) ? Number(params.id) : undefined,
  });

  const idCbiEntity = Number(params.id);
  const idEntityDraft = `${isDraftId ? params.id : ''}`;
  const { data: hasPendingDrafts } = useHasPendingDrafts({
    idCbiEntity,
    idEntityDraft,
    enabled: !!idCbiEntity || !!idEntityDraft,
    accessPermissions: {
      generalInfo: Boolean(
        permissions.adminPages[0]?.adminSections?.find(
          (el) =>
            el.idAdminSection === ENTITY_ADMIN_PERMISSIONS.sections[1].sectionId
        )?.permissionTypes?.length
      ),
      funding: Boolean(
        permissions.adminPages[0]?.adminSections?.find(
          (el) =>
            el.idAdminSection === ENTITY_ADMIN_PERMISSIONS.sections[2].sectionId
        )?.permissionTypes?.length
      ),
    },
  });

  const { isSectionBlocked, sectionId } = useSectionBlockedForEntityInfo({
    ...entityInfo?.entityInfo,
    isDraft: isDraftId,
  });

  const [activeKey, setActiveKey] = useState<CbiEntityTabPathType>(
    ENTITY_ADMIN_PERMISSIONS.sections[sectionId].path
  );

  if (isSectionBlocked) {
    return (
      <Navigate to={`/${CbiEntityRoutes.PageIndex}/${params.id}`} replace />
    );
  }

  const tabs = mapSectionsToTabs(
    permissions,
    entityInfo?.entityInfo,
    hasPendingDrafts,
    isDraftId
  );

  const handleTabChange = (path: CbiEntityTabPathType) => {
    onLeavePage({
      redirect: () => {
        setActiveKey(path);
        navigation(`/${CbiEntityRoutes.PageIndex}/${params.id}/${path}`);
      },
    });
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <div className="flex flex-row justify-between items-center">
      <DefaultTabBar {...props} className="!mb-0" />
      {
        TabBarAdditionalContent.find((el) =>
          location.pathname.includes(el?.tabPath)
        )?.AdditionalContent
      }
    </div>
  );

  return (
    <Tabs
      className="tabs"
      activeKey={activeKey}
      onChange={handleTabChange}
      renderTabBar={renderTabBar}
    >
      {tabs.map((tab) => (
        <TabPane
          tab={
            <span>
              {tab.hasPendingDraft && entityInfo && (
                <ExclamationCircleFilled
                  className={`text-${
                    DRAFT_STATUS_COLORS[
                      entityInfo?.entityDraftInfo?.entityDraftMetadata
                        ?.draftStatus
                    ] || 'cbi-funding-draft-qa'
                  }`}
                  style={{ marginRight: '7px' }}
                />
              )}
              {tab.tab}
            </span>
          }
          key={tab.key}
          disabled={tab.disabled}
          className="px-[20px] pt-[18px] pb-[16px]"
          style={{ border: '1px solid #d9d9d9' }}
        >
          {TabContent}
        </TabPane>
      ))}
    </Tabs>
  );
};
