export const truncateString = (stringToTruncate, truncateLength) => {
  if (!stringToTruncate || !truncateLength) {
    return stringToTruncate;
  } if (stringToTruncate.length < truncateLength) {
    return stringToTruncate;
  }
  return `${stringToTruncate.slice(0, truncateLength)}...`;
};

/*
 * Removes all white space before the first letter
 * example: ltrim("  Uber") = "Uber"
 */
export function ltrim(str) {
  if (!str) return str;
  return str.replace(/^\s+/g, '');
}

export default truncateString;
