import { map } from 'lodash';
import { formatDate } from 'client/modules/common/utils/timeFormattingMethods';
import abbreviateNumber from 'client/modules/common/utils/abbreviateNumber';
import {
  REJECTED,
  APPROVED,
  NEEDS_APPROVAL,
} from '../actions/record-status-filters';

export const mapStatus = {
  NeedApproval: NEEDS_APPROVAL,
  Rejected: REJECTED,
  Approved: APPROVED,
};

export const mapMarketSizingDetailResponseRow = (row) => {
  return {
    id: row.id,
    sentence: row.sentence,
    articleLink: row.news.link,
    marketId: row.market.id,
    market: row.market.name,
    description: row.marketDescription,
    news: row.news,
    startSize: (abbreviateNumber(row.startSize) || 0).toString(),
    endSize: (abbreviateNumber(row.endSize) || 0).toString(),
    cagr: row.cagr.toString(),
    geoId: row.geo.id,
    geography: row.geo.name,
    startYear: row.startYear === '0' ? 'N/A' : row.startYear,
    endYear: row.endYear === '0' ? 'N/A' : row.endYear,
    analystId: row.analyst.id,
    analyst: row.analyst.analyst,
    newsDate: formatDate(row.news.date, 'YYYY-MM-DD'),
    reviewer: row.lastModifiedBy.reviewer,
    reviewDate: row.lastModifiedBy.modified,
    reviewerNote: row.comment,
    modificationHash: row.modificationHash,
    dupeCount: parseInt(row.dupeCount, 10),
    status: mapStatus[row.status],
  };
};

export const mapMarketSizingDetailResponse = (rows) => {
  return map(rows, (row) => {
    return mapMarketSizingDetailResponseRow(row);
  });
};

export const mapMarketSizingDetailInnerTableResponse = (
  parentRecord,
  rows,
  parentId
) => {
  return map(rows, (row) => {
    const mappedRow = mapMarketSizingDetailResponseRow(row);
    return {
      ...mappedRow,
      parentId,
      highlightStartYear: parentRecord.startYear !== mappedRow.startYear,
      highlightEndYear: parentRecord.endYear !== mappedRow.endYear,
      highlightStartSize: parentRecord.startSize !== mappedRow.startSize,
      highlightEndSize: parentRecord.endSize !== mappedRow.endSize,
    };
  });
};

export const mapRelatedMarketsResponse = (rows) => {
  return map(rows, (row) => {
    return {
      id: row.id,
      status: row.status,
      modificationHash: row.modificationHash,
      parentId: row.parent.id,
      parentMarket: row.parent.name,
      childId: row.child.id,
      childMarket: row.child.name,
    };
  });
};

export const mapListOfMarketsResponse = (rows) => {
  return map(rows, (row) => {
    return {
      id: row.market.id,
      name: row.market.name,
      numAssocCompaniesNeedApproval: row.numAssocCompaniesNeedApproval,
      numMarketSizingDetailsNeedApproval:
        row.numMarketSizingDetailsNeedApproval,
      numRelatedMktsNeedApproval: row.numRelatedMktsNeedApproval,
      parentMarketId: row.parent.id,
      parentMarketName: row.parent.name,
    };
  });
};
