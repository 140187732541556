// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-component-library-buttons-ButtonAdmin-___afsButton-styles__inline___uG9sZ {
  min-height: inherit;
  font-weight: inherit;
}
`, "",{"version":3,"sources":["webpack://./client/component-library/buttons/ButtonAdmin/afsButton-styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".inline {\n  min-height: inherit;\n  font-weight: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline": `client-component-library-buttons-ButtonAdmin-___afsButton-styles__inline___uG9sZ`
};
export default ___CSS_LOADER_EXPORT___;
