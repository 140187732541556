import { forEach } from 'lodash';

const exactMatchComparison = (itemValue, formattedValue) => {
  return itemValue === formattedValue;
};

const startWithMatchComparison = (itemValue, formattedValue) => {
  return itemValue.startsWith(formattedValue);
};

const includesMatchComparison = (itemValue, formattedValue) => {
  return itemValue.includes(formattedValue);
};

const checkForMatch = (
  formattedValue,
  item,
  typeOfMatchInfo,
  fields,
  isCaseSensitive
) => {
  for (let i = 0; i < fields.length; i++) {
    if (typeof item[fields[i]] !== 'undefined') {
      const itemValue = isCaseSensitive
        ? item[fields[i]]
        : item[fields[i]].toLowerCase();
      if (typeOfMatchInfo.comparisionFunc(itemValue, formattedValue)) {
        typeOfMatchInfo.matchList.push(item);
        return true;
      }
    }
  }
  return false;
};

const getPropValues = (props) => {
  return {
    value: props.value || '',
    listOfItems: props.listOfItems || [],
    isCaseSensitive: !!props.isCaseSensitive,
    fields: props.fields || ['name'],
  };
};

export const filterListOfObjects = (props) => {
  const { value, listOfItems, isCaseSensitive, fields } = getPropValues(props);
  const formattedValue = isCaseSensitive ? value : value.toLowerCase();

  const exactMatchInfo = {
    comparisionFunc: exactMatchComparison,
    matchList: [],
  };
  const startWithMatchInfo = {
    comparisionFunc: startWithMatchComparison,
    matchList: [],
  };
  const includeMatchInfo = {
    comparisionFunc: includesMatchComparison,
    matchList: [],
  };
  const typesOfMatches = [exactMatchInfo, startWithMatchInfo, includeMatchInfo];

  forEach(listOfItems, (item) => {
    for (let i = 0; i < typesOfMatches.length; i++) {
      const foundMatch = checkForMatch(
        formattedValue,
        item,
        typesOfMatches[i],
        fields,
        isCaseSensitive
      );
      if (foundMatch) {
        break;
      }
    }
  });
  return exactMatchInfo.matchList
    .concat(startWithMatchInfo.matchList)
    .concat(includeMatchInfo.matchList);
};

export default filterListOfObjects;
