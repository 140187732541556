import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { TrialPackagePage } from 'client/modules/trial-package/components/TrialPackagePage';

type Props = {
  userId: number;
};

const EditTrialSettings = ({ userId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Edit Trial Settings</Button>
      <Modal
        title="Change Trial Details"
        centered
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={null}
      >
        {isOpen && (
          <TrialPackagePage defaultUserId={userId?.toString()} disabledUserId />
        )}
      </Modal>
    </>
  );
};

export default EditTrialSettings;
