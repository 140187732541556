import PropTypes from 'prop-types';
import React from 'react';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

const ClearQueueButton = ({ onClear, isResolvingNews }) => (
  <Button data-test="clearQueue" onClick={onClear} disabled={isResolvingNews}>
    Clear Queue
  </Button>
);

export default ClearQueueButton;

ClearQueueButton.propTypes = {
  onClear: PropTypes.func.isRequired,
  isResolvingNews: PropTypes.bool,
};

ClearQueueButton.defaultProps = {
  isResolvingNews: false,
};
