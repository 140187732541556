import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
} from 'client/modules/stories/utils/tab-names';
import { fetchApprovedStoriesSuggestions } from '../utils/api-request-helper';
import { getCurrentTab } from '../utils/records-helper';
import { getTeamsByStory, getStoriesByTeam } from './tab-data';
import { updatePageNumber } from './tab-details';

export const INITIALZE_SEARCH_TERM =
  'client/modules/stories/actions/search-bar/INITIALZE_SEARCH_TERM';
export const UPDATE_SEARCH_INFO =
  'client/modules/stories/actions/search-bars/UPDATE_SEARCH_INFO';
export const UPDATE_SELECTED_SEARCH_TERM =
  'client/modules/stories/actions/search-bars/UPDATE_SELECTED_SEARCH_TERM';
export const UPDATE_SUGGESTIONS =
  'client/modules/stories/actions/search-bars/UPDATE_SUGGESTIONS';
export const UPDATE_TEAM_SUGGESTIONS =
  'client/modules/stories/actions/search-bars/UPDATE_TEAM_SUGGESTIONS';
export const UPDATE_SLUG_SEARCH_TERM =
  'client/modules/stories/actions/search-bars/UPDATE_SLUG_SEARCH_TERM';

export const initializeSearchTerm = (searchTerm, searchTermId) => {
  return getCurrentTab((currentTab) => {
    return {
      type: INITIALZE_SEARCH_TERM,
      searchTerm,
      searchTermId,
      currentTab,
    };
  });
};

export const updateSearchInfo = (searchId, searchTerm) => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SEARCH_INFO,
      currentTab,
      searchId,
      searchTerm,
    };
  });
};

export const setSearchSuggestions = (searchTerm, suggestions) => {
  return getCurrentTab((currentTab) => {
    return { type: UPDATE_SUGGESTIONS, currentTab, searchTerm, suggestions };
  });
};

export const updateStoriesSuggestions = (searchTerm) => {
  if (!searchTerm) {
    return Promise.resolve();
  }
  return (dispatch) => {
    return dispatch(fetchApprovedStoriesSuggestions(searchTerm)).then(
      (response) => {
        return dispatch(setSearchSuggestions(searchTerm, response));
      }
    );
  };
};

export const updateSelectedSearchTerm = (
  searchId,
  searchTerm,
  currentTab,
  suggestions
) => {
  return {
    type: UPDATE_SELECTED_SEARCH_TERM,
    currentTab,
    searchId,
    searchTerm,
    suggestions,
  };
};

export const onSelectOrEnterSearch = (searchId, searchTerm) => {
  return (dispatch, getState) => {
    const {currentTab} = getState().stories;
    const {suggestions} = getState().stories.searchBars[currentTab];
    dispatch(updatePageNumber(0));
    dispatch(
      updateSelectedSearchTerm(searchId, searchTerm, currentTab, suggestions)
    );
    if (currentTab === UPDATE_ACCESS_BY_STORY) {
      return dispatch(getTeamsByStory(searchId));
    } if (currentTab === UPDATE_ACCESS_BY_TEAM) {
      return dispatch(getStoriesByTeam(searchId));
    }
    return Promise.resolve();
  };
};

export const updateTeamsSuggestionsHelper = (searchTerm, suggestions) => {
  return { type: UPDATE_TEAM_SUGGESTIONS, searchTerm, suggestions };
};

export const updateTeamsSuggestions = (searchTerm) => {
  if (!searchTerm) {
    return null;
  }
  return (dispatch, getState) => {
    const {allTeams} = getState().stories;
    return dispatch(updateTeamsSuggestionsHelper(searchTerm, allTeams));
  };
};
