import { combineReducers } from 'redux';
import tabData from './tab-data';
import tabDetails from './tab-details';
import currentTab from './current-tab';
import searchBar from './search-bar';
import recordStatusFilters from './record-status-filters';
import mergeMarkets from './merge-markets';
import mergeConflict from './merge-conflict';
import urlStatusFilters from './url-status-filters';
import articleContent from './article-content';

const markets = combineReducers({
  tabData,
  tabDetails,
  currentTab,
  searchBar,
  recordStatusFilters,
  mergeMarkets,
  mergeConflict,
  urlStatusFilters,
  articleContent,
});

export default markets;
