import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { closeCustomNotification } from 'client/modules/common/redux/actions/status-notification';
import formatMessage from '../utils/format-message';
import notificationTypes from '../utils/constants';
import styles from './custom-notification-style.css';
import Notification from '../Notification';

const getNotificationBackgroundColor = (notificationType) => {
  if (notificationType && notificationType === notificationTypes.SUCCESS) {
    return `${styles.successNotification}`;
  }
  if (notificationType && notificationType === notificationTypes.FAILURE) {
    return `${styles.failureNotification}`;
  }
  return `${styles.customNotification}`;
};

/** @deprecated */
class CustomNotification extends Component {
  handleCloseNotification = (id, reason) => {
    if (reason === 'clickaway') return;

    this.props.closeCustomNotification(id);
  };

  render() {
    const { notifications } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          width: '430px',
          left: 'calc(50% - 215px)',
          top: '5px',
          zIndex: '1200',
        }}
      >
        {notifications.length > 0 &&
          map(notifications, (notification) => {
            const { id, message, type, open, isMessageHtml, autoDisappear } =
              notification;
            const formattedMessage = formatMessage(message, isMessageHtml);
            return (
              <Notification
                key={id}
                notificationType={type}
                open={open}
                message={formattedMessage}
                autoDisappear={autoDisappear}
                handleCloseNotification={(event, reason) =>
                  this.handleCloseNotification(id, reason)
                }
                notificationStyles={getNotificationBackgroundColor(type)}
                marginBottom={10}
              />
            );
          })}
      </div>
    );
  }
}

CustomNotification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  closeCustomNotification: PropTypes.func.isRequired,
};

CustomNotification.defaultProps = {
  notifications: '',
};

function mapStateToProps({ common }) {
  return {
    notifications: common.statusNotification.notifications,
  };
}

/** @deprecated */
export default connect(mapStateToProps, { closeCustomNotification })(
  CustomNotification
);
