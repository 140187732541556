/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import defaultStyles from './styles/SearchableSelectStyles';

export default class SearchableSelectMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = { hoverActive: false };
    this.optionRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate() {
    const { cursorIndex, index } = this.props;

    if (
      index === cursorIndex &&
      this.optionRef.current &&
      !this.state.hoverActive
    ) {
      try {
        this.optionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      } catch (error) {
        console.error('Failed to focus item');
      }
    }
  }

  handleMouseEnter = () => {
    const { handleCursorIndexChange, index } = this.props;
    handleCursorIndexChange(index);
    this.setState({ hoverActive: true });
  };

  handleMouseLeave = () => {
    this.setState({ hoverActive: false });
  };

  handleKeyboardSelect = (event) => {
    if (event.keyCode === 13) {
      this.handleItemSelect(this.props.item);
    }
  };

  /**
   * @deprecated
   * This function renders the dropdown menu items
   * @returns A list item with a div inside of it.
   */
  render() {
    const {
      item,
      value,
      name,
      handleItemSelect,
      styles,
      cursorIndex,
      index,
      renderOption,
    } = this.props;
    let hoverStyle = '';
    if (this.state.hoverActive || index === cursorIndex) {
      hoverStyle = defaultStyles.menuItemHover;
    }
    return (
      <li
        style={{ textDecoration: 'none' }}
        onMouseDown={() => {
          handleItemSelect(item);
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onKeyUp={this.handleKeyboardSelect}
        ref={this.optionRef}
      >
        <div
          value={value}
          style={{ ...styles.menuItem, ...hoverStyle }}
          className="padding--all--half wrap--singleLine--truncate"
          key={value}
        >
          {renderOption ? renderOption(item) : name}
        </div>
      </li>
    );
  }
}

SearchableSelectMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleItemSelect: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  cursorIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleCursorIndexChange: PropTypes.func.isRequired,
};

SearchableSelectMenuItem.defaultProps = {
  styles: defaultStyles,
  renderOption: null,
};
