import { Link } from 'react-router-dom';

export const TeamRoutes = {
  PageIndex: '/teams',
  Team: '/teams/:id',
  Search: '/search',
} as const;

export const itemRender = (route) => {
  if (!route.path) {
    return <span>{route.breadcrumbName}</span>;
  }

  return <Link to={route.path}>{route.breadcrumbName}</Link>;
};
