import {
  CreateCbiEntityEODMappingRequest,
  DeleteCbiEntityEODMappingsRequest,
  GetAdminFinancialDataRequest,
  GetAdminFinancialDataResponse,
  GetEntityForSymbolAndExchangeRequest,
  GetEntityForSymbolAndExchangeResponse,
  GetLastDayFinancialDataRequest,
  GetLastDayFinancialDataResponse,
  GetTickersForExchangeRequest,
  GetTickersForExchangeResponse,
  ListExchangesResponse,
  ListQuartrCompaniesResponse,
  SuccessStatus,
  UpdateCbiEntityEODMappingRequest,
} from '@cbinsights/publicfinancialservice/publicfinancialservice';
import { request } from 'client/modules/common/utils/request';

export const getLastDayFinancialData = (
  req: GetLastDayFinancialDataRequest
): Promise<GetLastDayFinancialDataResponse> =>
  request({
    url: '/service/publicfinancialservice/GetLastDayFinancialData',
    body: { ...req },
  }).then((r) => r.body);

export const getAdminFinancialData = (
  req: GetAdminFinancialDataRequest
): Promise<GetAdminFinancialDataResponse> =>
  request({
    url: '/service/publicfinancialservice/GetAdminFinancialData',
    body: { ...req },
  }).then((r) => r.body);

export const getGetTickersForExchange = (
  req: GetTickersForExchangeRequest
): Promise<GetTickersForExchangeResponse> =>
  request({
    url: '/service/publicfinancialservice/GetTickersForExchange',
    body: { ...req },
  }).then((r) => r.body);

export const getEntityForSymbolAndExchange = (
  req: GetEntityForSymbolAndExchangeRequest
): Promise<GetEntityForSymbolAndExchangeResponse> =>
  request({
    url: '/service/publicfinancialservice/GetEntityForSymbolAndExchange',
    body: { ...req },
  }).then((r) => r.body);

export const listExchanges = (): Promise<ListExchangesResponse> =>
  request({
    url: '/service/publicfinancialservice/ListExchanges',
  }).then((r) => r.body);

export const createCbiEntityEODMapping = (
  req: IndexSignature<CreateCbiEntityEODMappingRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/publicfinancialservice/CreateCbiEntityEODMapping',
    body: req,
  }).then((r) => r.body);

export const updateCbiEntityEODMapping = (
  req: IndexSignature<UpdateCbiEntityEODMappingRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/publicfinancialservice/UpdateCbiEntityEODMapping',
    body: req,
  }).then((r) => r.body);

export const deleteCbiEntityEODMappings = (
  req: IndexSignature<DeleteCbiEntityEODMappingsRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/publicfinancialservice/DeleteCbiEntityEODMappings',
    body: req,
  }).then((r) => r.body);

export const listQuartrCompanies = (): Promise<ListQuartrCompaniesResponse> =>
  request({
    url: '/service/publicfinancialservice/ListQuartrCompanies',
  }).then((r) => r.body);
