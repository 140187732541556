import React, { useState } from 'react';
import { object, string, func, bool, node, number } from 'prop-types';
import { Field } from 'formik';
import get from 'lodash/get';
import cx from 'classnames';
import { ConfirmationModal } from 'client/component-library/modals/ConfirmationModal';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import TrashIcon from '@cbinsights/fds/lib/icons/react/TrashIcon';
import AddIcon from '@cbinsights/fds/lib/icons/react/AddIcon';
import ActionsArrowRightIcon from '@cbinsights/fds/lib/icons/react/ActionsArrowRightIcon';
import ActionsArrowDownIcon from '@cbinsights/fds/lib/icons/react/ActionsArrowDownIcon';

import {
  FormField,
  FIELD_SIZE_KEYS,
  getUuid,
  generateNewOption,
} from './UtilFields';

const { COL_12 } = FIELD_SIZE_KEYS;
/** @deprecated */
function DefaultActions(props) {
  const {
    hasColumnLabels,
    index,
    name,
    insert,
    addText,
    setIndexToDelete,
    canAdd,
    enableDeleteFirst,
    DeleteComponent,
    shouldInsertRows,
  } = props;

  return (
    <div
      className={`flex my-2 ml-2 ${
        canAdd ? 'items-center' : 'items-start'
      } text-xs`}
      style={{ marginTop: hasColumnLabels && index === 0 ? 18 : 0 }}
    >
      {!canAdd && shouldInsertRows && (
        <Button
          onClick={insert}
          data-test={`add-${`${name}[${index}]`}`}
          inline
        >
          {addText}
        </Button>
      )}

      {(index > 0 || enableDeleteFirst) && (
        <Button
          onClick={setIndexToDelete}
          data-test={`remove-${`${name}[${index}]`}`}
          isDestructive
          inline
        >
          <DeleteComponent />
        </Button>
      )}
    </div>
  );
}

/** @deprecated */
function DefaultTitle({
  title,
  name,
  insertEmpty,
  insertStart,
  shouldInsertStart,
}) {
  return (
    <div
      className="relative text-sm flex flex-row"
      style={{ width: 'fit-content' }}
    >
      {title}
      <div className="absolute" style={{ right: '-20px', top: '-1px' }}>
        <Button
          data-test={`add-${name}-button`}
          onClick={shouldInsertStart ? insertStart : insertEmpty}
          isDestructive
          inline
        >
          <AddIcon size="xs" />
        </Button>
      </div>
    </div>
  );
}

/**
 * @deprecated
 * It renders a form field with a title, a header, and a list of rows
 * @param props - {
 * @returns A component that renders a form field with a title, a header, and a list of rows.
 */
const InputFieldArray = (props) => {
  const [indexToDelete, setIndexToDelete] = useState(null);

  const {
    form,
    size,
    insert,
    remove,
    name,
    fieldComponent,
    deriveKey,
    newValue,
    addText,
    onDelete,
    modalTitle,
    modalBody,
    hasColumnLabels,
    RowActions,
    Header,
    push,
    title,
    valuesContainerClass,
    enableDeleteFirst,
    DeleteComponent = () => <TrashIcon color="red" size="xs" />,
    shouldHideRows = false,
    shouldInsertStart = false,
    alternateRowColor = false,
    shouldInsertRows = true,
    isShowHideButton = true,
    classNames = '',
    dataTest = '',
    ...rest
  } = props;

  const deleteField = () => {
    onDelete(indexToDelete, get(form, `values.${name}[${indexToDelete}]`));
    remove(indexToDelete);
    setIndexToDelete(null);
  };

  const cancelDeletion = () => {
    setIndexToDelete(null);
  };

  const _insert = (index) => insert(index + 1, newValue());

  const HeaderProps = {
    insert: (value) => push(value),
    insertEmpty: () => push(newValue(generateNewOption())),
  };

  const TitleProps = {
    title,
    name,
    insertEmpty: () => push(newValue(generateNewOption())),
    insertStart: () => insert(0, newValue(generateNewOption())),
  };

  const [showAllRows, setShowAllRows] = useState(!shouldHideRows);
  const toggleShowRows = () => {
    setShowAllRows(!showAllRows);
  };

  const rows = get(form, `values[${name}]`, []);

  return (
    <FormField size={size}>
      {title && (
        <DefaultTitle {...TitleProps} shouldInsertStart={shouldInsertStart} />
      )}
      {Header && <Header {...HeaderProps} />}
      <div className={valuesContainerClass}>
        {isShowHideButton && (
          <Button
            onClick={toggleShowRows}
            theme="ghost"
            data-test="toggle-show-rows"
            inline
          >
            {showAllRows ? (
              <ActionsArrowDownIcon customSize="15" />
            ) : (
              <div className="flex flex-row">
                <ActionsArrowRightIcon customSize="15" />
                <div>({rows.length})</div>
              </div>
            )}
          </Button>
        )}

        {rows.map((value, index) => {
          const rowClassNames = cx('flex flex-wrap -mx-1', classNames, {
            hidden: !showAllRows && index > 0,
            'bg-gray-200': alternateRowColor && index % 2 === 0,
          });

          let rowKey = deriveKey(value);
          if (typeof rowKey === 'object') {
            rowKey = value.value ? value.value : index;
          }

          return (
            <div key={rowKey} className={rowClassNames} data-test={dataTest}>
              <Field
                component={fieldComponent}
                name={`${name}[${index}]`}
                size={COL_12}
                handleDelete={() => setIndexToDelete(index)}
                {...rest}
              />

              {/* CTAs */}
              <RowActions
                enableDeleteFirst={enableDeleteFirst}
                canAdd={!!title}
                shouldInsertRows={shouldInsertRows}
                hasColumnLabels={hasColumnLabels}
                insert={() => _insert(index)}
                name={name}
                index={index}
                addText={addText}
                setIndexToDelete={() => setIndexToDelete(index)}
                DeleteComponent={DeleteComponent}
              />
            </div>
          );
        })}
      </div>
      <ConfirmationModal
        isOpen={typeof indexToDelete === 'number'}
        title={modalTitle}
        body={modalBody}
        onCancel={cancelDeletion}
        onSubmit={deleteField}
        cancelActText="Keep"
        submitActText="Delete"
        width={510}
      />
    </FormField>
  );
};

DefaultActions.propTypes = {
  hasColumnLabels: bool.isRequired,
  index: number.isRequired,
  insert: func.isRequired,
  addText: string.isRequired,
  setIndexToDelete: func.isRequired,
  canAdd: bool.isRequired,
  enableDeleteFirst: bool.isRequired,
  name: string,
  DeleteComponent: node.isRequired,
  shouldInsertRows: bool,
};

DefaultActions.defaultProps = {
  name: '',
};

DefaultTitle.propTypes = {
  title: string.isRequired,
  insertEmpty: func.isRequired,
  insertStart: func.isRequired,
  shouldInsertStart: bool,
  name: string,
};

DefaultTitle.defaultProps = {
  name: '',
};

/* Note: most of these are passed in via formik */
InputFieldArray.propTypes = {
  form: object.isRequired,
  size: string,
  addText: string,
  name: string.isRequired,
  placeholder: string,
  insert: func.isRequired,
  remove: func.isRequired,
  push: func.isRequired,
  validate: func,
  validateOnBlur: bool,
  fieldComponent: func.isRequired,
  deriveKey: func,
  newValue: func,
  onDelete: func,
  modalTitle: string,
  modalBody: string,
  hasColumnLabels: bool,
  RowActions: node,
  title: string,
  Header: node,
  valuesContainerClass: string,
  enableDeleteFirst: bool,
  DeleteComponent: node,
  shouldHideRows: bool,
  alternateRowColor: bool,
  shouldInsertStart: bool,
  shouldInsertRows: bool,
  isShowHideButton: bool,
  classNames: string,
  dataTest: string,
};

InputFieldArray.defaultProps = {
  size: 'FULL',
  addText: 'Add',
  validateOnBlur: false,
  validate: () => '',
  deriveKey: (value) => get(value, 'id', value),
  newValue: () => ({ id: getUuid() }),
  placeholder: undefined,
  onDelete: () => '',
  hasColumnLabels: false,
  modalTitle: 'Are you sure you want to delete this round?',
  modalBody:
    'Deleting this round from the form will also clear all of its associated deal terms. This action cannot be undone.',
  RowActions: DefaultActions,
  Header: null,
  enableDeleteFirst: false,
  shouldInsertRows: true,
  title: '',
  valuesContainerClass: '',
  dataTest: '',
};

export { InputFieldArray };
