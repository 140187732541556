export const IsTickerInUse = ({
  idCbiEntity,
  selectedIdCBiEntity,
}: {
  idCbiEntity: number;
  selectedIdCBiEntity: number;
}) => {
  return !!selectedIdCBiEntity &&
    selectedIdCBiEntity !== 0 &&
    selectedIdCBiEntity !== idCbiEntity ? (
    <div className="text-red-600 text-xs text-left">
      <span>
        Selected ticker is used in entity{' '}
        <a
          href={`/cbi-entity/${selectedIdCBiEntity}/general-info`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          {selectedIdCBiEntity}
        </a>{' '}
      </span>
    </div>
  ) : null;
};
