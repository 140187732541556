import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import * as actions from '../actions/url-status-filters';

const urlStatusFilterOptions = [
  { id: actions.ALL_URLS_ID, text: actions.ALL_URLS },
  { id: actions.BAD_URLS_ID, text: actions.BAD_URLS },
  { id: actions.GOOD_URLS_ID, text: actions.GOOD_URLS },
];

const initialState = {
  selectedUrlStatusFilterId: 2,
  urlStatusFilterOptions,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_URL_STATUS_FILTER: {
      updateUrlParams(
        'selectedUrlStatusFilterId',
        action.selectedUrlStatusFilterId
      );
      return {
        ...state,
        selectedUrlStatusFilterId:
          parseInt(action.selectedUrlStatusFilterId, 10) || 0,
      };
    }
    default:
      return state;
  }
}
