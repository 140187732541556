/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/dropdownItem.css';

const conditionallApplySelectedStyle = (isSelected) => {
  return isSelected ? styles.selected : '';
};

const createDropdownStyle = (height, fontSize) => {
  return {
    height: height ? `${height}px` : 'inherit',
    fontSize: fontSize ? `${fontSize}px` : 'inherit',
  };
};

/**
 * @deprecated
 * It renders a div that contains a div that contains the text of the item
 * @returns A div with a div inside of it.
 */
const DropdownItem = ({
  item,
  text,
  selected,
  onMouseOver,
  onMouseDown,
  height,
  fontSize,
  className,
  dropdownItemId,
}) => {
  const dataTestName = item.id ? item.id : text;
  return (
    <div>
      <div
        className={`${className} ${
          styles.dropdownItem
        } ${conditionallApplySelectedStyle(selected)}`}
        onMouseOver={onMouseOver}
        onMouseDown={onMouseDown}
        data-test={`admin-${dataTestName}`}
        style={createDropdownStyle(height, fontSize)}
        id={dropdownItemId}
      >
        {text}
      </div>
    </div>
  );
};

DropdownItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  dropdownItemId: PropTypes.string,
};

DropdownItem.defaultProps = {
  item: {},
  selected: false,
  onMouseOver: () => {},
  onMouseDown: () => {},
  height: 0,
  fontSize: 0,
  className: '',
  text: '',
  dropdownItemId: '',
};

export default DropdownItem;
