import {
  HasPendingEntityDraftsRequest,
  HasPendingEntityDraftsResponse,
} from '@cbinsights/cbientityservice/cbientityservice';
import { HasPendingFundingDraftsResponse } from '@cbinsights/fundingservice/fundingservice';
import { UseQueryResult, useQuery } from 'react-query';
import { hasPendingFundingDrafts } from '../../funding-service/api/HasPendingFundingDrafts';
import { hasPendingEntityDrafts } from '../apis/HasPendingEntityDrafts';
import { EntityServicesKeys } from './UseSearchForEntity';

export type HasPendingDrafts = {
  hasPendingFundingDraftsResponse: HasPendingFundingDraftsResponse;
  hasPendingEntityDraftsResponse: HasPendingEntityDraftsResponse;
};

export const useHasPendingDrafts = ({
  idCbiEntity,
  idEntityDraft,
  enabled,
  accessPermissions,
}: {
  idCbiEntity: number;
  idEntityDraft: string;
  enabled: boolean;
  accessPermissions: {
    generalInfo: boolean;
    funding: boolean;
  };
}): UseQueryResult<HasPendingDrafts, Error> =>
  useQuery<HasPendingDrafts, Error, HasPendingDrafts, [EntityServicesKeys]>(
    ['HasPendingDrafts'],
    () => {
      const apiRequest: HasPendingEntityDraftsRequest = {
        idCbiEntity: idCbiEntity || undefined,
        idEntityDraft: idEntityDraft || undefined,
      };

      const requests: [
        Promise<HasPendingEntityDraftsResponse>,
        Promise<HasPendingFundingDraftsResponse>
      ] = [
        accessPermissions.generalInfo
          ? hasPendingEntityDrafts(apiRequest)
          : Promise.resolve({ hasPendingDrafts: false }),
        accessPermissions.funding
          ? hasPendingFundingDrafts(apiRequest)
          : Promise.resolve({ hasPendingDrafts: false }),
      ];

      return Promise.all(requests).then((responses) => ({
        hasPendingEntityDraftsResponse: responses[0],
        hasPendingFundingDraftsResponse: responses[1],
      }));
    },
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
