import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Row,
  Spin,
  Tabs,
  TabsProps,
} from 'antd';
import moment from 'moment';
import { useTypedFlags } from 'client/component-library/FeatureFlags/hooks/useTypedFlags';
import { TeamRoutes, itemRender } from 'client/modules/team/RouteHelpers';
import { TeamSearch } from 'client/modules/team/components/TeamSearch';
import useSelectedTab from 'client/modules/team/hooks/useSelectedTab';
import { useGetUserInformation } from '../hooks/useGetUserInformation';
import UserDetail from '../components/UserDetail';
import PackageSettings from '../components/PackageSettings';
import UsageDetails from '../components/UsageDetails';
import Divider from '../components/Divider';
import { UserActions } from '../components/UserActions';
import UserHistory from '../components/UserHistory';
import { LEGACY_SITE, USER_USAGE } from '../constants';
import FeatureUsage from '../components/FeatureUsage';
import UserEvents from '../components/UserEvents';
import EmailEvents from '../components/EmailEvents';

const NewTabs = ({ items, defaultActiveKey, onChange }: TabsProps) => {
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={items}
      onChange={onChange}
    />
  );
};

const UserDetailPage = () => {
  const { id, isLoading, user, userTrialInfo, refetchUserQuery } =
    useGetUserInformation();

  const TabKeys = {
    loginHistory: 'login-history',
    featureUsage: 'feature-usage',
    userEvents: 'userEvents',
    emailEvents: 'emailEvents',
  };

  const { defaultActiveKey, onChangeTab } = useSelectedTab({
    tabItems: Object.values(TabKeys),
  });

  const items = [
    {
      key: TabKeys.loginHistory,
      label: <span className="text-xl">History</span>,
      children: <UserHistory />,
    },
    {
      key: TabKeys.featureUsage,
      label: <span className="text-xl">Feature Usage</span>,
      children: <FeatureUsage idUser={id} teamId={user?.team?.id} />,
    },
    {
      key: TabKeys.userEvents,
      label: <span className="text-xl">User Events</span>,
      children: <UserEvents idUser={id} enabled />,
    },
    {
      key: TabKeys.emailEvents,
      label: <span className="text-xl">Emails</span>,
      children: <EmailEvents idUser={id} enabled />,
    },
  ];

  const { getFlag } = useTypedFlags();

  const isFeatureUsageEnabled = getFlag(USER_USAGE);

  const isLegacySiteEnabled = getFlag(LEGACY_SITE);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="pb-20">
      <Row className="mb-2">
        <Col
          xl={{ push: 2, span: 6 }}
          md={{ push: 2, span: 10 }}
          xs={{ push: 1, span: 22 }}
          className="flex items-center mt-[50px] pl-3"
        >
          {user.first_name && (
            <Avatar>{`${user.first_name['0']}${user.last_name['0']}`}</Avatar>
          )}
          <span className="mb-0 ml-3 mr-2 text-3xl">{`${user.first_name} ${user.last_name}`}</span>
          <span className="text-sm">(user id: {id})</span>
        </Col>
        <Col
          xl={{ push: 8, span: 8 }}
          md={{ push: 4, span: 8 }}
          xs={{ push: 1, span: 22 }}
          className="flex justify-end items-end"
        >
          <TeamSearch />
        </Col>
      </Row>
      <Row className="mb-8">
        <Col
          xl={{ push: 2, span: 3 }}
          md={{ push: 2, span: 3 }}
          xs={{ push: 1, span: 22 }}
          className="flex items-center pl-3"
        >
          <Breadcrumb
            routes={[
              { breadcrumbName: 'Search', path: TeamRoutes.Search },
              { breadcrumbName: `User ${user.id_user}`, path: '' },
            ]}
            itemRender={itemRender}
          />
        </Col>
        <Col
          xl={{ push: 6, span: 13 }}
          md={{ push: 6, span: 13 }}
          xs={{ push: 1, span: 22 }}
          className="flex justify-end items-end"
        >
          <UserActions email={user.email} idUser={user.id_user} />
          {isLegacySiteEnabled && (
            <Button
              className="ml-2"
              type="dashed"
              href={`${window.envVars.LEGACY_DOMAIN}/admin/user/${id}`}
              target="_blank"
            >
              Use Legacy Site
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col xl={2} md={1} />
        <Col span={isFeatureUsageEnabled ? 10 : 7} className="pl-3">
          <span className="text-2xl">User Details</span>
        </Col>
        <Col span={isFeatureUsageEnabled ? 10 : 7} className="pl-3">
          <span className="text-2xl">Package Settings</span>
        </Col>
        {!isFeatureUsageEnabled && (
          <Col md={7} xl={6} className="pl-3">
            <span className="text-2xl">Usage Details</span>
          </Col>
        )}
      </Row>
      <Row>
        <Col xl={{ span: 20, push: 2 }} md={{ span: 21, push: 1 }}>
          <Divider
            orientationMargin={0}
            className="border-t-2 border-black border-solid"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xl={2} md={1} />
        <Col
          span={isFeatureUsageEnabled ? 10 : 7}
          className="border-solid border-black border-r-2"
        >
          <div className="w-full pl-5 pr-5">
            <UserDetail
              userId={user.id_user}
              email={user.email}
              company={user.company}
              isActive={user.active}
              firtsName={user.first_name}
              lastName={user.last_name}
              teamName={user.team?.name}
              teamId={user.team?.id}
              portal={user.id_portal}
              refetchUserQuery={refetchUserQuery}
              ts_created={user.ts_created}
              recurring={user.recurring}
              linkedEntity={parseInt(
                user.id_cbi_entity as unknown as string,
                10
              )}
            />
          </div>
        </Col>
        <Col
          span={isFeatureUsageEnabled ? 10 : 7}
          className={
            isFeatureUsageEnabled ? '' : 'border-solid border-black border-r-2'
          }
        >
          <div className="w-full pl-5 pr-5">
            <PackageSettings
              refetchUserQuery={refetchUserQuery}
              packageId={user.package?.id}
              packageName={user.package?.name}
              packageEndDate={moment(user.package_end).format('MM/DD/YYYY')}
              packageStartDate={moment(user.package_start).format('MM/DD/YYYY')}
              trialInfo={userTrialInfo}
              userId={user.id_user}
            />
          </div>
        </Col>
        {!isFeatureUsageEnabled && (
          <Col md={7} xl={6}>
            <div className="w-full pl-5 pr-5">
              <UsageDetails />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mb-5 mt-10">
        <Col sm={{ push: 1, span: 22 }} xl={{ push: 2, span: 20 }}>
          {isFeatureUsageEnabled ? (
            <NewTabs
              items={items}
              defaultActiveKey={defaultActiveKey}
              onChange={onChangeTab}
            />
          ) : (
            <UserHistory />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserDetailPage;
