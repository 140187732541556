import React, { ReactElement } from 'react';
import { DataCellProps } from './DataCell';

export type TableRowProps = {
  className?: string;
  direction?: 'row' | 'col';
  children?: ReactElement<DataCellProps> | ReactElement<DataCellProps>[];
  ['data-*']?: string;
};
export const TableRow = ({
  children,
  direction = 'row',
  className = '',
  ...props
}: TableRowProps) => {
  return (
    <div
      {...props}
      className={`${className} flex flex-auto 
    ${direction === 'col' ? 'flex-col' : ''}
    ${direction === 'row' ? 'flex-row' : ''}
    `}
    >
      {children}
    </div>
  );
};
