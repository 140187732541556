import findIndex from 'lodash/findIndex';

export function removeArticle(clusters, clusterId, articleId) {
  const currentClusters = [...clusters];

  if (articleId && clusterId) {
    const clusterIndex = findIndex(currentClusters, { id: clusterId });
    const clusterToUpdate = { ...currentClusters[clusterIndex] };

    clusterToUpdate.articles = [...clusterToUpdate.articles];
    const articleIndex = findIndex(clusterToUpdate.articles, {
      id: articleId,
    });

    clusterToUpdate.articles.splice(articleIndex, 1);
    currentClusters.splice(clusterIndex, 1, clusterToUpdate);
  }

  return currentClusters;
}

export function addOrUpdateClusterEntity(clusters, cluster, entity) {
  const currentClusters = [...clusters];
  const clusterIndex = findIndex(currentClusters, { id: cluster.id });
  const clusterToUpdate = { ...currentClusters[clusterIndex] };
  clusterToUpdate.entities = [...clusterToUpdate.entities];

  let searchId = { id: entity.id };
  if (entity.idEntityDraft) {
    searchId = { idEntityDraft: entity.idEntityDraft };
  }

  const entityIndex = findIndex(clusterToUpdate.entities, searchId);

  const entityToUpdate = clusterToUpdate.entities[entityIndex];

  if (entityToUpdate) {
    clusterToUpdate.entities.splice(entityIndex, 1, {
      ...entityToUpdate,
      ...entity,
    });
  } else {
    clusterToUpdate.entities = [].concat(entity, clusterToUpdate.entities);
  }

  currentClusters.splice(clusterIndex, 1, clusterToUpdate);
  return currentClusters;
}

export function removeClusterEntity(clusters, cluster, entity) {
  const currentClusters = [...clusters];
  const clusterIndex = findIndex(currentClusters, { id: cluster.id });
  const clusterToUpdate = { ...currentClusters[clusterIndex] };
  clusterToUpdate.entities = [...clusterToUpdate.entities];

  const entityIndex = findIndex(clusterToUpdate.entities, { id: entity.id });

  clusterToUpdate.entities.splice(entityIndex, 1);

  currentClusters.splice(clusterIndex, 1, clusterToUpdate);
  return currentClusters;
}
