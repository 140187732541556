import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../styles/css/cell-styles.css';

/** @deprecated It renders an error message if there is an error */
class TableCellError extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { error, localError } = this.props;
    if (!error && !localError) {
      return null;
    }
    return (
      <div style={{ position: 'relative', background: 'transparent' }}>
        <span className={styles.spanError}>{error || localError}</span>
      </div>
    );
  }
}

TableCellError.propTypes = {
  error: PropTypes.string,
  localError: PropTypes.string,
};

TableCellError.defaultProps = {
  error: '',
  localError: '',
};

function mapStateToProps({ table }, props) {
  return {
    error: table.recordsValidation[props.id]
      ? table.recordsValidation[props.id][props.field]
      : '',
  };
}

/** @deprecated */
export default connect(mapStateToProps)(TableCellError);
