import { Button, Input, Layout, List, Row, Select, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { NewsSource } from '@cbinsights/newsservice/newsservice';
import Title from 'antd/lib/typography/Title';
import { useListNewsSources } from './services/hooks';

const SourceStatus = {
  Active: 'active',
  Stale: 'stale',
  Inactive: 'inactive',
  All: 'all',
} as const;

type StatusType = (typeof SourceStatus)[keyof typeof SourceStatus];

const mapSourcesToDataSources: (
  el: NewsSource
) => Pick<NewsSource, 'active' | 'stale' | 'id_source' | 'name' | 'rss'> = (
  el
) => ({
  id_source: el.id_source,
  name: el.name,
  rss: el.rss,
  active: el.active,
  stale: el.stale,
});

const newsSourcesFilters = {
  [SourceStatus.All]: () => true,
  [SourceStatus.Active]: (el) => el.active === true,
  [SourceStatus.Inactive]: (el) => el.active === false,
  [SourceStatus.Stale]: (el) => el.stale === true,
};

const filterAndMapNewsSources = (
  news_sources: NewsSource[],
  status: StatusType,
  filterValue: string
) => {
  if (!news_sources) return [];

  return news_sources
    ?.filter(newsSourcesFilters[status])
    ?.filter((el) => {
      if (!filterValue) {
        return true;
      }
      return el.name.includes(filterValue) || el.rss.includes(filterValue);
    })
    .map(mapSourcesToDataSources);
};

export const NewsSourcesList = memo(
  ({
    sourcesData,
  }: {
    sourcesData: Pick<
      NewsSource,
      'id_source' | 'name' | 'rss' | 'active' | 'stale'
    >[];
  }) => (
    <List
      itemLayout="vertical"
      className="w-full"
      size="large"
      pagination={{
        pageSize: 20,
      }}
      dataSource={sourcesData}
      renderItem={(item) => (
        <List.Item key={item.name}>
          <div className="flex items-center">
            <div className="flex flex-col flex-1 max-w-[90%]">
              <div title={item.name}>{item.name}</div>
              <div className="truncate overflow-hidden" title={item.rss}>
                {item.rss}
              </div>
            </div>
            <div className="flex-0">
              <Button>
                <Link to={`/news/sources/${item.id_source}`}>Edit</Link>
              </Button>
            </div>
          </div>
        </List.Item>
      )}
    />
  )
);

export const NewsSources = () => {
  const { data, isFetched } = useListNewsSources();
  const [sourcesData, setSourcesData] =
    useState<
      Pick<NewsSource, 'id_source' | 'name' | 'rss' | 'active' | 'stale'>[]
    >();
  const [sourceStatus, setSourceStatus] = useState<StatusType>(
    SourceStatus.All
  );
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    setSourcesData(
      filterAndMapNewsSources(data?.news_sources, sourceStatus, filterValue)
    );
  }, [data, sourceStatus, filterValue]);

  if (!isFetched) {
    return (
      <div className="flex justify-center my-10" title="progress">
        <Spin size="large" />
      </div>
    );
  }

  const handleStateChange = (value: StatusType) => {
    setSourceStatus(value);
  };

  const handleFilter: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <Layout className="container mx-auto my-8 !bg-white ">
      <Row justify="space-between" align="middle" className="mb-5">
        <Title level={2} className="my-0">
          News Sources
        </Title>
        <Select
          defaultValue={sourceStatus}
          className="w-[150px]"
          onChange={handleStateChange}
        >
          {Object.keys(SourceStatus).map((statusKey) => (
            <Select.Option
              key={SourceStatus[statusKey]}
              value={SourceStatus[statusKey]}
            >
              {statusKey}
            </Select.Option>
          ))}
        </Select>
      </Row>

      <Row className="mb-5">
        <Button>
          <Link to="/news/groups/1">Manage Groups</Link>
        </Button>
      </Row>

      <Row justify="center">
        <div className="min-w-[700px] max-w-[700px]">
          <Row justify="space-between" align="middle" className="px-3">
            <Input
              style={{ flex: 1 }}
              className="mr-1"
              onChange={handleFilter}
            />
            <Button type="primary">
              <Link to="/news/sources/add">Add Source</Link>
            </Button>
          </Row>
          <Row>
            <NewsSourcesList sourcesData={sourcesData} />
          </Row>
        </div>
      </Row>
    </Layout>
  );
};
