export default ({ email, idUser, firstName, lastName }) => {
  if (window.FS) {
    window.FS.identify(idUser);
    window.FS.setUserVars({
      email,
      displayName: `${firstName} ${lastName}`,
      userId_str: `${idUser}`,
    });
  } else {
    // eslint-disable-next-line
    console.log('Could not intialize full story!');
  }
};
