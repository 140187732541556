import React, { ReactElement } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { useLDUserFlagFetcher } from 'client/app/components/LDConfigProvider';
import { healthCheckRoutes } from 'client/modules/health-check/HealthCheck';
import { localLoginRoutes } from 'client/modules/local-login/routes';
import { commonRoutes } from 'client/modules/common/routes';
import { marketRoutes } from 'client/modules/markets/routes';
import { userAdminRoutes } from 'client/modules/admin-user-admin/routes';
import { packagesRoutes } from 'client/modules/packages/routes';
import { cbiEntityRoutes } from 'client/modules/cbi-entity/routes';
import { profileQueueRoutes } from 'client/modules/third-party-admin/profile-queue/routes';
import { businessRelationshipRoutes } from 'client/modules/business-relationships/routes';
import { storiesRoutes } from 'client/modules/stories/routes';
import { storySharerRoutes } from 'client/modules/story-sharer/routes';
import { universityDisplayRoutes } from 'client/modules/university-display/routes';
import { accountSharingDetectionRoutes } from 'client/modules/account-sharing-detection/routes';
import { publicPagesRoutes } from 'client/modules/public-pages/routes';
import { dataPlatformRoutes } from 'client/modules/data-platform/routes';
import { collectionsRoutes } from 'client/modules/collections/routes';
import { newsQueueRoutes } from 'client/modules/news/routes';
import { valuationRoutes } from 'client/modules/third-party-admin/valuations/routes';
import { peopleRoutes } from 'client/modules/person-admin/routes';
import { serviceProviderRoutes } from 'client/modules/service-provider/routes';
import { domainBlockRoutes } from 'client/modules/domain-block/routes';
import { universitiesRoutes } from 'client/modules/universities/routes';
import { useAdminPageViewEvent } from 'client/utils/user-event/hooks/useAdminPageViewEvent';
import { industryLandingPagesRoutes } from 'client/modules/industry-landing-pages/routes';
import { UseAppInitialValidations } from 'client/utils/permissions-validation/hooks/UseAppInitialValidations';
import { adminPermissionsRoutes } from 'client/modules/permissions/routes';
import { draftQueueRoutes } from 'client/modules/draft-queue/routes';
import { externalApiRoutes } from 'client/modules/external-api/routes';
import { dataFeedCreditRoutes } from 'client/modules/data-feed-credit/routes';
import { trialPackageRoutes } from 'client/modules/trial-package/routes';
import { search, teamRoutes } from 'client/modules/team/routes';
import { userRoutes } from 'client/modules/users/routes';
import { marketInfrastructureRoutes } from 'client/modules/market-reports/routes';
import { proprietaryInsightsRoutes } from 'client/modules/proprietary-insights/routes';
import { simpleQueueRoutes } from 'client/modules/simple-queue/routes';

const ReduxContainer = React.lazy(
  () => import('client/app/components/Container')
);

export const AppRoutes = (): ReactElement => {
  const areValidationsReady = UseAppInitialValidations();
  useLDUserFlagFetcher();
  useAdminPageViewEvent();

  const appRoutes: RouteObject[] = [
    healthCheckRoutes,
    localLoginRoutes,
    {
      path: '/',
      element: (
        <React.Suspense fallback={undefined}>
          <ReduxContainer />
        </React.Suspense>
      ),
      children: [
        ...commonRoutes,
        marketRoutes,
        userAdminRoutes,
        packagesRoutes,
        cbiEntityRoutes,
        businessRelationshipRoutes,
        storiesRoutes,
        storySharerRoutes,
        universityDisplayRoutes,
        accountSharingDetectionRoutes,
        publicPagesRoutes,
        dataPlatformRoutes,
        collectionsRoutes,
        profileQueueRoutes,
        newsQueueRoutes,
        valuationRoutes,
        peopleRoutes,
        serviceProviderRoutes,
        domainBlockRoutes,
        universitiesRoutes,
        industryLandingPagesRoutes,
        adminPermissionsRoutes,
        draftQueueRoutes,
        externalApiRoutes,
        dataFeedCreditRoutes,
        trialPackageRoutes,
        teamRoutes,
        search,
        marketInfrastructureRoutes,
        userRoutes,
        simpleQueueRoutes,
        ...proprietaryInsightsRoutes,
      ],
    },
  ];

  const routes = useRoutes(appRoutes);
  return areValidationsReady ? routes : null;
};
