import { Space } from 'antd';
import { useFormikContext } from 'formik';
import { FinancialFormValues } from './hooks/useFinancials';

export const CompanyFinancialInfo = () => {
  const { values, initialValues } = useFormikContext<FinancialFormValues>();

  const hasCompanyFinancials =
    initialValues?.company.length > 0 &&
    initialValues.company[0]?.ticker.value ===
      values?.company[0]?.ticker.value &&
    initialValues.company[0]?.shortExchangeCode.value ===
      values?.company[0]?.shortExchangeCode.value;

  if (!hasCompanyFinancials) {
    return null;
  }

  const {
    ticker: { value: initialTicker },
    shortExchangeCode: { value: initialShortExchangeCode },
    previousClose,
    marketCap,
  } = initialValues.company[0];

  return (
    <Space direction="vertical">
      <div className="font-bold">
        Current status of {initialShortExchangeCode} - {initialTicker}
      </div>
      <p>
        <span>Previous close: ${previousClose?.toLocaleString('en-US')}</span>
        <br />
        <span>Market cap: ${marketCap?.toLocaleString('en-US')}</span>
      </p>
    </Space>
  );
};
