import React from 'react';
import { Button, Modal, Switch, message } from 'antd';
import { GetTeamResponse } from '@cbinsights/adminservice/adminservice';
import { useCopyTextToClipboard } from 'client/utils/copy';
import { buildEnvURL } from 'client/utils/env';
import { useUpdateTeam } from '../../hooks/useUpdateTeam';
import { useGetTeamQuery } from '../../services/hooks/useGetTeamQuery';

interface Props {
  teamId: number;
  refetchTeam: () => void;
  lbsStatus?: GetTeamResponse['signup_code'];
  domainWhitelist: string;
}

const LinkBasedSignup = ({
  teamId,
  refetchTeam,
  lbsStatus,
  domainWhitelist,
}: Props) => {
  const { onSubmit: updateTeam, updateTeamQuery } = useUpdateTeam(refetchTeam);

  const { isLoading: isLoadingTeam } = useGetTeamQuery({
    enabled: false,
    params: { id_team: Number(teamId) },
  });

  const { isLoading: isCopyingText, copyTextToClipboard } =
    useCopyTextToClipboard(1000, () => {
      message.success('copied to clipboard');
    });

  const updateLBSStatus = (enabled: boolean) => {
    updateTeam(
      {
        lbs_enabled: enabled,
        domain_list: domainWhitelist,
      },
      teamId
    )
      .then(() => {
        message.success("Success! The team's information has been updated.");
        refetchTeam();
      })
      .catch((e) => {
        if (
          e?.response?.body?.error?.details?.includes(
            'valid domain list is required'
          )
        ) {
          message.error('Error! Please add a valid Domain Whitelist');
          return;
        }

        message.error(
          "Error! Faild to update the team's information. Please try again later."
        );
      });
  };

  const lbsConfirm = (enabled: boolean) => {
    if (!domainWhitelist) {
      Modal.info({
        title: 'LBS cannot be enabled',
        content:
          'Please add domains to the Domain Whitelist field before enabling LBS.',
        onOk() {},
      });

      return;
    }

    const content = enabled
      ? 'Are you sure you want to enable this setting?'
      : 'Disabling Link Based Signup will prevent any new users from accessing the shared signup link. Are you sure you want to proceed?';

    Modal.confirm({
      title: 'Change LBS status',
      content,
      onOk: () => updateLBSStatus(enabled),
    });
  };

  const copyLbsURL = () => {
    copyTextToClipboard(buildEnvURL(`signup-via-code?code=${lbsStatus.code}`));
  };

  return (
    <div>
      {lbsStatus?.enabled && (
        <Button type="link" onClick={copyLbsURL} disabled={isCopyingText}>
          Copy URL
        </Button>
      )}
      <Switch
        checked={lbsStatus?.enabled}
        onChange={lbsConfirm}
        loading={updateTeamQuery?.isLoading || isLoadingTeam}
      />
    </div>
  );
};

export default LinkBasedSignup;
