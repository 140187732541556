import * as actions from '../actions/role-permissions';

const initialState = {
  features: {
    InternalPendingApproval: false,
    externalReview: false,
    externalQAReview: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_ROLE_PERMISSIONS: {
      return { ...state, features: action.features };
    }
    default:
      return state;
  }
}
