// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-component-library-buttons-Button-___buttonBaseStyle__buttonBase___KMigE {
  font-family: Roboto !important;
  font-weight: bold !important;
  font-size: 13px !important;
  letter-spacing: 1px;
  line-height: 19px !important;
  text-align: center;
  padding: 8px 15px 7px 15px !important;
  border-radius: 3px !important;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./client/component-library/buttons/Button/buttonBaseStyle.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,4BAA4B;EAC5B,0BAA0B;EAC1B,mBAAmB;EACnB,4BAA4B;EAC5B,kBAAkB;EAClB,qCAAqC;EACrC,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".buttonBase {\n  font-family: Roboto !important;\n  font-weight: bold !important;\n  font-size: 13px !important;\n  letter-spacing: 1px;\n  line-height: 19px !important;\n  text-align: center;\n  padding: 8px 15px 7px 15px !important;\n  border-radius: 3px !important;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBase": `client-component-library-buttons-Button-___buttonBaseStyle__buttonBase___KMigE`
};
export default ___CSS_LOADER_EXPORT___;
