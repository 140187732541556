import { DeleteOutlined } from '@ant-design/icons';
import { MarketEntityInReviewStatus } from '@cbinsights/espmatrixservice/espmatrixservice';
import { Button, Modal } from 'antd';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import {
  MarketFormProps,
  MarketFormValues,
} from '../hooks/useGetMarketDetailsValues';

const deleteCompany = (setFieldValue, name) => () => {
  setFieldValue(
    name,
    MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_DELETED
  );
};

export const DeleteCompanyButton = ({
  index,
  name,
  url,
}: {
  index: number;
  name: string;
  url: string;
}) => {
  const { setFieldValue } = useFormikContext<MarketFormValues>();
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  return (
    <Button
      type="link"
      className="text-cbi-red"
      title="Delete Company"
      onClick={toggleModal}
    >
      Delete <DeleteOutlined />
      <Modal
        open={open}
        onOk={deleteCompany(
          setFieldValue,
          `${MarketFormProps.Companies}[${index}].status`
        )}
        title="Delete Company"
        onCancel={toggleModal}
      >
        <p>
          You are trying to delete <b>{name}</b> (<b>{url}</b>), Are you wish to
          continue?
        </p>
      </Modal>
    </Button>
  );
};
