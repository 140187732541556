import * as tabName from '../tab-names';
import {
  marketSizingTableColumnNames,
  marketSizingModalColumnNames,
  hiddenRowColumns,
  marketSizingInnerTableColumnNames,
} from './market-sizing-details';
import { relatedMarketsTableColumnNames } from './related-markets';
import { listOfMarketsTableColumnNames } from './list-of-markets';

export const paginationPosition = {
  [tabName.MARKET_SIZING_DETAILS]: 9,
  [tabName.RELATED_MARKETS_DETAILS]: 3,
  [tabName.LIST_OF_MARKETS_DETAILS]: 3,
};

export const getTableStructure = (table) => {
  switch (table) {
    case tabName.MARKET_SIZING_DETAILS:
      return marketSizingTableColumnNames;
    case tabName.RELATED_MARKETS_DETAILS:
      return relatedMarketsTableColumnNames;
    case tabName.LIST_OF_MARKETS_DETAILS:
      return listOfMarketsTableColumnNames;
    case 'marketSizingModal':
      return marketSizingModalColumnNames;
    case 'marketsInnerTable':
      return marketSizingInnerTableColumnNames;
    case 'hiddenRowColumns':
      return hiddenRowColumns;
    default:
      return [];
  }
};

export const getFooterPaginationPosition = (table) => {
  return paginationPosition[table] || 3;
};

export default getTableStructure;
