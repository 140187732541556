/* eslint-disable react/static-property-placement */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @deprecated */
export default class FormError extends PureComponent {
  static propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.string,
    htmlFor: PropTypes.string,
  };

  static defaultProps = {
    touched: false,
    error: null,
    htmlFor: undefined,
  };

  render() {
    const { touched, error, htmlFor } = this.props;

    return (
      <div>
        {touched && error && (
          <label htmlFor={htmlFor} className="control-label">
            {error}
          </label>
        )}
      </div>
    );
  }
}
