/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectWithSearch from 'client/component-library/Select/SearchableSelect/SelectWithSearch';
import Tooltip from 'client/component-library/Tooltip/Tooltip';
import Modal from 'client/component-library/modals/MaterialUIModal';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Button from 'client/component-library/buttons/Button';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import TableFooter from 'client/modules/common/components/Table/TableComponents/TableFooter';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from 'client/component-library/ExpansionPanel';
import styles from 'client/component-library/Input/input.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import iconButtonStyle from '../containers/styles/material-ui/buttonStyle';

import {
  addPipelineVertexes,
  fetchAdditionalComponentCandidate,
  removeAdditionalComponentCandidate,
  clearAdditionalComponentCandidates,
  getSuggestedPipelineVertexes,
  setDisplayNewVertexes,
  addSuggestedExcludeLabel,
  clearSuggestedExcludeLabels,
  allSuggestedExcludeLabels,
  getAllIds,
} from '../redux/actions/search';
import {
  outerStyle,
  modalHeaderTextStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import {
  candidateColumns,
  suggestedComponentColumns,
} from './Table/utils/table-columns';
import {
  initializeSuggestionDataDetailsFromUrlParams,
  updateSuggestionPageNumber,
  updateSuggestionRecordsPerPage,
  initializeNewVertexesDataDetailsFromUrlParams,
  updateNewVertexesPageNumber,
  updateNewVertexesRecordsPerPage,
} from '../redux/actions/details';
import ExcludeLabelsSelect from './ExcludeLabelsSelect';

class AddVertex extends Component {
  state = {
    showAddNodePopover: false,
    searchTerm: '',
    expanded: true,
  };

  UNSAFE_componentWillMount() {
    this.props.initializeSuggestionDataDetailsFromUrlParams();
    this.props.initializeNewVertexesDataDetailsFromUrlParams();
  }

  setSearchTerm = ({ id }) => {
    this.setState({ searchTerm: id });
  };

  setSearchLabel = ({ id }) => {
    this.setState({ searchLabel: id });
    this.props.getAllIds({ labels: [id] });
  };

  getSuggestedPipelineVertexes = (pageNumber, recordsPerPage) => {
    let vertexes = this.props.newVertexes;
    if (
      this.props.pipelineRawVertexes &&
      !!this.props.pipelineRawVertexes.length
    ) {
      vertexes = this.props.pipelineRawVertexes.concat(this.props.newVertexes);
    }
    this.props.getSuggestedPipelineVertexes({
      vertexes,
      pageNumber,
      recordsPerPage,
      excludeLabels: this.props.suggestedExcludeLabels,
    });
  };

  fetchAdditionalComponentCandidate = () => {
    this.props.fetchAdditionalComponentCandidate({
      idVertex: this.state.searchTerm,
      pageNumber: this.props.newVertexesPageNumber,
      recordsPerPage: this.props.newVertexesRecordsPerPage,
      excludeLabels: this.props.suggestedExcludeLabels,
    });
  };

  openAddNodePopover = () => {
    this.getSuggestedPipelineVertexes(
      this.props.suggestionPageNumber,
      this.props.suggestionRecordsPerPage
    );
    this.setState({ showAddNodePopover: true });
  };

  closeAddNodePopover = () => {
    this.setState({ showAddNodePopover: false });
  };

  clearAdditionalComponentCandidates = () => {
    this.props.clearAdditionalComponentCandidates();
    this.props.updateSuggestionPageNumber(0);
    this.getSuggestedPipelineVertexes(0, 0);
    this.props.updateNewVertexesPageNumber(0);
  };

  handleChangeSuggestionPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    this.props.updateSuggestionPageNumber(page);
    this.getSuggestedPipelineVertexes(
      page,
      this.props.suggestionRecordsPerPage
    );
  };

  handleUpdateSuggestionRecordsPerPage = (event) => {
    this.props.updateSuggestionRecordsPerPage(event.target.value);
    this.props.updateSuggestionPageNumber(0);
    this.getSuggestedPipelineVertexes(0, event.target.value);
  };

  handleChangeNewVertexesPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.newVertexes.length) {
      this.props.updateNewVertexesPageNumber(page);
      this.props.setDisplayNewVertexes(
        page,
        this.props.newVertexesRecordsPerPage
      );
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  handleUpdateNewVertexesRecordsPerPage = (event) => {
    if (this.props.newVertexes.length) {
      this.props.updateNewVertexesRecordsPerPage(event.target.value);
      this.props.updateNewVertexesPageNumber(0);
      this.props.setDisplayNewVertexes(0, event.target.value);
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  popOverDivStyle = {
    ...outerStyle,
    width: '800px',
    borderColor: '#4cabce',
  };

  addPipelineVertexes = () => {
    this.props.addPipelineVertexes(
      this.props.pipelineName,
      this.props.newVertexes,
      this.props.graphExcludeLabels
    );
    this.setState({ showAddNodePopover: false });
    this.clearAdditionalComponentCandidates();
    this.clearSuggestedExcludeLabels();
  };

  toggleExpandedState = (event, expanded) => {
    this.setState({ expanded });
  };

  cancelAddVertexes = () => {
    this.setState({ showAddNodePopover: false });
    this.clearAdditionalComponentCandidates();
    this.clearSuggestedExcludeLabels();
  };

  excludeLabelGetSuggestedPipelineVertexes = () => {
    this.getSuggestedPipelineVertexes(0, this.props.suggestionRecordsPerPage);
  };

  clearSuggestedExcludeLabels = () => {
    this.props.clearSuggestedExcludeLabels(
      0,
      this.props.suggestionRecordsPerPage
    );
  };

  allSuggestedExcludeLabels = () => {
    this.props.allSuggestedExcludeLabels(
      0,
      this.props.suggestionRecordsPerPage,
      this.props.labels
    );
  };

  render() {
    return (
      <div>
        <Tooltip title="Add Node to Pipeline">
          <IconButton
            style={{ ...iconButtonStyle, backgroundColor: '#4cabce' }}
            onClick={this.openAddNodePopover}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <Icon
              className={styles.iconClassPossitionHack}
              name="add"
              family="material"
            />
          </IconButton>
        </Tooltip>
        <Modal
          id="add-node-popover"
          classes={{ paperScrollPaper: styles.modalClassName }}
          open={this.state.showAddNodePopover}
          handleCloseModal={this.closeAddNodePopover}
        >
          <div style={this.headerStyle}>
            <div style={{ backgroundColor: '#4cabce' }}>
              <p style={modalHeaderTextStyle}>
                Add Components to Pipeline {this.props.pipelineName}
              </p>
            </div>
            <div
              style={{ padding: '8px', height: '70vh', overflowY: 'scroll' }}
            >
              <div className="flex">
                <div>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Search Components by ID
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <SelectWithSearch
                        value={this.state.searchLabel}
                        options={this.props.labels.map((label) => ({
                          id: label,
                          name: label,
                        }))}
                        onChange={this.setSearchLabel}
                        className={styles.componentSearch}
                      />
                      <SelectWithSearch
                        value={this.state.searchTerm}
                        options={this.props.labelsIds.map((label) => ({
                          id: label,
                          name: label,
                        }))}
                        onChange={this.setSearchTerm}
                        className={styles.componentSearch}
                      />
                      <Button onClick={this.fetchAdditionalComponentCandidate}>
                        Add
                      </Button>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={
                      this.props.suggestedVertexRecordOrder.length > 0
                    }
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <p style={{ fontFamily: 'Roboto' }}>
                        Suggested Components
                      </p>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="flex-shrink">
                        <ExcludeLabelsSelect
                          excludeLabels={this.props.suggestedExcludeLabels}
                          addExcludeLabel={this.props.addSuggestedExcludeLabel}
                          clearExcludeLabels={this.clearSuggestedExcludeLabels}
                          allExcludeLabels={this.allSuggestedExcludeLabels}
                          filter={this.excludeLabelGetSuggestedPipelineVertexes}
                        />
                      </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableHeader columnNames={suggestedComponentColumns} />
                        <TableBody
                          records={this.props.suggestedVertexRecords}
                          columnNames={suggestedComponentColumns}
                          recordsOrder={this.props.suggestedVertexRecordOrder}
                          recordCellStyles={
                            this.props.suggestedVertexRecordCellStyles
                          }
                        />
                        <TableFooter
                          totalRecords={this.props.totalSuggestions}
                          recordsPerPage={this.props.suggestionRecordsPerPage}
                          pageNumber={this.props.suggestionPageNumber}
                          handleChangePageNumber={
                            this.handleChangeSuggestionPageNumber
                          }
                          handleUpdateRecordsPerPage={
                            this.handleUpdateSuggestionRecordsPerPage
                          }
                          rowsPerPageOptions={[3, 6, 9]}
                        />
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div>
                  <p style={{ fontFamily: 'Roboto' }}>
                    New Components
                    <Button onClick={this.clearAdditionalComponentCandidates}>
                      Remove All
                    </Button>
                  </p>
                  <Table>
                    <TableHeader columnNames={candidateColumns} />
                    <TableBody
                      records={this.props.newVertexRecords}
                      columnNames={candidateColumns}
                      recordsOrder={this.props.newVertexRecordsOrder}
                      recordCellStyles={
                        this.props.suggestedVertexRecordCellStyles
                      }
                    />
                    <TableFooter
                      totalRecords={this.props.numNewVertexes}
                      recordsPerPage={this.props.newVertexesRecordsPerPage}
                      pageNumber={this.props.newVertexesPageNumber}
                      handleChangePageNumber={
                        this.handleChangeNewVertexesPageNumber
                      }
                      handleUpdateRecordsPerPage={
                        this.handleUpdateNewVertexesRecordsPerPage
                      }
                      rowsPerPageOptions={[3, 6, 9]}
                    />
                  </Table>
                </div>
              </div>
            </div>
            <div>
              <Button onClick={this.addPipelineVertexes}>Save</Button>
              <Button onClick={this.cancelAddVertexes}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    newPipelineComponents: dataPlatform.search.newPipelineComponents,
    newVertexRecords: dataPlatform.search.newVertexRecords,
    newVertexRecordsOrder: dataPlatform.search.newVertexRecordsOrder,
    newVertexes: dataPlatform.search.newVertexes,
    suggestedVertexRecords: dataPlatform.search.suggestedVertexRecords,
    suggestedVertexRecordOrder: dataPlatform.search.suggestedVertexRecordOrder,
    pipelineRawVertexes: dataPlatform.search.pipelineRawVertexes,
    suggestedVertexRecordCellStyles:
      dataPlatform.search.suggestedVertexRecordCellStyles,
    suggestionRecordsPerPage: dataPlatform.details.suggestionRecordsPerPage,
    suggestionPageNumber: dataPlatform.details.suggestionPageNumber,
    totalSuggestions: dataPlatform.search.totalSuggestions,
    numNewVertexes: dataPlatform.search.numNewVertexes,
    newVertexesRecordsPerPage: dataPlatform.details.newVertexesRecordsPerPage,
    newVertexesPageNumber: dataPlatform.details.newVertexesPageNumber,
    suggestedExcludeLabels: dataPlatform.search.suggestedExcludeLabels,
    graphExcludeLabels: dataPlatform.search.graphExcludeLabels,
    labels: dataPlatform.search.labels,
    labelsIds: dataPlatform.search.labelsIds,
  };
}

AddVertex.propTypes = {
  addPipelineVertexes: PropTypes.func.isRequired,
  pipelineName: PropTypes.string.isRequired,
  newVertexRecords: PropTypes.object,
  newVertexRecordsOrder: PropTypes.arrayOf(PropTypes.string),
  fetchAdditionalComponentCandidate: PropTypes.func.isRequired,
  newVertexes: PropTypes.arrayOf(PropTypes.object),
  suggestedVertexRecords: PropTypes.object,
  suggestedVertexRecordOrder: PropTypes.arrayOf(PropTypes.string),
  pipelineRawVertexes: PropTypes.arrayOf(PropTypes.object),
  getSuggestedPipelineVertexes: PropTypes.func.isRequired,
  clearAdditionalComponentCandidates: PropTypes.func.isRequired,
  suggestedVertexRecordCellStyles: PropTypes.object,
  suggestionPageNumber: PropTypes.number.isRequired,
  suggestionRecordsPerPage: PropTypes.number.isRequired,
  updateSuggestionPageNumber: PropTypes.func.isRequired,
  updateSuggestionRecordsPerPage: PropTypes.func.isRequired,
  initializeSuggestionDataDetailsFromUrlParams: PropTypes.func.isRequired,
  totalSuggestions: PropTypes.number.isRequired,
  numNewVertexes: PropTypes.number.isRequired,
  newVertexesRecordsPerPage: PropTypes.number.isRequired,
  newVertexesPageNumber: PropTypes.number.isRequired,
  updateNewVertexesPageNumber: PropTypes.func.isRequired,
  updateNewVertexesRecordsPerPage: PropTypes.func.isRequired,
  initializeNewVertexesDataDetailsFromUrlParams: PropTypes.func.isRequired,
  setDisplayNewVertexes: PropTypes.func.isRequired,
  suggestedExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  addSuggestedExcludeLabel: PropTypes.func.isRequired,
  clearSuggestedExcludeLabels: PropTypes.func.isRequired,
  allSuggestedExcludeLabels: PropTypes.func.isRequired,
  getAllIds: PropTypes.func.isRequired,
  graphExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  labelsIds: PropTypes.arrayOf(PropTypes.string),
};

AddVertex.defaultProps = {
  newVertexes: [],
  newVertexRecords: {},
  newPipelineComponents: [],
  newVertexRecordsOrder: [],
  suggestedVertexRecords: {},
  suggestedVertexRecordOrder: [],
  pipelineRawVertexes: [],
  suggestedVertexRecordCellStyles: {},
  totalSuggestions: 0,
  suggestionPageNumber: 0,
  suggestionRecordsPerPage: 0,
  numNewVertexes: 0,
  newVertexesPageNumber: 0,
  newVertexesRecordsPerPage: 3,
  suggestedExcludeLabels: [],
  graphExcludeLabels: [],
  labels: [],
  labelsIds: ['Select Label First'],
};

export default connect(mapStateToProps, {
  addPipelineVertexes,
  fetchAdditionalComponentCandidate,
  getSuggestedPipelineVertexes,
  removeAdditionalComponentCandidate,
  clearAdditionalComponentCandidates,
  updateSuggestionRecordsPerPage,
  updateSuggestionPageNumber,
  initializeSuggestionDataDetailsFromUrlParams,
  updateNewVertexesPageNumber,
  updateNewVertexesRecordsPerPage,
  initializeNewVertexesDataDetailsFromUrlParams,
  setDisplayNewVertexes,
  addSuggestedExcludeLabel,
  clearSuggestedExcludeLabels,
  allSuggestedExcludeLabels,
  getAllIds,
})(AddVertex);
