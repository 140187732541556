import {
  SET_LIVE_SEARCH_RESULTS,
  SET_LIVE_SEARCH_TERM,
  RESET_LIVE_SEARCH_RESULTS,
} from '../actions/live-search';

/** @deprecated */
export default function (
  state = {
    investors: [],
    companies: [],
    term: '',
  },
  action
) {
  switch (action.type) {
    case SET_LIVE_SEARCH_RESULTS:
      if (state.term === action.term) {
        return {
          ...state,
          investors: action.data.investors,
          companies: action.data.companies,
        };
      }
      return state;
    case SET_LIVE_SEARCH_TERM:
      return {
        ...state,
        term: action.term,
      };
    case RESET_LIVE_SEARCH_RESULTS:
      return {};
    default:
      return state;
  }
}
