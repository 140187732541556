import { keyBy } from 'lodash';
import { mapEmployeeResponse } from '../utils/records-helper';
import { FETCH_ALL_COMPANY_EMPLOYEES } from '../actions/company-employees';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_COMPANY_EMPLOYEES: {
      const companyEmployees = mapEmployeeResponse(action.employeeInfo);
      return keyBy(companyEmployees, 'id');
    }
    default:
      return state;
  }
}
