import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import { UserRoutes } from './RouteHelpers';
import UserDetailPage from './containers/UserDetailPage';
import { teamPermissions } from '../team/permissions/Team';

// This page will be hidden for the time being
export const userRoutes: RouteObject = {
  path: UserRoutes.PageIndex,
  element: (
    <PermissionsProtectedRoute
      pageId={teamPermissions.pageId}
      currentPath={UserRoutes.PageIndex}
    />
  ),
  children: [
    {
      path: UserRoutes.UserDetail,
      element: <UserDetailPage />,
    },
  ],
};
