/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
// https://react-select.com/home#getting-started
import styles from 'client/component-library/Select/SearchableSelect/styles/styles.css';
import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import AsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';

import {
  string,
  bool,
  arrayOf,
  shape,
  func,
  oneOfType,
  object,
  number,
} from 'prop-types';
import { Icon } from 'client/modules/business-relationships/components/GenericComponents/Icon';
import { BasicOption } from './options/BasicOption';

export const DEFAULT_OPTION = Object.freeze({
  id: 0,
  name: 'Select One',
  value: 0,
  label: 'Select One',
});

/* Renders The Placeholder */
function Placeholder() {
  return null;
}

/* Renders The Input */
export function Input(props) {
  const {
    selectProps: { name, value, disablePlaceholder, placeholder },
  } = props;

  // only show placeholder if no value is selected and was not the Select One
  // a value can be an array of values or a single value
  const shouldShowPlaceholder =
    !disablePlaceholder &&
    (!value || (value && !value.label && (value.value <= 0 || !value.length)));

  const isMulti = Array.isArray(value);
  const singleValueTitle = get(value, 'name') || get(value, 'label', '');
  const multiValueTitle = (isMulti ? value : [])
    .map(({ label }) => label)
    .join(',');

  return (
    <div className="absolute">
      <components.Input
        {...props}
        className="simple-select"
        isHidden={false} // react-selects toggles this but we dont want to
        data-test={`simple-select-${name}`}
        name={`simple-select-input-${name}`}
        title={isMulti ? multiValueTitle : singleValueTitle}
        placeholder={shouldShowPlaceholder ? placeholder : ''}
      />
    </div>
  );
}

export const getOptionStyles = (shouldHighlightOption, state) => {
  let backgroundColor = 'white';

  if (state.isFocused) {
    backgroundColor = '#b2d4ff'; // light blue
  } else if (
    shouldHighlightOption(state) &&
    !state.isSelected &&
    !state.data.label.includes('Create "') // prevent highlight of any create rows
  ) {
    backgroundColor = '#94E5A2'; // green
  } else if (state.isSelected) {
    backgroundColor = '#3182ce'; // blue
  }
  return { backgroundColor };
};

const CopyableSingleValue = (el) => {
  const [copy, setCopy] = useState(false);
  return (
    <Fragment>
      <div
        className="text-base overflow-hidden relative"
        style={{
          gridColumn: '1/2',
          height: '23px',
        }}
      >
        <span
          className="absolute overflow-ellipsis whitespace-nowrap overflow-hidden"
          style={{
            top: '15%',
          }}
        >
          {el.children}
        </span>
      </div>
      <div
        className="justify-self-end"
        style={{
          gridColumn: '2/3',
          zIndex: 1,
        }}
      >
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigator.clipboard
              .writeText(el.children)
              .then(() => setCopy(true))
              .then(() => setTimeout(() => setCopy(false), 3000));
          }}
        >
          {copy ? (
            <Icon
              className="text-green-500  hover:text-green-600"
              iconName="done"
            />
          ) : (
            <Icon
              className="text-gray-500  hover:text-gray-600"
              iconName="content_copy"
            />
          )}
        </button>
      </div>
    </Fragment>
  );
};

export function SimpleSelect({
  label,
  required,
  isSearchable,
  isCopyable,
  renderComp,
  isCreatable,
  inheritStyles,
  shouldHighlight,
  shouldHighlightOption,
  ...props
}) {
  let SelectComponent = Select;

  if (props.loadOptions) {
    SelectComponent = AsyncCreatableSelect;
  } else if (isCreatable) {
    SelectComponent = Creatable;
  }

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value || null);
  }, [props.value]);

  const onChange = (v, optionProps) => {
    setValue(v);
    props.onChange(v, optionProps);
  };

  // react-select takes a styles object below, could not adjust background-color through className
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: shouldHighlight ? '#feebc8' : base.backgroundColor, // bg-orange-200
    }),
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyles(shouldHighlightOption, state),
    }),
  };

  return (
    <div className="w-full" data-test="simple-select-container">
      {label && (
        <div className="text-xs text-left">
          {label}&nbsp;
          {required && <span className="text-xs text-red-600">*</span>}
        </div>
      )}
      <>
        <SelectComponent
          createOptionPosition={isCreatable ? 'first' : 'last'}
          isSearchable={isSearchable}
          classNamePrefix={styles.simpleSelect}
          className={cx(styles.simpleSelect, styles.newInput, {
            [styles.simpleSelectInherit]: inheritStyles,
            [styles.simpleSelectCopyable]: isCopyable,
          })}
          components={{
            Placeholder,
            Input,
            Option: BasicOption,
            ...(isCopyable
              ? {
                  SingleValue: CopyableSingleValue,
                }
              : {}),
            ...renderComp,
          }}
          {...props}
          onChange={onChange}
          value={value}
          styles={customStyles}
          formatCreateLabel={props.formatCreateLabel}
        />
      </>
    </div>
  );
}

const optionProp = oneOfType([
  arrayOf(
    shape({
      value: string,
      label: string,
    })
  ),
  shape({
    value: string,
    label: string,
  }),
]);

SimpleSelect.defaultProps = {
  onChange: () => {},
  renderComp: {},
  shouldHighlight: false,
  isSelectable: false,
  shouldHighlightOption: () => false,
};

SimpleSelect.propTypes = {
  name: string,
  label: string,
  isDisabled: bool,
  isSelectable: bool,
  options: optionProp,
  defaultValue: optionProp,
  placeholder: string,
  onChange: func,
  loadOptions: func,
  isSearchable: bool,
  required: bool,
  isCreatable: bool,
  disablePlaceholder: bool,
  value: shape({
    value: oneOfType([string, number]),
    label: string,
  }),
  /* react-select components prop overwrite */
  renderComp: object,
  inheritStyles: bool,
  shouldHighlight: bool,
  shouldHighlightOption: func,
  formatCreateLabel: func,
};

const dependencies = [
  'isDisabled',
  'options',
  'shouldHighlight',
  'required',
  'value',
  'name',
];

function shouldNotRender(prevProps, nextProps) {
  for (let i = 0; i < dependencies.length; i++) {
    if (get(prevProps, dependencies[i]) !== get(nextProps, dependencies[i])) {
      return false;
    }
  }

  return true;
}

export const FastSimpleSelect = React.memo(SimpleSelect, shouldNotRender);
