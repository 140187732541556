import {
  GetTeamResponse,
  UpdateTeamRequest,
} from '@cbinsights/adminservice/adminservice';
import { useMutation, useQueryClient } from 'react-query';
import { updateTeam } from '../services/api/UpdateTeam';
import { FEATURE_IDS } from '../constants/features';

export const useUpdateTeam = (refetchTeam: () => void) => {
  const queryClient = useQueryClient();

  const updateTeamQuery = useMutation({
    mutationFn: updateTeam,
    onMutate: async (newTeamInformation: UpdateTeamRequest) => {
      const params = { id_team: newTeamInformation.id_team };

      await queryClient.cancelQueries({ queryKey: ['GetTeam', params] });

      const prevState = queryClient.getQueryData(['GetTeam', params]);

      queryClient.setQueryData(
        ['GetTeam', params],
        (prevGetTeam: GetTeamResponse) => {
          const result = { ...prevGetTeam };

          // updateTeam uses field mask (optional fields)

          if (typeof newTeamInformation.domain_list === 'string') {
            result.signup_code.domain_whitelist =
              newTeamInformation.domain_list?.split(',');
          }

          if (newTeamInformation.team_name) {
            result.team.name = newTeamInformation.team_name;
          }

          if (typeof newTeamInformation.lbs_enabled === 'boolean') {
            result.signup_code.enabled = newTeamInformation.lbs_enabled;
          }

          if (typeof newTeamInformation.mfa_enabled === 'boolean') {
            result.mfa_status.mfa_enabled = newTeamInformation.mfa_enabled;
          }

          if (typeof newTeamInformation.downloads === 'number') {
            result.team_usages = result.team_usages.map((usage) => {
              if (usage.feature.id === FEATURE_IDS.downloads) {
                return { ...usage, total: newTeamInformation.downloads };
              }
              return usage;
            });
          }

          return result;
        }
      );

      return { prevState };
    },
    onError: () => {
      refetchTeam();
    },
  });

  const onSubmit = (data: Partial<UpdateTeamRequest>, teamId: number) => {
    const updateMask = {
      paths: Object.keys(data),
    };

    return updateTeamQuery.mutateAsync({
      ...data,
      update_mask: updateMask,
      id_team: teamId,
    });
  };

  return {
    onSubmit,
    updateTeamQuery,
  };
};
