import { TeamMemberInfo } from '@cbinsights/adminservice/adminservice';
import { UseQueryResult, useQuery } from 'react-query';
import { GetSingleTeamMembersKeys } from '.';
import { getTeamMembers } from '../api/GetTeamMembers';

type Params = Parameters<typeof getTeamMembers>[0];
type Return = TeamMemberInfo;

export const useGetTeamMembersQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetSingleTeamMembersKeys, Params]>(
    ['GetSingleTeamMembers', params],
    () => getTeamMembers(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
