/* eslint-disable max-len */
export const INITIALZE_DATA_DETAILS =
  'client/modules/data-platform/actions/INITIALZE_DATA_DETAILS';
export const UPDATE_SORT_ORDER =
  'client/modules/data-platform/data/UPDATE_SORT_ORDER';
export const UPDATE_PAGE_NUMBER =
  'client/modules/data-platform/data/UPDATE_PAGE_NUMBER';
export const UPDATE_RECORDS_PER_PAGE =
  'client/modules/data-platform/data/UPDATE_RECORDS_PER_PAGE';
export const INITIALIZE_SUGGESTION_DATA_DETAILS =
  'client/modules/data-platform/actions/INITIALIZE_SUGGESTION_DATA_DETAILS';
export const UPDATE_SUGGESTION_RECORDS_PER_PAGE =
  'client/modules/data-platform/actions/UPDATE_SUGGESTION_RECORDS_PER_PAGE';
export const UPDATE_SUGGESTION_PAGE_NUMBER =
  'client/modules/data-platform/actions/UPDATE_SUGGESTION_PAGE_NUMBER';
export const INITIALIZE_NEW_VERTEXES_DATA_DETAILS =
  'client/modules/data-platform/actions/INITIALIZE_NEW_VERTEXES_DATA_DETAILS';
export const UPDATE_NEW_VERTEXES_RECORDS_PER_PAGE =
  'client/modules/data-platform/actions/UPDATE_NEW_VERTEXES_RECORDS_PER_PAGE';
export const UPDATE_NEW_VERTEXES_PAGE_NUMBER =
  'client/modules/data-platform/actions/UPDATE_NEW_VERTEXES_PAGE_NUMBER';
/* eslint-enable max-len */

export function updateSortOrder(sortField, sortDirection) {
  return {
    type: UPDATE_SORT_ORDER,
    sortField,
    sortDirection,
  };
}

export function updatePageNumber(pageNumber) {
  return {
    type: UPDATE_PAGE_NUMBER,
    pageNumber,
  };
}

export function updateRecordsPerPage(recordsPerPage) {
  return {
    type: UPDATE_RECORDS_PER_PAGE,
    recordsPerPage,
  };
}

export function initializeDataDetailsFromUrlParams(urlParams) {
  return {
    type: INITIALZE_DATA_DETAILS,
    ...urlParams,
  };
}

export function updateSuggestionPageNumber(pageNumber) {
  return {
    type: UPDATE_SUGGESTION_PAGE_NUMBER,
    pageNumber,
  };
}

export function updateSuggestionRecordsPerPage(recordsPerPage) {
  return {
    type: UPDATE_SUGGESTION_RECORDS_PER_PAGE,
    recordsPerPage,
  };
}

export function initializeSuggestionDataDetailsFromUrlParams(urlParams) {
  return {
    type: INITIALIZE_SUGGESTION_DATA_DETAILS,
    ...urlParams,
  };
}

export function updateNewVertexesPageNumber(pageNumber) {
  return {
    type: UPDATE_NEW_VERTEXES_PAGE_NUMBER,
    pageNumber,
  };
}

export function updateNewVertexesRecordsPerPage(recordsPerPage) {
  return {
    type: UPDATE_NEW_VERTEXES_RECORDS_PER_PAGE,
    recordsPerPage,
  };
}

export function initializeNewVertexesDataDetailsFromUrlParams(urlParams) {
  return {
    type: INITIALIZE_NEW_VERTEXES_DATA_DETAILS,
    ...urlParams,
  };
}
