import {
  UpdateDefaultLogoRequest,
  UpdateDefaultLogoResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const updateDefaultLogo = (
  req: Partial<UpdateDefaultLogoRequest>
): Promise<UpdateDefaultLogoResponse> => {
  return request({
    url: '/service/logoservice/UpdateDefaultLogo',
    body: { ...req },
  }).then((r): UpdateDefaultLogoResponse => r.body);
};
