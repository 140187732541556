import React, { useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { CreateNewTeam } from '../components/CreateNewTeam';
import { TeamSearch } from '../components/TeamSearch';
import AddNewUsers from '../components/TeamPage/AddNewUsers';
import RecentSearches from '../components/RecentSearches';

export const TeamsPage = () => {
  const [isOpenNewUser, setIsOpenNewUser] = useState(false);

  return (
    <>
      <Row>
        <Col
          xl={{ span: 8, push: 8 }}
          md={{ span: 10, push: 7 }}
          xs={{ span: 20, push: 1 }}
          className="mt-20"
        >
          <Typography.Title level={1} className="text-center">
            User/Team Lookup
          </Typography.Title>
          <TeamSearch />
        </Col>
      </Row>
      <Row justify="center" gutter={[20, 20]}>
        <Col>
          <Button className="mt-5" onClick={() => setIsOpenNewUser(true)}>
            Create New User
          </Button>
        </Col>
        <Col>
          <CreateNewTeam />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 20, push: 2 }}>
          <RecentSearches />
        </Col>
      </Row>
      <AddNewUsers
        onClose={() => setIsOpenNewUser(false)}
        open={isOpenNewUser}
        idTeam={null}
      />
    </>
  );
};
