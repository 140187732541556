import * as actions from '../actions/modal';

const initialState = {
  title: '',
  content: '',
  open: false,
};

/** @deprecated */
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL: {
      return {
        ...state,
        title: action.title,
        content: action.content,
        open: true,
      };
    }
    case actions.CLOSE_MODAL: {
      return {
        ...state,
        title: '',
        content: '',
        open: false,
      };
    }
    default:
      return state;
  }
}
