import { keyBy, omit } from 'lodash';
import * as tabName from 'client/modules/admin-user-admin/utils/tab-names';
import {
  updateSelectionStateOfAllRecords,
  saveRecordsOrder,
} from 'client/modules/markets/redux/utils/records-helper';
import {
  getKeyValueFromListViaOtherKey,
  updateObjectInListWithKeyValue,
} from 'client/modules/common/utils/objectHelper';
import * as actions from '../actions/tab-data';
import {
  addEmptyRecord,
  filteringRecords,
  mapAdminUsersResponse,
  mapAdminPageResponse,
  mapAdminUsersRoleResponse,
  updateNewRecordWithInfo,
  updateSearchInfo,
  updateSelectedSearchTerm,
  updateSearchSuggestions,
} from '../utils/records-helper';

const initialState = {
  [tabName.CREATE_DELETE_USERS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    allRecordsSelected: false,
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
      isTpaUser: false,
    },
  },
  [tabName.ADMIN_USERS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    allRecordsSelected: false,
    newRecords: {},
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
  },
  [tabName.ADMIN_PAGES]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
    allRecordsSelected: false,
    newRecords: {},
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_ADMIN_USER_RECORDS: {
      const records = mapAdminUsersResponse(action.adminInfo);
      return {
        ...state,
        [tabName.CREATE_DELETE_USERS]: {
          ...state[tabName.CREATE_DELETE_USERS],
          originalRecords: keyBy(records, 'id'),
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
        },
      };
    }
    case actions.SET_ADMIN_PAGES_RECORDS: {
      const records = mapAdminPageResponse(
        action.pagePermissions,
        action.allAdminPages
      );
      return {
        ...state,
        [tabName.ADMIN_USERS]: {
          ...state[tabName.ADMIN_USERS],
          originalRecords: keyBy(records, 'id'),
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
        },
      };
    }
    case actions.SET_ADMINS_WITH_ACCESS_TO_PAGE: {
      const records = mapAdminUsersRoleResponse(
        action.userRoleInfo,
        action.allAdminUsers
      );
      return {
        ...state,
        [tabName.ADMIN_PAGES]: {
          ...state[tabName.ADMIN_PAGES],
          originalRecords: keyBy(records, 'id'),
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
        },
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSearchInfo(
            state[action.currentTab].searchBox,
            action.searchId,
            action.searchTerm,
            action.isTpaUser
          ),
        },
      };
    }
    case actions.UPDATE_SUGGESTION: {
      let searchId = getKeyValueFromListViaOtherKey(
        action.suggestions,
        'name',
        action.searchTerm,
        'id'
      );
      searchId = parseInt(searchId, 10) || 0;
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSearchSuggestions(
            state[action.currentTab].searchBox,
            action.suggestions,
            searchId
          ),
        },
      };
    }
    case actions.UPDATE_SELECTED_SEARCH_TERM: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSelectedSearchTerm(
            state[action.currentTab].searchBox
          ),
        },
      };
    }
    case actions.ADD_NEW_PERMISSION_RECORD_LINE: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: addEmptyRecord(
            state[action.currentTab].newRecords,
            action.currentTab,
            state[action.currentTab].searchBox
          ),
        },
      };
    }
    case actions.UPDATE_NEW_PERMISSION_RECORD:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: updateObjectInListWithKeyValue({
            objects: state[action.currentTab].newRecords,
            id: action.newRecordId,
            key: action.fieldName,
            value: action.newValue,
          }),
        },
      };
    case actions.CLEAR_NEW_PERMISSION_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: {},
        },
      };
    case actions.DELETE_NEW_PERMISSION_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: omit(state[action.currentTab].newRecords, action.id),
        },
      };
    case actions.UPDATE_NEW_RECORD_WITH_USER_INFO: {
      return {
        ...state,
        [tabName.ADMIN_PAGES]: {
          ...state[tabName.ADMIN_PAGES],
          newRecords: updateNewRecordWithInfo(
            state[tabName.ADMIN_PAGES].newRecords,
            action.recordId,
            {
              email: action.email,
              idUser: action.idUser,
              name: action.userName,
            }
          ),
        },
      };
    }
    case actions.UPDATE_NEW_RECORD_WITH_PAGE_INFO: {
      return {
        ...state,
        [tabName.ADMIN_USERS]: {
          ...state[tabName.ADMIN_USERS],
          newRecords: updateNewRecordWithInfo(
            state[tabName.ADMIN_USERS].newRecords,
            action.recordId,
            {
              idPage: action.idPage,
              page: action.pageName,
              isTpaPage: action.isTpaPage,
            }
          ),
        },
      };
    }
    case actions.FILTERING_RECORDS: {
      const filteredRecords = filteringRecords(
        state[action.currentTab].originalRecords,
        action.filter
      );
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          allRecordsSelected: false,
          records: keyBy(filteredRecords, 'id'),
          recordsOrder: saveRecordsOrder(filteredRecords),
          selectedRecords: updateSelectionStateOfAllRecords(
            filteredRecords,
            false
          ),
        },
      };
    }
    default:
      return state;
  }
}
