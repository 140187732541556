import { keyBy, forEach, map } from 'lodash';
import { formatDate } from 'client/modules/common/utils/timeFormattingMethods';
import labelToProps from './component-props-order';

const queueLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/SQS_Queue.png';
const queueLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/SQS_Queue_Red.png';
const processorLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/Processor.png';
const processorLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/Processor_Red.png';
const topicLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/SNS_Topic.png';
const topicLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/SNS_Topic_Red.png';
const pipelineLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/Pipeline.png';
const pipelineLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/Pipeline_Red.png';
const bucketLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/S3_Bucket.png';
const bucketLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/S3_Bucket_Red.png';
const mysqlTableLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Table.png';
const mysqlTableLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Table_Red.png';
const mysqlDatabaseLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Database.png';
const mysqlDatabaseLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Database_Red.png';
const mysqlInstanceLogo =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Instance.png';
const mysqlInstanceLogoRed =
  '/admin-frontend-service-images/data-platform-graph-icons/Mysql_Instance_Red.png';

const metadataFields = ['create_date', 'last_updated_date'].sort();

// TODO depricate this for use with getVertexPropsRecordOrder
export const extractOrder = (records) => {
  const recordOrder = Object.keys(records)
    .filter((item) => metadataFields.indexOf(item) < 0)
    .sort()
    .concat(metadataFields);
  return recordOrder;
};

export const getPropValue = (properties, referenceLabel) => {
  let i = 0;
  while (i < Object.keys(properties).length) {
    if (properties[i].label === referenceLabel) {
      return properties[i].value;
    }
    i++;
  }
  return '';
};

const formatDateProperties = (property) => {
  const prop = property;
  if (prop.label.toLowerCase().includes('date')) {
    prop.value = formatDate(prop.value, 'M/D/YYYY HH:mm:ss');
  }
  return prop;
};

// Make sure that every link (edge in gremlin parlance) has nodes attached to both ends
const validateEdge = (edge, nodeMap) => {
  if (nodeMap.has(edge.inV) && nodeMap.has(edge.outV)) {
    return true;
  }
  return false;
};

export const formatLinks = (links, nodeMap) => {
  const formattedLinks = [];
  const linkMap = new Map();

  forEach(links, (e) => {
    const linkMapId = `${e.inV}:${e.outV}`;
    const isValid = validateEdge(e, nodeMap);
    if (!linkMap.has(linkMapId) && isValid) {
      let outVDisplayLabel = nodeMap.get(e.outV).id;
      if (
        !!nodeMap.get(e.outV).displayLabel &&
        !!nodeMap.get(e.outV).displayLabel.length
      ) {
        outVDisplayLabel = nodeMap.get(e.outV).displayLabel;
      }
      let inVDisplayLabel = nodeMap.get(e.inV).id;
      if (
        !!nodeMap.get(e.inV).displayLabel &&
        !!nodeMap.get(e.inV).displayLabel.length
      ) {
        inVDisplayLabel = nodeMap.get(e.inV).displayLabel;
      }
      const displayLabel = `${inVDisplayLabel} -> ${outVDisplayLabel}`;
      const link = {
        source: e.inV,
        target: e.outV,
        id: e.id,
        properties: e.properties,
        linkMapId,
        displayLabel,
      };
      formattedLinks.push(link);
      linkMap.set(linkMapId, link);
    }
  });
  return { formattedLinks, linkMap };
};

const getVertexSvg = (vertexHealth, healthyImage, unhealthyImage) => {
  return vertexHealth !== 'RED' || !unhealthyImage.length
    ? healthyImage
    : unhealthyImage;
};

export const formatNodes = (vertexes, idVertexCenter) => {
  const defaultSize = 400;
  const formattedVertexes = [];
  let records = {};
  const nodeMap = new Map();
  let centralVertexHealth = '';
  forEach(vertexes, (v) => {
    const vertex = {
      ...v,
      renderLabel: true,
    };
    switch (vertex.label) {
      case 'processor': {
        vertex.svg = getVertexSvg(
          vertex.health,
          processorLogo,
          processorLogoRed
        );
        const display = v.id.replace('-processor', '');
        vertex.displayLabel = display;
        // This image needs to be scaled down for uniformity
        vertex.size = 300;
        break;
      }
      case 'queue': {
        vertex.svg = getVertexSvg(vertex.health, queueLogo, queueLogoRed);
        vertex.displayLabel = getPropValue(vertex.properties, 'queue_name');
        vertex.size = defaultSize;
        break;
      }
      case 'topic': {
        vertex.svg = getVertexSvg(vertex.health, topicLogo, topicLogoRed);
        vertex.displayLabel = getPropValue(vertex.properties, 'topic_name');
        vertex.size = defaultSize;
        break;
      }
      case 'pipeline': {
        vertex.svg = getVertexSvg(vertex.health, pipelineLogo, pipelineLogoRed);
        vertex.displayLabel = getPropValue(vertex.properties, 'pipeline_name');
        vertex.size = defaultSize;
        break;
      }
      case 's3_bucket': {
        vertex.svg = getVertexSvg(vertex.health, bucketLogo, bucketLogoRed);
        vertex.displayLabel = getPropValue(vertex.properties, 'bucket_name');
        vertex.size = defaultSize;
        break;
      }
      case 'mysql_database_table': {
        vertex.svg = getVertexSvg(
          vertex.health,
          mysqlTableLogo,
          mysqlTableLogoRed
        );
        const db = getPropValue(vertex.properties, 'database');
        const tableName = getPropValue(vertex.properties, 'table_name');
        vertex.displayLabel = `${tableName}`;
        if (db) {
          vertex.displayLabel = `${db}.${tableName}`;
        }
        vertex.size = defaultSize;
        break;
      }
      case 'mysql_database': {
        vertex.svg = getVertexSvg(
          vertex.health,
          mysqlDatabaseLogo,
          mysqlDatabaseLogoRed
        );
        vertex.displayLabel = getPropValue(vertex.properties, 'database_name');
        vertex.size = defaultSize;
        break;
      }
      case 'mysql_instance': {
        vertex.svg = getVertexSvg(
          vertex.health,
          mysqlInstanceLogo,
          mysqlInstanceLogoRed
        );
        vertex.displayLabel = getPropValue(vertex.properties, 'hostname');
        vertex.size = defaultSize;
        break;
      }

      default: {
        vertex.size = defaultSize;
        break;
      }
    }
    if (vertex.id === idVertexCenter) {
      vertex.size *= 2;
      const props = map(vertex.properties, formatDateProperties);
      records = keyBy(props, 'label');
      centralVertexHealth = v.health;
    }
    formattedVertexes.push(vertex);
    nodeMap.set(v.id, vertex);
  });
  return {
    nodes: formattedVertexes,
    centralProps: records,
    nodeMap,
    centralVertexHealth,
  };
};

const formatPipeline = (pipeline) => ({
  ...pipeline,
  lastUpdatedTs: formatDate(pipeline.lastUpdatedTs, 'M/D/YYYY HH:mm:ss'),
  createdBy: pipeline.createdBy,
  lastUpdatedBy: pipeline.lastUpdatedBy,
});

export const formatPipelines = (pipelines) => {
  const formattedDates = map(pipelines, formatPipeline);
  const pipelineRecords = keyBy(formattedDates, 'pipelineName');
  const pipelineRecordOrder = map(
    pipelines,
    (pipeline) => pipeline.pipelineName
  );
  return { records: pipelineRecords, order: pipelineRecordOrder };
};

export const getVertexPropsRecordOrder = (label) => {
  let propsOrder = labelToProps[label];
  if (propsOrder.length === 0) {
    propsOrder = [];
  }
  return propsOrder;
};

export const fetchAdditionalComponentCandidate = (
  newVertexId,
  newVertexLabel,
  existingCandidates
) => {
  // Check for a valid new component id and label
  if ((!newVertexId || !newVertexLabel) && existingCandidates) {
    return existingCandidates;
  } if (!newVertexId || !newVertexLabel) {
    return [];
  }

  const newVertex = {
    id: newVertexId,
    label: newVertexLabel,
  };
  if (existingCandidates) {
    return [...existingCandidates, newVertex];
  }
  return [newVertex];
};

export const removeMatchingElementByObjectID = (
  elementToRemoveID,
  theArray
) => {
  const newArray = [];
  let i = 0;
  for (i = 0; i < Object.keys(theArray).length; i++) {
    if (theArray[i].id !== elementToRemoveID) {
      newArray.push(theArray[i]);
    }
  }
  return newArray;
};
