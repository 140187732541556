import React from 'react';

/**
 * @deprecated
 * It takes a message and a boolean value, and if the boolean value is true, it returns the message as
 * HTML, otherwise it returns the message as a string
 * @param message - The message to be displayed.
 * @param isMessageHtml - boolean
 * @returns A React component.
 */

export const formatMessage = (message, isMessageHtml) => {
  if (isMessageHtml) {
    return (
      <span id="message-id">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </span>
    );
  }
  return <span id="message-id">{message}</span>;
};

export default formatMessage;
