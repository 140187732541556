import React from 'react';
import { EntityOption } from 'client/component-library/Select/options/EntityOption';
import { shallowEqual, useSelector } from 'react-redux';

function InvestorOption(props) {
  const { investorCategoryMapping } = useSelector(
    ({
      cbiEntity: {
        generalInfo: { allInvestorCategoryValues },
      },
    }) => {
      return {
        investorCategoryMapping: allInvestorCategoryValues,
      };
    },
    shallowEqual
  );

  return (
    <EntityOption
      {...props}
      investorCategoryMapping={investorCategoryMapping}
    />
  );
}

export default InvestorOption;
