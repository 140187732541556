export const MarketReportsRoutes = {
  New: 'new',
  MarketReports: 'market-reports',
  EditMarket: 'edit-market',
  MarketCompany: 'market-company',
  MarketCompanies: 'market-companies',
  IsPendingReview: 'isPendingReview',
  MarketId: 'id',
  MarketActiveTab: 'activeTab',
} as const;

export const EditMarketTab = {
  MarketInfo: 'marketInfo',
  Companies: 'companies',
} as const;
