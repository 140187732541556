import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Modal, message } from 'antd';
import ResetPassword from 'client/modules/users/components/ResetPassword';
import ResetWelcomeEmail from 'client/modules/users/components/ResetWelcomeEmail';
import { useUpdateUserTeam } from '../../hooks/useUpdateUserTeam';
import RemoveUserModal from './RemoveUserModal';

type Props = {
  userId: number;
  isAdmin: boolean;
  teamId: number;
  email: string;
  refetchTeamMembers: () => void;
};

const ActionsDropDown = ({
  userId,
  isAdmin,
  teamId,
  email,
  refetchTeamMembers,
}: Props) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetWelcomeEmail, setShowResetWelcomeEmail] = useState(false);
  const { onSubmit } = useUpdateUserTeam();

  const AssignHandler = (enableAdmin: boolean) => {
    onSubmit(userId, {
      admin_priv: enableAdmin,
    })
      .then(() => {
        refetchTeamMembers();
        message.success("User's role has been updated");
      })
      .catch(() => {
        message.error("Unable to update user's role. Please try again later.");
      });
  };

  const items: MenuProps['items'] = [
    {
      key: '2',
      label: 'Remove User',
      onClick: () => {
        setShowRemoveModal(true);
      },
    },
    {
      key: '3',
      label: 'Reset Password',
      onClick: () => {
        setShowResetPassword(true);
      },
    },
    {
      key: '4',
      label: 'Resend Welcome Email',
      onClick: () => {
        setShowResetWelcomeEmail(true);
      },
    },
  ];

  if (isAdmin) {
    items.unshift({
      key: '1',
      label: 'Unassign as Admin',
      onClick: () => {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'This user will no longer have admin privileges',
          onOk: () => AssignHandler(false),
        });
      },
    });
  } else {
    items.unshift({
      key: '1',
      label: 'Assign as Admin',
      onClick: () => AssignHandler(true),
    });
  }

  return (
    <>
      <Dropdown menu={{ items }}>
        <MoreOutlined />
      </Dropdown>
      <RemoveUserModal
        email={email}
        isOpen={showRemoveModal}
        closeModal={() => {
          setShowRemoveModal(false);
        }}
        userId={userId}
        teamId={teamId}
        refetchTeamMembers={refetchTeamMembers}
      />
      <ResetPassword
        isOpen={showResetPassword}
        handleClose={() => setShowResetPassword(false)}
        email={email}
      />
      <ResetWelcomeEmail
        isOpen={showResetWelcomeEmail}
        handleClose={() => setShowResetWelcomeEmail(false)}
        idUser={userId}
      />
    </>
  );
};

export default ActionsDropDown;
