import { UseQueryResult, useQuery } from 'react-query';
import { GetUserPackageHistoryResponse } from '@cbinsights/adminservice/adminservice';
import { GetUserPackageHistory } from '.';
import { getUserPackageHistory } from '../api/GetUserPackageHistory';

type Params = Parameters<typeof getUserPackageHistory>[0];
type Return = GetUserPackageHistoryResponse;

export const useGetUserPackageHistory = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUserPackageHistory, Params]>(
    ['GetUserPackageHistory', params],
    () => getUserPackageHistory(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
