import DROPDOWN_LIMIT from 'client/modules/common/components/AutocompleteDropdown/utils/constants';
import { ltrim } from 'client/modules/common/utils/truncateString';
import { entitySearchRequest } from '../utils/api-request-helper';
import { getGeneralCbiEntityInfo } from './general-info';
import { entityType } from '../utils/constants';

/* eslint-disable max-len */
export const UPDATE_CURRENT_SEARCH_INFO =
  'client/modules/cbi-entity/redux/actions/search-bar/UPDATE_CURRENT_SEARCH_INFO';
export const SET_SELECTED_SEARCH_INFO =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_SELECTED_SEARCH_INFO';
export const SET_MARKET_SUGGESTIONS =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_MARKET_SUGGESTIONS';
export const SET_MARKET_SIZING_DETAILS =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_MARKET_SIZING_DETAILS';
export const SET_RELATED_MARKETS_DETAILS =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_RELATED_MARKETS_DETAILS';
export const SET_LIST_OF_MARKETS_DETAILS =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_LIST_OF_MARKETS_DETAILS';
export const INITIALZE_SEARCH_TERM =
  'client/modules/cbi-entity/redux/actions/search-bar/INITIALZE_SEARCH_TERM';
export const UPDATE_SEARCH_SUGGESTIONS =
  'client/modules/cbi-entity/redux/actions/search-bar/UPDATE_SEARCH_SUGGESTIONS';
export const SET_CBI_ENTITY_GENERAL_INFO =
  'client/modules/cbi-entity/redux/actions/search-bar/SET_CBI_ENTITY_GENERAL_INFO';
export const FETCH_NEW_ENTITY_LIMIT = 50;
/* eslint-enable max-len */

export const updateCurrentSearchInfo = (searchTerm, searchId) => {
  return { type: UPDATE_CURRENT_SEARCH_INFO, searchTerm, searchId };
};

export const setSelectedSearchInfo = (searchTerm, searchType, searchId) => {
  return { type: SET_SELECTED_SEARCH_INFO, searchTerm, searchType, searchId };
};

export const initializeSearchTerm = (searchTerm, searchTermId, searchType) => {
  return { type: INITIALZE_SEARCH_TERM, searchTerm, searchTermId, searchType };
};

export const updateMarketSuggestionsHelper = (searchTerm, markets) => {
  return { type: SET_MARKET_SUGGESTIONS, searchTerm, markets };
};

export const initializeDataDetailsFromUrlParams = (
  urlParams,
  queryParams = {}
) => {
  return (dispatch) => {
    const duplicateFromId = queryParams['duplicate-from'];

    if (!parseInt(urlParams.id, 10) && !duplicateFromId) {
      return dispatch(getGeneralCbiEntityInfo()); // initialize with a new, empty entity
    }

    const infoRequest = {
      id: parseInt(urlParams.id, 10) || parseInt(duplicateFromId, 10), // urlParams.id,
      idType: 3, // 3 is idCbiEntity
    };
    return dispatch(
      getGeneralCbiEntityInfo(infoRequest, true, true, !!duplicateFromId)
    );
  };
};

export function updateSuggestionsHelper(searchTerm, entitySearchResults) {
  return {
    type: UPDATE_SEARCH_SUGGESTIONS,
    searchTerm,
    entitySearchResults,
  };
}

export function updateSuggestions(
  searchTerm,
  type = entityType.ENTITY,
  limit = DROPDOWN_LIMIT,
  searchVcFunds = false
) {
  return (dispatch) => {
    const formattedTerm = ltrim(searchTerm);
    if (!formattedTerm) {
      return null;
    }
    return Promise.all([
      dispatch(entitySearchRequest(formattedTerm, type, limit, searchVcFunds)),
    ]).then((values) => {
      const {entitySearchResults} = values[0];
      dispatch(updateSuggestionsHelper(formattedTerm, entitySearchResults));
    });
  };
}
