import {
  setFailureNotification,
  setSuccessNotification,
} from 'client/modules/common/redux/actions/status-notification';
import { getCurrentTab } from '../utils/get-current-tab';

export const INITIALIZE_SEARCH_INFO =
  'client/modules/university-display/tab-data/INITIALIZE_SEARCH_INFO';
export const UPDATE_SEARCH_INFO =
  'client/modules/university-display/tab-data/UPDATE_SEARCH_INFO';
export const SELECT_SEARCH_INFO =
  'client/modules/university-display/tab-data/SELECT_SEARCH_INFO';
export const SET_SELECTED_DOMAIN_INFO =
  'client/modules/university-display/tab-data/SET_SELECTED_DOMAIN_INFO';
export const SET_SELECTED_IP_RANGE_INFO =
  'client/modules/university-display/tab-data/SET_SELECTED_IP_RANGE_INFO';

export const initializeSearchInfo = (allUniversityInfo) => {
  return { type: INITIALIZE_SEARCH_INFO, allUniversityInfo };
};

export const setSelectedUniversityDomainInfo = (domainInfo) => {
  return { type: SET_SELECTED_DOMAIN_INFO, domainInfo };
};

export const setSelectedUniversityIpRangeInfo = (ipRangeInfo) => {
  return { type: SET_SELECTED_IP_RANGE_INFO, ipRangeInfo };
};

export const updateSearchInfo = (searchId, searchTerm) => {
  return { type: UPDATE_SEARCH_INFO, searchId, searchTerm };
};

export const fetchAllUniversityInfo = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllUniversityInfo',
    name: 'adminservice',
    body: {},
  },
});

export const getSelectedUniversityDomainInfo = (idUniversity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetUniversityDomainInfo',
    name: 'adminservice',
    body: { idUniversity },
  },
});

export const getSelectedUniversityIpRangeInfo = (idUniversity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetUniversityIpRangeInfo',
    name: 'adminservice',
    body: { idUniversity },
  },
});

export const validateUniversityEmail = (universitySlug, email) => ({
  types: [null, null],
  service: {
    endpoint: 'ValidateUniversityEmail',
    name: 'adminservice',
    body: { universitySlug, email },
  },
});

export const validateUniversityIpAddress = (universitySlug, ipAddress) => ({
  types: [null, null],
  service: {
    endpoint: 'ValidateUniversityIpRange',
    name: 'adminservice',
    body: { universitySlug, ipAddress },
  },
});

export const getAndSetAllUniveristyInfo = () => {
  return (dispatch) => {
    dispatch(fetchAllUniversityInfo()).then((response) => {
      if (response && response.allUniversityInfo) {
        return dispatch(initializeSearchInfo(response.allUniversityInfo));
      }
      return null;
    });
  };
};

export const onSelectOrEnterSearch = (searchId, searchTerm) => {
  return getCurrentTab((currentTab) => {
    return {
      type: SELECT_SEARCH_INFO,
      currentTab,
      searchId,
      searchTerm,
    };
  });
};

export const fetchSelectedUniversityDomains = () => {
  return (dispatch, getState) => {
    const {idUniversity} = getState().universityDisplay.tabData.selectedUniversity;
    dispatch(getSelectedUniversityDomainInfo(idUniversity)).then((response) => {
      dispatch(setSelectedUniversityDomainInfo(response.universityDomainInfo));
    });
    return null;
  };
};

export const fetchSelectedUniversityIpRanges = () => {
  return (dispatch, getState) => {
    const {idUniversity} = getState().universityDisplay.tabData.selectedUniversity;
    dispatch(getSelectedUniversityIpRangeInfo(idUniversity)).then(
      (response) => {
        dispatch(setSelectedUniversityIpRangeInfo(response.ipRangeInfo));
      }
    );
    return null;
  };
};

export const validateEmail = (email) => {
  return (dispatch, getState) => {
    const {selectedUniversity} = getState().universityDisplay.tabData;
    const {universitySlug} = selectedUniversity;
    const {universityName} = selectedUniversity;
    return dispatch(validateUniversityEmail(universitySlug, email)).then(
      (response) => {
        if (response && response.success) {
          dispatch(
            setSuccessNotification(
              `${email} is a valid ${universityName} email!`
            )
          );
        } else {
          dispatch(
            setFailureNotification(
              `${email} is NOT a valid ${universityName} email!`
            )
          );
        }
      }
    );
  };
};

export const validateIpAddress = (ipAddress) => {
  return (dispatch, getState) => {
    const {selectedUniversity} = getState().universityDisplay.tabData;
    const {universitySlug} = selectedUniversity;
    const {universityName} = selectedUniversity;
    return dispatch(
      validateUniversityIpAddress(universitySlug, ipAddress)
    ).then((response) => {
      if (response && response.success) {
        dispatch(
          setSuccessNotification(
            `${ipAddress} is a valid ${universityName} IP address!`
          )
        );
      } else {
        dispatch(
          setFailureNotification(
            `${ipAddress} is NOT a valid ${universityName} IP address!`
          )
        );
      }
    });
  };
};
