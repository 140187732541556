/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import PropTypes from 'prop-types';
import SlideOverHeader from './SlideOverHeader';

/**
 * @deprecated
 * It renders a full screen div with a semi-transparent background, and a slide-over panel with a
 * header and children
 * @returns A React component
 */
export default function SlideOver({
  headerText = '',
  children,
  handleClose,
  dataTest = 'slide-over',
  closeOnClickAway = false,
}) {
  const handleClickAway = (event) => {
    if (closeOnClickAway && event.currentTarget === event.target) {
      handleClose();
    }
  };

  return (
    <div
      className="fixed inset-0 overflow-hidden"
      data-test={dataTest}
      style={{ zIndex: 1101 }} // Our Global Header is is 1100
    >
      <div
        data-test={`${dataTest}-inner`}
        className="absolute inset-0 bg-gray-500 bg-opacity-50 overflow-hidden"
        onClick={handleClickAway}
      >
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <div className="w-screen max-w-2xl">
            <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <SlideOverHeader
                handleClose={handleClose}
                headerText={headerText}
              />
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

SlideOver.propTypes = {
  headerText: PropTypes.string,
  dataTest: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  closeOnClickAway: PropTypes.bool,
};
