import React from 'react';
import PropTypes from 'prop-types';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import { Modal } from 'client/component-library/modals/Modal';

/**
 * @deprecated
 * It renders a modal with a title, body, and actions
 * @param props - {
 * @returns A function that returns a component
 */
export function ConfirmationModal(props) {
  const {
    isOpen,
    title,
    body,
    onCancel,
    onSubmit,
    cancelActText,
    submitActText,
    width,
    shouldRenderActions = true,
  } = props;
  return (
    <Modal isOpen={isOpen} handleClose={onCancel} closeOnClickAway>
      <div
        data-test="confirmation-modal"
        className="overflow-hidden shadow rounded-lg divide-y divide-gray-200 text-lg p-3"
        style={{ width }}
      >
        {title && (
          <div
            data-test="confirmation-modal-title"
            className="pb-3 text-xl text-black font-bold"
          >
            {title}
          </div>
        )}
        {body && (
          <div
            data-test="confirmation-modal-body"
            className="text-base overflow-auto whitespace-pre-line"
            style={{ maxHeight: '90vh' }}
          >
            {body}
          </div>
        )}
        {shouldRenderActions && (
          <div className="flex pt-2 border-t my-2">
            {submitActText && (
              <div className="mr-3">
                <Button
                  onClick={onSubmit}
                  theme="danger"
                  data-test={`modal-${submitActText}-button`}
                >
                  {submitActText}
                </Button>
              </div>
            )}
            {cancelActText && (
              <div>
                <Button
                  onClick={onCancel}
                  data-test={`modal-${cancelActText}-button`}
                >
                  {cancelActText}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  cancelActText: PropTypes.string,
  submitActText: PropTypes.string,
  width: PropTypes.number,
  shouldRenderActions: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  width: 500,
  title: '',
  body: '',
  onCancel: () => {},
  onSubmit: () => {},
  cancelActText: 'Cancel',
  submitActText: 'Submit',
};
