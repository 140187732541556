import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import * as tabNames from 'client/modules/markets/utils/tab-names';
import * as actions from '../actions/search-bar';

const searchTypesToNames = {
  1: 'Market',
  // 3: 'Company',
  4: 'User',
  5: 'Analyst',
};

const searchTypes = [
  { id: 1, text: 'Market' },
  { id: 4, text: 'User' },
  { id: 5, text: 'Analyst' },
];

const defaultState = {
  searchSuggestions: [],
  marketSuggestions: [],
  companySuggestions: [],
  adminUserSuggestions: [],
  analystSuggestions: [],
  currentSearchTerm: '',
  currentSearchType: 1, // Market
  currentSearchId: 0,
  selectedSearchTerm: '',
  selectedSearchType: 0,
  selectedSearchId: 0,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case actions.INITIALZE_SEARCH_TERM: {
      return {
        ...state,
        currentSearchId:
          parseInt(action.searchTermId, 10) || defaultState.currentSearchId,
        currentSearchTerm: action.searchTerm,
        currentSearchType:
          parseInt(action.searchType, 10) || defaultState.currentSearchType,
        selectedSearchId:
          parseInt(action.searchTermId, 10) || defaultState.selectedSearchId,
        selectedSearchTerm: action.searchTerm,
        selectedSearchType:
          parseInt(action.searchType, 10) || defaultState.selectedSearchType,
      };
    }
    case actions.SET_MARKET_SUGGESTIONS: {
      const searchId = getKeyValueFromListViaOtherKey(
        action.markets,
        'name',
        action.searchTerm,
        'id'
      );
      return {
        ...state,
        currentSearchId: parseInt(searchId, 10) || 0,
        marketSuggestions: action.markets,
        searchSuggestions: action.markets,
      };
    }
    case actions.SET_COMPANY_SUGGESTIONS: {
      return {
        ...state,
        companySuggestions: action.suggestions,
        searchSuggestions: action.suggestions,
      };
    }
    case actions.SET_ADMIN_USER_SUGGESTIONS: {
      return {
        ...state,
        adminUserSuggestions: action.suggestions,
        searchSuggestions: action.suggestions,
      };
    }
    case actions.SET_ANALYST_SUGGESTIONS: {
      return {
        ...state,
        analystSuggestions: action.suggestions,
        searchSuggestions: action.suggestions,
      };
    }
    case actions.UPDATE_CURRENT_SEARCH_INFO: {
      return {
        ...state,
        currentSearchTerm: action.searchTerm,
        currentSearchType: action.searchType,
        currentSearchId: parseInt(action.searchId, 10) || 0,
        currentSearchName: searchTypesToNames[action.searchType],
      };
    }
    case actions.SET_SELECTED_SEARCH_INFO: {
      return {
        ...state,
        currentSearchTerm: action.searchTerm,
        currentSearchType: action.searchType,
        currentSearchId: parseInt(action.searchId, 10) || 0,
        currentSearchName: searchTypesToNames[action.searchType],
        selectedSearchTerm: action.searchTerm,
        selectedSearchType: action.searchType,
        selectedSearchId: parseInt(action.searchId, 10) || 0,
        selectedSearchName: searchTypesToNames[action.searchType],
      };
    }
    case actions.SET_SEARCH_TYPES: {
      if (
        action.currentTab === tabNames.MARKET_SIZING_DETAILS ||
        state.currentSearchType === 1
      ) {
        return {
          ...state,
          searchTypes,
        };
      }
      return {
        ...state,
        searchTypes: [searchTypes[0]],
        currentSearchTerm: '',
        currentSearchType: 1,
        currentSearchId: 0,
        currentSearchName: searchTypesToNames[1],
        selectedSearchTerm: '',
        selectedSearchType: 1,
        selectedSearchId: 0,
        selectedSearchName: searchTypesToNames[1],
      };
    }
    default:
      return state;
  }
}
