import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

/**
 * @deprecated
 * It returns a div with a className that is a combination of the size prop and the className prop
 */
const Loading = ({ size, className }) => (
  <div className={`${styles[`loading--${size}`]} ${className}`} />
);
Loading.propTypes = {
  size: PropTypes.oneOf(['s', 'm']),
  className: PropTypes.string,
};

Loading.defaultProps = {
  size: 'm',
  className: '',
};

export default Loading;
