import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTeamsByStory,
  getStoriesByTeam,
  getStoriesByName,
} from 'client/modules/stories/redux/actions/tab-data';
import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import HeaderCell from './HeaderCell';

class HeaderCellWrapper extends Component {
  filterAction = () => {
    const { currentTab } = this.props;
    switch (currentTab) {
      case UPDATE_ACCESS_BY_STORY:
        return this.props.getTeamsByStory;
      case UPDATE_ACCESS_BY_TEAM:
        return this.props.getStoriesByTeam;
      case MANAGE_SLUGS:
        return this.props.getStoriesByName;
      default:
        return null;
    }
  };

  render() {
    return <HeaderCell {...this.props} filterAction={this.filterAction()} />;
  }
}

function mapStateToProps({ stories }) {
  return {
    currentTab: stories.currentTab,
  };
}

HeaderCellWrapper.propTypes = {
  currentTab: PropTypes.string.isRequired,
  getTeamsByStory: PropTypes.func.isRequired,
  getStoriesByTeam: PropTypes.func.isRequired,
  getStoriesByName: PropTypes.func.isRequired,
};

HeaderCellWrapper.defaultProps = {};

export default connect(mapStateToProps, {
  getTeamsByStory,
  getStoriesByTeam,
  getStoriesByName,
})(HeaderCellWrapper);
