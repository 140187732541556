import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import styles from '../styles/css/cell-styles.css';

/** @deprecated If the cellSubtext prop is an object, don't render anything. Otherwise, render the cellSubtext prop. */
class TableCellSubtext extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { cellSubtext } = this.props;
    if (typeof cellSubtext === 'object') {
      return null;
    }
    return (
      <div className={styles.cellSubtextWrapper}>
        <span className={styles.cellSubtext}>{cellSubtext}</span>
      </div>
    );
  }
}

TableCellSubtext.propTypes = {
  cellSubtext: PropTypes.string,
};

TableCellSubtext.defaultProps = {
  cellSubtext: '',
};

export default TableCellSubtext;
