export const marketSuggestions = (searchTerm, offset, limit) => ({
  types: [null, null],
  service: {
    endpoint: 'suggestMarkets',
    name: 'marketservice',
    body: {
      searchTerm: searchTerm ? searchTerm.trim() : '',
      offset: offset || 0,
      limit: limit || 10,
    },
  },
});

export const deleteRelatedMarketsRequest = (id) => ({
  types: [null, null],
  service: {
    endpoint: 'DeleteRelatedMarkets',
    name: 'marketservice',
    body: {
      id,
    },
  },
});

export const getDuplicateMarketSizingsRequest = ({
  id,
  market,
  startSize,
  startYear,
  endSize,
  endYear,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'GetDuplicateMarketSizings',
    name: 'marketservice',
    body: {
      id,
      market,
      startSize,
      startYear,
      endSize,
      endYear,
    },
  },
});

export default marketSuggestions;
