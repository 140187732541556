export const getAllPackagesRequest = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllPackages',
    name: 'adminservice',
    body: {},
  },
});
export const getAllFeaturesRequest = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllFeatures',
    name: 'adminservice',
    body: {},
  },
});

export const getFeatureInfoForPackageRequest = (id) => ({
  types: [null, null],
  service: {
    endpoint: 'GetFeatureInfoForPackage',
    name: 'adminservice',
    body: { id },
  },
});

export const createNewPackageRequest = ({
  id,
  name,
  packageType,
  annual,
  duration,
  featureInfo,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateNewPackage',
    name: 'adminservice',
    body: {
      id,
      name,
      packageType,
      annual: annual === 'yes',
      duration,
      featureInfo,
    },
  },
});

export const getPackageInfoForFeatureRequest = (id) => ({
  types: [null, null],
  service: {
    endpoint: 'GetPackageInfoForFeature',
    name: 'adminservice',
    body: { id },
  },
});

export const updatePackageRequest = ({
  id,
  name,
  packageType,
  annual,
  duration,
  featureInfo,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePackage',
    name: 'adminservice',
    body: {
      id,
      name,
      packageType,
      annual,
      duration,
      featureInfo,
    },
  },
});

export const getFeatureDescriptionsRequest = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetFeatureDescriptions',
    name: 'adminservice',
    body: {},
  },
});

export default getAllPackagesRequest;
