import {
  Industry,
  ListIndustriesResponse,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import { UseQueryResult, useQuery } from 'react-query';
import { listIndustries } from '../api/MarketsEndpoints';
import { MarketForAdminServiceKeys } from './useGetMarketDetails';

const fetchIndustries = async (): Promise<
  ListIndustriesResponse['industries']
> => {
  const results = await listIndustries();
  return results.industries;
};

export const useListIndustries = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<Industry[], Error> =>
  useQuery<
    ListIndustriesResponse,
    Error,
    Industry[],
    [MarketForAdminServiceKeys]
  >({
    queryKey: ['ListIndustries'],
    queryFn: async () => {
      const industries = await fetchIndustries();
      return { industries };
    },
    notifyOnChangeProps: [
      'data',
      'isFetching',
      'isFetched',
      'isFetchedAfterMount',
    ],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
    select(data) {
      const response = data.industries.reduce((acc: Industry[], el) => {
        return [
          ...acc,
          { ...el, id: Number(el.id), id_parent: Number(el.id_parent) },
          ...el.sub_industries.map((sub) => ({
            ...sub,
            id: Number(sub.id),
            id_parent: Number(sub.id_parent),
          })),
        ];
      }, []);
      return response;
    },
  });
