/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { map, forEach } from 'lodash';
import PropTypes from 'prop-types';
import { TableCell as Cell } from 'client/component-library/Table';
import TableCellError from './TableCellError';
import MapTableCellToComponent from './MapTableCellToComponent';
import TableCellSubtext from './TableCellSubtext';

const getCellSubtext = (cellSubtexts, rowId, keyName) => {
  if (
    cellSubtexts[rowId] &&
    typeof cellSubtexts[rowId][keyName] !== 'undefined'
  ) {
    return cellSubtexts[rowId][keyName];
  }
  return null;
};

const createCommonProps = (props, curr, row) => {
  const commonProps = {
    autocompleteOptions: props.autocompleteOptions,
    checked: props.checked,
    contentWidth: curr.contentWidth,
    defaultValue: curr.defaultValue,
    disabled: props.disabled || curr.cellDisabled,
    height: curr.height,
    id: `${props.id}-${curr.key}`,
    linkText: curr.linkText,
    name: curr.key,
    news: row.news,
    numberOfRows: curr.numberOfRows,
    options: curr.options,
    placeholder: props.placeholder,
    resize: curr.resize,
    rowId: props.id,
    showInputAdornment: curr.showInputAdornment,
    status: row.status,
    textAreaWidth: curr.textAreaWidth,
    type: curr.type,
    value: row[curr.key],
    width: props.width,
    isNew: row.isNew,
    checkValue: curr.checkValue,
    maxLength: curr.maxLength,
  };

  if (props.onChange) {
    commonProps.onChange = props.onChange;
  }

  return commonProps;
};

const setAdditionalProps = (propNames, propValues) => {
  const results = {};
  forEach(propNames, (name) => {
    if (name in propValues) {
      results[name] = propValues[name];
    }
  });
  return results;
};

/** @deprecated It renders a table cell */
class TableCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      generateCustomTableComponents,
      cellSubtexts,
      cellStyle,
      id,
      includes,
      row,
      showAllArticles,
      width,
      additionalPropNames,
      isFlexDisplay,
      excludeError,
    } = this.props;

    // Need to duplicate as React.Fragment can not take additional props
    if (isFlexDisplay) {
      return (
        <React.Fragment>
          {map(includes, (curr) => {
            const cellSubtext = getCellSubtext(cellSubtexts, row.id, curr.key);

            const commonProps = createCommonProps(
              this.props,
              curr,
              row,
              showAllArticles
            );
            const additionalProps = setAdditionalProps(additionalPropNames, {
              ...row,
              showAllArticles,
            });
            return (
              <div key={curr.key} style={{ width }}>
                <MapTableCellToComponent
                  {...commonProps}
                  {...additionalProps}
                />
                {!excludeError && (
                  <TableCellError
                    id={id}
                    key={`error-${curr.key}`}
                    field={curr.key}
                  />
                )}
                {cellSubtext !== null && (
                  <TableCellSubtext cellSubtext={cellSubtext} />
                )}
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <Cell width={width} cellStyle={cellStyle}>
        {map(includes, (curr) => {
          const cellSubtext = getCellSubtext(cellSubtexts, row.id, curr.key);

          const commonProps = createCommonProps(
            this.props,
            curr,
            row,
            showAllArticles
          );
          const additionalProps = setAdditionalProps(additionalPropNames, {
            ...row,
            showAllArticles,
          });
          return (
            <div key={curr.key} style={{ width }}>
              <MapTableCellToComponent
                {...commonProps}
                {...additionalProps}
                generateCustomTableComponents={generateCustomTableComponents}
              />
              <TableCellError
                id={id}
                key={`error-${curr.key}`}
                field={curr.key}
              />
              {cellSubtext !== null && (
                <TableCellSubtext cellSubtext={cellSubtext} />
              )}
            </div>
          );
        })}
      </Cell>
    );
  }
}

TableCell.propTypes = {
  cellStyle: PropTypes.object,
  cellSubtexts: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  includes: PropTypes.arrayOf(PropTypes.object),
  additionalPropNames: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.object,
  showAllArticles: PropTypes.bool,
  isFlexDisplay: PropTypes.bool,
  excludeError: PropTypes.bool,
  width: PropTypes.string,
};

TableCell.defaultProps = {
  cellStyle: {},
  cellSubtexts: {},
  includes: [],
  additionalPropNames: [],
  row: {},
  showAllArticles: false,
  isFlexDisplay: false,
  excludeError: false,
  width: '100px',
};

export default TableCell;
