import { combineReducers } from 'redux';
import searchBar from './search-bar';
import tab from './tab';
import tabDetails from './tab-details';
import tabData from './tab-data';
import generalInfo from './general-info';
import fundingRound from './funding-round';

export interface CbiEntity {
  generalInfo: $TSFixMe;
  searchBar: $TSFixMe;
  tab: $TSFixMe;
  tabData: $TSFixMe;
  tabDetails: $TSFixMe;
  fundingRound: $TSFixMe;
}

const cbiEntity = combineReducers({
  generalInfo,
  searchBar,
  tab,
  tabData,
  tabDetails,
  fundingRound,
});

export default cbiEntity;
