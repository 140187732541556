import { filter, get, map, omit } from 'lodash';
import * as tabs from 'client/modules/cbi-entity/utils/tab-names';
import { getMultiKeyValueFromListViaMulitpleKeyStringMatchCustomRegex } from 'client/modules/common/utils/objectHelper';
import { stripURL } from 'client/modules/common/utils/formatUrl';
import moment from 'moment';
import { newBoardOfDirectorsPerson } from './records-helper';

const formatEntityNameInfo = (entityNameInfo = {}) => {
  const { primaryName, companyName, investorName, aliases } = entityNameInfo;
  const editedAliases = map(
    filter(aliases, (a) => {
      return a.edited;
    }),
    (alias) => {
      const formattedAlias = { ...alias };
      if (Math.isNaN(formattedAlias.idAlias)) {
        formattedAlias.idAlias = 0;
      }
      return omit(formattedAlias, 'edited');
    }
  );
  return {
    primaryName,
    companyName: companyName || primaryName,
    investorName: investorName || primaryName,
    aliases: editedAliases,
  };
};

const formatUrlInfo = (urlInfo = {}) => {
  const primaryUrl = stripURL(urlInfo.primaryUrl);
  const nextAdditionalUrls = map(
    filter(urlInfo.additionalUrls, (u) => {
      return u.edited;
    }),
    (url) => {
      const formattedUrl = { ...url };
      if (Math.isNaN(formattedUrl.idUrl)) {
        formattedUrl.idUrl = 0;
      }
      formattedUrl.url = stripURL(formattedUrl.url);
      return omit(formattedUrl, 'edited');
    }
  );
  return { primaryUrl, additionalUrls: nextAdditionalUrls };
};

const formatStatusInfo = (status = {}) => ({
  idStatus: status.idStatus,
});

const formatAddress = (address) => ({
  idAddress: parseInt(address.idAddress, 10) || 0,
  addressName: address.addressName,
  hqFlag: address.hqFlag,
  street1: address.street1,
  street2: address.street2,
  cityInfo: address.cityInfo,
  stateInfo: address.stateInfo,
  zip: address.zip,
  countryInfo: address.countryInfo,
  idContinent: address.countryInfo.idContinent,
  phone: address.phone,
});

export const formatAddresses = (addresses) => {
  if (addresses.length < 1) {
    return null;
  }
  const addressesToSave = filter(addresses, (address) => {
    return address.edited;
  });
  return map(addressesToSave, (address) => {
    return formatAddress(address);
  });
};

export const emptyAddressObject = () => {
  return {
    idAddress: 0,
    addressName: '',
    hqFlag: 0,
    street1: '',
    street2: '',
    cityInfo: {
      name: '',
      idCity: 0,
      idState: 0,
      idCountry: 0,
      zip: '',
    },
    stateInfo: {
      idState: 0,
      idCountry: 0,
      name: '',
    },
    zip: '',
    countryInfo: {
      idCountry: 0,
      name: '',
      idContinent: 0,
    },
    phone: '',
    idGeoRegionNew: null,
    idType: null,
    edited: false,
  };
};

export const emptyHqAddressObject = (idAddress) => {
  return {
    ...emptyAddressObject(),
    idAddress,
    id: idAddress,
    addressName: 'Headquarters',
    hqFlag: 1,
  };
};

export const emptyFundingObject = (tabData) => {
  const firstRecordId = get(tabData, 'recordsOrder[0]', 0);
  const firstRecordBods = get(
    tabData,
    `records[${firstRecordId}].boardOfDirectors`,
    []
  );

  return {
    amount: '',
    boardOfDirector: '',
    boardOfDirectors: firstRecordBods.map((member) =>
      newBoardOfDirectorsPerson(member.idInvestorPerson, member.name)
    ),
    fundingDate: moment().format('MM-DD-YYYY'),
    fundingRound: {
      idRound: 0,
      idFunding: 0,
    },
    id: 0,
    investor: '',
    investors: [],
    maxValuation: '',
    mention: '',
    mentions: [],
    minValuation: '',
  };
};

export const emptySalesMetricObject = () => {
  return {
    id: 0,
    maxSales: '',
    minSales: '',
    date: '',
    sources: [],
  };
};

export const emptyVcFundObject = (id) => {
  return {
    id,
    idFund: null,
    fundName: '',
    amountRaised: 0,
    amountTarget: 0,
    startDate: '',
    closeDate: '',
    ciks: [],
    limitedPartners: [],
    sources: [],
    isNew: true,
    isDeleted: false,
    edited: false,
  };
};

export const createRequestBody = (body, createCompany, createInvestor) => {
  const {
    entityNameInfo,
    description,
    urlInfo,
    email,
    status,
    ciks,
    founded,
    taxonomy,
    addresses,
    idCbiEntity,
  } = body;
  const requestBody = {
    entityNameInfo: formatEntityNameInfo(entityNameInfo),
    description,
    urlInfo: formatUrlInfo(urlInfo),
    email,
    status: formatStatusInfo(status),
    ciks,
    foundedYear: parseInt(founded, 10) || 0,
    taxonomy,
    addresses: formatAddresses(addresses),
    idCbiEntity,
    createCompany,
    createInvestor,
  };
  return requestBody;
};

export const updateRequestBody = (body) => {
  const {
    entityNameInfo,
    description,
    urlInfo,
    email,
    status,
    ciks,
    founded,
    taxonomy,
    addresses,
    idCbiEntity,
    idCompany,
    idInvestor,
    idParentEntities,
    idChildEntities,
    idPrimaryCbiEntityType,
  } = body;
  const requestBody = {
    entityNameInfo: formatEntityNameInfo(entityNameInfo),
    description,
    urlInfo: formatUrlInfo(urlInfo),
    email,
    status: formatStatusInfo(status),
    ciks,
    foundedYear: parseInt(founded, 10) || 0,
    taxonomy,
    addresses: formatAddresses(addresses),
    idCbiEntity,
    idCompany,
    idInvestor,
    idParentEntities,
    idChildEntities,
    idPrimaryCbiEntityType,
  };

  return requestBody;
};

export const addRecordSchema = (tab, { id, tabData } = {}) => {
  switch (tab) {
    case tabs.GENERAL_INFO:
      return emptyAddressObject();
    case tabs.FUNDINGS:
      return emptyFundingObject(tabData);
    case tabs.SALES_METRICS:
      return emptySalesMetricObject();
    case tabs.VC_FUNDS:
      return emptyVcFundObject(id);
    default:
      return false;
  }
};

export const parseInvestorPersonSearchResults = (suggestions, term) => {
  // searchTerm and regex can change based on the format of the original term
  let searchTerm = term;
  let regex = new RegExp(searchTerm, 'g');
  // if the term has a `,` we assume it is of the format `lname, fname`
  if (term.indexOf(',') !== -1) {
    const [lname, fname] = term.split(',');
    searchTerm = `${fname.trim()} ${lname}`;
    regex = new RegExp(`(${fname.trim()}.*\\b)(${lname})`, 'g');
  }
  return getMultiKeyValueFromListViaMulitpleKeyStringMatchCustomRegex(
    suggestions,
    'name',
    ['idInvestorPerson', 'name', 'investor'],
    regex
  );
};
