import React from 'react';
import { Col, Divider, List, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import { DeleteOutlined } from '@ant-design/icons';
import SearchUtils, { SearchEntry } from '../utils/search';

const renderItem = (
  item: SearchEntry,
  removeSearchEntry: (id: number, type: SearchEntry['type']) => void
) => {
  const removeItem = () => {
    removeSearchEntry(item.id, item.type);
  };

  const handleSearchClick = () => {
    SearchUtils.addSearchEntry(item);
  };

  if (item.type === 'user') {
    return (
      <List.Item className="flex justify-between items-center">
        <Link to={`/users/${item.id}`} onClick={handleSearchClick}>
          <div className="flex justify-between items-center">
            <span className="flex justify-between items-center mr-2">
              <BaseIcon iconName="person" isIconBlock={false} />
              <span className="ml-2">{item.text}</span>
            </span>
            <span>id: {item.id}</span>
          </div>
        </Link>
        <Tooltip title="Delete search entry">
          <DeleteOutlined onClick={removeItem} />
        </Tooltip>
      </List.Item>
    );
  }

  if (item.type === 'team') {
    return (
      <List.Item className="flex justify-between items-center">
        <Link to={`/teams/${item.id}`} onClick={handleSearchClick}>
          <div className="flex justify-between items-center">
            <span className="flex justify-between items-center mr-2">
              <BaseIcon iconName="groups" isIconBlock={false} />
              <span className="ml-2">{item.text}</span>
            </span>
            <span>id: {item.id}</span>
          </div>
        </Link>
        <Tooltip title="Delete search entry">
          <DeleteOutlined onClick={removeItem} />
        </Tooltip>
      </List.Item>
    );
  }

  return <List.Item>{item.text}</List.Item>;
};

const RecentSearches = () => {
  const [recentSearches, setRecentSearches] = React.useState([]);

  React.useEffect(() => {
    setRecentSearches(SearchUtils.getRecentSearches());
  }, []);

  if (!recentSearches?.length) {
    return null;
  }

  const removeSearchEntry = (id: number, type: SearchEntry['type']) => {
    SearchUtils.removeSearchEntry(id, type);
    setRecentSearches(SearchUtils.getRecentSearches());
  };

  return (
    <Row>
      <Col
        xl={{ span: 8, push: 8 }}
        md={{ span: 12, push: 6 }}
        xs={{ span: 22, push: 1 }}
      >
        <Divider orientation="left">Recent Searches</Divider>
        <List
          bordered
          dataSource={recentSearches}
          renderItem={(item) => renderItem(item, removeSearchEntry)}
        />
      </Col>
    </Row>
  );
};

export default RecentSearches;
