/* eslint-disable prefer-destructuring */
/* eslint-disable no-else-return */
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import { fetchAllSuspiciousSessions } from '../utils/api-request-helper';

export const RESET_ALL_SESSION_DATA =
  'client/modules/account-sharing-details/actions/RESET_ALL_SESSION_DATA';
export const UPDATE_PAGE_NUMBER =
  'client/modules/account-sharing-details/actions/UPDATE_PAGE_NUMBER';
export const UPDATE_RECORDS_PER_PAGE =
  'client/modules/account-sharing-details/actions/UPDATE_RECORDS_PER_PAGE';

export const resetAllSessionData = () => {
  return { type: RESET_ALL_SESSION_DATA };
};

export const updatePageNumber = (pageNumber) => {
  updateUrlParams('pageNumber', pageNumber);
  return { type: UPDATE_PAGE_NUMBER, pageNumber };
};

export const updateRecordsPerPage = (recordsPerPage) => {
  updateUrlParams('recordsPerPage', recordsPerPage);
  return { type: UPDATE_RECORDS_PER_PAGE, recordsPerPage };
};

export function updateDashboardSessionData(
  searchId,
  searchTerm,
  searchType,
  startDate,
  endDate
) {
  return (dispatch, getState) => {
    const searchBar = getState().accountSharingDetection.searchBar;
    const allSessionData = getState().accountSharingDetection.allSessionData;

    const realSearchId = searchId || searchBar.searchId;
    const realSearchTerm = searchTerm || searchBar.searchTerm;
    const realSearchType = searchType || searchBar.currentSearchType;
    const realStartDate = startDate || searchBar.startDate;
    const realEndDate = endDate || searchBar.endDate;

    updateUrlParams('searchId', realSearchId);
    updateUrlParams('searchTerm', realSearchTerm);
    updateUrlParams('searchType', realSearchType);
    updateUrlParams('startDate', realStartDate);
    updateUrlParams('endDate', realEndDate);

    const limit = allSessionData.recordsPerPage;
    const offset = allSessionData.pageNumber * limit;

    if (realSearchType === 1) {
      return dispatch(
        fetchAllSuspiciousSessions({
          idUser: parseInt(realSearchId, 10) || 0,
          startDate: realStartDate,
          endDate: realEndDate,
          limit,
          offset,
        })
      );
    } else if (realSearchType === 2) {
      return dispatch(
        fetchAllSuspiciousSessions({
          idTeam: parseInt(realSearchId, 10) || 0,
          startDate: realStartDate,
          endDate: realEndDate,
          limit,
          offset,
        })
      );
    }
    return dispatch(
      fetchAllSuspiciousSessions({
        csm: realSearchTerm || '',
        startDate: realStartDate,
        endDate: realEndDate,
        limit,
        offset,
      })
    );
  };
}
