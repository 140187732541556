// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-Table-styles-css-___table-header__successNotification___BDgZJ {
  background-color: green !important;
}

.client-modules-common-components-Table-styles-css-___table-header__active___fYMmB svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["black"]};
}

.client-modules-common-components-Table-styles-css-___table-header__inactive___S8h3n svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"]};
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/Table/styles/css/table-header.css"],"names":[],"mappings":"AAIA;EACE,kCAAkC;AACpC;;AAEA;EACE,mDAAY;AACd;;AAEA;EACE,mDAAW;AACb","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value black from colors;\n@value grey from colors;\n\n.successNotification {\n  background-color: green !important;\n}\n\n.active svg {\n  color: black;\n}\n\n.inactive svg {\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"black": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["black"]}`,
	"grey": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"]}`,
	"successNotification": `client-modules-common-components-Table-styles-css-___table-header__successNotification___BDgZJ`,
	"active": `client-modules-common-components-Table-styles-css-___table-header__active___fYMmB`,
	"inactive": `client-modules-common-components-Table-styles-css-___table-header__inactive___S8h3n`
};
export default ___CSS_LOADER_EXPORT___;
