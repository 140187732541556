import { reduce, isEmpty } from 'lodash';
import {
  CREATE_DELETE_USERS,
  ADMIN_USERS,
  ADMIN_PAGES,
} from 'client/modules/admin-user-admin/utils/tab-names';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import {
  setFailureNotification,
  setSuccessNotification,
} from 'client/modules/common/redux/actions/status-notification';
import {
  requestAllAdminUsers,
  requestCreateAdminUser,
  requestDeactivateAdminUser,
  requestGetPagesUserCanAccess,
  requestGetUsersWithAccessToPage,
  requestUpdateAdminUserPagePermissions,
  requestUpdatePermissionsForPage,
} from '../utils/api-request-helper';
import { getCurrentTab, getPageId, getUserId } from '../utils/records-helper';

export const ADD_NEW_PERMISSION_RECORD_LINE =
  'client/modules/admin-user-admin/actions/tab-data/ADD_NEW_PERMISSION_RECORD_LINE';
export const FILTERING_RECORDS =
  'client/modules/admin-user-admin/actions/tab-data/FILTERING_RECORDS';
export const CLEAR_NEW_PERMISSION_RECORDS =
  'client/modules/admin-user-admin/actions/tab-data/CLEAR_NEW_PERMISSION_RECORDS';
export const DELETE_NEW_PERMISSION_RECORDS =
  'client/modules/admin-user-admin/actions/tab-data/DELETE_NEW_PERMISSION_RECORDS';
export const REVOKE_PAGE_PERMISSION =
  'client/modules/admin-user-admin/actions/tab-data/REVOKE_PAGE_PERMISSION';
export const SET_ADMIN_PAGES_RECORDS =
  'client/modules/admin-user-admin/actions/tab-data/SET_ADMIN_PAGES_RECORDS';
export const SET_ADMIN_USER_RECORDS =
  'client/modules/admin-user-admin/actions/tab-data/SET_ADMIN_USERS_RECORDS';
export const SET_ADMINS_WITH_ACCESS_TO_PAGE =
  'client/modules/admin-user-admin/actions/tab-data/SET_ADMINS_WITH_ACCESS_TO_PAGE';
export const UPDATE_NEW_PERMISSION_RECORD =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_NEW_PERMISSION_RECORD';
export const UPDATE_NEW_RECORD_WITH_USER_INFO =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_NEW_RECORD_WITH_USER_INFO';
export const UPDATE_NEW_RECORD_WITH_PAGE_INFO =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_NEW_RECORD_WITH_PAGE_INFO';
export const UPDATE_SEARCH_INFO =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_SEARCH_INFO';
export const UPDATE_SELECTED_SEARCH_TERM =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_SELECTED_SEARCH_TERM';
export const UPDATE_SUGGESTION =
  'client/modules/admin-user-admin/actions/tab-data/UPDATE_SUGGESTION';

export const setAdminUserRecordsHelper = (adminInfo) => {
  return {
    type: SET_ADMIN_USER_RECORDS,
    adminInfo,
  };
};

export const setAdminUserRecords = () => {
  return (dispatch) => {
    return dispatch(requestAllAdminUsers()).then((data) => {
      dispatch(setAdminUserRecordsHelper(data.userInfo));
    });
  };
};

/**
 * Filter actions
 */
export const filteringRecords = (filter) => {
  return getCurrentTab((currentTab) => {
    return {
      type: FILTERING_RECORDS,
      filter,
      currentTab,
    };
  });
};

/**
 * Searching actions
 */

export const updateSearchInfoHelper = (
  currentTab,
  searchId,
  searchTerm,
  isTpaUser
) => {
  return {
    type: UPDATE_SEARCH_INFO,
    currentTab,
    searchId,
    searchTerm,
    isTpaUser,
  };
};

export const updateSearchInfo = (searchId, searchTerm) => {
  return (dispatch, getState) => {
    const adminUsersState = getState().adminUsers;
    const {currentTab} = adminUsersState;
    const adminUsers = adminUsersState.userInfo;
    let isTpaUser = false;
    if (searchId in adminUsers) {
      isTpaUser = adminUsers[searchId].isTpaUser;
    }
    return dispatch(
      updateSearchInfoHelper(currentTab, searchId, searchTerm, isTpaUser)
    );
  };
};

export const setAdminPagesRecords = (pagePermissions, allAdminPages) => {
  return {
    type: SET_ADMIN_PAGES_RECORDS,
    pagePermissions,
    allAdminPages,
  };
};

export const setAdminsWithAccessToPage = (userRoleInfo, allAdminUsers) => {
  return {
    type: SET_ADMINS_WITH_ACCESS_TO_PAGE,
    userRoleInfo,
    allAdminUsers,
  };
};

export const searchAdminPagesRecords = (userId, allAdminPages) => {
  return (dispatch) => {
    dispatch(requestGetPagesUserCanAccess(userId)).then(
      (data) => {
        dispatch(setAdminPagesRecords(data.pagePermissions, allAdminPages));
      },
      () => {
        dispatch(
          setFailureNotification(
            'Failed to get the list of pages user can access, please try again.'
          )
        );
      }
    );
  };
};

export const searchAdminsWithAccessToPage = (pageId, allAdminUsers) => {
  return (dispatch) => {
    dispatch(requestGetUsersWithAccessToPage(pageId)).then(
      (data) => {
        dispatch(setAdminsWithAccessToPage(data.userRoleInfo, allAdminUsers));
      },
      () => {
        dispatch(
          setFailureNotification(
            'Failed to get list of users have permission to the page, please try again.'
          )
        );
      }
    );
  };
};

export const updateSelectedSearchTerm = () => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SELECTED_SEARCH_TERM,
      currentTab,
    };
  });
};

export const onSelectOrEnterSearch = (searchId) => {
  return (dispatch, getState) => {
    const {adminUsers} = getState();
    const allAdminPages = adminUsers.pageInfo;
    const allAdminUsers = adminUsers.userInfo;
    const {currentTab} = adminUsers;
    const currentTabData = adminUsers.tabData[currentTab];

    dispatch(updateSelectedSearchTerm());
    switch (currentTab) {
      case CREATE_DELETE_USERS: {
        const email = getKeyValueFromListViaOtherKey(
          currentTabData.searchBox.suggestions,
          'id',
          searchId,
          'email'
        );
        return dispatch(
          requestCreateAdminUser({ idUser: searchId, email })
        ).then(
          () => {
            dispatch(setSuccessNotification('New admin user is added!'));
            dispatch(setAdminUserRecords());
          },
          () => {
            dispatch(
              setFailureNotification(
                'Failed to add new admin user, please try again.'
              )
            );
          }
        );
      }
      case ADMIN_USERS: {
        return dispatch(searchAdminPagesRecords(searchId, allAdminPages));
      }
      case ADMIN_PAGES: {
        return dispatch(searchAdminsWithAccessToPage(searchId, allAdminUsers));
      }
      default:
        return null;
    }
  };
};

/**
 * Permission modals
 */

export const addNewPermissionRecordLine = () => {
  return getCurrentTab((currentTab) => {
    return {
      type: ADD_NEW_PERMISSION_RECORD_LINE,
      currentTab,
    };
  });
};

export function updateNewPermissionRecord(newRecordId, fieldName, newValue) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_NEW_PERMISSION_RECORD,
      newRecordId,
      fieldName,
      newValue,
      currentTab,
    };
  });
}

export function clearNewPermissionRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: CLEAR_NEW_PERMISSION_RECORDS,
      currentTab,
    };
  });
}

export function deleteNewPermissionRecords(id) {
  return getCurrentTab((currentTab) => {
    return {
      type: DELETE_NEW_PERMISSION_RECORDS,
      currentTab,
      id,
    };
  });
}

export const mapAdminUserPagePermissions = (
  records,
  access,
  autoCompleteInputValues
) => {
  return reduce(
    records,
    (results, record, id) => {
      const idPage = getPageId(record, id, autoCompleteInputValues);
      if (idPage !== -1) {
        results.push({
          idPage,
          access,
          isTpaPage: record.isTpaPage,
          role: record.role || '',
          idRole: parseInt(record.idRole, 10) || 0, // convert string to int
        });
      }
      return results;
    },
    []
  );
};

export const mapAdminUserRoleInfo = (records, autoCompleteInputValues) => {
  return reduce(
    records,
    (results, record, id) => {
      const idUser = getUserId(record, id, autoCompleteInputValues);
      if (idUser !== -1) {
        results.push({
          idUser,
          role: record.role || '',
          idRole: parseInt(record.idRole, 10) || 0, // convert string to int
        });
      }
      return results;
    },
    []
  );
};

export const updateNewRecordWithUserInfo = ({
  recordId,
  userId,
  email,
  userName,
}) => {
  return {
    type: UPDATE_NEW_RECORD_WITH_USER_INFO,
    recordId,
    idUser: userId,
    email,
    userName,
  };
};

export const updateNewRecordWithPageInfo = ({
  recordId,
  pageId,
  pageName,
  isTpaPage,
}) => {
  return {
    type: UPDATE_NEW_RECORD_WITH_PAGE_INFO,
    recordId,
    idPage: pageId,
    pageName,
    isTpaPage,
  };
};

export function saveNewPermissionRecords(callback) {
  return (dispatch, getState) => {
    const userAdmin = getState().adminUsers;
    const allAdminPages = userAdmin.pageInfo;
    const allAdminUsers = userAdmin.userInfo;
    const {currentTab} = userAdmin;
    const currentTabData = userAdmin.tabData[currentTab];
    const {searchId} = currentTabData.searchBox;

    const autoCompleteInputValues = getState().table.autocompleteInput
      .inputValues;

    if (currentTab === ADMIN_USERS) {
      const pagePermissions = mapAdminUserPagePermissions(
        currentTabData.newRecords,
        true,
        autoCompleteInputValues
      );
      if (isEmpty(pagePermissions)) {
        return dispatch(
          setFailureNotification(
            'Failed to save new records, please add at least one page.'
          )
        );
      }
      const dataToPost = {
        idUser: searchId,
        pagePermissions,
      };
      return dispatch(requestUpdateAdminUserPagePermissions(dataToPost)).then(
        () => {
          callback();
          dispatch(setSuccessNotification('Records saved successfully!'));
          dispatch(clearNewPermissionRecords());
          return dispatch(searchAdminPagesRecords(searchId, allAdminPages));
        },
        () => {
          return dispatch(
            setFailureNotification(
              'Failed to save new records, please try again.'
            )
          );
        }
      );
    } if (currentTab === ADMIN_PAGES) {
      const userRoleInfo = mapAdminUserRoleInfo(
        currentTabData.newRecords,
        autoCompleteInputValues
      );
      if (isEmpty(userRoleInfo)) {
        return dispatch(
          setFailureNotification(
            'Failed to save new records, please add at least one user.'
          )
        );
      }
      const dataToPost = {
        idPage: searchId,
        userRoleInfo,
      };
      return dispatch(requestUpdatePermissionsForPage(dataToPost)).then(
        () => {
          callback();
          dispatch(setSuccessNotification('Records saved successfully!'));
          dispatch(clearNewPermissionRecords());
          return dispatch(
            searchAdminsWithAccessToPage(searchId, allAdminUsers)
          );
        },
        () => {
          return dispatch(
            setFailureNotification(
              'Failed to save new records, please try again.'
            )
          );
        }
      );
    }
    return null;
  };
}

/**
 * Update autocomplete suggestions
 */

export const updateSuggestionsHelper = (
  currentTab,
  suggestions,
  searchTerm
) => {
  return {
    type: UPDATE_SUGGESTION,
    currentTab,
    suggestions,
    searchTerm,
  };
};

export const updateSuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    if (!searchTerm) {
      return null;
    }
    const userAdmin = getState().adminUsers;
    const {currentTab} = userAdmin;
    switch (currentTab) {
      case CREATE_DELETE_USERS: {
        const filteredEmployees = filterListOfObjects({
          value: searchTerm,
          listOfItems: userAdmin.companyEmployees,
          fields: ['name', 'email'],
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredEmployees, searchTerm)
        );
      }
      case ADMIN_USERS: {
        const filteredAdminUsers = filterListOfObjects({
          value: searchTerm,
          listOfItems: userAdmin.userInfo,
          fields: ['name', 'email'],
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredAdminUsers, searchTerm)
        );
      }
      case ADMIN_PAGES: {
        const filteredAdminPages = filterListOfObjects({
          value: searchTerm,
          listOfItems: userAdmin.pageInfo,
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredAdminPages, searchTerm)
        );
      }
      default:
        return null;
    }
  };
};

/**
 * Revoke page permission
 */

export const revokePagePermission = (id, currentTab) => {
  return {
    type: REVOKE_PAGE_PERMISSION,
    id,
    currentTab,
  };
};

export const revokePermission = (id) => {
  return (dispatch, getState) => {
    if (!id) {
      return null;
    }
    const userAdmin = getState().adminUsers;
    const {currentTab} = userAdmin;
    const allAdminPages = userAdmin.pageInfo;
    const allAdminUsers = userAdmin.userInfo;
    switch (currentTab) {
      case CREATE_DELETE_USERS: {
        // deactivate admin user
        return dispatch(requestDeactivateAdminUser(id)).then(
          (data) => {
            if (data.success) {
              dispatch(setAdminUserRecords());
              return dispatch(
                setSuccessNotification('Successfully revoked user permission!')
              );
            }
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission, please try again.'
              )
            );
          },
          () => {
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission, please try again.'
              )
            );
          }
        );
      }
      case ADMIN_USERS: {
        // revoke page permission
        const idUser = userAdmin.tabData[currentTab].searchBox.searchId;
        const pagePermission = userAdmin.tabData[currentTab].records[id];
        const requestData = {
          idUser,
          pagePermissions: [
            {
              idPage: id,
              access: false,
              role: pagePermission.role,
            },
          ],
        };
        return dispatch(
          requestUpdateAdminUserPagePermissions(requestData)
        ).then(
          (data) => {
            if (data.success) {
              dispatch(searchAdminPagesRecords(idUser, allAdminPages));
              return dispatch(
                setSuccessNotification('Successfully revoked user permission!')
              );
            }
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission, please try again.'
              )
            );
          },
          () => {
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission!, please try again.'
              )
            );
          }
        );
      }
      case ADMIN_PAGES: {
        // revoke user page permission
        const idPage = userAdmin.tabData[currentTab].searchBox.searchId;
        const user = userAdmin.tabData[currentTab].records[id];
        const requestData = {
          idUser: user.id,
          pagePermissions: [
            {
              idPage,
              access: false,
              role: user.role,
            },
          ],
        };
        return dispatch(
          requestUpdateAdminUserPagePermissions(requestData)
        ).then(
          (data) => {
            if (data.success) {
              dispatch(searchAdminsWithAccessToPage(idPage, allAdminUsers));
              return dispatch(
                setSuccessNotification('Successfully revoked user permission!')
              );
            }
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission, please try again.'
              )
            );
          },
          () => {
            return dispatch(
              setFailureNotification(
                'Failed to revoke user permission!, please try again.'
              )
            );
          }
        );
      }
      default:
        return null;
    }
  };
};
