import * as actions from '../actions/article-content';

const initialState = {
  selectedRecordId: '',
  selectedSentence: '',
  content: '',
  url: '',
  title: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_SELECTED_MARKET_ROW_ID:
      return {
        ...state,
        selectedRecordId: action.idRecord,
      };
    case actions.SET_ARTICLE_CONTENT: {
      const {article} = action.payload;
      return {
        ...state,
        selectedSentence: action.payload.sentence,
        content: article.content,
        url: article.link,
        title: article.title,
      };
    }
    default:
      return state;
  }
}
