import React from 'react';
import PropTypes from 'prop-types';
import {
  FailureNotification,
  SuccessNotification,
} from 'client/modules/common/components/Messages/';
import UserPermissionWrapper from 'client/modules/user/components/UserPermissionWrapper';
import styles from 'client/assets/styles/common-styles.css';
import DataPlatformTabs from './DataPlatformTabs';

const DataPlatformWrapper = (props) => {
  const { children } = props;

  // Could not get margin-bottom to work on DataPlatformTabs so I just put the line breaks
  return (
    <UserPermissionWrapper idPage={52}>
      <FailureNotification />
      <SuccessNotification />
      <div className={styles.adminPageHeaderText}>Data Platform</div>
      <DataPlatformTabs />
      <br /> <br />
      {children}
    </UserPermissionWrapper>
  );
};

DataPlatformWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataPlatformWrapper;
