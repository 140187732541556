export const FEATURE_IDS = {
  companyProfiles: 10,
  downloads: 14,
  investorProfiles: 11,
  profiles: 13,
  researchViews: 187,
  SSO: 304,
  vendorConnects: 199,
} as const;

export const NEW_FEATURE_IDS = {
  researchReportDownload: 315,
  chatCBIUsage: 321,
};
