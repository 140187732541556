import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import {
  setToastError,
  setToastSuccess,
} from 'client/modules/third-party-admin/profile-queue/utils';

function finalizeCluster(actionType, idCluster, itemType) {
  return request({
    url: 'service/newsqueueservice/FinalizeNewsQueueCluster',
    body: {
      actionType,
      newsQueueCluster: {
        idCluster,
        itemType,
      },
    },
  })
    .then(({ body: { success, errorMessage } }) => {
      if (!success) {
        throw errorMessage;
      }
      setToastSuccess(`Successfully performed ${actionType} on Cluster`);
      return success;
    })
    .catch((error) => {
      setToastError(`Error when trying to ${actionType} Cluster: ${error}`);
      logInfo({
        error,
        message: 'News Queue: Failed To finalizeCluster Cluster',
        method: 'finalizeCluster',
      });
      throw error;
    });
}

export function archiveCluster({ id, type }) {
  return finalizeCluster('ARCHIVE', id, type);
}

export function discardCluster({ id, type }) {
  return finalizeCluster('DISCARD', id, type);
}

export function blockCikCluster({ id, type }) {
  return finalizeCluster('BLOCK_CIK', id, type);
}
