import React from 'react';
import { forEach, map } from 'lodash';
import createFullName from 'client/modules/common/utils/createFullName';
import { formatUtcDateToLocal } from 'client/modules/common/utils/timeFormattingMethods';
import * as permissionType from 'client/modules/stories/utils/permission-types';
import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
} from 'client/modules/stories/utils/tab-names';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';

const dateFormatting = 'M/D/YYYY h:mm A z';

export const getCurrentTab = (callback) => {
  return (dispatch, getState) => {
    const {currentTab} = getState().stories;
    return dispatch(callback(currentTab));
  };
};

export const getUrlParams = (nextTab) => {
  return (dispatch, getState) => {
    return getState().stories.tabDetails[nextTab].urlParams;
  };
};

export const getSortOrder = (sortField, sortDirection) => {
  return sortField
    ? { sortField, sortDirection: sortDirection || 'asc' }
    : null;
};

export const updateSortOrder = (sortField, sortDirection) => {
  return { sortField, sortDirection };
};

export const customCompareRecords = (sortField) => {
  const comparisonFunc = (a, b) => {
    if (a[sortField] < b[sortField]) {
      return -1;
    } if (a[sortField] > b[sortField]) {
      return 1;
    }
    return 0;
  };
  return comparisonFunc;
};

export const sortInnerRecords = (records, sortField, sortDirection) => {
  const recordsOrder = Object.values(records);

  recordsOrder.sort(customCompareRecords(sortField));

  if (sortDirection === 'asc' && sortField !== permissionType.CONTENT_OWNER) {
    recordsOrder.reverse();
  } else if (
    sortDirection === 'desc' &&
    sortField === permissionType.CONTENT_OWNER
  ) {
    recordsOrder.reverse();
  }

  const recordsOrderId = [];
  forEach(recordsOrder, (record) => {
    recordsOrderId.push(record.id);
  });

  return recordsOrderId;
};

export const updateTeamMemberPermission = (records, recordId, permission) => {
  const updatedRecords = {};
  forEach(records, (record) => {
    if (record.id === parseInt(recordId, 10)) {
      if (record.contentOwner === true) {
        updatedRecords[record.id] = {
          ...record,
          permission: permissionType.VIEW_EDIT,
        };
      } else if (record.originalPermission === permissionType.NO_ACCESS) {
        updatedRecords[record.id] = {
          ...record,
          permission,
        };
      } else if (
        record.originalPermission !== permissionType.NO_ACCESS &&
        permission === permissionType.NO_ACCESS
      ) {
        updatedRecords[record.id] = {
          ...record,
        };
      } else {
        updatedRecords[record.id] = {
          ...record,
          permission,
        };
      }
    } else {
      updatedRecords[record.id] = {
        ...record,
      };
    }
  });
  return updatedRecords;
};

export const updateTeamContentOwner = (records, recordId) => {
  const updatedRecords = {};
  forEach(records, (record) => {
    if (record.id === parseInt(recordId, 10)) {
      updatedRecords[record.id] = {
        ...record,
        contentOwner: true,
        permission: permissionType.VIEW_EDIT,
      };
    } else {
      updatedRecords[record.id] = {
        ...record,
        contentOwner: false,
      };
    }
  });
  return updatedRecords;
};

export const initializeVisibility = (records) => {
  const updatedRecords = {};
  forEach(records, (record) => {
    updatedRecords[record.id] = false;
  });
  return updatedRecords;
};

export const bulkUpdatePermissions = (records, newValue) => {
  let permission = '';
  if (newValue === 'View') {
    permission = permissionType.VIEW;
  } else {
    permission = permissionType.VIEW_EDIT;
  }

  const updatedRecords = {};
  forEach(records, (record) => {
    if (record.permission === permissionType.NO_ACCESS) {
      updatedRecords[record.id] = {
        ...record,
        permission,
      };
    } else if (record.contentOwner === false) {
      updatedRecords[record.id] = {
        ...record,
        permission,
      };
    } else if (
      record.contentOwner === true &&
      permission === permissionType.VIEW_EDIT
    ) {
      updatedRecords[record.id] = {
        ...record,
        permission,
      };
    } else {
      updatedRecords[record.id] = {
        ...record,
      };
    }
  });
  return updatedRecords;
};

export const formatStoriesSuggestions = (response) => {
  const baseUrl = `${window.envVars.APP_DOMAIN}/`;
  const formattedResponse = [];
  forEach(response.stories, (story) => {
    formattedResponse.push({
      id: story.idStory,
      name: story.name,
      url: baseUrl.concat(story.mostRecentSlug),
      createdBy: createFullName(
        story.userInfo.firstName,
        story.userInfo.lastName,
        story.userInfo.email
      ),
      date: story.tsCreated ? formatUtcDateToLocal(story.tsCreated) : 'N/A',
    });
  });
  return formattedResponse;
};

export const formatClientTeams = (response) => {
  const formattedResponse = [];
  forEach(response.teamStory, (team, id) => {
    const {
      idTeam,
      idStory,
      teamName,
      numberOfUsers,
      usersWithAccess,
      sfOwner,
      renewalDate,
      packageName,
      cbiAccountSector,
      customerUseCase,
    } = team;
    formattedResponse.push({
      id,
      idTeam,
      idStory,
      teamName: teamName || idTeam.toString(),
      numUsers: `${usersWithAccess} of ${numberOfUsers}`,
      CSM: sfOwner,
      renewalDate: renewalDate
        ? formatUtcDateToLocal(renewalDate, dateFormatting)
        : 'N/A',
      packageName,
      contentTier: 'Expert',
      BDAccountSector: cbiAccountSector,
      customerUseCase,
    });
  });
  return formattedResponse;
};

export const getPermissionLevel = (accessLevel) => {
  if (accessLevel === 'READ_WRITE') {
    return permissionType.VIEW_EDIT;
  } if (accessLevel === 'READ_ONLY') {
    return permissionType.VIEW;
  }
  return permissionType.NO_ACCESS;
};

export const formatClientTeamMembers = (response) => {
  const formattedResponse = [];
  forEach(response.teamMembers, (teamMember) => {
    const {
      teamMemberUserInfo,
      accessLevel,
      accessGrantedDate,
      provisioningUserInfo,
      userIsOwner,
    } = teamMember;
    const { firstName, lastName, email } = provisioningUserInfo;
    const permissionLevel = getPermissionLevel(accessLevel);
    const fullName = `${createFullName(firstName, lastName, email)} ` || '';
    formattedResponse.push({
      id: teamMemberUserInfo.idUser,
      permission: permissionLevel,
      name:
        createFullName(
          teamMemberUserInfo.firstName,
          teamMemberUserInfo.lastName,
          teamMemberUserInfo.email
        ) || '',
      clientEmail: teamMemberUserInfo.email,
      contentOwner: userIsOwner,
      provisionedBy: fullName + (accessGrantedDate || ''),
      originalPermission: permissionLevel,
    });
  });
  return formattedResponse;
};

export const formatClientTeamMembersWithoutStory = (response) => {
  const formattedResponse = [];
  forEach(response.teamMemberInfo, (teams) => {
    forEach(teams.currentTeamMembers, (teamMember) => {
      const { idUser, email, firstName, lastName } = teamMember;
      formattedResponse.push({
        id: idUser,
        permission: permissionType.NO_ACCESS,
        name: createFullName(firstName, lastName, email) || '',
        clientEmail: email,
        contentOwner: false,
        provisionedBy: '',
        originalPermission: permissionType.NO_ACCESS,
      });
    });
  });
  return formattedResponse;
};

export const formatSlugs = (response) => {
  const formattedResponse = [];
  forEach(response.storySlugs, (slug) => {
    const {
      idStory,
      storyName,
      createdBy,
      createdDate,
      slugs,
      isPrivate,
    } = slug;

    const allSlugs = map(slugs, (singleSlug) => {
      return singleSlug.slug;
    });

    formattedResponse.push({
      id: idStory,
      idStory,
      storyName,
      isPrivate,
      createdBy: createdBy || '',
      createdDate: createdDate
        ? formatUtcDateToLocal(createdDate, dateFormatting)
        : 'N/A',
      numSlugs: `${slugs.length}`,
      url: allSlugs[0],
      slugs: allSlugs,
    });
  });
  return formattedResponse;
};

export const urlValidation = (slug) => {
  const re = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  return re.test(slug);
};

export const formatTeamStories = (response) => {
  const formattedResponse = [];
  forEach(response.provisionedStories, (story) => {
    const {
      idStory,
      idTeam,
      storyName,
      usersWithAccess,
      numberOfUsers,
      tsCreated,
    } = story;
    formattedResponse.push({
      id: idStory,
      idStory,
      idTeam,
      storyName,
      numUsers: `${usersWithAccess} of ${numberOfUsers}`,
      createdDate: tsCreated
        ? formatUtcDateToLocal(tsCreated, dateFormatting)
        : 'N/A',
    });
  });
  return formattedResponse;
};

export const getTablePageText = (
  pageNumber,
  recordsPerPage,
  totalRecords,
  currentTab
) => {
  const start = pageNumber * recordsPerPage + 1;
  const end = pageNumber * recordsPerPage + recordsPerPage;
  const totalRecordsOnPage = Math.min(end, totalRecords);
  if (currentTab === UPDATE_ACCESS_BY_STORY) {
    return `Showing ${start}-${totalRecordsOnPage} out of ${totalRecords.toLocaleString()} teams`;
  } if (currentTab === UPDATE_ACCESS_BY_TEAM) {
    return `Showing ${start}-${totalRecordsOnPage} out of ${totalRecords.toLocaleString()} provisioned stories`;
  }
  return `Showing ${start}-${totalRecordsOnPage} out of ${totalRecords.toLocaleString()} stories`;
};

export function validateInnerTableRecords(innerTableRecords) {
  return getKeyValueFromListViaOtherKey(
    innerTableRecords,
    'contentOwner',
    true,
    'id'
  )
    ? ''
    : 'Please select a content owner';
}

export const getSelectedRecords = (records) => {
  const selected = [];
  forEach(records, (_, key) => {
    if (records[key]) {
      selected.push(key.toString());
    }
  });
  return selected.sort();
};

export const createStoryErrorText = (teamNames, row, index) => {
  const contentOwnerErrorText = 'has no content owner and no team members';
  if (row.failureReason.indexOf(contentOwnerErrorText) !== -1) {
    return (
      <span key={index}>
        <i>{`${teamNames[row.idTeam]}`}</i> has no content owner: please set
        one&nbsp;
        <a
          href={`${window.envVars.LEGACY_DOMAIN}/admin/team-edit/${row.idTeam}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>
        <br />
      </span>
    );
  }
  return (
    <span key={index}>
      {`${teamNames[row.idTeam]} (${row.idTeam}): ${row.failureReason}`}
      <br />
    </span>
  );
};

export const createStoryError = (failureRows, teamNames) => {
  if (failureRows.length === 1) {
    return createStoryErrorText(teamNames, failureRows[0], 0);
  }
  return (
    <span>
      {map(failureRows, (row, index) => (
        <div>
          <span>{index + 1}.&nbsp;</span>
          {createStoryErrorText(teamNames, row, index)}
        </div>
      ))}
    </span>
  );
};

export default getCurrentTab;
