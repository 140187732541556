import { Spin } from 'antd';
import { FormContainerWithErrorBoundary } from 'client/modules/common/form/containers/FormContainerWithErrorBoundary';
import { CBISectionFormLayout } from '../components/cbi-entity/CBISectionFormLayout';
import { useFinancials } from './pages/financials/hooks/useFinancials';

import { CBISectionHeaderForm } from '../components/cbi-entity/CBISectionHeaderForm';
import { FinancialsActions } from './pages/financials/FinancialsActions';
import { FinancialsForm } from './pages/financials/FinancialForm';
import { useFinancialForm } from './pages/financials/hooks/useFinancialForm';

export const Financials = () => {
  const {
    idCbiEntity,
    exchangedListIsFetched,
    initialValues,
    initialStatusValues,
    financialsIsFetched,
  } = useFinancials();
  const { handleSubmit } = useFinancialForm();

  if (idCbiEntity === undefined) {
    return <span>Financial data is not available.</span>;
  }

  if (
    !exchangedListIsFetched ||
    !financialsIsFetched ||
    !initialValues?.entityName
  ) {
    return (
      <div className="flex justify-center my-10">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <FormContainerWithErrorBoundary
      Form={CBISectionFormLayout}
      enableReinitialize
      onReject={() => null}
      optionalFormProps={{
        FormHeader: CBISectionHeaderForm,
        FormContent: FinancialsForm,
        CallToAction: FinancialsActions,
        optionalProps: { formIsLoading: false },
        TabBarAdditionalContent: [],
      }}
      initialValues={initialValues}
      initialStatus={initialStatusValues}
      errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
      onSubmit={handleSubmit}
    />
  );
};
