import { Button, Modal, message } from 'antd';
import { useMutation } from 'react-query';
import { sendWelcomeEmail } from '../services/api/SendWelcomeEmail';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  idUser: number;
};

const ResetWelcomeEmail = ({ isOpen, handleClose, idUser }: Props) => {
  const sendWelcomeEmailQuery = useMutation(sendWelcomeEmail);

  const onConfirm = () => {
    sendWelcomeEmailQuery
      .mutateAsync({
        id_user: idUser,
      })
      .then(() => {
        message.success('Welcome email sent successfully');
        handleClose();
      })
      .catch(() => {
        message.error('Failed to send welcome email');
      });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      width="50vw"
      footer={
        <div>
          <Button
            onClick={handleClose}
            loading={sendWelcomeEmailQuery.isLoading}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={onConfirm}
            loading={sendWelcomeEmailQuery.isLoading}
          >
            Confirm
          </Button>
        </div>
      }
      title="Resend Welcome Email"
    >
      <div>
        Would you like to resend the welcome email to this user? Any other
        password reset link or welcome email link will be expired in favor of
        the one in this email.
      </div>
      <div>Click confirm to send</div>
    </Modal>
  );
};

export default ResetWelcomeEmail;
