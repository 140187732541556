import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const Domains = React.lazy(() => import('./containers/Domains'));
const IpRanges = React.lazy(() => import('./containers/IpRanges'));

export const universityDisplayRoutes: RouteObject = {
  path: 'university-display',
  children: [
    {
      path: '/university-display',
      element: <Navigate to="/university-display/domains" />,
    },
    {
      path: 'domains',
      element: (
        <React.Suspense fallback={undefined}>
          <Domains />
        </React.Suspense>
      ),
    },
    {
      path: 'ip-ranges',
      element: (
        <React.Suspense fallback={undefined}>
          <IpRanges />
        </React.Suspense>
      ),
    },
  ],
};
