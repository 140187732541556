import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { withStyles } from 'client/component-library/styles';
import {
  getDuplicates,
  toggleInnerTable,
} from 'client/modules/markets/redux/actions/data';
import styles from '../../styles/material-ui/duplicate-count';
import Duplicates from './Duplicates';

class DuplicateCountCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleOnClick = (e) => {
    e.preventDefault();

    const { id, showHiddenRowDict } = this.props;
    const [recordId] = id.split('-');

    const displayDuplicates = showHiddenRowDict[recordId];
    if (!displayDuplicates) {
      this.props.getDuplicates(recordId);
    }

    this.props.toggleInnerTable(recordId);
  };

  render() {
    const { id, classes, showHiddenRowDict, duplicatesCount } = this.props;

    if (duplicatesCount < 1) {
      return null;
    }

    return (
      <Duplicates
        id={id}
        classes={classes}
        showHiddenRowDict={showHiddenRowDict}
        duplicatesCount={duplicatesCount}
        handleOnClick={this.handleOnClick}
      />
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');

  if (
    markets.tabData.marketSizingDetails.records[recordId] &&
    markets.tabData.marketSizingDetails.showHiddenRowDict
  ) {
    return {
      showHiddenRowDict: markets.tabData.marketSizingDetails.showHiddenRowDict,
      duplicatesCount:
        markets.tabData.marketSizingDetails.records[recordId].dupeCount,
    };
  }
  return {};
}

DuplicateCountCell.propTypes = {
  classes: PropTypes.object.isRequired,
  duplicatesCount: PropTypes.number,
  getDuplicates: PropTypes.func.isRequired,
  id: PropTypes.string,
  showHiddenRowDict: PropTypes.object,
  toggleInnerTable: PropTypes.func.isRequired,
};

DuplicateCountCell.defaultProps = {
  id: '',
  duplicatesCount: 0,
  showHiddenRowDict: {},
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getDuplicates,
    toggleInnerTable,
  })(DuplicateCountCell)
);
