import React from 'react';
import { AppRoutes } from 'client/routes';
import { BrowserRouter } from 'react-router-dom';
import { DevRoot } from './Root.dev';
import { ProdRoot } from './Root.prod';

export const AppRoot = ({ store }: { store: $TSFixMe }) => {
  return (
    <BrowserRouter>
      {process.env.NODE_ENV === 'development' ? (
        <DevRoot store={store}>
          <AppRoutes />
        </DevRoot>
      ) : (
        <ProdRoot store={store}>
          <AppRoutes />
        </ProdRoot>
      )}
    </BrowserRouter>
  );
};
