import React, { ReactElement } from 'react';
import { Col, Divider, Row } from 'antd';
import Card from 'antd/lib/card/Card';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled';
import { DraftTypeFilter } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { DraftStatus } from '@cbinsights/draftqueueservice/draftqueueservice';
import { useGetDraftCountsQuery } from '../services/hooks/useGetDraftCounts';
import { useDraftQueueAdminPageDetail } from '../services/hooks/useDraftQueueAdminPageDetail';

export const DRAFT_STATUS_COLORS = {
  [DraftStatus.READY_FOR_ENRICHMENT]: 'cbi-entity-draft-enrichment',
  [DraftStatus.READY_FOR_QA]: 'cbi-entity-draft-qa',
} as const;

export const DraftOverview = (): ReactElement => {
  const draftCountsQuery = useGetDraftCountsQuery();
  const { draftTypeFilter } = useDraftQueueAdminPageDetail();
  // showing profile overview by default
  const showProfileDraftOverview =
    draftTypeFilter && draftTypeFilter.length !== 0
      ? draftTypeFilter.includes(DraftTypeFilter.ENTITY_DRAFT)
      : true;
  const showFundingDraftOverview =
    draftTypeFilter && draftTypeFilter.length !== 0
      ? draftTypeFilter.includes(DraftTypeFilter.FUNDING_DRAFT)
      : false;

  return (
    <Card className="w-full">
      <Row justify="center">
        {showProfileDraftOverview && (
          <Col>
            <h2 className="font-medium text-center text-xl pb-2">
              Profile Queue Overview
            </h2>
            <Row justify="center">
              <Col className="px-4">
                <div className="flex flex-col">
                  <span className="text-gray-500">Ready For Enrichment</span>
                  <div className="flex items-center">
                    <span className="pr-2">
                      <ExclamationCircleFilled className="text-cbi-entity-draft-enrichment" />
                    </span>
                    <span className="text-2xl">
                      {draftCountsQuery?.data?.entityDraftCounts
                        ?.enrichmentDraftCount ?? 0}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="px-4">
                <div className="flex flex-col">
                  <span className="text-gray-500">Ready For QA</span>
                  <div className="flex items-center">
                    <span className="pr-2">
                      <ExclamationCircleFilled className="text-cbi-entity-draft-qa" />
                    </span>
                    <span className="text-2xl">
                      {draftCountsQuery?.data?.entityDraftCounts
                        ?.qaDraftCount ?? 0}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="px-4">
                <div className="flex flex-col">
                  <span className="text-gray-500">Published (last 24 hrs)</span>
                  <div className="flex items-center">
                    <span className="pr-2">
                      <CheckCircleFilled className="text-cbi-draft-published" />
                    </span>
                    <span className="text-2xl">
                      {draftCountsQuery?.data?.entityDraftCounts
                        ?.publishedDraftCount ?? 0}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {showFundingDraftOverview && showProfileDraftOverview && (
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
        )}
        {showFundingDraftOverview && (
          <Col>
            <h2 className="font-medium text-center text-xl pb-2">
              Funding Queue Overview
            </h2>
            <Row justify="center">
              <Col className="px-4">
                <div className="flex flex-col">
                  <span className="text-gray-500">Ready For QA</span>
                  <div className="flex items-center">
                    <span className="pr-2">
                      <ExclamationCircleFilled className="text-cbi-funding-draft-qa" />
                    </span>
                    <span className="text-2xl">
                      {draftCountsQuery?.data?.fundingDraftCounts
                        ?.pendingDraftCount ?? 0}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="px-4">
                <div className="flex flex-col">
                  <span className="text-gray-500">Published (last 24 hrs)</span>
                  <div className="flex items-center">
                    <span className="pr-2">
                      <CheckCircleFilled className="text-cbi-draft-published" />
                    </span>
                    <span className="text-2xl">
                      {draftCountsQuery?.data?.fundingDraftCounts
                        ?.publishedDraftCount ?? 0}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};
