/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
/* eslint-enable max-len */

// Common Table Cell Components
import DateCell from './CellComponents/DateCell';
import LinkCell from './CellComponents/LinkCell';
import TextAreaCell from './CellComponents/TextAreaCell';
import TextCell from './CellComponents/TextCell';
import SelectCell from './CellComponents/SelectCell';
import InputCell from './CellComponents/InputCell';
import CheckboxCell from './CellComponents/CheckboxCell';
import RadioCell from './CellComponents/RadioCell';
import DangerouslySetHtmlCell from './CellComponents/DangerouslySetHtmlCell';

import NewsArticleInputAdornment from './CellComponents/NewsArticleInputAdornment';
import AutocompleteInputCell from '../ConnectedTableComponents/AutocompleteInputCell';
import AutocompleteInputAdornment from './CellComponents/AutocompleteInputAdornment';

/**
 * @deprecated
 * It takes in a bunch of props and returns a component based on the type prop
 * @param props - The props that are passed to the component.
 * @returns A component that is being returned.
 */
const MapTableCellToComponent = (props) => {
  const {
    id,
    width,
    checked,
    type,
    name,
    value,
    placeholder,
    autocompleteOptions,
    textAreaWidth,
    resize,
    showInputAdornment,
    rowId,
    options,
    news,
    numberOfRows,
    height,
    disabled,
    checkValue,
    maxLength,
    defaultValue,
    linkText,
    generateCustomTableComponents = window._circularDependency
      .mapCustomTableComponents,
  } = props;

  const commonProps = {
    id,
    dataTest: id,
    value,
    name,
    placeholder,
    width,
    disabled,
  };

  if (props.onChange) {
    commonProps.onChange = props.onChange;
  }

  switch (type) {
    case 'input':
      return <InputCell {...commonProps} multiline={false} />;
    case 'textarea':
      return (
        <TextAreaCell
          {...commonProps}
          resize={resize}
          textAreaWidth={textAreaWidth}
          numberOfRows={numberOfRows}
          height={height}
          maxLength={maxLength}
          multiline
        />
      );
    case 'selector':
      return <SelectCell {...commonProps} options={options} />;
    case 'date':
      return <DateCell {...commonProps} />;
    case 'link':
      return <LinkCell {...commonProps} linkText={linkText} />;
    case 'checkbox':
      return <CheckboxCell {...commonProps} id={rowId} checked={checked} />;
    case 'radio':
      return (
        <RadioCell
          {...commonProps}
          id={rowId}
          checked={checked}
          checkValue={checkValue}
        />
      );
    case 'articleLink':
      return (
        <InputCell
          {...commonProps}
          multiline={false}
          startAdornment={
            <NewsArticleInputAdornment
              showInputAdornment={showInputAdornment}
              value={value}
              news={news}
            />
          }
        />
      );
    case 'dangerouslySetInnerHTML':
      return <DangerouslySetHtmlCell value={value} dataTest={id} />;
    case 'autocompleteInput':
      return (
        <div key={id} width={width}>
          <AutocompleteInputCell
            disabled={disabled}
            inputId={id}
            suggestionType={autocompleteOptions.suggestionType}
            suggestionHandler={autocompleteOptions.suggestionHandler}
            width={autocompleteOptions.autoInputWidth}
            placeholder={placeholder}
            filterFieldName={autocompleteOptions.filterFieldName}
            showFixedItemAtTheTop={autocompleteOptions.showFixedItemAtTheTop}
            fixedItemText={autocompleteOptions.fixedItemText}
            name={name}
            dataTest={`${id}-${type}`}
            showAllItems={!!autocompleteOptions.showAllItems}
            startAdornment={
              <AutocompleteInputAdornment
                showInputAdornment={showInputAdornment}
                id={id}
              />
            }
          />
        </div>
      );
    case 'empty':
      return <div key={id} style={{ width, height }} />;
    case 'text':
    default: {
      const customComponent = generateCustomTableComponents(
        type,
        props,
        commonProps
      );
      return (
        customComponent || (
          <TextCell {...commonProps} defaultValue={defaultValue} />
        )
      );
    }
  }
};

MapTableCellToComponent.propTypes = {
  checkValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  autocompleteOptions: PropTypes.object,
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  resize: PropTypes.string,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showInputAdornment: PropTypes.bool,
  textAreaWidth: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object, // the value for reviewerCell is object contains first name, last name and id.
    PropTypes.bool,
  ]),
  width: PropTypes.string,
  news: PropTypes.object,
  height: PropTypes.string,
  numberOfRows: PropTypes.number,
  maxLength: PropTypes.string,
  linkText: PropTypes.string,
  onChange: PropTypes.func,
};

MapTableCellToComponent.defaultProps = {
  checkValue: '',
  defaultValue: '',
  disabled: false,
  autocompleteOptions: {},
  checked: false,
  id: '',
  linkText: '',
  name: '',
  news: {},
  options: [],
  placeholder: '',
  resize: 'vertical',
  showInputAdornment: false,
  textAreaWidth: '',
  type: 'text',
  value: '',
  width: '100px',
  height: '',
  numberOfRows: 5,
  maxLength: '',
  onChange: () => {},
};

export default MapTableCellToComponent;
