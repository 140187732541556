import { DraftQueueAdminPageDetail } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { useGetAdminPermissionsForUser } from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';

export const useDraftQueueAdminPageDetail = (): DraftQueueAdminPageDetail => {
  const { isLoading, data } = useGetAdminPermissionsForUser();

  let pageDetails: DraftQueueAdminPageDetail = {
    draftTypeFilter: [],
    draftStatusFilter: [],
    teamFilter: [],
    draftSources: [],
  };
  if (!isLoading && data) {
    pageDetails = data?.draftQueueAdminPageDetail;
  }

  return pageDetails;
};
