// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-Table-styles-css-___cell-styles__spanBold___T9oRo {
  font-weight: bold;
}

.client-modules-common-components-Table-styles-css-___cell-styles__articleCell___U2mML {
  text-decoration: none;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]};
}

.client-modules-common-components-Table-styles-css-___cell-styles__spanError___d0CPA {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["red"]};
}

.client-modules-common-components-Table-styles-css-___cell-styles__cellSubtextWrapper___iVgjs {
  position: relative;
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["yellow"]};
  padding-top: 5px;
  padding-bottom: 5px;
  overflow-x: scroll;
}

.client-modules-common-components-Table-styles-css-___cell-styles__cellsubtext___U_B90 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"]};
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/Table/styles/css/cell-styles.css"],"names":[],"mappings":"AAMA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,mDAAW;AACb;;AAEA;EACE,mDAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,wDAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mDAAW;AACb","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value blue from colors;\n@value red from colors;\n@value grey from colors;\n@value yellow from colors;\n\n.spanBold {\n  font-weight: bold;\n}\n\n.articleCell {\n  text-decoration: none;\n  color: blue;\n}\n\n.spanError {\n  color: red;\n}\n\n.cellSubtextWrapper {\n  position: relative;\n  background: yellow;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  overflow-x: scroll;\n}\n\n.cellsubtext {\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"blue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]}`,
	"red": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["red"]}`,
	"grey": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"]}`,
	"yellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["yellow"]}`,
	"spanBold": `client-modules-common-components-Table-styles-css-___cell-styles__spanBold___T9oRo`,
	"articleCell": `client-modules-common-components-Table-styles-css-___cell-styles__articleCell___U2mML`,
	"spanError": `client-modules-common-components-Table-styles-css-___cell-styles__spanError___d0CPA`,
	"cellSubtextWrapper": `client-modules-common-components-Table-styles-css-___cell-styles__cellSubtextWrapper___iVgjs`,
	"cellsubtext": `client-modules-common-components-Table-styles-css-___cell-styles__cellsubtext___U_B90`
};
export default ___CSS_LOADER_EXPORT___;
