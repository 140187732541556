import React from 'react';
import { Empty, Spin, Table } from 'antd';
import useUserEmailEventsTable from '../hooks/useUserEmailEventsTable';

type Props = {
  idUser: number;
  enabled: boolean;
};

const EmailEvents = ({ idUser, enabled }: Props) => {
  const { columns, dataSource, isLoading } = useUserEmailEventsTable(
    idUser,
    enabled
  );

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!isLoading && dataSource.length === 0) {
    return (
      <div className="flex justify-center">
        <Empty />
      </div>
    );
  }

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default EmailEvents;
