import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { BRPageRoute } from './components/TableStructure/BRTabsBar';

const BusinessRelationshipsPage = React.lazy(
  () => import('./containers/BusinessRelationshipPage')
);
const BusinessRelationshipsCSVUpload = React.lazy(
  () => import('./containers/BusinessRelationshipsCSVUpload')
);

export const businessRelationshipRoutes: RouteObject = {
  path: BRPageRoute,
  children: [
    {
      path: `/${BRPageRoute}`,
      element: <Navigate to={`/${BRPageRoute}/review`} />,
    },
    {
      path: ':tab',
      element: (
        <React.Suspense fallback={undefined}>
          <BusinessRelationshipsPage />
        </React.Suspense>
      ),
    },
    {
      path: 'csv-upload',
      element: (
        <React.Suspense fallback={undefined}>
          <BusinessRelationshipsCSVUpload />
        </React.Suspense>
      ),
    },
  ],
};
