import { GetDraftCountsResponse } from '@cbinsights/draftqueueservice/draftqueueservice';
import { useQuery, UseQueryResult } from 'react-query';
import { getDraftCounts } from '../api/getDraftCounts';

type GetDraftCounts = 'GetDraftCounts';
type ListDrafts = 'ListDrafts';
type GetDrafters = 'GetDrafters';
export type DraftQueueServiceKeys = GetDraftCounts | ListDrafts | GetDrafters;

export const useGetDraftCountsQuery = (): UseQueryResult<
  GetDraftCountsResponse,
  Error
> =>
  useQuery<
    GetDraftCountsResponse,
    Error,
    GetDraftCountsResponse,
    [DraftQueueServiceKeys]
  >(['GetDraftCounts'], getDraftCounts, {
    notifyOnChangeProps: ['data', 'isFetching'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
