import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export type AntdCheckboxFieldProps = {
  ref?: React.Ref<HTMLInputElement>;
} & CheckboxProps &
  FieldHookConfig<boolean>;

export const AntdSingleCheckboxField = ({
  disabled = false,
  onChange = () => {},
  ...props
}: AntdCheckboxFieldProps) => {
  const [field, , helpers] = useField(props);
  const { isSubmitting } = useFormikContext();

  const handleChange = (event: CheckboxChangeEvent) => {
    helpers.setValue(event?.target?.checked);
    helpers.setTouched(true);
    onChange(event);
  };

  return (
    <Checkbox
      {...field}
      disabled={isSubmitting || disabled}
      onChange={handleChange}
      checked={field.value}
      {...props}
    />
  );
};
