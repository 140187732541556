import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTES } from './constants';

const ProfileQueuePageWrapper = React.lazy(
  () => import('./containers/ProfilesQueuePageWrapper')
);
const HomePage = React.lazy(() => import('./pages/HomePage'));
const ReviewProfilesPage = React.lazy(
  () => import('./pages/ReviewProfilesPage')
);
const PendingProfilesPage = React.lazy(
  () => import('./pages/PendingProfilesPage')
);
const QAProfilesPage = React.lazy(() => import('./pages/QAProfilesPage'));

export const profileQueueRoutes: RouteObject = {
  path: 'profile-queue',
  children: [
    {
      path: '/profile-queue',
      element: <Navigate to={ROUTES.home.uri} />,
    },
    {
      element: (
        <React.Suspense fallback={undefined}>
          <ProfileQueuePageWrapper />
        </React.Suspense>
      ),
      children: [
        {
          path: ROUTES.home.path,
          element: (
            <React.Suspense fallback={undefined}>
              <HomePage />
            </React.Suspense>
          ),
        },
        {
          path: ROUTES.review.path,
          element: (
            <React.Suspense fallback={undefined}>
              <ReviewProfilesPage />
            </React.Suspense>
          ),
        },
        {
          path: ROUTES.pending.path,
          element: (
            <React.Suspense fallback={undefined}>
              <PendingProfilesPage />
            </React.Suspense>
          ),
        },
        {
          path: ROUTES.qa.path,
          element: (
            <React.Suspense fallback={undefined}>
              <QAProfilesPage />
            </React.Suspense>
          ),
        },
      ],
    },
  ],
};
