import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MaterialUILink } from 'client/component-library/Link';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import Input from 'client/component-library/Input';
import {
  updateRecord,
  updateMarketName,
} from 'client/modules/markets/redux/actions/data';
import { withReactRouter } from 'client/routes/withReactRouter';
import styles from '../styles/table-styles.css';

const baseIconStyles = {
  cursor: 'pointer',
  fontSize: '20px',
  position: 'relative',
};

class ListOfMarketsNameCell extends Component {
  constructor(props) {
    super(props);

    this.state = { inEditState: false, initialMarketName: '' };
  }

  listOfMarketsNameCell = (value) => (e) => {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.navigation(`/markets/market-sizing-details?searchTerm=${value}`);
  };

  handleEditMarketName = (value) => () => {
    this.setState({ inEditState: true, initialMarketName: value });
  };

  handleCancelEdit = () => () => {
    const { dispatch, id } = this.props;
    const [recordId, name] = id.split('-');
    dispatch(updateRecord(recordId, name, this.state.initialMarketName));
    this.setState({ inEditState: false });
  };

  handleSaveMarketName = () => () => {
    const { dispatch, id, value } = this.props;
    const [recordId] = id.split('-');
    dispatch(updateMarketName(recordId, value)).then((success) => {
      if (success) {
        this.setState({ inEditState: false });
      }
    });
  };

  render() {
    const { id, value, parentMarketId, parentMarketName } = this.props;
    return (
      <div className={styles.listOfMarketsNameCellWrapper}>
        <span className={styles.inlineBlock} style={{ maxWidth: '350px' }}>
          {this.state.inEditState ? (
            <Input
              value={value}
              name="name"
              data-test={`${id}-edit-market-name-input`}
              style={{ width: '200px' }}
            />
          ) : (
            <MaterialUILink
              onClick={this.listOfMarketsNameCell(value)}
              data-test={id}
              color="blue"
            >
              {value}
            </MaterialUILink>
          )}
          {parentMarketName && !this.state.inEditState && (
            <span>
              &nbsp;
              <MaterialUILink
                onClick={this.listOfMarketsNameCell(parentMarketName)}
                data-test={parentMarketId}
                color="blue"
              >
                (merged to: {parentMarketName})
              </MaterialUILink>
            </span>
          )}
        </span>
        {!this.state.inEditState && (
          <span className={styles.inlineBlock} style={{ left: '-5px' }}>
            <BaseIcon
              handleOnClick={this.handleEditMarketName(value)}
              iconButtonStyles={{ width: '48px', height: '48px' }}
              dataTest={`${id}-edit-market-name`}
              iconStyles={{ ...baseIconStyles, color: '#FF6633' }}
              iconName="Edit"
              isIconBlock={false}
            />
          </span>
        )}

        {this.state.inEditState && (
          <span className={styles.inlineBlock} style={{ left: '-5px' }}>
            <span className={styles.inlineBlock}>
              <BaseIcon
                handleOnClick={this.handleCancelEdit()}
                dataTest={`${id}-cancel-edit`}
                iconButtonStyles={{ width: '48px', height: '48px' }}
                iconStyles={{ ...baseIconStyles, color: '#FF0050' }}
                iconName="Cancel"
                isIconBlock={false}
              />
            </span>
            <span className={styles.inlineBlock} style={{ left: '-15px' }}>
              <BaseIcon
                handleOnClick={this.handleSaveMarketName()}
                dataTest={`${id}-update-market-name`}
                iconButtonStyles={{ width: '48px', height: '48px' }}
                iconStyles={{ ...baseIconStyles, color: '#66BB6A' }}
                iconName="Check Circle Outline"
                isIconBlock={false}
              />
            </span>
          </span>
        )}
      </div>
    );
  }
}

ListOfMarketsNameCell.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  navigation: PropTypes.object.isRequired,
  parentMarketId: PropTypes.string,
  parentMarketName: PropTypes.string,
};

ListOfMarketsNameCell.defaultProps = {
  id: '',
  value: '',
  parentMarketId: 0,
  parentMarketName: '',
};

export default withReactRouter(connect()(ListOfMarketsNameCell));
