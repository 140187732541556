import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import TextAreaCell from 'client/modules/common/components/Table/TableComponents/CellComponents/TextAreaCell';
import styles from '../styles/table-styles.css';

class ReviewerNoteCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      name,
      value,
      placeholder,
      width,
      height,
      disabled,
      id,
    } = this.props;

    return (
      <div style={{ marginTop: '10px' }}>
        <span className={styles.spanBold}>Reviewer notes: </span>
        <TextAreaCell
          id={id}
          disabled={disabled}
          key={name}
          value={value}
          name={name}
          placeholder={placeholder}
          width={width}
          height={height}
          dataTest={`${id}`}
          multiline
        />
      </div>
    );
  }
}

ReviewerNoteCell.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
};

ReviewerNoteCell.defaultProps = {
  disabled: false,
  name: '',
  value: '',
  width: 'inherit',
  placeholder: '',
  height: '',
  id: '',
};

export default ReviewerNoteCell;
