import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'client/component-library/Input';
import { map } from 'lodash';
import styles from './styles/autocompleteDropdown.css';
import DropdownItem from './DropdownItem';
import CompanyDropdownItem from './CompanyDropdownItem';
import EntityDropdownItem from './EntityDropdownItem';
import FixedDropdownItem from './FixedDropdownItem';
import HighlightEntityDropdownItem from './HighlightEntityDropdownItem';
import { scrollDropdownItemIntoView } from './utils/helpers';

let initialState;

export const displayFixedItem = (
  showFixedItem,
  showFixedItemAtTheTop,
  index,
  numberOfVisibleResults
) => {
  if (!showFixedItem) {
    return false;
  }
  if (showFixedItemAtTheTop) {
    return index === 0;
  }
  return index === numberOfVisibleResults - 1;
};

/** @deprecated */
class Autocomplete extends Component {
  static handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  constructor(props) {
    super(props);

    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.handleBlur = this.handleBlur.bind(this);
    this.foucsOnInput = this.foucsOnInput.bind(this);
    this.selectCurrentItem = this.selectCurrentItem.bind(this);

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleClick = this.handleClick.bind(this);

    initialState = {
      dropdownVisible: false,
      selectedIndex: props.initialSelectedIndex,
      items: [],
      inputValue: '',
    };
    this.state = initialState;
  }

  // eslint-disable-next-line react/sort-comp
  moveUp() {
    const selectedIndex =
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.selectedIndex <= 0
        ? this.props.numberOfVisibleResults
        : // eslint-disable-next-line react/no-access-state-in-setstate
          this.state.selectedIndex - 1;
    scrollDropdownItemIntoView(selectedIndex);
    this.setState({
      dropdownVisible: true,
      selectedIndex,
    });
  }

  moveDown() {
    const selectedIndex =
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.selectedIndex >= this.props.numberOfVisibleResults
        ? 0
        : // eslint-disable-next-line react/no-access-state-in-setstate
          this.state.selectedIndex + 1;
    scrollDropdownItemIntoView(selectedIndex);
    this.setState({
      dropdownVisible: true,
      selectedIndex,
    });
  }

  handleKeyUp(event) {
    const { selectedIndex } = this.state;
    const { inlineActions } = this.props;
    const { key } = event;
    const keyCode = event.which;

    const ignoredKeys = ['Shift', 'Alt', 'Control', 'ArrowLeft', 'ArrowRight'];
    const Command = 91;

    // Its possible the selection was an inline action and not a value
    const inlineAction =
      inlineActions.length &&
      inlineActions.length - 1 >= selectedIndex &&
      inlineActions[selectedIndex].onClick;

    if (key === 'Tab' || key === 'Enter') {
      event.preventDefault();
      if (this.state.dropdownVisible && selectedIndex >= 0) {
        if (inlineAction) {
          inlineAction(event);
        } else {
          this.selectCurrentItem();
        }
      } else if (key === 'Enter') {
        this.props.onEnter();
      }
      this.setState({ dropdownVisible: false });
      return;
    }
    if (key === 'Escape') {
      this.resetState();
      return;
    }
    if (key === 'ArrowUp') {
      event.preventDefault();
      this.moveUp();
      return;
    }
    if (key === 'ArrowDown') {
      event.preventDefault();
      this.moveDown();
      return;
    }

    if (
      event.target.value.length < this.props.numOfCharsNeededToDisplayResults
    ) {
      this.setState({ dropdownVisible: false });
    } else if (!ignoredKeys.includes(key) && keyCode !== Command) {
      this.setState({ dropdownVisible: true });
    }
  }

  handleClick(index) {
    this.setState({
      selectedIndex: index,
      dropdownVisible: false,
      // eslint-disable-next-line react/no-unused-state
      items: [],
    });

    this.selectItem(index);
    this.foucsOnInput();
  }

  handleClickCompany(e, index) {
    if (e.target.nodeName === 'A') {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.opener = null;
        newWindow.location = e.target.href;
      }
      return null;
    }
    return this.handleClick(index);
  }

  handleBlur() {
    this.resetState();
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  handleMouseOver(index) {
    this.setState({ selectedIndex: index });
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  selectCurrentItem() {
    const { showFixedItem, showFixedItemAtTheTop, handleFixedItemClick } =
      this.props;

    if (
      showFixedItem &&
      showFixedItemAtTheTop &&
      this.state.selectedIndex === 0
    ) {
      handleFixedItemClick(this.state.inputValue);
    } else if (
      showFixedItem &&
      this.state.selectedIndex === this.props.numberOfVisibleResults - 1
    ) {
      handleFixedItemClick(this.state.inputValue);
    } else {
      this.selectItem(this.state.selectedIndex);
    }
  }

  selectItem(index) {
    const { inlineActions } = this.props;

    if (this.props.onSelect) {
      this.props.onSelect(this.props.items[index - inlineActions.length]);
    }
    this.resetState();
  }

  resetState() {
    this.setState(initialState);
  }

  foucsOnInput() {
    const { autoInput } = this;
    setTimeout(() => {
      autoInput.focus();
    }, 0);
  }

  /**
   * @deprecated
   * The function renders the autocomplete dropdown component
   * @returns A div with a class of autocompleteDropdownWrapper.
   */
  render() {
    const {
      dropdownType,
      value,
      autoFocus,
      placeholder,
      disabled,
      width,
      inputClassName,
      showFixedItem,
      showFixedItemAtTheTop,
      fixedItem,
      items,
      numberOfVisibleResults,
      filterFieldName,
      dropdownBoxWidth,
      name,
      customStyles,
      multiline,
      startAdornment,
      endAdornment,
      dataTest,
      wrapperWidth,
      helperText,
      customDropdownInput,
      inlineActions,
      inputClasses,
    } = this.props;

    return (
      <div
        data-test="autocomplete-dropdown-wrapper"
        style={{
          maxHeight: '150px',
          color: '#404040',
          width: `${wrapperWidth || width}px`,
        }}
        className={customStyles.autocompleteDropdownWrapper}
      >
        <Input
          type="text"
          multiline={multiline}
          inputRef={(node) => {
            this.autoInput = node;
          }}
          className={`${styles.autocompleteInput} ${inputClassName} ${customStyles.autocompleteInput}`}
          style={{ width: `${width}px` }}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          onChange={this.handleInputChange}
          disabled={disabled}
          onKeyUp={this.handleKeyUp}
          onKeyDown={Autocomplete.handleKeyDown}
          onBlur={this.handleBlur}
          data-test={`${dataTest}-autocomplete-input`}
          name={name}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          helpertext={helperText}
          classes={inputClasses}
        />

        {this.state.dropdownVisible &&
        items &&
        (items.length || inlineActions.length) > 0 ? (
          <div
            className={styles.dropdownOuterDiv}
            data-test="dropdown-outer-div"
          >
            <div
              className={styles.dropdownBox}
              style={{ width: `${dropdownBoxWidth || width + 10}px` }}
              data-test="dropdown-box"
            >
              {inlineActions.map((action, index) =>
                React.cloneElement(<DropdownItem />, {
                  className: customStyles.dropdownItemClassName,
                  // eslint-disable-next-line react/no-array-index-key
                  key: index,
                  text: action.text,
                  selected: this.state.selectedIndex === 0,
                  onMouseDown: action.onClick,
                  fontSize: 15,
                })
              )}

              {map(items.slice(0, numberOfVisibleResults), (item, i) => {
                const index = i + inlineActions.length;
                if (
                  displayFixedItem(
                    showFixedItem,
                    showFixedItemAtTheTop,
                    index,
                    numberOfVisibleResults
                  )
                ) {
                  const fixedRowItem = {
                    className: customStyles.fixedItemClassName,
                    FixedItem: fixedItem,
                    key: index,
                    selected: this.state.selectedIndex === index,
                    onMouseDown: this.selectCurrentItem,
                    onMouseOver: () => this.handleMouseOver(index),
                  };
                  return React.cloneElement(
                    <FixedDropdownItem />,
                    fixedRowItem
                  );
                }

                const entityItem = {
                  ...item,
                  className: customStyles.dropdownItemClassName,
                  key: index,
                  text: item[filterFieldName],
                  item,
                  selected: index === this.state.selectedIndex,
                  onMouseOver: () => this.handleMouseOver(index),
                  onMouseDown: () => this.handleClick(index),
                  fontSize: 15,
                };
                if (customDropdownInput) {
                  return React.cloneElement(customDropdownInput, entityItem);
                }
                if (dropdownType === 'company') {
                  return React.cloneElement(<CompanyDropdownItem />, {
                    ...entityItem,
                    onMouseDown: (e) => this.handleClickCompany(e, index),
                    dropdownItemId: `dropdownItem-${i}`,
                  });
                }
                if (dropdownType === 'entity') {
                  return React.cloneElement(<EntityDropdownItem />, {
                    ...entityItem,
                    onMouseDown: (e) => this.handleClickCompany(e, index),
                    dropdownItemId: `dropdownItem-${i}`,
                  });
                }
                if (dropdownType === 'investorEntities') {
                  return React.cloneElement(<HighlightEntityDropdownItem />, {
                    ...entityItem,
                    highlightCompanies: true,
                    highlightInvestors: false,
                    onMouseDown: (e) => this.handleClickCompany(e, index),
                    dropdownItemId: `dropdownItem-${i}`,
                  });
                }
                if (dropdownType === 'boardOfDirectors') {
                  const textDisplay = (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>{entityItem.name}</p>
                      <p>{entityItem.investor}</p>
                    </div>
                  );
                  return React.cloneElement(<DropdownItem />, {
                    ...entityItem,
                    text: textDisplay,
                    onMouseDown: (e) => this.handleClickCompany(e, index),
                    dropdownItemId: `dropdownItem-${i}`,
                  });
                }
                if (dropdownType === 'simpleEntities') {
                  const textDisplay = (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>{entityItem.name}</p>
                      <p>{entityItem.url}</p>
                    </div>
                  );
                  return React.cloneElement(<DropdownItem />, {
                    ...entityItem,
                    text: textDisplay,
                    onMouseDown: (e) => this.handleClickCompany(e, index),
                    dropdownItemId: `dropdownItem-${i}`,
                  });
                }
                return React.cloneElement(<DropdownItem />, {
                  ...entityItem,
                  dropdownItemId: `dropdownItem-${i}`,
                });
              })}
            </div>
          </div>
        ) : null}

        {helperText && <span style={{ fontSize: '10px' }}>{helperText}</span>}
      </div>
    );
  }
}

export const AutocompletePropTypes = {
  autoFocus: PropTypes.bool,
  customDropdownInput: PropTypes.node,
  customStyles: PropTypes.object,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownBoxWidth: PropTypes.number,
  dropdownType: PropTypes.string,
  endAdornment: PropTypes.node,
  filterFieldName: PropTypes.string.isRequired,
  fixedItem: PropTypes.func,
  handleFixedItemClick: PropTypes.func,
  helperText: PropTypes.string,
  initialSelectedIndex: PropTypes.number,
  inputClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  numOfCharsNeededToDisplayResults: PropTypes.number,
  numberOfVisibleResults: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showFixedItem: PropTypes.bool,
  showFixedItemAtTheTop: PropTypes.bool,
  startAdornment: PropTypes.node,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapperWidth: PropTypes.number,
  inlineActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  inputClasses: PropTypes.object,
};

Autocomplete.propTypes = AutocompletePropTypes;

Autocomplete.defaultProps = {
  autoFocus: false,
  customDropdownInput: null,
  customStyles: {},
  dataTest: '',
  disabled: false,
  dropdownBoxWidth: undefined,
  dropdownType: '',
  endAdornment: null,
  fixedItem: () => {},
  handleFixedItemClick: () => {},
  helperText: '',
  initialSelectedIndex: -1,
  inputClassName: '',
  multiline: false,
  name: '',
  numOfCharsNeededToDisplayResults: 0,
  numberOfVisibleResults: 0,
  onBlur: () => {},
  onEnter: () => {},
  placeholder: '',
  showFixedItem: false,
  showFixedItemAtTheTop: false,
  startAdornment: null,
  width: 200,
  wrapperWidth: 0,
  inlineActions: [],
  value: '',
  inputClasses: {},
};

export default Autocomplete;
