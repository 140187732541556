/**
 * @summary These are the form validations, they handle all error messaging and transformation before submit
 */
import { string, number, bool, object, mixed } from 'yup';
import moment from 'moment';
import { DEFAULT_OPTION } from 'client/component-library/Select/SimpleSelect';
import { NO_VALUE_ERROR } from 'client/modules/third-party-admin/profile-queue/constants';

const stringValidation = () => string().ensure().trim();

const dateValidation = (dateFormat = 'YYYY-MM-DD') =>
  stringValidation().test(
    'valid-date',
    'Invalid Date',
    (date) => !date || moment(date, dateFormat).isValid()
  );

const floatValidation = () => number();

const integerValidation = () => number().integer();

const booleanValidation = () => bool();

const optionValidation = (
  idType = integerValidation,
  id = 'id',
  name = 'name'
) =>
  object().shape({
    [id]: idType(),
    [name]: stringValidation().transform((value) => {
      const { name: n, label: l } = DEFAULT_OPTION;
      return [n, l].includes(value) ? '' : value;
    }),
  });

const optionRequiredValidation = (message, idType, val, label) =>
  optionValidation(idType, val, label).test(
    'has-value',
    message || NO_VALUE_ERROR,
    ({ id, value, isTemp }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _key = typeof id === 'undefined' ? value : id;
      return (
        _key !== DEFAULT_OPTION.value &&
        parseInt(_key, 10) !== DEFAULT_OPTION.value &&
        !isTemp
      );
    }
  );

// this is for validating the draft entity options which id is 0 but value is string.
const optionRequiredValueValidation = (message, idType, val, label) =>
  optionValidation(idType, val, label).test(
    'has-value',
    message || NO_VALUE_ERROR,
    ({ id, value, isTemp }) => {
      return (
        ((id !== 'undefined' && parseInt(id, 10) !== DEFAULT_OPTION.id) ||
          (value !== 'undefined' &&
            parseInt(value, 10) !== DEFAULT_OPTION.value)) &&
        !isTemp
      );
    }
  );

const currencyValidation = () =>
  mixed()
    .transform((value) => parseFloat(value.toString().replace(/,/g, '')))
    .test('is-number', 'Value must be a number', (value = 0) => {
      return typeof value === 'number' || value === '';
    });

const requiredCountryStates = [
  'United States',
  'United Kingdom',
  'China',
  'Australia',
  'Canada',
];

export const isStateRequired = (country) => {
  return requiredCountryStates.includes(country?.name || country?.label);
};

const cityNotRequiredCountries = ['Hong Kong', 'Monaco', 'Singapore'];

export const isCityRequired = (country) => {
  return !cityNotRequiredCountries.includes(country?.name || country?.label);
};

/** @deprecated */
export const VALIDATIONS = {
  currency: currencyValidation,
  date: dateValidation,
  string: stringValidation,
  option: optionValidation,
  boolean: booleanValidation,
  float: floatValidation,
  number: integerValidation,
  optionRequired: optionRequiredValidation,
  optionValueRequired: optionRequiredValueValidation,
  booleanRequired: (message) =>
    booleanValidation().required(message || NO_VALUE_ERROR),
  numberRequired: (message) =>
    integerValidation().required(message || NO_VALUE_ERROR),
  currencyRequired: (message) =>
    currencyValidation().required(message || NO_VALUE_ERROR),
  floatRequired: (message) =>
    floatValidation().required(message || NO_VALUE_ERROR),
  stringRequired: (message) =>
    stringValidation().required(message || NO_VALUE_ERROR),
  // For Options that have value|label and whos value is an Int
  optionValueLabel: (valueType) =>
    optionValidation(valueType, 'value', 'label'),
  optionValueLabelRequired: (message, valueType) =>
    optionRequiredValidation(message, valueType, 'value', 'label'),
};
