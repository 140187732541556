// added wrapper to avoid issues with mocha, module is not imported when NODE_ENV is test
// eslint-disable-next-line import/no-mutable-exports
let history = {
  // eslint-disable-next-line no-unused-vars
  replace: (args) => {},
};

if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line global-require
  history = require('history/browser').default;
}

export { history };
