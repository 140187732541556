import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import * as actions from '../actions/tab-details';

const initialState = {
  [UPDATE_ACCESS_BY_STORY]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    urlParams: '',
  },
  [UPDATE_ACCESS_BY_TEAM]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    urlParams: '',
  },
  [MANAGE_SLUGS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    urlParams: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALZE_DATA_DETAILS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          pageNumber:
            parseInt(action.pageNumber, 10) ||
            initialState[action.currentTab].pageNumber,
          recordsPerPage:
            parseInt(action.recordsPerPage, 10) ||
            initialState[action.currentTab].recordsPerPage,
        },
      };
    case actions.UPDATE_PAGE_NUMBER:
      updateUrlParams('pageNumber', action.pageNumber);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          pageNumber: parseInt(action.pageNumber, 10),
        },
      };
    case actions.UPDATE_RECORDS_PER_PAGE:
      updateUrlParams('recordsPerPage', action.recordsPerPage);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          recordsPerPage: parseInt(action.recordsPerPage, 10),
        },
      };
    case actions.UPDATE_TOTAL_RECORDS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          totalRecords: parseInt(action.totalRecords, 10),
        },
      };
    }
    case actions.SAVE_URL_PARAMS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          urlParams: action.urlParams,
        },
      };
    }
    default:
      return state;
  }
}
