export const UPDATE_RECORD_STATUS_FILTER =
  'client/modules/markets/actions/record-status-filters/UPDATE_RECORD_STATUS_FILTER';
export const INITIALIZE_RECORD_STATUS_FILTERS =
  'client/modules/markets/actions/record-status-filters/INITIALIZE_RECORD_STATUS_FILTERS';
export const NEEDS_APPROVAL = 'Needs Approval';
export const NEEDS_REVIEW = 'Needs Review';
export const REJECTED = 'Rejected';
export const APPROVED = 'Approved';
export const SAVED = 'Saved';
export const IS_DUPLICATE = 'Is Duplicate';
export const NEEDS_REVIEW_CODE = 1;
export const NEEDS_APPROVAL_CODE = 2;
export const REJECTED_CODE = 3;
export const APPROVED_CODE = 4;
export const IS_DUPLICATE_CODE = 5;

export function updateSelectedRecordStatusFilter(selectedRecordStatusFilterId) {
  // Assuming a search has been made, make a new one with the selected search params...
  // otherwise, simply update the statusFilter
  return { type: UPDATE_RECORD_STATUS_FILTER, selectedRecordStatusFilterId };
}

export const fakeApiCall = () => ({
  types: [null, null],
  service: {
    endpoint: 'suggestMarkets',
    name: 'marketservice',
    body: {
      searchTerm: '',
      status: 2,
      offset: 0,
      limit: 10,
    },
  },
});

export const initializeRecordStatusFiltersHelper = (
  recordStatusFilters,
  selectedFilterId
) => {
  return {
    type: INITIALIZE_RECORD_STATUS_FILTERS,
    recordStatusFilters,
    selectedFilterId,
  };
};

export const initializeRecordStatusFilters = (selectedFilterId) => {
  // Make an api call to get the status filters a user has access to.
  // The fakeApiCall will actually dispatch the actions, but for now, do it after...
  return (dispatch) => {
    return dispatch(fakeApiCall()).then(() => {
      // This will be coming from the API
      const recordStatusFilters = {
        // 1: NEEDS_REVIEW, // Remove this status in the dropdown, which we might need in future
        2: NEEDS_APPROVAL,
        3: REJECTED,
        4: APPROVED,
      };
      return dispatch(
        initializeRecordStatusFiltersHelper(
          recordStatusFilters,
          selectedFilterId
        )
      );
    });
  };
};
