import { ApprovalStatus } from '@cbinsights/businessrelationshipservice/businessrelationshipservice';
import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useGetBRUserPermissions } from '../../services/hooks/useGetBRUserPermissions';
import { useGetRelationshipsCounts } from '../../services/hooks/useGetRelationshipCounts';
import { DataCell } from '../GenericComponents/FlexTable/DataCell';
import { TableRow } from '../GenericComponents/FlexTable/TableRow';

export const ApprovalStatusToBRTab: Readonly<{
  [ApprovalStatus.NeedsReview]: 'review';
  [ApprovalStatus.PendingApproved]: 'pending-approval';
  [ApprovalStatus.PendingRejected]: 'pending-rejection';
  [ApprovalStatus.Rejected]: 'rejected';
  [ApprovalStatus.Approved]: 'approved';
}> = {
  [ApprovalStatus.NeedsReview]: 'review',
  [ApprovalStatus.PendingApproved]: 'pending-approval',
  [ApprovalStatus.PendingRejected]: 'pending-rejection',
  [ApprovalStatus.Rejected]: 'rejected',
  [ApprovalStatus.Approved]: 'approved',
};

export const BRTabToApprovalStatus: Readonly<{
  [ApprovalStatusToBRTab.NeedsReview]: ApprovalStatus.NeedsReview;
  [ApprovalStatusToBRTab.PendingApproved]: ApprovalStatus.PendingApproved;
  [ApprovalStatusToBRTab.PendingRejected]: ApprovalStatus.PendingRejected;
  [ApprovalStatusToBRTab.Rejected]: ApprovalStatus.Rejected;
  [ApprovalStatusToBRTab.Approved]: ApprovalStatus.Approved;
}> = {
  [ApprovalStatusToBRTab.NeedsReview]: ApprovalStatus.NeedsReview,
  [ApprovalStatusToBRTab.PendingApproved]: ApprovalStatus.PendingApproved,
  [ApprovalStatusToBRTab.PendingRejected]: ApprovalStatus.PendingRejected,
  [ApprovalStatusToBRTab.Rejected]: ApprovalStatus.Rejected,
  [ApprovalStatusToBRTab.Approved]: ApprovalStatus.Approved,
};

export const BRPageRoute = 'business-relationships';

type TabButtonProps = {
  label: string;
  recordStatus: ApprovalStatus;
  isActive: boolean;
  count: number;
  test: string;
  isCountLoading: boolean;
};
const TabButton = ({
  label,
  recordStatus,
  isActive,
  count,
  test,
  isCountLoading,
}: TabButtonProps) => {
  const location = useLocation();
  return (
    <Link
      to={{
        pathname: `/${BRPageRoute}/${ApprovalStatusToBRTab[recordStatus]}`,
        search: location.search,
      }}
      className={`pb-3 !px-4 rounded-none border-b-2 hover:border-b-2 border-transparent text-lg font-semibold ${
        isActive
          ? 'border-blue-600 !text-blue-600'
          : '!text-black hover:border-blue-600 hover:!text-blue-600'
      }`}
      style={{ marginBottom: '-2px' }}
      data-test={test}
    >
      <span className="bg-white text-lg">
        {`${label} (${
          isCountLoading ? '...' : count?.toLocaleString('en-US')
        })`}
      </span>
    </Link>
  );
};

export const BRTabsBar = () => {
  const { tab } = useParams<'tab'>();

  const { isLoading, data: permissions } = useGetBRUserPermissions();
  const getRelationshipsCountsQuery = useGetRelationshipsCounts();

  return (
    !isLoading && (
      <div className="w-full border-b-2 border-gray-300 pt-5">
        <TableRow>
          {permissions.tabs.featurePermissionReview && (
            <DataCell className="pt-0">
              <TabButton
                label="Review"
                recordStatus={ApprovalStatus.NeedsReview}
                isActive={
                  tab === ApprovalStatusToBRTab[ApprovalStatus.NeedsReview]
                }
                count={
                  getRelationshipsCountsQuery.data && getRelationshipsCountsQuery?.data[ApprovalStatus.NeedsReview]
                }
                test={ApprovalStatusToBRTab[ApprovalStatus.NeedsReview]}
                isCountLoading={getRelationshipsCountsQuery.isFetching}
              />
            </DataCell>
          )}
          {permissions.tabs.featurePermissionPendingApproval && (
            <DataCell className="pt-0">
              <TabButton
                label="Pend. Approval"
                recordStatus={ApprovalStatus.PendingApproved}
                isActive={
                  tab === ApprovalStatusToBRTab[ApprovalStatus.PendingApproved]
                }
                count={
                  getRelationshipsCountsQuery.data && getRelationshipsCountsQuery?.data[
                    ApprovalStatus.PendingApproved
                  ]
                }
                test={ApprovalStatusToBRTab[ApprovalStatus.PendingApproved]}
                isCountLoading={getRelationshipsCountsQuery.isFetching}
              />
            </DataCell>
          )}
          {permissions.tabs.featurePermissionPendingRejection && (
            <DataCell className="pt-0">
              <TabButton
                label="Pend. Rejection"
                recordStatus={ApprovalStatus.PendingRejected}
                isActive={
                  tab === ApprovalStatusToBRTab[ApprovalStatus.PendingRejected]
                }
                count={
                  getRelationshipsCountsQuery.data && getRelationshipsCountsQuery?.data[
                    ApprovalStatus.PendingRejected
                  ]
                }
                test={ApprovalStatusToBRTab[ApprovalStatus.PendingRejected]}
                isCountLoading={getRelationshipsCountsQuery.isFetching}
              />
            </DataCell>
          )}
          {permissions.tabs.featurePermissionApproved && (
            <DataCell className="pt-0">
              <TabButton
                label="Approved"
                recordStatus={ApprovalStatus.Approved}
                isActive={
                  tab === ApprovalStatusToBRTab[ApprovalStatus.Approved]
                }
                count={
                  getRelationshipsCountsQuery.data &&  getRelationshipsCountsQuery?.data[ApprovalStatus.Approved]
                }
                test={ApprovalStatusToBRTab[ApprovalStatus.Approved]}
                isCountLoading={getRelationshipsCountsQuery.isFetching}
              />
            </DataCell>
          )}
          {permissions.tabs.featurePermissionRejected && (
            <DataCell className="pt-0">
              <TabButton
                label="Rejection"
                recordStatus={ApprovalStatus.Rejected}
                isActive={
                  tab === ApprovalStatusToBRTab[ApprovalStatus.Rejected]
                }
                count={
                  getRelationshipsCountsQuery.data &&  getRelationshipsCountsQuery?.data[ApprovalStatus.Rejected]
                }
                test={ApprovalStatusToBRTab[ApprovalStatus.Rejected]}
                isCountLoading={getRelationshipsCountsQuery.isFetching}
              />
            </DataCell>
          )}
        </TableRow>
      </div>
    )
  );
};
