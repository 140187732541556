import React, { ReactElement } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import { ExportOutlined } from '@ant-design/icons';

type Props = {
  sfOwner: string;
  csRepEmail: string;
  sfAccountId: string;
  renewalDate: string;
  recordedDate: string;
};

export const SFDCInfo = ({
  sfOwner,
  csRepEmail,
  sfAccountId,
  renewalDate,
  recordedDate,
}: Props): ReactElement => {
  return (
    <Col
      xl={{ push: 2, span: 7 }}
      md={{ push: 2, span: 20 }}
      xs={{ push: 1, span: 22 }}
    >
      <Typography.Title level={4} className="!font-medium mt-4 pl-2">
        Salesforce Information
      </Typography.Title>
      <Divider className="border-t-2 border-black border-solid" />
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium pl-2">
            SFDC Owner :
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right">
          {sfOwner}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium pl-2">
            CS Rep Email :
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right">
          {csRepEmail}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium pl-2">
            SFDC Account ID :
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right">
          {sfAccountId && (
            <a
              href={`https://cbinsights.lightning.force.com/lightning/r/Account/${sfAccountId}/view`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {sfAccountId} <ExportOutlined />
            </a>
          )}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium pl-2">
            Renewal Date :
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right">
          {renewalDate && moment(renewalDate).format('MM-DD-YYYY')}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium pl-2">
            Record Created :
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right">
          {recordedDate && moment(recordedDate).format('MM-DD-YYYY')}
        </Col>
      </Row>
    </Col>
  );
};
