// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-component-library-icons-Icon-___icon__admin-frontend-icon___EO5Dp::before {
  vertical-align: text-top;
}

.client-component-library-icons-Icon-___icon__icon___PbgoZ {
  font-family: 'FontAwesome';
  font-style: normal;
}

.client-component-library-icons-Icon-___icon__caret-up___njrMb {
}

.client-component-library-icons-Icon-___icon__caret-up___njrMb::before {
  content: '\\f0d8';
}

.client-component-library-icons-Icon-___icon__caret-down___RGKTo {
}
`, "",{"version":3,"sources":["webpack://./client/component-library/icons/Icon/icon.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;AAEA;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AAEA","sourcesContent":[".admin-frontend-icon::before {\n  vertical-align: text-top;\n}\n\n.icon {\n  font-family: 'FontAwesome';\n  font-style: normal;\n}\n\n.caret-up {\n  composes: icon;\n}\n\n.caret-up::before {\n  content: '\\f0d8';\n}\n\n.caret-down {\n  composes: icon;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-frontend-icon": `client-component-library-icons-Icon-___icon__admin-frontend-icon___EO5Dp`,
	"icon": `client-component-library-icons-Icon-___icon__icon___PbgoZ`,
	"caret-up": `client-component-library-icons-Icon-___icon__caret-up___njrMb client-component-library-icons-Icon-___icon__icon___PbgoZ`,
	"caret-down": `client-component-library-icons-Icon-___icon__caret-down___RGKTo client-component-library-icons-Icon-___icon__icon___PbgoZ`
};
export default ___CSS_LOADER_EXPORT___;
