import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { ROUTES as PROFILE_ROUTES } from 'client/modules/third-party-admin/profile-queue/constants';

/**
 * @summary generate Links to proper admin pages given an Id
 * @param {Array<Object>} links - array of Objects that have and ID and a route
 */
export function generateLinksById(links) {
  return links.map((link, i) => {
    const linkId = link.idEntityDraft || link.id;
    let currentRoute;
    if (link.route === 'profile') {
      currentRoute = `${PROFILE_ROUTES.pending.uri}?profileSearchQuery=profileId|${link.id}`;
    } else if (link.route === 'people') {
      // TODO add people route
      currentRoute = '';
    } else {
      currentRoute = `/cbi-entity/${linkId}/general-info`;
    }
    return (
      <React.Fragment key={linkId}>
        <div className="pl-1 mt-1">
          <Link
            className="underline text-blue-500"
            to={currentRoute}
            target="_blank"
          >
            {get(link, 'text', '') ? link.text : `ID: ${linkId}`}
          </Link>
          {i > 0 && ', '}
        </div>
      </React.Fragment>
    );
  });
}
