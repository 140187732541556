import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import { setToastError } from 'client/modules/third-party-admin/profile-queue/utils';
import { parseClusterResponse } from './GetNewsClusters';

function updateEntityOnCluster(cluster, entitiesInfo = [], toDelete = false) {
  return request({
    url: '/service/newsqueueservice/UpdateNewsQueueCluster',
    body: {
      newsQueueCluster: {
        idCluster: cluster.id,
        itemType: cluster.itemType,
        assignedEntities: entitiesInfo.map((entityInfo) => ({
          toDelete,
          idCbiEntity: entityInfo.id,
          idEntityDraft: entityInfo.idEntityDraft,
          name: entityInfo.name,
          idNewsQueueClusterEntity: entityInfo.entityClusterId || 0,
        })),
      },
    },
  })
    .then(({ body: { newsQueueCluster } }) => {
      return parseClusterResponse([newsQueueCluster]);
    })
    .catch((error) => {
      setToastError('Error Updating Cluster');
      logInfo({
        error,
        message: 'News Queue: Failed To Updating Cluster',
        method: 'updateEntityOnCluster',
      });
      throw error;
    });
}

function getEntityFromCluster(clusters, clusterId, entityId) {
  const cluster = clusters.find(({ id }) => id === clusterId);
  const entity = cluster.entities.find(({ id, idEntityDraft }) =>
    idEntityDraft ? idEntityDraft === entityId : id === entityId
  );

  if (!entity) {
    logInfo({
      error: 'Entity Not Found',
      message: `Entity "${entityId}" Not Found in cluster "${clusterId}"`,
      method: 'getEntityFromCluster',
    });
  }

  return entity;
}

export function addEntityToCluster(cluster, entityInfo) {
  return updateEntityOnCluster(cluster, [entityInfo]).then((clusters) => {
    return getEntityFromCluster(
      clusters,
      cluster.id,
      entityInfo.idEntityDraft || entityInfo.id
    );
  });
}

export function removeEntityToCluster(cluster, entityInfo) {
  return updateEntityOnCluster(cluster, [entityInfo], true).then(
    () => entityInfo
  );
}
