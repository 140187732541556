import React from 'react';
import PropTypes from 'prop-types';
import RadioHeaderCell from '../table-components/RadioHeaderCell';
import DateHeaderCellWrapper from '../table-components/DateHeaderCellWrapper';
import HeaderCellWrapper from '../table-components/HeaderCellWrapper';

const mapStoriesTableHeader = (type, columnName, classes, outerTableRowId) => {
  switch (type) {
    case 'stories-radio':
      return (
        <RadioHeaderCell {...columnName} outerTableRowId={outerTableRowId} />
      );
    case 'stories-team-name':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-csm':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-renewal-date':
      return <DateHeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-package-name':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-content-tier':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-bd-acount-sector':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-customer-user-case':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'stories-story-id':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'slugs-story-name':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'slugs-created-by':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    case 'slugs-created-date':
      return <DateHeaderCellWrapper {...columnName} classes={classes} />;
    case 'teams-story-name':
      return <HeaderCellWrapper {...columnName} classes={classes} />;
    default:
      return null;
  }
};

mapStoriesTableHeader.propTypes = {
  type: PropTypes.string.isRequired,
  columnName: PropTypes.object.isRequired,
};

export default mapStoriesTableHeader;
