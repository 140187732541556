import {
  asyncWithLDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { useAppSelector } from 'client/root-reducer';
/*
  Handles fetching LaunchDarkly flags when user info is available.

  If the useLDClient hook is used in a component at the top of the hierarchy,
  the entire platform will re-render when the client finishes initializing;
  therefore, we place it in a component which renders nothing.

  Generally copied from `cbi-site`.
*/
export const useLDUserFlagFetcher = () => {
  const ldClient = useLDClient();
  const idUser = useAppSelector((state) => state.user.data?.idUser);
  const email = useAppSelector((state) => state.user.data?.email);

  useEffect(() => {
    if (idUser && email) {
      ldClient
        .identify(
          {
            key: `${idUser}`,
            email,
            custom: { idUser },
          },
          null
        )
        // eslint-disable-next-line no-console
        .catch((e) => console.log(e));
    }
  }, [idUser, email]);
};

export const ldConfigProvider = () =>
  asyncWithLDProvider({
    clientSideID: process.env.LD_CLIENT_SIDE_ID,
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    // To prevent loading all flags, add any needed LD flags here.
    flags: {
      'BIO-refactor-funding-tab-page': false,
      'BIO-8270-generative-description': false,
      'PAB-2250-admin-fe-user-usage-stats': false,
      'PAB-2452-use-legacy-site': false,
    },
  });
