import {
  GetUserEmailEventsRequest,
  GetUserEmailEventsResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUserEmailEvents = (
  req: Partial<GetUserEmailEventsRequest>
): Promise<GetUserEmailEventsResponse> =>
  request({
    url: '/service/adminservice/GetUserEmailEvents',
    body: req,
  }).then((r) => r.body);
