import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import CircleCloseIcon from '@cbinsights/fds/lib/icons/react/CircleCloseIcon';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

/* Each Chip That will be Rendered */
/**
 * @deprecated
 * It's a function that returns a div with a span and a button
 * @param props - The props object that is passed to the component.
 * @returns A function that returns a div with a span and a button.
 */
export function ChipField(props) {
  const { field, handleDelete } = props;

  return (
    <div className="flex flex-wrap p-1 m-2 bg-gray-300 rounded-lg">
      <span name={`${field.name}`} className="mr-2">
        {get(field, 'value.label', field.value)}
      </span>
      <Button
        data-test={`${field.name}-delete`}
        tabIndex="-1"
        onClick={handleDelete}
        inline
        isDestructive
      >
        <CircleCloseIcon customSize="15" />
      </Button>
    </div>
  );
}

ChipField.propTypes = {
  field: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
