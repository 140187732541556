import { forEach } from 'lodash';
import levDist from './lev-dist';

// Due to the fact that the selected sentence contains extra spaces, we're going to try to find
// the end of the sentence by backtracking from the selectedSentenceLength, looking for a period.
// If we can't find one, we'll just use the selectedSentenceLength
export const calculateIndexOfEnd = (
  content,
  indexOfStart,
  selectedSentenceLength
) => {
  const upperLimit = indexOfStart + selectedSentenceLength;
  for (let i = 0; i < 10; i++) {
    const charToCheck = upperLimit - i;
    if (content.charAt(charToCheck) === '.') {
      return charToCheck + 1; // still want to highlight the period at the end of the sentence!
    }
  }
  return upperLimit;
};

export const removeOverlaps = (ranges) => {
  const nonOverlappingIndexes = [];
  let currentStart = -1;
  let currentStop = -1;

  const sortedRanges = ranges.sort((a, b) => {
    if (a.indexOfStart > b.indexOfStart) {
      return 1;
    }
    return b.indexOfStart > a.indexOfStart ? -1 : 0;
  });

  forEach(sortedRanges, (range) => {
    if (range.indexOfStart > currentStop) {
      nonOverlappingIndexes.push({
        indexOfStart: range.indexOfStart,
        indexOfEnd: range.indexOfEnd,
      });
      currentStart = range.indexOfStart;
      currentStop = range.indexOfEnd;
    } else {
      currentStop = Math.max(currentStop, range.indexOfEnd);
      nonOverlappingIndexes[nonOverlappingIndexes.length - 1] = {
        indexOfStart: currentStart,
        indexOfEnd: currentStop,
      };
    }
  });

  return nonOverlappingIndexes;
};

export const cleanContent = (content) => {
  return content.replace(/[^a-zA-Z0-9]/g, 'a');
};

export const findStart = (modifiedSelectedSentence, modifiedContent) => {
  const sentenceLength = modifiedSelectedSentence.length;
  const newSentenceLength = Math.min(35, sentenceLength);

  const slicedSentence = modifiedSelectedSentence.slice(0, newSentenceLength);

  let bestIndex = -1;
  let bestDistance = 20;

  for (let i = 0; i < modifiedContent.length - sentenceLength; i++) {
    const newDistance = levDist(
      slicedSentence,
      modifiedContent.slice(i, i + newSentenceLength)
    );
    if (newDistance < bestDistance) {
      bestIndex = i;
      bestDistance = newDistance;
    }
    if (newDistance > 20) {
      i += 5;
    }
  }
  return bestIndex;
};

export const calculateIndexes = (content, selectedSentences) => {
  const modifiedContent = cleanContent(content);
  const indexes = [];
  forEach(selectedSentences, (selectedSentence) => {
    if (!selectedSentence) return;
    const originalSelectedSentenceLength = selectedSentence.length;
    const modifiedSelectedSentence = cleanContent(selectedSentence);
    const indexOfStart = findStart(modifiedSelectedSentence, modifiedContent);
    if (indexOfStart > -1) {
      const indexOfEnd = calculateIndexOfEnd(
        content,
        indexOfStart,
        originalSelectedSentenceLength
      );
      indexes.push({ indexOfStart, indexOfEnd });
    }
  });
  return removeOverlaps(indexes);
};
