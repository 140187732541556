/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/fixedDropDownItem.css';

const conditionallApplySelectedStyle = (isSelected) => {
  return isSelected ? styles.selected : '';
};

/**
 * @deprecated
 * It renders a div that contains a div that contains a FixedItem
 * @returns A div with a className of dropdownItem and a child div with a className of dropdownItem.
 */
const FixedDropdownItem = ({
  onMouseDown,
  onMouseOver,
  selected,
  FixedItem,
}) => {
  return (
    <div
      data-test="fixed-dropdown-item"
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
    >
      <div
        data-test="fixed-dropdown-item-inner-div"
        className={`${styles.dropdownItem} ${conditionallApplySelectedStyle(
          selected
        )}`}
      >
        <FixedItem />
      </div>
    </div>
  );
};

FixedDropdownItem.propTypes = {
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  FixedItem: PropTypes.func,
};

FixedDropdownItem.defaultProps = {
  selected: false,
  onMouseOver: () => {},
  onMouseDown: () => {},
  FixedItem: () => {},
};

export default FixedDropdownItem;
