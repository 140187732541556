import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteNewRecords } from 'client/modules/markets/redux/actions/data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';

class DeleteIconCell extends Component {
  handleDelete = (id) => () => {
    const [recordId] = id.split('-');
    this.props.deleteNewRecords(recordId);
  };

  render() {
    const { id } = this.props;
    return (
      <BaseIcon
        handleOnClick={this.handleDelete(id)}
        dataTest={id}
        iconClassName="deleteIcon"
        iconName="cancel"
        iconStyles={{ fontSize: '20px', top: '-25px', left: '-10px' }}
        iconButtonStyles={{ width: 'inherit' }}
      />
    );
  }
}

DeleteIconCell.propTypes = {
  deleteNewRecords: PropTypes.func.isRequired,
  id: PropTypes.string,
};

DeleteIconCell.defaultProps = {
  id: '',
};

export default connect(null, { deleteNewRecords })(DeleteIconCell);
