/* eslint-disable @typescript-eslint/ban-types */
import { logInfo } from 'client/modules/common/utils/logInfo';
import {
  Formik,
  FormikConfig,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
  yupToFormErrors,
} from 'formik';
import React, { ComponentType, useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import {
  getValidationErrorMessage,
  setToastError,
} from 'client/modules/third-party-admin/profile-queue/utils';

export type RejectFormHandler = {
  rejectForm?: (options?: Record<string, unknown>) => void | undefined;
};

export type CustomValuesValidation<TForm = FormikValues> = {
  customValidation?: (
    values: TForm,
    formikProps: FormikProps<FormikValues>
  ) => FormikErrors<TForm>;
};

export type FormikFormProps<
  TComplementProps,
  TForm extends Record<string, unknown> = $TSFixMe,
  TStatus extends Record<symbol, unknown> = Record<string, unknown>
> = TComplementProps &
  Omit<Partial<FormikProps<TForm>>, 'status'> &
  RejectFormHandler & { status?: TStatus };

export type RejectFormConfig = {
  onReject: <TSchema extends object>(
    formData: TSchema | unknown,
    formikProps: FormikProps<FormikValues>,
    formOptions?: Record<string, unknown>
  ) => void;
};

export type FormikOnSubmit<
  TForm,
  TStatus extends Record<symbol, unknown> = Record<string, unknown>
> = (
  values: TForm,
  formikHelpers: FormikHelpers<TForm> & {
    initialValues: TForm;
    status: TStatus;
  }
) => void | Promise<unknown>;

export type FormikConfigCustomOnSubmit = Omit<
  FormikConfig<FormikValues>,
  'onSubmit'
> & {
  onSubmit: FormikOnSubmit<FormikValues>;
};

export type FormikContainerProps<TComplementProps, TSchema extends object> = {
  Form: ComponentType<FormikFormProps<TComplementProps>>;
  optionalFormProps?: TComplementProps;
  schema?: ObjectSchema<TSchema>;
} & CustomValuesValidation &
  FormikConfigCustomOnSubmit &
  RejectFormConfig;

/**
 * @summary cast fields: use schema to coerce or cast values to the correct type
 */
export function castFormValues<TSchema extends object>(
  skema: ObjectSchema<TSchema, object>,
  formValues: FormikValues
): TSchema | FormikValues {
  try {
    return skema ? skema.cast(formValues) : formValues;
  } catch (error) {
    logInfo({
      error,
      message: `Form with id of (${formValues.id}) failed to be cleaned`,
      method: 'FormikContainer.cleanFormValues',
    });
  }

  return formValues;
}

const FormikFormEffects = ({
  formik,
}: {
  formik: FormikProps<FormikValues>;
}) => {
  useEffect(() => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      setToastError(getValidationErrorMessage('approve'));
    }
  }, [formik.submitCount, formik.isSubmitting, formik.isValid]);

  return null;
};

export const contextValidation =
  <TSchema extends object>(schema: ObjectSchema<TSchema>) =>
  (values: FormikValues) => {
    if (schema) {
      return schema
        .validate(values, { abortEarly: false, context: values })
        .then(() => ({})) // empty error object
        .catch(yupToFormErrors);
    }
    return {};
  };

export const FormikContainer = <TOptionalFormProps, TSchema extends object>({
  Form,
  optionalFormProps,
  schema,
  validateOnBlur = false,
  validateOnChange = false,
  customValidation,
  ...formikConfig
}: FormikContainerProps<TOptionalFormProps, TSchema>) => {
  const [status, setStatus] = useState<Record<string, unknown>>(undefined);
  const onSubmit =
    (initialValues) =>
    (formData: FormikValues, formHelpers: FormikHelpers<FormikValues>) => formikConfig.onSubmit(castFormValues(schema, formData), {
      ...formHelpers,
      initialValues,
      status,
      setStatus,
    });

  return (
    <Formik
      {...formikConfig}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      onSubmit={onSubmit(formikConfig.initialValues)}
      validate={contextValidation(schema)}
    >
      {(formikProps: FormikProps<Record<string, unknown>>) => {
        if (formikProps.status) {
          setStatus(formikProps.status);
        }
        return (
          <>
            <FormikFormEffects formik={formikProps} />
            <Form
              {...formikProps}
              {...optionalFormProps}
              rejectForm={(options) =>
                formikConfig.onReject(
                  castFormValues(schema, formikProps.values),
                  formikProps,
                  options
                )
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
