export const PAGEID = 60;
export const RECORDS_PER_PAGE = 15;
export const NEW_RECORDS_PER_PAGE = 1; // We need this to make sure to only request the records from BE that we can show RECORDS_PER_PAGE - NEW_RECORDS_PER_PAGE

export const PERSONS_VENDORS = { idPage: PAGEID, idPackages: [651] };
export const WORK_EXPERIENCE = 'workExperience';
export const BOARD_SEATS = 'boardSeats';
export const WORK_AND_BOARD = 'workExpAndBoardSeat';

export const DROPDOWN_LIMIT = 200;

export const ROUTES = {
  root: {
    path: 'person',
    uri: '/person',
  },
  tableView: {
    path: 'table-view',
    uri: '/person/table-view',
  },
};

export const STATUS_OPTIONS = {
  current: 1,
  former: 2,
};

export const OPTIONS = {
  status: [
    { label: 'current', value: STATUS_OPTIONS.current },
    { label: 'former', value: STATUS_OPTIONS.former },
  ],
};

export const LINKEDIN_URL = 'linkedin.com/in/';

export const dateFormat = 'MM/YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
