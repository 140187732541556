import { forEach } from 'lodash';

// Make so words will wrap mid word rather than spilling over edge
// If users do not like this we could just truncate the word and then have a tooltip
export function createRecordCellStyles(records) {
  const recordCellStyles = {};
  const style = { whiteSpace: 'normal', wordWrap: 'break-word' };
  forEach(records, (record) => {
    recordCellStyles[record.id] = {
      id: style,
      label: style,
      value: style,
    };
  });
  return recordCellStyles;
}

export const createUserRecordCellStyles = (records) => {
  const recordCellStyles = {};
  const style = { whiteSpace: 'normal', wordWrap: 'break-word' };
  forEach(records, (record) => {
    recordCellStyles[record.idUser] = {
      fullName: style,
      actionType: style,
    };
  });
  return recordCellStyles;
};
