export const UPDATE_URL_STATUS_FILTER =
  'client/modules/markets/actions/url-status-filters/UPDATE_URL_STATUS_FILTER';

export const ALL_URLS = 'All URLs';
export const BAD_URLS = 'Low Quality URLs';
export const GOOD_URLS = 'Working URLs';

export const ALL_URLS_ID = 0;
export const BAD_URLS_ID = 1;
export const GOOD_URLS_ID = 2;

export function updateSelectedUrlStatusFilter(selectedUrlStatusFilterId) {
  return { type: UPDATE_URL_STATUS_FILTER, selectedUrlStatusFilterId };
}
