import { keyBy, omit } from 'lodash';
import * as tabName from 'client/modules/markets/utils/tab-names';
import {
  getKeyValueFromListViaOtherKey,
  updateObjectInListWithKeyValue,
} from 'client/modules/common/utils/objectHelper';
import * as actions from '../actions/data';
import * as searchBarActions from '../actions/search-bar';
import {
  newEmptyRecord,
  updateSelectionStateOfAllRecords,
  checkIfAllRecordsSelected,
  addEmptyRecord,
  saveOriginalRecords,
  saveRecordsOrder,
  initializeVisibility,
  updateShowHiddenRowDict,
  saveInnerTableRecords,
} from '../utils/records-helper';
import {
  updateMarketSuggestions,
  updateSearchInfo,
} from '../utils/market-search-helper';
import {
  mapMarketSizingDetailResponse,
  mapRelatedMarketsResponse,
  mapListOfMarketsResponse,
  mapMarketSizingDetailInnerTableResponse,
} from '../utils/api-response-helper';

const initialState = {
  [tabName.MARKET_SIZING_DETAILS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    newRecords: { [Date.now()]: newEmptyRecord },
    recordsOrder: [],
    innerTable: {},
    showHiddenRowDict: {},
  },
  [tabName.RELATED_MARKETS_DETAILS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    searchBox: {
      marketSuggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    recordsOrder: [],
  },
  [tabName.LIST_OF_MARKETS_DETAILS]: {
    records: {},
    selectedRecords: {},
    originalRecords: {},
    recordsOrder: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case searchBarActions.SET_MARKET_SIZING_DETAILS: {
      const records = mapMarketSizingDetailResponse(action.payload.rows);
      return {
        ...state,
        [tabName.MARKET_SIZING_DETAILS]: {
          ...state[tabName.MARKET_SIZING_DETAILS],
          originalRecords: keyBy(records, 'id'),
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
          showHiddenRowDict: initializeVisibility(records),
        },
      };
    }
    case searchBarActions.SET_RELATED_MARKETS_DETAILS: {
      const records = mapRelatedMarketsResponse(action.payload.rows);
      return {
        ...state,
        [tabName.RELATED_MARKETS_DETAILS]: {
          ...state[tabName.RELATED_MARKETS_DETAILS],
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
        },
      };
    }
    case searchBarActions.SET_LIST_OF_MARKETS_DETAILS: {
      const records = mapListOfMarketsResponse(action.payload.rows);
      return {
        ...state,
        [tabName.LIST_OF_MARKETS_DETAILS]: {
          ...state[tabName.LIST_OF_MARKETS_DETAILS],
          allRecordsSelected: false,
          records: keyBy(records, 'id'),
          recordsOrder: saveRecordsOrder(records),
          selectedRecords: updateSelectionStateOfAllRecords(records, false),
        },
      };
    }
    case actions.UPDATE_RECORD: {
      const currentTabData = state[action.currentTab];
      return {
        ...state,
        [action.currentTab]: {
          ...currentTabData,
          records: updateObjectInListWithKeyValue({
            objects: currentTabData.records,
            id: action.recordId,
            key: action.fieldName,
            value: action.newValue,
          }),
          originalRecords: saveOriginalRecords(
            currentTabData.originalRecords,
            action.recordId,
            currentTabData.records[action.recordId]
          ),
        },
      };
    }
    case actions.SELECT_ALL_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords: updateSelectionStateOfAllRecords(
            state[action.currentTab].records,
            true
          ),
          allRecordsSelected: true,
        },
      };
    case actions.UNSELECT_ALL_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords: updateSelectionStateOfAllRecords(
            state[action.currentTab].records,
            false
          ),
          allRecordsSelected: false,
        },
      };
    case actions.SELECT_RECORD: {
      const selectedRecords = {
        ...state[action.currentTab].selectedRecords,
        [action.recordId]: !state[action.currentTab].selectedRecords[
          action.recordId
        ],
      };
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords,
          allRecordsSelected: checkIfAllRecordsSelected(selectedRecords),
        },
      };
    }
    case actions.ADD_NEW_RECORD_LINE:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: addEmptyRecord(state[action.currentTab].newRecords),
        },
      };
    case actions.UPDATE_NEW_RECORD:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: updateObjectInListWithKeyValue({
            objects: state[action.currentTab].newRecords,
            id: action.newRecordId,
            key: action.fieldName,
            value: action.newValue,
          }),
        },
      };
    case actions.CLEAR_NEW_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: { [Date.now()]: newEmptyRecord },
        },
      };
    case actions.DELETE_NEW_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          newRecords: omit(state[action.currentTab].newRecords, action.id),
        },
      };
    case actions.ADD_RELATED_MARKET:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: { ...state[action.currentTab].records, action },
        },
      };
    case actions.UPDATE_RELATED_MARKETS_SUGGESTIONS: {
      let searchId = getKeyValueFromListViaOtherKey(
        action.markets,
        'name',
        action.searchTerm,
        'id'
      );
      searchId = parseInt(searchId, 10) || 0;
      return {
        ...state,
        [tabName.RELATED_MARKETS_DETAILS]: {
          ...state[tabName.RELATED_MARKETS_DETAILS],
          searchBox: updateMarketSuggestions(
            state[tabName.RELATED_MARKETS_DETAILS].searchBox,
            action.markets,
            searchId
          ),
        },
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      return {
        ...state,
        [tabName.RELATED_MARKETS_DETAILS]: {
          ...state[tabName.RELATED_MARKETS_DETAILS],
          searchBox: updateSearchInfo(
            state[tabName.RELATED_MARKETS_DETAILS].searchBox,
            action.searchTerm,
            action.searchId
          ),
        },
      };
    }
    case actions.TOGGLE_INNER_TABLE: {
      return {
        ...state,
        [tabName.MARKET_SIZING_DETAILS]: {
          ...state[tabName.MARKET_SIZING_DETAILS],
          showHiddenRowDict: updateShowHiddenRowDict(
            state[tabName.MARKET_SIZING_DETAILS].showHiddenRowDict,
            action.recordId
          ),
        },
      };
    }
    case actions.SET_DUPLICATE_RECORDS: {
      const currentTabData = state[action.currentTab];
      if (action.innerTableRecords.length < 1) {
        return {
          ...state,
          [action.currentTab]: {
            ...currentTabData,
            showHiddenRowDict: {
              ...currentTabData.showHiddenRowDict,
              [action.record.id]: false,
            },
            records: {
              ...currentTabData.records,
              [action.record.id]: {
                ...currentTabData.records[action.record.id],
                dupeCount: parseInt(action.dupeCount, 10),
              },
            },
          },
        };
      }
      const parentRecord = currentTabData.records[action.record.id];
      const formattedInnerTableRecords = mapMarketSizingDetailInnerTableResponse(
        parentRecord,
        action.innerTableRecords,
        action.record.id
      );
      return {
        ...state,
        [action.currentTab]: {
          ...currentTabData,
          records: {
            ...currentTabData.records,
            [action.record.id]: {
              ...currentTabData.records[action.record.id],
              dupeCount: parseInt(action.dupeCount, 10),
            },
          },
          innerTable: saveInnerTableRecords(
            currentTabData.innerTable,
            formattedInnerTableRecords,
            action.record.id
          ),
        },
      };
    }
    default:
      return state;
  }
}
