/* eslint-disable no-unused-vars */
import React from 'react';
import takeRight from 'lodash/takeRight';
import moment from 'moment';
import { generateLinksById } from 'client/modules/common/utils/generateLinksById';
import { Description } from 'client/modules/service-provider/components/EntityInfoHeader';
import ErrorBoundary from 'client/modules/common/components/FormFieldErrorBoundary';
import { get } from 'lodash';

// TODO hook up with api
const openInvestorProfile = () => {};

const EntitySection = ({ entities = [], rounds = {}, handleRemoveEntity }) => {
  const getLatestRounds = (id) => {
    return takeRight(rounds[id], 2).reverse();
  };

  if (!entities.length) {
    return null;
  }

  return entities.map((entity) => {
    const { id, url, description, address, idEntityDraft } = entity;
    const roundStatus = get(rounds, `${id}[0].status.label`, '(Not Found)');
    const removeEntityFromCluster = () => {
      handleRemoveEntity(entity);
    };

    return (
      <ErrorBoundary key={id}>
        <div className="mb-4 flex flex-col text-sm border-b border-solid border-gray-400">
          <div className="flex flex-row">
            <span data-test="entity-name" className="font-bold pr-2">
              {generateLinksById([{ ...entity, text: entity.name }])}
            </span>
            <a
              className="text-blue-500 underline pl-1 mt-1"
              href={`//${url}`}
              target="_blank"
              rel="noreferrer"
            >
              {url}
            </a>
            <span className="pl-1 mt-1">{address?.countryInfo?.name}</span>
          </div>
          <div className="py-2 text-sm">
            <Description
              description={description || get(rounds, `[${id}][0].description`)}
            />
          </div>
          <div className="py-2 flex flex-col">
            <span className="font-bold">Status</span>
            {roundStatus}
          </div>
          <div className="py-2 flex flex-col">
            <span className="font-bold pb-2">Latest Funding Rounds</span>
            <div className="flex flex-col justify-start">
              {getLatestRounds(id !== 0 ? id : idEntityDraft).map(
                ({
                  label,
                  amount,
                  investors,
                  date,
                  value,
                  maxValuation,
                  minValuation,
                  isDraft,
                }) => {
                  return (
                    <React.Fragment key={value}>
                      <div className="flex flex-row">
                        <span>{label}</span>
                        {amount > 0 && (
                          <span>
                            :<span className="pl-1">${amount.toFixed(2)}M</span>
                          </span>
                        )}
                        {isDraft && (
                          <span className="pl-3 font-bold">(Draft)</span>
                        )}
                      </div>
                      <div>
                        {maxValuation > 0 && (
                          <span>
                            Max Valuation:{' '}
                            <span className="pl-1">
                              ${maxValuation.toFixed(2)}M
                            </span>
                          </span>
                        )}
                      </div>
                      <div>
                        {minValuation > 0 && (
                          <span>
                            Min Valuation:{' '}
                            <span className="pl-1">
                              ${minValuation.toFixed(2)}M
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="flex justify-start">
                        {generateLinksById(
                          investors.map((inv) => ({ ...inv, text: inv.name }))
                        )}
                      </div>
                      <div className="flex flex-row pt-1 pb-2">
                        <span>Submitted:</span>&nbsp;
                        <span>{moment(date).format('MM/DD/YYYY')}</span>
                      </div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
          <div className="py-2">
            <button
              data-test="remove-entity"
              type="button"
              className="text-red-500 font-bold"
              onClick={removeEntityFromCluster}
            >
              Remove Entity
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );
  });
};

export default EntitySection;
