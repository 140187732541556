import React from 'react';
import { Col, Row, Spin, Switch, Typography } from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'client/utils/copy';
import UTCTooltip from 'client/utils/dates';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';
import { useGetFederatedTeam } from '../../services/hooks/useGetFederatedTeam';
import { teamPermissions } from '../../permissions/Team';

type Props = {
  idTeam: number;
};

const SSODetails = ({ idTeam }: Props) => {
  const { data: adminPermissions } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    adminPermissions,
    teamPermissions.pageId,
    teamPermissions.sections[3].sectionId
  );

  const hasSSOPermission = section.permissionTypes.length > 0;

  const { data: federatedTeam, isLoading } = useGetFederatedTeam({
    enabled: true,
    params: { id_team: idTeam },
  });

  if (!hasSSOPermission) {
    return (
      <div className="flex justify-center">
        Sorry, but it looks like you are unauthorized to view this page
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!federatedTeam || !federatedTeam.team) {
    return <div className="flex justify-center">No SSO Settings</div>;
  }

  /* FederatedTeam date types are wrong   */
  const createdTime = (
    federatedTeam.team.insert_time as unknown as { seconds: number }
  )?.seconds;
  const updatedTime = (
    federatedTeam.team.update_time as unknown as { seconds: number }
  )?.seconds;

  const createdDate = createdTime
    ? moment.unix(createdTime).format('HH:mm:ss MM/DD/YYYY')
    : '';
  const updatedDate = updatedTime
    ? moment.unix(updatedTime).format('HH:mm:ss MM/DD/YYYY')
    : '';

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        lg={{ span: 18 }}
        xxl={{ span: 14 }}
        md={{ span: 20 }}
      >
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Enabled
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <Switch checked={federatedTeam.team.is_enabled} disabled />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Pool ID
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <span className="mr-1">{federatedTeam.team.pool_id}</span>
            <CopyToClipboard text={federatedTeam.team.pool_id} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Pool Name
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <span className="mr-1">{federatedTeam.team.pool_name}</span>
            <CopyToClipboard text={federatedTeam.team.pool_name} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              IDP
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <span className="mr-1">{federatedTeam.team.idp}</span>
            <CopyToClipboard text={federatedTeam.team.idp} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Hosted URL
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <span className="mr-1">{federatedTeam.team.hosted_url}</span>
            <CopyToClipboard text={federatedTeam.team.hosted_url} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              App Client
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <span className="mr-1">{federatedTeam.team.app_client}</span>
            <CopyToClipboard text={federatedTeam.team.app_client} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Creation Date
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <UTCTooltip date={createdDate} />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span="12">
            <Typography.Title level={5} className="!font-medium">
              Last Modified Date
            </Typography.Title>
          </Col>
          <Col span="12" className="text-right">
            <UTCTooltip date={updatedDate} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SSODetails;
