import {
  GetTeamInvitesRequest,
  GetTeamInvitesResponse,
} from '@cbinsights/usermanagementservice/usermanagementservice';
import { request } from 'client/modules/common/utils/request';

export const getTeamInvites = (
  req: Partial<GetTeamInvitesRequest>
): Promise<GetTeamInvitesResponse> =>
  request({
    url: '/service/usermanagementservice/GetTeamInvites',
    body: req,
  }).then((r) => r.body);
