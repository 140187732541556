// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entity-search-container {
  display: flex;
  flex-direction: column;
}

.entity-search.ant-select
  .ant-select-selection-item-content
  .entity-description {
  display: none;
}
.search-icon {
  top: 6px;
  right: 9px;
  font-size: 15pt;
  color: #bfbfbf !important;
}
`, "",{"version":3,"sources":["webpack://./client/modules/cbi-entity/components/cbi-entity/EntitySearch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;;EAIE,aAAa;AACf;AACA;EACE,QAAQ;EACR,UAAU;EACV,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[":global .entity-search-container {\n  display: flex;\n  flex-direction: column;\n}\n\n:global\n  .entity-search.ant-select\n  .ant-select-selection-item-content\n  .entity-description {\n  display: none;\n}\n:global .search-icon {\n  top: 6px;\n  right: 9px;\n  font-size: 15pt;\n  color: #bfbfbf !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
