const styles = (theme) => ({
  root: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  primary: {
    color: theme.palette.primary.main, // blue
  },
  secondary: {
    color: theme.palette.secondary.main, // red
  },
  blue: {
    color: 'blue',
  },
});

export default styles;
