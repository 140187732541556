import React from 'react';
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadProps, message } from 'antd';
import Papa from 'papaparse';
import {
  GetPortalsResponse,
  ListPackagesResponse,
  Portal,
} from '@cbinsights/userv2service/userv2service';
import { CBI_PORTAL_NAME } from 'client/modules/users/services/hooks/useGetPortals';
import { DataType } from '../../hooks/useAddNewUsers';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore: Valid file import
import csvUserTeamTemplate from '../../constants/import-user-team-template.csv';
// @ts-ignore: Valid file import
import csvUserTemplate from '../../constants/import-user-template.csv';
import useGetTeamQuery from '../../services/hooks/useGetTeamQuery';
/* eslint-enable @typescript-eslint/ban-ts-comment */

type Props = {
  addUsers: (users: DataType[]) => void;
  teamId: number;
  packages: ListPackagesResponse;
  portals: GetPortalsResponse;
};

type CsvRecord = {
  company: string;
  email: string;
  firstname: string;
  lastname: string;
  portal: string;
  package: string;
};

const UploadUsers = ({ addUsers, teamId, packages, portals }: Props) => {
  const template = {
    fileName: teamId
      ? 'import-users-team-template.csv'
      : 'import-users-template.csv',
    blob: teamId ? csvUserTeamTemplate : csvUserTemplate,
  };

  const { data: teamDetail } = useGetTeamQuery({
    enabled: false,
    params: { id_team: Number(teamId) },
  });

  const downloadTemplate = () => {
    const url = window.URL.createObjectURL(new Blob([template.blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', template.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const beforeUpload = (file) => {
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      message.error('You can only upload CSV files!');
    }
    return isCSV;
  };

  const handleCustomRequest: UploadProps['customRequest'] = ({
    file,
    onSuccess,
    onError,
  }) => {
    Papa.parse(file as string, {
      header: true,
      complete: (result) => {
        onSuccess(result.data);
      },
      error: () => {
        onError(null);
      },
    });
  };

  const handleFileUpload = (info) => {
    const { status } = info.file;

    if (status === 'done') {
      const result = info.file.response as CsvRecord[];

      if (result.length > 500) {
        message.error('Exceeded maximum record limit of 500');
        return;
      }

      const emails = {};

      let key = 1;

      const users: DataType[] = result.map((entry) => {
        let recordStatus = null;
        let error = null;

        if (entry.email && !entry.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          recordStatus = 'validation-error';
          error = 'Invalid email';
        }

        if (emails[entry.email]) {
          recordStatus = 'validation-error';
          error = 'Duplicated email';
        } else {
          emails[entry.email] = true;
        }

        if (
          teamDetail?.signup_code?.domain_whitelist?.length > 0 &&
          entry.email
        ) {
          const domainWhitelist = teamDetail.signup_code.domain_whitelist;

          const emailDomain = entry.email.split('@')[1];
          if (emailDomain && !domainWhitelist.includes(emailDomain)) {
            recordStatus = 'validation-error';
            error = 'Email domain is not allowed';
          }
        }

        let packageId = null;
        let portalId = null;
        let portalDetail: Portal = null;

        if (!teamId) {
          if (entry.portal) {
            portalDetail = portals.portals.find(
              (portalEntry) =>
                portalEntry.name.toLowerCase() === entry.portal.toLowerCase()
            );
            portalId = portalDetail?.id_portal;
            if (!portalId)
              message.error(`Portal '${entry.package}' does not exist.`);
          }

          if (entry.package) {
            const portalPackage = packages?.packages?.find(
              (packageDetail) =>
                packageDetail.id_package === portalDetail.id_package
            );

            if (
              entry.portal &&
              portalDetail.name !== CBI_PORTAL_NAME &&
              portalPackage
            ) {
              packageId = portalDetail.id_package;
            } else {
              packageId = packages?.packages?.find(
                (packageDetail) =>
                  packageDetail.name.toLowerCase() ===
                  entry.package.toLowerCase()
              )?.id_package;
            }
            if (!packageId)
              message.error(`Package '${entry.package}' does not exist.`);
          }
        }

        return {
          key: ++key,
          company: entry.company,
          email: entry.email,
          firstName: entry.firstname,
          lastName: entry.lastname,
          status: recordStatus,
          package: packageId,
          portal: portalId,
          error,
        };
      });

      addUsers(users);
      message.success(
        `${info.file.name} file uploaded successfully. Please review and then Submit the form`
      );
    } else if (status === 'error') {
      message.error(
        `${info.file.name} file upload failed. Please review the file and try again`
      );
    }
  };

  return (
    <div>
      <Upload
        name="file"
        showUploadList={false}
        onChange={handleFileUpload}
        customRequest={handleCustomRequest}
        beforeUpload={beforeUpload}
      >
        <Button icon={<CloudUploadOutlined />}>Import Users from CSV</Button>
      </Upload>
      <Button
        className="ml-2"
        icon={<DownloadOutlined />}
        onClick={downloadTemplate}
      >
        Download Import Template
      </Button>
    </div>
  );
};

export default UploadUsers;
