const styles = (theme) => ({
  text: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#000',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  root: {
    marginLeft: '10px',
  },
  textBlock: {
    paddingTop: '10px',
  },
  wrap: {
    overflowWrap: 'break-word',
  },
});

export default styles;
