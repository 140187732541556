import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import apiMiddleware from 'client/modules/common/redux/middleware/apiMiddleware';
import serviceMiddleware from 'client/modules/common/redux/middleware/serviceMiddleware';

const middleware = [apiMiddleware, serviceMiddleware, thunkMiddleware];

const enhancer = applyMiddleware(...middleware);

function configureProdStore(reducer, initialState) {
  const store = createStore(reducer, initialState, enhancer);
  window.store = store;

  return store;
}

export { configureProdStore };
