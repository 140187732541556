import { MARKET_SUGGESTION_HANDLER } from 'client/modules/common/components/Table/utils/Constants';

/**
 * 'includes' contains the field need to display in one column
 * -- 'type' is the field type
 * -- 'key' is associated to the records field key in the store, so it can be rendered automaticlly
 */
export const marketSizingTableColumnNames = [
  {
    name: '',
    width: '5px',
    key: 'optionalRowBelow',
    includes: [
      { type: 'empty', key: 'empty', height: '150px' }, // height should === row's height
      {
        type: 'markets-articleContent',
        key: 'articleContent',
        contentWidth: '1180px', // contentWidth should = ~row's width
      },
    ],
  },
  {
    name: '',
    width: '30px',
    key: '',
    includes: [{ type: 'checkbox', key: 'id' }],
  },
  {
    name: 'Market Name',
    key: 'marketName',
    sort: true,
    sortKey: 'market',
    required: true,
    width: '100px',
    includes: [
      {
        type: 'autocompleteInput',
        key: 'market',
        placeholder: 'Search Market...',
        showInputAdornment: true,
      },
      { type: 'markets-recordId', key: 'recordId' },
      {
        type: 'markets-marketDescription',
        key: 'description',
        placeholder: 'Enter market description found in news article',
        numberOfRows: 4,
        height: '80px',
      },
      { type: 'markets-duplicateCount', key: 'duplicateCount' },
    ],
    autocompleteOptions: {
      autoInputWidth: 120,
      suggestionType: 'market',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'name',
    },
  },
  {
    name: 'News Article',
    key: 'newsArticle',
    sort: true,
    sortKey: 'link',
    required: true,
    width: '120px',
    includes: [
      {
        type: 'markets-articleLink',
        key: 'articleLink',
        placeholder: 'News article URL',
        showInputAdornment: true,
      },
      {
        type: 'textarea',
        key: 'sentence',
        placeholder:
          'Enter the sentence in the news article that contains market sizing information',
        resize: 'both',
        textAreaWidth: '540px',
      },
    ],
  },
  {
    name: 'Start Market Size',
    key: 'startSize',
    sort: true,
    sortKey: 'start_size',
    required: false,
    width: '85px',
    includes: [{ type: 'input', key: 'startSize' }],
  },
  {
    name: 'Start Forecast Year',
    key: 'startYear',
    sort: true,
    sortKey: 'start_year',
    required: false,
    width: '80px',
    includes: [{ type: 'input', key: 'startYear', placeholder: 'Start year' }],
  },
  {
    name: 'End Market Size',
    key: 'endSize',
    sort: true,
    sortKey: 'end_size',
    required: true,
    width: '80px',
    includes: [{ type: 'input', key: 'endSize' }],
  },
  {
    name: 'End Forecast Year',
    key: 'endYear',
    sort: true,
    sortKey: 'end_year',
    required: true,
    width: '80px',
    includes: [{ type: 'input', key: 'endYear', placeholder: 'End year' }],
  },
  {
    name: 'CAGR (%)',
    key: 'cagr',
    sort: true,
    sortKey: 'cagr',
    width: '50px',
    includes: [{ type: 'input', key: 'cagr' }],
  },
  {
    name: 'Geography',
    key: 'geo',
    sort: true,
    sortKey: 'geo',
    width: '60px',
    includes: [
      {
        type: 'autocompleteInput',
        key: 'geography',
        placeholder: 'Search Geo...',
      },
    ],
    autocompleteOptions: {
      autoInputWidth: 93,
      suggestionType: 'geography',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'geo',
    },
  },
  {
    name: 'Analyst',
    key: 'analyst',
    sort: true,
    sortKey: 'analyst',
    width: '60px',
    required: false,
    includes: [
      {
        type: 'autocompleteInput',
        key: 'analyst',
        placeholder: 'Search Analyst...',
      },
    ],
    autocompleteOptions: {
      autoInputWidth: 100,
      suggestionType: 'analyst',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'analyst',
    },
  },
  {
    name: 'News Date',
    key: 'newsDate',
    sort: true,
    sortKey: 'ts_news',
    required: true,
    width: '70px',
    includes: [{ type: 'date', key: 'newsDate' }],
  },
  {
    name: 'History',
    key: 'history',
    sort: true,
    sortKey: 'ts_update',
    width: '80px',
    includes: [
      { type: 'markets-reviewer', key: 'reviewer' },
      { type: 'markets-reviewerNote', key: 'reviewerNote', height: '15px' },
      { type: 'markets-actionIcons', key: 'actionIcons' },
    ],
  },
];

export const marketSizingModalColumnNames = [
  {
    name: '',
    key: '',
    width: '20px',
    includes: [{ type: 'markets-deleteIcon', key: 'id' }],
  },
  {
    name: 'Market Name',
    key: 'marketName',
    type: 'autocompleteInput',
    width: '130px',
    required: true,
    includes: [
      {
        type: 'autocompleteInput',
        key: 'market',
        placeholder: 'Search Market...',
      },
    ],
    autocompleteOptions: {
      autoInputWidth: 130,
      suggestionType: 'market',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'name',
      showFixedItemAtTheTop: true,
      fixedItemText: '+ Create New Market',
    },
  },
  {
    name: 'Market Description',
    key: 'marketDescription',
    width: '120px',
    required: false,
    includes: [
      {
        type: 'textarea',
        key: 'description',
        placeholder: 'Enter market description found in news article',
      },
    ],
  },
  {
    name: 'News Article Link',
    key: 'articleLink',
    width: '120px',
    required: true,
    includes: [
      {
        type: 'articleLink',
        key: 'articleLink',
        placeholder: 'News article URL',
      },
    ],
  },
  {
    name: 'News Sentence',
    key: 'newsSentence',
    width: '140px',
    required: true,
    includes: [
      {
        type: 'textarea',
        key: 'sentence',
        placeholder:
          'Enter the sentence in the news article that contains market sizing information',
      },
    ],
  },
  {
    name: 'Start Market Size',
    key: 'startSize',
    width: '50px',
    required: false,
    includes: [{ type: 'input', key: 'startSize' }],
  },
  {
    name: 'Start Forecast Year',
    key: 'startYear',
    width: '50px',
    required: false,
    includes: [{ type: 'input', key: 'startYear', placeholder: 'Start yr.' }],
  },
  {
    name: 'End Market Size',
    key: 'endSize',
    width: '50px',
    required: true,
    includes: [{ type: 'input', key: 'endSize' }],
  },
  {
    name: 'End Forecast Year',
    key: 'endYear',
    width: '50px',
    required: true,
    includes: [{ type: 'input', key: 'endYear', placeholder: 'End yr.' }],
  },
  {
    name: 'CAGR (%)',
    key: 'cagr',
    width: '50px',
    required: false,
    includes: [{ type: 'input', key: 'cagr' }],
  },
  {
    name: 'Geography',
    key: 'geo',
    type: 'autocompleteInput',
    width: '60px',
    required: false,
    includes: [
      {
        type: 'autocompleteInput',
        key: 'geography',
        placeholder: 'Search Geo...',
      },
    ],
    autocompleteOptions: {
      autoInputWidth: 60,
      suggestionType: 'geography',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'geo',
      showFixedItemAtTheTop: true,
      fixedItemText: '+ Create New Geo',
    },
  },
  {
    name: 'Analyst',
    key: 'analyst',
    type: 'autocompleteInput',
    width: '60px',
    required: false,
    includes: [
      {
        type: 'autocompleteInput',
        key: 'analyst',
        placeholder: 'Search Analyst...',
      },
    ],
    autocompleteOptions: {
      autoInputWidth: 60,
      suggestionType: 'analyst',
      suggestionHandler: MARKET_SUGGESTION_HANDLER,
      filterFieldName: 'analyst',
      showFixedItemAtTheTop: true,
      fixedItemText: '+ Create New Analyst',
    },
  },
  {
    name: 'Publication Date',
    key: 'publicationDate',
    width: '123px',
    required: true,
    includes: [{ type: 'date', key: 'newsDate' }],
  },
];

export const hiddenRowColumns = [
  {
    name: '',
    width: '0px',
    key: 'markets-innerTable',
    includes: [{ type: 'markets-innerTable', key: 'markets-innerTable' }],
  },
];

export const marketSizingInnerTableColumnNames = [
  {
    name: '',
    width: '0px',
    key: 'optionalRowBelow',
    includes: [
      { type: 'empty', key: 'empty', height: '150px' }, // height should === row's height
      {
        type: 'markets-articleContent',
        key: 'articleContent',
        contentWidth: '800px', // contentWidth should = ~row's width
      },
    ],
  },
  {
    name: 'Market Name',
    key: 'marketName',
    width: '130px',
    includes: [
      { type: 'markets-marketNameInnerTable', key: 'marketNameInnerTable' },
    ],
  },
  {
    name: 'News Article',
    key: 'newsArticle',
    width: '160px',
    includes: [
      {
        type: 'markets-articleLinkInnerTable',
        key: 'articleLink',
        showInputAdornment: true,
      },
      {
        type: 'markets-articleSentenceInnerTable',
        key: 'newsArticleInnerTable',
      },
    ],
  },
  {
    name: 'Start Market Size',
    key: 'startSize',
    width: '60px',
    includes: [{ type: 'markets-innerTableStartSize', key: 'startSize' }],
  },
  {
    name: 'Start Forecast Year',
    key: 'startYear',
    width: '60px',
    includes: [{ type: 'markets-innerTableStartYear', key: 'startYear' }],
  },
  {
    name: 'End Market Size',
    key: 'endSize',
    width: '60px',
    includes: [{ type: 'markets-innerTableEndSize', key: 'endSize' }],
  },
  {
    name: 'End Forecast Year',
    key: 'endYear',
    width: '60px',
    includes: [{ type: 'markets-innerTableEndYear', key: 'endYear' }],
  },
  {
    name: 'CAGR (%)',
    key: 'cagr',
    width: '55px',
    includes: [{ type: 'text', key: 'cagr' }],
  },
  {
    name: 'Geography',
    key: 'geo',
    width: '88px',
    includes: [{ type: 'text', key: 'geography' }],
  },
  {
    name: 'Analyst',
    key: 'analyst',
    width: '80px',
    includes: [{ type: 'text', key: 'analyst' }],
  },
  {
    name: 'News Date',
    key: 'newsDate',
    width: '110px',
    includes: [{ type: 'markets-readOnlyDate', key: 'newsDate' }],
  },
  {
    name: 'History',
    key: 'history',
    width: '113px',
    includes: [{ type: 'markets-innerTableHistory', key: 'innerTableHistory' }],
  },
];

export default marketSizingTableColumnNames;
