import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'client/component-library/styles';
import styles from './styles/material-ui/linkStyles';

/**
 * @deprecated
 * It takes in props, destructures the props, and returns an anchor tag with the root class and the
 * color class
 * @param props - This is the props object that is passed to the component.
 * @returns A link with the root class and the color class.
 */
function MaterialUILink(props) {
  const { children, classes, color, ...rest } = props;

  return (
    <a className={`${classes.root} ${classes[color]}`} {...rest}>
      {children}
    </a>
  );
}

MaterialUILink.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'blue']),
};

MaterialUILink.defaultProps = {
  color: 'primary',
};
/** @deprecated */
export default withStyles(styles)(MaterialUILink);
