// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-component-library-modals-MaterialUIModal-___modal__close-icon___X4Ono {
  position: absolute !important;
  right: 30px;
  top: 20px;
  border: 1px solid grey !important;
  height: 30px !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  width: 30px !important;
}
`, "",{"version":3,"sources":["webpack://./client/component-library/modals/MaterialUIModal/modal.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,WAAW;EACX,SAAS;EACT,iCAAiC;EACjC,uBAAuB;EACvB,6BAA6B;EAC7B,kCAAkC;EAClC,qCAAqC;EACrC,sBAAsB;AACxB","sourcesContent":[".close-icon {\n  position: absolute !important;\n  right: 30px;\n  top: 20px;\n  border: 1px solid grey !important;\n  height: 30px !important;\n  border-radius: 50% !important;\n  -moz-border-radius: 50% !important;\n  -webkit-border-radius: 50% !important;\n  width: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close-icon": `client-component-library-modals-MaterialUIModal-___modal__close-icon___X4Ono`
};
export default ___CSS_LOADER_EXPORT___;
