import { SetTeamTypeRequest_TeamType } from '@cbinsights/userv2service/userv2service';
import { Switch, message } from 'antd';
import React from 'react';
import useSetTeamType from '../../hooks/useSetTeamType';

type Props = {
  teamType: string;
  teamId: number;
  refetchTeam: () => void;
};

const CopilotTeam = ({ teamType, teamId, refetchTeam }: Props) => {
  const isCopilotEnabled = teamType === SetTeamTypeRequest_TeamType.COPILOT;

  const { setCopilotTeam, setRegularTeam, isLoading } = useSetTeamType({
    teamId,
  });

  const onChange = (checked: boolean) => {
    const request = checked ? setCopilotTeam() : setRegularTeam();
    request
      .then(() => {
        message.success(`Success! The team's type has been updated`);
        refetchTeam();
      })
      .catch(() => {
        message.success(
          `Error! Failed to update the team's type. Please try again later`
        );
      });
  };

  return (
    <Switch
      checked={isCopilotEnabled}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default CopilotTeam;
