import {
  PostCompanyLogoRequest,
  PostLogoResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const readBlobFile = (file: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
      const bytes = new Uint8Array(arrayBuffer);
      resolve(bytes);
    };
  });

export const postCompanyLogo = (
  req: Partial<PostCompanyLogoRequest>
): Promise<PostLogoResponse> => {
  return request({
    url: '/service/logoservice/PostCompanyLogo',
    body: { ...req },
  }).then((r): PostLogoResponse => r.body);
};
