import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from 'client/component-library/Table';
import { withStyles } from 'client/component-library/styles';

import mapStoriesTableHeader from 'client/modules/stories/components/tables/utils/map-stories-table-header';
import CheckboxCell from './CellComponents/CheckboxCell';
import customStyles from '../styles/css/table-header.css';
import tableHeaderStyles from '../styles/material-ui/tableHeaderStyles';

const mapCustomTableHeader = (type, columnName, classes, outerTableRowId) => {
  const storiesHeaderComponent = mapStoriesTableHeader(
    type,
    columnName,
    classes,
    outerTableRowId
  );
  if (storiesHeaderComponent) {
    return storiesHeaderComponent;
  }
  return null;
};

/**
 * @deprecated
 * It renders a table header with a checkbox, a sortable label, or a label
 * @param props - {
 * @returns A table header with a checkbox and a table sort label.
 */
const TableHeader = (props) => {
  const {
    checked,
    columnNames,
    onSelectAllClick,
    classes,
    sortField,
    sortDirection,
    onSortClick,
    editingDisabled,
    outerTableRowId,
    tHeaderStyles,
  } = props;
  return (
    <TableHead style={tHeaderStyles}>
      <TableRow onChange={onSelectAllClick}>
        {map(columnNames, (columnName) => {
          switch (columnName.includes[0].type) {
            case 'checkbox':
              return (
                <TableCell
                  key={columnName.key}
                  classes={{ tableCell: classes.tableCell }}
                  width={columnName.headerWidth || columnName.width}
                >
                  <CheckboxCell
                    key="header"
                    checked={checked}
                    id="all"
                    disabled={editingDisabled}
                  />
                </TableCell>
              );
            default: {
              const customTableHeader = mapCustomTableHeader(
                columnName.includes[0].type,
                columnName,
                classes,
                outerTableRowId
              );

              if (customTableHeader) return customTableHeader;
              if (columnName.sort) {
                const direction =
                  columnName.sortKey === sortField ? sortDirection : 'desc';
                return (
                  <TableCell
                    classes={{ tableCell: classes.tableCell }}
                    width={columnName.headerWidth || columnName.width}
                    key={columnName.key}
                  >
                    <TableSortLabel
                      active
                      direction={direction}
                      onClick={onSortClick(
                        columnName.sortKey,
                        direction === 'asc' ? 'desc' : 'asc'
                      )}
                      classes={{
                        active: classes.active,
                        icon: classes.icon,
                      }}
                      className={
                        columnName.sortKey === sortField
                          ? customStyles.active
                          : customStyles.inactive
                      }
                    >
                      <div className={classes.label}>
                        {columnName.name}
                        <span className={classes.required}>
                          {' '}
                          {columnName.required ? '*' : ''}
                        </span>
                      </div>
                    </TableSortLabel>
                  </TableCell>
                );
              }
              return (
                <TableCell
                  classes={{ tableCell: classes.tableCell }}
                  width={columnName.width}
                  key={columnName.key}
                >
                  <div className={classes.label}>
                    {columnName.name}
                    <span className={classes.required}>
                      {' '}
                      {columnName.required ? '*' : ''}
                    </span>
                  </div>
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  editingDisabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.object),
  onSelectAllClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortClick: PropTypes.func,
  outerTableRowId: PropTypes.string,
  tHeaderStyles: PropTypes.object,
};

TableHeader.defaultProps = {
  editingDisabled: false,
  columnNames: [],
  checked: false,
  sortField: '',
  sortDirection: 'desc',
  onSortClick: () => {},
  outerTableRowId: '',
  tHeaderStyles: {},
};

/**
 * @deprecated */
export default withStyles(tableHeaderStyles)(TableHeader);
