import {
  AllUserInfo,
  SearchByEmailRequest,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const getUser = (
  req: Partial<SearchByEmailRequest>
): Promise<AllUserInfo> =>
  request({
    url: '/service/userv2service/SearchForUsersByEmail',
    body: req,
  }).then((r) => r.body);
