import * as actions from '../actions/status-notification';

const initialState = {
  success: {
    open: false,
    message: 'Success!',
  },
  failure: {
    open: false,
    message: 'Failed!',
  },
  notifications: [],
  autoDisappear: true,
  isMessageHtml: false,
};

const setStatusNotification = (message) => {
  return { open: true, message };
};

let incrementingId = 1;

/** @deprecated */
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_SUCCESS_NOTIFICATION: {
      return {
        ...state,
        success: setStatusNotification(action.message),
        failure: initialState.failure,
        autoDisappear:
          typeof action.autoDisappear === 'undefined'
            ? true
            : !!action.autoDisappear,
        isMessageHtml: !!action.isMessageHtml,
      };
    }
    case actions.SET_FAILURE_NOTIFICATION: {
      return {
        ...state,
        success: initialState.success,
        failure: setStatusNotification(action.message),
        autoDisappear:
          typeof action.autoDisappear === 'undefined'
            ? true
            : !!action.autoDisappear,
        isMessageHtml: !!action.isMessageHtml,
      };
    }
    case actions.SET_CUSTOM_NOTIFICATION: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            message: action.message,
            type: action.notificationType,
            open: true,
            id: incrementingId++,
            autoDisappear:
              typeof action.autoDisappear === 'undefined'
                ? true
                : !!action.autoDisappear,
            isMessageHtml: !!action.isMessageHtml,
          },
        ],
      };
    }
    case actions.CLOSE_CUSTOM_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.id
        ),
      };
    }
    case actions.CLOSE_NOTIFICATION: {
      return {
        ...state,
        success: initialState.success,
        failure: initialState.failure,
        autoDisappear: initialState.autoDisappear,
        isMessageHtml: initialState.isMessageHtml,
      };
    }
    default:
      return state;
  }
}
