export const SET_MERGE_CONFLICT_DATA =
  'client/modules/markets/actions/merge-conflict/SET_MERGE_CONFLICT_DATA';

export const setMergeConflictData = (
  currentDataInTheDB,
  mergeConflicts,
  status
) => {
  return {
    type: SET_MERGE_CONFLICT_DATA,
    currentDataInTheDB,
    mergeConflicts,
    status,
  };
};
