import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, orderBy } from 'lodash';
import Select from 'client/modules/common/components/forms/Select';
import {
  TODAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  fetchUserStats,
  setTimePeriod,
} from 'client/modules/news/user-stats/redux/reducers/news-user-stats.reducer';
import UserStatsTable from 'client/modules/news/user-stats/components/UserStatsTable';
import UserStatsRow from 'client/modules/news/user-stats/components/UserStatsRow';

const TimePeriodOptions = [
  { name: TODAY },
  { name: LAST_7_DAYS },
  { name: LAST_30_DAYS },
];

export class UserStats extends Component {
  constructor(props) {
    super(props);
    this.onTimePeriodChange = this.onTimePeriodChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchUserStats());
  }

  onTimePeriodChange(period) {
    this.props.dispatch(setTimePeriod(period));
  }

  render() {
    const { stats, timePeriod } = this.props;

    return (
      <div className="container mx-auto mt-8 mb-4">
        <div className="flex justify-between mb-16">
          <div className="user-stats-title text-3xl text-black font-medium py-2">
            News Queue Stats
          </div>
          <div className="flex items-center">
            <Select
              options={TimePeriodOptions}
              value={timePeriod}
              textField="name"
              valueField="name"
              onChange={this.onTimePeriodChange}
              data-test="categorySelect"
            />
          </div>
        </div>
        <UserStatsTable>
          {map(stats, (stat, index) => (
            <UserStatsRow key={stat.id} stat={stat} index={index} />
          ))}
        </UserStatsTable>
      </div>
    );
  }
}

function mapStateToProps({ newsUserStats }) {
  let stats = [];
  if (newsUserStats.timePeriod === TODAY) stats = newsUserStats.stats.today;
  if (newsUserStats.timePeriod === LAST_7_DAYS)
    stats = newsUserStats.stats.lastSevenDays;
  if (newsUserStats.timePeriod === LAST_30_DAYS)
    stats = newsUserStats.stats.lastThirtyDays;

  stats = orderBy(stats, ['fname', 'id'], 'asc');
  return { stats, timePeriod: newsUserStats.timePeriod };
}

UserStats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.object).isRequired,
  timePeriod: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UserStats);
