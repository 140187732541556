import {
  GetMarketDetailsForAdminRequest,
  GetMarketDetailsForAdminResponse,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import { UseQueryResult, useQuery } from 'react-query';

import { getMarketDetailsForAdmin } from '../api/MarketsEndpoints';

export type MarketForAdminServiceKeys =
  | 'GetMarketsForAdmin'
  | 'GetMarketDetailsForAdmin'
  | 'ListIndustries'
  | 'GetGeneralCbiEntitiesInfo';

export const useGetMarketDetailsForAdmin = (
  req: Partial<GetMarketDetailsForAdminRequest>
): UseQueryResult<GetMarketDetailsForAdminResponse, Error> =>
  useQuery<
    GetMarketDetailsForAdminResponse,
    Error,
    GetMarketDetailsForAdminResponse,
    [MarketForAdminServiceKeys, Partial<GetMarketDetailsForAdminRequest>]
  >({
    queryKey: ['GetMarketDetailsForAdmin', req],
    queryFn: () => getMarketDetailsForAdmin(req),
    notifyOnChangeProps: [
      'data',
      'isFetching',
      'isFetched',
      'isFetchedAfterMount',
    ],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
