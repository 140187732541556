import {
  GeneralCbiEntityInfo,
  InfoRequest,
} from '@cbinsights/cbientityservice/cbientityservice';
import { UseQueryResult, useQuery } from 'react-query';
import { getGeneralCbiEntityInfo } from '../apis/GetGeneralCbiEntityInfo';
import { EntityServicesKeys } from './UseSearchForEntity';

export const useGetGeneralCbiEntityInfo = (
  enabled: boolean,
  request?: InfoRequest
): UseQueryResult<GeneralCbiEntityInfo, Error> =>
  useQuery<
    GeneralCbiEntityInfo,
    Error,
    GeneralCbiEntityInfo,
    [EntityServicesKeys, InfoRequest]
  >(
    ['GetGeneralCbiEntityInfo', request],
    () => getGeneralCbiEntityInfo(request),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
