import {
  CreateTeamV2Request,
  CreateTeamV2Response,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const createTeam = (
  req: Partial<CreateTeamV2Request>
): Promise<CreateTeamV2Response> =>
  request({
    url: '/service/adminservice/CreateTeamV2',
    body: req,
  }).then((r) => r.body);
