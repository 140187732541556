import { Col, Row, Table } from 'antd';
import useShowPagination from 'client/modules/team/hooks/useShowPagination';
import { useGetUserInformation } from '../hooks/useGetUserInformation';

export const UserHistory = () => {
  const {
    loginHistory,
    packageHistory,
    loginHistoryTableData,
    packageHistorySource,
  } = useGetUserInformation();
  const pagination = useShowPagination({ data: packageHistorySource });

  return (
    <>
      <Row>
        <Col span="12" className="pl-3">
          <div className="flex justify-between items-center">
            <span className="text-2xl">Login History</span>
          </div>
        </Col>
        <Col span="12" className="pl-3">
          <span className="text-2xl">Package History</span>
        </Col>
      </Row>
      <Row>
        <Col xl={{ span: 12 }} md={{ span: 24 }} className="pl-3 pr-3">
          <Table
            columns={loginHistory.columns}
            dataSource={loginHistoryTableData}
          />
        </Col>
        <Col xl={{ span: 12 }} md={{ span: 24 }} className="pl-3">
          <Table
            pagination={pagination}
            columns={packageHistory.columns}
            dataSource={packageHistorySource}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserHistory;
