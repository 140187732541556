import {
  CreateFilterExpressionGroupRequest,
  CreateFilterExpressionGroupResponse,
  DeactivateFilterExpressionGroupRequest,
  DeleteFilterExpressionGroupRequest,
  GetFilterExpressionGroupInfoRequest,
  GetFilterExpressionGroupInfoResponse,
  ListFilterExpressionGroupsResponse,
  SuccessStatus,
  UpdateFilterExpressionGroupRequest,
} from '@cbinsights/newsqueueservice/newsqueueservice';
import { request } from 'client/modules/common/utils/request';

export const getFilterExpressionGroupInfo = (
  req: Partial<GetFilterExpressionGroupInfoRequest>
): Promise<GetFilterExpressionGroupInfoResponse> =>
  request({
    url: '/service/newsqueueservice/GetFilterExpressionGroupInfo',
    body: req,
  }).then((r) => r.body);

export const createFilterExpressionGroup = (
  req: Partial<CreateFilterExpressionGroupRequest>
): Promise<CreateFilterExpressionGroupResponse> =>
  request({
    url: '/service/newsqueueservice/CreateFilterExpressionGroup',
    body: req,
  }).then((r) => r.body);

export const deleteFilterExpressionGroup = (
  req: Partial<DeleteFilterExpressionGroupRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/newsqueueservice/DeleteFilterExpressionGroup',
    body: req,
  }).then((r) => r.body);

export const listFilterExpressionGroups =
  (): Promise<ListFilterExpressionGroupsResponse> =>
    request({
      url: '/service/newsqueueservice/ListFilterExpressionGroups',
    }).then((r) => r.body);

export const deactivateFilterExpressionGroup = (
  req: Partial<DeactivateFilterExpressionGroupRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/newsqueueservice/DeactivateFilterExpressionGroup',
    body: req,
  }).then((r) => r.body);

export const updateFilterExpressionGroup = (
  req: Partial<UpdateFilterExpressionGroupRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/newsqueueservice/UpdateFilterExpressionGroup',
    body: req,
  }).then((r) => r.body);
