const styles = (theme) => {
  return {
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    hiddenRow: {
      '&:nth-of-type(4n+1)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  };
};

/** @deprecated  */
export default styles;
