import FDS from '@cbinsights/fds/lib/dictionary/js/styleConstants';

export default {
  noMargin: {
    margin: 0,
    width: '100%',
  },
  rootDefault: {},
  tableText: {
    fontSize: '0.9225rem',
    color: FDS.COLOR_CHARCOAL,
  },
  widthAuto: {
    width: 'auto',
    margin: 0,
  },
};
