export const SET_LIVE_SEARCH_RESULTS =
  'client/modules/common/redux/live-search/SET_LIVE_SEARCH_RESULTS';
export const SET_LIVE_SEARCH_TERM =
  'client/modules/common/redux/live-search/SET_LIVE_SEARCH_TERM';
export const RESET_LIVE_SEARCH_RESULTS =
  'client/modules/common/redux/live-search/RESET_LIVE_SEARCH_RESULTS';

export function setLiveSearchTerm(term) {
  return { type: SET_LIVE_SEARCH_TERM, term };
}

export function resetLiveSearchResults() {
  return { type: RESET_LIVE_SEARCH_RESULTS };
}

export function fetchLiveSearchResultsHelper(data, term) {
  return { type: SET_LIVE_SEARCH_RESULTS, data, term };
}

export const fetchLiveSearchResults = (term) => ({
  types: [null, null],
  apicall: {
    endpoint: '/api/live-search',
    method: 'POST',
    body: {
      url: '/search/live-search',
      data: { term, limit: 20 },
    },
  },
});

export function fetchLiveSearch(term) {
  return (dispatch) => {
    if (!term) {
      return Promise.resolve();
    }
    dispatch(setLiveSearchTerm(term));
    return dispatch(fetchLiveSearchResults(term)).then((data) => {
      dispatch(fetchLiveSearchResultsHelper(data, term));
    });
  };
}
