/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */
import { remove, join, endsWith, trimEnd } from 'lodash';

const URL = require('url').URL;

export const formatURL = (url) => {
  let www = '';
  try {
    const urlObject = new URL(url);
    return urlObject.href;
  } catch (error) {
    if (url.indexOf('www.') === -1) {
      www = 'www.';
    }
    return `http://${www}${url}/`;
  }
};

export const addHttpsToUrl = (url) => {
  if (!url.includes('http://') && !url.includes('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const stripURL = (url = '') => {
  const separators = new RegExp(/\/\/|\./); // split on '//' or '.'
  const urlTokens = url.split(separators);
  remove(urlTokens, (token) => {
    return token.match(/^http/g) || token.match(/^www/g);
  });
  const lastIndex = urlTokens.length - 1;
  if (endsWith(urlTokens[lastIndex], '/')) {
    urlTokens[lastIndex] = trimEnd(urlTokens[lastIndex], '/');
  }
  return join(
    urlTokens.filter((token) => token), // removes white space from array
    '.'
  );
};

export default formatURL;
