import { map, each, forEach } from 'lodash';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import { getMultiKeyValueFromListViaOtherKeyStringMatch } from 'client/modules/common/utils/objectHelper';
import * as actions from '../actions/search-bar';

export const removeDuplicates = (filteredSuggestions) => {
  const results = [];
  const exist = {};
  forEach(filteredSuggestions, (suggestion) => {
    if (!exist[suggestion.name]) {
      exist[suggestion.name] = true;
      results.push(suggestion);
    }
  });
  return results;
};

export const combineCompanyWithInvestor = (
  { companies, investors },
  searchTerm
) => {
  const combinedSuggestions = companies.concat(investors);
  const filteredSuggestions = filterListOfObjects({
    value: searchTerm,
    listOfItems: combinedSuggestions,
    fields: ['name'],
  });
  return removeDuplicates(filteredSuggestions);
};

export const addEntityType = (entities, entityType) => {
  return map(entities, (entity) => {
    return { ...entity, entityType };
  });
};

export const combineSuggestions = (
  { companies, investors },
  recentlyCreatedCompany,
  recentlyCreatedInvestor
) => {
  const formattedCompanies = map(recentlyCreatedCompany, (company) => {
    return {
      description: company.descrip,
      id: company.idCompany,
      name: company.company,
      profileUrl: company.profileUrl,
      url: company.url,
      entityType: 'company',
    };
  });
  const formattedInvestors = map(recentlyCreatedInvestor, (investor) => {
    return {
      description: investor.descrip,
      id: investor.idInvestor,
      name: investor.investor,
      profileUrl: investor.profileUrl,
      url: investor.url,
      entityType: 'investor',
    };
  });

  const updatedCompanies = addEntityType(companies, 'company');
  const updatedInvestors = addEntityType(investors, 'investor');

  return {
    companies: updatedCompanies.concat(formattedCompanies),
    investors: updatedInvestors.concat(formattedInvestors),
  };
};

export const defaultState = {
  searchSuggestions: [],
  marketSuggestions: [],
  collectionSuggestions: [],
  companySuggestions: [],
  currentSearchTerm: '',
  currentSearchId: 0,
  selectedSearchTerm: '',
  selectedSearchId: 0,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case actions.INITIALZE_SEARCH_TERM: {
      return {
        ...state,
        currentSearchId:
          parseInt(action.searchTermId, 10) || defaultState.currentSearchId,
        currentSearchTerm: action.searchTerm,
        selectedSearchId:
          parseInt(action.searchTermId, 10) || defaultState.selectedSearchId,
        selectedSearchTerm: action.searchTerm,
      };
    }
    case actions.UPDATE_SEARCH_SUGGESTIONS: {
      const suggestions = action.entitySearchResults;
      const { searchTerm } = action;
      const matchableFields = ['name', 'url', 'alias', 'altUrl'];
      let searchId;
      let entityType;
      each(matchableFields, (field) => {
        const result = getMultiKeyValueFromListViaOtherKeyStringMatch(
          suggestions,
          field,
          searchTerm,
          ['idCbiEntity', 'entityType']
        );
        if (result) {
          [searchId, entityType] = result;
        }
      });
      return {
        ...state,
        searchSuggestions: suggestions,
        searchId: parseInt(searchId, 10) || 0,
        entityType,
      };
    }
    case actions.UPDATE_CURRENT_SEARCH_INFO: {
      return {
        ...state,
        currentSearchTerm: action.searchTerm,
        currentSearchId: parseInt(action.searchId, 10) || 0,
      };
    }
    case actions.SET_SELECTED_SEARCH_INFO: {
      return {
        ...state,
        currentSearchTerm: action.searchTerm,
        currentSearchId: parseInt(action.searchId, 10) || 0,
        selectedSearchTerm: action.searchTerm,
        selectedSearchId: parseInt(action.searchId, 10) || 0,
      };
    }
    default:
      return state;
  }
}
