import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import Input from 'client/component-library/Input/afsInput';
import {
  SimpleSelect,
  DEFAULT_OPTION,
} from 'client/component-library/Select/SimpleSelect';
import { ContextField } from '../ContextField';

/* The Input field or search feild the user will interact with */
/**
 * @deprecated
 * It's a wrapper around a formik field that allows you to create a new option and insert it into the
 * formik form
 * @returns A React component
 */
export function ChipSelectionField({
  loadOptions,
  options,
  placeholder,
  name,
  inputName,
  label,
  validate,
  insert,
  disabled,
  required,
  renderComp,
  inputType,
  generateValue = (value) => value,
  shouldHighlightOption = () => false,
  handleWarning = () => false,
  isCreatable = false,
  disableCreate = false,
  customStyles = '',
  onCreateOption,
}) {
  const isInput = !loadOptions && !options;
  const defaultValue = isInput
    ? ''
    : {
        ...DEFAULT_OPTION,
        label: placeholder || DEFAULT_OPTION.label,
        name: placeholder || DEFAULT_OPTION.name,
      };

  const [selectionValue, setSelectionValue] = useState(defaultValue);
  const { setFieldError, setFieldValue, values } = useFormikContext();

  const _insert = (value) => {
    const newValue = generateValue(value);
    const warning = handleWarning(newValue || {});
    const errorMessage = validate(newValue);

    function handleValidation(error) {
      if (error) {
        setFieldError(name, error);
      } else {
        setFieldError(name, '');
        if (newValue) {
          if (typeof newValue === 'object') {
            insert({ ...newValue, isNew: true });
            setSelectionValue(
              typeof value === 'string' ? defaultValue : { ...defaultValue }
            );
          } else if (inputType === 'number') {
            insert(toNumber(newValue));
            setSelectionValue(defaultValue);
          } else {
            insert(newValue);
            setSelectionValue(defaultValue);
          }
        }
      }
      if (warning && !error) {
        setFieldValue(`warnings.${name}`, warning);
      } else if (get(values, `warnings.${name}`, '')) {
        setFieldValue(`warnings.${name}`, '');
      }
    }

    if (errorMessage && errorMessage.then) {
      return errorMessage.then(handleValidation);
    }

    return handleValidation(errorMessage);
  };

  const updateInput = (e) => {
    setSelectionValue(e.target.value);
  };

  return (
    <ContextField name={name}>
      <div className={`-mx-1 ${customStyles}`}>
        {isInput ? (
          <Input
            disabled={disabled}
            required={required}
            inputType={inputType}
            value={selectionValue}
            placeholder={placeholder}
            onEnter={(event) => _insert(event.target.value)}
            name={inputName}
            label={label}
            onChange={updateInput}
          />
        ) : (
          <SimpleSelect
            isDisabled={disabled}
            required={required}
            isCreatable={isCreatable}
            isValidNewOption={() => !disableCreate}
            onCreateOption={onCreateOption}
            name={inputName}
            onChange={(value) => _insert(value)}
            loadOptions={loadOptions}
            options={options}
            placeholder={placeholder}
            label={label}
            renderComp={renderComp}
            value={selectionValue}
            shouldHighlightOption={shouldHighlightOption}
          />
        )}
      </div>
    </ContextField>
  );
}

ChipSelectionField.propTypes = {
  loadOptions: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  renderComp: PropTypes.object,
  inputName: PropTypes.string,
  inputType: PropTypes.string,
  disabled: PropTypes.bool,
  disableCreate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  shouldHighlightOption: PropTypes.bool,
  handleWarning: PropTypes.func,
  generateValue: PropTypes.func,
  onCreateOption: PropTypes.func,
  isCreatable: PropTypes.bool,
  customStyles: PropTypes.string,
  required: PropTypes.bool,
};

ChipSelectionField.defaultProps = {
  loadOptions: null,
  renderComp: null,
  inputName: '',
  inputType: 'text',
  disabled: false,
  required: false,
};
