import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';
import TextField from 'client/component-library/TextField';
import { FormControl } from 'client/component-library/Form';
import Button from 'client/component-library/buttons/Button';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import { createNewSlug } from 'client/modules/stories/redux/actions/tab-data';
import { setFailureNotification } from 'client/modules/common/redux/actions/status-notification';
import styles from './styles/material-ui/expandIcon';
import { urlValidation } from '../redux/utils/records-helper';

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

const slugMsg =
  'Slug names can only include the following characters: a-z, 0-9, - and cannot start or end with a -';

class ConfirmSlugCreationDialog extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      inputValue: '',
      open: false,
    });
  }

  handleChange = (inputValue) => (event) => {
    this.setState({
      [inputValue]: event.target.value.toLowerCase(),
    });
  };

  handleOnEnter = (event) => {
    if (event.key === 'Enter') {
      const isValidSlug = urlValidation(this.state.inputValue);

      if (isValidSlug) {
        this.setState({ open: true });
      } else {
        this.props.setFailureNotification(slugMsg);
      }
    }
  };

  handleOpenDialog = () => {
    const isValidSlug = urlValidation(this.state.inputValue);

    if (isValidSlug) {
      this.setState({ open: true });
    } else {
      this.props.setFailureNotification(slugMsg);
    }
  };

  handleSaveClick = (id) => () => {
    const [storyId] = id.split('-');
    this.props.createNewSlug(storyId, this.state.inputValue);
    this.setState({
      inputValue: '',
      open: false,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { id, disabled, name } = this.props;
    return (
      <div style={{ marginLeft: '30px' }}>
        <FormControl>
          <TextField
            id={id}
            disabled={disabled}
            name={name}
            value={this.state.inputValue}
            onChange={this.handleChange('inputValue')}
            onKeyPress={this.handleOnEnter}
            rows={1}
            placeholder="Add a slug..."
            inputProps={{ style: { fontSize: '0.9225rem', width: '200px' } }}
            data-test={`${id}-new-slug-input`}
          />
        </FormControl>
        <span>
          <Button
            onClick={this.handleOpenDialog}
            color="primary"
            dataTest="save"
          >
            SAVE
          </Button>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle>
              Are you sure you would like to create this slug?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Slugs are permanent and cannot be modified or deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={this.handleClose}
                  color="secondary"
                  dataTest="dialog-cancel"
                >
                  CANCEL
                </Button>
              </MuiThemeProvider>
              <Button
                onClick={this.handleSaveClick(id)}
                color="primary"
                dataTest="dialog-save"
              >
                SAVE
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </div>
    );
  }
}

ConfirmSlugCreationDialog.propTypes = {
  createNewSlug: PropTypes.func.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  setFailureNotification: PropTypes.func.isRequired,
};

ConfirmSlugCreationDialog.defaultProps = {
  id: '',
  disabled: false,
  name: '',
};

export default compose(
  withStyles(styles),
  connect(null, {
    createNewSlug,
    setFailureNotification,
  })
)(ConfirmSlugCreationDialog);
