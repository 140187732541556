import * as actions from '../actions/modified-records';

const initialState = {};

export function modifiedRecordsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_MODIFIED_RECORDS_DIFF:
      return { ...action.modifiedRecords };
    case actions.CLEAR_MODIFIED_RECORDS_DIFF:
      return {};
    default:
      return state;
  }
}
