import { forEach } from 'lodash';
import validateUrl from 'client/modules/common/utils/validateUrl';

export const validateRecords = (records) => {
  const response = {};
  forEach(records, (record) => {
    forEach(record, (field, key) => {
      switch (key) {
        case 'market':
          if (!field.name) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              market: 'Required',
            };
          } else if (field.name.length >= 100) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              market: 'Market name must be less than 100 characters',
            };
          }
          break;
        case 'news':
          if (!field.link) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              articleLink: 'Required',
            };
          } else if (!validateUrl({ url: field.link })) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              articleLink: 'Invalid url',
            };
          }
          if (!field.date || field.date === 'Invalid date') {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              newsDate: 'Required',
            };
          }
          break;
        case 'startSize':
          if (isNaN(field)) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startSize: 'Invalid number',
            };
          } else if (
            !field &&
            record.startYear &&
            record.startYear.toString().toLowerCase() !== 'n/a'
          ) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startSize: 'Required if start year present',
            };
          }
          break;
        case 'startYear':
          if (field === 0 && record.startSize) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startYear: 'Required if start size present',
            };
          } else if (field === 0) {
            break; // this is valid!
          } else if (isNaN(field)) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startYear: 'Invalid year',
            };
          } else if (field % 1 !== 0) {
            // must be an integer
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startYear: 'Invalid year',
            };
          } else if (field < 1900 || field > 2150) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              startYear: 'Invalid year',
            };
          }
          break;
        case 'endSize':
          if (isNaN(field)) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endSize: 'Invalid number',
            };
          } else if (!field) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endSize: 'Required',
            };
          }
          break;
        case 'endYear':
          if (isNaN(field)) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endYear: 'Invalid year',
            };
          } else if (!field) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endYear: 'Required',
            };
          } else if (field % 1 !== 0) {
            // must be an integer
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endYear: 'Invalid year',
            };
          } else if (field < 1900 || field > 2150) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              endYear: 'Invalid year',
            };
          }
          break;
        case 'cagr':
          if (isNaN(field)) {
            response[record.tmpKeyId] = {
              ...response[record.tmpKeyId],
              cagr: 'Invalid number',
            };
          }
          break;
        default:
          break;
      }
    });
  });
  return response;
};

export default validateRecords;
