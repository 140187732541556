/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

/**
 * @deprecated
 * It's a React component that renders a custom option for the Select component
 * @param props - The props passed to the component.
 * @returns A function that returns a component
 */
export function EntityOption(props) {
  const {
    data: {
      name,
      alias,
      aliasType,
      url,
      description,
      idInvestorCategory,
      label,
      altUrl,
      idCbiEntity,
      idEntityDraft,
    },
    isSelected,
    innerProps,
    investorCategoryMapping = [],
  } = props;

  const colorClass = isSelected ? 'text-white' : 'text-black';
  const headersClassname = `${colorClass} font-bold mr-1`;

  const investorCategory = investorCategoryMapping.find(
    (category) => category.idInvestorCategory === idInvestorCategory
  ) || { name: '' };

  const draftHightlight =
    !idCbiEntity && idEntityDraft && idEntityDraft ? '!bg-yellow-400' : '';

  const hasInvestorCategory =
    investorCategoryMapping && idInvestorCategory > 0 && investorCategory.name;
  return (
    <components.Option
      {...props}
      className={`${draftHightlight} ${props.className}`}
      innerProps={{
        // eslint-disable-next-line react/prop-types
        ...innerProps,
        'data-test': 'select-entity-option',
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div data-test="entity-option-label" className={headersClassname}>
            {name || label}
          </div>
          <span className={isSelected ? 'text-white' : 'text-blue-600'}>
            {url}
          </span>
        </div>
        {alias && (
          <div className={colorClass}>
            <span className={headersClassname}>{aliasType}:</span> {alias}
          </div>
        )}
        {altUrl && (
          <span>
            <b>alternate url:</b> {altUrl}
          </span>
        )}
        {hasInvestorCategory && (
          <div className={colorClass}>{investorCategory.name}</div>
        )}
        <div className={`${isSelected ? 'text-white' : 'text-gray-600'}`}>
          {description}
        </div>
      </div>
    </components.Option>
  );
}

EntityOption.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    alias: PropTypes.string,
    aliasType: PropTypes.string,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    altUrl: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  innerProps: PropTypes.object,
};
