import { ConfirmationModal } from 'client/component-library/modals/ConfirmationModal';
import React, { ReactNode, useMemo, useState } from 'react';
import {
  FormModalProps,
  FormUtilsContext,
  FormUtilsExternalContext,
  FormUtilsHighlightProps,
} from './FormUtilsContext';

type FormUtilsProviderProps = {
  children: ReactNode;
} & FormUtilsHighlightProps &
  FormUtilsExternalContext;

export const FormUtilsContextProvider = ({
  children,
  isHighlightable = () => true,
  noHighlightFields = [],
  shouldHighlight = false,
  customContext,
}: FormUtilsProviderProps) => {
  const highlightMap = useMemo<Record<string, unknown>>(() => ({}), []);
  const setHighlightMap = (key: string, value: unknown) => {
    highlightMap[key] = value;
  };
  const [confirmationModal, setConfirmationModal] = useState<FormModalProps>({
    isOpen: false,
  });

  return (
    <FormUtilsContext.Provider
      value={{
        setConfirmationModal,
        isHighlightable,
        noHighlightFields,
        shouldHighlight,
        setHighlightMap,
        highlightMap,
        customContext,
      }}
    >
      {children}
      <ConfirmationModal width={510} {...(confirmationModal as $TSFixMe)} />
    </FormUtilsContext.Provider>
  );
};
