import { keyBy } from 'lodash';
import * as tabName from 'client/modules/packages/utils/tab-names';
import {
  getKeyValueFromListViaOtherKey,
  updateObjectInListWithKeyValue,
} from 'client/modules/common/utils/objectHelper';
import {
  filteringRecords,
  updateSearchInfo,
  updateSelectedSearchTerm,
  updateSearchSuggestions,
} from 'client/modules/admin-user-admin/redux/utils/records-helper';
import {
  saveRecordsOrder,
  saveOriginalRecords,
} from 'client/modules/markets/redux/utils/records-helper';
import * as actions from '../actions/tab-data';
import * as tabDetailActions from '../actions/tab-details';
import * as allFeatureActions from '../actions/all-features';
import {
  addToPreviousRecords,
  diffRecords,
  getPackageCommonInfo,
  getPackageFeatureInfo,
  updatePackageCommonInfo,
  sortList,
  mapPackagesWithTheFeature,
  createRecordStyles,
  createRecordCellStyles,
  updateCompareRecords,
  setFeatureDescription,
} from '../utils/records-helper';

const initialState = {
  [tabName.CREATE_PACKAGE]: {
    records: {},
    originalRecords: {},
    recordsOrder: [],
    originalCommonInfo: {},
    commonInfo: {},
    allRecordsSelected: false,
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    sortInfo: {},
    recordStyles: {},
  },
  [tabName.UPDATE_PACKAGE]: {
    records: {},
    originalRecords: {},
    recordsOrder: [],
    originalCommonInfo: {},
    commonInfo: {},
    allRecordsSelected: false,
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    sortInfo: { sortField: 'name', sortDirection: 'desc' },
    recordStyles: {},
  },
  [tabName.PACKAGE_FEATURES]: {
    records: {},
    originalRecords: {},
    recordsOrder: [],
    allRecordsSelected: false,
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    sortInfo: {},
  },
  [tabName.COMPARE_PACKAGE]: {
    records: {},
    originalRecords: {},
    recordsOrder: [],
    originalCommonInfo: {},
    commonInfo: {},
    secondCommonInfo: {},
    secondOriginalCommonInfo: {},
    allRecordsSelected: false,
    searchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    secondSearchBox: {
      selectedSearchTerm: '',
      suggestions: [],
      searchTerm: '',
      searchId: 0,
    },
    sortInfo: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_PACKAGE_INFO_TO_CREATE_PACKAGE: {
      const featureInfo = keyBy(
        getPackageFeatureInfo(action.response, action.featuresInfo),
        'id'
      );
      const commonInfo = getPackageCommonInfo(action.response);
      return {
        ...state,
        [tabName.CREATE_PACKAGE]: {
          ...state[tabName.CREATE_PACKAGE],
          commonInfo,
          originalCommonInfo: commonInfo,
          records: featureInfo,
          originalRecords: featureInfo,
          recordsOrder: saveRecordsOrder(featureInfo),
          recordStyles: createRecordStyles(featureInfo),
        },
      };
    }
    case actions.SET_PACKAGE_INFO_TO_UPDATE_PACKAGE: {
      const featureInfo = keyBy(
        getPackageFeatureInfo(action.response, action.featuresInfo),
        'id'
      );
      const commonInfo = getPackageCommonInfo(action.response);
      return {
        ...state,
        [tabName.UPDATE_PACKAGE]: {
          ...state[tabName.UPDATE_PACKAGE],
          commonInfo,
          originalCommonInfo: commonInfo,
          records: featureInfo,
          originalRecords: featureInfo,
          recordsOrder: saveRecordsOrder(featureInfo),
          recordStyles: createRecordStyles(featureInfo),
        },
      };
    }
    case actions.SET_ALL_PACKAGES_WITH_THE_FEATURE: {
      const packagesDetails = keyBy(
        mapPackagesWithTheFeature(action.response),
        'id'
      );
      return {
        ...state,
        [tabName.PACKAGE_FEATURES]: {
          ...state[tabName.PACKAGE_FEATURES],
          records: packagesDetails,
          originalRecords: packagesDetails,
          recordsOrder: saveRecordsOrder(packagesDetails),
          recordStyles: createRecordStyles(packagesDetails),
        },
      };
    }
    case actions.SET_PACKAGE_INFO_TO_COMPARE_PACKAGE: {
      const featureInfo = keyBy(
        getPackageFeatureInfo(action.response, action.featuresInfo),
        'id'
      );
      const commonInfo = getPackageCommonInfo(action.response);
      if (!action.secondSearchBox) {
        const updatedOriginalRecords = keyBy(
          updateCompareRecords(
            state[tabName.COMPARE_PACKAGE].originalRecords,
            featureInfo
          ),
          'id'
        );
        return {
          ...state,
          [tabName.COMPARE_PACKAGE]: {
            ...state[tabName.COMPARE_PACKAGE],
            commonInfo,
            originalCommonInfo: commonInfo,
            records: updatedOriginalRecords,
            originalRecords: updatedOriginalRecords,
            recordsOrder: saveRecordsOrder(featureInfo),
            recordCellStyles: createRecordCellStyles(updatedOriginalRecords),
          },
        };
      }
      const updatedOriginalRecords = keyBy(
        addToPreviousRecords(
          state[tabName.COMPARE_PACKAGE].originalRecords,
          featureInfo
        ),
        'id'
      );
      return {
        ...state,
        [tabName.COMPARE_PACKAGE]: {
          ...state[tabName.COMPARE_PACKAGE],
          secondCommonInfo: commonInfo,
          secondOriginalCommonInfo: commonInfo,
          records: updatedOriginalRecords,
          originalRecords: updatedOriginalRecords,
          recordCellStyles: createRecordCellStyles(updatedOriginalRecords),
        },
      };
    }
    case actions.UPDATE_RECORD: {
      const currentTabData = state[action.currentTab];
      const updatedRecords = updateObjectInListWithKeyValue({
        objects: currentTabData.records,
        id: action.recordId,
        key: action.fieldName,
        value: action.newValue,
      });
      return {
        ...state,
        [action.currentTab]: {
          ...currentTabData,
          records: updatedRecords,
          originalRecords: saveOriginalRecords(
            currentTabData.originalRecords,
            action.recordId,
            currentTabData.records[action.recordId]
          ),
          recordStyles: createRecordStyles(updatedRecords),
        },
      };
    }
    case actions.UPDATE_PACKAGE_COMMON_INFO: {
      const currentTabData = state[action.currentTab];
      return {
        ...state,
        [action.currentTab]: {
          ...currentTabData,
          commonInfo: updatePackageCommonInfo(
            currentTabData.commonInfo,
            action.key,
            action.value
          ),
        },
      };
    }
    case actions.UPDATE_SUGGESTION: {
      let searchId = getKeyValueFromListViaOtherKey(
        action.suggestions,
        'name',
        action.searchTerm,
        'id'
      );
      searchId = parseInt(searchId, 10) || 0;
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSearchSuggestions(
            state[action.currentTab].searchBox,
            action.suggestions,
            searchId
          ),
        },
      };
    }
    case actions.UPDATE_SECOND_SUGGESTION: {
      let searchId = getKeyValueFromListViaOtherKey(
        action.suggestions,
        'name',
        action.searchTerm,
        'id'
      );
      searchId = parseInt(searchId, 10) || 0;
      return {
        ...state,
        [tabName.COMPARE_PACKAGE]: {
          ...state[tabName.COMPARE_PACKAGE],
          secondSearchBox: updateSearchSuggestions(
            state[tabName.COMPARE_PACKAGE].secondSearchBox,
            action.suggestions,
            searchId
          ),
        },
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSearchInfo(
            state[action.currentTab].searchBox,
            action.searchId,
            action.searchTerm
          ),
        },
      };
    }
    case actions.UPDATE_SECOND_SEARCH_INFO: {
      return {
        ...state,
        [tabName.COMPARE_PACKAGE]: {
          ...state[tabName.COMPARE_PACKAGE],
          secondSearchBox: updateSearchInfo(
            state[tabName.COMPARE_PACKAGE].secondSearchBox,
            action.searchId,
            action.searchTerm
          ),
        },
      };
    }
    case actions.UPDATE_SELECTED_SEARCH_TERM: {
      if (action.secondSearchBox) {
        return {
          ...state,
          [tabName.COMPARE_PACKAGE]: {
            ...state[tabName.COMPARE_PACKAGE],
            secondSearchBox: updateSelectedSearchTerm(
              state[tabName.COMPARE_PACKAGE].secondSearchBox
            ),
          },
        };
      }
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchBox: updateSelectedSearchTerm(
            state[action.currentTab].searchBox
          ),
        },
      };
    }
    case tabDetailActions.UPDATE_SORT_ORDER: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          recordsOrder: sortList(
            state[action.currentTab].records,
            action.sortField,
            action.sortDirection
          ),
        },
      };
    }
    case actions.FILTERING_RECORDS: {
      const filteredRecords = filteringRecords(
        state[action.currentTab].originalRecords,
        action.filter
      );
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: keyBy(filteredRecords, 'id'),
          recordsOrder: saveRecordsOrder(filteredRecords),
        },
      };
    }
    case actions.DIFF_RECORDS: {
      const diffedRecords = diffRecords(
        state[tabName.COMPARE_PACKAGE].originalRecords,
        action.checked
      );
      return {
        ...state,
        [tabName.COMPARE_PACKAGE]: {
          ...state[tabName.COMPARE_PACKAGE],
          records: diffedRecords,
          recordsOrder: saveRecordsOrder(diffedRecords),
        },
      };
    }
    case allFeatureActions.SET_FEATURE_DESCRIPTIONS: {
      if (action.currentTab === tabName.PACKAGE_FEATURES) {
        return state;
      }

      const featureInfo = { featureInfo: state[action.currentTab].records };
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: keyBy(
            setFeatureDescription(featureInfo, action.descriptions),
            'id'
          ),
        },
      };
    }
    default:
      return state;
  }
}
