import {
  CreateNewsSourceRequest,
  CreateNewsSourceResponse,
  GetNewsSourceRequest,
  GetNewsSourceResponse,
  ListNewsSourcesResponse,
  UpdateNewsSourceRequest,
  UpdateNewsSourceResponse,
} from '@cbinsights/newsservice/newsservice';
import { request } from 'client/modules/common/utils/request';

export const listNewsSources = (): Promise<ListNewsSourcesResponse> => {
  return request({
    url: '/service/newsservice/ListNewsSources',
  }).then((res) => res.body);
};

export const getNewsSource = (
  req: IndexSignature<GetNewsSourceRequest>
): Promise<GetNewsSourceResponse> => {
  return request({
    url: '/service/newsservice/GetNewsSource',
    body: req,
  }).then((res) => res.body);
};

export const updateNewsSource = (
  req: IndexSignature<UpdateNewsSourceRequest>
): Promise<UpdateNewsSourceResponse> => {
  return request({
    url: '/service/newsservice/UpdateNewsSource',
    body: req,
  }).then((res) => res.body);
};

export const createNewsSource = (
  req: IndexSignature<CreateNewsSourceRequest>
): Promise<CreateNewsSourceResponse> => {
  return request({
    url: '/service/newsservice/CreateNewsSource',
    body: req,
  }).then((res) => res.body);
};
