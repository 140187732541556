import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MaterialUILink } from 'client/component-library/Link';
import { removeExistingPipelineUser } from '../redux/actions/users';

class RemoveExistingPipelineUserLink extends Component {
  onClickComponent = () => {
    this.props.removeExistingPipelineUser({
      idUser: this.props.idUser,
      pipelineUsers: this.props.pipelineUsers,
      numPipelineUsers: this.props.numPipelineUsers,
      pipelineUserPageNumber: this.props.pipelineUserPageNumber,
      pipelineUserRecordsPerPage: this.props.pipelineUserRecordsPerPage,
      newUsers: this.props.newUsers,
      numNewUsers: this.props.numNewUsers,
      newUserPageNumber: this.props.newUserPageNumber,
      newUserRecordsPerPage: this.props.newUserRecordsPerPage,
    });
  };

  render() {
    return (
      <MaterialUILink onClick={this.onClickComponent}>remove</MaterialUILink>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    pipelineUsers: dataPlatform.users.pipelineUsers,
    numPipelineUsers: dataPlatform.users.numPipelineUsers,
    pipelineUserPageNumber: dataPlatform.users.pipelineUserPageNumber,
    pipelineUserRecordsPerPage: dataPlatform.users.pipelineUserRecordsPerPage,
    newUsers: dataPlatform.users.newUsers,
    numNewUsers: dataPlatform.users.numNewUsers,
    newUserPageNumber: dataPlatform.users.newUserPageNumber,
    newUserRecordsPerPage: dataPlatform.users.newUserRecordsPerPage,
  };
}

RemoveExistingPipelineUserLink.propTypes = {
  idUser: PropTypes.number.isRequired,
  removeExistingPipelineUser: PropTypes.func.isRequired,
  pipelineUsers: PropTypes.object,
  numPipelineUsers: PropTypes.number,
  pipelineUserPageNumber: PropTypes.number,
  pipelineUserRecordsPerPage: PropTypes.number,
  newUsers: PropTypes.object,
  numNewUsers: PropTypes.number,
  newUserPageNumber: PropTypes.number,
  newUserRecordsPerPage: PropTypes.number,
};

RemoveExistingPipelineUserLink.defaultProps = {
  pipelineUsers: {},
  numPipelineUsers: 0,
  pipelineUserPageNumber: 0,
  pipelineUserRecordsPerPage: 3,
  newUsers: {},
  numNewUsers: 0,
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
};

export default connect(mapStateToProps, { removeExistingPipelineUser })(
  RemoveExistingPipelineUserLink
);
