import React from 'react';
import { RouteObject } from 'react-router-dom';

const StorySharer = React.lazy(() => import('./StorySharer'));

export const storySharerRoutes: RouteObject = {
  path: 'story-sharer',
  element: (
    <React.Suspense fallback={undefined}>
      <StorySharer />
    </React.Suspense>
  ),
};
