import { forEach, map } from 'lodash';
import moment from 'moment';
import { saveRecordsOrder } from 'client/modules/common/utils/recordsHelper';
import * as actions from '../actions/user-session-data';

export const chartColorOptions = [
  '#4cabce',
  '#a02385',
  '#ff6633',
  '#003366',
  '#00986B',
  'F9a862',
];

export const formatEventLocation = (city, country, ipAddress) => {
  if (!city) {
    return `${country} (${ipAddress})`;
  }
  return `${city}, ${country} (${ipAddress})`;
};

export const formatPayload = (payload) => {
  let counter = 0;
  const records = {};
  forEach(payload.suspiciousSessionInfo, (info) => {
    counter += 1;
    records[counter] = {
      ...info,
      id: counter.toString(),
      eventLocation1: formatEventLocation(
        info.city1,
        info.country1,
        info.ipAddress1
      ),
      eventLocation2: formatEventLocation(
        info.city2,
        info.country2,
        info.ipAddress2
      ),
    };
  });
  return records;
};

export const getUnknownKeyFromIp = (ipAddress, unknownAddressesDict) => {
  if (!unknownAddressesDict[ipAddress]) {
    /* eslint-disable no-param-reassign */
    unknownAddressesDict[ipAddress] = `Unknown spot ${
      Object.keys(unknownAddressesDict).length + 1
    }`;
  }
  return unknownAddressesDict[ipAddress];
};

export const createTitle = (unknownAddressesDict, item) => {
  if (item.city) {
    return `${item.city}, ${item.country}`;
  } if (item.country) {
    return item.country;
  }
  return getUnknownKeyFromIp(item.ipAddress, unknownAddressesDict);
};

export const formatCustomTimelineData = (info, startDate, endDate) => {
  const {suspiciousSessionEvents} = info;

  const groupArray = [];
  const itemColorArray = [];
  const unknownAddressesDict = {};
  const itemsData = map(suspiciousSessionEvents, (item) => {
    const groupKey = item.city || item.ipAddress;
    if (!groupArray[groupKey]) {
      groupArray[groupKey] = {
        id: groupKey,
        title: createTitle(unknownAddressesDict, item),
      };
    }

    if (!itemColorArray[item.eventTableName]) {
      itemColorArray[item.eventTableName] =
        chartColorOptions[Math.floor(Math.random() * 6)];
    }

    return {
      id: item.idPaidEvent,
      group: groupKey,
      title: item.eventTableName,
      start: moment(item.tsEvent),
      end: moment(item.tsEvent),
      bgColor: itemColorArray[item.eventTableName],
    };
  });
  const groups = Object.values(groupArray);
  const items = itemsData;

  const defaultTimeStart = moment(startDate);
  const defaultTimeEnd = moment(endDate);

  return {
    groups,
    items,
    defaultTimeStart,
    defaultTimeEnd,
  };
};

export const getRecordsToDisplay = (
  recordsOrder,
  pageNumber,
  recordsPerPage
) => {
  return recordsOrder.slice(pageNumber, pageNumber + recordsPerPage);
};

const initialState = {
  customTimelineData: {},
  userSessionData: {},
  records: {},
  recordsOrderToDisplay: [],
  recordsOrder: [],
  pageNumber: 0,
  recordsPerPage: 10,
  totalNumberOfRecords: 0,
  tableOffset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_USER_SUSPICIOUS_SESSIONS: {
      const formattedResponse = formatPayload(action.payload);
      const originalRecordsOrder = saveRecordsOrder(formattedResponse);
      const formattedRecords = formatPayload(action.payload);
      return {
        ...state,
        originalRecordsOrder,
        records: formattedRecords,
        customTimelineData: formatCustomTimelineData(
          action.payload,
          action.startDate,
          action.endDate
        ),
        recordsOrderToDisplay: getRecordsToDisplay(
          originalRecordsOrder,
          state.pageNumber,
          state.recordsPerPage
        ),
        totalNumberOfRecords: originalRecordsOrder.length,
      };
    }
    case actions.UPDATE_PAGE_NUMBER: {
      const pageNumber = parseInt(action.pageNumber, 10);
      return {
        ...state,
        pageNumber,
        recordsOrderToDisplay: getRecordsToDisplay(
          state.originalRecordsOrder,
          pageNumber,
          state.recordsPerPage
        ),
      };
    }
    case actions.UPDATE_RECORDS_PER_PAGE: {
      const recordsPerPage = parseInt(action.recordsPerPage, 10);
      return {
        ...state,
        recordsPerPage,
        recordsOrderToDisplay: getRecordsToDisplay(
          state.originalRecordsOrder,
          state.pageNumber,
          action.recordsPerPage
        ),
      };
    }
    case actions.RESET_USER_SUSPICIOUS_SESSIONS:
      return initialState;
    default:
      return state;
  }
}
