import { combineReducers } from 'redux';
import currentTab from './tab';
import tabData from './tab-data';

const universityDisplay = combineReducers({
  currentTab,
  tabData,
});

export default universityDisplay;
