import React, { ReactElement, ReactNode } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import {
  DefaultOptionType,
  RefSelectProps,
  SelectProps,
} from 'antd/lib/select';
import { Select } from 'antd';

export const setFieldWarning = (
  name: string,
  value: DefaultOptionType | DefaultOptionType[],
  options: DefaultOptionType[],
  setFieldValue: (field: string, value: $TSFixMe) => void,
  handleWarning?: (
    value: DefaultOptionType | DefaultOptionType[],
    options: DefaultOptionType[]
  ) => string
): void => {
  const warning = handleWarning ? handleWarning(value, options) : null;
  setFieldValue(`warnings.${name}`, warning ?? '');
};

export type AntdSelectFieldProps = {
  ref?: React.Ref<RefSelectProps>;
  onChangeClear?: string[];
  onChange?: (
    value: unknown,
    option: DefaultOptionType | DefaultOptionType[],
    updateFieldValue: (value: unknown, shouldValidate?: boolean) => void
  ) => void;
  validateOnBlur?: boolean;
  'data-test'?: string;
  handleWarning?: (
    value: DefaultOptionType | DefaultOptionType[],
    options: DefaultOptionType[]
  ) => string;
} & Omit<FieldHookConfig<ReactNode>, 'as' | 'component' | 'onChange'> &
  Omit<SelectProps, 'onChange'>;

export const AntdSelectField = ({
  disabled,
  onChange,
  handleWarning,
  onBlur = () => {},
  onClear = () => {},
  validateOnBlur = false,
  onChangeClear = [],
  options = [],
  ...props
}: AntdSelectFieldProps): ReactElement => {
  const [field, , helpers] = useField(props as FieldHookConfig<ReactNode>);
  const { isSubmitting, setFieldValue, validateField } = useFormikContext();

  const handleChange = (
    value: DefaultOptionType | DefaultOptionType[],
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    setFieldWarning(field.name, value, options, setFieldValue, handleWarning);
    helpers.setTouched(true);

    onChangeClear.forEach((fieldName) => setFieldValue(fieldName, null));
    if (onChange) {
      return onChange(value, option, helpers.setValue);
    }
    return helpers.setValue((value as $TSFixMe) ?? null);
  };

  const handleBlur = (event) => {
    if (validateOnBlur) {
      validateField(props.name);
    }
    helpers.setTouched(true);
    onBlur(event);
  };

  const handleOnClear = () => {
    helpers.setValue(null);
    onClear();
  };

  return (
    <Select
      {...field}
      {...props}
      onClear={handleOnClear}
      disabled={isSubmitting || disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      options={options}
    />
  );
};
