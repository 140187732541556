import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserEvents } from './useUserEvent';
import { useUser } from './useIdUser';

export const useAdminPageViewEvent = () => {
  const { idUser } = useUser();
  const { recordUserEvent } = useUserEvents();
  const location = useLocation();
  const page = location.pathname.split('/')[1];

  useEffect(() => {
    if (idUser) {
      recordUserEvent({
        type: 'admin.page.view',
      });
    }
  }, [idUser, page]);
};
