import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { TablePagination as MuiTablePagination } from 'client/component-library/Table';

/** 
 * @deprecated 
 * This class is a wrapper around the Material-UI TablePagination component that only re-renders when
the props change. */
class TablePagination extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      totalRecords,
      recordsPerPage,
      pageNumber,
      handleChangePageNumber,
      handleUpdateRecordsPerPage,
      colSpan,
      rowsPerPageOptions,
      style,
    } = this.props;
    return (
      <MuiTablePagination
        colSpan={colSpan}
        count={totalRecords}
        rowsPerPage={recordsPerPage}
        page={pageNumber}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePageNumber}
        onChangeRowsPerPage={handleUpdateRecordsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} of ${count.toLocaleString()}`
        }
        rowsPerPageOptions={rowsPerPageOptions}
        style={style}
      />
    );
  }
}

TablePagination.propTypes = {
  colSpan: PropTypes.number,
  handleChangePageNumber: PropTypes.func,
  handleUpdateRecordsPerPage: PropTypes.func,
  totalRecords: PropTypes.number,
  recordsPerPage: PropTypes.number,
  pageNumber: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  style: PropTypes.object,
};

TablePagination.defaultProps = {
  colSpan: 3,
  handleChangePageNumber: () => {},
  handleUpdateRecordsPerPage: () => {},
  totalRecords: 0,
  recordsPerPage: 10,
  pageNumber: 1,
  rowsPerPageOptions: [5, 10, 25],
  style: {},
};

export default TablePagination;
