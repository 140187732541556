import * as actions from '../actions/autocomplete-input';

export const updateInputInfo = (inputValues, inputId, inputInfo) => {
  return {
    ...inputValues,
    [inputId]: inputInfo,
  };
};

export const updateTermId = (inputValues, inputId, termId, entityType) => {
  return {
    ...inputValues,
    [inputId]: {
      ...inputValues[inputId],
      termId,
      entityType,
    },
  };
};

export const updateSuggestions = (suggestions, inputId, newSuggestions) => {
  return {
    ...suggestions,
    [inputId]: newSuggestions,
  };
};

export interface AutocompleteInputState {
  inputValues: Record<
    string,
    {
      termId?: string;
      term: string;
      entityType?: string;
    }
  >;
  suggestions: Record<string, unknown>;
}

const initialState: AutocompleteInputState = {
  inputValues: {},
  suggestions: {},
};

export function autocompleteInputReducer(
  state = initialState,
  action
): AutocompleteInputState {
  switch (action.type) {
    case actions.INITIALIZE_INPUT_INFO: {
      return {
        ...state,
        inputValues: action.inputValues,
      };
    }
    case actions.UPDATE_MULTIPLE_INPUTS_INFO: {
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          ...action.inputValues,
        },
      };
    }
    case actions.UPDATE_INPUT_INFO: {
      return {
        ...state,
        inputValues: updateInputInfo(
          state.inputValues,
          action.inputId,
          action.inputInfo
        ),
      };
    }
    case actions.UPDATE_INPUT_INFO_TERM_ID: {
      // If the new term doesn't match the current term in the state, do not update the term id!
      if (
        state.inputValues[action.inputId] &&
        state.inputValues[action.inputId].term !== action.inputInfo.term
      ) {
        return { ...state };
      }
      return {
        ...state,
        inputValues: updateTermId(
          state.inputValues,
          action.inputId,
          action.inputInfo.termId,
          action.inputInfo.entityType
        ),
      };
    }
    case actions.UPDATE_INPUT_SUGGESTIONS: {
      // If suggestions aren't for the current term, do not update them.
      if (
        state.inputValues[action.inputId] &&
        action.term !== state.inputValues[action.inputId].term
      ) {
        return { ...state };
      }
      return {
        ...state,
        suggestions: updateSuggestions(
          state.suggestions,
          action.inputId,
          action.suggestions
        ),
      };
    }
    default:
      return state;
  }
}
