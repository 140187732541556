import get from 'lodash/get';
import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import { setToastError } from 'client/modules/third-party-admin/profile-queue/utils';
import { getDomainName } from 'client/modules/common/utils/getUrl';

const getArticleTitle = (title, url) => {
  return `${getDomainName(url)}: ${title}`;
};

export function parseClusterResponse(newsQueueClusters) {
  return newsQueueClusters.map((cluster) => {
    const x = {
      id: cluster.idCluster,
      date: cluster.date,
      type: cluster.itemType,
      entities: get(cluster, 'assignedEntities', []).map((entity) => ({
        id: entity.idCbiEntity,
        name: entity.name,
        entityClusterId: entity.idNewsQueueClusterEntity || 0,
        isSuggestedEntity: entity.idEntityDraft
          ? false
          : entity.idCbiEntity <= 0,
        url: entity.url,
        address: entity.hqAddress,
        idEntityDraft: entity.idEntityDraft,
      })),
      articles: get(cluster, 'news', []).map((article) => ({
        id: article.idNews,
        title: getArticleTitle(article.title, article.link),
        description: article.content,
        url: article.link,
        higlight: article.higlight,
      })),
    };

    return x;
  });
}

export const getNewsQueueMapping = ({
  newsQueueClusters = [],
  totalCount = 0,
}) => {
  const clusters = parseClusterResponse(newsQueueClusters);
  return { clusters, totalCount };
};

export function getNewsClusters({ sourceType, classifier, languages }) {
  const lang = languages.find((lng) => lng.value === 0)
    ? []
    : languages.map((lng) => lng.value);

  return request({
    url: 'service/newsqueueservice/ListNewsQueueClusters',
    body: {
      itemType: sourceType.value,
      classifier: classifier.value,
      languages: lang,
      limit: 5,
    },
  })
    .then(({ body }) => getNewsQueueMapping(body))
    .catch((error) => {
      setToastError('Error Fetching Queue');
      logInfo({
        error,
        message: 'News Queue: Failed To Fetch Queue',
        method: 'getNewsClusters',
      });
      throw error;
    });
}
