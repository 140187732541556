/* eslint-disable max-len */
export const UPDATE_COMPETITOR_SEARCH_SUGGESTIONS =
  'client/modules/cbi-entity/redux/actions/tab-details/UPDATE_COMPETITOR_SEARCH_SUGGESTIONS';
/* eslint-enable max-len */

/* eslint-disable no-console */
export const updateSortOrder = () => {
  console.log('update sort orders');
};
/* eslint-enable no-console */

export function updateSuggestionsHelper(
  suggestions,
  searchTerm,
  recentlyCreatedCompany,
  recentlyCreatedInvestor
) {
  return {
    type: UPDATE_COMPETITOR_SEARCH_SUGGESTIONS,
    suggestions,
    searchTerm,
    recentlyCreatedCompany,
    recentlyCreatedInvestor,
  };
}

export default updateSortOrder;
