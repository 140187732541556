import { SET_FEATURE_DESCRIPTIONS } from '../actions/all-features';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FEATURE_DESCRIPTIONS: {
      return action.descriptions;
    }
    default:
      return state;
  }
}
