import { forEach, map, filter, isEmpty, keyBy } from 'lodash';
import moment from 'moment';
import {
  getKeyValueFromListViaOtherKey,
  getObjDifferences,
  addRecordToObjectsWithKeyValue,
} from 'client/modules/common/utils/objectHelper';
import {
  REJECTED,
  APPROVED,
  NEEDS_APPROVAL,
  IS_DUPLICATE,
  IS_DUPLICATE_CODE,
} from '../actions/record-status-filters';

export const newEmptyRecord = {
  market: '',
  description: '',
  sentence: '',
  articleLink: '',
  startSize: '',
  endSize: '',
  startYear: '',
  endYear: '',
  cagr: '',
  geography: '',
  analyst: '',
  newsDate: '',
  isNew: true,
};

export const KEYS_TO_IGNORE = {
  tmpKeyId: 1,
  status: 1,
  modificationHash: 1,
  reviewer: 1,
  reviewDate: 1,
};

export const updateSelectionStateOfAllRecords = (records, isSelected) => {
  const selectedRecords = {};
  forEach(records, (record) => {
    selectedRecords[record.id] = isSelected;
  });
  return selectedRecords;
};

export const checkIfAllRecordsSelected = (selectedRecords) => {
  return (
    filter(selectedRecords, (isSelected) => {
      return !isSelected;
    }).length === 0
  );
};

export const addEmptyRecord = (newRecords) => {
  return addRecordToObjectsWithKeyValue({
    objects: newRecords,
    key: Date.now(),
    value: newEmptyRecord,
  });
};

export const saveOriginalRecords = (originalRecords, recordId, oldRecord) => {
  if (!originalRecords[recordId]) {
    return {
      ...originalRecords,
      [recordId]: { ...oldRecord },
    };
  }
  return originalRecords;
};

export function getCurrentTab(callback) {
  return (dispatch, getState) => {
    const {currentTab} = getState().markets;
    return dispatch(callback(currentTab));
  };
}

export function getCurrentTabData(getState) {
  const {markets} = getState();
  const {currentTab} = markets;
  const currentTabData = markets.tabData[currentTab];
  const {recordStatusFilters} = markets;
  return { currentTab, currentTabData, recordStatusFilters };
}

export function getCurrentUser(getState) {
  return { user: getState().user };
}

export function getSelectedRecords(records) {
  const selected = [];
  forEach(records, (_, key) => {
    if (records[key]) {
      selected.push(key.toString());
    }
  });
  return selected;
}

export const getYearMonthDay = (date, format) => {
  return {
    year: moment(date, format).year(),
    month: moment(date, format).month(),
    day: moment(date, format).date(), // get the day in a month
  };
};

export const getNews = (record) => {
  if (record.isNew) {
    return {
      link: record.articleLink,
      date: record.newsDate,
    };
  }
  return {
    ...record.news,
    id: parseInt(record.news.id, 10),
    date: record.newsDate,
    link: record.articleLink,
  };
};

export function getStatus(newStatus, currentStatus, recordStatusFilterOptions) {
  if (newStatus === APPROVED) {
    return getKeyValueFromListViaOtherKey(
      recordStatusFilterOptions,
      'text',
      APPROVED,
      'id'
    );
  } if (newStatus === REJECTED) {
    return getKeyValueFromListViaOtherKey(
      recordStatusFilterOptions,
      'text',
      REJECTED,
      'id'
    );
  } if (newStatus === NEEDS_APPROVAL) {
    return getKeyValueFromListViaOtherKey(
      recordStatusFilterOptions,
      'text',
      NEEDS_APPROVAL,
      'id'
    );
  } if (newStatus === IS_DUPLICATE) {
    return IS_DUPLICATE_CODE;
  }
  // Status must === 'Saved'
  return currentStatus;
}

export function getRecordsInfo(selectedIds, records) {
  const recordsToPost = {};
  forEach(selectedIds, (id) => {
    recordsToPost[id] = { ...records[id] };
  });
  return recordsToPost;
}

export const getInputValueFromTable = (
  autoCompleteInputValues,
  id,
  columnName
) => {
  const fullKeyName = `${id}-${columnName}`;
  return autoCompleteInputValues[fullKeyName];
};

export const getMarketInfo = (record, id, autoCompleteInputValues) => {
  const marketInfo = getInputValueFromTable(
    autoCompleteInputValues,
    id,
    'market'
  );
  if (marketInfo) {
    return { id: parseInt(marketInfo.termId || 0, 10), name: marketInfo.term };
  }
  if (!record.isNew) {
    return { id: parseInt(record.marketId || 0, 10), name: record.market };
  }
  return { id: -1, name: '' };
};

export const getGeoInfo = (record, id, autoCompleteInputValues) => {
  const geoInfo = getInputValueFromTable(
    autoCompleteInputValues,
    id,
    'geography'
  );
  if (geoInfo) {
    return { id: parseInt(geoInfo.termId || 0, 10), name: geoInfo.term };
  }
  if (!record.isNew) {
    return { id: parseInt(record.geoId || 0, 10), name: record.geography };
  }
  return { id: -1, name: '' };
};

export const getAnalystInfo = (record, id, autoCompleteInputValues) => {
  const analystInfo = getInputValueFromTable(
    autoCompleteInputValues,
    id,
    'analyst'
  );
  if (analystInfo) {
    return {
      id: parseInt(analystInfo.termId || 0, 10),
      analyst: analystInfo.term,
    };
  }
  if (!record.isNew) {
    return { id: parseInt(record.analystId || 0, 10), analyst: record.analyst };
  }
  return { id: -1, analyst: '' };
};

export const getYearValue = (year) => {
  if (year.toString().toLowerCase() === 'n/a' || year === '') {
    return 0;
  }
  return isNaN(year) ? NaN : Number(year);
};

export const saveRecordsOrder = (rows) => {
  return map(rows, (row) => row.id);
};

export const getMarketIdAndNameOnly = (markets) => {
  return map(markets, (market) => {
    return {
      id: parseInt(market.id, 10) || 0,
      name: market.name || '',
    };
  });
};

export const getModifiedRecords = (originalRecords, recordsToPost) => {
  let modified = false;
  const modifiedRecords = {};
  const recordsDiffs = {};
  forEach(recordsToPost, (r) => {
    const difference = getObjDifferences(
      originalRecords[r.id],
      recordsToPost[r.id]
    );
    if (!isEmpty(difference)) {
      modified = true;
      modifiedRecords[r.id] = recordsToPost[r.id];
      recordsDiffs[r.id] = difference;
    }
  });

  return { modified, modifiedRecords, recordsDiffs };
};

export const getMergeConflicts = (currentData, rejectedData) => {
  const conflicts = {};
  forEach(currentData, (currentDataRecord) => {
    const rejectedDataRecord = rejectedData[currentDataRecord.id];
    const objDifference = getObjDifferences(
      rejectedDataRecord,
      currentDataRecord,
      KEYS_TO_IGNORE
    );
    if (!isEmpty(objDifference)) {
      conflicts[currentDataRecord.id] = objDifference;
    }
  });
  return conflicts;
};

export const initializeVisibility = (records) => {
  const updatedRecords = {};
  forEach(records, (record) => {
    updatedRecords[record.id] = false;
  });
  return updatedRecords;
};

export const updateShowHiddenRowDict = (records, recordId) => {
  return { ...records, [recordId]: !records[recordId] };
};

export const saveInnerTableRecords = (
  innerTable,
  formattedResponse,
  recordId
) => {
  return {
    ...innerTable,
    [recordId]: {
      innerTableRecords: keyBy(formattedResponse, 'id'),
      innerTableRecordsOrder: saveRecordsOrder(formattedResponse),
      innerTableOriginalRecords: keyBy(formattedResponse, 'id'),
    },
  };
};
