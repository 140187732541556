import cbiUtils, { handleAsyncError } from 'client/modules/common/utils/cbi';

export const TODAY = 'Today';
export const LAST_7_DAYS = 'Last 7 Days';
export const LAST_30_DAYS = 'Last 30 Days';
export const SET_USER_STATS =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_MANAGEMENT_QUEUE';
export const LOADING_STATS = 'ADMIN/NEWS/QUEUE/MANAGEMENT/LOADING_NEWS';
export const DONE_LOADING_STATS =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/DONE_LOADING_NEWS';
export const SET_TIME_PERIOD = 'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_TIME_PERIOD';

export function loadingStats() {
  return { type: LOADING_STATS };
}

export function doneLoadingStats() {
  return { type: DONE_LOADING_STATS };
}

export function setUserStats(stats) {
  return { type: SET_USER_STATS, stats };
}

export function setTimePeriod(period) {
  return { type: SET_TIME_PERIOD, period };
}

export function fetchUserStats() {
  return (dispatch) =>
    cbiUtils
      .apiCall({
        url: 'admin/news/user-stats',
        method: 'GET',
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((json) => {
        dispatch(setUserStats(json));
        dispatch(doneLoadingStats());
      });
}

export default function (
  state = {
    stats: [],
    isLoading: true,
    timePeriod: 'Today',
  },
  action
) {
  switch (action.type) {
    case SET_USER_STATS: {
      return { ...state, stats: action.stats };
    }
    case LOADING_STATS: {
      return { ...state, isLoading: true };
    }
    case DONE_LOADING_STATS: {
      return { ...state, isLoading: false };
    }
    case SET_TIME_PERIOD: {
      return { ...state, timePeriod: action.period };
    }
    default:
      return state;
  }
}
