export const SET_ALL_SESSION_DATA =
  'client/modules/account-sharing-details/actions/SET_ALL_SESSION_DATA';

export const searchForPaidUsers = (searchTerm) => ({
  types: [null, null],
  service: {
    endpoint: 'getPaidUsers',
    name: 'adminservice',
    body: {
      searchTerm,
      limit: 10,
    },
  },
});

export const searchForTeams = (searchTerm) => ({
  types: [null, null],
  service: {
    endpoint: 'searchForTeam',
    name: 'adminservice',
    body: {
      name: searchTerm,
      limit: 10,
    },
  },
});

export const getAllCsms = () => ({
  types: [null, null],
  service: {
    endpoint: 'getAllCsms',
    name: 'adminservice',
    body: {},
  },
});

export const fetchAllSuspiciousSessions = ({
  idUser,
  idTeam,
  csm,
  startDate,
  endDate,
  limit,
  offset,
}) => ({
  types: [null, SET_ALL_SESSION_DATA],
  service: {
    endpoint: 'getAllSuspiciousSessions',
    name: 'usereventsservice',
    body: {
      idUser,
      idTeam,
      csm,
      startDate,
      endDate,
      limit,
      offset,
    },
  },
});

export const fetchUserSuspiciousSessions = (idUser, startDate, endDate) => ({
  types: [null, null],
  service: {
    endpoint: 'getUserSuspiciousSessions',
    name: 'usereventsservice',
    body: {
      idUser: parseInt(idUser, 10),
      startDate,
      endDate,
    },
  },
});

export default fetchAllSuspiciousSessions;
