import {
  EntitySearchResults,
  SearchInfo,
} from '@cbinsights/cbientityservice/cbientityservice';
import { UseQueryResult, useQuery } from 'react-query';
import { SearchForEntity } from '../apis/SearchForEntity';

type SearchForEntityType = 'SearchForEntity';
type GetGeneralCbiEntityInfo = 'GetGeneralCbiEntityInfo';
type GeneralInfoDependencies = 'GeneralInfoDependencies';
type GetCompanyLogos = 'GetCompanyLogos';
type SearchForCity = 'SearchForCity';
type ListEntityDrafts = 'ListEntityDrafts';
type HasPendingDrafts = 'HasPendingDrafts';
type ListEntityNotes = 'ListEntityNotes';
type GetInvestorLogos = 'GetInvestorLogos';
type GetEntityDraftLogos = 'GetEntityDraftLogos';
type GetEntityInfo = 'GetEntityInfo';
type AllEntityStatusValues = 'AllEntityStatusValues';
type GetEntityFundings = 'GetEntityFundings';
type GenerateDescription = 'GenerateDescription';
type GenerateEntityBusinessModelTags = 'GenerateEntityBusinessModelTags';
type GetDrafters = 'GetDrafters';
type GetEntityLogo = 'GetEntityLogo';
type GetAllCountryValues = 'GetAllCountryValues';
type GetAllSectorValues = 'GetAllSectorValues';
type ListTagGroups = 'ListTagGroups';

export type EntityServicesKeys =
  | SearchForEntityType
  | GetGeneralCbiEntityInfo
  | GeneralInfoDependencies
  | GetCompanyLogos
  | SearchForCity
  | ListEntityDrafts
  | HasPendingDrafts
  | ListEntityNotes
  | GetInvestorLogos
  | GetEntityDraftLogos
  | GetEntityInfo
  | AllEntityStatusValues
  | GetEntityFundings
  | GenerateDescription
  | GenerateEntityBusinessModelTags
  | GetDrafters
  | GetEntityLogo
  | GetAllCountryValues
  | GetAllSectorValues
  | ListTagGroups;

export const useSearchForEntity = (
  request: Partial<SearchInfo>,
  enabled: boolean
): UseQueryResult<EntitySearchResults, Error> =>
  useQuery<
    EntitySearchResults,
    Error,
    EntitySearchResults,
    [EntityServicesKeys, Partial<SearchInfo>]
  >(['SearchForEntity', request], () => SearchForEntity(request), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: !!request.searchTerm && enabled,
  });
