import { request } from 'client/modules/common/utils/request';

/**
 *
 * @param req UpdateUserTeamRequest TODO: PAB-2106: Fix Request type
 * @returns
 */
export const updateUserTeam = (req: $TSFixMe): Promise<void> =>
  request({
    url: '/service/adminservice/UpdateUserTeam',
    body: req,
  }).then((r) => r.body);
