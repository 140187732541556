import { map, keyBy } from 'lodash';
import { updateObjectInListWithKeyValue } from 'client/modules/common/utils/objectHelper';
import * as actions from '../actions/merge-conflict';
import { SET_MERGE_CONFLICT_DATA } from '../actions/setMergeConflictData';

const initialState = {
  dataToPost: {},
  mergeConflicts: {},
  numberOfConflictingRows: 0,
  showMergeConflictModal: false,
  recordIdOrder: [],
  currentConflictNumber: 0,
  status: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_MERGE_CONFLICTS:
      return initialState;
    case SET_MERGE_CONFLICT_DATA: {
      return {
        ...state,
        dataToPost: keyBy(action.currentDataInTheDB, 'id'),
        mergeConflicts: action.mergeConflicts,
        numberOfConflictingRows: action.currentDataInTheDB.length,
        recordIdOrder: map(
          action.currentDataInTheDB,
          (newRecord) => newRecord.id
        ),
        currentConflictNumber: 0,
        status: action.status,
      };
    }
    case actions.OPEN_MERGE_CONFLICT_MODAL: {
      return {
        ...state,
        showMergeConflictModal: true,
      };
    }
    case actions.GO_TO_NEXT_CONFLICT: {
      return {
        ...state,
        currentConflictNumber: state.currentConflictNumber + 1,
      };
    }
    case actions.UPDATE_RECORD: {
      return {
        ...state,
        dataToPost: updateObjectInListWithKeyValue({
          objects: state.dataToPost,
          id: action.recordId,
          key: action.fieldName,
          value: action.newValue,
        }),
      };
    }
    default:
      return state;
  }
}
