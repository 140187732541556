import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deletePendingRelatedMarketRecords } from 'client/modules/markets/redux/actions/data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';

class DeletePendingRelatedMarketIconCell extends Component {
  handleDelete = (id) => () => {
    const [recordId] = id.split('-');
    this.props.deletePendingRelatedMarketRecords(recordId);
  };

  render() {
    const { id, status, disabled } = this.props;
    if (status !== 'NeedApproval') {
      // API should return status with enum number, not string 'NeedApproval'
      return null;
    }

    return (
      <BaseIcon
        handleOnClick={this.handleDelete(id)}
        disabled={disabled}
        dataTest={`${id}`}
        iconName="cancel"
        iconClassName="deleteIcon"
        iconStyles={{ top: '5px', fontSize: '20px' }}
        iconButtonStyles={{ width: 'inherit' }}
      />
    );
  }
}

DeletePendingRelatedMarketIconCell.propTypes = {
  deletePendingRelatedMarketRecords: PropTypes.func.isRequired,
  id: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
};

DeletePendingRelatedMarketIconCell.defaultProps = {
  id: '',
  status: '',
  disabled: false,
};

export default connect(null, { deletePendingRelatedMarketRecords })(
  DeletePendingRelatedMarketIconCell
);
