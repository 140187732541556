export const ACCOUNT_SHARING_DASHBOARD_TAB_NAME = 'accountSharingDashboard';
export const ACCOUNT_SHARING_DASHBOARD_ROUTE = 'account-sharing-dashboard';

export const SUSPICIOUS_USER_SESSIONS_TAB_NAME = 'suspiciousUserSessions';
export const SUSPICIOUS_USER_SESSIONS_ROUTE = 'suspicious-user-sessions';

export const getTabOptions = () => {
  return [
    {
      tabName: ACCOUNT_SHARING_DASHBOARD_TAB_NAME,
      label: 'Account Sharing Dashboard',
      route: ACCOUNT_SHARING_DASHBOARD_ROUTE,
    },
    {
      tabName: SUSPICIOUS_USER_SESSIONS_TAB_NAME,
      label: 'Suspicious User Sessions',
      route: SUSPICIOUS_USER_SESSIONS_ROUTE,
    },
  ];
};

export default getTabOptions;
