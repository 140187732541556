/* eslint-disable react/default-props-match-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import UserInfo from 'client/modules/news/queue/components/UserInfo';

const UserQueueDetails = ({ user, type, remainingCount }) => (
  <div className="text-black">
    {user.idUser && (
      <UserInfo
        name={`${user.firstName} ${user.lastName}`}
        id={user.idUser}
        userType={user.userType}
      />
    )}
    {remainingCount != null && (
      <div id="remainingEntries">
        <span style={{ fontWeight: 'bold' }}>
          Remaining{type ? ` ${type} ` : ' '}Entries:{' '}
        </span>
        {remainingCount}
      </div>
    )}
  </div>
);

UserQueueDetails.propTypes = {
  user: PropTypes.object.isRequired,
  remainingCount: PropTypes.number,
  type: PropTypes.string.isRequired,
};

UserQueueDetails.defaultProps = {
  remainingCount: null,
  type: null,
};

export default UserQueueDetails;
