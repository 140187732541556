/**
 * @deprecated This component is not longer supported, use FormikContainer.tsx
 * @summary Formik Container takes in a form and adds formik context to it
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, yupToFormErrors } from 'formik';
import { logInfo } from 'client/modules/common/utils/logInfo';
import {
  setToastError,
  getValidationErrorMessage,
} from 'client/modules/third-party-admin/profile-queue/utils';
import { FormUtilsContextProvider } from './FormUtilsContextProvider';

/**
 * @deprecated
 * @summary clean fields: formik should do this under the hood but there is an open bug for it.
 */
export function cleanFormValues(skema, formValues) {
  try {
    return skema.cast(formValues);
  } catch (error) {
    logInfo({
      error,
      message: `Form with id of (${formValues.id}) failed to be cleaned`,
      method: 'FormikContainer.cleanFormValues',
    });
  }

  return formValues;
}

// if we ever wanted to reuse this, we would just need to pass the error toast message as a prop
const FormikFormEffects = ({ formik }) => {
  React.useEffect(() => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      setToastError(getValidationErrorMessage('approve'));
    }
  }, [formik.submitCount, formik.isSubmitting, formik.isValid]);
  return null;
};

/**
 * @deprecated
 * It takes a schema, a formikProps object, and a formikProps.values object, and returns a new
 * formikProps.values object with the same values, but with the values of any fields that are not in
 * the schema removed
 * @param props - {
 * @returns A function that takes in a formikProps object and returns a React Fragment.
 */
export const FormikContainerOld = (props) => {
  const {
    Form,
    initialValues,
    schema,
    onSubmit,
    onReject,
    enableReinitialize,
    shouldHighlight,
    isHighlightable,
    noHighlightFields,
    customContext,
    ...rest
  } = props;

  // Hijack onSubmit to apply fix of cleaning form values
  const _onSubmit = (formData, formHelpers) =>
    onSubmit(cleanFormValues(schema, formData), formHelpers);

  const _contextValidation = (values) =>
    schema
      .validate(values, { abortEarly: false, context: values })
      .then(() => ({})) // empty error object
      .catch(yupToFormErrors);

  return (
    <FormUtilsContextProvider
      shouldHighlight={shouldHighlight}
      isHighlightable={isHighlightable}
      noHighlightFields={noHighlightFields}
      customContext={customContext}
    >
      <Formik
        initialValues={{
          ...initialValues,
          warnings: {}, // Formik does not have ability to deal with warnings yet, so we store them in the state.
        }}
        validate={_contextValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={_onSubmit}
        enableReinitialize={enableReinitialize}
      >
        {(formikProps) => (
          <React.Fragment>
            <FormikFormEffects formik={formikProps} />
            <Form
              {...formikProps}
              {...rest}
              handleReject={(options) =>
                onReject(
                  cleanFormValues(schema, formikProps.values),
                  formikProps,
                  options
                )
              }
            />
          </React.Fragment>
        )}
      </Formik>
    </FormUtilsContextProvider>
  );
};

FormikContainerOld.propTypes = {
  Form: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  enableReinitialize: PropTypes.bool,
  shouldHighlight: PropTypes.bool,
  isHighlightable: PropTypes.func,
  handleClear: PropTypes.func,
  user: PropTypes.object,
  options: PropTypes.object,
  clusterData: PropTypes.object,
  noHighlightFields: PropTypes.arrayOf(PropTypes.string),
  customContext: PropTypes.object,
};

FormikContainerOld.defaultProps = {
  enableReinitialize: false,
  shouldHighlight: false,
  noHighlightFields: [],
  customContext: {},
  isHighlightable: () => true,
};
