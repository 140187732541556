import { combineReducers } from 'redux';
import statusNotification from './status-notification';
import liveSearch from './live-search';
import modal from './modal';
import { RequestStatusState, requestStatusReducer } from './request-status';

export interface CommonState {
  liveSearch: $TSFixMe;
  modal: $TSFixMe;
  statusNotification: $TSFixMe;
  requestStatus: RequestStatusState;
}

const common = combineReducers({
  liveSearch,
  modal,
  statusNotification,
  requestStatus: requestStatusReducer,
});

export default common;
