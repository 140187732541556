import React from 'react';
import PropTypes from 'prop-types';
import PipelineLink from '../../PipelineLink';
import SuggestedComponentLink from '../../SuggestedComponentLink';
import RemoveComponentCandidateLink from '../../RemoveComponentCandidateLink';
import RemovePipelineLink from '../../RemovePipelineLink';
import HealthIcon from '../../HealthIcon';
import VertexPropertyField from '../../VertexPropertyField';
import AddNewUserLink from '../../AddNewUserLink';
import RemoveExistingPipelineUserLink from '../../RemoveExistingPipelineUserLink';
import RemoveNewUserLink from '../../RemoveNewUserLink';

const mapDataPlatformTableComponents = (type, props, commonProps) => {
  switch (type) {
    case 'pipelineLink':
      return <PipelineLink {...commonProps} pipelineName={props.rowId} />;
    case 'suggestedComponentLink':
      return (
        <SuggestedComponentLink {...commonProps} componentId={props.rowId} />
      );
    case 'removeComponentCandidateLink':
      return (
        <RemoveComponentCandidateLink
          {...commonProps}
          componentId={props.rowId}
        />
      );
    case 'removePipelineLink':
      return <RemovePipelineLink {...commonProps} componentId={props.rowId} />;
    case 'healthColumn':
      return <HealthIcon health={commonProps.value} />;
    case 'vertexPropertyField':
      return (
        <VertexPropertyField value={commonProps.value} label={props.rowId} />
      );
    case 'addNewUserLink':
      return <AddNewUserLink idUser={commonProps.value} />;
    case 'removeExistingPipelineUserLink':
      return <RemoveExistingPipelineUserLink idUser={commonProps.value} />;
    case 'removeNewUserLink':
      return <RemoveNewUserLink idUser={commonProps.value} />;
    default:
      return null;
  }
};

mapDataPlatformTableComponents.propTypes = {
  rowId: PropTypes.string.isRequired,
};

export default mapDataPlatformTableComponents;
