import React, { ReactNode } from 'react';

export const InitialPageLayout = ({
  children,
  addPadding = true,
  defaultTailwindFont = true,
}: {
  children: ReactNode;
  addPadding;
  defaultTailwindFont: boolean;
}) => (
  <div
    className={`${addPadding ? 'px-4' : ''} ${
      defaultTailwindFont ? 'font-body' : ''
    } text-base`}
  >
    {children}
  </div>
);
