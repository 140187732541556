const RECENT_SEARCHES_KEY = 'recentSearches';
const MAX_RECENT_SEARCHES = 5; // number of recent searches to store

export type SearchEntry = {
  type: 'user' | 'team';
  text: string;
  id: number;
};

interface SearchType {
  addSearchEntry: (term: SearchEntry) => void;
  getRecentSearches: () => SearchEntry[];
  clearSearchHistory: () => void;
  removeSearchEntry: (id: number, type: SearchEntry['type']) => void;
}

const SearchUtils: SearchType = {
  addSearchEntry: (entry: SearchEntry) => {
    const searches = SearchUtils.getRecentSearches();
    const newSearches = [
      entry,
      ...searches.filter((e) => e.id !== entry.id || e.type !== entry.type),
    ].slice(0, MAX_RECENT_SEARCHES);
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(newSearches));
  },
  getRecentSearches: () => {
    const searches = localStorage.getItem(RECENT_SEARCHES_KEY);
    return searches ? JSON.parse(searches) : [];
  },
  removeSearchEntry: (id: number, type: SearchEntry['type']) => {
    const searches = SearchUtils.getRecentSearches().filter(
      (e) => e.id !== id || e.type !== type
    ); // Filter the search entry to remove
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(searches));
  },
  clearSearchHistory: () => {
    localStorage.removeItem(RECENT_SEARCHES_KEY);
  },
};

export default SearchUtils;
