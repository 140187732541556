import {
  GetFederatedTeamRequest,
  GetFederatedTeamResponse,
} from '@cbinsights/authservice/authservice';
import { request } from 'client/modules/common/utils/request';

export const getFederatedTeam = (
  req: Partial<GetFederatedTeamRequest>
): Promise<GetFederatedTeamResponse> =>
  request({
    url: '/service/authservice/GetFederatedTeam',
    body: req,
  }).then((r) => r.body);
