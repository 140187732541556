import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableFooter as MuiFooter,
  TableRow,
} from 'client/component-library/Table';
import TablePagination from 'client/modules/common/components/Table/TablePagination';
import { MenuItem } from 'client/component-library/Menu';
import { MuiSelect as Select } from 'client/component-library/Select';

/**
 * @deprecated
 * It renders a footer for a table that contains a pagination component and a select dropdown that
 * allows the user to jump to a specific page
 * @param props - {
 * @returns A React component
 */
const TableFooter = (props) => {
  const {
    totalRecords,
    recordsPerPage,
    pageNumber,
    handleChangePageNumber,
    handleUpdateRecordsPerPage,
    colSpan,
    rowsPerPageOptions,
  } = props;
  const lastPage = Math.floor(totalRecords / recordsPerPage);
  const moreLeft = totalRecords % recordsPerPage;
  const pageList = Array.from(Array(lastPage).keys());

  return (
    <MuiFooter>
      <TableRow>
        <TablePagination
          colSpan={colSpan}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          pageNumber={pageNumber}
          handleChangePageNumber={handleChangePageNumber}
          handleUpdateRecordsPerPage={handleUpdateRecordsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
        <TableCell
          cellStyle={{
            verticalAlign: 'middle',
          }}
        >
          <span>Page:</span>
          <Select
            style={{
              textAlign: 'center',
              fontSize: '15px',
              width: '48px',
            }}
            value={pageNumber}
            onChange={(event) =>
              handleChangePageNumber(event, event.target.value)
            }
            inputProps={{
              name: 'pageNumber',
              id: 'page-number',
            }}
          >
            <MenuItem key={0} value={0}>
              1
            </MenuItem>
            {map(pageList, (page) => {
              // Make sure the select dropdown doesn't crash the browser
              if (
                page > pageNumber - 50 &&
                page < pageNumber + 50 &&
                page !== 0 &&
                page !== lastPage
              ) {
                return (
                  <MenuItem key={page} value={page}>
                    {page + 1}
                  </MenuItem>
                );
              }
              return null;
            })}
            {lastPage !== 0 && moreLeft && (
              <MenuItem key={lastPage} value={lastPage}>
                {lastPage + 1}
              </MenuItem>
            )}
          </Select>
        </TableCell>
      </TableRow>
    </MuiFooter>
  );
};

TableFooter.propTypes = {
  colSpan: PropTypes.number,
  handleChangePageNumber: PropTypes.func.isRequired,
  handleUpdateRecordsPerPage: PropTypes.func.isRequired,
  totalRecords: PropTypes.number,
  recordsPerPage: PropTypes.number,
  pageNumber: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

TableFooter.defaultProps = {
  colSpan: 3,
  totalRecords: 0,
  recordsPerPage: 10,
  pageNumber: 1,
  rowsPerPageOptions: [5, 10, 25],
};

export default TableFooter;
