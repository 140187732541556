import { UserSessionData } from '@cbinsights/userv2service/userv2service';
import * as actions from '../actions/user';

export interface UserSession extends Partial<UserSessionData> {
  adminPagePermissions: Record<string, unknown>;
  isAuthenticated: boolean;
  isLoggedIn: boolean;
}
export interface UserData {
  data: UserSession;
}
const initialState: UserSession = {
  adminPagePermissions: {},
  email: '',
  isAuthenticated: false,
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  idUser: undefined,
};

export default function (state = initialState, action): UserSession {
  switch (action.type) {
    case actions.FETCH_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actions.SET_USER_AUTHENTICATION_INFO: {
      return {
        ...state,
        isAuthenticated: true,
        isLoggedIn: !!action.payload.isLoggedIn,
      };
    }
    default:
      return state;
  }
}
