import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import * as actions from '../actions/details';

const getSortOrder = (sortField, sortDirection) => {
  return sortField ? { sortField, sortDirection: sortDirection || 'asc' } : {};
};

const initialState = {
  sortInfo: {}, // { sortField: start_date, sortDirection:desc }
  pageNumber: 0,
  recordsPerPage: 5,
  suggestionRecordsPerPage: 3,
  newVertexesPageNumber: 0,
  newVertexesRecordsPerPage: 3,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_PAGE_NUMBER:
      updateUrlParams('pageNumber', action.pageNumber);
      return {
        ...state,
        pageNumber: parseInt(action.pageNumber, 10),
      };
    case actions.UPDATE_RECORDS_PER_PAGE:
      updateUrlParams('recordsPerPage', action.recordsPerPage);
      return {
        ...state,
        recordsPerPage: parseInt(action.recordsPerPage, 10),
      };
    case actions.INITIALZE_DATA_DETAILS:
      return {
        ...state,
        pageNumber:
          parseInt(action.pageNumber, 5) ||
          state.pageNumber ||
          initialState.pageNumber,
        recordsPerPage:
          parseInt(action.recordsPerPage, 5) || initialState.recordsPerPage,
        sortInfo: getSortOrder(action.sortField, action.sortDirection),
      };
    case actions.UPDATE_SORT_ORDER: {
      updateUrlParams('sortField', action.sortField);
      updateUrlParams('sortDirection', action.sortDirection);
      return {
        ...state,
        sortInfo: getSortOrder(action.sortField, action.sortDirection),
      };
    }
    case actions.UPDATE_SUGGESTION_PAGE_NUMBER:
      updateUrlParams('suggestionPageNumber', action.pageNumber);
      return {
        ...state,
        suggestionPageNumber: action.pageNumber,
      };
    case actions.UPDATE_SUGGESTION_RECORDS_PER_PAGE:
      updateUrlParams('suggestionRecordsPerPage', action.recordsPerPage);
      return {
        ...state,
        suggestionRecordsPerPage: action.recordsPerPage,
      };
    case actions.INITIALIZE_SUGGESTION_DATA_DETAILS:
      return {
        ...state,
        suggestionPageNumber:
          parseInt(action.pageNumber, 5) || initialState.pageNumber,
        suggestionRecordsPerPage:
          parseInt(action.recordsPerPage, 3) ||
          initialState.suggestionRecordsPerPage,
      };
    case actions.UPDATE_NEW_VERTEXES_PAGE_NUMBER:
      updateUrlParams('newVertexesPageNumber', action.pageNumber);
      return {
        ...state,
        newVertexesPageNumber: action.pageNumber,
      };
    case actions.UPDATE_NEW_VERTEXES_RECORDS_PER_PAGE:
      updateUrlParams('newVertexesRecordsPerPage', action.recordsPerPage);
      return {
        ...state,
        newVertexesRecordsPerPage: action.recordsPerPage,
      };
    case actions.INITIALIZE_NEW_VERTEXES_DATA_DETAILS:
      return {
        ...state,
        newVertexesPageNumber:
          parseInt(action.pageNumber, 5) || initialState.newVertexesPageNumber,
        newVertexesRecordsPerPage:
          parseInt(action.recordsPerPage, 3) ||
          initialState.newVertexesRecordsPerPage,
      };
    default:
      return state;
  }
}
