import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import './modal.css';

/** @deprecated */
class Modal extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    handleCloseModal: PropTypes.func,
    open: PropTypes.bool.isRequired,
    modalClassName: PropTypes.string,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    modalClassName: '',
    handleCloseModal: () => {},
  };

  handleClose = (event) => this.props.handleCloseModal(event);

  /**
   * @deprecated
   * This function renders the modal and its children
   * @returns A modal that is open or closed based on the open prop.
   */
  render() {
    const { open, modalClassName, classes, children } = this.props;
    return (
      <Dialog
        classes={classes}
        maxWidth={false}
        open={open}
        className={modalClassName}
        onClose={this.handleClose}
      >
        {children}
      </Dialog>
    );
  }
}

const styles = {
  paper: {
    width: '840px',
  },
};

/** @deprecated */
export default withStyles(styles)(Modal);
