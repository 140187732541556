import { useFormUtilsContext } from 'client/modules/common/form/containers/hooks/useFormUtilsContext';
import get from 'lodash/get';


export function deriveChildProps(fieldMetaData, name, context, fieldType) {
  const {
    shouldHighlight,
    noHighlightFields,
    highlightMap,
    setHighlightMap,
    isHighlightable = () => true,
  } = context;
  const derivedProps = {};

  // derive shouldHighlight
  let deriveShouldHighlight =
    shouldHighlight &&
    isHighlightable({ name }) &&
    !noHighlightFields.includes(name) &&
    !fieldMetaData.touched &&
    !!fieldMetaData.initialValue;

  /**
   * We are having the consumer do the below so we can properly update the values in the highlightMap
   */

  // account for injections of default values for simpleSelects
  deriveShouldHighlight =
    deriveShouldHighlight &&
    get(fieldMetaData, 'initialValue.name', '') !== 'Select One' &&
    get(fieldMetaData, 'initialValue', '') !== 'Select One';

  // need this line or all radio buttons within the same field context would be highlighted
  if (fieldType === 'radio') {
    deriveShouldHighlight =
      deriveShouldHighlight &&
      fieldMetaData.initialValue === fieldMetaData.value;
  }

  // set value in highlightMap
  if (!highlightMap[name] && deriveShouldHighlight) {
    setHighlightMap(name, deriveShouldHighlight);
  } else if (highlightMap[name] && !deriveShouldHighlight) {
    setHighlightMap(name, deriveShouldHighlight);
  }

  derivedProps.shouldHighlight = deriveShouldHighlight;

  return derivedProps;
}

export function useDerivedChildProps(fieldMetaData, name, fieldType) {
  const cbiFormikContext = useFormUtilsContext();
  return deriveChildProps(fieldMetaData, name, cbiFormikContext, fieldType);
}
