import React from 'react';
import PropTypes from 'prop-types';

/**
 * @deprecated
 * If the value prop is truthy, return the value prop, otherwise return the defaultValue prop
 * @returns A React component that renders a div with the value of the value prop. If the value prop is
 * not provided, the defaultValue prop is used.
 */
const TextCell = ({ value, dataTest, defaultValue, ...rest }) => {
  if (value || !defaultValue) {
    return (
      <div data-test={dataTest} {...rest}>
        {value}
      </div>
    );
  }
  return (
    <div data-test={dataTest} {...rest}>
      {defaultValue}
    </div>
  );
};

TextCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTest: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextCell.defaultProps = {
  value: '',
  dataTest: '',
  defaultValue: '',
};

export default TextCell;
