export const getEnv = () => {
  switch (process.env.ENV_NAME) {
    case 'local':
    case 'dev':
      return 'app-dev.';
    case 'stg':
      return 'app-staging.';
    default:
      return 'app.';
  }
};

export const buildEnvURL = (path: string) => {
  return `https://${getEnv()}cbinsights.com/${path}`;
};
