import {
  SearchInfo,
  EntitySearchResults,
} from '@cbinsights/cbientityservice/cbientityservice';
import { request } from 'client/modules/common/utils/request';

export const SearchForEntity = ({
  searchTerm,
  entityType,
  serviceProvidersOnly,
  searchDrafts = false,
  limit = 10,
}: Partial<SearchInfo>): Promise<EntitySearchResults> => {
  return request({
    url: '/service/cbientityservice/SearchForEntity',
    body: {
      searchTerm,
      limit,
      entityType,
      serviceProvidersOnly,
      searchDrafts,
    },
  }).then((r): EntitySearchResults => r.body);
};
