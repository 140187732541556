export const listOfMarketsTableColumnNames = [
  {
    name: '',
    width: '13px',
    key: '',
    includes: [{ type: 'checkbox', key: 'id' }],
  },
  {
    name: 'Market',
    width: '400px',
    key: 'name',
    sort: true,
    sortKey: 'market',
    includes: [{ type: 'markets-listOfMarketsName', key: 'name' }],
  },
  {
    name: 'Number of Market Detail Rows',
    width: '200px',
    key: 'numMarketSizingDetailsNeedApproval',
    sort: true,
    sortKey: 'number_of_market_sizings',
    includes: [{ type: 'text', key: 'numMarketSizingDetailsNeedApproval' }],
  },
  {
    name: 'Number of Associate Companies',
    width: '200px',
    key: 'numAssocCompaniesNeedApproval',
    sort: true,
    sortKey: 'number_of_associated_companies',
    includes: [{ type: 'text', key: 'numAssocCompaniesNeedApproval' }],
  },
  {
    name: 'Number of Related Markets',
    width: '200px',
    key: 'numRelatedMktsNeedApproval',
    sort: true,
    sortKey: 'number_of_related_markets',
    includes: [{ type: 'text', key: 'numRelatedMktsNeedApproval' }],
  },
];

export default listOfMarketsTableColumnNames;
