import { CbiEntityRoutes } from 'client/modules/cbi-entity/RoutesHelpers';
import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useGetPackagesQuery } from 'client/modules/trial-package/services/hooks/useGetPackagesQuery';
import { PermissionType } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';
import { teamPermissions } from '../permissions/Team';
import { updateTeamPackage } from '../services/api/UpdateTeamPackage';
import useFuseSearch from './useFuseSearch';

export const useChangePackage = (
  oldPackageId?: number,
  oldPackageName?: string
) => {
  const { data } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    data,
    teamPermissions.pageId,
    teamPermissions.sections[1].sectionId
  );

  const hasChangePackagePermission = section.permissionTypes.includes(
    PermissionType.PUBLISH
  );

  const { id } = useParams<typeof CbiEntityRoutes.Id>();
  const [packageOptions, setPackageOptions] = useState<
    Array<DefaultOptionType>
  >([]);

  const { setSearchQuery, results: filteredResults } = useFuseSearch({
    data: packageOptions,
    options: { keys: ['label'], shouldSort: true, threshold: 0.3 },
  });

  const updatePackageMutation = useMutation(updateTeamPackage);

  const onSubmit = (packageID, packageStart, packageEnd) => {
    return new Promise((resolve, reject) => {
      const startDate = moment(packageStart).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(packageEnd).format('YYYY-MM-DD HH:mm:ss');
      updatePackageMutation.mutate(
        {
          id_package: packageID,
          package_start: startDate,
          package_end: endDate,
          id_team: Number(id),
        },
        {
          onSuccess: () => {
            resolve(true);
          },
          onError: (e) => {
            reject(e);
          },
        }
      );
    });
  };

  const { data: packagesData } = useGetPackagesQuery({
    enabled: true,
    params: {},
  });

  useEffect(() => {
    if (!packagesData) {
      return;
    }
    const options = packagesData.packages.map((item) => ({
      label: item.name,
      value: item.id_package,
      disabled: false,
    }));

    if (!options.find((item) => item.value === oldPackageId)) {
      options.push({
        label: oldPackageName,
        value: oldPackageId,
        disabled: true,
      });
    }
    setPackageOptions(options);
  }, [packagesData]);

  return {
    fields: {
      package: {
        options: packageOptions,
      },
    },
    onSubmit,
    packagesData,
    hasChangePackagePermission,
    setSearchPackageQuery: setSearchQuery,
    filteredResults,
  };
};

export default useChangePackage;
