/* eslint-disable no-else-return */
import { marketSuggestionHandler } from 'client/modules/markets/redux/actions/autocomplete-input';
import { adminUserAdminSuggestionHandler } from 'client/modules/admin-user-admin/redux/actions/autocomplete-input';
import { cbiEntitySuggestionHandler } from 'client/modules/cbi-entity/redux/actions/autocomplete-input';

import {
  MARKET_SUGGESTION_HANDLER,
  ADMIN_USER_ADMIN_SUGGESTION_HANDLER,
  BUSINESS_RELATIONSHIP_SUGGESTION_HANDLER,
  CBI_ENTITY_SUGGESTION_HANDLER,
} from 'client/modules/common/components/Table/utils/Constants';
import { ltrim } from 'client/modules/common/utils/truncateString';
import { entitySearchRequest } from 'client/modules/cbi-entity/redux/utils/api-request-helper';
import { entityType } from 'client/modules/cbi-entity/redux/utils/constants';
import { updateSuggestionsHelper } from 'client/modules/cbi-entity/redux/actions/search-bar';
import { updateSuggestionTypeError } from './autocomplete-input';

export const businessRelationshipSuggestionHandler = (
  dispatch,
  getState,
  inputId,
  term,
  suggestionType
) => {
  if (suggestionType === 'player') {
    const formattedTerm = ltrim(term);
    if (!formattedTerm) {
      return null;
    }
    return dispatch(entitySearchRequest(formattedTerm, entityType.ENTITY)).then(
      ({ entitySearchResults }) =>
        dispatch(
          updateSuggestionsHelper(formattedTerm, inputId, entitySearchResults)
        )
    );
  }
  return dispatch(updateSuggestionTypeError());
};

export const updateSuggestions = (
  inputId,
  term,
  suggestionType,
  suggestionHandler
) => {
  return (dispatch, getState) => {
    if (term === '') {
      return dispatch(updateSuggestionTypeError());
    } else if (suggestionHandler === MARKET_SUGGESTION_HANDLER) {
      return marketSuggestionHandler(dispatch, inputId, term, suggestionType);
    } else if (suggestionHandler === ADMIN_USER_ADMIN_SUGGESTION_HANDLER) {
      return adminUserAdminSuggestionHandler(
        dispatch,
        getState,
        inputId,
        term,
        suggestionType
      );
    } else if (suggestionHandler === BUSINESS_RELATIONSHIP_SUGGESTION_HANDLER) {
      return businessRelationshipSuggestionHandler(
        dispatch,
        getState,
        inputId,
        term,
        suggestionType
      );
    } else if (suggestionHandler === CBI_ENTITY_SUGGESTION_HANDLER) {
      return cbiEntitySuggestionHandler(
        dispatch,
        getState,
        inputId,
        term,
        suggestionType
      );
    }
    return dispatch(updateSuggestionTypeError());
  };
};
