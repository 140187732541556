import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'client/component-library/Snackbar';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import Fade from 'client/component-library/Transitions/Fade';

const anchorOrigin = { vertical: 'top', horizontal: 'center' };

/**
 * @deprecated
 * It returns a Snackbar component from Material UI that displays a message and an optional close icon
 * @param props - {
 * @returns A function that returns a div with a Snackbar component.
 */
const Notification = (props) => {
  const {
    open,
    message,
    autoDisappear,
    handleCloseNotification,
    notificationStyles,
    notificationType,
    marginBottom,
  } = props;
  if (autoDisappear) {
    return (
      <div>
        <Snackbar
          key={notificationType}
          anchorOrigin={anchorOrigin}
          open={open}
          onClose={handleCloseNotification}
          TransitionComponent={Fade}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: `${notificationStyles}`,
          }}
          message={message}
          autoHideDuration={7000}
          style={{
            display: 'inline-block',
            position: 'relative',
            marginBottom: `${marginBottom}px`,
            top: '0px',
          }}
        />
      </div>
    );
  }
  return (
    <div>
      <Snackbar
        key={notificationType}
        anchorOrigin={anchorOrigin}
        open={open}
        TransitionComponent={Fade}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: `${notificationStyles}`,
        }}
        message={message}
        autoHideDuration={null}
        style={{
          display: 'inline-block',
          position: 'relative',
          marginBottom: `${marginBottom}px`,
          top: '0px',
        }}
        action={[
          <BaseIcon
            key="close"
            handleOnClick={handleCloseNotification}
            dataTest={`${notificationType}-message-close-icon`}
            iconName="close"
            isIconBlock={false}
          />,
        ]}
      />
    </div>
  );
};

Notification.propTypes = {
  autoDisappear: PropTypes.bool,
  handleCloseNotification: PropTypes.func.isRequired,
  notificationType: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  notificationStyles: PropTypes.string,
  open: PropTypes.bool,
  marginBottom: PropTypes.number,
};

Notification.defaultProps = {
  autoDisappear: true,
  message: '',
  notificationStyles: '',
  open: false,
  marginBottom: 0,
};

export default Notification;
