export const SET_NAVIGATION_STRUCTURE =
  'client/modules/navigation/navigation/SET_NAVIGATION_STRUCTURE';
export const SET_CONFIRM_MODAL_PROPS =
  'client/modules/navigation/navigation/SET_CONFIRM_MODAL_PROPS';

export const setNavigationStructure = () => ({
  types: [null, SET_NAVIGATION_STRUCTURE],
  service: {
    endpoint: 'GetAdminNavigation',
    name: 'adminpermissionservice',
    body: {},
  },
});

export const setConfirmModalProps = (props) => {
  return {
    type: SET_CONFIRM_MODAL_PROPS,
    props,
  };
};
