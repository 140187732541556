export const tableHeaderStyle = {
  // display: 'block',
};

export const tableBodyStyle = {
  // display: 'block',
  overflowWrap: 'break-word',
};

export const tableFooterStyle = {
  // display: 'block',
};
