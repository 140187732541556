import React from 'react';
import { AntPageLayoutWithHeader } from 'client/modules/permissions/components/AntPageLayoutWithHeader';

import { List } from 'antd';
import { useMarketForAdmin } from '../hooks/useMarketsList';
import { useRefreshMarkets } from '../hooks/useRefreshMarkets';
import { useListIndustries } from '../service/hooks/useListIndustries';
import { MarketListHeader } from '../components/MarketListHeader';
import { MarketCardListItem } from '../components/MarketCardListItem';

export const MarketList = () => {
  const { isFetching: isIndustryFetching } = useListIndustries({
    enabled: false,
  });
  const { areMarketsFetched, markets, filteredMarkets, setFilteredMarkets } =
    useMarketForAdmin(true);
  const {
    isMarketRefreshingDict,
    areMarketsRefreshing,
    areMarketsCompetitorRefreshing,
    selectMarketToRefresh,
    selectedMarkets,
    refreshMarket,
    refreshMarkets,
    refreshMarketsCompetitor,
    refreshMarketsCompetitors,
  } = useRefreshMarkets({
    markets: filteredMarkets,
    setMarkets: setFilteredMarkets,
  });
  return (
    <AntPageLayoutWithHeader title="Market Reports">
      <MarketListHeader
        markets={markets}
        setFilteredMarkets={setFilteredMarkets}
        areMarketsFetched={areMarketsFetched}
        areMarketsRefreshing={areMarketsRefreshing}
        refreshMarkets={refreshMarkets}
        areMarketsCompetitorRefreshing={areMarketsCompetitorRefreshing}
        refreshMarketsCompetitors={refreshMarketsCompetitors}
        selectedMarkets={selectedMarkets}
      />
      <List
        dataSource={filteredMarkets}
        pagination={{ position: 'top' }}
        style={{ marginBottom: 40 }}
        loading={!areMarketsFetched || isIndustryFetching}
        renderItem={(item) => (
          <MarketCardListItem
            item={item}
            selectMarketToRefresh={selectMarketToRefresh}
            selectedMarkets={selectedMarkets}
            isMarketRefreshingDict={isMarketRefreshingDict}
            refreshMarket={refreshMarket}
            refreshMarketsCompetitor={refreshMarketsCompetitor}
          />
        )}
      />
    </AntPageLayoutWithHeader>
  );
};
