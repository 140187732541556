import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import {
  DraftStatus,
  EntitySearchResult,
  IdType,
} from '@cbinsights/cbientityservice/cbientityservice';
import { Space, Tag } from 'antd';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { debounce } from 'lodash';
import React, { ReactElement, useCallback, useState } from 'react';
import { useSearchForEntity } from '../../services/general-info/hooks/UseSearchForEntity';
import './EntitySearch.css';

export type createEntityOption = 'create-entity-option';

export type EntitySearchProps = {
  initialSelection?: { label: string };
  onSelection?: (
    entityId: createEntityOption | string,
    entitySearchResult: EntitySearchResult
  ) => void;
  onCreate?: (search: string) => void;
  showCreateEntity?: boolean;
  placeholder?: string;
};

const DRAFT_STATUSES = {
  [DraftStatus.READY_FOR_ENRICHMENT]: 'Ready for enrichment',
  [DraftStatus.READY_FOR_QA]: 'Ready for QA',
} as const;
const DRAFT_STATUS_COLORS = {
  [DraftStatus.READY_FOR_ENRICHMENT]: 'processing',
  [DraftStatus.READY_FOR_QA]: 'success',
} as const;

export const EntitySearch = ({
  initialSelection: initialValues = {
    label: undefined,
  },
  onSelection,
  onCreate,
  showCreateEntity = true,
  placeholder = 'Search for an entity...',
}: EntitySearchProps): ReactElement => {
  const [search, setSearch] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const entity = useSearchForEntity(
    {
      searchTerm: search,
      entityType: IdType.CBI_ENTITY,
      searchVcFunds: true,
      limit: 200,
      searchDrafts: true,
    },
    !!search
  );

  const debounceFetcher = useCallback(
    debounce((searchTerm) => {
      setSearch(searchTerm);
    }, 300),
    []
  );

  const handleCreate = (e) => {
    onCreate(search);
    setDropdownOpen(false);
    e.stopPropagation();
  };

  const newEntityOption: Array<{
    value?: number | string;
    label: React.ReactNode;
    disabled?: boolean;
  }> = showCreateEntity
    ? [
        {
          value: 'create-entity-option' as const,
          label: (
            <button
              type="button"
              onClick={handleCreate}
              className="w-full text-left py-1.5 font-semibold text-black"
            >
              Create Entity Draft
            </button>
          ),
        },
        {
          value: entity?.isFetching ? 'loading' : undefined,
          label: <Spin size="small" />,
          disabled: true,
        },
      ]
    : [
        {
          value: entity?.isFetching ? 'loading' : undefined,
          label: <Spin size="small" />,
          disabled: true,
        },
      ];

  const handleOnSelection = (value) => {
    return onSelection(
      value,
      entity.data.entitySearchResults.find((el) => el.idCbiEntity === value)
    );
  };

  return (
    <div
      className="relative entity-search-container"
      data-test="entity-search-container"
    >
      <Select
        className="entity-search text-gray-500"
        data-test="entity-search"
        showSearch
        value={initialValues.label ?? null}
        onSearch={debounceFetcher}
        onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
        showArrow={false}
        placeholder={placeholder}
        onChange={handleOnSelection}
        filterOption={false}
        notFoundContent={entity?.isFetching ? <Spin size="small" /> : undefined}
        options={newEntityOption
          .filter((el) => !!el?.value)
          .concat(
            (entity?.data?.entitySearchResults ?? []).map((el) => ({
              value: el.idCbiEntity || el.idEntityDraft,
              label: (
                <div
                  className="entity-item"
                  data-test={`entity-item-${
                    el.idCbiEntity || el.idEntityDraft
                  }`}
                >
                  <div className="flex flex-row justify-between">
                    <div>
                      <span
                        data-test="entity-name"
                        className="entity-name font-bold"
                      >
                        {el.name}
                      </span>
                      <div className="entity-link">
                        <a
                          data-test="entity-url"
                          className="text-blue-500"
                          target="_blank"
                          href={`//${el.url}`}
                          rel="noreferrer"
                        >
                          {el.url}
                        </a>
                      </div>
                    </div>
                    <Tag
                      className={`${
                        DRAFT_STATUSES[el?.draftStatus] ? null : 'hidden'
                      } self-start`}
                      color={DRAFT_STATUS_COLORS[el?.draftStatus]}
                    >
                      {DRAFT_STATUSES[el?.draftStatus]}
                    </Tag>
                  </div>

                  {(!!el?.alias || !!el?.altUrl) && (
                    <Space>
                      {el?.alias && (
                        <>
                          <span className="font-semibold">Alias: </span>
                          <span>{el?.alias}</span>
                        </>
                      )}
                      {el?.altUrl && (
                        <>
                          <span className="font-semibold">Alternate URL: </span>
                          <a
                            data-test="entity-alt-url"
                            className="text-blue-500"
                            target="_blank"
                            href={`//${el?.altUrl}`}
                            rel="noreferrer"
                          >
                            {el?.altUrl}
                          </a>
                        </>
                      )}
                    </Space>
                  )}
                  <p
                    data-test="entity-description"
                    className="entity-description whitespace-normal text-gray-500"
                  >
                    {el.description}
                  </p>
                </div>
              ),
            }))
          )}
        open={dropdownOpen}
      />

      <SearchOutlined className="absolute search-icon" />
    </div>
  );
};
