/*
 * This is partially copied from cbi-site, if the logic is ever changed
 * in cbi-site, it needs to be changed here as well
 */
import Hashids from 'hashids';

export const COMPANY_ENTITY = 'COMPANY_ENTITY_TYPE';
export const INVESTOR_ENTITY = 'INVESTOR_ENTITY_TYPE';

const prefix = {
  [COMPANY_ENTITY]: 'c',
  [INVESTOR_ENTITY]: 'i',
};

export const hashids = new Hashids('CBI Profiles');

/**
 * Generates a profile URL based on the provided entity information and the current environment.
 *
 * @param {Object} entityInfo - An object containing information about the entity.
 * @param {number} entityInfo.idCompanyOrIdInvestor - The unique identifier for the company or investor.
 * @param {string} entityInfo.type - The type of the entity (e.g., 'company', 'investor').
 *
 * @returns {string} A URL string representing the profile URL based on the provided entity information
 * and the current environment. The returned URL depends on the value of the `AFS_SERVER_ENV` variable
 * from the `process.env` object. If `AFS_SERVER_ENV` is equal to 'local', 'dev', 'stg', or any other
 * value, the corresponding URL is returned.
 */
export const getProfileURL = (entityInfo: {
  idCompanyOrIdInvestor: number;
  type: string;
}) => {
  const { idCompanyOrIdInvestor: id, type } = entityInfo;
  const encodedId = hashids.encode(id);

  const env = process.env.AFS_SERVER_ENV;

  switch (env) {
    case 'local':
      return `app-dev.cbinsights.com/profiles/${prefix[type]}/${encodedId}`;
    case 'dev':
      return `app-dev.cbinsights.com/profiles/${prefix[type]}/${encodedId}`;
    case 'stg':
      return `app-staging.cbinsights.com/profiles/${prefix[type]}/${encodedId}`;
    default:
      return `app.cbinsights.com/profiles/${prefix[type]}/${encodedId}`;
  }
};

export default getProfileURL;
