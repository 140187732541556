import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export async function copyTextToClipboard(text: string) {
  if (!text) {
    return Promise.reject();
  }

  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }

  return document.execCommand('copy', true, text);
}

export const useCopyTextToClipboard = (
  timeOut = 1000,
  onSuccess?: () => void
) => {
  const [isLoading, setIsLoadingCopyText] = React.useState(false);

  const callback = React.useCallback(async (value: string) => {
    setIsLoadingCopyText(true);
    await copyTextToClipboard(value);
    setTimeout(() => {
      if (onSuccess) onSuccess();
      setIsLoadingCopyText(false);
    }, timeOut);
  }, []);

  return {
    isLoading,
    copyTextToClipboard: callback,
  };
};

export const CopyToClipboard = ({ text }: { text: string }) => {
  const [tooltipTitle, setTooltipTitle] = useState('Click to copy');

  const onCopy = async () => {
    setTooltipTitle('Copied!');
    setTimeout(() => setTooltipTitle('Click to copy'), 3000);
  };

  const { copyTextToClipboard: copy } = useCopyTextToClipboard(500, onCopy);

  if (!text) {
    return null;
  }

  return (
    <Tooltip title={tooltipTitle}>
      <Button type="link" icon={<CopyOutlined />} onClick={() => copy(text)} />
    </Tooltip>
  );
};
