import { getCurrentTab } from '../utils/records-helper';

export const UPDATE_PAGE_NUMBER =
  'client/modules/stories/actions/tab-details/UPDATE_PAGE_NUMBER';
export const UPDATE_RECORDS_PER_PAGE =
  'client/modules/stories/actions/tab-details/UPDATE_RECORDS_PER_PAGE';
export const INITIALZE_DATA_DETAILS =
  'client/modules/stories/actions/tab-details/INITIALZE_DATA_DETAILS';
export const UPDATE_TOTAL_RECORDS =
  'client/modules/stories/actions/tab-details/UPDATE_TOTAL_RECORDS';
export const SAVE_URL_PARAMS =
  'client/modules/stories/actions/tab-details/SAVE_URL_PARAMS';

export function initializeDataDetailsFromUrlParams(urlParams) {
  return getCurrentTab((currentTab) => {
    return {
      type: INITIALZE_DATA_DETAILS,
      ...urlParams,
      currentTab,
    };
  });
}

export function updatePageNumber(pageNumber) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_PAGE_NUMBER,
      pageNumber,
      currentTab,
    };
  });
}

export function updateRecordsPerPage(recordsPerPage) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_RECORDS_PER_PAGE,
      recordsPerPage,
      currentTab,
    };
  });
}

export function updateTotalRecords(totalRecords) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_TOTAL_RECORDS,
      totalRecords,
      currentTab,
    };
  });
}

export function saveUrlParams(urlParams) {
  return getCurrentTab((currentTab) => {
    return {
      type: SAVE_URL_PARAMS,
      urlParams,
      currentTab,
    };
  });
}
