const styles = (theme) => ({
  text: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#000',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  root: {
    marginLeft: '10px',
  },
  textBlock: {
    paddingTop: '10px',
  },
  bold: {
    fontWeight: 'bold',
  },
  marketsBtn: {
    width: '110px',
    height: '12px',
    minHeight: '30px !important',
    margin: '10px 0 !important',
    padding: '5px 15px 0px 15px !important',
  },
  btnLabel: {
    fontSize: '11px',
    fontWeight: '300',
    textTransform: 'capitalize',
    paddingBottom: '4px',
  },
});

export default styles;
