import { PermissionType } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { useGetAdminPermissionsForUser } from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';
import React, { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

/**
 * Granular Page Permission System
 * Generally for DIO. Page permissions that uses admin-permissions-service
 * If the user doesn't have access to the path, redirect them to previous level in location.pathname
 * @param  - sectionId - the id of the section you want to check for access
 * @param  - pageId - the id of the adminPage you want to check for access
 * @param  - currentPath - the path of the section you want to check for access
 * @returns A ReactElement
 */
export const PermissionsProtectedRoute = ({
  sectionId,
  pageId,
  currentPath,
  children,
}: {
  pageId: number;
  sectionId?: number;
  currentPath: string;
  children?: ReactElement;
}): ReactElement => {
  const location = useLocation();
  const adminPermissionsQuery = useGetAdminPermissionsForUser();

  const hasPageAccess = adminPermissionsQuery.data?.adminPages?.filter(
    (el) => el.idAdminPage === pageId
  )[0];

  if (
    hasPageAccess.permissionTypes.filter(
      (permission) => permission !== PermissionType.AUDIT
    ).length === 0
  ) {
    return <Navigate to="/403" replace />;
  }

  if (!children) {
    return <Outlet />;
  }

  const sectionToAccess = hasPageAccess.adminSections.filter(
    (el) => el.idAdminSection === sectionId
  );

  if (sectionToAccess.length === 0) {
    return <Navigate to="/403" replace />;
  }

  if (
    sectionToAccess[0].permissionTypes.filter(
      (permission) => permission !== PermissionType.AUDIT
    ).length === 0
  ) {
    const redirectPath = location.pathname.replace(`/${currentPath}`, '');
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
