import PropTypes from 'prop-types';
import React from 'react';

const UserInfo = ({ name, id, userType }) => (
  <div>
    {name} - {id} ({userType})
  </div>
);

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
};

export default UserInfo;
