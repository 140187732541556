import { PermissionType } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import { teamPermissions } from 'client/modules/team/permissions/Team';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';

export const useUserPermissions = () => {
  const { data } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    data,
    teamPermissions.pageId,
    teamPermissions.sections[2].sectionId
  );

  const hasLoginAsUserPermission = section.permissionTypes.includes(
    PermissionType.PUBLISH
  );

  return { hasLoginAsUserPermission };
};

export default useUserPermissions;
