import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';

type EditableTextProps = {
  text: string;
  onSave: (text: string) => void;
  validation?: (text: string) => string;
  ariaLabel?: string;
  render?: (text: string) => React.ReactNode;
  defaultIcon?: React.ReactNode;
};

const EditableText: React.FC<EditableTextProps> = ({
  text,
  onSave,
  validation,
  ariaLabel,
  render,
  defaultIcon,
}: EditableTextProps) => {
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const icon = defaultIcon || <EditOutlined />;

  const handleEditClick = () => {
    setEditing(true);
    setEditedText(text);
  };

  const handleSaveClick = async (e: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (validation && validation(editedText)) {
      return;
    }
    if (editedText !== text) {
      setLoading(true);
      onSave(editedText);
      setLoading(false);
    }
    setEditing(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value);
  };

  const handleBlur = (event?) => {
    if (event?.relatedTarget?.type === 'button') {
      return;
    }
    setEditing(false);
    setEditedText(text);
  };

  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleBlur();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <div className="w-full">
      {isEditing ? (
        <div>
          <Input
            autoFocus
            value={editedText}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onPressEnter={() => handleSaveClick(null)}
            className="w-full"
            style={{
              borderColor: validation && validation(editedText) ? 'red' : '',
            }}
            suffix={
              <Tooltip
                title={validation && validation(editedText)}
                visible={validation && !!validation(editedText)}
              >
                <Button
                  type="default"
                  shape="circle"
                  icon={<CheckOutlined style={{ fontSize: '14px' }} />}
                  onClick={handleSaveClick}
                  loading={isLoading}
                />
              </Tooltip>
            }
          />
        </div>
      ) : (
        <div className="flex items-center justify-end">
          {render ? render(text) : <span>{text}</span>}
          <Button
            type="link"
            icon={icon}
            onClick={handleEditClick}
            aria-label={ariaLabel}
          />
        </div>
      )}
    </div>
  );
};

export default EditableText;
