import { map } from 'lodash';
import { GET_ALL_TEAMS } from '../actions/all-teams';

const initialState = [];

const formatTeams = (teams) => {
  return map(teams, (team) => {
    return { id: team.idTeam, name: team.name };
  });
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TEAMS: {
      return formatTeams(action.payload.teams);
    }
    default:
      return state;
  }
}
