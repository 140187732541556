import {
  Industry,
  MarketInReview,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import { useMemo, useState } from 'react';
import { useGetMarketsForAdmin } from '../service/hooks/useGetMarkets';
import { useListIndustries } from '../service/hooks/useListIndustries';
import { IndustryInfo, mapIndustries } from './useIndustryList';

export type MarketReport = {
  marketName: string;
  marketId?: number;
  marketReviewId?: number;
  marketDescription: string;
  publishedDate?: string;
  creationDate?: string;
  industries?: IndustryInfo[];
  isPendingReview?: boolean;
};

export const useMarketList = () => {};

const updateMarket = (
  dataMarkets: MarketInReview[],
  industries: Industry[]
) => {
  return dataMarkets.map((el) => ({
    marketName: el.market_name,
    marketId: el.id_market ? Number(el.id_market) : null,
    marketReviewId: el.id_market_review ? Number(el.id_market_review) : null,
    marketDescription: el.market_description,
    publishedDate: new Date(`${el.ts_updated}Z`).toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }),
    creationDate: el.ts_inserted
      ? new Date(`${el.ts_inserted}Z`).toLocaleDateString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
      : null,
    industries: mapIndustries(el?.id_industries, industries),
    isPendingReview: el.id_market_review > 0,
  }));
};

export const useMarketForAdmin = (enabled?: boolean) => {
  const { data, isFetched, isFetching, isLoading } = useGetMarketsForAdmin({
    enabled,
  });
  const { data: industries } = useListIndustries({ enabled: false });
  const [filteredMarkets, setFilteredMarkets] = useState<MarketReport[]>([]);

  const markets = useMemo<MarketReport[]>(() => {
    let option: MarketReport[] = [];
    if (data?.markets && filteredMarkets.length === 0) {
      option = updateMarket(data?.markets, industries);
      setFilteredMarkets(option);
      return option;
    }

    if (
      data?.markets?.length > 0 &&
      data?.markets?.length !== markets?.length
    ) {
      option = updateMarket(data?.markets, industries);
      setFilteredMarkets(option);
      return option;
    }

    return filteredMarkets;
  }, [data?.markets, data?.markets?.length]);

  return {
    areMarketsFetched: !isLoading && !isFetching && isFetched,
    markets,
    filteredMarkets,
    setFilteredMarkets,
  };
};
