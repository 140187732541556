import PropTypes from 'prop-types';
import React from 'react';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

import UserQueueActions from 'client/modules/news/queue/components/UserQueueActions';

const QueueRow = ({
  newsItem,
  index,
  openModal,
  openSource,
  onArchive,
  onDiscard,
  onBlock,
  queueSourceType,
  isResolvingNews,
}) => {
  const item = newsItem.primaryArticle;

  const handleClick = (e) => {
    e.preventDefault();
    openSource(newsItem);
  };

  const onClick = (e) => {
    e.preventDefault();
    openModal(index);
  };

  const onArchiveItem = (e) => {
    e.preventDefault();
    onArchive(index);
  };

  const onDiscardItem = (e) => {
    e.preventDefault();
    onDiscard(index);
  };

  const onBlockItem = (e) => {
    e.preventDefault();
    onBlock(index);
  };

  return (
    <tr className={!(index % 2) ? 'bg-gray-400' : 'bg-gray-200'}>
      <td>
        <div data-test="queueDate" className="px-4 py-2">
          {' '}
          {item.date}{' '}
        </div>
        {newsItem.relatedNews && newsItem.relatedNews.length > 0 && (
          <Button data-test="relatedNews" onClick={onClick} theme="success">
            Related News
          </Button>
        )}
        {item.restored ? (
          <div data-test="restored-message">
            <span className="glyphicon glyphicon-exclamation-sign" /> Restored
          </div>
        ) : null}
      </td>
      <td className="px-4 py-2">{item.type}</td>
      <td className="px-4 py-2 break-words" data-test="queueTitle">
        {newsItem.primaryArticle.newsSource && (
          <div>
            {' '}
            <b> {newsItem.primaryArticle.newsSource} </b>{' '}
          </div>
        )}
        <a
          className="text-blue-500 hover:underline hover:text-blue-600"
          href={newsItem.primaryArticle.source}
          onClick={handleClick}
          rel="noopener noreferrer"
        >
          {item.title}
        </a>
      </td>
      <td className="px-4 py-2 break-words" data-test="queueContent">
        {' '}
        {item.content}{' '}
      </td>
      <td className="px-4 py-2">
        <UserQueueActions
          onArchive={onArchiveItem}
          onDiscard={onDiscardItem}
          onBlock={onBlockItem}
          queueSourceType={queueSourceType}
          isResolvingNews={isResolvingNews}
        />
      </td>
    </tr>
  );
};

QueueRow.propTypes = {
  newsItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  openSource: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  queueSourceType: PropTypes.string,
  isResolvingNews: PropTypes.bool,
};

QueueRow.defaultProps = {
  queueSourceType: undefined,
  isResolvingNews: false,
};

export default QueueRow;
