import superagent from 'superagent';
import { logInfo } from 'client/modules/common/utils/logInfo';

/**
 * @deprecated
 * @param {String} url - the endpoint you are looking to hit
 * @param {String} options.method - either get or post
 * @param {Object} options.body - the request body
 * @param {Object} options.query - url params to send
 * @param {Boolean} options.shouldRetry - if request fails should it retry
 * @param {String} options.contentType - content type to accept
 * @param {Boolean} options.getRawRes - if true will return the full res obj
 */
export function request(url, options) {
  const {
    method = 'post',
    body,
    query,
    shouldRetry = true,
    contentType,
    getRawRes = false,
  } = options;

  const req = superagent[method](url);
  body && req.send(body);
  query && req.query(query);
  shouldRetry &&
    req.retry(3, (error) => {
      logInfo({
        error,
        message: error.message,
      });
    });

  const _abort = req.abort;

  const _request = req
    .accept(contentType || 'application/json')
    .timeout({
      response: 20000, // Wait 5 seconds for the server to start sending,
      deadline: 60000, // but allow 1 minute for the file to finish loading.
    })
    .then((res) => {
      return getRawRes ? res : res.body;
    })
    .catch((error) => {
      logInfo({
        error,
        message: error.message,
      });
      throw error;
    });

  return {
    request: _request,
    abort: _abort,
  };
}
