import { useMutation } from 'react-query';
import { impersonateUser } from '../services/api/ImpersonateUser';

export const useImpersonateUser = () => {
  const impersonateMutation = useMutation(impersonateUser);

  const onSubmit = (targetEmail: string) => {
    return new Promise((resolve, reject) => {
      impersonateMutation.mutate(
        {
          targetEmail,
        },
        {
          onSuccess: () => {
            resolve(true);
          },
          onError: (e) => {
            reject(e);
          },
        }
      );
    });
  };

  return {
    onSubmit,
  };
};
