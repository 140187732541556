import ExportOutlined from '@ant-design/icons/lib/icons/ExportOutlined';
import React, { ReactElement } from 'react';

export const AntdOpenUrlIcon = ({
  url,
  description = '',
}: {
  url: string;
  description?: string;
}): ReactElement => {
  return (
    <a
      href={`//${url}`}
      target="_blank"
      rel="noreferrer"
      className="text-blue-500"
    >
      <ExportOutlined />
      {description && <span className="text-xs">{description}</span>}
    </a>
  );
};
