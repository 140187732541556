import { SearchOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/lib/select';
import { useListExchanges } from 'client/modules/cbi-entity/services/financials/hooks/usePublicFinancialService';
import { AntdSearchableField } from 'client/modules/common/antDesign/form/fields/AntdSearchableSelect';
import { FinancialFormFields } from './hooks/useFinancials';

export const SearchableShortExchangeCodeField = ({
  index,
}: {
  index: number;
}) => {
  const { data: exchanges } = useListExchanges({ enabled: false });
  const exchangeOptions: DefaultOptionType[] = exchanges?.exchanges?.map(
    (el) => ({
      label: `${el.shortCode} (${el.name})`,
      value: el.shortCode,
    })
  );

  return (
    <AntdSearchableField
      suffixIcon={<SearchOutlined />}
      className="min-w-[200px]"
      options={exchangeOptions}
      name={`${FinancialFormFields.Company}.${index}.${FinancialFormFields.ShortExchangeCode}`}
    />
  );
};
