/* eslint-disable max-len */
import { map, omit } from 'lodash';
import {
  getElementByObjectKey,
  getTabOptions,
} from '../../components/utils/get-tab-options';
import { updateNewVertexesPageNumber } from './details';
import { resetManagePermissionsPage, initializePipelineUsers } from './users';
import { mapCandidatesToAddRemoveVertexes } from '../utils/admin-user-helpers';

export const DISPLAY_SEARCH_REQUEST =
  'client/modules/data-platform/actions/search/DISPLAY_SEARCH_REQUEST';
export const FETCH_NODE_DETAILS =
  'client/modules/data-platform/actions/search/FETCH_NODE_DETAILS';
export const FETCH_PIPELINE_LIST =
  'client/modules/data-platform/actions/search/FETCH_PIPELINE_LIST';
export const FETCH_PIPELINE_GRAPH =
  'client/modules/data-platform/actions/search/FETCH_PIPELINE_GRAPH';
export const FETCH_ENTIRE_GRAPH =
  'client/modules/data-platform/actions/search/FETCH_ENTIRE_GRAPH';
export const UPDATE_PIPELINE_EMAIL_ALERTING =
  'client/modules/data-platform/actions/search/UPDATE_PIPELINE_EMAIL_ALERTING';
export const TOGGLE_PIPELINE_COMPONENT_SIDEBAR =
  'client/modules/data-platform/actions/search/TOGGLE_PIPELINE_COMPONENT_SIDEBAR';
export const TOGGLE_PIPELINE_SETTINGS_SIDEBAR =
  'client/modules/data-platform/actions/search/TOGGLE_PIPELINE_SETTINGS_SIDEBAR';
export const TOGGLE_COMPONENT_SETTINGS_SIDEBAR =
  'client/modules/data-platform/actions/search/TOGGLE_COMPONENT_SETTINGS_SIDEBAR';
export const ADD_PIPELINE_VERTEX =
  'client/modules/data-platform/actions/search/ADD_PIPELINE_VERTEX';
export const CREATE_PIPELINE =
  'client/modules/data-platform/actions/search/CREATE_PIPELINE_VERTEX';
export const FETCH_ADDITIONAL_COMPONENT_CANDIDATE =
  'client/modules/data-platform/actions/search/FETCH_ADDITIONAL_COMPONENT_CANDIDATE';
export const GET_SUGGESTED_PIPELINE_VERTEXES =
  'client/modules/data-platform/actions/search/GET_SUGGESTED_PIPELINE_VERTEXES';
export const CLEAR_SUGGESTED_PIPELINE_VERTEXES =
  'client/modules/data-platform/actions/search/CLEAR_SUGGESTED_PIPELINE_VERTEXES';
export const CLEAR_ADDITIONAL_COMPONENT_CANDIDATES =
  'client/modules/data-platform/actions/search/CLEAR_ADDITIONAL_COMPONENT_CANDIDATES';
export const REMOVE_ADDITIONAL_COMPONENT_CANDIDATE =
  'client/modules/data-platform/actions/search/REMOVE_ADDITIONAL_COMPONENT_CANDIDATE';
export const REMOVE_PIPELINE_VERTEX =
  'client/modules/data-platform/actions/search/REMOVE_PIPELINE_VERTEX';
export const SOFT_DELETE_PIPELINE =
  'client/modules/data-platform/actions/search/SOFT_DELETE_PIPELINE';
export const SET_DISPLAY_NEW_VERTEXES =
  'client/modules/data-platform/actions/search/SET_DISPLAY_NEW_VERTEXES';
export const SET_PIPELINE =
  'client/modules/data-platform/actions/search/SET_PIPELINE';
export const SET_COMPONENT =
  'client/modules/data-platform/actions/search/SET_COMPONENT';
export const SET_GRAPH_EXCLUDE_LABELS =
  'client/modules/data-platform/actions/search/SET_GRAPH_EXCLUDE_LABELS';
export const GET_ALL_LABELS =
  'client/modules/data-platform/actions/search/GET_ALL_LABELS';
export const GET_ALL_ID =
  'client/modules/data-platform/actions/search/GET_ALL_ID';
export const ADD_GRAPH_EXCLUDE_LABEL =
  'client/modules/data-platform/actions/search/ADD_GRAPH_EXCLUDE_LABEL';
export const CLEAR_GRAPH_EXCLUDE_LABELS =
  'client/modules/data-platform/actions/search/CLEAR_GRAPH_EXCLUDE_LABELS';
export const ALL_GRAPH_EXCLUDE_LABELS =
  'client/modules/data-platform/actions/search/ALL_GRAPH_EXCLUDE_LABELS';
export const ADD_SUGGESTED_EXCLUDE_LABEL =
  'client/modules/data-platform/actions/search/ADD_SUGGESTED_EXCLUDE_LABEL';
export const CLEAR_SUGGESTED_EXCLUDE_LABELS =
  'client/modules/data-platform/actions/search/CLEAR_SUGGESTED_EXCLUDE_LABELS';
export const ALL_SUGGESTED_EXCLUDE_LABELS =
  'client/modules/data-platform/actions/search/ALL_SUGGESTED_EXCLUDE_LABELS';
export const UPDATE_PIPELINE_USERS =
  'client/modules/data-platform/actions/search/UPDATE_PIPELINE_USERS';
export const SET_FILTER_BY_CALLING_USER =
  'client/modules/data-platform/actions/search/SET_FILTER_BY_CALLING_USER';

export function updateSearchTerm(searchTerm) {
  return { type: DISPLAY_SEARCH_REQUEST, searchTerm };
}

export const fetchNodeDetails = (idVertex, excludeLabels) => ({
  types: [null, FETCH_NODE_DETAILS],
  service: {
    endpoint: 'GetVertexNeighbors',
    name: 'dataplatform',
    body: {
      vertexId: idVertex,
      excludeLabels,
    },
  },
});

export const fetchPipelineList = ({
  sortField,
  sortDirection,
  recordsPerPage,
  pageNumber,
  filterByCallingUser,
}) => ({
  types: [null, FETCH_PIPELINE_LIST],
  service: {
    endpoint: 'GetPipelineList',
    name: 'dataplatform',
    body: {
      sortDirection,
      sortField,
      pageSize: recordsPerPage,
      offSet: pageNumber * recordsPerPage,
      filterByCallingUser,
    },
  },
});

export const fetchPipelineGraphAction = (pipelineName, excludeLabels) => ({
  types: [null, null],
  service: {
    endpoint: 'GetPipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      excludeLabels,
    },
  },
});

export const fetchPipelineGraphHelper = (payload) => {
  return { type: FETCH_PIPELINE_GRAPH, payload };
};

export const fetchPipelineGraph = (pipelineName, excludeLabels) => {
  return (dispatch) => {
    return dispatch(fetchPipelineGraphAction(pipelineName, excludeLabels)).then(
      (payload) => {
        dispatch(fetchPipelineGraphHelper(payload));
        if (payload.users) {
          dispatch(initializePipelineUsers(payload.users));
        }
      }
    );
  };
};

export const fetchEntireGraph = () => ({
  types: [null, FETCH_ENTIRE_GRAPH],
  service: {
    endpoint: 'GetAllComponents',
    name: 'dataplatform',
  },
});

export const togglePipelineComponentSideBar = () => {
  return { type: TOGGLE_PIPELINE_COMPONENT_SIDEBAR };
};

export const togglePipelineSettingsSideBar = () => {
  return { type: TOGGLE_PIPELINE_SETTINGS_SIDEBAR };
};

export const toggleComponentSettingsSideBar = () => {
  return { type: TOGGLE_COMPONENT_SETTINGS_SIDEBAR };
};

export const toggleEmailAlertsHelper = (pipelineName, emailAlerting) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      emailAlerting: {
        value: emailAlerting,
      },
    },
  },
});

export const toggleEmailAlertsAction = (payload) => {
  return { type: UPDATE_PIPELINE_EMAIL_ALERTING, payload };
};

export const toggleEmailAlerts = (pipelineName, emailAlerting) => {
  return (dispatch) => {
    dispatch(toggleEmailAlertsHelper(pipelineName, emailAlerting)).then(
      (payload) => {
        if (payload.success) {
          dispatch(toggleEmailAlertsAction(payload));
        } else {
          window.alert(`Error updating pipeline: ${payload.errorMessage}`);
        }
      }
    );
  };
};

export const clearAdditionalComponentCandidates = () => {
  return { type: CLEAR_ADDITIONAL_COMPONENT_CANDIDATES };
};

export const addPipelineVertexesHelper = (pipelineName, vertexes) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      addVertexes: vertexes,
    },
  },
});

export const addPipelineVertexesAction = (pipelineMetaData) => {
  return { type: ADD_PIPELINE_VERTEX, payload: pipelineMetaData };
};

export const addPipelineVertexes = (pipelineName, vertexes, excludeLabels) => {
  return (dispatch) => {
    dispatch(addPipelineVertexesHelper(pipelineName, vertexes)).then(
      (response) => {
        if (response.success) {
          dispatch(addPipelineVertexesAction(response));
          dispatch(fetchPipelineGraph(pipelineName, excludeLabels));
        } else {
          window.alert(`Error updating pipeline: ${response.errorMessage}`);
        }
      }
    );
  };
};

export const removePipelineVertexesHelper = (vertexes, pipelineName) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      removeVertexes: vertexes,
    },
  },
});

export const removePipelineVertexesAction = (pipelineMetaData) => {
  return { type: REMOVE_PIPELINE_VERTEX, payload: pipelineMetaData };
};

export const removePipelineVertexes = (
  vertexes,
  pipelineName,
  excludeLabels
) => {
  return (dispatch) => {
    dispatch(removePipelineVertexesHelper(vertexes, pipelineName)).then(
      (response) => {
        if (response.success) {
          dispatch(removePipelineVertexesAction(response));
          dispatch(fetchPipelineGraph(pipelineName, excludeLabels));
        } else {
          window.alert(`Error updating pipeline: ${response.errorMessage}`);
        }
      }
    );
  };
};

export const fetchAdditionalComponentCandidateHelper = (idVertex) => ({
  types: [null, null],
  service: {
    endpoint: 'GetVertex',
    name: 'dataplatform',
    body: {
      vertexId: idVertex,
    },
  },
});

export const fetchAdditionalComponentCandidateAction = (
  vertex,
  pageNumber,
  recordsPerPage
) => {
  return {
    type: FETCH_ADDITIONAL_COMPONENT_CANDIDATE,
    payload: vertex,
    pageNumber,
    recordsPerPage,
  };
};

export const clearSuggestedPipelineVertexes = () => {
  return { type: CLEAR_SUGGESTED_PIPELINE_VERTEXES };
};

export const getSuggestedPipelineVertexesHelper = ({
  vertexes,
  pageNumber,
  recordsPerPage,
  excludeLabels,
}) => ({
  types: [null],
  service: {
    endpoint: 'GetSuggestedPipelineVertexes',
    name: 'dataplatform',
    body: {
      vertexes,
      depth: 1,
      // Dummy value for now
      pipelineName: '',
      pageSize: recordsPerPage,
      offSet: pageNumber * recordsPerPage,
      excludeLabels,
    },
  },
});

export const getSuggestedPipelineVertexesAction = (response) => {
  return { type: GET_SUGGESTED_PIPELINE_VERTEXES, payload: response };
};

export const getSuggestedPipelineVertexes = ({
  vertexes,
  pageNumber,
  recordsPerPage,
  excludeLabels,
}) => {
  return (dispatch) => {
    if (!vertexes.length) {
      dispatch(clearSuggestedPipelineVertexes());
      return;
    }
    // const suggestionRecordsPerPage = getState().dataPlatform.details.suggestionRecordsPerPage;
    // const suggestionPageNumber = getState().dataPlatform.details.suggestionPageNumber;
    dispatch(
      getSuggestedPipelineVertexesHelper({
        vertexes,
        pageNumber,
        recordsPerPage,
        excludeLabels,
      })
    ).then((response) => {
      if (response.vertexes.length) {
        dispatch(getSuggestedPipelineVertexesAction(response));
      } else {
        dispatch(clearSuggestedPipelineVertexes());
      }
    });
  };
};

const setSuggestVertexes = (state) => {
  // We only want to use the pipelineRawVertexes in the suggestion if it's the pipelineGraph tab
  // Otherwise only use newVertexes
  let vertexes = state.dataPlatform.search.newVertexes;
  const currentTabOptions = getElementByObjectKey(
    'tabName',
    state.dataPlatform.tab,
    getTabOptions()
  );
  if (
    currentTabOptions.usePipelineState &&
    state.dataPlatform.search.pipelineRawVertexes
  ) {
    if (vertexes) {
      vertexes = state.dataPlatform.search.pipelineRawVertexes.concat(vertexes);
    } else {
      vertexes = state.dataPlatform.search.pipelineRawVertexes;
    }
  }
  return vertexes;
};

export const setDisplayNewVertexes = (pageNumber, recordsPerPage) => {
  return {
    type: SET_DISPLAY_NEW_VERTEXES,
    pageNumber,
    recordsPerPage,
  };
};

export const fetchAdditionalComponentCandidate = ({
  idVertex,
  pageNumber,
  recordsPerPage,
  excludeLabels,
}) => {
  return (dispatch, getState) => {
    return dispatch(fetchAdditionalComponentCandidateHelper(idVertex)).then(
      (response) => {
        if (response.id) {
          dispatch(
            fetchAdditionalComponentCandidateAction(
              response,
              pageNumber,
              recordsPerPage
            )
          );
          const vertexes = setSuggestVertexes(getState());
          if (vertexes.length) {
            dispatch(
              getSuggestedPipelineVertexes({
                vertexes,
                pageNumber,
                recordsPerPage,
                excludeLabels,
              })
            );
          }
        } else {
          window.alert(`Component with ID ${idVertex} not found`);
        }
      }
    );
  };
};

export const pushRouteHelper = (tabName, navigation, getState) => {
  const state = getState();
  const updatedPipelineName = state.dataPlatform.search.pipelineName;
  const currentComponentId = state.dataPlatform.search.centralVertexId;
  const route = `/data-platform/${tabName}?pipelineName=${
    updatedPipelineName || ''
  }&componentId=${currentComponentId || ''}`;
  navigation(route);
};

export const setPipeline = (pipelineName) => {
  return { type: SET_PIPELINE, pipelineName };
};

export const viewPipeline = (pipelineName, navigation) => {
  return (dispatch, getState) => {
    dispatch(setPipeline(pipelineName));
    pushRouteHelper('pipeline-components', navigation, getState);
  };
};

export const setComponent = (componentId) => {
  return { type: SET_COMPONENT, componentId };
};

export const viewComponent = (componentId, navigation) => {
  return (dispatch, getState) => {
    dispatch(setComponent(componentId));
    pushRouteHelper('component-details', navigation, getState);
  };
};

export const setGraphExcludeLabels = (graphExcludeLabels) => {
  return { type: SET_GRAPH_EXCLUDE_LABELS, graphExcludeLabels };
};

export const removeAdditionalComponentCandidateAction = (
  idVertex,
  pageNumber,
  recordsPerPage
) => {
  return {
    type: REMOVE_ADDITIONAL_COMPONENT_CANDIDATE,
    idVertex,
    pageNumber,
    recordsPerPage,
  };
};

export const removeAdditionalComponentCandidate = ({
  idVertex,
  pageNumber,
  recordsPerPage,
  excludeLabels,
}) => {
  return (dispatch, getState) => {
    dispatch(
      removeAdditionalComponentCandidateAction(
        idVertex,
        pageNumber,
        recordsPerPage
      )
    );
    const vertexes = setSuggestVertexes(getState());
    dispatch(
      getSuggestedPipelineVertexes({
        vertexes,
        pageNumber,
        recordsPerPage,
        excludeLabels,
      })
    );
    const offset = pageNumber * recordsPerPage;
    const { numNewVertexes } = getState().dataPlatform.search;
    if (numNewVertexes === 0) {
      dispatch(updateNewVertexesPageNumber(0));
    } else if (offset >= numNewVertexes) {
      dispatch(updateNewVertexesPageNumber(pageNumber - 1));
    }
  };
};

export const softDeletePipelineHelper = (pipelineName) => ({
  types: [null, null],
  service: {
    endpoint: 'DeletePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
    },
  },
});

export const softDeletePipelineAction = (response) => {
  return { type: SOFT_DELETE_PIPELINE, response };
};

export const softDeletePipeline = ({
  pipelineName,
  sortField,
  sortDirection,
  recordsPerPage,
  pageNumber,
  filterByCallingUser,
}) => {
  return (dispatch) => {
    dispatch(softDeletePipelineHelper(pipelineName)).then((response) => {
      if (response.success) {
        dispatch(softDeletePipelineAction(response));
        dispatch(
          fetchPipelineList({
            sortField,
            sortDirection,
            recordsPerPage,
            pageNumber,
            filterByCallingUser,
          })
        );
      } else {
        window.alert(`Error deleting pipeline: ${response.errorMessage}`);
      }
    });
  };
};

export const getAllLabels = () => ({
  types: [null, GET_ALL_LABELS],
  service: {
    endpoint: 'GetAllLabels',
    name: 'dataplatform',
    body: {},
  },
});

export const getAllIds = (body = {}) => ({
  types: [null, GET_ALL_ID],
  service: {
    endpoint: 'GetAllIds',
    name: 'dataplatform',
    body,
  },
});

export const addGraphExcludeLabel = (label) => {
  return { type: ADD_GRAPH_EXCLUDE_LABEL, label };
};

export const clearGraphExcludeLabels = () => {
  return { type: CLEAR_GRAPH_EXCLUDE_LABELS };
};

export const allGraphExcludeLabels = () => {
  return { type: ALL_GRAPH_EXCLUDE_LABELS };
};

export const addSuggestedExcludeLabel = (label) => {
  return { type: ADD_SUGGESTED_EXCLUDE_LABEL, label };
};

export const clearSuggestedExcludeLabelsAction = () => {
  return { type: CLEAR_SUGGESTED_EXCLUDE_LABELS };
};

export const clearSuggestedExcludeLabels = (pageNumber, recordsPerPage) => {
  return (dispatch, getState) => {
    dispatch(clearSuggestedExcludeLabelsAction());
    const vertexes = setSuggestVertexes(getState());
    dispatch(
      getSuggestedPipelineVertexes({
        vertexes,
        pageNumber,
        recordsPerPage,
        excludeLabels: [],
      })
    );
  };
};

export const allSuggestedExcludeLabelsAction = () => {
  return { type: ALL_SUGGESTED_EXCLUDE_LABELS };
};

export const allSuggestedExcludeLabels = (
  pageNumber,
  recordsPerPage,
  labels
) => {
  return (dispatch, getState) => {
    dispatch(allSuggestedExcludeLabelsAction());
    const vertexes = setSuggestVertexes(getState());
    if (vertexes) {
      dispatch(
        getSuggestedPipelineVertexes({
          vertexes,
          pageNumber,
          recordsPerPage,
          excludeLabels: labels,
        })
      );
    } else {
      dispatch(clearSuggestedPipelineVertexes());
    }
  };
};

export const createPipelineHelper = (pipelineName, vertexes, addUsers) => ({
  types: [null, null],
  service: {
    endpoint: 'CreatePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      addVertexes: vertexes,
      addUsers,
    },
  },
});

export const createPipelineAction = (pipelineMetaData) => {
  return { type: CREATE_PIPELINE, payload: pipelineMetaData };
};

export const createPipelinePushRouteHelper = (
  navigation,
  tabName,
  pipelineName,
  getState
) => {
  const state = getState();
  const currentComponentId = state.dataPlatform.search.centralVertexId;
  const route = `/data-platform/${tabName}?pipelineName=${
    pipelineName || ''
  }&componentId=${currentComponentId || ''}`;
  navigation(route);
};

export const createPipeline = (props) => {
  const {
    pipelineName,
    vertexes,
    sortField,
    sortDirection,
    recordsPerPage,
    pageNumber,
    navigation,
    tabName,
    users,
    filterByCallingUser,
  } = props;
  const addUsers = map(users, (user) => omit(user, ['fullName', 'actionType']));
  return (dispatch, getState) => {
    return dispatch(
      createPipelineHelper(pipelineName, vertexes, addUsers)
    ).then((response) => {
      if (response.success) {
        dispatch(createPipelineAction(response));
        dispatch(fetchPipelineGraph(pipelineName, []));
        dispatch(clearAdditionalComponentCandidates());
        dispatch(
          fetchPipelineList({
            sortField,
            sortDirection,
            recordsPerPage,
            pageNumber,
            filterByCallingUser,
          })
        );
        dispatch(clearSuggestedExcludeLabels());
        dispatch(clearGraphExcludeLabels());
        dispatch(updateNewVertexesPageNumber(0));
        dispatch(resetManagePermissionsPage());
        createPipelinePushRouteHelper(navigation, tabName, pipelineName, getState);
      } else {
        window.alert(`Error creating pipeline: ${response.errorMessage}`);
      }
    });
  };
};

export const updatePipelineUsersHelper = ({
  pipelineName,
  addUsers,
  removeUsers,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePipeline',
    name: 'dataplatform',
    body: {
      pipelineName,
      addUsers,
      removeUsers,
    },
  },
});

export const updatePipelineUsersAction = (pipelineMetaData) => {
  return { type: UPDATE_PIPELINE_USERS, payload: pipelineMetaData };
};

export const updatePipelineUsers = ({
  pipelineName,
  excludeLabels,
  newUsers,
}) => {
  const users = mapCandidatesToAddRemoveVertexes(newUsers);
  const { addUsers } = users;
  const { removeUsers } = users;
  return (dispatch) => {
    return dispatch(
      updatePipelineUsersHelper({ pipelineName, addUsers, removeUsers })
    ).then((response) => {
      if (response.success) {
        dispatch(updatePipelineUsersAction(response));
        dispatch(fetchPipelineGraph(pipelineName, excludeLabels));
        dispatch(resetManagePermissionsPage());
      } else {
        window.alert(`Error updating pipeline users: ${response.errorMessage}`);
      }
    });
  };
};

export const setFilterByCallingUser = (filterByCallingUser) => {
  return { type: SET_FILTER_BY_CALLING_USER, filterByCallingUser };
};
