import { combineReducers } from 'redux';
import currentTab from './current-tab';
import tabData from './tab-data';
import tabDetails from './tab-details';
import packagesInfo from './all-packages';
import featuresInfo from './all-features';

const packages = combineReducers({
  currentTab,
  tabData,
  tabDetails,
  packagesInfo,
  featuresInfo,
});

export default packages;
