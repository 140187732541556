import { Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { MarketReportsRoutes } from '../RouterHelper';
import {
  useDeleteMarketInReviewForAdmin,
  useSoftDeleteMarket,
} from '../service/hooks/useSoftDeleteMarket';

export const DeleteMarketButton = ({
  idMarketReview,
  idMarket,
  isInReview,
}: {
  idMarketReview: number;
  idMarket: number;
  isInReview: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { mutateAsync: softDeleteMutation } = useSoftDeleteMarket();
  const { mutateAsync: deleteMarketInReviewMutation } =
    useDeleteMarketInReviewForAdmin();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    const mutationParams = {
      onSuccess: () => {
        message.success(
          isInReview
            ? 'Marked discarded successfully!'
            : 'Market deleted successfully!'
        );

        queryClient.invalidateQueries(['GetMarketsForAdmin']);
        queryClient.invalidateQueries(['GetMarketDetailsForAdmin']);

        navigate(`/${MarketReportsRoutes.MarketReports}`);
      },
      onError: (error: $TSFixMe) => {
        message.error(
          <>
            <span>An error ocurred: </span>
            <br />
            <span>
              {error?.response?.body?.error?.details || error.message}
            </span>
          </>
        );
      },
    };
    if (isInReview) {
      await deleteMarketInReviewMutation(
        {
          id_market_review: idMarketReview,
        },
        mutationParams
      );
    } else {
      await softDeleteMutation(
        {
          id_market: idMarket,
        },
        mutationParams
      );
    }
  };
  const handleClick = () => setOpen(!open);
  const handleCancel = () => {
    setOpen(!open);
  };
  return (
    <>
      <Button danger onClick={handleClick} className="ml-3">
        {isInReview && <span>Discard Changes</span>}
        {!isInReview && <span>Delete Market</span>}
      </Button>
      <Modal
        open={open}
        title={isInReview ? 'Discard Changes' : 'Delete Market'}
        onOk={handleDelete}
        onCancel={handleCancel}
      >
        {isInReview && (
          <span>
            You are trying to discard all current changes, do you wish to
            continue?
          </span>
        )}
        {!isInReview && (
          <span>
            You are trying to delete this market, do you wish to continue?
          </span>
        )}
      </Modal>
    </>
  );
};
