import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { withStyles } from 'client/component-library/styles';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import styles from '../../styles/material-ui/duplicate-count';

class Duplicates extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      id,
      classes,
      showHiddenRowDict,
      duplicatesCount,
      handleOnClick,
    } = this.props;
    const labelClass = { label: classes.label };
    const displayDuplicates = showHiddenRowDict[id.split('-')[0]];
    const displayText = duplicatesCount === 1 ? 'duplicate' : 'duplicates';

    if (displayDuplicates) {
      return (
        <a
          href="#toggleClick"
          onClick={handleOnClick}
          className={classes.duplicateLinkText}
        >
          <BaseIcon
            customIconClass={classes.icon}
            customIconButtonClass={classes.iconButton}
            customSpanClass={classes.duplicateText}
            customWrapperClass={classes.duplicateIconWrapper}
            dataTest={`${id}-duplicate-alert`}
            iconName="report_problem"
            isIconBlock={false}
            classes={labelClass}
            customWrapperStyles={{ display: 'inline-block' }}
            disableRipple
          />
          <div className={`${classes.duplicateDisplay}`}>
            <div className={`${classes.duplicateDisplayText}`}>
              {`${duplicatesCount} ${displayText}`}
            </div>
            <div className={`${classes.duplicateDisplay}`}>
              <BaseIcon
                customIconClass={classes.icon}
                customIconButtonClass={classes.iconButton}
                customWrapperClass={classes.expandIconWrapper}
                dataTest={`${id}-display-duplicates`}
                iconName="expand_less"
                isIconBlock={false}
                classes={labelClass}
                disableRipple
              />
            </div>
          </div>
        </a>
      );
    }

    return (
      <a
        href="#toggleClick"
        onClick={handleOnClick}
        className={classes.duplicateLinkText}
      >
        <BaseIcon
          customIconClass={classes.icon}
          customIconButtonClass={classes.iconButton}
          customSpanClass={classes.duplicateText}
          customWrapperClass={classes.duplicateIconWrapper}
          dataTest={`${id}-duplicate-alert`}
          iconName="report_problem"
          isIconBlock={false}
          classes={labelClass}
          customWrapperStyles={{ display: 'inline-block' }}
          disableRipple
        />
        <div className={`${classes.duplicateDisplay}`}>
          <div className={`${classes.duplicateDisplayText}`}>
            {`${duplicatesCount} ${displayText}`}
          </div>
          <div className={`${classes.duplicateDisplay}`}>
            <BaseIcon
              customIconClass={classes.icon}
              customIconButtonClass={classes.iconButton}
              customWrapperClass={classes.expandIconWrapper}
              dataTest={`${id}-display-duplicates`}
              iconName="expand_more"
              isIconBlock={false}
              classes={labelClass}
              disableRipple
            />
          </div>
        </div>
      </a>
    );
  }
}

Duplicates.propTypes = {
  classes: PropTypes.object.isRequired,
  duplicatesCount: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  showHiddenRowDict: PropTypes.object.isRequired,
};

export default withStyles(styles)(Duplicates);
