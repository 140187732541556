import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const CreateDeleteUser = React.lazy(
  () => import('./containers/CreateDeleteUser')
);
const AdminUsers = React.lazy(() => import('./containers/AdminUsers'));
const AdminPages = React.lazy(() => import('./containers/AdminPages'));

export const userAdminRoutes: RouteObject = {
  path: 'admin-user-admin',
  children: [
    {
      path: '/admin-user-admin',
      element: <Navigate to="/admin-user-admin/admin-users" />,
    },
    {
      path: '/admin-user-admin/create-delete-users',
      element: (
        <React.Suspense fallback={undefined}>
          <CreateDeleteUser />
        </React.Suspense>
      ),
    },
    {
      path: '/admin-user-admin/admin-users',
      element: (
        <React.Suspense fallback={undefined}>
          <AdminUsers />
        </React.Suspense>
      ),
    },
    {
      path: '/admin-user-admin/admin-pages',
      element: (
        <React.Suspense fallback={undefined}>
          <AdminPages />
        </React.Suspense>
      ),
    },
    { path: '*', element: <Navigate to="/404" /> },
  ],
};
