/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { MultiSelect } from 'client/component-library/Select/MultiSelect';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  DEFAULT_OPTION,
  FastSimpleSelect,
  SimpleSelect,
} from 'client/component-library/Select/SimpleSelect';
import { autoFocusNextElement } from 'client/component-library/Form';
import { ContextField } from './ContextField';
import { generateNewOption, getDefaultValue } from './UtilFields';

/**
 * @deprecated
 * _SelectSearchField is a function that returns a component that renders a select field
 * @returns A function that takes in props and returns a react component.
 */
const _SelectSearchField = ({
  field,
  form,
  size,
  onChangeClear,
  onChange,
  handleWarning,
  shouldRequire,
  disableCreate,
  isMulti,
  isFast,
  disablePlaceholder,
  shouldUpdate = () => true,
  placeholder = '',
  shouldShowNewOption,
  validateOnBlur,
  isClearable,
  disabled,
  shouldHighlightOption,
  defaultValue = DEFAULT_OPTION,
  ...props
}) => {
  const { options } = props;

  useEffect(() => {
    /* Support warning at the field level */
    const warning = handleWarning(field.value || {});

    if (warning) {
      const currentWarning = get(form.values, `warnings.${field.name}`, '');
      if (currentWarning !== warning) {
        form.setFieldValue(`warnings.${field.name}`, warning);
      }
    } else if (get(form, `values.warnings.${field.name}`, '')) {
      form.setFieldValue(`warnings.${field.name}`, '');
    }
  }, [field.name, field.value, options]);

  const _onChange = (selectedOption) => {
    // This may be an obj from react-select for new optionf
    let option;
    const oldValue = get(form.values, field.name);

    if (selectedOption === null) {
      option = { ...DEFAULT_OPTION };
    } else {
      option = selectedOption.__isNew__
        ? generateNewOption(selectedOption.label)
        : selectedOption;
    }

    const setFieldWarning = (warningMessage) =>
      form.setFieldValue(`warnings.${field.name}`, warningMessage);

    if (shouldUpdate(selectedOption, setFieldWarning)) {
      form.setFieldValue(field.name, option);
    }

    /* Support Clearing other fields on change */
    onChangeClear.forEach((fieldName) =>
      form.setFieldValue(
        fieldName,
        getDefaultValue(form.getFieldMeta(fieldName), placeholder)
      )
    );

    /* Support normal onchange as well as pass form values and ability to alter other fields on update */
    onChange(
      option,
      {
        formValues: { ...form.values, [field.name]: option },
        setFieldValue: form.setFieldValue,
        oldValue,
      },
      getOptions()
    );

    /* NOTE: Product wants us to auto focus to next element on change */
    if (!isMulti) {
      setTimeout(() => autoFocusNextElement());
    }
  };

  const _isRequired = () => {
    if (shouldRequire) {
      return shouldRequire(form.values);
    }
    return props.required;
  };

  const getOptionLabel = ({ name, label }) => name || label;
  const getOptionValue = ({ id, value }) => id || value;
  const getOptions = () => {
    return props.options.length && !props.excludeDefaultOption
      ? [DEFAULT_OPTION, ...props.options]
      : props.options;
  };

  // Deal With Aysn and Create New
  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (!inputValue || disableCreate) {
      return false;
    }

    if (shouldShowNewOption) {
      return shouldShowNewOption({ inputValue, selectValue, selectOptions });
    }

    // It could be that we have grouped options
    const _selectOptions = selectOptions
      .map((obj) => get(obj, 'options', obj))
      .flat();

    return !_selectOptions.some(
      ({ name, label }) =>
        (name || label).toLowerCase() === inputValue.toLowerCase()
    );
  };

  const _onBlur = () => {
    if (validateOnBlur) {
      form.validateField(field.name);
    }

    form.setFieldTouched(field.name);
  };

  const formatCreateLabel = (inputValue) => (
    <div>
      {inputValue} <span className="text-xs text-gray-600">(new)</span>
    </div>
  );

  // Experimenting with memo SimpleSelect. If all is good we can always use FastSimpleSelect after People Admin Project.
  let SelectType = isFast ? FastSimpleSelect : SimpleSelect;

  SelectType = isMulti ? MultiSelect : SelectType;
  return (
    <ContextField size={size} name={field.name}>
      <SelectType
        {...field}
        {...props}
        title={field.name}
        createOptionPosition="first"
        formatCreateLabel={formatCreateLabel}
        isValidNewOption={isValidNewOption}
        maxMenuHeight={200}
        defaultValue={defaultValue} // We want the DEFAULT_OPTION to act as the placeholder
        disablePlaceholder={disablePlaceholder}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={getOptions()}
        shouldHighlightOption={shouldHighlightOption}
        required={_isRequired()}
        isDisabled={form.isSubmitting || disabled}
        renderOption={({ name }) => <div className="p-2 text-md">{name}</div>}
        onChange={_onChange}
        onBlur={_onBlur}
        isClearable={
          !!(get(field, 'value.id') || get(field, 'value.value')) && isClearable
        }
      />
    </ContextField>
  );
};
_SelectSearchField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  size: string,
  onChangeClear: arrayOf(string),
  options: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      name: string.isRequired,
    })
  ),
  /** note that this is an enhanced onChange that give access to a formHelper, (option, formHelper) => {} */
  shouldUpdate: func,
  onChange: func,
  handleWarning: func,
  shouldRequire: func,
  required: bool,
  isClearable: bool,
  disabled: bool,
  excludeDefaultOption: bool,
  loadOptions: func,
  /** used when loadOptions is supplied to disable new entires */
  disableCreate: bool,
  /** used when you want to create new entires on a simple select */
  isCreatable: bool,
  /** Used to manager performance, this will allow component to re-render based off external factors */
  cacheKey: string,
  isMulti: bool,
  isFast: bool,
  disablePlaceholder: bool,
  shouldShowNewOption: func,
  shouldHighlightOption: func,
  placeholder: string,
  defaultValue: object,
  validateOnBlur: bool,
};

_SelectSearchField.defaultProps = {
  size: 'FULL',
  onChangeClear: [],
  options: [],
  onChange: () => {},
  handleWarning: () => {},
  shouldRequire: null,
  required: false,
  validateOnBlur: false,
  isClearable: false,
  disabled: false,
  disableCreate: false,
  isCreatable: false,
  loadOptions: undefined,
  excludeDefaultOption: false,
  cacheKey: '',
  isMulti: false,
  isFast: false,
  disablePlaceholder: true,
  shouldShowNewOption: undefined,
  shouldHighlightOption: () => false,
};

const dependencies = [
  'field.value',
  'field.name',
  'form.isSubmitting',
  'options',
  'warning',
  'disabled',
  'cacheKey',
];

function shouldNotRender(prevProps, nextProps) {
  for (let i = 0; i < dependencies.length; i++) {
    if (get(prevProps, dependencies[i]) !== get(nextProps, dependencies[i])) {
      return false;
    }
  }

  return true;
}

/** @deprecated */
const SelectSearchField = React.memo(_SelectSearchField, shouldNotRender);

export { SelectSearchField };
