import React from 'react';
import { bool, func, node } from 'prop-types';
import ReactModal from 'react-modal';
// fix for unit test error
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#app');
}

const NOP = () => null;
const parentSelector = () => document.body;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1102,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

/**
 * @deprecated
 * It's a React component that renders a ReactModal component
 */
const Modal = ({ children, isOpen, handleClose, onOpen, closeOnClickAway }) => (
  <ReactModal
    shouldFocusAfterRender
    shouldCloseOnOverlayClick={closeOnClickAway}
    shouldCloseOnEsc
    shouldReturnFocusAfterClose
    isOpen={isOpen}
    onAfterOpen={onOpen}
    onRequestClose={handleClose}
    closeTimeoutMS={0}
    style={customStyles}
    bodyOpenClassName="admin-react-modal-open"
    role="dialog"
    parentSelector={parentSelector}
    overlayRef={NOP}
    contentRef={NOP}
  >
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: node.isRequired,
  isOpen: bool.isRequired,
  closeOnClickAway: bool,
  handleClose: func,
  onOpen: func,
};

Modal.defaultProps = {
  closeOnClickAway: false,
  onOpen: () => {},
  handleClose: () => {},
};

export { Modal };
