import { UpdateTeamPackageRequest } from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const updateTeamPackage = (
  req: Partial<UpdateTeamPackageRequest>
): Promise<void> =>
  request({
    url: '/service/adminservice/UpdateTeamPackage',
    body: req,
  }).then((r) => r.body);
