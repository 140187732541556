// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tabs .ant-tabs-nav{
    margin: 0px
}
`, "",{"version":3,"sources":["webpack://./client/modules/cbi-entity/components/cbi-entity/CBIEntityTabs.css"],"names":[],"mappings":";AACA;IACI;AACJ","sourcesContent":["\n:global .tabs .ant-tabs-nav{\n    margin: 0px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
