import PropTypes from 'prop-types';
import React from 'react';

const UserStatsRow = ({ stat, index }) => {
  return (
    <div
      className={`user-stats-row flex text-center ${
        !(index % 2) ? 'bg-white' : 'bg-gray-200'
      } py-2`}
      data-test={`${stat.id}-row`}
    >
      <div className="w-1/12" />
      <div className="user-stats-col w-1/6 text-center">{`${stat.fname} ${stat.lname}`}</div>
      <div className="user-stats-col w-1/6 text-center" data-test="idUser">
        {stat.id}
      </div>
      <div className="user-stats-col w-1/6 text-center" data-test="archived">
        {stat.archived}
      </div>
      <div className="user-stats-col w-1/6 text-center" data-test="discarded">
        {stat.discarded}
      </div>
      <div className="user-stats-col w-1/6 text-center" data-test="recovered">
        {stat.recovered}
      </div>
      <div className="w-1/12" />
    </div>
  );
};

UserStatsRow.propTypes = {
  stat: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default UserStatsRow;
