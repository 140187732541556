import React from 'react';
import PropTypes from 'prop-types';
import Input from 'client/component-library/Input';
import Select from 'client/component-library/Select';

/**
 * @deprecated
 * It's a React component that renders a Kendo UI Select component
 * @returns A Select component with the following props:
 *   name: name
 *   value: value
 *   input: an Input component with the id of the row
 *   options: options
 *   placeholder: "N/A"
 *   valueField: "id"
 *   textField: "text"
 *   disabled: disabled
 *   dataTest: dataTest
 * */
const Selector = ({ name, value, row, options, disabled, dataTest }) => {
  return (
    <Select
      name={name}
      value={value}
      input={<Input id={row.id} />}
      options={options}
      placeholder="N/A"
      valueField="id"
      textField="text"
      disabled={disabled}
      dataTest={dataTest}
    />
  );
};

Selector.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  row: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  dataTest: PropTypes.string,
};

Selector.defaultProps = {
  disabled: false,
  name: '',
  value: '',
  row: {},
  options: [],
  dataTest: '',
};

export default Selector;
