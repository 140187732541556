import { Button, Card, Checkbox, List, Space } from 'antd';

import { Link } from 'react-router-dom';
import React, { MouseEvent } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MarketReport } from '../hooks/useMarketsList';
import { EditMarketTab, MarketReportsRoutes } from '../RouterHelper';

export const MarketCardListItem = ({
  item,
  selectMarketToRefresh,
  selectedMarkets,
  isMarketRefreshingDict,
  refreshMarket,
  refreshMarketsCompetitor,
}: {
  item: MarketReport;
  selectMarketToRefresh: (id: number) => () => void;
  selectedMarkets: Record<number, boolean>;
  isMarketRefreshingDict: Record<number, boolean>;
  refreshMarket: (idMarket: number) => (event: unknown) => Promise<void>;
  refreshMarketsCompetitor: (
    idMarket: number
  ) => (event: unknown) => Promise<void>;
}) => {
  const preventRedirect = (cb: (e: unknown) => void) => (e: MouseEvent) => {
    e.preventDefault();
    cb(e);
  };
  return (
    <List.Item>
      <Link
        to={{
          pathname: `${
            item.marketId ? String(item.marketId) : String(item.marketReviewId)
          }/${EditMarketTab.MarketInfo}`,
          search:
            !item.marketId && item.isPendingReview
              ? `${MarketReportsRoutes.IsPendingReview}=${item.isPendingReview}`
              : '',
        }}
        className="flex-grow"
      >
        <Card
          className={`cursor-pointer flex-grow${
            selectedMarkets[item.marketId] ? ' border-green-500' : ''
          }`}
        >
          <Card.Meta
            title={
              <div className="flex justify-between">
                <div className="flex flex-row">
                  <div className="pr-3">
                    {!item.isPendingReview && (
                      <Checkbox
                        onClick={preventRedirect(
                          selectMarketToRefresh(item.marketId)
                        )}
                        checked={selectedMarkets[item.marketId]}
                      />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span>
                        {item.marketName} ({item.marketId})
                      </span>
                      {item.isPendingReview && (
                        <span className="ml-2 text-sm text-cbi-green">
                          <ExclamationCircleOutlined /> Pending Changes
                        </span>
                      )}
                    </div>
                    <div className="font-normal text-gray-600">
                      <div>{item?.industries[0]?.industryName}</div>
                      {item?.industries[1]?.industryName
                        ? `${item?.industries[1]?.industryName}`
                        : ''}
                      {item?.industries[2]?.industryName
                        ? `, ${item?.industries[2]?.industryName}`
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  {item.publishedDate ? (
                    <span className="font-normal text-base">
                      Last updated: {item.publishedDate}
                    </span>
                  ) : null}
                  {item.creationDate ? (
                    <span className="font-normal text-base">
                      Created on: {item.creationDate}
                    </span>
                  ) : null}
                </div>
              </div>
            }
            description={
              <Space direction="vertical" className="flex pl-3 ml-[16px]">
                <div className="flex justify-between">
                  <div>{item.marketDescription}</div>
                  <div className="min-w-[182px] flex justify-end">
                    <div className="flex flex-col">
                      {!item.isPendingReview && (
                        <Button
                          className="ml-3 bg-cbi-green text-white hover:bg-cbi-green hover:text-white focus:bg-cbi-green focus:text-white"
                          title="Run ESP Scoring"
                          disabled={
                            isMarketRefreshingDict[item.marketId] === true
                          }
                          onClick={preventRedirect(
                            refreshMarket(item.marketId)
                          )}
                        >
                          Run ESP Scoring
                        </Button>
                      )}
                      {!item.isPendingReview && (
                        <Button
                          className="ml-3 bg-cbi-green text-white hover:bg-cbi-green hover:text-white focus:bg-cbi-green focus:text-white"
                          title="Run Suggestion Algo"
                          disabled={
                            isMarketRefreshingDict[item.marketId] === true
                          }
                          onClick={preventRedirect(
                            refreshMarketsCompetitor(item.marketId)
                          )}
                        >
                          Run Suggestion Algo
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Space>
            }
          />
        </Card>
      </Link>
    </List.Item>
  );
};
