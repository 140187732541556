import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { TableCell } from 'client/component-library/Table';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
} from 'client/component-library/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from 'client/component-library/Divider';
import Button from 'client/component-library/buttons/Button';
import Popover from 'client/component-library/Popover/Popover';
import { DateTextField } from 'client/modules/common/components/TextField';
import {
  setSortInfo,
  setFilterInfo,
  clearFilterInfo,
} from 'client/modules/stories/redux/actions/tab-data';
import { updatePageNumber } from 'client/modules/stories/redux/actions/tab-details';
import styles from '../../styles/material-ui/tableHeaderStyles';

const collapseProps = {
  style: {
    zIndex: 10,
    marginTop: '4px',
    width: '250px',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2),' +
      '0px 1px 1px 0px rgba(0, 0, 0, 0.14),' +
      '0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
};
const panelSummaryStyle = { minHeight: '48px', padding: '0' };
const dividerStyle = { margin: '10px 0', width: '200px' };

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

class DateHeaderCell extends Component {
  constructor(props) {
    super(props);
    this.toggleSort = this.toggleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClearFilterInfo = this.handleClearFilterInfo.bind(this);
    this.handleFilterAction = this.handleFilterAction.bind(this);
    this.handleUpdateDateFilter = this.handleUpdateDateFilter.bind(this);
    this.toggleExpandedState = this.toggleExpandedState.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleChange(event) {
    const { name, dispatch } = this.props;
    dispatch(setFilterInfo(name, event.target.value));
  }

  handleClearFilterInfo() {
    const { name, dispatch, filterAction } = this.props;
    dispatch(clearFilterInfo(name));
    dispatch(updatePageNumber(0));
    filterAction();
    this.setState({ expanded: false });
  }

  handleFilterAction() {
    const { dispatch, filterAction } = this.props;
    dispatch(updatePageNumber(0));
    filterAction();
    this.setState({ expanded: false });
  }

  handleOnBlur() {
    this.setState({ expanded: false });
  }

  handleUpdateDateFilter(event, start) {
    const { filterValue, name, dispatch } = this.props;
    let dateRange;
    if (start === 'start') {
      dateRange = {
        ...filterValue,
        startDate: event.target.value || filterValue.startDate,
      };
    } else {
      dateRange = {
        ...filterValue,
        endDate: event.target.value || filterValue.endDate,
      };
    }
    dispatch(setFilterInfo(name, dateRange));
  }

  toggleExpandedState(event, expanded) {
    this.setState({ expanded });
  }

  toggleSort(direction) {
    const { name, dispatch, filterAction } = this.props;
    dispatch(setSortInfo(name, direction));
    filterAction();
  }

  render() {
    const { classes, name, filterValue, sortInfo, width } = this.props;
    const labelClass = { label: classes.label };
    const panelSummaryClasses = {
      root: classes.expansionPanelSummaryRoot,
      content: classes.expansionPanelSummaryContent,
      expandIcon: classes.expansionPanelSummaryExpandIcon,
    };
    const panelActionClasses = { root: classes.expensionPanelActionRoot };
    const sortByTeamName = sortInfo.sortField === name;
    const withValue =
      filterValue.startDate ||
      filterValue.endDate ||
      sortInfo.sortField === name;

    return (
      <TableCell classes={{ tableCell: classes.tableCell }} width={width}>
        <Popover onBlur={this.handleOnBlur}>
          <ExpansionPanel
            onChange={(event, value) => this.toggleExpandedState(event, value)}
            expanded={this.state.expanded}
            classes={{
              root: withValue
                ? classes.expensionPanelWithValue
                : classes.expensionPanel,
            }}
            CollapseProps={collapseProps}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              style={panelSummaryStyle}
              classes={panelSummaryClasses}
            >
              {name}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div
                style={{
                  fontSize: '10px',
                  color: 'gray',
                  fontWeight: 'lighter',
                }}
              >
                <div>Sort by</div>
                <BaseIcon
                  customIconClass={classes.icon}
                  customIconButtonClass={classes.iconButton}
                  customSpanClass={
                    sortByTeamName && sortInfo.sortDirection === 'asc'
                      ? classes.textSelected
                      : classes.text
                  }
                  customWrapperClass={
                    sortByTeamName && sortInfo.sortDirection === 'asc'
                      ? classes.addIconWrapperSelected
                      : classes.addIconWrapper
                  }
                  dataTest="sort-by-team-name"
                  handleOnClick={() => this.toggleSort('asc')}
                  iconName="arrow_downward"
                  iconText="High to low"
                  isIconBlock={false}
                  classes={labelClass}
                />
                <BaseIcon
                  customIconClass={classes.icon}
                  customIconButtonClass={classes.iconButton}
                  customSpanClass={
                    sortByTeamName && sortInfo.sortDirection === 'desc'
                      ? classes.textSelected
                      : classes.text
                  }
                  customWrapperClass={
                    sortByTeamName && sortInfo.sortDirection === 'desc'
                      ? classes.addIconWrapperSelected
                      : classes.addIconWrapper
                  }
                  dataTest="sort-by-team-name"
                  handleOnClick={() => this.toggleSort('desc')}
                  iconName="arrow_upward"
                  iconText="Low to high"
                  isIconBlock={false}
                  classes={labelClass}
                />
                <Divider style={dividerStyle} />
                <DateTextField
                  label="From"
                  classes={{ TextField: classes.textField }}
                  name="startDate"
                  value={filterValue.startDate}
                  onChange={(event) =>
                    this.handleUpdateDateFilter(event, 'start')
                  }
                  id="filter-by-startDate"
                  dataTest="filter-by-startDate"
                />
                <DateTextField
                  label="To"
                  classes={{ TextField: classes.textField }}
                  name="endDate"
                  value={filterValue.endDate}
                  onChange={(event) =>
                    this.handleUpdateDateFilter(event, 'end')
                  }
                  id="filter-by-endDate"
                  dataTest="filter-by-startDate"
                />
              </div>
            </ExpansionPanelDetails>
            <ExpansionPanelActions classes={panelActionClasses}>
              <MuiThemeProvider theme={theme}>
                <Button
                  size="small"
                  onClick={this.handleClearFilterInfo}
                  color="secondary"
                >
                  Clear
                </Button>
              </MuiThemeProvider>
              <Button
                size="small"
                onClick={this.handleFilterAction}
                color="primary"
              >
                Apply
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </Popover>
      </TableCell>
    );
  }
}

function mapStateToProps({ stories }, ownProps) {
  const { currentTab } = stories;
  return {
    filterValue: stories.tabData[currentTab].filterInfo[ownProps.name],
    sortInfo: stories.tabData[currentTab].sortInfo,
  };
}

DateHeaderCell.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filterValue: PropTypes.object,
  name: PropTypes.string.isRequired,
  sortInfo: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  filterAction: PropTypes.func,
};

DateHeaderCell.defaultProps = {
  filterValue: {},
  filterAction: () => {},
};

export default withStyles(styles)(connect(mapStateToProps)(DateHeaderCell));
