import { PermissionType } from '@cbinsights/adminpermissionservice/adminpermissionservice';
import {
  getSectionPage,
  useGetAdminPermissionsForUser,
} from 'client/utils/permissions-validation/hooks/UseGetAdminPermissionsForUserQuery';
import { MARKET_REPORTS_PERMISSIONS } from './MarketReports';

export const useGetMarketReportsPermissions = (): {
  hasDraftPermission: boolean;
  hasPublishPermission: boolean;
} => {
  const { data } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    data,
    MARKET_REPORTS_PERMISSIONS.pageId,
    MARKET_REPORTS_PERMISSIONS.sections[1].sectionId
  );

  const hasDraftPermission = section.permissionTypes.includes(
    PermissionType.DRAFT
  );

  const hasPublishPermission = section.permissionTypes.includes(
    PermissionType.PUBLISH
  );

  return {
    hasDraftPermission,
    hasPublishPermission,
  };
};

export const useGetMarketCompaniesPermissions = (): {
  hasMarketCompanyDraftPermission: boolean;
  hasMarketCompanyPublishPermission: boolean;
} => {
  const { data } = useGetAdminPermissionsForUser();
  const section = getSectionPage(
    data,
    MARKET_REPORTS_PERMISSIONS.pageId,
    MARKET_REPORTS_PERMISSIONS.sections[2].sectionId
  );

  const hasMarketCompanyDraftPermission = section.permissionTypes.includes(
    PermissionType.DRAFT
  );

  const hasMarketCompanyPublishPermission = section.permissionTypes.includes(
    PermissionType.PUBLISH
  );

  return {
    hasMarketCompanyDraftPermission,
    hasMarketCompanyPublishPermission,
  };
};
