import { UseQueryResult, useQuery } from 'react-query';
import { GetUserResponse } from '@cbinsights/adminservice/adminservice';
import { GetUserKey } from '.';
import { getUser } from '../api/GetUser';

type Params = Parameters<typeof getUser>[0];
type Return = GetUserResponse;

export const useGetUserQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUserKey, Params]>(
    ['GetUser', params],
    () => getUser(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
