import { Button } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { TeamMemberData } from '../../hooks/useTeamMembers';

type Props = {
  members: TeamMemberData[];
  idTeam: number;
};

function convertArrayOfObjectsToCSV(
  headers: string[],
  data: TeamSchema[]
): string {
  const header = headers.join(',');
  const rows = data.map((obj) => Object.values(obj).join(',')).join('\n');
  return `${header}\n${rows}`;
}

const headers: string[] = [
  'id',
  'name',
  'email',
  'package',
  'startDate',
  'endDate',
  'downloads',
  'downloadLimit',
];

type TeamSchema = {
  id: number;
  name: string;
  email: string;
  package: string;
  startDate: string;
  endDate: string;
  downloads: number;
  downloadLimit: number;
};

const DownloadTeam = ({ members, idTeam }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadCSV = () => {
    setIsLoading(true);

    const data = members
      ? members.map((member) => ({
          id: member.userId,
          name: member.name,
          email: member.email,
          package: member.package,
          startDate: member.startDate,
          endDate: member.endDate,
          downloads: member.downloadsUsed,
          downloadLimit: member.downloadLimit,
        }))
      : [];

    const csvData = convertArrayOfObjectsToCSV(headers, data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `team-id-${idTeam}-${moment().format('YYYY-MM-DD')}`
    );
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsLoading(false);
  };

  return (
    <div>
      <Button onClick={downloadCSV} loading={isLoading}>
        Download as CSV
      </Button>
    </div>
  );
};

export default DownloadTeam;
