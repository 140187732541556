import React from 'react';
import PropTypes from 'prop-types';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

export function SECActionButtons({ onArchive, onBlock }) {
  return (
    <div className="w-2/12 px-1">
      <div className="flex flex-col">
        <div className="p-2 flex-1">
          <Button
            onClick={onArchive}
            theme="success"
            data-test="archive-cluster-button"
          >
            Archive
          </Button>
        </div>
        <div className="p-2 flex-1">
          <Button onClick={onBlock} theme="danger" data-test="block-cik-button">
            Block CIK
          </Button>
        </div>
      </div>
    </div>
  );
}

SECActionButtons.propTypes = {
  onBlock: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
};
