import { Button, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FilterExpressionGroupEndpoints,
  useDeactivateFilterExpressionGroup,
} from '../services/hooks/useGetFilterExpressionGroupInfo';

export const FilterGroupMenuItem = ({
  name,
  groupId,
  index,
}: {
  name: string;
  groupId: number;
  index: number;
}) => {
  const { groupId: currentGroupId } = useParams();
  const deactivateFilterExpressionGroupMutation =
    useDeactivateFilterExpressionGroup();
  const client = useQueryClient();
  const navigate = useNavigate();

  const bgc = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  const [open, setOpen] = useState(false);

  const handleOk = () => {
    deactivateFilterExpressionGroupMutation.mutateAsync({
      idFilterExpressionGroup: groupId,
    });
    client.invalidateQueries([
      FilterExpressionGroupEndpoints.ListFilterExpressionGroups,
    ]);
    message.success('Filter group deactivated successfully');
    navigate('/news/groups');
    setOpen(!open);
  };
  const handleCancel = () => {
    setOpen(!open);
  };

  return (
    <div
      className={`${
        Number(currentGroupId) === groupId
          ? `filter-group-menu-item-selected flex justify-between mt-1 text-black`
          : `text-blue-600 hover:text-blue-700 flex justify-between mt-1`
      } ${bgc}`}
      data-test={`${name}-group`}
    >
      <Link
        to={`/news/groups/${groupId}`}
        className="filter-group-menu-item hover:underline"
      >
        {name.trim()}
      </Link>
      <Button
        type="link"
        className="filter-group-menu-item-remove p-0 leading-0 h-0"
        data-test="removeGroup"
        onClick={() => setOpen(!open)}
      >
        <span aria-hidden="true">&times;</span>
        <Modal
          open={open}
          title="Confirm deactivate filter group"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Do you want to deactivate {name} filter group?</p>
        </Modal>
      </Button>
    </div>
  );
};
