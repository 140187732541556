import { forEach, filter } from 'lodash';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import * as actions from '../actions/tab-data';

export const getSelectedUniveristy = (
  allUniversityInfo,
  selectedUniversityId
) => {
  let selectedUniversity = {};
  forEach(allUniversityInfo, (universityInfo) => {
    if (universityInfo.idUniversity === selectedUniversityId) {
      selectedUniversity = universityInfo;
    }
  });
  return selectedUniversity;
};

// Every univeristy has a cbinsights domain (this is so we can test it), but no need to
// show that in the FE here!
export const removeCbinsightsDomainInfo = (allDomainInfo) => {
  return filter(allDomainInfo, (domainInfo) => {
    return domainInfo.domain !== 'cbinsights';
  });
};

const initialState = {
  allUniversityInfo: [],
  searchTerm: '',
  searchId: 0,
  suggestions: [],
  selectedUniversity: {},
  selectedUniversityDomainInfo: [],
  selectedUniversityIpRangeInfo: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_SEARCH_INFO: {
      return {
        ...state,
        allUniversityInfo: action.allUniversityInfo,
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      const suggestions = filterListOfObjects({
        value: action.searchTerm,
        listOfItems: state.allUniversityInfo,
        fields: ['universityName', 'universitySlug'],
      });
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchId: action.searchId,
        suggestions,
      };
    }
    case actions.SELECT_SEARCH_INFO: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        searchId: action.searchId,
        selectedUniversity: getSelectedUniveristy(
          state.allUniversityInfo,
          action.searchId
        ),
      };
    }
    case actions.SET_SELECTED_DOMAIN_INFO: {
      return {
        ...state,
        selectedUniversityDomainInfo: removeCbinsightsDomainInfo(
          action.domainInfo
        ),
      };
    }
    case actions.SET_SELECTED_IP_RANGE_INFO: {
      return {
        ...state,
        selectedUniversityIpRangeInfo: action.ipRangeInfo,
      };
    }
    default:
      return state;
  }
}
