export const PIPELINE_LIST = 'pipelineList';
export const PIPELINE_LIST_ROUTE = 'pipeline-list';
export const PIPELINE_LIST_LABEL = 'Pipelines';
export const PIPELINE_LIST_USE_PIPELINE_STATE = false;

export const PIPELINE_GRAPH = 'pipelineGraph';
export const PIPELINE_GRAPH_ROUTE = 'pipeline-components';
export const PIPELINE_GRAPH_LABEL = 'Pipeline Components';
export const PIPELINE_GRAPH_USE_PIPELINE_STATE = true;

export const COMPONENT_DETAILS = 'componentDetails';
export const COMPONENT_DETAILS_ROUTE = 'component-details';
export const COMPONENT_DETAILS_LABEL = 'Component Details';
export const COMPONENT_DETAILS_USE_PIPELINE_STATE = false;

export const getTabOptions = () => {
  const PIPELINE_LIST_INFO = {
    tabName: PIPELINE_LIST,
    label: PIPELINE_LIST_LABEL,
    route: PIPELINE_LIST_ROUTE,
    usePipelineState: PIPELINE_LIST_USE_PIPELINE_STATE,
  };
  const PIPELINE_GRAPH_INFO = {
    tabName: PIPELINE_GRAPH,
    label: PIPELINE_GRAPH_LABEL,
    route: PIPELINE_GRAPH_ROUTE,
    usePipelineState: PIPELINE_GRAPH_USE_PIPELINE_STATE,
  };
  const COMPONENT_DETAILS_INFO = {
    tabName: COMPONENT_DETAILS,
    label: COMPONENT_DETAILS_LABEL,
    route: COMPONENT_DETAILS_ROUTE,
    usePipelineState: COMPONENT_DETAILS_USE_PIPELINE_STATE,
  };

  // TODO add permissions around which tabs are visible
  // I would imagine that the only one requiring special permissions is PIPELINE_EDITOR
  const tabs = [
    PIPELINE_LIST_INFO,
    PIPELINE_GRAPH_INFO,
    COMPONENT_DETAILS_INFO,
  ];
  return tabs;
};

export const getElementByObjectKey = (key, value, theArray) => {
  let i = 0;
  for (i = 0; i < Object.keys(theArray).length; i++) {
    if (theArray[i][key] === value) {
      return theArray[i];
    }
  }
  return {};
};

export default getTabOptions;
