import React from 'react';
import PropTypes from 'prop-types';

/**
 * @deprecated
 * It returns a div with a class of bg-gray-100 my-4 shadow overflow-hidden rounded-md, and inside of
 * that div is another div with a class of px-4 py-4, and inside of that div is the children prop
 * @returns A div with a class of bg-gray-100 my-4 shadow overflow-hidden rounded-md.
 */
export const Card = ({ children }) => {
  return (
    <div className="bg-gray-100 my-4 shadow overflow-hidden rounded-md">
      <div className="px-4 py-4">{children}</div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};
