import { isEmpty, map, omit } from 'lodash';
import { setFailureNotification } from 'client/modules/common/redux/actions/status-notification';
import {
  mapMarketSizingDetailRequest,
  saveMarketSizingDetails,
  setRecordsValidation,
} from './data';
import {
  getCurrentTabData,
  getStatus,
  getCurrentUser,
  getMergeConflicts,
} from '../utils/records-helper';
import { validateRecords } from '../utils/validate-records';
import { makeSearch, initializeAndUpdateAutocompleteInput } from './search-bar';
import { mapMarketSizingDetailResponse } from '../utils/api-response-helper';
import { setMergeConflictData } from './setMergeConflictData';

export const OPEN_MERGE_CONFLICT_MODAL =
  'client/modules/markets/actions/merge-conflict/OPEN_MERGE_CONFLICT_MODAL';
export const SELECT_CONFLICTING_VALUE =
  'client/modules/markets/actions/merge-conflict/SELECT_CONFLICTING_VALUE';
export const UPDATE_RECORD =
  'client/modules/markets/actions/merge-conflict/UPDATE_RECORD';
export const GO_TO_NEXT_CONFLICT =
  'client/modules/markets/actions/merge-conflict/GO_TO_NEXT_CONFLICT';
export const RESET_MERGE_CONFLICTS =
  'client/modules/markets/actions/merge-conflict/RESET_MERGE_CONFLICTS';

export const openMergeConflictModal = () => {
  return { type: OPEN_MERGE_CONFLICT_MODAL };
};

export const selectConflictingValue = (cellId, tableType) => {
  return { type: SELECT_CONFLICTING_VALUE, cellId, tableType };
};

export const updateMergeConflictRecord = (recordId, fieldName, newValue) => {
  return { type: UPDATE_RECORD, recordId, fieldName, newValue };
};

export const goToNextConflict = () => {
  return { type: GO_TO_NEXT_CONFLICT };
};

export const resetMergeConflicts = () => {
  return { type: RESET_MERGE_CONFLICTS };
};

export const handleNewMergeConflicts = (failedRows, recordToPost) => {
  return (dispatch) => {
    const mappedRows = mapMarketSizingDetailResponse(failedRows);
    const mergeConflicts = getMergeConflicts(mappedRows, recordToPost);
    if (isEmpty(mergeConflicts)) {
      dispatch(makeSearch());
      return dispatch(resetMergeConflicts());
    }
    dispatch(initializeAndUpdateAutocompleteInput(failedRows));
    dispatch(setMergeConflictData(mappedRows, mergeConflicts));
    return dispatch(setFailureNotification('Another merge conflict occurred!'));
  };
};

export const saveAndGoToNextConflict = (recordId) => {
  return (dispatch, getState) => {
    const { recordStatusFilters } = getCurrentTabData(getState);
    const { user } = getCurrentUser(getState);
    const autoCompleteInputValues = getState().table.autocompleteInput
      .inputValues;

    const currentStatus = recordStatusFilters.selectedRecordStatusFilterId;
    const {
      dataToPost,
      status,
      currentConflictNumber,
      numberOfConflictingRows,
    } = getState().markets.mergeConflict;

    // Only want to save the individual record that was just resolved
    const recordToPost = {
      [recordId]: dataToPost[recordId],
    };

    const data = mapMarketSizingDetailRequest(
      recordToPost,
      getStatus(
        status,
        currentStatus,
        recordStatusFilters.recordStatusFilterOptions
      ),
      user.data.id,
      autoCompleteInputValues
    );

    const invalidCells = validateRecords(data);
    dispatch(setRecordsValidation(invalidCells));

    if (isEmpty(invalidCells) && !isEmpty(data)) {
      dispatch(
        saveMarketSizingDetails(map(data, (d) => omit(d, 'tmpKeyId')))
      ).then(
        (response) => {
          if (!response.success) {
            dispatch(
              handleNewMergeConflicts(response.failedRows, recordToPost)
            );
          } else if (currentConflictNumber + 1 >= numberOfConflictingRows) {
            dispatch(makeSearch());
            dispatch(resetMergeConflicts());
          } else {
            dispatch(goToNextConflict());
          }
        },
        () => {
          dispatch(
            setFailureNotification(
              'Failed to update records, please try again.'
            )
          );
        }
      );
    }
  };
};
