import React, { useState } from 'react';
import { TablePaginationConfig } from 'antd';

type Props<T> = {
  data: Array<T>;
  config?: TablePaginationConfig;
};

/**
 * @param config - antd TablePaginationConfig or undefined to use default config
 * @returns
 */
export function useShowPagination<T>({ data, config }: Props<T>) {
  const [pageSize, setPageSize] = useState(config?.pageSize || 10);

  const handleSizeChange = (_: number, size: number) => {
    setPageSize(size);
  };

  const showPagination: false | TablePaginationConfig = React.useMemo(() => {
    if (data && data.length > pageSize) {
      return {
        ...config,
        pageSize,
        onShowSizeChange: handleSizeChange,
      };
    }
    return false;
  }, [data, pageSize, config]);

  return showPagination;
}

export default useShowPagination;
