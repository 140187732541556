import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteNewPermissionRecords } from 'client/modules/admin-user-admin/redux/actions/tab-data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';

class DeleteIconCell extends Component {
  handleDelete = (id) => () => {
    const [recordId] = id.split('-');
    this.props.deleteNewPermissionRecords(recordId);
  };

  render() {
    const { id } = this.props;
    return (
      <div style={{ position: 'relative', top: '-23px' }}>
        <BaseIcon
          handleOnClick={this.handleDelete(id)}
          dataTest={`${id}-delete-icon`}
          iconName="cancel"
          iconClassName="deleteIcon"
          iconStyles={{ fontSize: '20px' }}
          iconButtonStyles={{ width: 'inherit' }}
        />
      </div>
    );
  }
}

DeleteIconCell.propTypes = {
  deleteNewPermissionRecords: PropTypes.func.isRequired,
  id: PropTypes.string,
};

DeleteIconCell.defaultProps = {
  id: '',
};

export default connect(null, { deleteNewPermissionRecords })(DeleteIconCell);
