import fetch from 'isomorphic-fetch';

import { setFailureNotification } from 'client/modules/common/redux/actions/status-notification';

function adminLoginRedirect() {
  window.location = '/app/admin/site-login';
}

const CBI = {
  apiCall: function apiCall(data) {
    return fetch(`/api`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'same-origin',
    });
  },
};

export function handleAsyncError(response) {
  return (dispatch) => {
    if (response.status >= 400) {
      if (response.status === 401 || response.status === 403) {
        return adminLoginRedirect();
      }
      dispatch(
        setFailureNotification('Oops! An error has occurred. Please try again.')
      );
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }

    return response.json();
  };
}

export function handleAsyncErrorWithRedirect(response) {
  return () => {
    if (response.status === 404) {
      return history.replace('/404');
    } if (response.status === 500) {
      return history.replace('/500');
    }

    return response.json();
  };
}

export default CBI;
