import { keyBy, omit } from 'lodash';
import {
  checkIfAllRecordsSelected,
  saveRecordsOrder,
  updateSelectionStateOfAllRecords,
} from 'client/modules/common/utils/recordsHelper';
import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import {
  sortInnerRecords,
  updateSortOrder,
  updateTeamContentOwner,
  initializeVisibility,
  bulkUpdatePermissions,
  updateTeamMemberPermission,
  formatClientTeams,
  formatClientTeamMembers,
  formatClientTeamMembersWithoutStory,
  formatSlugs,
  formatTeamStories,
} from '../utils/records-helper';
import * as actions from '../actions/tab-data';

const initialState = {
  [UPDATE_ACCESS_BY_STORY]: {
    records: {},
    recordsOrder: [],
    innerTable: {},
    innerTableSortInfo: {}, // { sortField, sortDirection }
    allRecordsSelected: false,
    selectedRecords: {},
    filterInfo: {},
    sortInfo: {}, // { sortField, sortDirection }
    showHiddenRowDict: {},
  },
  [UPDATE_ACCESS_BY_TEAM]: {
    records: {},
    recordsOrder: [],
    innerTable: {},
    innerTableSortInfo: {}, // { sortField, sortDirection }
    allRecordsSelected: false,
    selectedRecords: {},
    filterInfo: {},
    sortInfo: {}, // { sortField, sortDirection }
    showHiddenRowDict: {},
    totalPrivateStories: 0,
  },
  [MANAGE_SLUGS]: {
    records: {},
    recordsOrder: [],
    filterInfo: {},
    sortInfo: {}, // { sortField, sortDirection }
    showHiddenRowDict: {},
  },
};

export const saveInnerTableRecords = (
  innerTable,
  formattedResponse,
  recordId
) => {
  const records = keyBy(formattedResponse, 'id');
  return {
    ...innerTable,
    [recordId]: {
      originalInnerTableRecords: records,
      innerTableRecords: records,
      innerTableRecordsOrder: saveRecordsOrder(formattedResponse, 'id'),
    },
  };
};

export const updateTeamMemberPermissionHelper = (
  innerTable,
  outerTableRowId,
  recordId,
  newValue
) => {
  return {
    ...innerTable,
    [outerTableRowId]: {
      ...innerTable[outerTableRowId],
      innerTableRecords: updateTeamMemberPermission(
        innerTable[outerTableRowId].innerTableRecords,
        recordId,
        newValue
      ),
    },
  };
};

export const updateTeamContentOwnerHelper = (
  innerTable,
  outerTableRowId,
  recordId
) => {
  return {
    ...innerTable,
    [outerTableRowId]: {
      ...innerTable[outerTableRowId],
      innerTableRecords: updateTeamContentOwner(
        innerTable[outerTableRowId].innerTableRecords,
        recordId
      ),
    },
  };
};

export const sortInnerRecordsHelper = (
  innerTable,
  outerTableRowId,
  sortField,
  sortDirection
) => {
  return {
    ...innerTable,
    [outerTableRowId]: {
      ...innerTable[outerTableRowId],
      innerTableRecordsOrder: sortInnerRecords(
        innerTable[outerTableRowId].innerTableRecords,
        sortField,
        sortDirection
      ),
    },
  };
};

export const bulkUpdatePermissionsHelper = (
  innerTable,
  outerTableRowId,
  newValue
) => {
  return {
    ...innerTable,
    [outerTableRowId]: {
      ...innerTable[outerTableRowId],
      innerTableRecords: bulkUpdatePermissions(
        innerTable[outerTableRowId].innerTableRecords,
        newValue
      ),
    },
  };
};

export const resetInnerTableRecords = (innerTable, outerTableRowId) => {
  return {
    ...innerTable,
    [outerTableRowId]: {
      ...innerTable[outerTableRowId],
      innerTableRecords: innerTable[outerTableRowId].originalInnerTableRecords,
    },
  };
};

export const updateFilterInfo = (filters, filterName, filterValue) => {
  return {
    ...filters,
    [filterName]: filterValue,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CLIENT_TEAMS: {
      const formattedResponse = formatClientTeams(action.records);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: keyBy(formattedResponse, 'id'),
          recordsOrder: saveRecordsOrder(formattedResponse),
          selectedRecords: updateSelectionStateOfAllRecords(
            formattedResponse,
            false
          ),
          showHiddenRowDict: initializeVisibility(formattedResponse),
        },
      };
    }
    case actions.FETCH_CLIENT_TEAM_MEMBERS: {
      let formattedResponse = [];
      if (!action.storyId) {
        formattedResponse = formatClientTeamMembersWithoutStory(
          action.innerTableRecords
        );
      } else {
        formattedResponse = formatClientTeamMembers(action.innerTableRecords);
      }
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTable: saveInnerTableRecords(
            state[action.currentTab].innerTable,
            formattedResponse,
            action.recordId
          ),
        },
      };
    }
    case actions.SET_TEAM_STORIES: {
      const formattedResponse = formatTeamStories(action.records);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: keyBy(formattedResponse, 'id'),
          recordsOrder: saveRecordsOrder(formattedResponse),
          selectedRecords: updateSelectionStateOfAllRecords(
            formattedResponse,
            false
          ),
          showHiddenRowDict: initializeVisibility(formattedResponse),
          totalPrivateStories: action.records.totalPrivateStories,
        },
      };
    }
    case actions.SET_STORY_SLUG_INFO: {
      const formattedResponse = formatSlugs(action.records);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          records: keyBy(formattedResponse, 'id'),
          recordsOrder: saveRecordsOrder(formattedResponse),
          showHiddenRowDict: initializeVisibility(formattedResponse),
        },
      };
    }
    case actions.SELECT_ALL_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords: updateSelectionStateOfAllRecords(
            state[action.currentTab].records,
            true
          ),
          allRecordsSelected: true,
        },
      };
    case actions.UNSELECT_ALL_RECORDS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords: updateSelectionStateOfAllRecords(
            state[action.currentTab].records,
            false
          ),
          allRecordsSelected: false,
        },
      };
    case actions.SELECT_RECORD: {
      const selectedRecords = {
        ...state[action.currentTab].selectedRecords,
        [action.recordId]: !state[action.currentTab].selectedRecords[
          action.recordId
        ],
      };
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedRecords,
          allRecordsSelected: checkIfAllRecordsSelected(selectedRecords),
        },
      };
    }
    case actions.UPDATE_TEAM_MEMBER_PERMISSION: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTable: updateTeamMemberPermissionHelper(
            state[action.currentTab].innerTable,
            action.outerTableRowId,
            action.recordId,
            action.newValue
          ),
        },
      };
    }
    case actions.UPDATE_TEAM_CONTENT_OWNER: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTable: updateTeamContentOwnerHelper(
            state[action.currentTab].innerTable,
            action.outerTableRowId,
            action.recordId
          ),
        },
      };
    }
    case actions.UPDATE_SORT_ORDER: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTableSortInfo: updateSortOrder(
            action.sortField,
            action.sortDirection
          ),
          innerTable: sortInnerRecordsHelper(
            state[action.currentTab].innerTable,
            action.outerTableRowId,
            action.sortField,
            action.sortDirection
          ),
        },
      };
    }
    case actions.TOGGLE_INNER_TABLE: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          showHiddenRowDict: {
            ...state[action.currentTab].showHiddenRowDict,
            [action.recordId]: !state[action.currentTab].showHiddenRowDict[
              action.recordId
            ],
          },
        },
      };
    }
    case actions.BULK_UPDATE_TEAM_MEMBER_PERMISSIONS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTable: bulkUpdatePermissionsHelper(
            state[action.currentTab].innerTable,
            action.outerTableRowId,
            action.newValue
          ),
        },
      };
    }
    case actions.SET_SORT_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          sortInfo: updateSortOrder(action.sortField, action.sortDirection),
        },
      };
    }
    case actions.SET_FILTER_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          filterInfo: updateFilterInfo(
            state[action.currentTab].filterInfo,
            action.filterName,
            action.filterValue
          ),
        },
      };
    }
    case actions.CLEAR_FILTER_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          filterInfo: omit(state[action.currentTab].filterInfo, action.name),
        },
      };
    }
    case actions.CANCEL_EDIT_PERMISSIONS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          innerTable: resetInnerTableRecords(
            state[action.currentTab].innerTable,
            action.outerTableRowId
          ),
        },
      };
    }
    default:
      return state;
  }
}
