import { Button, Col, Form, Input, Row, Space, message } from 'antd';
import {
  CreateFilterExpressionGroupRequest,
  FilterExpression,
  UpdateFilterExpressionGroupRequest,
} from '@cbinsights/newsqueueservice/newsqueueservice';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AntdInputField } from 'client/modules/common/antDesign/form/fields/AntdInputField';
import { AntdSearchableWithConfirmationOnUnselectField } from 'client/modules/common/antDesign/form/fields/AntdSearchableWithConfirmationOnUnselectField';
import { AntdSelectField } from 'client/modules/common/antDesign/form/fields/AntdSelectField';
import { DefaultOptionType } from 'antd/lib/select';
import { FormContainerWithErrorBoundary } from 'client/modules/common/form/containers/FormContainerWithErrorBoundary';
import { FormikFormProps } from 'client/modules/common/form/containers/FormikContainer';
import Loading from 'client/component-library/LoadingIcon';
import { getUuid } from 'client/modules/common/form/fields/UtilFields';
import { useQueryClient } from 'react-query';
import {
  FilterExpressionGroupEndpoints,
  useCreateFilterExpressionGroupMutation,
  useGetFilterExpressionGroupInfo,
  useUpdateFilterExpressionGroup,
} from '../services/hooks/useGetFilterExpressionGroupInfo';

const CategoryOptions = [
  { label: 'Funding', value: 1 },
  { label: 'M&A', value: 2 },
] as const;

const ActionOptions = [
  { label: 'Keep', value: 1 },
  { label: 'Kill', value: 2 },
] as const;
const AppliedOptions = [
  { label: 'Title', value: 1 },
  { label: 'Content', value: 2 },
] as const;

const filterBy =
  (filter: number, key: keyof FilterExpression) => (exp: FilterExpression) =>
    exp[key] === filter;
const getVisibleExpressions = (
  expressions: FilterExpression[],
  {
    categoryFilter,
    actionFilter,
    applyToFilter,
    searchTerm,
  }: {
    categoryFilter: number;
    actionFilter: number;
    applyToFilter: number;
    searchTerm: string;
  }
) => {
  if (!expressions) {
    return [];
  }

  const filterByCategory = filterBy(categoryFilter, 'idNewsCategory');
  const filterByAction = filterBy(actionFilter, 'idFilterAction');
  const filterByApplyTo = filterBy(applyToFilter, 'idAppliedOn');

  const filterBySearchTerm = (term: string) => (exp: FilterExpression) => {
    return term.length > 0
      ? exp.regularExpression.match(
          new RegExp(term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
        )
      : true;
  };

  return expressions
    .filter(filterByCategory)
    .filter(filterByAction)
    .filter(filterByApplyTo)
    .filter(filterBySearchTerm(searchTerm));
};

const isValidRegex = (exp: string): { isValid: boolean; error?: Error } => {
  let isValid = true;
  let error;
  try {
    // eslint-disable-next-line no-new
    new RegExp(exp);
    return { isValid };
  } catch (err: unknown) {
    isValid = false;
    error = err;
  }
  return { isValid, error };
};

export const ExpressionGroupProps = {
  GroupName: 'groupName',
  Category: 'category',
  Action: 'action',
  ApplyTo: 'applyTo',
  AddedExpression: 'addedExpression',
  DeletedExpression: 'deletedExpression',
  VisibleExpression: 'visibleExpression',
  InitialExpressions: 'initialExpressions',
} as const;

export type FilterGroupFormModel = {
  [ExpressionGroupProps.GroupName]: string;
  [ExpressionGroupProps.Action]: DefaultOptionType;
  [ExpressionGroupProps.Category]: DefaultOptionType;
  [ExpressionGroupProps.ApplyTo]: DefaultOptionType;
  [ExpressionGroupProps.AddedExpression]: FilterExpression[];
  [ExpressionGroupProps.DeletedExpression]: FilterExpression[];
  [ExpressionGroupProps.VisibleExpression]: DefaultOptionType[];
  [ExpressionGroupProps.InitialExpressions]: FilterExpression[];
};

export type FilterGroupFormikFormProps = FormikFormProps<
  Record<string, unknown>,
  FilterGroupFormModel,
  Record<string, unknown>
>;

export const FilterGroupForm = ({
  values,
  setFieldValue,
  dirty,
  submitForm,
}: FilterGroupFormikFormProps) => {
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    const visibleExpressions = getVisibleExpressions(
      values.initialExpressions
        .filter(
          (el) =>
            !values.deletedExpression.some(
              (deleted) => el.regularExpression === deleted.regularExpression
            )
        )
        .concat(values.addedExpression),
      {
        categoryFilter: Number(values.category.value),
        actionFilter: Number(values.action.value),
        applyToFilter: Number(values.applyTo.value),
        searchTerm: filtro,
      }
    ).map((el) => ({
      label: el.regularExpression,
      value: el.idFilterExpression,
    }));

    setFieldValue(ExpressionGroupProps.VisibleExpression, visibleExpressions);
  }, [
    values.category.value,
    values.action.value,
    values.applyTo.value,
    filtro,
  ]);

  const handleInputChange = (value) => {
    setFiltro(value);
  };

  const [expression, setExpression] = useState('');

  const addNewExpression = (exp: string) => () => {
    if (!exp) {
      return;
    }

    const { isValid } = isValidRegex(exp);
    if (isValid) {
      const newUuid = getUuid();
      setFieldValue(ExpressionGroupProps.AddedExpression, [
        ...values.addedExpression,
        {
          idFilterExpression: newUuid,
          regularExpression: exp,
          idNewsCategory: Number(values.category.value),
          idFilterAction: Number(values.action.value),
          idAppliedOn: Number(values.applyTo.value),
        },
      ]);
      setFieldValue(ExpressionGroupProps.VisibleExpression, [
        ...values.visibleExpression,
        { label: exp, value: newUuid },
      ]);
      setExpression('');
    }
  };

  const onDeleteExpression = (deletedValue: DefaultOptionType) => {
    if (deletedValue.value > 0) {
      setFieldValue(ExpressionGroupProps.DeletedExpression, [
        ...values.deletedExpression,
        {
          idFilterExpression: deletedValue.value,
          regularExpression: deletedValue.label,
          idNewsCategory: Number(values.category.value),
          idFilterAction: Number(values.action.value),
          idAppliedOn: Number(values.applyTo.value),
        },
      ]);
    } else {
      setFieldValue(
        ExpressionGroupProps.AddedExpression,
        values.addedExpression.filter(
          (el) => el.regularExpression !== deletedValue.label
        )
      );
    }
  };

  return (
    <Form name="filterGroupForm" layout="vertical">
      <Row className="flex flex-row justify-between">
        <Form.Item label="Group Name">
          <AntdInputField
            name={ExpressionGroupProps.GroupName}
            placeholder="Filter group name"
            validateOnBlur
            required
          />
        </Form.Item>
        <Button type="primary" disabled={!dirty} onClick={submitForm}>
          Submit Changes
        </Button>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Category">
            <AntdSelectField
              className="min-w-[250px] pr-2"
              defaultValue={1}
              name={ExpressionGroupProps.Category}
              labelInValue
              options={[...CategoryOptions]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Action">
            <AntdSelectField
              className="min-w-[250px] pr-2"
              defaultValue={1}
              name={ExpressionGroupProps.Action}
              labelInValue
              options={[...ActionOptions]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Apply to">
            <AntdSelectField
              className="min-w-[250px] pr-2"
              defaultValue={1}
              name={ExpressionGroupProps.ApplyTo}
              labelInValue
              options={[...AppliedOptions]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="flex flex-row justify-between">
        <Form.Item>
          <Input
            placeholder="Filter expression"
            value={filtro}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Input
              placeholder="Add new expression"
              onChange={(e) => setExpression(e.target.value)}
              value={expression}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  addNewExpression(expression)();
                }
              }}
            />
            <Button onClick={addNewExpression(expression)}>
              Add Expression
            </Button>
          </Space>
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          <AntdSearchableWithConfirmationOnUnselectField
            name={ExpressionGroupProps.VisibleExpression}
            confirmDeleteModalTitle="Delete expression"
            confirmDeleteModalBody="Do you want to delete the selected expression?"
            labelInValue
            mode="multiple"
            onChange={addNewExpression}
            onDeselect={onDeleteExpression}
            showSearch={false}
          />
        </Form.Item>
      </Row>
    </Form>
  );
};

export const FilterGroupContainer = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { data: filterGroupData, isFetching } = useGetFilterExpressionGroupInfo(
    {
      params: { idFilterExpressionGroup: Number(groupId) },
    }
  );
  const updateFilterExpressionGroup = useUpdateFilterExpressionGroup();
  const client = useQueryClient();

  const initialValues: FilterGroupFormModel = {
    groupName: filterGroupData?.filterExpressionGroup?.name,
    category: CategoryOptions[0],
    action: ActionOptions[0],
    applyTo: AppliedOptions[0],
    visibleExpression: getVisibleExpressions(
      filterGroupData?.filterExpressionGroup.filterExpressions,
      {
        categoryFilter: 1,
        actionFilter: 1,
        applyToFilter: 1,
        searchTerm: '',
      }
    ).map((el) => ({
      label: el.regularExpression,
      value: el.idFilterExpression,
    })),
    initialExpressions:
      filterGroupData?.filterExpressionGroup.filterExpressions || [],
    addedExpression: [],
    deletedExpression: [],
  };

  const handleSubmit = (values: FilterGroupFormModel) => {
    const request: Partial<UpdateFilterExpressionGroupRequest> = {
      idFilterExpressionsToDelete: values.deletedExpression.map(
        (el) => el.idFilterExpression
      ),
      filterExpressionGroup: {
        idFilterExpressionGroup: Number(groupId),
        name: values.groupName,
        filterExpressions: values.addedExpression,
      },
    };

    return updateFilterExpressionGroup.mutateAsync(request, {
      onSuccess: () => {
        message.success('Filter group updated successfully');
        client.invalidateQueries([
          FilterExpressionGroupEndpoints.GetFilterExpressionGroupInfo,
        ]);
      },
      onError: () => {
        message.error(
          'Something went wrong, please confirm the information and try again'
        );
      },
    });
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <FormContainerWithErrorBoundary
      Form={FilterGroupForm}
      enableReinitialize
      initialStatus={{}}
      optionalFormProps={{}}
      onSubmit={handleSubmit}
      onReject={() => {}}
      initialValues={initialValues}
      errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
    />
  );
};

export const NewFilterGroupContainer = () => {
  const createFilterExpressionGroup = useCreateFilterExpressionGroupMutation();
  const client = useQueryClient();
  const navigation = useNavigate();

  const initialValues: FilterGroupFormModel = {
    groupName: '',
    category: CategoryOptions[0],
    action: ActionOptions[0],
    applyTo: AppliedOptions[0],
    visibleExpression: [],
    initialExpressions: [],
    addedExpression: [],
    deletedExpression: [],
  };

  const handleSubmit = (values: FilterGroupFormModel) => {
    const request: Partial<CreateFilterExpressionGroupRequest> = {
      filterExpressionGroup: {
        idFilterExpressionGroup: undefined,
        name: values.groupName,
        filterExpressions: values.addedExpression,
      },
    };

    return createFilterExpressionGroup.mutateAsync(request, {
      onSuccess: (data) => {
        client.invalidateQueries([
          FilterExpressionGroupEndpoints.ListFilterExpressionGroups,
        ]);
        message.success('Filter group created successfully');
        navigation(`/news/groups/${data.idFilterExpressionGroup}`);
      },
      onError: () => {
        message.error(
          'Something went wrong, please confirm the information and try again'
        );
      },
    });
  };
  return (
    <FormContainerWithErrorBoundary
      Form={FilterGroupForm}
      enableReinitialize
      initialStatus={{}}
      optionalFormProps={{}}
      onSubmit={handleSubmit}
      onReject={() => {}}
      initialValues={initialValues}
      errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
    />
  );
};
