import { forEach, map } from 'lodash';
import {
  fetchLiveSearchResults,
  resetLiveSearchResults,
} from 'client/modules/common/redux/actions/live-search';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import {
  initializeInputInfo,
  updateMultipleInputsInfo,
} from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/autocomplete-input';
import { clearModifiedRecordsDiff } from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/modified-records';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import * as tabName from 'client/modules/markets/utils/tab-names';
import { updateTotalRecords, updateIsChildMarket } from './data-details';
import { marketSuggestions } from '../utils/api-requests-helper';
import { getCurrentTab } from '../utils/records-helper';
import { updateAnalystSuggestions } from './autocomplete-input';

export const UPDATE_CURRENT_SEARCH_INFO =
  'client/modules/markets/search-bar/UPDATE_CURRENT_SEARCH_INFO';
export const SET_SELECTED_SEARCH_INFO =
  'client/modules/markets/search-bar/SET_SELECTED_SEARCH_INFO';
export const SET_MARKET_SUGGESTIONS =
  'client/modules/markets/search-bar/SET_MARKET_SUGGESTIONS';
export const SET_COMPANY_SUGGESTIONS =
  'client/modules/markets/search-bar/SET_COMPANY_SUGGESTIONS';
export const SET_MARKET_SIZING_DETAILS =
  'client/modules/markets/search-bar/SET_MARKET_SIZING_DETAILS';
export const SET_RELATED_MARKETS_DETAILS =
  'client/modules/markets/search-bar/SET_RELATED_MARKETS_DETAILS';
export const SET_LIST_OF_MARKETS_DETAILS =
  'client/modules/markets/search-bar/SET_LIST_OF_MARKETS_DETAILS';
export const INITIALZE_SEARCH_TERM =
  'client/modules/markets/search-bar/INITIALZE_SEARCH_TERM';
export const SET_ADMIN_USER_SUGGESTIONS =
  'client/modules/markets/search-bar/SET_ADMIN_USER_SUGGESTIONS';
export const SET_ANALYST_SUGGESTIONS =
  'client/modules/markets/search-bar/SET_ANALYST_SUGGESTIONS';
export const SET_SEARCH_TYPES =
  'client/modules/markets/search-bar/SET_SEARCH_TYPES';

export const SEARCH_TYPES = { MARKET: 1, COMPANY: 3, USER: 4, ANALYST: 5 };

export const updateCurrentSearchInfo = (searchTerm, searchType, searchId) => {
  return { type: UPDATE_CURRENT_SEARCH_INFO, searchTerm, searchType, searchId };
};

export const setSelectedSearchInfo = (searchTerm, searchType, searchId) => {
  return { type: SET_SELECTED_SEARCH_INFO, searchTerm, searchType, searchId };
};

export const setCompanySuggestions = (suggestions) => {
  return { type: SET_COMPANY_SUGGESTIONS, suggestions };
};

export const setAdminUserSuggestions = (filteredAdminUsers) => {
  return { type: SET_ADMIN_USER_SUGGESTIONS, suggestions: filteredAdminUsers };
};

export const setAnalystSuggestions = (suggestions) => {
  return { type: SET_ANALYST_SUGGESTIONS, suggestions };
};

export const initializeSearchTerm = (searchTerm, searchTermId, searchType) => {
  return { type: INITIALZE_SEARCH_TERM, searchTerm, searchTermId, searchType };
};

export const updateMarketSuggestionsHelper = (searchTerm, markets) => {
  return { type: SET_MARKET_SUGGESTIONS, searchTerm, markets };
};

export const setSearchTypes = () => {
  return getCurrentTab((currentTab) => {
    return { type: SET_SEARCH_TYPES, currentTab };
  });
};

export const formatAutoCompleteRecords = (records) => {
  const formattedRecords = {};
  forEach(records, (record) => {
    formattedRecords[`${record.id}-market`] = {
      termId: record.market.id,
      term: record.market.name,
    };
    formattedRecords[`${record.id}-geography`] = {
      termId: record.geo.id,
      term: record.geo.name,
    };
    formattedRecords[`${record.id}-analyst`] = {
      termId: record.analyst.id,
      term: record.analyst.analyst,
    };
  });
  return formattedRecords;
};

export const initializeAutocompleteInput = (records) => {
  return (dispatch) => {
    const formattedRecords = formatAutoCompleteRecords(records);
    return dispatch(initializeInputInfo(formattedRecords));
  };
};

export const initializeAndUpdateAutocompleteInput = (records) => {
  return (dispatch) => {
    const formattedRecords = formatAutoCompleteRecords(records);
    return dispatch(updateMultipleInputsInfo(formattedRecords));
  };
};

export const marketSizingDetailsSearch = ({
  searchTerm,
  limit,
  offset,
  status,
  searchType,
  sortField,
  sortDirection,
  urlStatusFilter,
  searchReviewerId,
  searchAnalystTerm,
}) => ({
  types: [null, SET_MARKET_SIZING_DETAILS],
  service: {
    endpoint: 'searchMarketSizingDetails',
    name: 'marketservice',
    body: {
      searchTerm: searchTerm ? searchTerm.trim() : '',
      status,
      searchType,
      offset,
      limit,
      sortField,
      sortDirection,
      urlStatusFilter,
      searchReviewerId,
      searchAnalystTerm: searchAnalystTerm ? searchAnalystTerm.trim() : '',
    },
  },
});

export const relatedMarketsSearch = (
  searchTermId,
  searchTerm,
  limit,
  offset,
  status,
  searchType,
  sortField,
  sortDirection
) => ({
  types: [null, SET_RELATED_MARKETS_DETAILS],
  service: {
    endpoint: 'searchRelatedMarkets',
    name: 'marketservice',
    body: {
      searchTerm: searchTerm ? searchTerm.trim() : '',
      searchTermId,
      status,
      searchType,
      offset,
      limit,
      sortField,
      sortDirection,
    },
  },
});

export const listOfMarketsSearch = (
  searchTerm,
  limit,
  offset,
  status,
  searchType,
  sortField,
  sortDirection
) => ({
  types: [null, SET_LIST_OF_MARKETS_DETAILS],
  service: {
    endpoint: 'SearchListOfMarkets',
    name: 'marketservice',
    body: {
      searchTerm: searchTerm ? searchTerm.trim() : '',
      status,
      searchType,
      offset,
      limit,
      sortField,
      sortDirection,
    },
  },
});

export const updateCompanySuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    if (!searchTerm) {
      dispatch(resetLiveSearchResults());
      dispatch(setCompanySuggestions([]));
    }
    return dispatch(fetchLiveSearchResults(searchTerm)).then(() => {
      dispatch(setCompanySuggestions(getState().common.liveSearch.companies));
    });
  };
};

export const updateAdminUserSuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    if (!searchTerm) {
      return dispatch(setAdminUserSuggestions([]));
    }
    const allAdminUsers = getState().adminUsers.userInfo;
    const filteredAdminUsers = filterListOfObjects({
      value: searchTerm,
      listOfItems: allAdminUsers,
    });
    return dispatch(setAdminUserSuggestions(filteredAdminUsers));
  };
};

export const updateAnalystSuggestionsHelper = (searchTerm) => {
  return (dispatch) => {
    if (!searchTerm) {
      return dispatch(setAnalystSuggestions([]));
    }
    return dispatch(updateAnalystSuggestions(searchTerm, 0, 10)).then(
      (response) => {
        const analysts = map(response.analysts, (analystInfo) => {
          return { name: analystInfo.analyst, id: analystInfo.id };
        });
        return dispatch(setAnalystSuggestions(analysts));
      }
    );
  };
};

export const updateSearchSuggestions = (searchTerm, searchType) => {
  return (dispatch) => {
    if (searchType === SEARCH_TYPES.MARKET) {
      return dispatch(marketSuggestions(searchTerm)).then((data) => {
        dispatch(updateMarketSuggestionsHelper(searchTerm, data.markets));
      });
    } if (searchType === SEARCH_TYPES.USER) {
      return dispatch(updateAdminUserSuggestions(searchTerm));
    } if (searchType === SEARCH_TYPES.ANALYST) {
      return dispatch(updateAnalystSuggestionsHelper(searchTerm));
    }
    // This will need to use live search!
    return dispatch(updateCompanySuggestions(searchTerm));
  };
};

export const makeSearch = (searchTerm, searchType) => {
  return (dispatch, getState) => {
    const {markets} = getState();
    const {currentTab} = markets;
    const currentTabDetails = markets.tabDetails[currentTab];

    const urlStatusFilterId =
      markets.urlStatusFilters.selectedUrlStatusFilterId;

    const limit = currentTabDetails.recordsPerPage;
    const offset = currentTabDetails.pageNumber * limit;
    const status = markets.recordStatusFilters.selectedRecordStatusFilterId;
    const {sortField} = currentTabDetails.sortInfo;
    const {sortDirection} = currentTabDetails.sortInfo;

    const theSearchTerm = searchTerm || markets.searchBar.currentSearchTerm;
    const theSearchType = searchType || markets.searchBar.currentSearchType;
    const theSearchId = markets.searchBar.currentSearchId;
    dispatch(setSelectedSearchInfo(theSearchTerm, theSearchType, theSearchId));
    dispatch(clearModifiedRecordsDiff());

    updateUrlParams('searchTerm', theSearchTerm);
    updateUrlParams('searchTermId', theSearchId);
    updateUrlParams('searchType', theSearchType);

    if (currentTab === tabName.MARKET_SIZING_DETAILS) {
      // Make some hack when seach by userid.
      let searchTermToPost = theSearchTerm;
      let searchTypeToPost = theSearchType;
      if (
        theSearchType === SEARCH_TYPES.USER ||
        theSearchType === SEARCH_TYPES.ANALYST
      ) {
        searchTermToPost = '';
        searchTypeToPost = SEARCH_TYPES.MARKET;
      }

      const searchReviewerId =
        theSearchType === SEARCH_TYPES.USER ? theSearchId : 0;
      const searchAnalystTerm =
        theSearchType === SEARCH_TYPES.ANALYST ? theSearchTerm : '';

      dispatch(
        marketSizingDetailsSearch({
          searchTerm: searchTermToPost,
          limit,
          offset,
          status,
          searchType: searchTypeToPost,
          sortField,
          sortDirection,
          urlStatusFilter: urlStatusFilterId,
          searchReviewerId,
          searchAnalystTerm,
        })
      ).then((data) => {
        dispatch(updateTotalRecords(data.totalResults));
        dispatch(initializeAutocompleteInput(data.rows));
      });
    } else if (currentTab === tabName.RELATED_MARKETS_DETAILS) {
      dispatch(
        relatedMarketsSearch(
          theSearchId,
          theSearchTerm,
          limit,
          offset,
          status,
          theSearchType,
          sortField,
          sortDirection
        )
      ).then((data) => {
        dispatch(updateTotalRecords(data.totalResults));
        dispatch(updateIsChildMarket(data.isChild));
        if (!theSearchId && data.rows.length) {
          const searchId = data.rows[0].parent.id;
          dispatch(
            setSelectedSearchInfo(theSearchTerm, theSearchType, searchId)
          );
          updateUrlParams('searchTermId', searchId);
        }
      });
    } else if (currentTab === tabName.LIST_OF_MARKETS_DETAILS) {
      dispatch(
        listOfMarketsSearch(
          theSearchTerm,
          limit,
          offset,
          status,
          theSearchType,
          sortField,
          sortDirection
        )
      ).then((data) => {
        dispatch(updateTotalRecords(data.totalResults));
      });
    }
  };
};
