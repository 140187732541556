import { useEffect, useState } from 'react';
import { request } from './request';

const CACHE = {};

/**
 * @deprecated
 * @param {String} url - the endpoint you are looking to hit
 * @param {String} options.method - either get or post
 * @param {Object} options.body - the request body
 * @param {Object} options.query - url params to send
 * @param {Boolean} options.shouldRetry - if request fails should it retry
 * @param {String} options.contentType - content type to accept
 * @param {Boolean} options.getRawRes - if true will return the full res obj
 * @param {Function} options.transformer - a function to run on the response
 * @param {Boolean} options.mocResolver - a function that returns data, for testing only
 * @param {Boolean} options.cache - will cache results
 */
export function useRequest(url, options = {}) {
  const cacheKey = `${url}_${JSON.stringify(options)}`;

  const [state, setState] = useState({
    error: false,
    body: undefined,
    loading: true,
  });

  useEffect(() => {
    let reqObj = {};
    if (options.cache && CACHE[cacheKey]) {
      setState({
        error: false,
        body: CACHE[cacheKey],
        loading: false,
      });
    } else {
      reqObj = options.mocResolver
        ? { request: options.mocResolver(url, options) }
        : request(url, options);

      setState({
        error: false,
        body: undefined,
        loading: true,
      });

      reqObj.request
        .then((response) => {
          const resBody = options.transformer
            ? options.transformer(response)
            : response;

          if (options.cache) {
            CACHE[cacheKey] = resBody;
          }
          setState({
            error: false,
            body: resBody,
            loading: false,
          });
        })
        .catch((error) => {
          setState({
            error,
            body: undefined,
            loading: false,
          });
        });
    }

    // abort this on unmount or request change
    return () => reqObj.abort && reqObj.abort();
  }, [url, cacheKey]);

  return state;
}
