import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MaterialUILink } from 'client/component-library/Link';
import { removeNewUser } from '../redux/actions/users';

class RemoveNewUserLink extends Component {
  onClickComponent = () => {
    this.props.removeNewUser({
      idUser: this.props.idUser,
      adminUsers: this.props.adminUsers,
      numAdminUsers: this.props.numAdminUsers,
      adminUserPageNumber: this.props.adminUserPageNumber,
      adminUserRecordsPerPage: this.props.adminUserRecordsPerPage,
      pipelineUsers: this.props.pipelineUsers,
      numPipelineUsers: this.props.numPipelineUsers,
      pipelineUserPageNumber: this.props.pipelineUserPageNumber,
      pipelineUserRecordsPerPage: this.props.pipelineUserRecordsPerPage,
      newUsers: this.props.newUsers,
      numNewUsers: this.props.numNewUsers,
      newUserPageNumber: this.props.newUserPageNumber,
      newUserRecordsPerPage: this.props.newUserRecordsPerPage,
      adminUserFilterTerm: this.props.adminUserFilterTerm,
    });
  };

  render() {
    return (
      <MaterialUILink onClick={this.onClickComponent}>remove</MaterialUILink>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    adminUsers: dataPlatform.users.adminUsers,
    numAdminUsers: dataPlatform.users.numAdminUsers,
    adminUserPageNumber: dataPlatform.users.adminUserPageNumber,
    adminUserRecordsPerPage: dataPlatform.users.adminUserRecordsPerPage,
    pipelineUsers: dataPlatform.users.pipelineUsers,
    numPipelineUsers: dataPlatform.users.numPipelineUsers,
    pipelineUserPageNumber: dataPlatform.users.pipelineUserPageNumber,
    pipelineUserRecordsPerPage: dataPlatform.users.pipelineUserRecordsPerPage,
    newUsers: dataPlatform.users.newUsers,
    numNewUsers: dataPlatform.users.numNewUsers,
    newUserPageNumber: dataPlatform.users.newUserPageNumber,
    newUserRecordsPerPage: dataPlatform.users.newUserRecordsPerPage,
    adminUserFilterTerm: dataPlatform.users.adminUserFilterTerm,
  };
}

RemoveNewUserLink.propTypes = {
  idUser: PropTypes.number.isRequired,
  removeNewUser: PropTypes.func.isRequired,
  adminUsers: PropTypes.object,
  numAdminUsers: PropTypes.number,
  adminUserPageNumber: PropTypes.number,
  adminUserRecordsPerPage: PropTypes.number,
  pipelineUsers: PropTypes.object,
  numPipelineUsers: PropTypes.number,
  pipelineUserPageNumber: PropTypes.number,
  pipelineUserRecordsPerPage: PropTypes.number,
  newUsers: PropTypes.object,
  numNewUsers: PropTypes.number,
  newUserPageNumber: PropTypes.number,
  newUserRecordsPerPage: PropTypes.number,
  adminUserFilterTerm: PropTypes.string,
};

RemoveNewUserLink.defaultProps = {
  adminUsers: {},
  numAdminUsers: 0,
  adminUserPageNumber: 0,
  adminUserRecordsPerPage: 3,
  pipelineUsers: {},
  numPipelineUsers: 0,
  pipelineUserPageNumber: 0,
  pipelineUserRecordsPerPage: 3,
  newUsers: {},
  numNewUsers: 0,
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
  adminUserFilterTerm: '',
};

export default connect(mapStateToProps, { removeNewUser })(RemoveNewUserLink);
