import { useMemo } from 'react';
import { useGetTeamInvites } from '../services/hooks/useGetTeamInvites';

export type PendingInvitation = {
  email: string;
  invitedBy: string;
};

type Props = {
  idTeam: number;
};

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Invited By',
    dataIndex: 'invitedBy',
    key: 'invitedBy',
  },
];

const useTeamInvitations = ({ idTeam }: Props) => {
  const { data } = useGetTeamInvites({
    enabled: Boolean(idTeam),
    params: {
      id_team: idTeam,
    },
  });

  const pendingInvitations: Array<PendingInvitation> = useMemo(() => {
    if (!data?.invites) {
      return [];
    }

    return data.invites
      .filter((invite) => !invite.is_accepted)
      .map((invite) => {
        return {
          email: invite.invitee_email,
          invitedBy: invite.inviter_email,
        };
      });
  }, [data]);

  return {
    columns,
    pendingInvitations,
  };
};

export default useTeamInvitations;
