import React from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Tooltip, message } from 'antd';
import EditableText from 'client/component-library/EditableText/EditableText';
import { useUpdateTeam } from '../../hooks/useUpdateTeam';
import { useResetFeatureUsage } from '../../hooks/useResetFeatureUsage';
import { FEATURE_IDS } from '../../constants/features';

type Props = {
  downloads: number;
  teamId: number;
  refetchTeam: () => void;
};

export const downloadValidation = (value: string) => {
  if (value === null || value === undefined) {
    return 'This field is required. Please enter a number.';
  }

  const number = Number(value);

  if (Number.isNaN(number)) {
    return 'Invalid input. Please enter a valid number.';
  }

  if (number < 0) {
    return 'Number is too small. Please enter a number greater than or equal to 0.';
  }

  if (number > 1000000000) {
    return 'Number is too large. Please enter a number less than or equal to 1,000,000,000.';
  }

  return '';
};

const Downloads = ({ downloads, teamId, refetchTeam }: Props) => {
  const { onSubmit } = useUpdateTeam(refetchTeam);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [resetDownloadsForAllUsers, setResetDownloadsForAllUsers] =
    React.useState(false);

  const downloadsText = (downloads || 0).toString();
  const { resetFeatureUsage, updateFeatureUsageQuery } = useResetFeatureUsage();

  const onSave = (newText: string) => {
    onSubmit(
      {
        downloads: Number(newText),
      },
      teamId
    )
      .then(() => {
        message.success("Success! The team's downloads has been updated.");
        refetchTeam();
      })
      .catch(() => {
        message.error(
          "Error! Failed to update the team's downloads. Please try again later."
        );
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setResetDownloadsForAllUsers(false);
  };

  const onResetDownloads = () => {
    resetFeatureUsage({
      idFeature: FEATURE_IDS.downloads,
      idTeam: teamId,
      newValue: 0,
      updateUsers: resetDownloadsForAllUsers,
    })
      .then(() => {
        message.success("Success! The team's downloads has been updated.");
        closeModal();
        refetchTeam();
      })
      .catch(() => {
        message.error(
          "Error! Failed to update the team's downloads. Please try again later."
        );
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        title="Are you sure you want to reset the downloads for the team?"
        open={isModalOpen}
        onOk={onResetDownloads}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updateFeatureUsageQuery.isLoading}
            onClick={onResetDownloads}
          >
            Submit
          </Button>,
        ]}
      >
        <Checkbox
          checked={resetDownloadsForAllUsers}
          onChange={(e) => setResetDownloadsForAllUsers(e.target.checked)}
        >
          Reset the downloads for all the team members
        </Checkbox>
      </Modal>
      <EditableText
        text={downloadsText}
        onSave={onSave}
        validation={downloadValidation}
      />
      <Tooltip title="Reset Downloads">
        <Button
          type="link"
          shape="circle"
          className="ml-1"
          icon={<ClearOutlined />}
          onClick={openModal}
        />
      </Tooltip>
    </>
  );
};

export default Downloads;
