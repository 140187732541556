import {
  BatchCreateUsersRequest,
  BatchCreateUsersResponse,
  User,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

type QueryRequest = {
  id_team: BatchCreateUsersRequest['id_team'];
  users: Array<Partial<User>>;
  skip_welcome_email?: boolean;
};

export const batchCreateUsers = (
  req: Partial<QueryRequest>
): Promise<BatchCreateUsersResponse> =>
  request({
    url: '/service/adminservice/BatchCreateUsers',
    body: req,
  }).then((r) => r.body);
