import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

export type QueryParams = Record<string, string>;
export type Query<TQueryParams extends QueryParams = QueryParams> = {
  queryParams: TQueryParams;
  updateQueryParams: (param: TQueryParams, cleanUpdate?: boolean) => void;
};

/**
 * @param search: URL search string that will be converted in a Key/Value Object
 * @returns QueryParams object
 */
export const getQueryParams = (search: string): qs.ParsedQuery<string> =>
  qs.parse(search) as QueryParams;

/**
 * @param queryParams Object that will be converted to URL search string
 * @returns URL Search string (it will prefixed with character ´?´)
 */
export const getSearchFromQueryParams = <TQueryParams extends QueryParams>(
  queryParams: TQueryParams
): string => `?${new URLSearchParams(queryParams).toString()}`;

/**
 * hook that returns an object with two properties:
 *
 * - `queryParams`: an object with the query parameters from the current URL search string
 * - `updateQueryParams`: a function that updates the query parameters in the URL search string
 *
 * @returns An object with two properties: queryParams and updateQueryParams.
 */
export const useQueryParams = <
  TQueryParams extends QueryParams
>(): Query<TQueryParams> => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const queryParams = React.useMemo(
    () => getQueryParams(search) as TQueryParams,
    [search]
  );

  const updateQueryParams = (param: TQueryParams, cleanUpdate = false) =>
    navigate({
      pathname,
      search: cleanUpdate
        ? getSearchFromQueryParams({ ...param })
        : getSearchFromQueryParams({ ...queryParams, ...param }),
    });

  return {
    queryParams,
    updateQueryParams,
  };
};
