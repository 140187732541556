import { Button, Col, Form, Layout, Row, Spin, message } from 'antd';
import {
  FormikContainerProps,
  FormikFormProps,
  FormikOnSubmit,
} from 'client/modules/common/form/containers/FormikContainer';
import {
  useGetAllCountryValues,
  useGetAllSectorValues,
} from 'client/modules/cbi-entity/services/general-info/hooks/useGeneralFormDependencies';

import { AntdInputField } from 'client/modules/common/antDesign/form/fields/AntdInputField';
import { AntdSelectField } from 'client/modules/common/antDesign/form/fields/AntdSelectField';
import { DefaultOptionType } from 'antd/lib/select';
import { FormContainerWithErrorBoundary } from 'client/modules/common/form/containers/FormContainerWithErrorBoundary';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { memo, useMemo } from 'react';
import { NewsSource } from '@cbinsights/newsservice/newsservice';
import { useListFilterExpressionGroups } from '../../filter-groups/services/hooks/useGetFilterExpressionGroupInfo';
import {
  useCreateNewsSource,
  useGetNewsSource,
  useUpdateNewsSource,
} from './services/hooks';

type NewsSourcesFormModel = {
  idSource: number;
  name: string;
  url: string;
  rss: string;
  sector: DefaultOptionType;
  country: DefaultOptionType;
  dateAdded: string;
  lastFetched: string;
  status: DefaultOptionType;
  groups: DefaultOptionType[];
  stale: boolean;
};

export type NewsSourcesFormProps = FormikFormProps<
  Record<string, never>,
  NewsSourcesFormModel,
  { isNew: boolean }
>;

export const NewsSourcesForm = ({
  status,
  handleSubmit,
}: NewsSourcesFormProps) => {
  const { data: filterExpressionGroupsData } = useListFilterExpressionGroups();
  const { data: countryData } = useGetAllCountryValues({ enabled: true });
  const { data: sectorData } = useGetAllSectorValues({ enabled: true });

  const filterExpressionGroupOptions = useMemo(
    () =>
      filterExpressionGroupsData?.filterExpressionGroups.map((el) => ({
        label: el.name,
        value: el.idFilterExpressionGroup,
      })) || [],
    [filterExpressionGroupsData]
  );

  const countryDataOptions = useMemo(
    () =>
      countryData?.countryInfo.map((el) => ({
        label: el.name,
        value: el.idCountry,
      })) || [],
    [countryData]
  );

  const sectorDataOptions = useMemo(
    () =>
      sectorData?.taxonomyMap?.map((el) => ({
        label: el.name,
        value: el.id,
      })) || [],
    [sectorData]
  );

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Name" required>
            <AntdInputField name="name" />
          </Form.Item>
          <Form.Item label="URL" required>
            <AntdInputField name="url" />
          </Form.Item>
          <Form.Item label="RSS" required>
            <AntdInputField name="rss" />
          </Form.Item>
          <Form.Item label="Sector">
            <AntdSelectField
              name="sector"
              labelInValue
              options={sectorDataOptions}
              placeholder="Select a sector"
            />
          </Form.Item>
          <Form.Item label="Country">
            <AntdSelectField
              name="country"
              labelInValue
              options={countryDataOptions}
              placeholder="Select a country"
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          {!status.isNew && (
            <Row justify="space-between" gutter={16}>
              <Col span={8}>
                <Form.Item label="Date Added">
                  <AntdInputField name="dateAdded" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last Fetched">
                  <AntdInputField name="lastFetched" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Status">
                  <AntdSelectField
                    name="status"
                    labelInValue
                    options={[
                      { value: 1, label: 'Active' },
                      { value: 0, label: 'Inactive' },
                    ]}
                    placeholder="Select an option"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item label="Group">
            <AntdSelectField
              name="groups"
              className="input-groups"
              mode="multiple"
              labelInValue
              options={filterExpressionGroupOptions}
              placeholder="Select an option"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button>
          <Link to="/news/sources">Back</Link>
        </Button>
        <Button type="primary" onClick={() => handleSubmit()}>
          Submit
        </Button>
      </Row>
    </Form>
  );
};

export const NewsSourcesLayout = memo(
  ({
    title,
    Form: FormImpl,
    onSubmit,
    onReject,
    initialValues,
    initialStatus,
  }: { title: string } & FormikContainerProps<
    Record<string, never>,
    NewsSourcesFormModel
  >) => {
    return (
      <Layout className="container mx-auto my-8 !bg-white ">
        <Row justify="space-between" align="middle" className="mb-5">
          <Title level={2} className="my-0">
            {title}
          </Title>
        </Row>

        <FormContainerWithErrorBoundary
          Form={FormImpl}
          enableReinitialize
          onSubmit={onSubmit}
          onReject={onReject}
          optionalFormProps={{}}
          initialValues={initialValues}
          initialStatus={initialStatus}
          errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
        />
      </Layout>
    );
  }
);

export const AddNewsSources = () => {
  const navigation = useNavigate();
  const { mutateCreateNewsSource } = useCreateNewsSource();
  const submitHandler: FormikOnSubmit<
    NewsSourcesFormModel,
    Record<string, unknown>
  > = (values) => {
    const request: NewsSource = {
      name: values?.name,
      url: values?.url,
      rss: values?.rss,
      active: true,
      id_country: Number(values?.country?.value),
      id_sector: Number(values?.sector?.value),
      ts_created: values?.dateAdded,
      ts_fetched: values?.lastFetched,
      filter_expression_groups:
        values?.groups?.map((el) => ({
          id_filter_expression_group: Number(el.value),
          name: el.label.toString(),
        })) || [],
      stale: false,
      id_source: undefined,
    };

    return mutateCreateNewsSource(
      { news_source: request },
      {
        onSuccess: (response) => {
          message.success('News source created successfully! redirecting... ');
          navigation(`/news/sources/${response.id_source}`, { replace: true });
        },
        onError: (error: $TSFixMe) => {
          message.error(
            error?.response?.body?.error?.details ||
              'An error ocurred, please contact to an administrator'
          );
        },
      }
    );
  };

  return (
    <NewsSourcesLayout
      title="Add Sources"
      Form={NewsSourcesForm}
      onSubmit={submitHandler}
      onReject={() => null}
      optionalFormProps={{}}
      initialValues={{}}
      initialStatus={{ isNew: true }}
    />
  );
};

export const EditNewsSources = () => {
  const { sourceId } = useParams<{ sourceId: string }>();
  const {
    data: newsSource,
    isFetched,
    refetch,
  } = useGetNewsSource({
    id_source: Number(sourceId),
  });

  const { mutateUpdateNewsSource: mutateNewsSource } = useUpdateNewsSource();

  if (!isFetched) {
    return (
      <div className="flex justify-center my-10">
        <Spin size="large" />
      </div>
    );
  }

  const initialValues: NewsSourcesFormModel = {
    idSource: Number(sourceId),
    name: newsSource?.news_source?.name,
    url: newsSource?.news_source?.url,
    rss: newsSource?.news_source?.rss,
    sector: Number(newsSource?.news_source?.id_sector)
      ? { value: Number(newsSource?.news_source?.id_sector), label: undefined }
      : undefined,
    country: Number(newsSource?.news_source?.id_country)
      ? { value: Number(newsSource?.news_source?.id_country), label: undefined }
      : undefined,
    dateAdded: newsSource?.news_source?.ts_created,
    lastFetched: newsSource?.news_source?.ts_fetched,
    status: {
      label: undefined,
      value: Number(newsSource?.news_source?.active),
    },
    groups: newsSource?.news_source?.filter_expression_groups.map((el) => ({
      label: el.name,
      value: el.id_filter_expression_group,
    })),
    stale: newsSource?.news_source?.stale,
  };

  const handleSubmit: FormikOnSubmit<
    NewsSourcesFormModel,
    Record<string, unknown>
  > = (values) => {
    const request: NewsSource = {
      id_source: values?.idSource,
      name: values?.name,
      url: values?.url,
      rss: values?.rss,
      active: Boolean(values?.status?.value),
      id_country: Number(values?.country?.value),
      id_sector: Number(values?.sector?.value),
      ts_created: values?.dateAdded,
      ts_fetched: values?.lastFetched,
      filter_expression_groups: values?.groups.map((el) => ({
        id_filter_expression_group: Number(el.value),
        name: el.label.toString(),
      })),
      stale: values?.stale,
    };
    return mutateNewsSource(
      { news_source: request },
      {
        onSuccess: () => {
          message.success('News Source updated successfully!');
          refetch();
        },
        onError: (error: $TSFixMe) => {
          message.error(
            error?.response?.body?.error?.details ||
              'An error ocurred, please contact to an administrator'
          );
        },
      }
    );
  };

  return (
    <NewsSourcesLayout
      title="Edit Sources"
      Form={NewsSourcesForm}
      onSubmit={handleSubmit}
      onReject={() => null}
      optionalFormProps={{}}
      initialValues={initialValues}
      initialStatus={{ isNew: false }}
    />
  );
};
