/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { map, compact, replace, filter } from 'lodash';
import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import TextField from 'client/component-library/TextField';
import defaultStyles from './styles/SearchableSelectStyles';
import SearchableSelectMenuItem from './SearchableSelectMenuItem';

const PLACEHOLDER_CHARACTER = '%';
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ENTER_KEY = 13;
const TAB_KEY = 9;

/** @deprecated It's a React component that renders a searchable select menu */
export default class SearchableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterTerm: '',
      menuOpen: false,
      anchorEl: null,
      cursorIndex: 0,
      filteredResults: this.props.menuItems,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }
  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    if (!prevState.menuOpen && this.state.menuOpen && value) {
      const event = { target: { value } };
      this.handleChange(event);
    }
  }
  filterResults = (filterTerm = '') => {
    const { menuItems, filterOptions, isFilteringDisabled } = this.props;

    if (
      !filterTerm.trim() ||
      filterTerm === PLACEHOLDER_CHARACTER ||
      isFilteringDisabled
    ) {
      return this.setState({ filteredResults: menuItems });
    }

    const regex = new RegExp(`${filterTerm}`, 'i');
    const items = filter(menuItems, (item) => {
      return regex.test(item[filterOptions.nameFilterKey].toLowerCase());
    });
    return this.setState({ filteredResults: items });
  };
  handleCursorIndexChange = (nextCursorIndex) => {
    this.setState({ cursorIndex: nextCursorIndex });
  };
  handleClose = () => {
    this.setState({ filterTerm: '', menuOpen: false, anchorEl: null });
    this.props.onSearchChange('', { target: { value: '' } });
  };
  handleOpen = (event) => {
    this.setState({
      menuOpen: true,
      anchorEl: event.target,
      cursorIndex: 0,
      filteredResults: this.props.menuItems,
    });
  };
  handleKeyDown = (event) => {
    const { cursorIndex, filteredResults } = this.state;
    const { isFilteringDisabled, menuItems } = this.props;

    const _options = isFilteringDisabled ? menuItems : filteredResults;

    if (
      event.keyCode === UP_ARROW &&
      cursorIndex > 0 &&
      cursorIndex < _options.length - 1
    ) {
      this.setState((prevState) => ({
        cursorIndex: prevState.cursorIndex - 1,
      }));
    } else if (
      event.keyCode === DOWN_ARROW &&
      cursorIndex < _options.length - 1
    ) {
      this.setState((prevState) => ({
        cursorIndex: prevState.cursorIndex + 1,
      }));
    } else if (
      event.keyCode === UP_ARROW &&
      cursorIndex >= _options.length - 1
    ) {
      this.setState({ cursorIndex: cursorIndex - 1 });
    } else if (event.keyCode === ENTER_KEY || event.keyCode === TAB_KEY) {
      this.props.handleSelect(_options[cursorIndex]);
      this.handleClose();
    }
  };
  handleChange = (event) => {
    // handle cases where the user deletes autopopulated text
    const { value } = event.target;
    let filterTerm = value;
    if (value === '') {
      filterTerm = PLACEHOLDER_CHARACTER;
    }
    this.setState({ filterTerm, cursorIndex: 0 });
    this.filterResults(filterTerm);
    if (!this.state.menuOpen) {
      this.handleOpen(event);
    }

    this.props.onSearchChange(event.target.value, event);
  };
  handleItemSelect = (item) => {
    this.props.handleSelect(item);
    this.handleClose();
  };
  handleOnBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (currentTarget && !currentTarget.contains(document.activeElement)) {
        this.handleClose();
      }
    }, 0);
  };
  renderDisplayText = () => {
    const { filterTerm } = this.state;
    const { value } = this.props;
    if (filterTerm === '') {
      return value;
    }
    return replace(filterTerm, PLACEHOLDER_CHARACTER, '');
  };
  renderMenuItems = () => {
    const {
      filterOptions,
      styles,
      renderOption,
      menuItems,
      isFilteringDisabled,
    } = this.props;
    const { cursorIndex, filteredResults } = this.state;

    const optionsToRender = isFilteringDisabled ? menuItems : filteredResults;

    const items = map(optionsToRender, (item, index) => {
      return (
        <SearchableSelectMenuItem
          item={item}
          value={item[filterOptions.valueFilterKey]}
          name={item[filterOptions.nameFilterKey]}
          handleItemSelect={() => this.handleItemSelect(item)}
          styles={styles}
          key={item[filterOptions.valueFilterKey]}
          index={index}
          cursorIndex={cursorIndex}
          handleCursorIndexChange={this.handleCursorIndexChange}
          renderOption={renderOption}
        />
      );
    });
    return compact(items);
  };

  render() {
    const { styles, inputRef } = this.props;
    const displayText = this.renderDisplayText();
    return (
      <Paper>
        <div
          onFocus={this.handleOpen}
          onBlur={this.handleOnBlur}
          style={{ padding: '2px 5px' }}
        >
          <TextField
            onChange={this.handleChange}
            value={displayText}
            onKeyDown={this.handleKeyDown}
            inputRef={inputRef}
            label="Search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ol style={styles.menuContainer}>{this.renderMenuItems()}</ol>
        </div>
      </Paper>
    );
  }
}

SearchableSelect.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelect: PropTypes.func.isRequired,
  inputRef: PropTypes.func,
  onSearchChange: PropTypes.func,
  renderOption: PropTypes.func,
  styles: PropTypes.object,
  value: PropTypes.string,
  filterOptions: PropTypes.object,
  isFilteringDisabled: PropTypes.bool,
};

SearchableSelect.defaultProps = {
  inputRef: () => {},
  onSearchChange: () => {},
  renderOption: null,
  isFilteringDisabled: false,
  value: '',
  filterOptions: {
    valueFilterKey: 'id',
    nameFilterKey: 'name',
  },
  styles: defaultStyles,
};
