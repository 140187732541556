import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserQueueDetails from 'client/modules/news/queue/components/UserQueueDetails';
import QueueTable from 'client/modules/news/queue/components/QueueTable';
import QueueRow from 'client/modules/news/queue/components/QueueRow';
import ClearQueueButton from 'client/modules/news/queue/components/ClearQueueButton';
import FetchQueueButton from 'client/modules/news/queue/components/FetchQueueButton';
import RelatedNewsList from 'client/modules/news/queue/components/RelatedNewsList';
import FormControl from 'client/modules/common/components/forms/FormControl';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import * as actionCreators from 'client/modules/news/queue/redux/reducers/news-queue.reducer';
import { Dialog } from 'client/component-library/Dialog';
import { map } from 'lodash';
import { MultiSelect } from 'client/component-library/Select/MultiSelect';
import { SimpleSelect } from 'client/component-library/Select/SimpleSelect';

const SourceTypeOptions = [
  actionCreators.SOURCETYPE_ALL,
  actionCreators.SOURCETYPE_FUNDING,
  actionCreators.SOURCETYPE_MERGERS_ACQUISITIONS,
  actionCreators.SOURCETYPE_SEC_FUNDS,
  actionCreators.SOURCETYPE_SEC_NEWS,
].map((name) => ({ label: name, value: name }));

export class UserQueue extends React.Component {
  constructor(props) {
    super(props);

    this.onArchive = this.onArchive.bind(this);
    this.onDiscard = this.onDiscard.bind(this);
    this.onBlock = this.onBlock.bind(this);
    this.onChangeSourceType = this.onChangeSourceType.bind(this);
    this.onChangeFilterGroup = this.onChangeFilterGroup.bind(this);
    this.onFetchNews = this.onFetchNews.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onRelatedNewsListChange = this.onRelatedNewsListChange.bind(this);
    this.markNewsAsUnrelated = this.markNewsAsUnrelated.bind(this);

    this.state = {
      showModal: false,
      modalItemIndex: 0,
    };
  }

  componentDidMount() {
    this.props.actions.fetchAssignedNews();
    this.props.actions.fetchAllFilterGroups();
  }

  onChangeSourceType(sourceType) {
    this.props.actions.changeSourceTypeAndClearQueue(sourceType.value);
  }

  onChangeFilterGroup(filterGroupName) {
    this.props.actions.changeFilterTypeAndClearQueue(
      filterGroupName.map((fgn) => fgn.value)
    );
  }

  onArchive(index) {
    this.props.actions.resolveAndFetch(
      this.props.newsQueue.userQueue[index],
      index,
      actionCreators.NEWS_QUEUE_ARCHIVE
    );
  }

  onDiscard(index) {
    this.props.actions.resolveAndFetch(
      this.props.newsQueue.userQueue[index],
      index,
      actionCreators.NEWS_QUEUE_DISCARD
    );
  }

  onBlock(index) {
    this.props.actions.blockCIKCall(
      this.props.newsQueue.userQueue[index],
      index
    );
  }

  onFetchNews(e) {
    e.preventDefault();
    this.props.actions.fetchNews(actionCreators.QUEUE_SIZE);
  }

  onRelatedNewsListChange(id) {
    this.props.actions.toggleRelatedFlagForNewsItem(
      this.state.modalItemIndex,
      id
    );
  }

  markNewsAsUnrelated() {
    this.props.actions.markNewsAsUnrelated(this.state.modalItemIndex);
    this.close();
  }

  close() {
    this.setState({ showModal: false, modalItemIndex: 0 });
  }

  open(index) {
    this.setState({ showModal: true, modalItemIndex: index });
  }

  render() {
    const {
      newsQueue: {
        isFetchingNews,
        sourceType,
        displaySourceType,
        remainingCount,
        userQueue,
        isResolvingNews,
        selectedFilterGroupName,
        filterGroupNames,
      },
      user,
      actions,
    } = this.props;

    const queue =
      userQueue.length === 0 ? (
        <FetchQueueButton
          onClick={this.onFetchNews}
          isFetchingNews={isFetchingNews}
        />
      ) : (
        <div>
          <div className="mt-4">
            <QueueTable>
              {map(userQueue, (newsItem, index) => (
                <QueueRow
                  newsItem={newsItem}
                  index={index}
                  openModal={this.open}
                  openSource={actions.openSource}
                  onArchive={this.onArchive}
                  onDiscard={this.onDiscard}
                  onBlock={this.onBlock}
                  queueSourceType={newsItem.source}
                  isResolvingNews={isResolvingNews}
                  key={newsItem.primaryArticle.id}
                />
              ))}
            </QueueTable>
          </div>
          <Dialog onClose={this.close} open={this.state.showModal}>
            <div style={{ width: '600px' }}>
              <div className="border-b border-solid border-gray-300 px-4 py-6 text-2xl text-black">
                Related News
              </div>
              <div className="border-b border-solid border-gray-300 px-4 pt-6 pb-8">
                <RelatedNewsList
                  newsList={userQueue[this.state.modalItemIndex].relatedNews}
                  onChange={(id) => {
                    this.onRelatedNewsListChange(id);
                  }}
                />
              </div>
              <div className="flex justify-end p-4">
                <Button
                  data-test="unrelateNews"
                  onClick={this.markNewsAsUnrelated}
                >
                  Unrelate
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      );

    return (
      <div className="container mx-auto mt-8 mb-4">
        <div className="text-center text-3xl text-black font-medium py-2">
          News Queue
        </div>
        <div className="flex justify-between">
          <div className="inline-block">
            <UserQueueDetails
              user={user}
              remainingCount={remainingCount}
              type={displaySourceType}
            />
          </div>
          <div className="flex justify-between">
            <div className="inline-block w-32">
              <FormControl label="Source Type">
                <SimpleSelect
                  options={SourceTypeOptions}
                  value={{ label: sourceType, value: sourceType }}
                  onChange={this.onChangeSourceType}
                  data-test="sourceTypeSelect"
                />
              </FormControl>
            </div>
            <div className="inline-block ml-8 w-32">
              <FormControl label="Language">
                <MultiSelect
                  options={map(filterGroupNames, (name) => ({
                    label: name,
                    value: name,
                  }))}
                  onChange={this.onChangeFilterGroup}
                  value={selectedFilterGroupName.map((name) => ({
                    label: name,
                    value: name,
                  }))}
                  data-test="filterGroupNamesSelect"
                />
              </FormControl>
            </div>
            <div className="inline-block ml-8 pt-6">
              <ClearQueueButton
                onClear={actions.clearQueue}
                isResolvingNews={isResolvingNews}
              />
            </div>
          </div>
        </div>
        {queue}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    newsQueue: state.newsQueue,
    user: state.user.data,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) };
}

UserQueue.propTypes = {
  user: PropTypes.object.isRequired,
  newsQueue: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserQueue);
