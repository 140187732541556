import PropTypes from 'prop-types';
import React from 'react';
import { map } from 'lodash/fp';

const RelatedNewsListItem = ({ id, key, source, onChange, title }) => (
  <tr>
    <td className="w-1/12">
      {key !== 0 && (
        <input
          type="checkbox"
          value={id}
          onChange={() => onChange(id)}
          data-test={`related-news-${title}`}
        />
      )}
    </td>
    <td className="w-5/12">
      <a href={source} target="_blank" rel="noopener noreferrer">
        {source.substring(0, 30)}
      </a>
    </td>
    <td className="w-1/2"> {title} </td>
  </tr>
);

RelatedNewsListItem.propTypes = {
  id: PropTypes.number.isRequired,
  key: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RelatedNewsList = ({ newsList, onChange }) => {
  const mapNewsItems = map((news, index) => (
    <RelatedNewsListItem
      key={index}
      id={news.id}
      source={news.source}
      title={news.title}
      onChange={onChange}
    />
  ));

  return (
    <table className="w-full">
      <thead data-test="tableHeader">
        <tr className="border-b border-solid border-black">
          <th className="w-1/12" />
          <th className="w-5/12">Source</th>
          <th className="w-1/2">Title</th>
        </tr>
      </thead>
      <tbody>{mapNewsItems(newsList)}</tbody>
    </table>
  );
};

export default RelatedNewsList;

RelatedNewsList.propTypes = {
  newsList: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

RelatedNewsList.defaultProps = {
  newsList: [],
};
