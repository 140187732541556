import React from 'react';
import PropTypes from 'prop-types';
import DenyIcon from '@cbinsights/fds/lib/icons/react/DenyIcon';

/**
 * @deprecated
 * It renders a header for a slide over component
 * @returns A React component
 */
export default function SlideOverHeader({ headerText, handleClose }) {
  return (
    <div className="flex-shrink-0 px-1 py-1 space-x-4 flex justify-end">
      <header className="w-full pt-4 px-3 sm:px-5">
        <div className="flex items-start justify-between space-x-3">
          <h2 className="text-lg leading-7 font-medium text-gray-900">
            {headerText}
          </h2>
          <div className="h-7 flex items-center">
            <button
              type="button"
              data-test="slide-over-close-button"
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-500 ease-in-out duration-150"
            >
              <DenyIcon customSize={20} />
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

SlideOverHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
