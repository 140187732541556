import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import * as tabName from 'client/modules/packages/utils/tab-names';
import * as actions from '../actions/tab-details';
import * as tabDataActions from '../actions/tab-data';

const initialState = {
  [tabName.CREATE_PACKAGE]: {
    sortInfo: {},
    filterInfo: {},
  },
  [tabName.UPDATE_PACKAGE]: {
    sortInfo: { sortField: 'name', sortDirection: 'desc' },
    filterInfo: {},
  },
  [tabName.PACKAGE_FEATURES]: {
    sortInfo: {},
    filterInfo: { value: '', name: 'packageName' },
  },
  [tabName.COMPARE_PACKAGE]: {
    sortInfo: {},
    filterInfo: {},
    showDiff: false,
  },
};

export const getSortOrder = (sortField, sortDirection) => {
  return sortField
    ? { sortField, sortDirection: sortDirection || 'asc' }
    : null;
};

export const updateSortOrder = (sortField, sortDirection) => {
  return { sortField, sortDirection };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALZE_DATA_DETAILS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          sortInfo:
            getSortOrder(action.sortField, action.sortDirection) ||
            initialState[action.currentTab].sortInfo,
        },
      };
    case actions.UPDATE_SORT_ORDER:
      updateUrlParams('sortField', action.sortField);
      updateUrlParams('sortDirection', action.sortDirection);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          sortInfo: updateSortOrder(action.sortField, action.sortDirection),
        },
      };
    case actions.TOGGLE_SHOW_DIFF_CHECKBOX:
      return {
        ...state,
        [tabName.COMPARE_PACKAGE]: {
          ...state[tabName.COMPARE_PACKAGE],
          showDiff: !state[tabName.COMPARE_PACKAGE].showDiff,
        },
      };
    case tabDataActions.FILTERING_RECORDS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          filterInfo: action.filter,
        },
      };
    }
    default:
      return state;
  }
}
