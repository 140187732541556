// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes client-component-library-LoadingIcon-___styles__spinner___pcGzO {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.client-component-library-LoadingIcon-___styles__loading--m___VIKDb {
  height: 62px;
  width: 62px;
  margin: 0 auto;
  position: relative;
}

.client-component-library-LoadingIcon-___styles__loading--m___VIKDb:before {
  content: '';
  position: absolute;
  top: 32px;
  left: 32px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 6px solid #006699;
  border-bottom: 6px solid #006699;
  border-right: 6px solid #006699;
  border: 6px solid #006699;
  border-top: 6px solid transparent;
  animation: client-component-library-LoadingIcon-___styles__spinner___pcGzO 1s linear infinite;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.client-component-library-LoadingIcon-___styles__loading--s___svBVB {
  height: 32px;
  width: 32px;
  margin: 0 auto;
  position: relative;
}

.client-component-library-LoadingIcon-___styles__loading--s___svBVB:before {
  content: '';
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left: 3px solid #006699;
  border-bottom: 3px solid #006699;
  border-right: 3px solid #006699;
  border: 3px solid #006699;
  border-top: 3px solid transparent;
  animation: client-component-library-LoadingIcon-___styles__spinner___pcGzO 1s linear infinite;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
`, "",{"version":3,"sources":["webpack://./client/component-library/LoadingIcon/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,kDAAkD;EACpD;;EAEA;IACE,oDAAoD;EACtD;AACF;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,gCAAgC;EAChC,+BAA+B;EAC/B,yBAAyB;EACzB,iCAAiC;EACjC,6FAAqC;EACrC,qCAAqC;EACrC,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,gCAAgC;EAChC,+BAA+B;EAC/B,yBAAyB;EACzB,iCAAiC;EACjC,6FAAqC;EACrC,qCAAqC;EACrC,sBAAsB;AACxB","sourcesContent":["@keyframes spinner {\n  0% {\n    transform: translate3d(-50%, -50%, 0) rotate(0deg);\n  }\n\n  100% {\n    transform: translate3d(-50%, -50%, 0) rotate(360deg);\n  }\n}\n\n.loading--m {\n  height: 62px;\n  width: 62px;\n  margin: 0 auto;\n  position: relative;\n}\n\n.loading--m:before {\n  content: '';\n  position: absolute;\n  top: 32px;\n  left: 32px;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  border-left: 6px solid #006699;\n  border-bottom: 6px solid #006699;\n  border-right: 6px solid #006699;\n  border: 6px solid #006699;\n  border-top: 6px solid transparent;\n  animation: spinner 1s linear infinite;\n  transform: translate3d(-50%, -50%, 0);\n  will-change: transform;\n}\n\n.loading--s {\n  height: 32px;\n  width: 32px;\n  margin: 0 auto;\n  position: relative;\n}\n\n.loading--s:before {\n  content: '';\n  position: absolute;\n  top: 16px;\n  left: 16px;\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  border-left: 3px solid #006699;\n  border-bottom: 3px solid #006699;\n  border-right: 3px solid #006699;\n  border: 3px solid #006699;\n  border-top: 3px solid transparent;\n  animation: spinner 1s linear infinite;\n  transform: translate3d(-50%, -50%, 0);\n  will-change: transform;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading--m": `client-component-library-LoadingIcon-___styles__loading--m___VIKDb`,
	"spinner": `client-component-library-LoadingIcon-___styles__spinner___pcGzO`,
	"loading--s": `client-component-library-LoadingIcon-___styles__loading--s___svBVB`
};
export default ___CSS_LOADER_EXPORT___;
