import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import { ALL_LANGUAGE_OPTION } from '../utils/constants';

export function getFilterOptions() {
  return request({
    url: 'service/newsqueueservice/ListNewsQueueFilterOptions',
    body: {},
  })
    .then(({ body: { languages } }) => {
      return {
        languageOptions: [ALL_LANGUAGE_OPTION].concat(
          languages.map(({ idLanguage, language }) => ({
            value: idLanguage,
            label: language,
          }))
        ),
      };
    })
    .catch((error) => {
      logInfo({
        error,
        message: 'News Queue: Failed To Fetch Filter Options',
        method: 'getFilterOptions',
      });
      throw error;
    });
}
