import {
  GetFilterExpressionGroupInfoRequest,
  GetFilterExpressionGroupInfoResponse,
  ListFilterExpressionGroupsResponse,
} from '@cbinsights/newsqueueservice/newsqueueservice';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import {
  createFilterExpressionGroup,
  deactivateFilterExpressionGroup,
  deleteFilterExpressionGroup,
  getFilterExpressionGroupInfo,
  listFilterExpressionGroups,
  updateFilterExpressionGroup,
} from '../api/FilterExpressionGroup';

export const FilterExpressionGroupEndpoints = {
  GetFilterExpressionGroupInfo: 'GetFilterExpressionGroupInfo',
  CreateFilterExpressionGroup: 'CreateFilterExpressionGroup',
  DeleteFilterExpressionGroup: 'DeleteFilterExpressionGroup',
  ListFilterExpressionGroups: 'ListFilterExpressionGroups',
  DeactivateFilterExpressionGroup: 'DeactivateFilterExpressionGroup',
} as const;

export type FilterExpressionGroupKeys =
  | typeof FilterExpressionGroupEndpoints.GetFilterExpressionGroupInfo
  | typeof FilterExpressionGroupEndpoints.CreateFilterExpressionGroup
  | typeof FilterExpressionGroupEndpoints.DeleteFilterExpressionGroup
  | typeof FilterExpressionGroupEndpoints.ListFilterExpressionGroups
  | typeof FilterExpressionGroupEndpoints.DeactivateFilterExpressionGroup;

export const useGetFilterExpressionGroupInfo = ({
  params,
}: Partial<{
  enabled: boolean;
  params: Partial<GetFilterExpressionGroupInfoRequest>;
}>): UseQueryResult<GetFilterExpressionGroupInfoResponse, Error> =>
  useQuery<
    GetFilterExpressionGroupInfoResponse,
    Error,
    GetFilterExpressionGroupInfoResponse,
    [FilterExpressionGroupKeys, Partial<GetFilterExpressionGroupInfoRequest>]
  >(
    [FilterExpressionGroupEndpoints.GetFilterExpressionGroupInfo, params],
    () => getFilterExpressionGroupInfo(params),
    {
      enabled: true,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

export const useListFilterExpressionGroups = (
  enabled = true
): UseQueryResult<ListFilterExpressionGroupsResponse, Error> =>
  useQuery<
    ListFilterExpressionGroupsResponse,
    Error,
    ListFilterExpressionGroupsResponse,
    [FilterExpressionGroupKeys]
  >(
    [FilterExpressionGroupEndpoints.ListFilterExpressionGroups],
    () => listFilterExpressionGroups(),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

export const useCreateFilterExpressionGroupMutation = () =>
  useMutation(createFilterExpressionGroup);
export const useDeleteFilterExpressionGroupMutation = () =>
  useMutation(deleteFilterExpressionGroup);
export const useDeactivateFilterExpressionGroup = () =>
  useMutation(deactivateFilterExpressionGroup);
export const useUpdateFilterExpressionGroup = () =>
  useMutation(updateFilterExpressionGroup);
