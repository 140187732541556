import React from 'react';
import { Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PendingInvitation } from '../../hooks/useTeamInvitations';
import useShowPagination from '../../hooks/useShowPagination';

type Props = {
  columns: ColumnsType<PendingInvitation>;
  data: PendingInvitation[];
};

const PendingInvitations = ({ columns, data }: Props) => {
  const showPagination = useShowPagination({ data, config: { pageSize: 25 } });

  return (
    <Row className="mb-10">
      <Col span="24">
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={showPagination}
        />
        <br />
      </Col>
    </Row>
  );
};

export default PendingInvitations;
