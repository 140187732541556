import { UserTeamAttrUpdate } from '@cbinsights/adminservice/adminservice';
import { useMutation } from 'react-query';
import { updateUserTeam } from '../services/api/UpdateUserTeam';

export const useUpdateUserTeam = () => {
  const updateTeamMutation = useMutation(updateUserTeam);

  const onSubmit = (userId: number, data: Partial<UserTeamAttrUpdate>) => {
    const updateMask = {
      paths: Object.keys(data) as (keyof UserTeamAttrUpdate)[],
    };

    return updateTeamMutation.mutateAsync({
      user_team_updates: [
        { ...data, id_user: userId, update_mask: updateMask },
      ],
    });
  };

  return {
    onSubmit,
  };
};
