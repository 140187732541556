/* eslint-disable react/static-property-placement */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';
import FormError from './FormError';

/** @deprecated */
export default class FormControl extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
    hiddenError: PropTypes.bool,
    width: PropTypes.string,
    hideFormGroup: PropTypes.bool,
    renderLabel: PropTypes.func,
  };

  static defaultProps = {
    id: undefined,
    label: undefined,
    touched: false,
    error: null,
    hiddenError: false,
    width: undefined,
    hideFormGroup: false,
    renderLabel: undefined,
  };

  render() {
    const {
      children,
      id,
      label,
      renderLabel,
      touched,
      error,
      hiddenError,
      width,
      hideFormGroup,
    } = this.props;
    const className = cx({
      'form-group': !hideFormGroup,
      'has-error': touched && error,
    });
    const style = {
      marginBottom: hiddenError || (touched && error) ? 0 : 25,
      width,
    };
    const Label = (renderLabel || label) && <label htmlFor={id}>{label}</label>;

    const formError = error || '';

    return (
      <div className={className} style={style}>
        {Label}
        {children}
        <FormError touched={touched} error={formError} />
      </div>
    );
  }
}
