import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Modal,
  Select,
  Tooltip,
  message,
} from 'antd';
import moment from 'moment';
import useChangePackage from '../hooks/useChangePackage';
import { TrialInfo } from '../types/trial';

type Props = {
  packageName: string;
  packageID: number;
  packageStart?: string;
  packageEnd?: string;
  refetchUser?: () => void;
  trialInfo?: TrialInfo;
};

type ChangePackageForm = {
  packageStart: moment.Moment;
  packageEnd: moment.Moment;
  packageID: number;
};

export const EditPackageSettings = ({
  packageName,
  packageID,
  packageStart,
  packageEnd,
  refetchUser,
  trialInfo,
}: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    onSubmit,
    filteredResults,
    setSearchPackageQuery,
    hasChangePackagePermission,
  } = useChangePackage(packageID, packageName);
  const [form] = Form.useForm<ChangePackageForm>();

  const resetForm = () => {
    const packageIDtoSet = packageID === 0 ? undefined : packageID;

    const startDate = moment(packageStart);
    const endDate = moment(packageEnd);

    form.setFieldsValue({
      packageID: packageIDtoSet,
      packageStart: startDate.isValid() ? startDate : '',
      packageEnd: endDate.isValid() ? endDate : '',
    });
  };

  useEffect(() => {
    resetForm();
  }, [packageID, packageStart, packageEnd]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          await onSubmit(
            values.packageID,
            values.packageStart,
            values.packageEnd
          );
          message.success("Success! The user's package has been updated.");
          refetchUser();
        } catch (error) {
          message.error(
            "Error! Failed to update the user's package. Please try again later."
          );
        } finally {
          setModalOpen(false);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Tooltip
        title={
          trialInfo.isActive
            ? "The 'Edit Package Settings' function is disabled because the user is on a trial package. Once the trial period ends, the user will return to the original package and the edit functionality will be restored"
            : ''
        }
      >
        <Button
          disabled={trialInfo.isActive}
          onClick={() => setModalOpen(true)}
        >
          Edit Package Settings
        </Button>
      </Tooltip>
      <Modal
        title="Change Package Details"
        centered
        open={modalOpen}
        onOk={handleSubmit}
        onCancel={() => {
          resetForm();
          setModalOpen(false);
        }}
        okText="Save"
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={() => {
            setModalOpen(false);
          }}
          style={{ maxWidth: 600 }}
        >
          <Tooltip
            title={
              hasChangePackagePermission
                ? null
                : 'You do not have permission to change the package settings'
            }
          >
            <Form.Item
              name="packageID"
              label="Package"
              rules={[{ required: true }]}
              help={false}
            >
              <Select
                showSearch
                placeholder="Select a Package"
                options={filteredResults}
                notFoundContent={<Empty />}
                onSearch={setSearchPackageQuery}
                filterOption={false}
                disabled={!hasChangePackagePermission}
              />
            </Form.Item>
          </Tooltip>
          <Form.Item
            name="packageStart"
            label="Start Date"
            rules={[{ required: true }]}
            help={false}
          >
            <DatePicker
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item
            name="packageEnd"
            label="End Date"
            rules={[
              { required: true, message: '' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('packageStart').isBefore(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('End date must be greater than start date')
                  );
                },
              }),
            ]}
          >
            <DatePicker
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditPackageSettings;
