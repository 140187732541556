import { GET_ALL_FUNDING_ROUND } from '../actions/funding-round';
import { mapFundingRound } from '../utils/records-helper';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FUNDING_ROUND: {
      return mapFundingRound(action.roundInfo);
    }
    default:
      return state;
  }
}
