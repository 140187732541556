import { map, reduce } from 'lodash';
import createFullName from 'client/modules/common/utils/createFullName';
import {
  ADMIN_PAGES,
  ADMIN_USERS,
} from 'client/modules/admin-user-admin/utils/tab-names';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';

export const newEmptyRecord = {
  name: '',
  email: '',
  role: '',
  page: '',
  idPage: 0,
  idUser: 0,
  isNew: true,
  // idRole is string because the selector return the value as string anyway.
  // We will convert it to int when we make a request
  idRole: '0',
};

export function getCurrentTab(callback) {
  return (dispatch, getState) => {
    const { currentTab } = getState().adminUsers;
    return dispatch(callback(currentTab));
  };
}

export const mapUserHelper = (users, fetchInactiveUsers) => {
  return reduce(
    users,
    (results, user) => {
      if (
        typeof user.isActive === 'undefined' ||
        user.isActive ||
        fetchInactiveUsers
      ) {
        results.push({
          id: user.idUser,
          name: createFullName(user.firstName, user.lastName, user.email),
          email: user.email,
          isActive: user.isActive,
          isTpaUser: user.isTpaUser,
        });
      }
      return results;
    },
    []
  );
};

export const mapAdminUsersResponse = (adminUsers, fetchInactiveUsers) => {
  return mapUserHelper(adminUsers, fetchInactiveUsers);
};

export const mapEmployeeResponse = (employeeInfo) => {
  return mapUserHelper(employeeInfo);
};

export const mapAdminPageResponse = (pagePermissions, allAdminPages) => {
  return pagePermissions
    .filter((page) => allAdminPages[page.idPage])
    .map((page) => {
      return {
        id: page.idPage,
        role: page.role,
        name: allAdminPages[page.idPage].name,
        url: allAdminPages[page.idPage].url,
        access: page.access,
        idRole: page.idRole,
      };
    });
};

export const mapAdminUsersRoleResponse = (userRoleInfo, allAdminUsers) => {
  return map(userRoleInfo, (userRole) => {
    return {
      id: userRole.idUser,
      name: allAdminUsers[userRole.idUser]
        ? allAdminUsers[userRole.idUser].name
        : '',
      email: allAdminUsers[userRole.idUser]
        ? allAdminUsers[userRole.idUser].email
        : '',
      role: userRole.role,
      idRole: userRole.idRole,
    };
  });
};

export const mapAdminPagesResponse = (adminPages) => {
  return map(adminPages, (page) => {
    return {
      id: page.idPage,
      name: page.pageName,
      url: page.url,
      section: page.pageSection,
      isTpaPage: page.isTpaPage,
    };
  });
};

export const updateSearchInfo = (
  searchInfo,
  searchId,
  searchTerm,
  isTpaUser
) => {
  return {
    ...searchInfo,
    searchTerm,
    searchId: parseInt(searchId, 10) || 0,
    isTpaUser,
  };
};

export const updateSearchSuggestions = (searchInfo, suggestions, searchId) => {
  return { ...searchInfo, suggestions, searchId: parseInt(searchId, 10) || 0 };
};

export const updateSelectedSearchTerm = (searchInfo) => {
  return { ...searchInfo, selectedSearchTerm: searchInfo.searchTerm };
};

export const addEmptyRecordWithPageHelper = (
  newRecords,
  newKey,
  searchInfo
) => {
  return {
    ...newRecords,
    [newKey]: {
      ...newEmptyRecord,
      idPage: searchInfo.searchId,
      page: searchInfo.searchTerm,
    },
  };
};

export const addEmptyRecordWithUserHelper = (
  newRecords,
  newKey,
  searchInfo
) => {
  return {
    ...newRecords,
    [newKey]: {
      ...newEmptyRecord,
      idUser: searchInfo.searchId,
      name: searchInfo.searchTerm,
      email: getKeyValueFromListViaOtherKey(
        searchInfo.suggestions,
        'id',
        searchInfo.searchId,
        'email'
      ),
    },
  };
};

export const addEmptyRecord = (newRecords, currentTab, searchInfo) => {
  if (currentTab === ADMIN_PAGES) {
    return addEmptyRecordWithPageHelper(newRecords, Date.now(), searchInfo);
  }
  if (currentTab === ADMIN_USERS) {
    return addEmptyRecordWithUserHelper(newRecords, Date.now(), searchInfo);
  }
  return null;
};

export const getInputValueFromTable = (
  autoCompleteInputValues,
  id,
  columnName
) => {
  const fullKeyName = `${id}-${columnName}`;
  return autoCompleteInputValues[fullKeyName];
};

export const getPageId = (record, id, autoCompleteInputValues) => {
  const pageInfo = getInputValueFromTable(autoCompleteInputValues, id, 'page');
  return pageInfo ? parseInt(pageInfo.termId || 0, 10) : -1;
};

export const getUserId = (record, id, autoCompleteInputValues) => {
  const userInfo = getInputValueFromTable(autoCompleteInputValues, id, 'name');
  return userInfo ? parseInt(userInfo.termId || 0, 10) : -1;
};

export const updateNewRecordWithInfo = (newRecords, recordId, info) => {
  return { ...newRecords, [recordId]: { ...newRecords[recordId], ...info } };
};

export const filteringRecords = (records, filter) => {
  return filterListOfObjects({
    value: filter.value,
    listOfItems: records,
    fields: [filter.name],
  });
};

export default getCurrentTab;
