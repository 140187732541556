/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SlideOver from 'client/modules/common/components/SlideOver';
import EntitySection from 'client/modules/news/queue/components/EntitySection';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import Loading from 'client/component-library/LoadingIcon';
import { SimpleSelect } from 'client/component-library/Select/SimpleSelect';
import { EntityOption } from 'client/component-library/Select/options/EntityOption';
import { getEntityFundingRounds } from 'client/modules/service-provider/apis/getAssociatedRounds';
import { onCreateNewEntity } from 'client/modules/person-admin/components/forms/fields/InvestorSearchField';
import { setToastError } from 'client/modules/third-party-admin/profile-queue/utils';
import { useUserEvents } from 'client/utils/user-event/hooks/useUserEvent';
import { useFormUtilsContext } from 'client/modules/common/form/containers/hooks/useFormUtilsContext';
import { getEntityByName } from 'client/modules/common/apis/getEntityByName';
import {
  addEntityToCluster,
  removeEntityToCluster,
} from '../apis/updateCluster';

export function EntitiesSlideOverWrapper({
  handleClose,
  entities = [],
  clusterId,
  clusterType,
  onArchive,
}) {
  const [slideoverData, setSlideoverData] = useState({
    isLoading: !!entities.length,
    rounds: {},
  });

  const {
    customContext: {
      updateOrAddEntityToClusterState,
      handleRemoveEntityFromClusterState,
    },
  } = useFormUtilsContext();
  const { recordUserEvent } = useUserEvents();

  const onArchiveHandler = () => {
    onArchive({ id: clusterId, type: clusterType });
    handleClose();
  };
  // Helper Function Get Entity Rounds
  const getEntitiesRounds = (ids, draftIds) => {
    if (!ids.length && !draftIds.length) {
      return Promise.resolve({});
    }

    return getEntityFundingRounds(ids, true, draftIds).then((rounds) => {
      setSlideoverData((state) => ({
        ...state,
        isLoading: false,
        rounds: { ...state.rounds, ...rounds },
      }));
    });
  };

  const onAddEntityToCluster = (entityInfo) => {
    if (
      entities.some(
        ({ id, idEntityDraft }) =>
          (!!idEntityDraft && idEntityDraft === entityInfo.idEntityDraft) ||
          (!idEntityDraft && id === entityInfo.id)
      )
    ) {
      setToastError(`${entityInfo.name} already in cluster`);
    } else {
      recordUserEvent({
        type: 'admin.nq.cluster.entityAdded',
        details: {
          idCluster: clusterId,
          idCbiEntity: entityInfo.idCbiEntity,
          idEntityDraft: entityInfo.idEntityDraft,
        },
      });

      updateOrAddEntityToClusterState({ id: clusterId }, entityInfo);
      addEntityToCluster({ id: clusterId }, entityInfo)
        .then((updatedEntity) => {
          return updateOrAddEntityToClusterState(
            { id: clusterId },
            updatedEntity
          );
        })
        .catch(() => {
          handleRemoveEntityFromClusterState({ id: clusterId }, entityInfo);
        });
    }
  };

  const onRemoveEntity = (entityInfo) => {
    recordUserEvent({
      type: 'admin.nq.cluster.entityRemoved',
      details: {
        idCluster: clusterId,
        idCbiEntity: entityInfo.idCbiEntity,
      },
    });

    handleRemoveEntityFromClusterState({ id: clusterId }, entityInfo);
    removeEntityToCluster({ id: clusterId }, entityInfo).catch(() => {
      updateOrAddEntityToClusterState({ id: clusterId }, entityInfo);
    });
  };

  useEffect(() => {
    const ids = entities
      .filter((entity) => !!entity.id)
      .map((entity) => entity.id);
    const draftIds = entities
      .filter((entity) => !!entity.idEntityDraft)
      .map((entity) => entity.idEntityDraft);
    getEntitiesRounds(ids, draftIds);
  }, [entities]);

  const totalEntities = entities.length;

  const isValidNewOption = () => {
    return true;
  };

  return (
    <SlideOver
      closeOnClickAway
      headerText={
        totalEntities > 0
          ? `${totalEntities} ${totalEntities > 1 ? 'Companies' : 'Company'}`
          : 'No Entities In Cluster'
      }
      handleClose={handleClose}
      dataTest="advanced-view-people-from"
    >
      {slideoverData.isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="min-h-0 flex-1 flex flex-col py-6 space-y-6 overflow-y-scroll bg-gray-200">
            <div className="relative flex-1 px-4 sm:px-6">
              <div className="h-full">
                <div className="w-full pb-2 border-b-2 mb-2">
                  <SimpleSelect
                    autoFocus
                    isClearable
                    isCreatable
                    isValidNewOption={isValidNewOption}
                    onCreateOption={onCreateNewEntity}
                    label="Add Entity"
                    name="entity-search"
                    placeholder="Entity Search"
                    loadOptions={(data) =>
                      getEntityByName(data, 3, false, true)
                    }
                    renderComp={{ Option: EntityOption }}
                    onChange={onAddEntityToCluster}
                  />
                </div>
                <div className="border-t flex flex-row bg-gray-200 mb-4">
                  <div className="flex py-2 w-full">
                    <div className="w-full mr-3">
                      <Button
                        onClick={handleClose}
                        data-test="submit-cluster-entity-card"
                      >
                        Done
                      </Button>
                    </div>
                    <div className="w-full ml-3">
                      <Button
                        onClick={onArchiveHandler}
                        theme="success"
                        disabled={!entities.length}
                      >
                        Archive
                      </Button>
                    </div>
                  </div>
                </div>
                <EntitySection
                  entities={entities}
                  rounds={slideoverData.rounds}
                  handleRemoveEntity={onRemoveEntity}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </SlideOver>
  );
}

EntitiesSlideOverWrapper.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClose: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
};
