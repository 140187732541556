import React from 'react';

import {
  Clusters,
  FilterGroups,
  Queue,
  QueueManagement,
  UserStatus,
} from 'client/modules/news/NewsWrapper';
import { Navigate, RouteObject } from 'react-router-dom';
import { NewsSources } from './sources/containers/NewsSources';
import {
  AddNewsSources,
  EditNewsSources,
} from './sources/containers/NewsSourcesForm';

export const newsQueueRoutes: RouteObject = {
  children: [
    {
      path: 'news-queue',
      element: <Clusters />,
    },
    {
      path: 'news',
      children: [
        {
          path: '/news',
          element: <Navigate to="/news/queue" />,
        },
        {
          path: 'queue',
          element: <Queue />,
        },
        {
          path: 'queue/management',
          element: <QueueManagement />,
        },
        {
          path: 'user-stats',
          element: <UserStatus />,
        },
        {
          path: 'sources',
          element: <NewsSources />,
        },
        {
          path: 'sources/add',
          element: <AddNewsSources />,
        },
        {
          path: 'sources/:sourceId',
          element: <EditNewsSources />,
        },
        {
          path: 'groups',
          element: <Navigate replace to="1" />,
        },
        {
          path: 'groups/add',
          element: <FilterGroups />,
        },
        {
          path: 'groups/:groupId',
          element: <FilterGroups />,
        },
      ],
    },
  ],
};
