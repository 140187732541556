export const OPEN_MODAL = 'client/modules/common/modal/OPEN_MODAL';
export const CLOSE_MODAL = 'client/modules/common/modal/CLOSE_MODAL';

export const openModal = (title, content) => {
  return { type: OPEN_MODAL, title, content };
};

export const closeModal = () => {
  return { type: CLOSE_MODAL };
};
