export const PermissionsRoutes = {
  Permissions: 'permissions',
  Roles: 'roles',
  Role: 'role',
  Team: 'team',
  Teams: 'teams',
  Users: 'users',
  User: 'user',
  UserId: 'userId',
  RoleId: 'roleId',
  TeamId: 'teamId',
  New: 'new',
  PermissionsKindId: 'permissionsId',
} as const;

export const PermissionKind = {
  Roles: 'roles',
  Users: 'users',
  Teams: 'teams',
} as const;

export type PermissionKindType =
  | typeof PermissionKind.Roles
  | typeof PermissionKind.Users
  | typeof PermissionKind.Teams;

export const urlOnCancelUserRolesForm = `/${PermissionsRoutes.Permissions}/${PermissionsRoutes.Users}`;
export const urlOnCancelTeamsForm = `/${PermissionsRoutes.Permissions}/${PermissionsRoutes.Teams}`;
