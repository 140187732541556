import {
  GetEntityInfoRequest,
  GetEntityInfoResponse,
} from '@cbinsights/cbientityservice/cbientityservice';
import { UseQueryResult, useQuery } from 'react-query';
import { getEntityInfo } from '../apis/GetEntityInfo';
import { EntityServicesKeys } from './UseSearchForEntity';

export const useGetEntityInfo = (
  enabled: boolean,
  request: Partial<GetEntityInfoRequest>
): UseQueryResult<Partial<GetEntityInfoResponse>, Error> =>
  useQuery<
    GetEntityInfoResponse,
    Error,
    GetEntityInfoResponse,
    [EntityServicesKeys, Partial<GetEntityInfoRequest>]
  >(['GetEntityInfo', request], () => getEntityInfo(request), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
  });
