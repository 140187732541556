import { getAllPackagesRequest } from '../utils/api-request-helper';

export const FETCH_ALL_PACKAEGS =
  'client/modules/admin-user-admin/actions/FETCH_ALL_PACKAEGS';

export const getAllPackagesHelper = (packages) => {
  return {
    type: FETCH_ALL_PACKAEGS,
    packages,
  };
};

export const getAllPackages = () => {
  return (dispatch, getState) => {
    if (getState().packages.packagesInfo.length) {
      return null;
    }
    return dispatch(getAllPackagesRequest()).then((data) => {
      dispatch(getAllPackagesHelper(data.packages));
    });
  };
};
