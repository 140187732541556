import { useQuery } from 'react-query';
import { initialAppValidation, UserAuth } from '../api/initialAppValidation';

const userAuthId: Readonly<'checkIfUserIsAuthenticated'> =
  'checkIfUserIsAuthenticated';
export const useCheckUserAuthQuery = (enabled = false) => {
  return useQuery<UserAuth, Error, UserAuth, [typeof userAuthId]>(
    [userAuthId],
    () => initialAppValidation(userAuthId),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetched'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
};
