import { combineReducers } from 'redux';
import userInfo from './admin-users';
import pageInfo from './admin-pages';
import companyEmployees from './company-employees';
import currentTab from './current-tab';
import tabData from './tab-data';

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  isActive: string;
  isTpaUser: string;
}

export interface AdminUsers {
  userInfo: {
    [index: string]: UserInfo;
  };
  pageInfo: $TSFixMe;
  companyEmployees: $TSFixMe;
  currentTab: $TSFixMe;
  tabData: $TSFixMe;
}

const adminUsers = combineReducers({
  userInfo,
  pageInfo,
  companyEmployees,
  currentTab,
  tabData,
});

export default adminUsers;
