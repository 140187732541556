import React, { ComponentPropsWithoutRef } from 'react';
import styles from './styles.css';

export type IconProps = {
  iconName: string;
} & ComponentPropsWithoutRef<'span'>;
export const Icon = ({ iconName, className = '', ...props }: IconProps) => {
  return (
    <span className={`${styles.icon} ${className}`} {...props}>
      {iconName}
    </span>
  );
};
