import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const ListOfMarkets = React.lazy(() => import('./containers/ListOfMarkets'));
const MarketSizingDetails = React.lazy(
  () => import('./containers/MarketSizingDetails')
);
const RelatedMarkets = React.lazy(() => import('./containers/RelatedMarkets'));

export const marketRoutes: RouteObject = {
  path: 'markets',
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    {
      path: '/markets/list-of-markets',
      element: (
        <React.Suspense fallback={undefined}>
          <ListOfMarkets />
        </React.Suspense>
      ),
    },
    {
      path: '/markets/market-sizing-details',
      element: (
        <React.Suspense fallback={undefined}>
          <MarketSizingDetails />
        </React.Suspense>
      ),
    },
    {
      path: '/markets/related-markets',
      element: (
        <React.Suspense fallback={undefined}>
          <RelatedMarkets />
        </React.Suspense>
      ),
    },
    {
      path: '/markets',
      element: <Navigate to="/markets/market-sizing-details" />,
    },
  ],
};
