import { ListMarketsForAdminResponse } from '@cbinsights/espmatrixservice/espmatrixservice';
import { UseQueryResult, useQuery } from 'react-query';
import { listMarketsForAdmin } from '../api/MarketsEndpoints';
import { MarketForAdminServiceKeys } from './useGetMarketDetails';

const fetchMarketsForAdmin = async (
  inReviewOnly = false
): Promise<ListMarketsForAdminResponse['markets']> => {
  const results = await listMarketsForAdmin({
    markets_in_review_only: inReviewOnly,
  });
  return results.markets;
};

export const useGetMarketsForAdmin = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<ListMarketsForAdminResponse, Error> =>
  useQuery<
    ListMarketsForAdminResponse,
    Error,
    ListMarketsForAdminResponse,
    [MarketForAdminServiceKeys]
  >({
    queryKey: ['GetMarketsForAdmin'],
    queryFn: async () => {
      const markets = await fetchMarketsForAdmin();
      return { markets };
    },
    notifyOnChangeProps: [
      'data',
      'isFetching',
      'isFetched',
      'isFetchedAfterMount',
    ],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled,
  });
