import { useMutation } from 'react-query';
import { setTeamAdditionalQuota } from '../services/api/SetTeamAdditionalQuota';

type Props = {
  additionalQuota: number;
  idFeature: number;
  idTeam: number;
};

const useSetTeamAdditionalQuota = () => {
  const setTeamFeatureUsageQuery = useMutation(setTeamAdditionalQuota);

  const onSubmit = ({ additionalQuota, idFeature, idTeam }: Props) => {
    return setTeamFeatureUsageQuery.mutateAsync({
      additional_quota: additionalQuota,
      id_feature: idFeature,
      id_team: idTeam,
    });
  };

  return {
    onSubmit,
    isLoading: setTeamFeatureUsageQuery.isLoading,
  };
};

export default useSetTeamAdditionalQuota;
