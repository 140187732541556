export const SET_IS_SAVING_STATUS =
  'client/modules/common/status-notification/SET_IS_SAVING_STATUS';
export const SET_IS_LOADING_STATUS =
  'client/modules/common/status-notification/SET_IS_LOADING_STATUS';

export const setIsSavingStatus = (isSaving) => {
  return { type: SET_IS_SAVING_STATUS, isSaving };
};

export const setIsLoadingStatus = (isLoading) => {
  return { type: SET_IS_LOADING_STATUS, isLoading };
};
