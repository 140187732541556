import React, { useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { NEW_FEATURE_IDS } from 'client/modules/team/constants/features';
import { useUpdateFeatureUsage } from '../hooks/useUpdateFeatureUsage';

type FeatureProps = {
  featureName: string;
  idFeature: number;
  userLimit: number;
  usage: number;
  userId: number;
  teamId?: number;
  refetchFeatureUsage: () => void;
  isVendorConnect?: boolean;
};

const UpdateFeatureUsage = ({
  featureName,
  idFeature,
  userLimit,
  usage,
  userId,
  teamId,
  refetchFeatureUsage,
  isVendorConnect,
}: FeatureProps) => {
  const { updateFeatureUsage, updateUserQuota } = useUpdateFeatureUsage();
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm<FeatureProps>();

  const closeModal = () => {
    form.resetFields();
    setModalOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (form.isFieldTouched('userLimit') && teamId) {
          try {
            await updateUserQuota(
              userId,
              teamId,
              idFeature,
              form.getFieldValue('userLimit')
            );
          } catch (error) {
            message.error('Unable to update user limit. Please try again.');
          }
        }
        if (form.isFieldTouched('usage')) {
          try {
            await updateFeatureUsage(
              userId,
              idFeature,
              form.getFieldValue('usage')
            );
          } catch (error) {
            message.error(
              `Unable to update ${
                isVendorConnect ? 'credits' : 'usage'
              }. Please try again.`
            );
          }
        }
        message.success(
          `Feature ${
            isVendorConnect ? 'credits' : 'usage'
          } updated successfully`
        );
        refetchFeatureUsage();
        closeModal();
      })
      .catch(() => {});
  };

  const openModal = () => {
    setModalOpen(true);
    form.setFieldsValue({
      userLimit,
      usage,
    });
  };

  const isNewFeature = Object.values(NEW_FEATURE_IDS).includes(idFeature);

  const validateInput = (_, value) =>
    value >= 0
      ? Promise.resolve()
      : Promise.reject(new Error('Input should be a positive number'));

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={openModal} />
      <Modal
        title={
          <span className="capitalize">
            {`Update ${featureName} ${isVendorConnect ? 'credits' : 'usage'}`}
          </span>
        }
        centered
        open={modalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        okText="Save"
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={() => {
            setModalOpen(false);
          }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="usage"
            label={isVendorConnect ? 'Credits' : 'Usage'}
            rules={[{ required: true }, { validator: validateInput }]}
            help={false}
            initialValue={usage}
            hidden={isNewFeature}
          >
            <Input type="number" />
          </Form.Item>
          {!isVendorConnect && (
            <Form.Item
              name="userLimit"
              label="User Limit"
              rules={[{ required: true }, { validator: validateInput }]}
              help={false}
              initialValue={userLimit}
              hidden={!teamId}
            >
              <Input type="number" />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default UpdateFeatureUsage;
