import { keyBy } from 'lodash';
import { FETCH_ALL_PACKAEGS } from '../actions/all-packages';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_PACKAEGS: {
      return keyBy(action.packages, 'id');
    }
    default:
      return state;
  }
}
