import { forEach } from 'lodash';
import { logInfo } from 'client/modules/common/utils/logInfo';
import { request } from 'client/modules/common/utils/request';
import {
  setFailureNotification,
  setSuccessNotification,
} from 'client/modules/common/redux/actions/status-notification';
import * as tabName from 'client/modules/cbi-entity/utils/tab-names';
import {
  initializeHqAddress,
  addNewBoardOfDirectorsPerson,
} from 'client/modules/cbi-entity/redux/actions/tab-data';
import {
  fetchCompanyLogoInfo,
  fetchInvestorLogoInfo,
  fetchAllStatusValues,
  fetchAllSectorValues,
  fetchAllInvestorCategoryValues,
  fetchAllAliasTypeValues,
  setCompanyLogo,
  setInvestorLogo,
  unsetEntityLogo,
  createEntityPerson,
  fetchCountrySuggestions,
  fetchStateSuggestions,
  fetchAllPersonTitles,
} from '../utils/api-request-helper';

/* eslint-disable max-len */
export const MARK_AS_NEW_ENTITY =
  'client/modules/cbi-entity/redux/actions/general-info/MARK_AS_NEW_ENTITY';
export const SET_ENTITY_LOGO =
  'client/modules/cbi-entity/redux/actions/general-info/SET_ENTITY_LOGO';
export const SET_TAXONOMY_TREE =
  'client/modules/cbi-entity/redux/actions/general-info/SET_TAXONOMY_TREE';
export const SET_STATUS_VALUES =
  'client/modules/cbi-entity/redux/actions/general-info/SET_STATUS_VALUES';
export const SET_INVESTOR_CATEGORY_VALUES =
  'client/modules/cbi-entity/redux/actions/general-info/SET_INVESTOR_CATEGORY_VALUES';
export const SET_ALIAS_TYPE_VALUES =
  'client/modules/cbi-entity/redux/actions/general-info/SET_ALIAS_TYPE_VALUES';
export const SET_GENERAL_ENTITY_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/SET_GENERAL_ENTITY_INFO';
export const HYDRATE_GENERAL_ENTITY_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/HYDRATE_GENERAL_ENTITY_INFO';
export const SET_COUNTRY_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/SET_COUNTRY_INFO';
export const SET_STATE_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/SET_STATE_INFO';
export const UPDATE_ENTITY_PRIMARY_NAME =
  'client/modules/cbi-entity/redux/actions/general-info/UPDATE_ENTITY_PRIMARY_NAME';
export const UPDATE_LOGO_URL =
  'client/modules/cbi-entity/redux/actions/general-info/UPDATE_LOGO_URL';

export const SET_ADDRESS_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/SET_ADDRESS_INFO';
export const HYDRATE_ADDRESS_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/HYDRATE_ADDRESS_INFO';
export const SET_VALIDATION_ERRORS =
  'client/modules/cbi-entity/redux/actions/general-info/SET_VALIDATION_ERRORS';

export const RESET_ENTITY_INFO =
  'client/modules/cbi-entity/redux/actions/general-info/RESET_ENTITY_INFO';
export const RESET_ENTITY_EDIT_STATUS =
  'client/modules/cbi-entity/redux/actions/general-info/RESET_ENTITY_EDIT_STATUS';
export const RESET_ENTITY_LOGO =
  'client/modules/cbi-entity/redux/actions/general-info/RESET_ENTITY_LOGO';
/* eslint-enable max-len */

export const SET_IS_LOADING_STATUS =
  'client/modules/common/status-notification/SET_IS_LOADING_STATUS';

export const SET_TITLE_VALUES =
  'client/modules/cbi-entity/redux/actions/general-info/SET_TITLE_VALUES';

export const SET_LIST_ENTITY_DRAFTS =
  'client/modules/cbi-entity/redux/actions/general-info/SET_LIST_ENTITY_DRAFTS';

export const setIsLoadingStatus = (isLoading) => {
  return { type: SET_IS_LOADING_STATUS, isLoading };
};

export const markAsNewEntity = (entityName = '') => {
  return { type: MARK_AS_NEW_ENTITY, entityName };
};

export const setEntityLogoData = (logo) => {
  return { type: SET_ENTITY_LOGO, logo };
};

export const setAddressInfo = (addressInfo) => {
  return {
    type: SET_ADDRESS_INFO,
    addressInfo,
    currentTab: tabName.GENERAL_INFO,
  };
};

export const hydrateAddressInfo = (addressInfo) => {
  return {
    type: HYDRATE_ADDRESS_INFO,
    addressInfo,
    currentTab: tabName.GENERAL_INFO,
  };
};

export const setStatusValues = (allStatusValues) => {
  return { type: SET_STATUS_VALUES, allStatusValues };
};

export const setTaxonomyTree = (taxonomyTree) => {
  return { type: SET_TAXONOMY_TREE, taxonomyTree };
};

export const setInvestorCategoryValues = (allInvestorCategoryValues) => {
  return { type: SET_INVESTOR_CATEGORY_VALUES, allInvestorCategoryValues };
};

export const setAliasTypeValues = (allAliasTypeValues) => {
  return { type: SET_ALIAS_TYPE_VALUES, allAliasTypeValues };
};

export const setGeneralEntityInfo = (
  generalEntityInfo,
  isDuplicate = false
) => {
  return { type: SET_GENERAL_ENTITY_INFO, generalEntityInfo, isDuplicate };
};

export const hydrateGeneralEntityInfo = (generalEntityInfo) => {
  return { type: HYDRATE_GENERAL_ENTITY_INFO, generalEntityInfo };
};

export const updateEntityPrimaryName = (entityPrimaryName) => {
  return { type: UPDATE_ENTITY_PRIMARY_NAME, entityPrimaryName };
};

export const setValidationErrors = (errors) => {
  return { type: SET_VALIDATION_ERRORS, errors };
};

export const setCountryInfo = (countries) => {
  return { type: SET_COUNTRY_INFO, countries };
};

export const setStateInfo = (idCountry, states) => {
  return { type: SET_STATE_INFO, idCountry, states };
};

export const resetEntityInfo = () => {
  return { type: RESET_ENTITY_INFO };
};

export const resetEntityEditStatus = () => {
  return { type: RESET_ENTITY_EDIT_STATUS };
};

export const resetEntityLogo = () => {
  return { type: RESET_ENTITY_LOGO };
};

export const setTitleValues = (allTitleValues) => {
  return { type: SET_TITLE_VALUES, allTitleValues };
};

export const setListEntityDrafts = (entityDraftInfo) => {
  return { type: SET_LIST_ENTITY_DRAFTS, entityDraftInfo };
};

export const saveEntityPrimaryName = (entityPrimaryName) => {
  // ACTUALLY SAVE THE NAME
  return (dispatch) => {
    return dispatch(fetchAllStatusValues()).then(() => {
      dispatch(updateEntityPrimaryName(entityPrimaryName));
    });
  };
};

export const initializeAllStatusValues = () => {
  return (dispatch) => {
    return dispatch(fetchAllStatusValues()).then((data) => {
      dispatch(setStatusValues(data));
    });
  };
};

export const fetchAllTitles = () => {
  return (dispatch) => {
    return dispatch(fetchAllPersonTitles()).then((data) => {
      dispatch(setTitleValues(data));
    });
  };
};

export const initializeAllSectorValues = () => {
  return (dispatch) => {
    return dispatch(fetchAllSectorValues()).then(({ taxonomyMap }) => {
      dispatch(setTaxonomyTree(taxonomyMap));
    });
  };
};

export const initializeAllInvestorCategoryValues = () => {
  return (dispatch) => {
    return dispatch(fetchAllInvestorCategoryValues()).then((data) => {
      dispatch(setInvestorCategoryValues(data));
    });
  };
};

export const initializeAllAliasTypeValues = () => {
  return (dispatch) => {
    return dispatch(fetchAllAliasTypeValues()).then((data) => {
      dispatch(setAliasTypeValues(data.aliasTypeValues));
    });
  };
};

export const initializeAllCountryValues = () => {
  return (dispatch) => {
    return dispatch(fetchCountrySuggestions()).then((data) => {
      dispatch(setCountryInfo(data.countryInfo));
    });
  };
};

export const initializeStateForCountry = (idCountry) => {
  return (dispatch) => {
    return dispatch(fetchStateSuggestions(idCountry)).then((data) => {
      dispatch(setStateInfo(idCountry, data.stateInfo));
    });
  };
};

export const getLogoDataFromLogoResponse = (data) => {
  if (
    data &&
    data.logoInfo &&
    data.logoInfo.length &&
    data.logoInfo[0].logos &&
    data.logoInfo[0].logos.length
  ) {
    const logoInfo = data.logoInfo[0].logos[0];
    return logoInfo.noLogoFound
      ? ''
      : { idLogo: logoInfo.idLogo, url: logoInfo.url };
  }
  return '';
};

export const setEntityLogo = (data = {}, isInvestor) => {
  return (dispatch) => {
    if (!data.idInvestor && !data.idCompany) {
      return dispatch(setFailureNotification('Could not retrieve logo'));
    }

    if (data.idCompany && !isInvestor) {
      return dispatch(fetchCompanyLogoInfo(data.idCompany)).then((response) => {
        if (response) {
          const logoData = getLogoDataFromLogoResponse(response);

          // It could be that this entity only has an investor image.
          if (logoData) {
            dispatch(setEntityLogoData(logoData));
          } else {
            dispatch(setEntityLogo(data, true));
          }
        }
      });
    }
    if (data.idInvestor) {
      return dispatch(fetchInvestorLogoInfo(data.idInvestor)).then(
        (response) => {
          if (response) {
            const logoData = getLogoDataFromLogoResponse(response);
            dispatch(setEntityLogoData(logoData));
          }
        }
      );
    }

    // if no logo found for either, reset logo state
    return dispatch(resetEntityLogo());
  };
};

export const getGeneralCbiEntityInfo = (
  infoRequest,
  shouldDefaultToCurrentName = false,
  shouldRetry = true,
  isDuplicate = false
) => {
  return (dispatch, getState) => {
    dispatch(setIsLoadingStatus(true));
    if (!infoRequest || !infoRequest.id) {
      const addresses = getState().cbiEntity.tabData.generalInfo.records;
      if (!addresses.length) {
        dispatch(initializeHqAddress());
      }
      if (shouldDefaultToCurrentName) {
        const entityName =
          getState().cbiEntity.generalInfo.entityInfo.entityNameInfo
            .primaryName;
        dispatch(markAsNewEntity(entityName));
        return dispatch(setIsLoadingStatus(false));
      }
      dispatch(markAsNewEntity());
      return dispatch(setIsLoadingStatus(false));
    }

    return request({
      url: '/service/cbientityservice/GetGeneralCbiEntityInfo',
      body: infoRequest,
    })
      .then(({ body: data }) => {
        dispatch(setGeneralEntityInfo(data, isDuplicate));
        !isDuplicate && dispatch(setEntityLogo(data));
        dispatch(setAddressInfo(data.addresses)); // set address data if it exists
        dispatch(setValidationErrors({}));
      })
      .then(() => {
        dispatch(resetEntityEditStatus());
      })
      .catch((error) => {
        if (!shouldRetry) {
          logInfo({
            error,
            message: `Failed to get Entity Info: ${JSON.stringify(
              infoRequest
            )}`,
            method: 'getGeneralCbiEntityInfo',
          });
          dispatch(
            setFailureNotification(
              `Unexpected error: could not fetch cbi entity info: ${JSON.stringify(
                error.details || error
              )}`
            )
          );
        } else {
          setTimeout(() => {
            dispatch(
              getGeneralCbiEntityInfo(
                infoRequest,
                shouldDefaultToCurrentName,
                false
              )
            );
          }, 1000);
        }
      })
      .finally(() => dispatch(setIsLoadingStatus(false)));
  };
};

export const initializeGeneralCbiEntityInfoFromSearch = (entityName) => {
  return (dispatch, getState) => {
    const addresses = getState().cbiEntity.tabData.generalInfo.records;
    if (!addresses.length) {
      dispatch(initializeHqAddress());
    }
    return dispatch(markAsNewEntity(entityName));
  };
};

function logUpdateLogoError(error = 'No Error Returned', message, meta = {}) {
  try {
    logInfo({
      error,
      message: `${message}: meta=${JSON.stringify(meta)}`,
      method: 'updateEntityLogo',
    });
  } catch (err) {
    /* Failing to log should not stop update */
  }
}

export const updateEntityLogo = (file) => {
  return (dispatch, getState) => {
    const requests = [];
    const requestTypes = [];
    const { idCompany, idInvestor } =
      getState().cbiEntity.generalInfo.entityInfo;
    const { idUser } = getState().user.data;

    function getBuffer(resolve) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const bytes = new Uint8Array(arrayBuffer);
        resolve(bytes);
      };
    }

    const imagePromise = new Promise(getBuffer);
    imagePromise
      .then((bytes) => {
        if (idCompany) {
          const body = {
            idUser,
            idCompany,
            image: bytes,
            imageName: file.name,
            setAsDefault: true,
            // imageUrl: file.preview
          };
          requests.push(dispatch(setCompanyLogo(body)));
          requestTypes.push('company');
        }
        if (idInvestor) {
          const body = {
            idUser,
            idInvestor,
            image: bytes,
            imageName: file.name,
            setAsDefault: true,
            // imageUrl: file.preview
          };
          requests.push(dispatch(setInvestorLogo(body)));
          requestTypes.push('investor');
        }
      })
      .then(() => {
        return Promise.all(requests)
          .then((response) => {
            let success = true;
            let errorMsg = '';
            if (!response.length || !response) {
              success = false;
              errorMsg = 'Something went wrong';
            }
            forEach(response, (resp, index) => {
              if (!resp.success) {
                success = false;
                errorMsg = `Failed to update ${requestTypes[index]} logo`;
                logUpdateLogoError(
                  resp.failureReason,
                  `API did not return success for ${requestTypes[index]}`,
                  { idCompany, idInvestor, idUser }
                );
              }
            });
            if (success) {
              dispatch(setEntityLogo({ idCompany, idInvestor }));
              return dispatch(
                setSuccessNotification('Logo updated successfully!')
              );
            }
            return dispatch(setFailureNotification(errorMsg));
          })
          .catch((error) => {
            logUpdateLogoError(error, 'API Failed', {
              idCompany,
              idInvestor,
              idUser,
            });
            return dispatch(setFailureNotification(`errorMsg: ${error}`));
          });
      })
      .catch((error) => {
        logUpdateLogoError(error, 'Failed at getBuffer on File Read');
        throw error;
      });
  };
};

export const removeEntityLogo = () => {
  return (dispatch, getState) => {
    const { idUser } = getState().user.data;
    const { idCbiEntity, idCompany, idInvestor } =
      getState().cbiEntity.generalInfo.entityInfo;
    const { idLogo } = getState().cbiEntity.generalInfo.logo;
    const body = {
      idUser,
      idLogo,
      idEntity: idCbiEntity,
      entityType: 'entity',
      unsetDefaultLogo: true,
    };
    return dispatch(unsetEntityLogo(body))
      .then((response) => {
        if (response.success) {
          dispatch(setEntityLogo({ idCompany, idInvestor }));
          return dispatch(setSuccessNotification('Logo successfully removed!'));
        }
        return dispatch(setFailureNotification('Logo removal failed'));
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Logo removal failed: ${error}`)
        );
      });
  };
};

export const createNewEntityPerson = (body, options = {}) => {
  const { recordId } = options;
  return (dispatch) => {
    return dispatch(createEntityPerson(body))
      .then((response) => {
        if (response && response.idInvPerson) {
          // After we create a new record we may want to show it on the UI
          if (recordId) {
            dispatch(
              addNewBoardOfDirectorsPerson(
                recordId,
                response.idInvPerson,
                `${response.firstName} ${response.lastName}`
              )
            );
          }

          return dispatch(
            setSuccessNotification(
              'New board of directors person created successfully'
            )
          );
        }
        return dispatch(
          setFailureNotification('Unable to create board of directors person')
        );
      })
      .catch((error) => {
        return dispatch(
          setFailureNotification(`Something went wrong: ${error}`)
        );
      });
  };
};
