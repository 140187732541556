/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { map, get } from 'lodash';
import { TableRow as MuiRow } from 'client/component-library/Table';
import TableCell from './TableCell';

/**
 * @deprecated
 * It renders a table cell for each column in the table
 * @param id - The id of the row
 * @param row - the row object
 * @param columnNames - An array of objects that describe the columns in the table.
 * @param selected - an object with the ids of the selected rows as keys
 * @param cellSubtexts - An object with keys that are the ids of the rows and values that are the
 * subtexts for the row.
 * @param editingDisabled - boolean
 * @param recordCellStyles - an object with keys that are column names and values that are objects with
 * keys that are record ids and values that are objects with keys that are css properties and values
 * that are css values.
 * @param showAllArticles - boolean
 * @param additionalPropNames - An array of strings that are the names of additional props that should
 * be passed to the TableCell component.
 * @param generateCustomTableComponents - a function that returns a custom table component
 * @returns A function that takes in the following parameters:
 * id,
 * row,
 * columnNames,
 * selected,
 * cellSubtexts,
 * editingDisabled,
 * recordCellStyles,
 * showAllArticles,
 * additionalPropNames,
 * generateCustomTableComponents
 */
const renderCell = (
  id,
  row,
  columnNames,
  selected,
  cellSubtexts,
  editingDisabled,
  recordCellStyles,
  showAllArticles,
  additionalPropNames,
  generateCustomTableComponents
) => {
  return map(columnNames, (column) => {
    let cellStyle = {};
    if (recordCellStyles) {
      cellStyle = recordCellStyles[column.key] || {};
    }
    const derivedProps = get(column, 'getDerivedProps', () => ({}))(row);
    return (
      <TableCell
        generateCustomTableComponents={generateCustomTableComponents}
        additionalPropNames={additionalPropNames}
        autocompleteOptions={column.autocompleteOptions}
        cellStyle={cellStyle}
        cellSubtexts={cellSubtexts}
        checked={!!selected[id]}
        disabled={editingDisabled}
        id={id}
        includes={column.includes}
        key={column.key}
        row={row}
        showAllArticles={showAllArticles}
        width={column.width}
        {...derivedProps}
      />
    );
  });
};

/** @deprecated It renders a row of cells */
class TableRow extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      id,
      tableRowId,
      record,
      selected,
      columnNames,
      className,
      style,
      additionalPropNames,
      handleChange,
      cellSubtexts,
      editingDisabled,
      recordCellStyles,
      showAllArticles,
      generateCustomTableComponents,
    } = this.props;
    return (
      <MuiRow
        onChange={handleChange(id, record)}
        key={tableRowId}
        className={className}
        table-row-id={tableRowId}
        style={style}
      >
        {renderCell(
          id,
          record,
          columnNames,
          selected,
          cellSubtexts,
          editingDisabled,
          recordCellStyles,
          showAllArticles,
          additionalPropNames,
          generateCustomTableComponents
        )}
      </MuiRow>
    );
  }
}

TableRow.propTypes = {
  additionalPropNames: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  editingDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
  selected: PropTypes.object,
  cellSubtexts: PropTypes.object,
  recordCellStyles: PropTypes.object,
  showAllArticles: PropTypes.bool,
  style: PropTypes.object,
  tableRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

TableRow.defaultProps = {
  additionalPropNames: [],
  className: '',
  editingDisabled: false,
  handleChange: () => {},
  columnNames: [],
  record: {},
  selected: {},
  recordStyles: {},
  cellSubtexts: {},
  recordCellStyles: {},
  showAllArticles: false,
  style: {},
};

export default TableRow;
