import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { CBISectionForm } from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { CompanyFinancialInfo } from './CompanyFinancialInfo';
import {
  FinancialFormFields,
  FinancialFormStatus,
  FinancialFormValues,
} from './hooks/useFinancials';
import { SearchableShortExchangeCodeField } from './SearchableShortExchangeCodeField';
import { SearchableTickerField } from './SearchableTickerField';
import { SearchableQuartrCompanyField } from './SearchableQuartrCompanyField';

export type FinancialsFormProps = CBISectionForm<
  FinancialFormValues,
  { formIsLoading: boolean },
  FinancialFormStatus
>;

export const clearQuartrCompany = (values: FinancialFormValues) => {
  const updatedValues = { ...values };
  updatedValues.company[0].quartrCompany = {
    label: undefined,
    value: undefined,
    key: undefined,
    idQuartrCompany: undefined,
  };
  return updatedValues;
};

export const FinancialsForm = ({ values }: FinancialsFormProps) => {
  const [isTickerItemSelected, setIsTickerItemSelected] = useState(
    !!values.company[0]?.ticker?.value
  );

  const { setFieldValue } = useFormikContext<FinancialFormValues>();

  useEffect(() => {
    if (!isTickerItemSelected && values.company.length > 0) {
      const updatedValues = clearQuartrCompany(values);
      setFieldValue('company', updatedValues.company);
    }
  }, [isTickerItemSelected]);

  return (
    <Form name="horizontal_login">
      <div className="flex flex-col">
        <FieldArray name={FinancialFormFields.Company}>
          {(arrayHelpers) =>
            values.company.length ? (
              values.company.map((_, index) => (
                <div
                  className="flex flex-wrap"
                  key={`${_.shortExchangeCode}-${_.ticker}-${String(index)}`}
                >
                  <Form.Item label="Stock Exchange" className="mr-2">
                    <SearchableShortExchangeCodeField index={index} />
                  </Form.Item>
                  <Form.Item label="Stock Ticker" className="mr-2">
                    <SearchableTickerField
                      index={index}
                      onChange={(selected) => {
                        setIsTickerItemSelected(selected);
                        clearQuartrCompany(values);
                      }}
                    />
                  </Form.Item>
                  {isTickerItemSelected && (
                    <Form.Item label="Quartr Company" className="mr-2">
                      <SearchableQuartrCompanyField index={index} />
                    </Form.Item>
                  )}
                  <Button
                    className="mtext-red-600"
                    onClick={() => arrayHelpers.remove(index)}
                    type="link"
                    icon={<DeleteOutlined />}
                    data-testid="delete-financial-relationship"
                  />
                </div>
              ))
            ) : (
              <Button
                onClick={() =>
                  arrayHelpers.push({
                    ticker: { label: undefined, value: undefined },
                    exchange: { label: undefined, value: undefined },
                    quartrCompany: { label: undefined, value: undefined },
                  })
                }
                type="link"
                data-testid="add-financial-relationship"
                className="mb-2"
                icon={<PlusCircleOutlined />}
              />
            )
          }
        </FieldArray>
        <CompanyFinancialInfo />
      </div>
    </Form>
  );
};
