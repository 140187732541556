import { SetTeamAdditionalQuotaRequest } from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const setTeamAdditionalQuota = (
  req: Partial<SetTeamAdditionalQuotaRequest>
): Promise<void> =>
  request({
    url: '/service/userv2service/SetTeamAdditionalQuota',
    body: req,
  }).then((r) => r.body);
