import { Modal } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { AntdSearchableField } from 'client/modules/common/antDesign/form/fields/AntdSearchableSelect';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { ReactElement, ReactNode, useState } from 'react';
import { AntdSelectFieldProps, setFieldWarning } from './AntdSelectField';

export type AntdSearchableWithConfirmationOnUnselectFieldProps = {
  modalDataTest?: string;
  confirmDeleteModalTitle: string;
  confirmDeleteModalBody: ReactNode;
} & AntdSelectFieldProps;

export const AntdSearchableWithConfirmationOnUnselectField = ({
  confirmDeleteModalTitle,
  confirmDeleteModalBody,
  modalDataTest,
  name,
  onChange,
  handleWarning,
  onDeselect = () => null,
  ...props
}: AntdSearchableWithConfirmationOnUnselectFieldProps): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletedItem, setDeletedItem] = useState<
    DefaultOptionType | number | string
  >(null);
  const { values, setFieldValue } = useFormikContext();
  const formSelectValue: DefaultOptionType[] = get(values, name, []).map(
    (el) => {
      if (!Object.getOwnPropertyDescriptor(el, 'value')) {
        return {
          value: el,
        };
      }
      return el;
    }
  );

  const handleChange: AntdSelectFieldProps['onChange'] = (
    updatedSelection,
    options,
    updateFieldValue
  ) => {
    if (Array.isArray(updatedSelection)) {
      if (formSelectValue.length > updatedSelection.length) {
        updateFieldValue(formSelectValue);
        return;
      }
    }
    if (onChange) {
      onChange(updatedSelection, options, updateFieldValue);
      return;
    }
    updateFieldValue(updatedSelection);
  };

  const handleConfirmDelete = () => {
    const filteredSelection = formSelectValue.filter((option) => {
      if (deletedItem instanceof Object && 'value' in deletedItem) {
        return option?.value !== deletedItem?.value;
      }
      return option?.value !== deletedItem;
    });

    setFieldWarning(name, filteredSelection, [], setFieldValue, handleWarning);
    onDeselect(deletedItem, filteredSelection as $TSFixMe);
    setFieldValue(name, filteredSelection);
    setIsModalVisible(false);
  };

  const deselectHandler = (deletedValue: DefaultOptionType) => {
    let completeDeleteValue: DefaultOptionType;
    if (deletedValue instanceof Object && 'value' in deletedValue) {
      completeDeleteValue = formSelectValue.find(
        (el) => el.value === deletedValue.value
      );
    } else {
      completeDeleteValue = deletedValue;
    }

    setFieldWarning(name, formSelectValue, [], setFieldValue, handleWarning);
    setDeletedItem(completeDeleteValue);
    setIsModalVisible(true);
  };

  const handleCancelDelete = () => setIsModalVisible(false);

  return (
    <>
      <AntdSearchableField
        {...props}
        name={name}
        onChange={handleChange}
        onDeselect={deselectHandler}
        handleWarning={handleWarning}
      />
      <Modal
        title={confirmDeleteModalTitle}
        visible={isModalVisible}
        data-test={modalDataTest}
        onOk={handleConfirmDelete}
        okText="Delete"
        onCancel={handleCancelDelete}
        cancelText="Keep"
      >
        <p>{confirmDeleteModalBody}</p>
      </Modal>
    </>
  );
};
