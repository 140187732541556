import {
  map,
  findIndex,
  filter,
  compose,
  remove,
  includes,
  difference,
} from 'lodash/fp';
import cbiUtils, { handleAsyncError } from 'client/modules/common/utils/cbi';
import {
  setSuccessNotification,
  setFailureNotification,
} from 'client/modules/common/redux/actions/status-notification';

import newsQueueManagement, {
  SET_MANAGEMENT_QUEUE,
  SET_CATEGORY_FILTER,
  SET_SOURCE_FILTER,
  SET_FUNDING_CLASSIFIER_FILTER_STATUS,
  UPDATE_LANGUAGE_FILTER,
  SET_LANGUAGE_FILTERS,
  REMOVE_ARTICLE_FROM_QUEUE,
  LOADING_NEWS,
  DONE_LOADING_NEWS,
  TOGGLE_DATE_SORT,
  SET_TITLE_SEARCH_TEXT,
  SET_SOURCE_SEARCH_TEXT,
  SET_WEEK_SELECTION,
  SET_ARTICLE_TYPE_FILTER,
} from './news-queue-management';

const hostnameMap = {
  'admin.cbinsights.com': 'www.cbinsights.com',
  'admin-staging.cbinsights.com': 'staging.cbinsights.com',
  'admin-dev.cbinsights.com': 'dev.cbinsights.com',
  localhost: 'dev.cbinsights.com',
};

const openSECNewsSourceInOldAdmin = (secPath = '') => {
  if (!secPath) {
    return;
  }

  // Open SEC NEWS source links in the old cbi-site admin
  const hostname =
    hostnameMap[window.location.hostname] || 'www.cbinsights.com';
  const url = `https://${hostname}/${secPath}`;
  window.open(url, '_blank');
};

const SET_FETCHING_NEWS = 'ADMIN/NEWS/QUEUE/SET_FETCHING_NEWS';
const SET_QUEUE = 'ADMIN/NEWS/QUEUE/SET_QUEUE';
const RESET_QUEUE = 'ADMIN/NEWS/QUEUE/RESET_QUEUE';
const REMOVE_ITEM = 'ADMIN/NEWS/QUEUE/REMOVE_ITEM';
const REMOVE_UNRELATED_ITEM = 'ADMIN/NEWS/QUEUE/REMOVE_UNRELATED_ITEM';
const SET_SOURCE_TYPE = 'ADMIN/NEWS/QUEUE/SET_SOURCE_TYPE';
const TOGGLE_RELATED_FLAG_FOR_NEWS_ITEM =
  'ADMIN/NEWS/QUEUE/TOGGLE_RELATED_FLAG_FOR_NEWS_ITEM';
const OPEN_SOURCE = 'ADMIN/NEWS/QUEUE/OPEN_SOURCE';
const SET_RESOLVING_NEWS = 'ADMIN/NEWS/QUEUE/SET_RESOLVING_NEWS';
const SET_FILTER_GROUPS = 'ADMIN/NEWS/QUEUE/SET_FILTER_GROUPS';
const UPDATE_SELECTED_FILTER_GROUP_NAME =
  'ADMIN/NEWS/QUEUE/UPDATE_SELECTED_FILTER_GROUP_NAME';
const SET_SELECTED_FILTER_GROUPS =
  'ADMIN/NEWS/QUEUE/SET_SELECTED_FILTER_GROUPS';

const cruncherSuggestionPath = '/admin/cruncher/news/suggestion/';
const secBasePath = '/admin/sec/';
const FETCH_ONE = 1;

export const QUEUE_SIZE = 5;
export const SOURCETYPE_ALL = 'All';
export const SOURCETYPE_FUNDING = 'Funding';
export const SOURCETYPE_MERGERS_ACQUISITIONS = 'M&A';
export const SOURCETYPE_SEC_FUNDS = 'SEC Funds';
export const SOURCETYPE_SEC_NEWS = 'SEC News';
export const NEWS_QUEUE_ARCHIVE = 'archive';
export const NEWS_QUEUE_DISCARD = 'discard';
export const NEWS_QUEUE_BLOCK = 'block';

export function mapSourceTypeToDisplayType(sourceType) {
  switch (sourceType) {
    case SOURCETYPE_ALL:
      return 'Total';
    case SOURCETYPE_FUNDING:
      return 'Funding';
    case SOURCETYPE_MERGERS_ACQUISITIONS:
      return 'M&A';
    case SOURCETYPE_SEC_FUNDS:
      return 'SEC Funding';
    case SOURCETYPE_SEC_NEWS:
      return 'SEC News';
    default:
      return '';
  }
}

export function fetchingEditSource() {
  return { type: SET_FETCHING_NEWS };
}

export function resolvingNews() {
  return { type: SET_RESOLVING_NEWS };
}

export function setQueue({ queue, remainingCount }) {
  return { type: SET_QUEUE, queue, remainingCount };
}

export function updateSourceType(sourceType) {
  return { type: SET_SOURCE_TYPE, sourceType };
}

export function updateFilterGroupName(filterGroupNames) {
  return { type: UPDATE_SELECTED_FILTER_GROUP_NAME, filterGroupNames };
}

export function resetQueue() {
  return { type: RESET_QUEUE };
}

export function removeUnrelatedItems(index, ids) {
  return { type: REMOVE_UNRELATED_ITEM, index, ids };
}

export function removeItem(index) {
  return { type: REMOVE_ITEM, index };
}

export function toggleRelatedFlagForNewsItem(index, id) {
  return { type: TOGGLE_RELATED_FLAG_FOR_NEWS_ITEM, index, id };
}

export function setFilterGroups(filterGroups) {
  return { type: SET_FILTER_GROUPS, filterGroups };
}

export function setSelectedFilterGroups(filterGroups) {
  return { type: SET_SELECTED_FILTER_GROUPS, filterGroups };
}

export function fetchNews(numItemsToAssign) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.newsQueue.userQueue || state.newsQueue.userQueue.length === 0) {
      dispatch(fetchingEditSource());
    }

    let filterName = '';
    if (state.newsQueue.selectedFilterGroupName !== 'All') {
      filterName = state.newsQueue.selectedFilterGroupName;
    }
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'POST',
        data: {
          type: state.newsQueue.sourceType,
          numItemsToAssign,
          filterName,
        },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((json) => {
        if (!json.queue || json.queue.length === 0) {
          dispatch(setFailureNotification('No articles fetched'));
        }
        dispatch(setQueue(json));
      });
  };
}

export function fetchAssignedNews() {
  return (dispatch) => {
    dispatch(fetchingEditSource());

    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        data: {
          type: 'All',
        },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((json) => {
        dispatch(setQueue(json));
      });
  };
}

export function fetchAllFilterGroups() {
  return (dispatch) => {
    dispatch(fetchingEditSource());

    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        data: {
          getFilterGroups: 1,
        },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((filterGroups) => {
        dispatch(setFilterGroups(filterGroups));
      });
  };
}

export const makeIds = (action, primaryArticle, relatedNews) => {
  const ids = map((i) => i.id)(relatedNews);
  if (action === 'block') {
    ids.push(parseInt(primaryArticle.content, 10));
  } else {
    ids.push(primaryArticle.id);
  }
  return ids;
};

export function resolveAndFetch(
  { primaryArticle, relatedNews, source },
  index,
  action
) {
  const ids = makeIds(action, primaryArticle, relatedNews);
  return (dispatch) => {
    dispatch(resolvingNews());

    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'PATCH',
        data: { ids, action, source },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        dispatch(removeItem(index));
        return dispatch(fetchNews(FETCH_ONE));
      });
  };
}

export function blockCIKCall({ primaryArticle, relatedNews, source }, index) {
  const discardIds = makeIds(NEWS_QUEUE_DISCARD, primaryArticle, relatedNews);
  let ids;
  let action;
  return (dispatch) => {
    dispatch(resolvingNews());
    ids = discardIds;
    action = NEWS_QUEUE_BLOCK;
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'PATCH',
        data: { ids, action, source },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        dispatch(removeItem(index));
        return dispatch(fetchNews(FETCH_ONE));
      });
  };
}

export function clearQueue() {
  return (dispatch) =>
    cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'DELETE',
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        return dispatch(resetQueue({ queue: {}, remainingCount: null }));
      });
}

export function unassignNews(index, ids, source) {
  return (dispatch) =>
    cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'DELETE',
        data: { idNews: ids, source },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        dispatch(removeUnrelatedItems(index, ids));
        dispatch(setSuccessNotification());
      });
}

export function createClusters(index, ids, source) {
  return (dispatch) =>
    cbiUtils
      .apiCall({
        url: 'admin/news/clusters',
        method: 'POST',
        data: { idNews: ids, source },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => dispatch(unassignNews(index, ids, source)));
}

export function markNewsAsUnrelated(index) {
  return (dispatch, getState) => {
    const state = getState();

    const obtainUnrelatedNewsIds = compose(
      map((newsItem) => newsItem.id),
      filter((newsItem) => !newsItem.isRelated)
    );

    const idNews = obtainUnrelatedNewsIds(
      state.newsQueue.userQueue[index].relatedNews
    );
    const {source} = state.newsQueue.userQueue[index];

    return cbiUtils
      .apiCall({
        url: 'admin/news/related',
        method: 'DELETE',
        data: { idNews, source },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => dispatch(createClusters(index, idNews, source)));
  };
}

export function openSource(newsItem) {
  return { newsItem, type: OPEN_SOURCE };
}

export function changeSourceTypeAndClearQueue(sourceType) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.newsQueue.userQueue && state.newsQueue.userQueue.length > 0) {
      dispatch(clearQueue());
    }
    dispatch(updateSourceType(sourceType));
    dispatch(fetchingEditSource());
    dispatch(fetchNews(QUEUE_SIZE));
  };
}

export function changeFilterTypeAndClearQueue(filterGroupName) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.newsQueue.userQueue && state.newsQueue.userQueue.length > 0) {
      dispatch(clearQueue());
    }
    dispatch(updateFilterGroupName(filterGroupName));
    dispatch(fetchingEditSource());
    dispatch(fetchNews(QUEUE_SIZE));
  };
}

export function getFilterGroupNames(filterGroups) {
  const filterGroupNames = { 0: 'All' };
  Object.keys(filterGroups).forEach((filterGroupValue) => {
    filterGroupNames[filterGroups[filterGroupValue]] = filterGroupValue;
  });
  return filterGroupNames;
}

export default function (
  state = {
    isEnabledFundingClassiferFilter: false,
    isFetchingNews: true,
    isResolvingNews: true,
    userQueue: [],
    sourceType: 'All',
    selectedFilterGroupName: ['All'],
    displaySourceType: 'Total',
    articleTypeOptions: [
      { id: 1, label: 'Funding' },
      { id: 2, label: 'M&A' },
    ],
    remainingCount: null,
    management: newsQueueManagement(undefined, {}),
  },
  action
) {
  switch (action.type) {
    case SET_FUNDING_CLASSIFIER_FILTER_STATUS: {
      return {
        ...state,
        isEnabledFundingClassiferFilter: action.isEnabledFundingClassiferFilter,
      };
    }
    case SET_FETCHING_NEWS: {
      return { ...state, isFetchingNews: true };
    }
    case SET_RESOLVING_NEWS: {
      return { ...state, isResolvingNews: true };
    }
    case SET_FILTER_GROUPS: {
      return {
        ...state,
        filterGroups: action.filterGroups,
        filterGroupNames: getFilterGroupNames(action.filterGroups),
      };
    }
    case SET_QUEUE: {
      const queue = map((newsQueueItem) => {
        let relatedNews;
        if (newsQueueItem.relatedNews && newsQueueItem.relatedNews.length > 0) {
          relatedNews = map((relatedNewsItem) =>
            ({ ...relatedNewsItem, isRelated: true})
          )(newsQueueItem.relatedNews);
        }
        return { ...newsQueueItem, relatedNews};
      })(action.queue);

      return {
        ...state,
        userQueue: state.userQueue.concat(queue) || [],
        isFetchingNews: false,
        isResolvingNews: false,
        remainingCount: action.remainingCount,
      };
    }
    case REMOVE_ITEM: {
      return {
        ...state,
        userQueue: [
          ...state.userQueue.slice(0, action.index),
          ...state.userQueue.slice(action.index + 1),
        ],
      };
    }
    case RESET_QUEUE: {
      return {
        ...state,
        userQueue: [],
        remainingCount: null,
      };
    }
    case REMOVE_UNRELATED_ITEM: {
      const relatedNews = remove((item) => {
        return includes(item.id)(action.ids);
      })(state.userQueue[action.index].relatedNews);

      return {
        ...state,
        userQueue: [
          ...state.userQueue.slice(0, action.index),
          { ...state.userQueue[action.index], relatedNews},
          ...state.userQueue.slice(action.index + 1),
        ],
      };
    }
    case SET_SOURCE_TYPE: {
      return {
        ...state,
        sourceType: action.sourceType,
        displaySourceType: mapSourceTypeToDisplayType(action.sourceType),
        remainingCount: null,
      };
    }
    case TOGGLE_RELATED_FLAG_FOR_NEWS_ITEM: {
      const relatedNews = state.userQueue[action.index].relatedNews.slice();
      const index = findIndex((newsItem) => newsItem.id === action.id)(
        relatedNews
      );
      const newsItem = { ...relatedNews[index], isRelated: !relatedNews[index].isRelated,};

      relatedNews.splice(index, 1, newsItem);

      return {
        ...state,
        userQueue: [
          ...state.userQueue.slice(0, action.index),
          { ...state.userQueue[action.index], relatedNews},
          ...state.userQueue.slice(action.index + 1),
        ],
      };
    }
    case SET_MANAGEMENT_QUEUE:
    case SET_CATEGORY_FILTER:
    case SET_SOURCE_FILTER:
    case UPDATE_LANGUAGE_FILTER:
    case SET_LANGUAGE_FILTERS:
    case REMOVE_ARTICLE_FROM_QUEUE:
    case LOADING_NEWS:
    case DONE_LOADING_NEWS:
    case TOGGLE_DATE_SORT:
    case SET_TITLE_SEARCH_TEXT:
    case SET_SOURCE_SEARCH_TEXT:
    case SET_WEEK_SELECTION:
    case SET_ARTICLE_TYPE_FILTER: {
      return {
        ...state,
        management: newsQueueManagement(state.management, action),
      };
    }
    case OPEN_SOURCE:
      if (action.newsItem.primaryArticle.idSuggestion) {
        const suggestionPath =
          cruncherSuggestionPath + action.newsItem.primaryArticle.idSuggestion;
        window.open(`${suggestionPath}/`, '_blank');
      }
      if (state.sourceType === SOURCETYPE_SEC_NEWS) {
        const secPath = secBasePath + action.newsItem.primaryArticle.id;
        openSECNewsSourceInOldAdmin(secPath);
      }

      window.open(action.newsItem.primaryArticle.source, '_blank');

      return state;
    case UPDATE_SELECTED_FILTER_GROUP_NAME: {
      let nextFilterGroupNames;
      // remove 'all' when adding a new filter group
      if (
        includes(state.selectedFilterGroupName, 'All') &&
        difference(action.filterGroupNames, ['All']).length
      ) {
        nextFilterGroupNames = difference(action.filterGroupNames, ['All']);
        // remove other filter groups when selecting 'all'
      } else if (
        action.filterGroupNames.indexOf('All') >= 0 &&
        state.selectedFilterGroupName.indexOf('All') < 0
      ) {
        nextFilterGroupNames = ['All'];
      } else {
        nextFilterGroupNames = action.filterGroupNames;
      }
      return {
        ...state,
        selectedFilterGroupName: nextFilterGroupNames,
      };
    }
    default:
      return state;
  }
}
