import {
  ApprovalStatus,
  GetRelationshipCountsResponse,
  GetRelationshipsRequest,
} from '@cbinsights/businessrelationshipservice/businessrelationshipservice';
import { useQuery } from 'react-query';
import { getRelationshipsCounts } from '../GetRelationshipCounts';
import { QueryKeys } from './useGetRelationshipQuery';

type RelationshipsCount = Readonly<{
  [ApprovalStatus.Approved]: number;
  [ApprovalStatus.NeedsReview]: number;
  [ApprovalStatus.PendingApproved]: number;
  [ApprovalStatus.PendingRejected]: number;
  [ApprovalStatus.Rejected]: number;
}>;
export const useGetRelationshipsCounts = (
  enabled = false,
  params?: GetRelationshipsRequest
) => {
  const query = useQuery<
    GetRelationshipCountsResponse,
    Error,
    RelationshipsCount,
    [QueryKeys]
  >(['GetRelationshipCounts'], () => getRelationshipsCounts(params), {
    notifyOnChangeProps: ['data', 'isFetching', 'isFetched'],
    select: (response) => {
      const temp = {
        ...response.allCountPerStatus,
        ...response.unlockedCountPerStatus,
      };

      return {
        [ApprovalStatus.Approved]: Number(temp.approved),
        [ApprovalStatus.NeedsReview]: Number(temp.needsReview),
        [ApprovalStatus.PendingApproved]: Number(temp.pendingApproved),
        [ApprovalStatus.PendingRejected]: Number(temp.pendingRejected),
        [ApprovalStatus.Rejected]: Number(temp.rejected),
      };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: true,
    enabled,
  });

  return query;
};
