import { getCurrentTab } from '../utils/records-helper';

export const INITIALZE_DATA_DETAILS =
  'client/modules/packages/actions/INITIALZE_DATA_DETAILS';
export const TOGGLE_SHOW_DIFF_CHECKBOX =
  'client/modules/packages/actions/TOGGLE_SHOW_DIFF_CHECKBOX';
export const UPDATE_SORT_ORDER =
  'client/modules/packages/actions/UPDATE_SORT_ORDER';

export function initializeDataDetailsFromUrlParams(urlParams) {
  return getCurrentTab((currentTab) => {
    return {
      type: INITIALZE_DATA_DETAILS,
      ...urlParams,
      currentTab,
    };
  });
}

export const updateSortOrder = (currentTab, sortField, sortDirection) => {
  return { type: UPDATE_SORT_ORDER, currentTab, sortField, sortDirection };
};

export const toggleShowDiffCheckbox = () => {
  return { type: TOGGLE_SHOW_DIFF_CHECKBOX };
};
