import {
  DeleteMarketInReviewForAdminRequest,
  DeleteMarketInReviewForAdminResponse,
  DeleteMarketRequest,
  GetMarketDetailsForAdminRequest,
  GetMarketDetailsForAdminResponse,
  ListIndustriesResponse,
  ListMarketsForAdminRequest,
  ListMarketsForAdminResponse,
  MarketInReview,
  RefreshMarketCompetitorsForAdminRequest,
  RefreshMarketCompetitorsForAdminResponse,
  UpsertMarketForAdminResponse,
  UpsertMarketInReviewForAdminResponse,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import { request } from 'client/modules/common/utils/request';

export const listIndustries = (): Promise<ListIndustriesResponse> =>
  request({
    url: '/service/espmatrixservice/ListIndustries',
  }).then((r) => r.body);
export const listMarketsForAdmin = (
  req: Partial<ListMarketsForAdminRequest>
): Promise<ListMarketsForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/ListMarketsForAdmin',
    body: req,
  }).then((r) => r.body);

export const getMarketDetailsForAdmin = (
  req: Partial<GetMarketDetailsForAdminRequest>
): Promise<GetMarketDetailsForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/GetMarketDetailsForAdmin',
    body: req,
  }).then((r) => r.body);

export const upsertMarketInReviewForAdmin = (
  req: Partial<MarketInReview>
): Promise<UpsertMarketInReviewForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/UpsertMarketInReviewForAdmin',
    body: { market: req },
  }).then((r) => r.body);

export const upsertMarketForAdmin = (
  req: Partial<MarketInReview>
): Promise<UpsertMarketForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/UpsertMarketForAdmin',
    body: { market: req },
  }).then((r) => r.body);

export const deleteMarketInReviewForAdmin = (
  req: Partial<DeleteMarketInReviewForAdminRequest>
): Promise<DeleteMarketInReviewForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/DeleteMarketInReviewForAdmin',
    body: req,
  }).then((r) => r.body);

export const softDeleteMarket = (
  req: Partial<DeleteMarketRequest>
): Promise<void> =>
  request({
    url: '/service/espmatrixservice/SoftDeleteMarket',
    body: req,
  }).then((r) => r.body);

export const refreshMarketCompetitorsForAdmin = (
  req: Partial<RefreshMarketCompetitorsForAdminRequest>
): Promise<RefreshMarketCompetitorsForAdminResponse> =>
  request({
    url: '/service/espmatrixservice/RefreshMarketCompetitorsForAdmin',
    body: req,
  }).then((r) => r.body);
