import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { adminTheme } from './styles/adminMaterialTheme';

export const adminStyles = createMuiTheme(adminTheme as $TSFixMe);

const DevTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? () => null
  : // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('./DevTools').default;

const DevRoot = ({ children, store }) => {
  return (
    <MuiThemeProvider theme={adminStyles}>
      <Provider store={store}>
        {children}
        <DevTools />
      </Provider>
    </MuiThemeProvider>
  );
};

DevRoot.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export { DevRoot };
