// ids should be same as in database
export const trialPackagePermissions: Readonly<{
  pageId: 68;
  name: 'Trial Package';
  path: 'trial-package';
}> = {
  pageId: 68,
  name: 'Trial Package',
  path: 'trial-package',
};
