import { forEach } from 'lodash';
import * as actions from '../actions/navigation-structure';

export const formatNavbarStructure = (adminPageInfo, sections) => {
  const sectionPages = {};
  forEach(sections, (section) => {
    sectionPages[section] = [];
  });

  forEach(adminPageInfo, (pageInfo) => {
    sectionPages[pageInfo.section].push({
      title: pageInfo.title,
      url: pageInfo.url,
      isNewAdmin: pageInfo.isNewAdmin,
    }); 
  });

  if (sectionPages.User && !sectionPages.User.find((page) => page.url === 'packages/list')) {
    if (sectionPages.User.find((page) => page.title === 'Plans')) {
      sectionPages.User.push({
        title: 'Package List',
        url: 'packages/list',
        isNewAdmin: true,
      });
    }
  }
  return sectionPages;
};

const initialState = {
  sections: {},
  confirmationModalProps: {
    isOpen: false,
    title: null,
    body: null,
    onConfirm: () => {},
    onClose: () => {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_NAVIGATION_STRUCTURE: {
      return {
        ...state,
        sections: formatNavbarStructure(
          action.payload.adminPageInfo,
          action.payload.sections
        ),
      };
    }
    case actions.SET_CONFIRM_MODAL_PROPS: {
      return {
        ...state,
        confirmationModalProps: {
          ...initialState.confirmationModalProps,
          ...action.props,
        },
      };
    }
    default:
      return state;
  }
}
