import { forEach, isEmpty } from 'lodash';

/**
 * Get value via matching other key value pair
 * @param  {array} objects array of object
 * @param  {string} key1    The other key of the matching value
 * @param  {string|number} value1  The other value we need to match
 * @param  {string} key2    The key of output value
 * @return {string|number}  The desired value
 * @example
 *   const objects = [{ a: '1', b: '2' }, { a: '2', b: '3' }];
 *   const value2 = getKeyValueFromListViaOtherKey(objects, 'a', '1', 'b');
 *   value2.should.equal('2'); // the value will be 2 not 3, since objects[a] == '1'
 */
export const getKeyValueFromListViaOtherKey = (objects, key1, value1, key2) => {
  let result = '';
  forEach(objects, (object) => {
    if (object[key1] === value1) {
      result = object[key2];
    }
  });
  return result;
};

/**
 * Converts to return companyId and type or investorId and type
 * @param entities
 * @returns {[]}
 */
export const getLegacyIdFormatFromEntityListForSearch = (entities) => {
  const result = entities.map((args) => ({
    id: args?.idCompany !== 0 ? args?.idCompany : args?.idInvestor,
    type: args?.idCompany !== 0 ? 'company' : 'investor',
    idCbiEntity: args?.idCbiEntity || args?.id,
  }));
  return result;
};

export const getMultiKeyValueFromListViaOtherKey = (
  objects,
  key1,
  value1,
  keyArr
) => {
  const result = [];
  forEach(objects, (object) => {
    if (object[key1] === value1) {
      forEach(keyArr, (key) => {
        result.push(object[key]);
      });
    }
  });
  return result;
};

export const getMultiKeyValueFromListViaOtherKeyStringMatch = (
  objects,
  key1,
  value1,
  keyArr
) => {
  const result = [];
  const valueRegex = new RegExp(value1, 'g');
  forEach(objects, (object) => {
    if (object[key1].match(valueRegex)) {
      forEach(keyArr, (key) => {
        result.push(object[key]);
      });
    }
  });
  return result;
};

export const getMultiKeyValueFromListViaMulitpleKeyStringMatchCustomRegex = (
  objects,
  key1,
  keyArr,
  valueRegex
) => {
  const result = [];
  forEach(objects, (object) => {
    if (object[key1].match(valueRegex)) {
      forEach(keyArr, (key) => {
        result.push(object[key]);
      });
    }
  });
  return result;
};

export const getObjDifferences = (o1, o2, keysToIgnore = {}) => {
  const difference = Object.keys(o1).reduce((diff, key) => {
    if (typeof o2 === 'undefined') {
      return {
        ...diff,
        [key]: o1[key],
      };
    }
    if (keysToIgnore[key] || o1[key] === o2[key]) {
      return diff;
    }
    if (typeof o1[key] === 'object') {
      const innerDifference = getObjDifferences(o1[key], o2[key], keysToIgnore);
      if (isEmpty(innerDifference)) {
        return diff;
      }
      return {
        ...diff,
        [key]: {
          ...innerDifference,
        },
      };
    }
    return { ...diff, [key]: o1[key] };
  }, {});
  return difference;
};

export const addRecordToObjectsWithKeyValue = ({ objects, key, value }) => {
  return { ...objects, [key]: value };
};

const updateObjectInListWithKeyValueHelper = (object, keyArr, value) => {
  if (keyArr.length === 0) {
    return value;
  }
  const firstKey = keyArr[0];
  const restKey = keyArr.slice(1);
  if (typeof object[firstKey] === 'undefined') {
    return object;
  }

  return {
    ...object,
    [firstKey]: updateObjectInListWithKeyValueHelper(
      object[firstKey],
      restKey,
      value
    ),
  };
};

/**
 * Update child object in list with key value
 * @param  {object} options.objects original objects
 * @param  {string} options.id      key of child object
 * @param  {string} options.key     key inside child objects, you can connect keys with '-', for example,
 *                                  {a:{b:{c: '1'}}},if you want update the value of c, the key will be 'a-b-c'
 * @param  {string} options.value   the new value
 * @return {object}                 new copy of objects after update
 */
export const updateObjectInListWithKeyValue = ({ objects, id, key, value }) => {
  const keyArr = key.split('-');

  if (typeof objects[id] === 'undefined') {
    return { ...objects };
  }

  return {
    ...objects,
    [id]: updateObjectInListWithKeyValueHelper(objects[id], keyArr, value),
  };
};

export default getKeyValueFromListViaOtherKey;
