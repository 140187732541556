import React from 'react';
import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import { RouteObject } from 'react-router-dom';
import { DraftQueueRoutes } from './RouterHelper';
import { draftQueuePermissions } from './permissions/DraftQueue';

const DraftQueuePage = React.lazy(
  () => import('./containers/DraftQueueHomePage')
);
const DraftQueueListPage = React.lazy(
  () => import('./containers/DraftQueueListPage')
);

export const draftQueueRoutes: RouteObject = {
  path: DraftQueueRoutes.PageIndex,
  element: (
    <PermissionsProtectedRoute
      pageId={draftQueuePermissions.pageId}
      currentPath={DraftQueueRoutes.PageIndex}
    />
  ),
  children: [
    {
      path: '',
      element: (
        <React.Suspense fallback={undefined}>
          <DraftQueuePage />
        </React.Suspense>
      ),
    },
    {
      path: DraftQueueRoutes.List,
      element: (
        <React.Suspense fallback={undefined}>
          <DraftQueueListPage />
        </React.Suspense>
      ),
    },
  ],
};
