import { Outlet } from 'react-router-dom';
import React from 'react';
import { useMarketForAdmin } from '../hooks/useMarketsList';
import { useListIndustries } from '../service/hooks/useListIndustries';

export const MarketReportsHome = () => {
  useListIndustries({ enabled: true });
  useMarketForAdmin(true);

  return <Outlet />;
};
