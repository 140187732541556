/**
 * @deprecated
 * It scrolls the dropdown item into view
 * @param selectedIndex - the index of the selected item in the dropdown
 */
export function scrollDropdownItemIntoView(selectedIndex) {
  const elmnt = document.getElementById(`dropdownItem-${selectedIndex}`);
  try {
    elmnt.scrollIntoView({ block: 'nearest' });
  } catch (error) {
    console.log(
      `failed to scroll: ${error.message}. element found? ${!!elmnt}`
    );
  }
}
