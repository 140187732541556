import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeNotification } from 'client/modules/common/redux/actions/status-notification';
import formatMessage from '../utils/format-message';
import styles from './success-notification-style.css';
import Notification from '../Notification';

/** @deprecated */
class SuccessNotification extends Component {
  handleCloseNotification = () => {
    this.props.closeNotification();
  };

  render() {
    const { open, message, isMessageHtml, autoDisappear } = this.props;
    const successMessage = formatMessage(message, isMessageHtml);
    return (
      <div
        style={{
          position: 'fixed',
          width: '98%',
          left: '0',
          top: '0',
          zIndex: '1200',
        }}
      >
        <Notification
          notificationType="success"
          open={open}
          message={successMessage}
          autoDisappear={autoDisappear}
          handleCloseNotification={this.handleCloseNotification}
          notificationStyles={`${styles.successNotification}`}
        />
      </div>
    );
  }
}

SuccessNotification.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  closeNotification: PropTypes.func.isRequired,
  autoDisappear: PropTypes.bool,
  isMessageHtml: PropTypes.bool,
};

SuccessNotification.defaultProps = {
  open: false,
  message: '',
  autoDisappear: true,
  isMessageHtml: false,
};

function mapStateToProps({ common }) {
  return {
    open: common.statusNotification.success.open,
    message: common.statusNotification.success.message,
    autoDisappear: common.statusNotification.autoDisappear,
    isMessageHtml: common.statusNotification.isMessageHtml,
  };
}

/** @deprecated */
export default connect(mapStateToProps, { closeNotification })(
  SuccessNotification
);
