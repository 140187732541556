import { Industry } from '@cbinsights/espmatrixservice/espmatrixservice';

export interface IndustryInfo {
  industryId: number;
  industryName: string;
  subIndustries: IndustryInfo[];
  parentIndustryId: number;
}

export function mapIndustries(
  idIndustries: number[],
  industries: Industry[]
): IndustryInfo[] {
  return (
    idIndustries
      ?.map(
        (id) =>
          industries?.find((industry) => industry.id === id) || {
            id,
            name: 'Not Found',
            id_parent: 0,
            sub_industries: [],
          }
      )
      .map((industry) => ({
        industryId: industry.id,
        industryName: industry.name,
        parentIndustryId: industry.id_parent,
        subIndustries: industry.sub_industries.map((el) => ({
          industryId: el.id,
          industryName: el.name,
          parentIndustryId: el.id_parent,
          subIndustries: [],
        })),
      })) || []
  );
}
