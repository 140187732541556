/* eslint-disable no-restricted-globals */
import { filter, forEach, map, omit, sortBy, uniqBy } from 'lodash';
import {
  formatDate,
  formatDateToUnix,
  formatDateWithFormat,
} from 'client/modules/common/utils/timeFormattingMethods';
import { getUrlWithHttp } from 'client/modules/common/utils/getUrl';
import roundToCurrencyFormat from 'client/modules/common/utils/roundToCurrencyFormat';

const newInvestor = (idInvestor, name, idCbiEntity) => ({
  name,
  idInvestor,
  idCbiEntity,
  isNew: true,
});

export const newBoardOfDirectorsPerson = (id, name, title) => ({
  name,
  idInvestorPerson: id,
  title,
  isNew: true,
});

const newMention = (value) => ({
  idFundingSource: Date.now(),
  url: getUrlWithHttp(value),
  isNew: true,
});

const newSource = (value) => ({
  idVcFundSource: Date.now(),
  url: getUrlWithHttp(value),
  isNew: true,
});

const newCik = (value) => ({
  value,
  isNew: true,
});

const newSalesMetricSource = (value) => ({
  idSource: `new_${Date.now()}`,
  url: getUrlWithHttp(value),
  isNew: true,
});

// All Intake Transformations
export const newEmployee = (data = {}) => ({
  firstName: data.fname || '',
  middleName: data.mname || '',
  lastName: data.lname || '',
  title: data.title || '',
  email: data.email || '',
  linkedin: data.linkedin || '',
  twitter: data.twitter || '',
  facebook: data.facebook || '',
  startDate: formatDate(data.startDate, 'MM-DD-YYYY') || '',
  endDate: formatDate(data.endDate, 'MM-DD-YYYY') || '',
  bio: data.bio || '',
  firms: data.firms || [],
  boardMembers: data.boardMembers || [],
  id: data.idInvestorPerson || data.idPerson || `new_${Date.now()}`,
  isNew: true,
  isCollapsed: true,
});

export const newFirmRow = (parentId, firmInfo = {}) => ({
  id: firmInfo.idInvestor || `new_${Date.now()}`,
  parentId,
  current: !!firmInfo.current,
  firm: { name: firmInfo.firm || '', id: firmInfo.idInvestor || '' },
  title: firmInfo.title || '',
  startDate: firmInfo.startDate || '',
  endDate: firmInfo.endDate || '',
  isNew: !firmInfo.idInvestor,
  isPrimary: !!firmInfo.isPrimary,
  isCollapsed: true,
});

export const newBoardMemberRow = (parentId, member = {}) => ({
  id: member.idCbiEntityInvestment || `new_${Date.now()}`,
  parentId,
  current: !!member.current,
  company: {
    name: member.company || '',
    id: member.idCbiEntityInvestment || '',
  },
  title: member.title || '',
  startDate: member.startDate || '',
  endDate: member.endDate || '',
  sittingOnFunds: member.sittingOnFunds || [],
  availableFunds:
    member.availableFunds && member.availableFunds.length
      ? member.availableFunds
      : [{ name: 'N/A', id: -1 }],
  isNew: !member.idCbiEntityInvestment,
});

export const removeEmployee = (records, recordId) => {
  const newRecords = records.filter(({ id }) => id !== recordId);
  const selectedRecord = records.find(({ id }) => id === recordId);

  if (!selectedRecord.isNew) {
    newRecords.push({
      ...selectedRecord,
      isDeleted: true,
    });
  }

  return newRecords;
};

export const saveOriginalRecords = (originalRecords, recordId, oldRecord) => {
  if (!originalRecords[recordId]) {
    return {
      ...originalRecords,
      [recordId]: { ...oldRecord },
    };
  }
  return originalRecords;
};

export const mapFundingInfo = (records) => {
  // sort by funding date descending
  const sortedRecords = sortBy(records, [
    (funding) => {
      return formatDateToUnix(funding.fundingDate);
    },
  ]).reverse();

  return map(sortedRecords, (record) => {
    return {
      id: record.fundingRound.idFunding,
      fundingDate: formatDate(record.fundingDate, 'MM-DD-YYYY'),
      fundingRound: record.fundingRound,
      amount: record.amount > 0 ? roundToCurrencyFormat(record.amount) : null,
      maxValuation:
        record.maxValuation > 0
          ? roundToCurrencyFormat(record.maxValuation)
          : null,
      minValuation:
        record.minValuation > 0
          ? roundToCurrencyFormat(record.minValuation)
          : null,
      investors: record.investors,
      investor: '',
      mentions: record.mentions,
      mention: '',
      boardOfDirectors: record.boardOfDirectors,
      boardOfDirector: '',
      divestitureFrom: record.divestitureFrom,
    };
  });
};

export const formatInvestors = (investors) => {
  const newInvestors = filter(investors, (investor) => {
    return investor.isNew;
  });
  return map(newInvestors, (investor) => {
    return {
      name: investor.name,
      idInvestor: investor.idInvestor,
      leadInvestor: investor.leadInvestor,
      idCbiEntity: investor.idCbiEntity || 0,
    };
  });
};

export const formatBoardOfDirectors = (boardOfDirectors) => {
  const newBoDs = filter(boardOfDirectors, (bod) => {
    return bod.isNew;
  });
  return map(newBoDs, (bod) => {
    return {
      name: bod.name,
      idInvestorPerson: bod.idInvestorPerson,
    };
  });
};

export const formatMentions = (mentions, idFunding) => {
  const newMentions = filter(mentions, (mention) => {
    return mention.isNew;
  });

  return map(newMentions, (mention) => {
    return {
      url: mention.url,
      idFundingSource: mention.idFundingSource,
      idFunding: parseInt(idFunding, 10) || 0,
    };
  });
};

export const mapFundingInfoForRequest = (records, idCbiEntity) => {
  return map(records, (record) => {
    const { idFunding, idRound, name } = record.fundingRound;
    return {
      idCbiEntity,
      fundingDate: formatDate(record.fundingDate, 'YYYY-MM-DD'),
      fundingRound: {
        idFunding: parseInt(idFunding, 10) || 0,
        idRound: parseInt(idRound, 10),
        name: name || '',
      },
      amount: !isNaN(parseFloat(record.amount)) ? parseFloat(record.amount) : 0,
      maxValuation: !isNaN(parseFloat(record.maxValuation))
        ? parseFloat(record.maxValuation)
        : 0,
      minValuation: !isNaN(parseFloat(record.minValuation))
        ? parseFloat(record.minValuation)
        : 0,
      investors: formatInvestors(record.investors),
      mentions: formatMentions(record.mentions, idFunding),
      boardOfDirectors: formatBoardOfDirectors(record.boardOfDirectors),
    };
  });
};

export const formatSalesMetricSources = (sources) => {
  if (!sources.length) {
    return [];
  }
  const sourcesHolder = map(sources, (source) => {
    const { idSource, url } = source;
    return {
      idSource: parseInt(idSource, 10) || 0,
      url,
    };
  });
  return uniqBy(sourcesHolder, 'url');
};

export const mapSalesMetricInfoForRequest = (records) => {
  return map(records, (record) => {
    const { maxSales, minSales, date, sources } = record;
    return {
      idMetric: record.id,
      maxSales: parseFloat(maxSales) || parseFloat(minSales),
      minSales: parseFloat(minSales) || parseFloat(maxSales),
      sources: formatSalesMetricSources(sources),
      date: formatDate(date, 'YYYY-MM-DD'),
    };
  });
};

export const mapAddressInfo = (records) => {
  const sortedRecords = sortBy(records, ['hq_flag']);
  return map(sortedRecords, (record) => {
    return omit(
      {
        ...record,
        id: record.idAddress,
        countryInfo: { ...record.countryInfo, idContinent: record.idContinent },
      },
      'idContinent'
    );
  });
};

export const formatVcFundCiks = (ciks) => {
  return map(ciks, (cik) => {
    return {
      value: cik,
      isNew: false,
    };
  });
};

export const mapSalesMetricInfo = (records) => {
  return map(records, (record) => {
    return {
      id: record.idMetric,
      maxSales: roundToCurrencyFormat(record.maxSales),
      minSales: roundToCurrencyFormat(record.minSales),
      date: formatDateWithFormat(
        record.date,
        'MM/DD/YYYY hh:mm:ss',
        'MM-DD-YYYY'
      ),
      sources: formatSalesMetricSources(record.sources),
      newSource: '',
    };
  });
};

export const mapFundingRound = (records) => {
  const fundingTypes = map(records, (record) => {
    return {
      id: record.idRound,
      name: record.name,
      idStatus: record.idStatus,
      simplifiedRound: record.simplifiedRound,
      searchMapping: record.searchMapping,
    };
  });
  return [{ id: 0, name: 'Select One' }, ...fundingTypes];
};

export const mapEmployeeInfo = (records) => {
  return map(records, (record) => {
    return {
      ...record,
      id: record.idPerson,
    };
  });
};

export const mapCompetitorInfo = (records) => {
  return map(records, (record) => {
    return {
      ...record,
      id: record.idCompetitor,
      competitor: record.name,
    };
  });
};

export const addNewInvestorHelper = (id, name, idCbiEntity, investors = []) => {
  return investors.concat([newInvestor(id, name, idCbiEntity)]);
};

export const addNewInvestor = (
  records,
  recordId,
  idInvestor,
  investorName,
  idCbiEntity
) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      investors: addNewInvestorHelper(
        idInvestor,
        investorName,
        idCbiEntity,
        records[recordId].investors
      ),
      edited: true,
    },
  };
};

export const addNewBoardOfDirectorsPersonHelper = (
  boardOfDirectors,
  id,
  name
) => {
  return boardOfDirectors.concat([newBoardOfDirectorsPerson(id, name)]);
};

export const addNewBoardOfDirectorsPerson = (
  records,
  recordId,
  idInvestorPerson,
  boardOfDirectorsPersonName
) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      boardOfDirectors: addNewBoardOfDirectorsPersonHelper(
        records[recordId].boardOfDirectors,
        idInvestorPerson,
        boardOfDirectorsPersonName
      ),
      edited: true,
    },
  };
};

export const addNewMentionHelper = (mentions, value) => {
  const newValue = newMention(value);

  // dont allow duplicates
  if (mentions.find(({ url }) => url === newValue.url)) {
    return mentions;
  }

  return mentions.concat([newMention(value)]);
};

export const addNewMention = (records, recordId, value) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      mentions: addNewMentionHelper(records[recordId].mentions, value),
    },
  };
};

export const addNewVcSourceHelper = (sources, value) => {
  return sources.concat([newSource(value)]);
};

export const addNewVcSource = (records, recordId, value) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      sources: addNewVcSourceHelper(records[recordId].sources, value),
      edited: true,
    },
  };
};

export const addNewVcCikHelper = (ciks, value) => {
  return ciks.concat([newCik(value)]);
};

export const addNewVcCik = (records, recordId, value) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      ciks: addNewVcCikHelper(records[recordId].ciks, value),
      edited: true,
    },
  };
};

export const deleteInvestorHelper = (investors, investorId) => {
  const results = [];
  forEach(investors, (investor) => {
    if (parseInt(investor.idInvestor, 10) !== parseInt(investorId, 10)) {
      results.push(investor);
    }
  });
  return results;
};

export const deleteInvestor = (records, recordId, investorId) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      investors: deleteInvestorHelper(records[recordId].investors, investorId),
      edited: true,
    },
  };
};

export const deleteBoardOfDirectorsPersonHelper = (
  boardOfDirectorsPeople,
  boardOfDirectorPersonId
) => {
  const results = [];
  forEach(boardOfDirectorsPeople, (bodPerson) => {
    if (
      parseInt(bodPerson.idInvestorPerson, 10) !==
      parseInt(boardOfDirectorPersonId, 10)
    ) {
      results.push(bodPerson);
    }
  });
  return results;
};

export const deleteBoardOfDirectorsPerson = (
  records,
  recordId,
  boardOfDirectorsPersonId
) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      boardOfDirectors: deleteBoardOfDirectorsPersonHelper(
        records[recordId].boardOfDirectors,
        boardOfDirectorsPersonId
      ),
    },
  };
};

export const deleteMentionHelper = (mentions, mentionId) => {
  const results = [];
  forEach(mentions, (mention) => {
    if (parseInt(mention.idFundingSource, 10) !== parseInt(mentionId, 10)) {
      results.push(mention);
    }
  });
  return results;
};

export const deleteMention = (records, recordId, mentionId) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      mentions: deleteMentionHelper(records[recordId].mentions, mentionId),
    },
  };
};

export const deleteVcSourceHelper = (source, sourceId) => {
  const results = [];
  forEach(source, (row) => {
    if (parseInt(row.idVcFundSource, 10) !== parseInt(sourceId, 10)) {
      results.push(row);
    } else if (!row.isNew) {
      results.push({
        ...row,
        isDeleted: true,
      });
    }
  });
  return results;
};

export const deleteVcSource = (records, recordId, sourceId) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      sources: deleteVcSourceHelper(records[recordId].sources, sourceId),
      edited: true,
    },
  };
};

export const deleteVcCikHelper = (ciks, cikId) => {
  const results = [];
  forEach(ciks, (row) => {
    if (parseInt(row.value, 10) !== parseInt(cikId, 10)) {
      results.push(row);
    } else if (!row.isNew) {
      results.push({
        ...row,
        isDeleted: true,
      });
    }
  });
  return results;
};

export const deleteVcCik = (records, recordId, cikId) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      ciks: deleteVcCikHelper(records[recordId].ciks, cikId),
      edited: true,
    },
  };
};

export const deleteSalesMetricSourceHelper = (sources, sourceId) => {
  if (isNaN(sourceId)) {
    return sources.filter((source) => source.idSource !== sourceId);
  }
  return sources.filter(
    (source) => parseInt(source.idSource, 10) !== parseInt(sourceId, 10)
  );
};

export const deleteSalesMetricSource = (records, recordId, sourceId) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      sources: deleteSalesMetricSourceHelper(
        records[recordId].sources,
        sourceId
      ),
    },
  };
};

export const addSalesMetricSourceHelper = (sources, value) => {
  return sources.concat([newSalesMetricSource(value)]);
};

export const addSalesMetricSource = (records, recordId, value) => {
  return {
    ...records,
    [recordId]: {
      ...records[recordId],
      sources: addSalesMetricSourceHelper(records[recordId].sources, value),
    },
  };
};

export const deleteRecordId = (recordsOrder, recordId) => {
  if (isNaN(recordId)) {
    return recordsOrder.filter((item) => item !== recordId);
  }
  return recordsOrder.filter(
    (item) => parseInt(item, 10) !== parseInt(recordId, 10)
  );
};

// NOTE: this function filter out all sources/limited partners that are not new
export function prepVcFundForRequest(vcInfo) {
  const { fundName, startDate, closeDate } = vcInfo;

  return {
    fundName,
    startDate: formatDate(startDate, 'YYYY-MM-DD'),
    closeDate: formatDate(closeDate, 'YYYY-MM-DD'),
    idVcFund: parseInt(vcInfo.id, 10) || 0,
    sources: vcInfo.sources
      ? vcInfo.sources
          .filter((source) => source.isNew)
          .map(({ url, idVcFundSource }) => ({
            url,
            idVcFundSource: parseInt(idVcFundSource, 10),
          }))
      : [],
    ciks: vcInfo.ciks ? vcInfo.ciks.map((cik) => parseInt(cik.value, 10)) : [],
    amountRaised: parseFloat(vcInfo.amountRaised),
    amountTarget: parseFloat(vcInfo.amountTarget),
    idCbiEntity: parseInt(vcInfo.idCbiEntity, 10),
    limitedPartners: vcInfo.limitedPartners
      ? vcInfo.limitedPartners
          .filter((source) => source.isNew)
          .map(({ idCbiEntity, name }) => ({
            limitedPartner: name,
            idCbiEntity: parseInt(idCbiEntity, 10),
          }))
      : [],
  };
}

export const mapVcFundingInfo = (records) => {
  return map(records, (record) => {
    return {
      id: record.idVcFund,
      idFund: record.idVcFund,
      startDate: formatDate(record.startDate, 'MM-DD-YYYY'),
      closeDate: formatDate(record.closeDate, 'MM-DD-YYYY'),
      fundName: record.fundName,
      ciks: formatVcFundCiks(record.ciks),
      amountRaised: roundToCurrencyFormat(record.amountRaised),
      amountTarget: roundToCurrencyFormat(record.amountTarget),
      sources: record.sources,
      newSource: '',
      limitedPartners:
        record.limitedPartners.map((lp) => ({
          id: lp.idLimitedPartner,
          idCbiEntity: lp.idCbiEntity,
          name: lp.limitedPartner,
        })) || [],
      isNew: false,
    };
  });
};

export const newAddress = () => ({
  id: `new_${Date.now()}`,
  idAddress: `new_${Date.now()}`,
  addressName: '',
  hqFlag: 0,
  street1: '',
  street2: '',
  cityInfo: {
    name: '',
    idCity: 0,
    idState: 0,
    idCountry: 0,
    zip: '',
  },
  stateInfo: {
    idState: 0,
    idCountry: 0,
    name: '',
  },
  zip: '',
  countryInfo: {
    idCountry: 0,
    name: '',
    idContinent: 0,
  },
  phone: '',
  idGeoRegionNew: null,
  idType: null,
  edited: false,
});

export default mapFundingInfo;
