import {
  GetSingleTeamsMembersRequest,
  TeamMemberInfo,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getTeamMembers = (
  req: Partial<GetSingleTeamsMembersRequest>
): Promise<TeamMemberInfo> =>
  request({
    url: '/service/adminservice/GetSingleTeamsMembers',
    body: req,
  }).then((r) => r.body);
