import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ManagementQueueRow extends Component {
  render() {
    const { newsItem, children, index } = this.props;
    const mapTypeToString = (idType) => {
      if (idType === 1) {
        return 'Funding';
      } if (idType === 2) {
        return 'M&A';
      }
      return 'Unknown';
    };

    return (
      <tr className={!(index % 2) ? 'bg-gray-200' : 'bg-gray-400'}>
        <td className="px-4 py-2 w-2/12 flex flex-col justify-between">
          {children}
        </td>
        <td className="px-4 py-2 w-1/12">
          <div data-test="queueActionDate"> {newsItem.actionDate} </div>
          {newsItem.user && (
            <div style={{ fontSize: 12 }}> {`Reviewer: ${newsItem.user}`}</div>
          )}
        </td>
        <td className="px-4 py-2 w-1/12">
          <div data-test="queueArticleDate"> {newsItem.articleDate} </div>
        </td>
        <td className="px-4 py-2 w-1/12">
          {`${mapTypeToString(newsItem.type)} (${newsItem.source})`}
        </td>
        <td data-test="queueNewsSource" className="px-4 py-2 w-1/12">
          {' '}
          {newsItem.newsSource}{' '}
        </td>
        <td data-test="queueTitle" className="px-4 py-2 w-1/12 break-words">
          <a
            className="text-blue-500 hover:underline hover:text-blue-600"
            href={newsItem.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {newsItem.title}
          </a>
        </td>
        <td
          data-test="queueContent"
          className="px-4 py-2 w-5/12 break-words"
          style={{ maxWidth: '300px', overflowX: 'scroll' }}
        >
          {' '}
          {newsItem.content}{' '}
        </td>
      </tr>
    );
  }
}

ManagementQueueRow.propTypes = {
  newsItem: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

export default ManagementQueueRow;
