import React from 'react';
import PropTypes from 'prop-types';
import { ChipField } from './ChipField';
import { ChipSelectionField } from './ChipSelectionField';
import { MultiFieldArray } from '../MultiFieldArray';

const NOP = () => null;

/**
 * @deprecated
 * It's a wrapper around a MultiFieldArray that uses a ChipSelectionField as the Header
 * @param props - {
 * @returns A React component
 */
export function ChipedValueField(props) {
  const {
    size,
    onDelete,
    name,
    inputName,
    label,
    placeholder,
    validate,
    modalTitle,
    modalBody,
    loadOptions,
    options,
    disabled,
    required,
    renderComp,
    inputType,
    prefix,
    deletionPath,
    chipComponent = ChipField,
    onChipClick = () => {},
    shouldHighlightChip = () => false,
    shouldHighlightOption = () => false,
    handleWarning = () => false,
    shouldHideChips = false,
    generateValue = (value) => value,
    isCreatable = false,
    disableCreate = false,
    customStyles = '',
    onCreateOption,
    SecondHeaderComponent = () => '',
    valuesContainerClass = 'flex flex-row flex-wrap -p-1',
  } = props;

  // eslint-disable-next-line react/prop-types
  const _ChipSelectField = ({ insert }) => (
    <React.Fragment>
      <ChipSelectionField
        disabled={disabled}
        insert={insert}
        name={name}
        inputName={inputName}
        {...{
          loadOptions,
          options,
          placeholder,
          label,
          validate,
          renderComp,
          inputType,
          shouldHighlightOption,
          handleWarning,
          generateValue,
          isCreatable,
          onCreateOption,
          disableCreate,
          customStyles,
          required,
        }}
      />
      <SecondHeaderComponent />
    </React.Fragment>
  );

  return (
    <MultiFieldArray
      name={name}
      prefix={prefix}
      deletionPath={deletionPath}
      size={size}
      valuesContainerClass={valuesContainerClass}
      RowActions={NOP}
      onDelete={onDelete}
      fieldComponent={chipComponent}
      modalTitle={modalTitle}
      modalBody={modalBody}
      Header={_ChipSelectField}
      onChipClick={onChipClick}
      shouldHighlightChip={shouldHighlightChip}
      shouldHideRows={shouldHideChips}
    />
  );
}

ChipedValueField.propTypes = {
  size: PropTypes.string,
  onDelete: PropTypes.func,
  name: PropTypes.string.isRequired,
  inputName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.string,
  loadOptions: PropTypes.func,
  renderComp: PropTypes.object,
  inputType: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  disableCreate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  prefix: PropTypes.string,
  deletionPath: PropTypes.string,
  onChipClick: PropTypes.func,
  shouldHighlightChip: PropTypes.func,
  shouldHighlightOption: PropTypes.func,
  handleWarning: PropTypes.func,
  shouldHideChips: PropTypes.bool,
  isCreatable: PropTypes.bool,
  chipComponent: PropTypes.node,
  generateValue: PropTypes.func,
  onCreateOption: PropTypes.func,
  customStyles: PropTypes.string,
  valuesContainerClass: PropTypes.string,
  SecondHeaderComponent: PropTypes.func,
};

ChipedValueField.defaultProps = {
  size: 'FULL',
  onDelete: NOP,
  inputName: '',
  placeholder: '',
  validate: NOP,
  modalTitle: null,
  modalBody: null,
  loadOptions: null,
  renderComp: null,
  inputType: 'text',
  disabled: false,
  required: false,
};
