import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import { updateRelatedMarkets } from 'client/modules/markets/redux/actions/data';
import { APPROVED } from 'client/modules/markets/redux/actions/record-status-filters';

class ApproveIconCell extends Component {
  handleApprove = (id) => () => {
    const [recordId] = id.split('-');
    this.props.updateRelatedMarkets(recordId, APPROVED);
  };

  render() {
    const { id, status, disabled } = this.props;
    return (
      <BaseIcon
        dataTest={`${id}`}
        disabled={status === APPROVED || disabled}
        handleOnClick={this.handleApprove(id)}
        iconClassName="approveIcon"
        iconName="Thumb Up"
        iconText="Approve"
      />
    );
  }
}

ApproveIconCell.propTypes = {
  id: PropTypes.string,
  updateRelatedMarkets: PropTypes.func.isRequired,
  status: PropTypes.string,
  disabled: PropTypes.bool,
};

ApproveIconCell.defaultProps = {
  id: '',
  status: '',
  disabled: false,
};

export default connect(null, { updateRelatedMarkets })(ApproveIconCell);
