import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatUtcDateToLocal } from 'client/modules/common/utils/timeFormattingMethods';

class ReadOnlyDate extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, record, parentId } = this.props;
    const [recordId] = id.split('-');
    return (
      <div data-test={`${parentId}-${recordId}-news-date`}>
        {formatUtcDateToLocal(record.newsDate, 'MM/DD/YYYY')}
      </div>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');
  const {parentId} = ownProps;
  const innerTable = markets.tabData.marketSizingDetails.innerTable[parentId];

  return {
    record: innerTable.innerTableRecords[recordId],
  };
}

ReadOnlyDate.propTypes = {
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ReadOnlyDate);
