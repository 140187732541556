import React from 'react';
import PropTypes from 'prop-types';
import { Table as MuiTable } from 'client/component-library/Table';

/**
 * @deprecated
 * It takes in a `children` prop and a `className` prop, and returns a `<MuiTable>` component with the
 * `className` prop passed to it
 * @returns A table with the children and className passed in as props.
 */
const Table = ({ children, className }) => {
  return <MuiTable className={className}>{children}</MuiTable>;
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
};

Table.defaultProps = {
  className: '',
};

export default Table;
