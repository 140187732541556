import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import TableFooter from 'client/modules/common/components/Table/TableComponents/TableFooter';
import smoothScroll from 'client/modules/common/utils/smoothScroll';
import styles from 'client/component-library/Input/input.css';

import IconButton from '@material-ui/core/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Tooltip from 'client/component-library/Tooltip/Tooltip';
import Button from 'client/component-library/buttons/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';

import { withReactRouter } from 'client/routes/withReactRouter';
import DataPlatformWrapper from '../components/DataPlatformWrapper';
import AddPipeline from '../components/AddPipeline';
import {
  fetchPipelineList,
  fetchEntireGraph,
  setPipeline,
  setComponent,
  setFilterByCallingUser,
} from '../redux/actions/search';
import {
  updateSortOrder,
  updatePageNumber,
  updateRecordsPerPage,
} from '../redux/actions/details';
import { pipelineListColumns } from '../components/Table/utils/table-columns';
import { PIPELINE_GRAPH_ROUTE } from '../components/utils/get-tab-options';
import iconButtonStyle from './styles/material-ui/buttonStyle';
import {
  tableHeaderStyle,
  tableBodyStyle,
  tableFooterStyle,
} from './styles/material-ui/tableStyles';

const { iconClassPossitionHack } = styles;

class PipelineList extends Component {
  state = {
    confirmationOpen: false,
  };

  UNSAFE_componentWillMount() {
    /* This is a hack to get around the fact that the query params are not
            available in the componentDidMount method.
            We need to set the pipeline name in the componentDidMount method,
            but the query params are not available until after the componentDidMount
            method is called.
            This is a hack to get around that.
            It's not ideal, but it works.
            The query params are available in the componentDidUpdate method.
            This is the method that is called after the componentDidMount method.
            This is the method that we want to use to set the pipeline name.
            The reason we can't use the componentDidUpdate method is because
            the query params are not available in the componentDidUpdate method.
            The query params are available in the componentDidMount method.
            The query params are available in the componentDidMount method because
            the componentDidMount method is called after the componentDidUpdate
            method.
            The componentDidUpdate method is called after the componentDidMount
            method.
            The componentDidMount method is called after the componentDidUpdate */
    const {queryParams} = this.props;
    if (queryParams.pipelineName && queryParams.pipelineName.length) {
      this.props.setPipeline(queryParams.pipelineName);
    }
    if (queryParams.componentId && queryParams.componentId.length) {
      this.props.setComponent(queryParams.componentId);
    }
  }

  componentDidMount() {
    const {
      pipelines,
      sortField,
      sortDirection,
      recordsPerPage,
      pageNumber,
      filterByCallingUser,
    } = this.props;
    if (!pipelines.records) {
      this.props.fetchPipelineList({
        sortField,
        sortDirection,
        recordsPerPage,
        pageNumber,
        filterByCallingUser,
      });
    }
  }

  onClickShowAll = () => {
    this.setState({ confirmationOpen: true });
  };

  handleSortClick = (sortField, sortDirection) => () => {
    const { recordsPerPage, pageNumber, filterByCallingUser } = this.props;
    this.props.updateSortOrder(sortField, sortDirection);
    this.props.fetchPipelineList({
      sortField,
      sortDirection,
      recordsPerPage,
      pageNumber,
      filterByCallingUser,
    });
  };

  handleChangePageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    const {
      sortField,
      sortDirection,
      recordsPerPage,
      filterByCallingUser,
    } = this.props;
    this.props.updatePageNumber(page);
    this.props.fetchPipelineList({
      sortField,
      sortDirection,
      recordsPerPage,
      pageNumber: page,
      filterByCallingUser,
    });
    setTimeout(() => {
      smoothScroll({ destination: 0 });
    }, 200);
  };

  handleUpdateRecordsPerPage = (event) => {
    const { sortField, sortDirection, filterByCallingUser } = this.props;
    this.props.updateRecordsPerPage(event.target.value);
    this.props.updatePageNumber(0);
    this.props.fetchPipelineList({
      sortField,
      sortDirection,
      recordsPerPage: event.target.value,
      pageNumber: 0,
      filterByCallingUser,
    });
  };

  fetchEntireGraph = () => {
    this.props.fetchEntireGraph();
    this.props.router.push(`/data-platform/${PIPELINE_GRAPH_ROUTE}`);
  };

  closeConfirmation = () => {
    this.setState({ confirmationOpen: false });
  };

  toggleSetFilterByCallingUser = () => {
    const {
      sortField,
      sortDirection,
      recordsPerPage,
      filterByCallingUser,
    } = this.props;
    this.props.setFilterByCallingUser(!filterByCallingUser);
    this.props.updatePageNumber(0);
    this.props.fetchPipelineList({
      sortField,
      sortDirection,
      recordsPerPage,
      pageNumber: 0,
      filterByCallingUser: !filterByCallingUser,
    });
  };

  toggleSetFilterByCallingUserOnStyle = {
    ...iconButtonStyle,
    backgroundColor: '#006699',
  };

  toggleSetFilterByCallingUserOffStyle = {
    ...iconButtonStyle,
    backgroundColor: '#a2a0a0',
  };

  render() {
    return (
      <DataPlatformWrapper>
        <div className="flex justify-end">
          <div className="flex flex-shrink">
            <Tooltip title="Show Only My Pipelines">
              <IconButton
                style={
                  this.props.filterByCallingUser
                    ? this.toggleSetFilterByCallingUserOnStyle
                    : this.toggleSetFilterByCallingUserOffStyle
                }
                onClick={this.toggleSetFilterByCallingUser}
                // buttonRef={node => { this.anchorEl = node; }}
              >
                {this.props.filterByCallingUser ? (
                  <Icon
                    name="visibility"
                    family="material"
                    className={iconClassPossitionHack}
                  />
                ) : (
                  <Icon
                    name="visibility_off"
                    family="material"
                    className={iconClassPossitionHack}
                  />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className="flex flex-shrink">
            <Tooltip title="Show All Components">
              <IconButton
                style={{ ...iconButtonStyle, backgroundColor: '#4cabce' }}
                onClick={this.onClickShowAll}
                // buttonRef={node => { this.anchorEl = node; }}
              >
                <Icon
                  name="timeline"
                  family="material"
                  className={iconClassPossitionHack}
                />
              </IconButton>
            </Tooltip>
            <Dialog
              open={this.state.confirmationOpen}
              onClose={this.closeConfirmation}
            >
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Loading the entire graph may be slow. Are you sure you want to
                  view it?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.fetchEntireGraph} color="primary">
                  Ok
                </Button>
                <Button
                  onClick={this.closeConfirmation}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="flex flex-shrink">
            <AddPipeline
              pageNumber={this.props.pageNumber}
              sortField={this.props.sortField}
              sortDirection={this.props.sortDirection}
              recordsPerPage={this.props.recordsPerPage}
            />
          </div>
        </div>
        <Table>
          <TableHeader
            columnNames={pipelineListColumns}
            sortField={this.props.sortField}
            sortDirection={this.props.sortDirection}
            onSortClick={this.handleSortClick}
            tHeaderStyles={tableHeaderStyle}
          />
          <TableBody
            records={this.props.pipelines.records}
            columnNames={pipelineListColumns}
            recordsOrder={this.props.pipelines.order}
            tBodyStyles={tableBodyStyle}
          />
          <TableFooter
            totalRecords={this.props.pipelines.total}
            recordsPerPage={this.props.recordsPerPage}
            pageNumber={this.props.pageNumber}
            handleChangePageNumber={this.handleChangePageNumber}
            handleUpdateRecordsPerPage={this.handleUpdateRecordsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            tFooterStyles={tableFooterStyle}
          />
        </Table>
      </DataPlatformWrapper>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    pipelines: dataPlatform.search.pipelines,
    sortDirection: dataPlatform.details.sortInfo.sortDirection,
    sortField: dataPlatform.details.sortInfo.sortField,
    recordsPerPage: dataPlatform.details.recordsPerPage,
    pageNumber: dataPlatform.details.pageNumber,
    filterByCallingUser: dataPlatform.search.filterByCallingUser,
  };
}

PipelineList.propTypes = {
  fetchPipelineList: PropTypes.func.isRequired,
  pipelines: PropTypes.object,
  updateSortOrder: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  updatePageNumber: PropTypes.func.isRequired,
  updateRecordsPerPage: PropTypes.func.isRequired,
  fetchEntireGraph: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  setPipeline: PropTypes.func.isRequired,
  setComponent: PropTypes.func.isRequired,
  filterByCallingUser: PropTypes.bool.isRequired,
  setFilterByCallingUser: PropTypes.func.isRequired,
};

PipelineList.defaultProps = {
  pipelines: {},
  sortField: 'pipeline_name',
  sortDirection: 'asc',
  filterByCallingUser: false,
};

export default compose(
  connect(mapStateToProps, {
    fetchPipelineList,
    updateSortOrder,
    updatePageNumber,
    updateRecordsPerPage,
    setComponent,
    setPipeline,
    fetchEntireGraph,
    setFilterByCallingUser,
  }),
  withReactRouter
)(PipelineList);
