import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'client/component-library/styles';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import { NEEDS_APPROVAL } from 'client/modules/markets/redux/actions/record-status-filters';
import styles from '../styles/material-ui/InnerTableMarketNameCell';

class InnerTableMarketNameCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, classes, record, parentId } = this.props;
    const [recordId] = id.split('-');

    const labelClass = { label: classes.label };

    const iconColor =
      record.status === NEEDS_APPROVAL
        ? classes.iconWrapperOrange
        : classes.iconWrapperRed;

    return (
      <div className={classes.root}>
        <div>
          <BaseIcon
            customIconClass={classes.icon}
            customIconButtonClass={classes.iconButton}
            customSpanClass={classes.text}
            customWrapperClass={iconColor}
            dataTest={`${id}-duplicate-alert`}
            iconName="report_problem"
            iconText={record.status}
            isIconBlock={false}
            classes={labelClass}
            customWrapperStyles={{ display: 'inline-block' }}
            disableRipple
          />
        </div>
        <div
          className={classes.marketName}
          data-test={`${parentId}-${recordId}-market-name`}
        >
          {record.market}
        </div>
        <div
          className={classes.textBlock}
          data-test={`${parentId}-${recordId}-record-id`}
        >
          {`ID: ${recordId}`}
        </div>
        {record.description && (
          <div
            className={classes.textBlock}
            data-test={`${parentId}-${recordId}-description`}
          >
            {record.description}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');
  const {parentId} = ownProps;
  const innerTable = markets.tabData.marketSizingDetails.innerTable[parentId];

  return {
    record: innerTable.innerTableRecords[recordId],
  };
}

InnerTableMarketNameCell.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps)(InnerTableMarketNameCell)
);
