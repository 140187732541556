import { keyBy, map, assign, find } from 'lodash';
import {
  getAllFeaturesRequest,
  getFeatureDescriptionsRequest,
} from '../utils/api-request-helper';

export const FETCH_ALL_PACKAGE_FEATURES =
  'client/modules/packages/actions/FETCH_ALL_PACKAGE_FEATURES';
export const SET_FEATURE_DESCRIPTIONS =
  'client/modules/packages/actions/SET_FEATURE_DESCRIPTIONS';

// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/35091975/how-to-use-lodash-to-merge-two-collections-based-on-a-key?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const mapFeaturesWithDescription = (features, featureDescriptions) => {
  return map(features, (obj) => {
    // add the properties from second array matching the userID
    // to the object from first array and return the updated object
    return assign(obj, find(featureDescriptions, { id: obj.id }));
  });
};

export const formatFeatureDescriptions = (features, featureDescriptions) => {
  return keyBy(mapFeaturesWithDescription(features, featureDescriptions), 'id');
};

export const setFeatureDescriptions = (descriptions, currentTab) => {
  return { type: SET_FEATURE_DESCRIPTIONS, descriptions, currentTab };
};

export const getAllFeatures = () => {
  return (dispatch, getState) => {
    if (getState().packages.featuresInfo.length) {
      return null;
    }
    return dispatch(getAllFeaturesRequest()).then((features) => {
      dispatch(getFeatureDescriptionsRequest()).then((featureDescriptions) => {
        const descriptions = formatFeatureDescriptions(
          features.features,
          featureDescriptions.featureDescriptions
        );
        const {currentTab} = getState().packages;
        // Why we need this?
        dispatch(setFeatureDescriptions(descriptions, currentTab));
      });
    });
  };
};
