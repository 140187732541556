import { getMultiKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import * as tabName from 'client/modules/cbi-entity/utils/tab-names';
import * as actions from '../actions/tab-details';
import {
  combineCompanyWithInvestor,
  // addEntityType,
  combineSuggestions,
} from './search-bar';

const initialState = {
  [tabName.GENERAL_INFO]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {},
    editingDisabled: false,
  },
  [tabName.FUNDINGS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {},
    editingDisabled: false,
  },
  [tabName.COMPETITORS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
    searchSuggestions: [],
    currentSearchTerm: '',
  },
  [tabName.VC_FUNDS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
  },
  [tabName.EMPLOYEES]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
  },
  [tabName.SALES_METRICS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
  },
};

export const getSortOrder = (sortField, sortDirection) => {
  return sortField
    ? { sortField, sortDirection: sortDirection || 'asc' }
    : null;
};

export const updateSortOrder = (sortField, sortDirection) => {
  return { sortField, sortDirection };
};

// when update pagnation, need to make an api call, and put the data back into the state!
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_COMPETITOR_SEARCH_SUGGESTIONS: {
      const combinedSuggestions = combineSuggestions(
        action.suggestions,
        action.recentlyCreatedCompany,
        action.recentlyCreatedInvestor
      );
      const suggestions = combineCompanyWithInvestor(
        combinedSuggestions,
        action.searchTerm
      );
      const [
        searchId,
        entityType,
      ] = getMultiKeyValueFromListViaOtherKey(
        suggestions,
        'name',
        action.searchTerm,
        ['id', 'entityType']
      );
      return {
        ...state,
        competitors: {
          ...state.competitors,
          searchSuggestions: suggestions,
          searchId: parseInt(searchId, 10) || 0,
          entityType,
        },
      };
    }
    default:
      return state;
  }
}
