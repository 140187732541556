/* eslint-disable prefer-destructuring */
/* eslint-disable no-else-return */
import { omit, map, isEmpty, forEach } from 'lodash';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import unabbreviateNumber from 'client/modules/common/utils/unabbreviateNumber';
import {
  setSuccessNotification,
  setFailureNotification,
} from 'client/modules/common/redux/actions/status-notification';
import { setModifiedRecordsDiff } from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/modified-records';
import {
  getCurrentTab,
  getCurrentTabData,
  getSelectedRecords,
  getNews,
  getRecordsInfo,
  getStatus,
  getCurrentUser,
  getMarketInfo,
  getGeoInfo,
  getYearValue,
  getAnalystInfo,
  getModifiedRecords,
  getMergeConflicts,
} from '../utils/records-helper';
import {
  marketSuggestions,
  deleteRelatedMarketsRequest,
  getDuplicateMarketSizingsRequest,
} from '../utils/api-requests-helper';
import { validateRecords } from '../utils/validate-records';
import {
  NEEDS_APPROVAL,
  REJECTED,
  SAVED,
  REJECTED_CODE,
  IS_DUPLICATE,
  APPROVED,
} from './record-status-filters';
import { makeSearch, initializeAutocompleteInput } from './search-bar';
import { mapMarketSizingDetailResponse } from '../utils/api-response-helper';
import { setMergeConflictData } from './setMergeConflictData';

export const ADD_NEW_RECORD_LINE =
  'client/modules/markets/data/ADD_NEW_RECORD_LINE';
export const ADD_RELATED_MARKET =
  'client/modules/markets/data/ADD_RELATED_MARKET';
export const CLEAR_NEW_RECORDS =
  'client/modules/markets/data/CLEAR_NEW_RECORDS';
export const DELETE_NEW_RECORDS =
  'client/modules/markets/data/DELETE_NEW_RECORDS';
export const SELECT_RECORD = 'client/modules/markets/data/SELECT_RECORD';
export const SELECT_ALL_RECORDS =
  'client/modules/markets/data/SELECT_ALL_RECORDS';
export const SAVE_NEW_RECORDS = 'client/modules/markets/data/SAVE_NEW_RECORDS';
export const SET_RECORDS_VALIDATION =
  'client/modules/markets/data/SET_RECORDS_VALIDATION';
export const UNSELECT_ALL_RECORDS =
  'client/modules/markets/data/UNSELECT_ALL_RECORDS';
export const UPDATE_NEW_RECORD =
  'client/modules/markets/data/UPDATE_NEW_RECORD';
export const UPDATE_RECORD = 'client/modules/markets/data/UPDATE_RECORD';
export const UPDATE_RELATED_MARKETS_SUGGESTIONS =
  'client/modules/markets/data/UPDATE_RELATED_MARKETS_SUGGESTIONS';
export const UPDATE_SEARCH_INFO =
  'client/modules/markets/data/UPDATE_SEARCH_INFO';
export const TOGGLE_INNER_TABLE =
  'client/modules/markets/data/TOGGLE_INNER_TABLE';
export const SET_DUPLICATE_RECORDS =
  'client/modules/markets/data/SET_DUPLICATE_RECORDS';

export const saveMarketSizingDetails = (data) => ({
  types: [null, null],
  service: {
    endpoint: 'saveMarketSizingDetails',
    name: 'marketservice',
    body: {
      rows: data,
    },
  },
});

export const mapMarketSizingDetailRequest = (
  records,
  status,
  userId,
  autoCompleteInputValues
) => {
  return map(records, (record, id) => {
    return {
      id: parseInt(record.id || 0, 10),
      market: getMarketInfo(record, id, autoCompleteInputValues),
      news: getNews(record),
      startSize: unabbreviateNumber(record.startSize) || 0,
      endSize: unabbreviateNumber(record.endSize) || 0,
      startYear: getYearValue(record.startYear),
      endYear: getYearValue(record.endYear),
      cagr: parseFloat(record.cagr * 1),
      geo: getGeoInfo(record, id, autoCompleteInputValues),
      analyst: getAnalystInfo(record, id, autoCompleteInputValues),
      comment: record.reviewerNote,
      modificationHash: record.modificationHash,
      userId,
      marketDescription: record.description,
      status,
      tmpKeyId: id,
      sentence: record.sentence,
    };
  });
};

export const mapRelatedMarketsRequest = (relatedMarkets, status) => {
  return [
    {
      id: parseInt(relatedMarkets.id, 10) || 0,
      modificationHash: relatedMarkets.modificationHash || '',
      parent: {
        id: parseInt(relatedMarkets.parentId, 10) || 0,
        name: relatedMarkets.parentMarket,
      },
      child: {
        id: parseInt(relatedMarkets.childId, 10) || 0,
        name: relatedMarkets.childMarket,
      },
      status,
    },
  ];
};

export const updateMarketSuggestionsHelper = (searchTerm, markets) => {
  return { type: UPDATE_RELATED_MARKETS_SUGGESTIONS, searchTerm, markets };
};

export const saveRelatedMarkets = (data) => ({
  types: [null, null],
  service: {
    endpoint: 'SaveRelatedMarkets',
    name: 'marketservice',
    body: {
      rows: data,
    },
  },
});

export function updateRecord(recordId, fieldName, newValue) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_RECORD,
      recordId,
      fieldName,
      newValue,
      currentTab,
    };
  });
}

// Update selected record (which records are checked)
export function selectRecord(recordId) {
  return getCurrentTab((currentTab) => {
    return {
      type: SELECT_RECORD,
      recordId,
      currentTab,
    };
  });
}

export function selectAllRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: SELECT_ALL_RECORDS,
      currentTab,
    };
  });
}

export function unselectAllRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: UNSELECT_ALL_RECORDS,
      currentTab,
    };
  });
}

export function addNewRecordLine() {
  return getCurrentTab((currentTab) => {
    return {
      type: ADD_NEW_RECORD_LINE,
      currentTab,
    };
  });
}

export function updateNewRecord(newRecordId, fieldName, newValue) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_NEW_RECORD,
      newRecordId,
      fieldName,
      newValue,
      currentTab,
    };
  });
}

export function clearNewRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: CLEAR_NEW_RECORDS,
      currentTab,
    };
  });
}

export function deleteNewRecords(id) {
  return getCurrentTab((currentTab) => {
    return {
      type: DELETE_NEW_RECORDS,
      currentTab,
      id,
    };
  });
}

export function setRecordsValidation(recordsValidation) {
  return {
    type: SET_RECORDS_VALIDATION,
    recordsValidation,
  };
}

export function updateSearchInfo(searchId, searchTerm) {
  return {
    type: UPDATE_SEARCH_INFO,
    searchTerm,
    searchId,
  };
}

export function updateRelatedMarkets(id, action) {
  return (dispatch, getState) => {
    const { currentTabData, recordStatusFilters } = getCurrentTabData(getState);
    const relatedMarkets = currentTabData.records[id];
    const status = getKeyValueFromListViaOtherKey(
      recordStatusFilters.recordStatusFilterOptions,
      'text',
      action,
      'id'
    );
    const dataToPost = mapRelatedMarketsRequest(relatedMarkets, status);
    dispatch(saveRelatedMarkets(dataToPost)).then(
      () => {
        dispatch(setSuccessNotification(`Market ${action}!`));
        dispatch(makeSearch());
      },
      () => {
        dispatch(setFailureNotification(`Failed to ${action} market!`));
      }
    );
  };
}

export function updateRelatedMarketsSuggestions(searchTerm) {
  return (dispatch) => {
    dispatch(marketSuggestions(searchTerm)).then((data) => {
      dispatch(updateMarketSuggestionsHelper(searchTerm, data.markets));
    });
  };
}

export function addRelatedMarket(searchId, searchTerm) {
  return (dispatch, getState) => {
    const searchInfo = getState().markets.searchBar;
    const recordStatusFilters = getState().markets.recordStatusFilters;

    // Validate search params
    if (!searchId) {
      return dispatch(
        setFailureNotification('The selected market does not exist.')
      );
    } else if (
      !searchInfo ||
      !searchInfo.selectedSearchId ||
      !searchInfo.selectedSearchName
    ) {
      return dispatch(
        setFailureNotification('You must select a related market first!')
      );
    }

    const status = getKeyValueFromListViaOtherKey(
      recordStatusFilters.recordStatusFilterOptions,
      'text',
      NEEDS_APPROVAL,
      'id'
    );
    const relatedMarkets = {
      parentId: searchInfo.selectedSearchId,
      parentMarket: searchInfo.selectedSearchName,
      childId: searchId,
      childMarket: searchTerm,
    };
    const data = mapRelatedMarketsRequest(relatedMarkets, status);

    return dispatch(saveRelatedMarkets(data)).then(
      () => {
        dispatch(makeSearch());
        dispatch(updateSearchInfo(0, '')); // remove the text in the search box
        dispatch(
          setSuccessNotification('New related market successfully added!')
        );
      },
      () => {
        dispatch(
          setFailureNotification(
            'Failed to add new related market, please try again.'
          )
        );
      }
    );
  };
}

export function saveNewRecords(callback) {
  return (dispatch, getState) => {
    const { currentTabData, recordStatusFilters } = getCurrentTabData(getState);
    const { user } = getCurrentUser(getState);
    const autoCompleteInputValues = getState().table.autocompleteInput
      .inputValues;

    const status = getKeyValueFromListViaOtherKey(
      recordStatusFilters.recordStatusFilterOptions,
      'text',
      APPROVED,
      'id'
    );

    const data = mapMarketSizingDetailRequest(
      currentTabData.newRecords,
      status,
      user.data.id,
      autoCompleteInputValues
    );

    const invalidCells = validateRecords(data);
    if (isEmpty(invalidCells) && !isEmpty(data)) {
      // use omit to remove tmp tmpKeyId
      dispatch(
        saveMarketSizingDetails(map(data, (d) => omit(d, 'tmpKeyId')))
      ).then(
        (response) => {
          if (!response.success) {
            const errorMessage =
              response.message || 'Failed to create new records';
            dispatch(setFailureNotification(errorMessage));
          } else {
            callback();
            dispatch(setSuccessNotification('Records saved successfully!'));
            dispatch(clearNewRecords());
            dispatch(makeSearch());
          }
        },
        (error) => {
          const errorMessage =
            error.details || error.message || 'Failed to create new records';
          dispatch(setFailureNotification(errorMessage));
        }
      );
    }
    dispatch(setRecordsValidation(invalidCells));
  };
}

export function handleMergeConflicts(failedRows, recordsToPost, updatedStatus) {
  return (dispatch) => {
    const mappedRows = mapMarketSizingDetailResponse(failedRows);
    const mergeConflicts = getMergeConflicts(mappedRows, recordsToPost);
    if (isEmpty(mergeConflicts)) {
      dispatch(
        setSuccessNotification(
          `Records ${
            updatedStatus ? updatedStatus.toLowerCase() : 'saved'
          } successfully!`
        )
      );
      return dispatch(makeSearch());
    }
    dispatch(initializeAutocompleteInput(failedRows));
    dispatch(setMergeConflictData(mappedRows, mergeConflicts, updatedStatus));
    return dispatch({
      type:
        'client/modules/markets/actions/merge-conflict/OPEN_MERGE_CONFLICT_MODAL',
    });
  };
}

// NOTE: this is called by updateRecords and updateIndividualRecord
export function updateRecordsHelper(
  updatedStatus,
  selectedIds,
  records,
  originalRecords
) {
  return (dispatch, getState) => {
    const { recordStatusFilters } = getCurrentTabData(getState);
    const autoCompleteInputValues = getState().table.autocompleteInput
      .inputValues;
    const currentStatus = recordStatusFilters.selectedRecordStatusFilterId;

    // Get modified records we are going to post
    let recordsToPost = getRecordsInfo(selectedIds, records);
    const { modified, modifiedRecords } = getModifiedRecords(
      originalRecords,
      recordsToPost
    );

    // For status type of SAVED, we only care about modified records
    if (!modified && updatedStatus === SAVED) {
      return dispatch(setFailureNotification('No records changed!'));
    } else if (!isEmpty(modifiedRecords) && updatedStatus === SAVED) {
      recordsToPost = modifiedRecords;
    }

    // Map and format the market sizing records for posting to the API
    const { user } = getCurrentUser(getState);
    const data = mapMarketSizingDetailRequest(
      recordsToPost,
      getStatus(
        updatedStatus,
        currentStatus,
        recordStatusFilters.recordStatusFilterOptions
      ),
      user.data.id,
      autoCompleteInputValues
    );

    // Get validation errors (as long as we are not rejecting the record)
    let invalidCells = {};
    if (updatedStatus !== REJECTED && updatedStatus !== IS_DUPLICATE) {
      invalidCells = validateRecords(data);
    }

    // Always set validation errors. Note: invalidCells could be an empty object, which just means there are no errors
    dispatch(setRecordsValidation(invalidCells));
    if (!isEmpty(invalidCells)) {
      return dispatch(
        setFailureNotification(
          'Could not save - please fix the validation errors and try again!'
        )
      );
    } else if (isEmpty(data)) {
      return dispatch(setFailureNotification('No records selected!'));
    }

    return dispatch(
      saveMarketSizingDetails(map(data, (d) => omit(d, 'tmpKeyId')))
    ).then(
      (response) => {
        if (
          !response.success &&
          response.failedRows &&
          response.failedRows.length === 0
        ) {
          dispatch(setFailureNotification(response.message));
        } else if (!response.success) {
          dispatch(
            handleMergeConflicts(
              response.failedRows,
              recordsToPost,
              updatedStatus
            )
          );
        } else {
          let successMessage = `Records ${
            updatedStatus ? updatedStatus.toLowerCase() : 'saved'
          } successfully!`;
          if (
            updatedStatus === NEEDS_APPROVAL &&
            currentStatus === REJECTED_CODE
          ) {
            successMessage = 'Records restored successfully!';
          }
          dispatch(setSuccessNotification(successMessage));
          dispatch(makeSearch());
        }
      },
      () => {
        dispatch(
          setFailureNotification('Failed to update records, please try again.')
        );
      }
    );
  };
}

// NOTE: this is called by toolbar actions
export function updateRecords(updatedStatus) {
  return (dispatch, getState) => {
    const { currentTabData } = getCurrentTabData(getState);

    // Check if there are records changed
    const records = currentTabData.records;
    const originalRecords = currentTabData.originalRecords;
    if (isEmpty(originalRecords) && updatedStatus === SAVED) {
      return dispatch(setFailureNotification('No records changed!'));
    }

    // Get all the selected ids
    const selectedIds = getSelectedRecords(currentTabData.selectedRecords);
    if (isEmpty(selectedIds)) {
      return dispatch(setFailureNotification('No record selected!'));
    }

    return dispatch(
      updateRecordsHelper(updatedStatus, selectedIds, records, originalRecords)
    );
  };
}

export const updateRecordSuccessMsg = (updatedStatus) => {
  return updatedStatus === IS_DUPLICATE
    ? 'marked as a duplicate'
    : updatedStatus.toLowerCase();
};

// NOTE: this is called by inline actions
export function updateIndividualRecord(recordId, updatedStatus, parentId) {
  return (dispatch, getState) => {
    const { currentTabData } = getCurrentTabData(getState);

    // Check if the record changed
    let records;
    if (parentId) {
      records = currentTabData.innerTable[parentId].innerTableRecords;
    } else {
      records = currentTabData.records;
    }

    let originalRecords;
    if (parentId) {
      originalRecords =
        currentTabData.innerTable[parentId].innerTableOriginalRecords;
    } else {
      originalRecords = currentTabData.originalRecords;
    }

    if (isEmpty(originalRecords) && updatedStatus === SAVED) {
      return dispatch(setFailureNotification('No records changed!'));
    }

    // Get the selected ids
    const selectedIds = [recordId];
    if (isEmpty(selectedIds)) {
      return dispatch(setFailureNotification('No record selected!'));
    }

    return dispatch(
      updateRecordsHelper(updatedStatus, selectedIds, records, originalRecords)
    );
  };
}

export const confirmNoWantedChanges = () => {
  return (dispatch, getState) => {
    const markets = getState().markets;
    const currentTab = markets.currentTab;
    const records = markets.tabData[currentTab].records;
    const originalRecords = markets.tabData[currentTab].originalRecords;

    // Only want to check the records that have been touched...
    const modifiedRecords = {};
    forEach(Object.keys(originalRecords), (recordId) => {
      modifiedRecords[recordId] = records[recordId];
    });

    const { modified, recordsDiffs } = getModifiedRecords(
      originalRecords,
      modifiedRecords
    );
    if (!modified) {
      return true;
    } else if (
      window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      )
    ) {
      return true;
    }
    dispatch(setModifiedRecordsDiff(recordsDiffs));
    return false;
  };
};

export const deletePendingRelatedMarketRecords = (id) => {
  return (dispatch) => {
    dispatch(deleteRelatedMarketsRequest(parseInt(id, 10))).then(
      (response) => {
        if (response.success) {
          dispatch(
            setSuccessNotification(
              'Pending related market removed successfully!'
            )
          );
          dispatch(makeSearch());
        } else {
          dispatch(
            setFailureNotification('Failed to remove pending related market!')
          );
        }
      },
      () => {
        dispatch(
          setFailureNotification('Failed to remove pending related market!')
        );
      }
    );
  };
};

export const updateMarketNameHelper = (id, newName) => ({
  types: [null, null],
  service: {
    endpoint: 'updateMarketName',
    name: 'marketservice',
    body: { id, newName },
  },
});

export const updateMarketName = (id, value) => {
  return (dispatch) => {
    return dispatch(updateMarketNameHelper(parseInt(id, 10), value)).then(
      (response) => {
        if (response.success) {
          dispatch(setSuccessNotification('Updated market name successfully!'));
          dispatch(makeSearch());
        } else {
          dispatch(setFailureNotification('Failed to update market name!'));
        }
        return response.success;
      },
      (error) => {
        let errorMessage = 'Failed to update market name: Unknown Error';
        if (error.details.indexOf('Error 1062: Duplicate entry') !== -1) {
          errorMessage =
            'Failed to update market name: Market names must be unique!';
        }
        dispatch(setFailureNotification(errorMessage));
        return false;
      }
    );
  };
};

export function toggleInnerTable(recordId) {
  return { type: TOGGLE_INNER_TABLE, recordId };
}

export function setDuplicateRecords(innerTableRecords, dupeCount, record) {
  return getCurrentTab((currentTab) => {
    return {
      type: SET_DUPLICATE_RECORDS,
      innerTableRecords,
      dupeCount,
      record,
      currentTab,
    };
  });
}

export const getDuplicates = (recordId) => {
  return (dispatch, getState) => {
    const markets = getState().markets;
    const currentTab = markets.currentTab;
    const currentTabData = markets.tabData[currentTab];

    const record = currentTabData.records[recordId];
    const autoCompleteInputValues = getState().table.autocompleteInput
      .inputValues;

    const id = parseInt(record.id, 10);
    const market = getMarketInfo(record, id, autoCompleteInputValues);
    const startSize = unabbreviateNumber(record.startSize) || 0;
    const startYear = getYearValue(record.startYear);
    const endSize = unabbreviateNumber(record.endSize) || 0;
    const endYear = getYearValue(record.endYear);

    if (record) {
      dispatch(
        getDuplicateMarketSizingsRequest({
          id,
          market,
          startSize,
          startYear,
          endSize,
          endYear,
        })
      ).then((response) => {
        dispatch(
          setDuplicateRecords(response.rows, response.totalResults, record)
        );
      });
    }
  };
};
