import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import { formatStoriesSuggestions } from '../utils/records-helper';
import * as actions from '../actions/search-bars';

const initialState = {
  [UPDATE_ACCESS_BY_STORY]: {
    selectedSearchTerm: '',
    selectedSearchId: 0,
    suggestions: [],
    searchTerm: '',
    searchId: 0,
  },
  [UPDATE_ACCESS_BY_TEAM]: {
    selectedSearchTerm: '',
    selectedSearchId: 0,
    suggestions: [],
    searchTerm: '',
    searchId: 0,
  },
  [MANAGE_SLUGS]: {
    selectedSearchTerm: '',
    selectedSearchId: 0,
    searchTerm: '',
    searchId: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALZE_SEARCH_TERM: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchId:
            parseInt(action.searchTermId, 10) ||
            initialState[action.currentTab].searchId,
          searchTerm:
            action.searchTerm || initialState[action.currentTab].searchTerm,
          selectedSearchId:
            parseInt(action.searchTermId, 10) ||
            initialState[action.currentTab].selectedSearchId,
          selectedSearchTerm:
            action.searchTerm ||
            initialState[action.currentTab].selectedSearchTerm,
        },
      };
    }
    case actions.UPDATE_SEARCH_INFO: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchTerm: action.searchTerm,
          searchId: action.searchId,
        },
      };
    }
    case actions.UPDATE_SUGGESTIONS: {
      const formattedResponse = formatStoriesSuggestions(action.suggestions);
      const filteredStories = filterListOfObjects({
        value: action.searchTerm,
        listOfItems: formattedResponse,
        fields: ['name'],
      });
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          suggestions: filteredStories,
        },
      };
    }
    case actions.UPDATE_TEAM_SUGGESTIONS: {
      const filteredTeams = filterListOfObjects({
        value: action.searchTerm,
        listOfItems: action.suggestions,
        fields: ['name'],
      });
      return {
        ...state,
        [UPDATE_ACCESS_BY_TEAM]: {
          ...state[UPDATE_ACCESS_BY_TEAM],
          suggestions: filteredTeams,
        },
      };
    }
    case actions.UPDATE_SELECTED_SEARCH_TERM: {
      let searchId = getKeyValueFromListViaOtherKey(
        action.suggestions,
        'name',
        action.searchTerm,
        'id'
      );
      searchId = parseInt(searchId, 10) || 0;
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          selectedSearchTerm: action.searchTerm,
          selectedSearchId: action.searchId === 0 ? searchId : action.searchId,
          searchId: action.searchId === 0 ? searchId : action.searchId,
        },
      };
    }
    case actions.UPDATE_SLUG_SEARCH_TERM: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          searchTerm: action.searchTerm,
        },
      };
    }
    default:
      return state;
  }
}
