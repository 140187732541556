// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-AutocompleteDropdown-styles-___fixedDropDownItem__dropdownItem___Nyk1A {
  padding: 2px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.client-modules-common-components-AutocompleteDropdown-styles-___fixedDropDownItem__selected___K716g {
  background-color: #005aae;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/AutocompleteDropdown/styles/fixedDropDownItem.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".dropdownItem {\n  padding: 2px 10px;\n  border-bottom: 1px solid #eee;\n  cursor: pointer;\n}\n\n.selected {\n  background-color: #005aae;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownItem": `client-modules-common-components-AutocompleteDropdown-styles-___fixedDropDownItem__dropdownItem___Nyk1A`,
	"selected": `client-modules-common-components-AutocompleteDropdown-styles-___fixedDropDownItem__selected___K716g`
};
export default ___CSS_LOADER_EXPORT___;
