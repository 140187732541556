const styles = () => ({
  tableCell: {
    verticalAlign: 'inherit',
    color: '#404040',
    fontWeight: 'bold',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '0em 0.4em',
  },
  label: {
    textAlign: 'center',
    float: 'left',
  },
  required: {
    color: 'red',
    fontSize: '0.8rem',
  },
});

/**
 * @deprecated
 */
export default styles;
