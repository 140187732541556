import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'client/component-library/buttons/Button';
import { updateIndividualRecord } from 'client/modules/markets/redux/actions/data';
import {
  IS_DUPLICATE,
  APPROVED,
} from 'client/modules/markets/redux/actions/record-status-filters';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import styles from '../styles/material-ui/InnerTableHistoryCell';

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

class InnerTableIsDuplicateActionIcon extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      open: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleDuplicate = () => {
    const { id, parentId } = this.props;
    const [recordId] = id.split('-');
    this.props.updateIndividualRecord(recordId, IS_DUPLICATE, parentId);
    this.setState({ open: false });
  };

  handleOpenDialog = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { id, classes, record } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Button
            className={classes.marketsBtn}
            variant="contained"
            dataTest={`${id}-mark-as-duplicate`}
            onClick={
              record.status === APPROVED
                ? this.handleOpenDialog
                : this.handleDuplicate
            }
          >
            <span className={classes.btnLabel}>Mark As Dupe</span>
          </Button>
          <Dialog open={this.state.open} onClose={this.handleCloseDialog}>
            <DialogTitle>
              Are you sure you would like to mark an approved forecast record as
              a duplicate?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Doing so will remove it from the CBI platform.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialog}
                color="secondary"
                dataTest="dialog-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleDuplicate}
                color="primary"
                dataTest="dialog-mark-as-duplicate"
              >
                Mark As Dupe
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');
  const { parentId } = ownProps;
  const innerTable = markets.tabData.marketSizingDetails.innerTable[parentId];

  return {
    record: innerTable.innerTableRecords[recordId],
  };
}

InnerTableIsDuplicateActionIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  updateIndividualRecord: PropTypes.func.isRequired,
};

InnerTableIsDuplicateActionIcon.defaultProps = {
  id: '',
};

export default withStyles(styles)(
  connect(mapStateToProps, { updateIndividualRecord })(
    InnerTableIsDuplicateActionIcon
  )
);
