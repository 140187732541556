import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatUtcDateToLocal } from 'client/modules/common/utils/timeFormattingMethods';
import createFullName from 'client/modules/common/utils/createFullName';
import { withStyles } from 'client/component-library/styles';
import InnerTableIsDuplicateActionIcon from './InnerTableIsDuplicateActionIcon';
import styles from '../styles/material-ui/InnerTableHistoryCell';

class InnerTableHistoryCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, classes, record, parentId } = this.props;
    const [recordId] = id.split('-');

    if (record.reviewer.firstName || record.reviewer.lastName) {
      const fullName = createFullName(
        record.reviewer.firstName,
        record.reviewer.lastName
      );
      return (
        <div>
          <div
            className={classes.textBlock}
            data-test={`${parentId}-${recordId}-reviewer`}
          >
            <span className={classes.bold}>Reviewer: </span>
            {fullName}{' '}
            {formatUtcDateToLocal(record.reviewDate, 'YYYY-MM-DD HH:mm:ss')}
          </div>
          {record.reviewerNote && (
            <div
              className={classes.textBlock}
              data-test={`${parentId}-${recordId}-reviewer-note`}
            >
              <span className={classes.bold}>Reviewer notes: </span>
              {record.reviewerNote}
            </div>
          )}
          <InnerTableIsDuplicateActionIcon
            id={id}
            parentId={parentId}
            record={record}
            classes={classes}
          />
        </div>
      );
    }

    return (
      <div>
        <div>
          <span className={classes.bold}>Reviewer: </span>none
        </div>
        <InnerTableIsDuplicateActionIcon
          id={id}
          parentId={parentId}
          record={record}
          classes={classes}
        />
      </div>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const [recordId] = ownProps.id.split('-');
  const {parentId} = ownProps;
  const innerTable = markets.tabData.marketSizingDetails.innerTable[parentId];

  return {
    record: innerTable.innerTableRecords[recordId],
  };
}

InnerTableHistoryCell.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

InnerTableHistoryCell.defaultProps = {
  id: '',
};

export default withStyles(styles)(
  connect(mapStateToProps, {})(InnerTableHistoryCell)
);
