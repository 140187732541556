import { Navigate, RouteObject } from 'react-router-dom';

import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import React from 'react';
import { CbiEntityRoutes } from './RoutesHelpers';
import { ENTITY_ADMIN_PERMISSIONS } from './permissions/EntityAdmin';
import { Financials } from './containers/Financials';

const GeneralInfoWrapper = React.lazy(
  () => import('./containers/GeneralInfoWrapper')
);
const CBIEntityHomeOutletPage = React.lazy(
  () => import('./containers/pages/CBIEntityHomeOutletPage')
);
const Fundings = React.lazy(() => import('./containers/FundingsWrapper'));
const SalesMetrics = React.lazy(() => import('./containers/SalesMetrics'));
const VcFunds = React.lazy(() => import('./containers/VcFunds'));
const Competitors = React.lazy(
  () => import('./containers/pages/competitors/CBIEntityCompetitorsPage')
);
const Employees = React.lazy(() => import('./containers/Employees'));
const CBINewEntitySearchPage = React.lazy(
  () => import('./containers/pages/CBINewEntitySearchPage')
);
const CBIEntityHomePage = React.lazy(
  () => import('./containers/pages/CBIEntityHomePage')
);
const NewEntity = React.lazy(
  () => import('./containers/pages/general-info/CBINewEntityPage')
);

export const cbiEntityRoutes: RouteObject = {
  path: CbiEntityRoutes.PageIndex,
  element: (
    <PermissionsProtectedRoute
      pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
      currentPath={CbiEntityRoutes.PageIndex}
    />
  ),
  children: [
    {
      path: `/${CbiEntityRoutes.PageIndex}`,
      element: (
        <Navigate
          to={`/${CbiEntityRoutes.PageIndex}/${CbiEntityRoutes.Home}`}
        />
      ),
    },
    {
      path: CbiEntityRoutes.Home,
      element: (
        <React.Suspense fallback={undefined}>
          <CBIEntityHomePage />
        </React.Suspense>
      ),
    },
    {
      path: CbiEntityRoutes.New,
      element: (
        <React.Suspense fallback={undefined}>
          <CBINewEntitySearchPage />
        </React.Suspense>
      ),
      children: [
        {
          path: '',
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[1].sectionId}
                currentPath={CbiEntityRoutes.GeneralInfo}
              >
                <NewEntity />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: `/${CbiEntityRoutes.PageIndex}/0/:placeholder`,
      element: (
        <Navigate
          to={`/${CbiEntityRoutes.PageIndex}/${CbiEntityRoutes.New}`}
          replace
        />
      ),
    },
    {
      path: `:${CbiEntityRoutes.Id}`,
      element: (
        <React.Suspense fallback={undefined}>
          <CBIEntityHomeOutletPage />
        </React.Suspense>
      ),
      children: [
        {
          path: CbiEntityRoutes.GeneralInfo,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[1].sectionId}
                currentPath={CbiEntityRoutes.GeneralInfo}
              >
                <GeneralInfoWrapper />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.Fundings,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[2].sectionId}
                currentPath={CbiEntityRoutes.Fundings}
              >
                <Fundings />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.SalesMetrics,
          element: (
            <React.Suspense fallback={undefined}>
              <SalesMetrics />
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.VCFunds,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[3].sectionId}
                currentPath={CbiEntityRoutes.VCFunds}
              >
                <VcFunds />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.Competitors,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[4].sectionId}
                currentPath={CbiEntityRoutes.Competitors}
              >
                <Competitors />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.Employees,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[5].sectionId}
                currentPath={CbiEntityRoutes.Employees}
              >
                <Employees />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
        {
          path: CbiEntityRoutes.Financials,
          element: (
            <React.Suspense fallback={undefined}>
              <PermissionsProtectedRoute
                pageId={ENTITY_ADMIN_PERMISSIONS.pageId}
                sectionId={ENTITY_ADMIN_PERMISSIONS.sections[10].sectionId}
                currentPath={CbiEntityRoutes.Financials}
              >
                <Financials />
              </PermissionsProtectedRoute>
            </React.Suspense>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ],
};
