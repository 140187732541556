import { forEach, map } from 'lodash';
import {
  UPDATE_ACCESS_BY_STORY,
  UPDATE_ACCESS_BY_TEAM,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import {
  NO_ACCESS,
  VIEW,
  VIEW_EDIT,
} from 'client/modules/stories/utils/permission-types';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import {
  setFailureNotification,
  setSuccessNotification,
} from 'client/modules/common/redux/actions/status-notification';
import { openModal } from 'client/modules/common/redux/actions/modal';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import { updateTotalRecords } from './tab-details';
import {
  getTeamsByStoryRequest,
  getClientTeamMembersStoryServiceRequest,
  getClientTeamMembersAdminServiceRequest,
  getSlugs,
  createSlug,
  getStoriesByTeamRequest,
  cloneStory,
  updatePermissions,
  bulkUpdatePermissions,
} from '../utils/api-request-helper';
import {
  getCurrentTab,
  getSelectedRecords,
  createStoryError,
} from '../utils/records-helper';

export const CANCEL_EDIT_PERMISSIONS =
  'client/modules/stories/actions/tab-data/CANCEL_EDIT_PERMISSIONS';
export const CLEAR_FILTER_INFO =
  'client/modules/stories/actions/tab-data/CLEAR_FILTER_INFO';
export const FETCH_CLIENT_TEAMS =
  'client/modules/stories/actions/tab-data/FETCH_CLIENT_TEAMS';
export const FETCH_CLIENT_TEAM_MEMBERS =
  'client/modules/stories/actions/tab-data/FETCH_CLIENT_TEAM_MEMBERS';
export const SET_STORY_SLUG_INFO =
  'client/modules/stories/actions/tab-data/SET_STORY_SLUG_INFO';
export const SELECT_RECORD = 'client/modules/stories/tab-data/SELECT_RECORD';
export const SELECT_ALL_RECORDS =
  'client/modules/stories/tab-data/SELECT_ALL_RECORDS';
export const UNSELECT_ALL_RECORDS =
  'client/modules/stories/tab-data/UNSELECT_ALL_RECORDS';
export const UPDATE_TEAM_MEMBER_PERMISSION =
  'client/modules/stories/tab-data/UPDATE_TEAM_MEMBER_PERMISSION';
export const UPDATE_TEAM_CONTENT_OWNER =
  'client/modules/stories/tab-data/UPDATE_TEAM_CONTENT_OWNER';
export const UPDATE_SORT_ORDER =
  'client/modules/stories/tab-data/UPDATE_SORT_ORDER';
export const TOGGLE_INNER_TABLE =
  'client/modules/stories/tab-data/TOGGLE_INNER_TABLE';
export const BULK_UPDATE_TEAM_MEMBER_PERMISSIONS =
  'client/modules/stories/tab-data/BULK_UPDATE_TEAM_MEMBER_PERMISSIONS';
export const SET_SORT_INFO = 'client/modules/stories/tab-data/SET_SORT_INFO';
export const SET_FILTER_INFO =
  'client/modules/stories/tab-data/SET_FILTER_INFO';
export const SET_TEAM_STORIES =
  'client/modules/stories/tab-data/SET_TEAM_STORIES';

export const setClientTeams = (records) => {
  return getCurrentTab((currentTab) => {
    return { type: FETCH_CLIENT_TEAMS, currentTab, records };
  });
};

export const setStorySlugInfo = (records) => {
  return getCurrentTab((currentTab) => {
    return { type: SET_STORY_SLUG_INFO, currentTab, records };
  });
};

export const setClientTeamMembers = (innerTableRecords, recordId, storyId) => {
  return getCurrentTab((currentTab) => {
    return {
      type: FETCH_CLIENT_TEAM_MEMBERS,
      currentTab,
      innerTableRecords,
      recordId,
      storyId,
    };
  });
};

export const getClientTeamMembers = (recordId, storyId, teamId) => {
  return (dispatch) => {
    if (!teamId) {
      return Promise.resolve();
    }
    if (!storyId) {
      return dispatch(getClientTeamMembersAdminServiceRequest(teamId)).then(
        (response) => {
          return dispatch(setClientTeamMembers(response, recordId, storyId));
        },
        () => {
          return dispatch(
            setFailureNotification('Failed to get team members!')
          );
        }
      );
    }
    return dispatch(
      getClientTeamMembersStoryServiceRequest(
        parseInt(storyId, 10) || 0,
        teamId
      )
    ).then(
      (response) => {
        return dispatch(setClientTeamMembers(response, recordId, storyId));
      },
      () => {
        return dispatch(setFailureNotification('Failed to get team members!'));
      }
    );
  };
};

export const setTeamStories = (records) => {
  return getCurrentTab((currentTab) => {
    return { type: SET_TEAM_STORIES, currentTab, records };
  });
};

/* OUTER TABLE */

export function selectRecord(recordId) {
  return getCurrentTab((currentTab) => {
    return {
      type: SELECT_RECORD,
      currentTab,
      recordId,
    };
  });
}

export function selectAllRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: SELECT_ALL_RECORDS,
      currentTab,
    };
  });
}

export function unselectAllRecords() {
  return getCurrentTab((currentTab) => {
    return {
      type: UNSELECT_ALL_RECORDS,
      currentTab,
    };
  });
}

/* INNER TABLE */

export function updateTeamMemberPermission(
  outerTableRowId,
  recordId,
  fieldName,
  newValue
) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_TEAM_MEMBER_PERMISSION,
      currentTab,
      outerTableRowId,
      recordId,
      fieldName,
      newValue,
    };
  });
}

export function updateTeamContentOwner(outerTableRowId, recordId) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_TEAM_CONTENT_OWNER,
      currentTab,
      outerTableRowId,
      recordId,
    };
  });
}

export function cancelEditingPermissions(outerTableRowId) {
  return getCurrentTab((currentTab) => {
    return {
      type: CANCEL_EDIT_PERMISSIONS,
      currentTab,
      outerTableRowId,
    };
  });
}

/* SORT: TABLE HEADER */

export function setSortInfo(sortField, sortDirection) {
  return getCurrentTab((currentTab) => {
    return {
      type: SET_SORT_INFO,
      currentTab,
      sortField,
      sortDirection,
    };
  });
}

export function setFilterInfo(filterName, filterValue) {
  return getCurrentTab((currentTab) => {
    return {
      type: SET_FILTER_INFO,
      currentTab,
      filterName,
      filterValue,
    };
  });
}

export function updateSortOrder(outerTableRowId, sortField, sortDirection) {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SORT_ORDER,
      currentTab,
      sortField,
      sortDirection,
      outerTableRowId,
    };
  });
}

export function toggleInnerTable(recordId) {
  return getCurrentTab((currentTab) => {
    return {
      type: TOGGLE_INNER_TABLE,
      recordId,
      currentTab,
    };
  });
}

export function bulkUpdateTeamMemberPermissions(outerTableRowId, newValue) {
  return getCurrentTab((currentTab) => {
    return {
      type: BULK_UPDATE_TEAM_MEMBER_PERMISSIONS,
      currentTab,
      newValue,
      outerTableRowId,
    };
  });
}

export function clearFilterInfo(name) {
  return getCurrentTab((currentTab) => {
    return {
      type: CLEAR_FILTER_INFO,
      currentTab,
      name,
    };
  });
}

const filterNameMapping = {
  'Team Name': 'teamName',
  CSM: 'sfOwner',
  'Renewal Date': 'renewalDate',
  'Package Name': 'packageName',
  'Content Tier': 'contentTier',
  'B-D Account Sector': 'cbiAccountSector',
  'Customer Use Case': 'customerUseCase',
  'Story Name': 'storyName',
  'Created By': 'createdBy',
  'Created Date': 'createdDate',
  'Story ID': 'idStory',
};

export function getFilterParams(filterInfo, dateRangeName, dateFilterName) {
  const filterParams = [];
  const dateRange = {};
  forEach(filterInfo, (value, filterName) => {
    if (filterNameMapping[filterName] === dateFilterName) {
      dateRange.startDate = value.startDate;
      dateRange.endDate = value.endDate;
    } else if (filterName in filterNameMapping) {
      filterParams.push({
        fieldName: filterNameMapping[filterName],
        filterTerm: value,
      });
    }
  });
  return { filterParams, [dateRangeName]: dateRange };
}

export function validateDateRange(dateRange) {
  let errorMessage = '';
  if (dateRange.startDate && !dateRange.endDate) {
    errorMessage = 'You need to specify the end date!';
  } else if (!dateRange.startDate && dateRange.endDate) {
    errorMessage = 'You need to specify the start date!';
  } else if (dateRange.startDate > dateRange.endDate) {
    errorMessage = 'Start date needs to be after the end date!';
  }
  return errorMessage;
}

export function getTeamsByStory(storyId) {
  return (dispatch, getState) => {
    const {stories} = getState();

    const { filterInfo, sortInfo } = stories.tabData[UPDATE_ACCESS_BY_STORY];
    const { recordsPerPage, pageNumber } = stories.tabDetails[
      UPDATE_ACCESS_BY_STORY
    ];
    const { selectedSearchTerm, selectedSearchId } = stories.searchBars[
      UPDATE_ACCESS_BY_STORY
    ];
    const idStory = storyId || selectedSearchId;

    updateUrlParams('searchTerm', selectedSearchTerm);
    updateUrlParams('searchTermId', idStory);

    if (!idStory) {
      return dispatch(
        setFailureNotification('You need to search for a story first!')
      );
    }
    const { filterParams, renewalDateRange } = getFilterParams(
      filterInfo,
      'renewalDateRange',
      'renewalDate'
    );
    const dateRangeError = validateDateRange(renewalDateRange);
    if (dateRangeError) {
      return dispatch(setFailureNotification(dateRangeError));
    }
    return dispatch(
      getTeamsByStoryRequest({
        idStory: parseInt(idStory, 10),
        filterInfo: filterParams,
        renewalDateRange,
        sortInfo: {
          fieldName: filterNameMapping[sortInfo.sortField],
          direction: sortInfo.sortDirection,
        },
        limit: recordsPerPage,
        offset: pageNumber * recordsPerPage,
      })
    ).then(
      (response) => {
        dispatch(updateTotalRecords(response.total));
        return dispatch(setClientTeams(response));
      },
      () => {
        return dispatch(setFailureNotification('Failed to get the records!'));
      }
    );
  };
}

export function getStoriesByName() {
  return (dispatch, getState) => {
    const {stories} = getState();
    const { filterInfo, sortInfo } = stories.tabData[MANAGE_SLUGS];
    const { searchTerm } = stories.searchBars[MANAGE_SLUGS];
    const { recordsPerPage, pageNumber } = stories.tabDetails[MANAGE_SLUGS];

    updateUrlParams('searchTerm', searchTerm);

    if (!searchTerm) {
      return dispatch(setFailureNotification('You need to search first!'));
    }
    const { filterParams, createdDateRange } = getFilterParams(
      filterInfo,
      'createdDateRange',
      'createdDate'
    );
    const dateRangeError = validateDateRange(createdDateRange);
    if (dateRangeError) {
      return dispatch(setFailureNotification(dateRangeError));
    }
    return dispatch(
      getSlugs({
        query: searchTerm,
        sortInfo: {
          fieldName: filterNameMapping[sortInfo.sortField],
          direction: sortInfo.sortDirection,
        },
        filterInfo: filterParams,
        createdDateRange,
        limit: recordsPerPage,
        offset: pageNumber * recordsPerPage,
      })
    ).then(
      (response) => {
        dispatch(updateTotalRecords(response.total));
        return dispatch(setStorySlugInfo(response));
      },
      () => {
        return dispatch(setFailureNotification('Failed to get the records!'));
      }
    );
  };
}

export function createNewSlug(idStory, slug) {
  return (dispatch) => {
    if (!slug || !idStory) {
      return dispatch(setFailureNotification('Failed to create the slug!'));
    }
    return dispatch(
      createSlug({
        idStory: parseInt(idStory, 10),
        slug,
      })
    ).then(
      (response) => {
        if (response.success) {
          dispatch(setSuccessNotification('New slug created!'));
          return dispatch(getStoriesByName());
        }
        return dispatch(
          setFailureNotification(
            `Failed to create the slug! ${JSON.stringify(response.msg)}`
          )
        );
      },
      (error) => {
        if (error.details.includes('Duplicate entry')) {
          return dispatch(
            setFailureNotification(
              'Failed to create the slug! All slug names must be unique'
            )
          );
        }
        return dispatch(
          setFailureNotification(
            `Failed to create the slug! ${JSON.stringify(error)}`
          )
        );
      }
    );
  };
}

export function getStoriesByTeam(teamId) {
  return (dispatch, getState) => {
    const {stories} = getState();

    const { filterInfo, sortInfo } = stories.tabData[UPDATE_ACCESS_BY_TEAM];
    const { recordsPerPage, pageNumber } = stories.tabDetails[
      UPDATE_ACCESS_BY_TEAM
    ];
    const { selectedSearchTerm, selectedSearchId } = stories.searchBars[
      UPDATE_ACCESS_BY_TEAM
    ];
    const idTeam = teamId || selectedSearchId;

    updateUrlParams('searchTerm', selectedSearchTerm);
    updateUrlParams('searchTermId', idTeam);

    if (!idTeam) {
      return dispatch(
        setFailureNotification('You need to search for a team first!')
      );
    }

    const { filterParams } = getFilterParams(filterInfo);
    return dispatch(
      getStoriesByTeamRequest({
        idTeam: parseInt(idTeam, 10),
        sortInfo: {
          fieldName: filterNameMapping[sortInfo.sortField],
          direction: sortInfo.sortDirection,
        },
        filterInfo: filterParams,
        limit: recordsPerPage,
        offset: pageNumber * recordsPerPage,
      })
    ).then(
      (response) => {
        dispatch(updateTotalRecords(response.totalProvisionedStories));
        return dispatch(setTeamStories(response));
      },
      () => {
        return dispatch(setFailureNotification('Failed to get the records!'));
      }
    );
  };
}

/* INNER TABLE UPDATE PERMISSION */
const permissionLevel = {
  [NO_ACCESS]: 0,
  [VIEW]: 1,
  [VIEW_EDIT]: 2,
};

export function updatePermissionRequest(newIdStory, innerTableRecords) {
  return map(innerTableRecords, (record) => {
    return {
      idStory: newIdStory,
      accessLevel: permissionLevel[record.permission],
      idUser: record.id,
      updateOwner: record.contentOwner,
    };
  });
}

export function updatePermissionsInTeamHelper(
  dispatch,
  idStory,
  innerTableRecords
) {
  return dispatch(
    updatePermissions({
      updatePermissionsInfo: updatePermissionRequest(
        idStory,
        innerTableRecords
      ),
    })
  ).then(
    (response) => {
      if (response && response.success) {
        return dispatch(
          setSuccessNotification('Permissions updated successfully!')
        );
      }
      return dispatch(
        setFailureNotification(
          `Failed to updated permissions! ${JSON.stringify(response)}`
        )
      );
    },
    (error) => {
      return dispatch(
        setFailureNotification(
          `Failed to updated permissions! ${JSON.stringify(error)}`
        )
      );
    }
  );
}

export function updatePermissionsInTeam(outerTableRowId) {
  return (dispatch, getState) => {
    const {stories} = getState();
    const {currentTab} = stories;
    let idStory = null;
    const {innerTableRecords} = stories.tabData[currentTab].innerTable[outerTableRowId];

    if (currentTab === UPDATE_ACCESS_BY_TEAM) {
      idStory = stories.tabData[currentTab].records[outerTableRowId].id;
      const idTeam = stories.searchBars[currentTab].selectedSearchId;
      if (!idStory) {
        return dispatch(setFailureNotification('Story id is not exist!'));
      }
      return updatePermissionsInTeamHelper(
        dispatch,
        idStory,
        innerTableRecords
      ).then(() => {
        return dispatch(getStoriesByTeam(idTeam));
      });
    } if (currentTab === UPDATE_ACCESS_BY_STORY) {
      idStory = stories.tabData[currentTab].records[outerTableRowId].idStory;
      const parentStoryId = stories.searchBars[currentTab].selectedSearchId;
      if (!idStory) {
        const contentOwnerId = getKeyValueFromListViaOtherKey(
          innerTableRecords,
          'contentOwner',
          true,
          'id'
        );
        return dispatch(
          cloneStory({ idStory: parentStoryId, newOwnerIdUser: contentOwnerId })
        ).then(
          (newStory) => {
            if (newStory && newStory.success) {
              return updatePermissionsInTeamHelper(
                dispatch,
                newStory.newIdStory,
                innerTableRecords
              ).then(() => {
                return dispatch(getTeamsByStory(parentStoryId));
              });
            }
            return dispatch(
              setFailureNotification(
                `Failed to clone the story! ${newStory.failureReason}`
              )
            );
          },
          (error) => {
            return dispatch(
              setFailureNotification(
                `Failed to clone the story! ${JSON.stringify(error)}`
              )
            );
          }
        );
      }
      return updatePermissionsInTeamHelper(
        dispatch,
        idStory,
        innerTableRecords
      ).then(() => {
        return dispatch(getTeamsByStory(parentStoryId));
      });
    }
    return Promise.resolve();
  };
}

export function bulkUpdatePermissionsInTeamHelper(
  dispatch,
  parentIdStory,
  accessLevel,
  teamStories,
  records
) {
  return dispatch(
    bulkUpdatePermissions({
      parentIdStory,
      accessLevel,
      teamStories,
    })
  ).then(
    (response) => {
      if (response && response.success) {
        dispatch(unselectAllRecords());
        return dispatch(
          setSuccessNotification('Permissions updated successfully!')
        );
      } if (response && !response.success) {
        dispatch(unselectAllRecords());

        const teamNames = {};
        forEach(records, (record) => {
          teamNames[record.idTeam] = record.teamName;
        });

        let errorTitle = '';
        if (response.failedTeamStories.length === 1) {
          errorTitle = 'There was 1 error during bulk provisioning!';
        } else {
          errorTitle = `There were ${response.failedTeamStories.length} errors during bulk provisioning!`;
        }
        const errorMessage = createStoryError(
          response.failedTeamStories,
          teamNames
        );

        return dispatch(openModal(errorTitle, errorMessage));
      }
      return dispatch(
        setFailureNotification(
          `Failed to updated permissions! ${JSON.stringify(response)}`
        )
      );
    },
    (error) => {
      return dispatch(
        setFailureNotification(
          `Failed to updated permissions! ${JSON.stringify(error)}`
        )
      );
    }
  );
}

export function bulkUpdatePermissionsInTeam(permission) {
  return (dispatch, getState) => {
    const {stories} = getState();
    const {currentTab} = stories;
    const {records} = stories.tabData[currentTab];
    const {selectedRecords} = stories.tabData[currentTab];

    const selectedIds = getSelectedRecords(selectedRecords);
    const accessLevel = permissionLevel[permission];

    if (currentTab === UPDATE_ACCESS_BY_TEAM) {
      const parentIdStory = 0;
      const idTeam = stories.searchBars[currentTab].selectedSearchId;
      const teamStories = map(selectedIds, (id) => {
        return {
          idTeam,
          idStory: parseInt(id, 10),
        };
      });

      return bulkUpdatePermissionsInTeamHelper(
        dispatch,
        parentIdStory,
        accessLevel,
        teamStories,
        records
      ).then(() => {
        return dispatch(getStoriesByTeam(idTeam));
      });
    } if (currentTab === UPDATE_ACCESS_BY_STORY) {
      const parentIdStory = stories.searchBars[currentTab].selectedSearchId;
      const teamStories = map(selectedIds, (id) => {
        return {
          idTeam: records[parseInt(id, 10)].idTeam,
          idStory: records[parseInt(id, 10)].idStory,
        };
      });

      return bulkUpdatePermissionsInTeamHelper(
        dispatch,
        parentIdStory,
        accessLevel,
        teamStories,
        records
      ).then(() => {
        return dispatch(getTeamsByStory(parentIdStory));
      });
    }
    return Promise.resolve();
  };
}
