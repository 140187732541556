import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const CreatePackage = React.lazy(() => import('./containers/CreatePackage'));
const UpdatePackage = React.lazy(() => import('./containers/UpdatePackage'));
const PackageFeatures = React.lazy(
  () => import('./containers/PackageFeatures')
);
const ComparePackages = React.lazy(
  () => import('./containers/ComparePackages')
);
const PackageList = React.lazy(() => import('./containers/PackageList'));
const PackageDetail = React.lazy(() => import('./containers/PackageDetail'));

export const packagesRoutes: RouteObject = {
  path: 'packages',
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    {
      path: '/packages/create-package',
      element: (
        <React.Suspense fallback={undefined}>
          <CreatePackage />
        </React.Suspense>
      ),
    },
    {
      path: '/packages/update-package',
      element: (
        <React.Suspense fallback={undefined}>
          <UpdatePackage />
        </React.Suspense>
      ),
    },
    {
      path: '/packages/features',
      element: (
        <React.Suspense fallback={undefined}>
          <PackageFeatures />
        </React.Suspense>
      ),
    },
    {
      path: '/packages/compare-packages',
      element: (
        <React.Suspense fallback={undefined}>
          <ComparePackages />
        </React.Suspense>
      ),
    },
    {
      path: '/packages/:id',
      element: (
        <React.Suspense fallback={undefined}>
          <PackageDetail />
        </React.Suspense>
      ),
    },
    {
      path: '/packages',
      element: <Navigate to="/packages/create-package" />,
    },
    {
      path: '/packages/list',
      element: (
        <React.Suspense fallback={undefined}>
          <PackageList />
        </React.Suspense>
      ),
    },
  ],
};
