import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

/**
 * @deprecated
 * It's a custom IconButton component that takes in all the props of the Material UI IconButton
 * component and adds a few more
 * @param props - This is the props object that is passed to the component.
 * @returns A custom icon button component
 */
const CustomIconButton = (props) => {
  const {
    children,
    onClick,
    showHover,
    className,
    color,
    ariaLabel,
    disabled,
    style,
    ...rest
  } = props;
  const allStyles = {
    outline: 'none',
    backgroundColor: `${showHover ? '' : 'initial'}`,
    ...style,
  };
  return (
    <IconButton
      style={allStyles}
      onClick={onClick}
      className={className}
      color={color}
      aria-label={ariaLabel}
      disabled={disabled}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

CustomIconButton.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  showHover: PropTypes.bool,
  style: PropTypes.object,
};

CustomIconButton.defaultProps = {
  ariaLabel: '',
  className: '',
  color: 'inherit',
  disabled: false,
  onClick: () => {},
  showHover: false,
  style: {},
};

export default CustomIconButton;
