import {
  PostInvestorLogoRequest,
  PostLogoResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const postInvestorLogo = (
  req: Partial<PostInvestorLogoRequest>
): Promise<PostLogoResponse> => {
  return request({
    url: '/service/logoservice/PostInvestorLogo',
    body: { ...req },
  }).then((r): PostLogoResponse => r.body);
};
