/* eslint-disable max-len */
const iconButtonStyle = {
  color: '#ffffff',
  float: 'right',
  margin: 5,
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
};

export default iconButtonStyle;
