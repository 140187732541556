/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';
import CircleCloseIcon from '@cbinsights/fds/lib/icons/react/CircleCloseIcon';
import ExternalLinkIcon from '@cbinsights/fds/lib/icons/react/ExternalLinkIcon';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

/* Each Chip That will be Rendered */
/**
 * @deprecated
 * It renders a chip for an investor field
 * @param props - {
 * @returns A function that returns a div
 */
export function InvestorChipField(props) {
  const {
    field,
    handleDelete,
    onChipClick = () => {},
    shouldHighlightChip = () => false,
  } = props;

  const isHighlighted = shouldHighlightChip(field);
  const chipClassName = cx('flex flex-wrap p-1 m-2 rounded-lg', {
    'bg-blue-600 text-white': isHighlighted,
    'bg-gray-300': !isHighlighted,
  });

  const draftChipClassName = cx('flex flex-wrap p-1 m-2 rounded-lg', {
    'bg-orange-600 text-white': isHighlighted,
    'bg-yellow-400': !isHighlighted,
  });
  const onClick = () => {
    onChipClick(field);
  };

  const isExistingEntity = get(field, 'value.id', 0);
  const chipName = get(field, `${field.name}.name`, field.value.name);
  const idEntityDraft = get(
    field,
    'value.idEntityDraft',
    field.value.idEntityDraft
  );

  if (!isExistingEntity) {
    return (
      <div
        data-test={`${field.name}-no-entity`}
        className={
          idEntityDraft
            ? draftChipClassName
            : 'flex flex-wrap p-1 m-2 rounded-lg bg-yellow-400'
        }
        title="Create Investor Profile, then add new investor"
      >
        <span
          name={`${field.name}`}
          data-test={`${field.name}-chip`}
          className="mr-2"
          onClick={idEntityDraft ? onClick : null}
        >
          {chipName || 'Unknown Investor'}
        </span>
        {idEntityDraft ? (
          <Link
            to={`/cbi-entity/${idEntityDraft}/general-info`}
            target="_blank"
          >
            <ExternalLinkIcon customSize="15" />
          </Link>
        ) : (
          <Link
            to={`/cbi-entity/0/general-info?entityName=${chipName}`}
            target="_blank"
          >
            <ExternalLinkIcon customSize="15" />
          </Link>
        )}
        <Button
          data-test={`${field.name}-delete`}
          tabIndex="-1"
          onClick={handleDelete}
          inline
          isDestructive
        >
          <CircleCloseIcon customSize="15" />
        </Button>
      </div>
    );
  }

  return (
    <div className={chipClassName}>
      <span
        name={`${field.name}`}
        data-test={`${field.name}-chip`}
        className="mr-2"
        onClick={onClick}
        role="button"
      >
        {chipName}
      </span>
      <Link to={`/cbi-entity/${field.value.id}/general-info`} target="_blank">
        <ExternalLinkIcon customSize="15" />
      </Link>
      <Button
        data-test={`${field.name}-delete`}
        tabIndex="-1"
        onClick={handleDelete}
        inline
        isDestructive
      >
        <CircleCloseIcon customSize="15" />
      </Button>
    </div>
  );
}

InvestorChipField.propTypes = {
  field: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  onChipClick: PropTypes.func,
  shouldHighlightChip: PropTypes.func,
};
