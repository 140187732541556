import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { useListFilterExpressionGroups } from '../services/hooks/useGetFilterExpressionGroupInfo';
import { FilterGroupMenuItem } from './FilterGroupMenuItem';

export const FilterGroupMenu = () => {
  const { data, isFetching: isFetchingGroups } =
    useListFilterExpressionGroups();

  if (isFetchingGroups) {
    return (
      <div style={{ marginTop: 20 }} className="text-center">
        <h4>
          <i className="glyphicon glyphicon-cog gly-spin" /> Loading Groups
        </h4>
      </div>
    );
  }

  return (
    <div className="p-2 mr-1">
      <div className="filter-group-link-back text-blue-500 hover:text-blue-600 hover:underline mb-2">
        <Link to="/news/sources">Back to Sources</Link>
      </div>
      <div className="filter-group-new-btn mb-8">
        <Link to="/news/groups/add">
          <Button data-test="addNewGroupButton">Add a new group</Button>
        </Link>
      </div>
      <div
        className="overflow-scroll pl-2 pr-4 py-1 border border-gray-400"
        style={{ height: '550px' }}
      >
        {data.filterExpressionGroups.map((group, index) => (
          <FilterGroupMenuItem
            name={group.name}
            groupId={group.idFilterExpressionGroup}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
