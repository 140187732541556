/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FieldArray, useFormikContext } from 'formik';
import { InputFieldArray } from 'client/modules/common/form/fields/InputFieldArray';

// eslint-disable-next-line no-unused-vars
const NOP = (option) => {};

export function MultiFieldRowHeader({ fields }) {
  return (
    <div className="flex flex-row px-3 mt-1" style={{ width: '98%' }}>
      {fields.map(({ width, label, required = false }) => {
        return (
          <div style={{ width }} className="px-1">
            {label}
            {required && <span className="text-xs text-red-600">&nbsp;*</span>}
          </div>
        );
      })}
    </div>
  );
}

/**
 * @deprecated
 * It wraps a `FieldArray` component from `formik` and adds a `onDelete` handler that adds the deleted
 * value to a `deletions` object in the form's `values`
 * @returns A FieldArray component that is wrapped in a MultiFieldArray component.
 */
function MultiFieldArray({
  name,
  prefix = '',
  deletionPath = '',
  onDelete = NOP,
  ...props
}) {
  const { values, setFieldValue } = useFormikContext();

  const handleDelete = (index, deletedValue) => {
    const path = deletionPath
      ? `${deletionPath}.deletions.${name}`
      : `deletions.${name}`;

    setFieldValue(path, [deletedValue, ...get(values, path, [])]);
    onDelete({ index, deletedValue });
  };

  return (
    <FieldArray name={prefix ? `${prefix}.${name}` : name}>
      {(formikProps) => (
        <InputFieldArray {...formikProps} {...props} onDelete={handleDelete} />
      )}
    </FieldArray>
  );
}

MultiFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  deletionPath: PropTypes.string,
  onDelete: PropTypes.func,
};

export { MultiFieldArray };
