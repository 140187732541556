import React from 'react';
import PropTypes from 'prop-types';

/**
 * @deprecated
 * It takes in a company logo object, and returns a link to a Google Image Search for that company logo
 * @returns A function that returns a div with a link to a google search for the company logo.
 */
const GoogleSearchImage = ({ companyLogo }) => {
  const { companyName, primaryUrl } = companyLogo;
  const encodedCompanyName = encodeURIComponent(companyName);

  return (
    <div style={{ paddingBottom: '10px' }}>
      <a
        className="underline text-blue-500"
        href={`https://www.google.com/search?tbm=isch&q=${encodedCompanyName}+logo+${primaryUrl}&tbs=isz:lt,islt:qsvga`}
        target="_blank"
        rel="noreferrer"
      >
        Run logo search
      </a>
    </div>
  );
};

GoogleSearchImage.propTypes = {
  companyLogo: PropTypes.shape({
    companyName: PropTypes.string,
    primaryUrl: PropTypes.string,
  }),
};

export default GoogleSearchImage;
