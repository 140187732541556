import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'client/component-library/TextField';

/**
 * @deprecated
 * It's a text field that takes a date
 * @returns A TextField component with the following props:
 *   className: classes.textField
 *   label: label
 *   disabled: disabled
 *   id: id
 *   name: name
 *   type: date
 *   data-test: dataTest
 *   value: value
 *   InputProps: {
 *     style: { color: '#404040', fontSize: '
 */
const DateTextField = ({
  name,
  value,
  id,
  classes,
  disabled,
  dataTest,
  label,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      className={classes.textField}
      label={label}
      disabled={disabled}
      id={id}
      name={name}
      type="date"
      data-test={dataTest}
      value={value}
      InputProps={{
        style: { color: '#404040', fontSize: '0.9225rem' },
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

DateTextField.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
};

DateTextField.defaultProps = {
  dataTest: '',
  disabled: false,
  label: '',
  name: '',
  value: '',
  id: '',
};

export default DateTextField;
