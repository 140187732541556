import DROPDOWN_LIMIT from 'client/modules/common/components/AutocompleteDropdown/utils/constants';
import { logInfo } from 'client/modules/common/utils/logInfo';

const UPDATE_COMPANY_STATUS_FROM_FUNDING =
  'client/modules/cbi-entity/actions/UPDATE_COMPANY_STATUS_FROM_FUNDING';

export const fetchCompanyLogoInfo = (id) => ({
  types: [null, null],
  service: {
    endpoint: 'GetCompanyLogos',
    name: 'logoservice',
    body: {
      idCompanies: [id] || [846],
      getMeta: false,
      onlyGetDefault: true,
      height: 100,
    },
  },
});

export const fetchInvestorLogoInfo = (id) => ({
  types: [null, null],
  service: {
    endpoint: 'GetInvestorLogos',
    name: 'logoservice',
    body: {
      idInvestors: [id] || [2],
      getMeta: false,
      onlyGetDefault: true,
      height: 100,
    },
  },
});

export const setCompanyLogo = (body) => ({
  types: [null, null],
  service: {
    endpoint: 'PostCompanyLogo',
    name: 'logoservice',
    body,
  },
});

export const setInvestorLogo = (body) => ({
  types: [null, null],
  service: {
    endpoint: 'PostInvestorLogo',
    name: 'logoservice',
    body,
  },
});

export const unsetEntityLogo = (body) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdateDefaultLogo',
    name: 'logoservice',
    body,
  },
});

export const fetchAllStatusValues = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllEntityStatusValues',
    name: 'cbientityservice',
  },
});

export const fetchAllPersonTitles = () => ({
  types: [null, null],
  service: {
    endpoint: 'ListTitles',
    name: 'cbientityservice',
  },
});

export const fetchAllSectorValues = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllSectorValues',
    name: 'cbientityservice',
  },
});

export const fetchAllInvestorCategoryValues = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllInvestorCategoryValues',
    name: 'cbientityservice',
  },
});

export const fetchAllAliasTypeValues = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllAliasTypeValues',
    name: 'cbientityservice',
  },
});

export const fetchGeneralCbiEntityInfo = (infoRequest) => ({
  types: [null, null],
  service: {
    endpoint: 'GetGeneralCbiEntityInfo',
    name: 'cbientityservice',
    body: { ...infoRequest },
  },
});

export const getEntityFundingsRequest = (idCbiEntity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetEntityFundings',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
    },
  },
});

// TO DO: add these api calls
// rpc GetLimitedPartners (SearchTerm) returns (AllLimitedPartnerInfo) {}
export const getCbiEntityVcFundsRequest = (idCbiEntity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetCbiEntityVcFunds',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
    },
  },
});

export const deleteCbiEntityVcFund = (
  idVcFunds = [],
  vcFundCiks = [],
  idVcFundSources = [],
  vcFundLimitedPartners = []
) => ({
  types: [null, null],
  service: {
    endpoint: 'DeleteCbiEntityVcFund',
    name: 'cbientityservice',
    body: {
      idVcFunds,
      vcFundCiks,
      idVcFundSources,
      vcFundLimitedPartners,
    },
  },
});

export const createCbiEntityVcFund = (idCbiEntity, vcFundInfo) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateCbiEntityVcFund',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
      vcFundInfo,
    },
  },
});

export const updateCbiEntityVcFund = (idCbiEntity, vcFundInfo) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdateCbiEntityVcFund',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
      vcFundInfo,
    },
  },
});

export const fetchCountrySuggestions = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllCountryValues',
    name: 'cbientityservice',
    body: {},
  },
});

export const fetchStateSuggestions = (idCountry) => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllStateValues',
    name: 'cbientityservice',
    body: { idCountry },
  },
});

// Will pass in cityName...
export const fetchCitySuggestions = (body) => ({
  types: [null, null],
  service: {
    endpoint: 'SearchForCity',
    name: 'cbientityservice',
    body,
  },
});

export const getAllRoundValuesRequest = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllRoundValues',
    name: 'cbientityservice',
  },
});

export const fetchCbiEntityEmployees = (idCbiEntity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetCbiEntityEmployees',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
    },
  },
});

export const fetchCbiEntitySalesMetrics = (idCbiEntity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetSalesMetrics',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
    },
  },
});

export const entitySearchRequest = (
  searchTerm,
  type,
  limit = DROPDOWN_LIMIT,
  searchVcFunds = false
) => {
  if (!searchTerm) {
    logInfo({
      error: new Error('Searching With No Search Term'),
      message: `Search Term not provided`,
      method: 'entitySearchRequest-cbiEntity',
    });
  }
  return {
    types: [null, null],
    service: {
      endpoint: 'SearchForEntity',
      name: 'cbientityservice',
      body: {
        searchTerm,
        limit,
        entityType: type,
        searchVcFunds,
      },
    },
  };
};

export const investorPersonSearch = (searchTerm, limit = DROPDOWN_LIMIT) => ({
  types: [null, null],
  service: {
    endpoint: 'SearchInvestorPeople',
    name: 'cbientityservice',
    body: {
      searchTerm,
      limit,
    },
  },
});

const resetCompanyStatusFromFunding = () => {
  return {
    type: UPDATE_COMPANY_STATUS_FROM_FUNDING,
    value: false,
  };
};

export const processFundingRequest = (
  idCbiEntity,
  fundingInfo,
  shouldUpdateCompanyStatus,
  itemsToDelete
) => ({
  types: [null, resetCompanyStatusFromFunding],
  service: {
    endpoint: 'ProcessFunding',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
      fundingInfo,
      shouldUpdateCompanyStatus,
      itemsToDelete,
    },
  },
});

export const processSalesMetricRequest = (
  idCbiEntity,
  salesMetricInfo,
  itemsToDelete
) => ({
  types: [null, null],
  service: {
    endpoint: 'ProcessSalesMetric',
    name: 'cbientityservice',
    body: {
      idCbiEntity,
      salesMetricInfo,
      itemsToDelete,
    },
  },
});

export const createEntityPerson = (body) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateEntityPerson',
    name: 'cbientityservice',
    body: {
      idInvPerson: body.bodPerson.idInvestorPerson,
      firstName: body.bodPerson.fname,
      middleName: body.bodPerson.mname,
      lastName: body.bodPerson.lname,
      bio: body.bodPerson.bio,
      boardAssociations: [],
      workExperience: [
        {
          idCbiEntity: body.workExperience.idCbiEntity,
          entityName: body.workExperience.entityName,
          current: true,
          title: {
            idPersonTitle: body.bodPerson.firmTitle.value,
            title: body.bodPerson.firmTitle.label,
            titleCode: body.bodPerson.firmTitle.abbreviation,
          },
        },
      ],
    },
  },
});

export const createOrUpdateInvestorPeople = (investorPerson) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateOrUpdateInvestorPeople',
    name: 'cbientityservice',
    body: { investorPerson },
  },
});

export const deleteCbiEntityEmployee = (employmentRelationships) => ({
  types: [null, null],
  service: {
    endpoint: 'DeleteInvestorPeople',
    name: 'cbientityservice',
    body: { employmentRelationships },
  },
});

export const addFirmsToInvestorPeople = (firms) => ({
  types: [null, null],
  service: {
    endpoint: 'AddFirmsToInvestorPeople',
    name: 'cbientityservice',
    body: { firms },
  },
});

export const removeFirmsFromInvestorPeople = (firmsToDelete) => ({
  types: [null, null],
  service: {
    endpoint: 'RemoveFirmsFromInvestorPeople',
    name: 'cbientityservice',
    body: { firmsToDelete },
  },
});

export const getInvestorPeopleDetails = (idEmployee) => ({
  types: [null, null],
  service: {
    endpoint: 'GetInvestorPeopleDetails',
    name: 'cbientityservice',
    body: { idEmployee },
  },
});

export const addBoardMemberToFundingRound = (boardMemberFundingRounds) => ({
  types: [null, null],
  service: {
    endpoint: 'AddBoardMemberToFundingRound',
    name: 'cbientityservice',
    body: { boardMemberFundingRounds },
  },
});

export const removeBoardMemberFromFundingRound = (
  boardMemberFundingRounds
) => ({
  types: [null, null],
  service: {
    endpoint: 'RemoveBoardMemberFromFundingRound',
    name: 'cbientityservice',
    body: { boardMemberFundingRounds },
  },
});

export const getEntityFundingRounds = (idCbiEntity) => ({
  types: [null, null],
  service: {
    endpoint: 'GetEntityFundingRounds',
    name: 'cbientityservice',
    body: { idCbiEntity },
  },
});
