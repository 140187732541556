import { useState } from 'react';
import { Button, Form, Input, Space, Spin, message } from 'antd';
import Password from 'antd/lib/input/Password';
import { useLogin } from './useLogin';
import './input.css';

export const getGotoValue = () => {
  if (!window.location.search) {
    return null;
  }

  const splitSearchQuery = window.location.search.split('?goto=');
  return splitSearchQuery.length === 2 ? splitSearchQuery[1] : null;
};

export const LocalLogin = () => {
  const login = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (values) => {
    setIsLoading(true);
    await login.mutateAsync(
      {
        email: values.userName,
        password: values.password,
      },
      {
        onSuccess(response) {
          setIsLoading(false);
          message.success('Login successful! Redirecting...');
          if (response.user.email) {
            const gotoValue = getGotoValue();
            window.location.href =
              gotoValue ||
              `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
          }
        },
        onError(error: $TSFixMe) {
          setIsLoading(false);
          message.error(
            error?.response?.body?.message ||
              'An error ocurred, please check your credentials and try again!'
          );
        },
      }
    );
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Space direction="vertical">
        <div className="flex justify-center">
          <img
            alt="local login"
            src="/admin-frontend-service-images/local.jpg"
            className="w-[100px]"
          />
        </div>
        <div>
          <Form id="login" onFinish={handleSubmit} className="w-[380px]">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter your username',
                },
              ]}
              name="userName"
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter your password',
                },
              ]}
              name="password"
            >
              <Password placeholder="Password" />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? <Spin className="px-5" /> : <span>Login</span>}
            </Button>
          </Form>
        </div>
      </Space>
    </div>
  );
};
