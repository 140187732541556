import {
  RefreshMarketCompetitorsForAdminRequest,
  UpsertMarketRequest,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import React, { useState } from 'react';

import { message } from 'antd';
import { useQueryClient } from 'react-query';
import { useUpsertMarket } from '../service/hooks/useUpsertMarket';
import { MarketReport } from './useMarketsList';
import { useRefreshMarketCompetitorsForAdmin } from '../service/hooks/useRefreshMarketCompetitors';

export const useRefreshMarkets = ({
  markets,
  setMarkets,
}: {
  setMarkets: (list: []) => void;
  markets: MarketReport[];
}) => {
  const [isMarketRefreshingDict, setMarketRefreshDict] = useState<
    Record<number, boolean>
  >({});
  const [areMarketsRefreshing, setProcessMarketsRefresh] = useState(false);
  const [areMarketsCompetitorRefreshing, setProcessMarketsCompetitorRefresh] =
    useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState<
    Record<number, boolean>
  >({});

  const upsertMarketMutation = useUpsertMarket();
  const queryClient = useQueryClient();
  const refreshMarketsCompetitorMutation =
    useRefreshMarketCompetitorsForAdmin();

  const selectMarketToRefresh = (id: number) => () => {
    setSelectedMarkets({
      ...selectedMarkets,
      [id]: selectedMarkets[id] ? !selectedMarkets[id] : true,
    });
  };

  const refreshMarketsCompetitors = async () => {
    setProcessMarketsCompetitorRefresh(true);
    const idMarkets: Partial<RefreshMarketCompetitorsForAdminRequest> = {
      id_markets: Object.keys(selectedMarkets).map(Number),
    };

    await refreshMarketsCompetitorMutation.mutateAsync(idMarkets, {
      onError: (error: $TSFixMe) => {
        message.error(
          <span>
            An error occurs when refreshing competitor <br />{' '}
            {error?.response?.body?.error?.details || error.message}
          </span>
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['GetMarketsForAdmin']);
        setSelectedMarkets({});
        message.success(
          'Market competitors will run through the market suggestion job and should be updated shortly'
        );
      },
    });
    setProcessMarketsCompetitorRefresh(false);
  };

  const refreshMarketsCompetitor =
    (idMarket: number) => async (event: $TSFixMe) => {
      event.stopPropagation();
      setMarketRefreshDict({ ...isMarketRefreshingDict, [idMarket]: true });
      const idMarkets: Partial<RefreshMarketCompetitorsForAdminRequest> = {
        id_markets: [idMarket],
      };

      await refreshMarketsCompetitorMutation.mutateAsync(idMarkets, {
        onError: (error: $TSFixMe) => {
          message.error(
            <span>
              An error occurs when refreshing competitor <br />{' '}
              {error?.response?.body?.error?.details || error.message}
            </span>
          );
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(['GetMarketsForAdmin']);
          message.success(
            'Market competitors will run through the market suggestion job and should be updated shortly'
          );
        },
      });
      setMarketRefreshDict({ ...isMarketRefreshingDict, [idMarket]: false });
    };
  const refreshMarkets = async () => {
    setProcessMarketsRefresh(true);
    const marketsToUpdate: Partial<UpsertMarketRequest>[] = Object.keys(
      selectedMarkets
    )
      .filter((key) => selectedMarkets[key] === true)
      .map((key) => {
        const market = markets.find((el) => el.marketId === Number(key));

        return {
          id_market: market.marketId,
          market_name: market.marketName,
          market_description: market.marketDescription,
          id_industries: market?.industries?.map((i) => i.industryId),
        };
      });

    const processMarkets = marketsToUpdate.map((market) =>
      upsertMarketMutation.mutateAsync(market)
    );

    const responses = await Promise.all(
      processMarkets.map(async (promise) => {
        try {
          return await promise;
        } catch (error) {
          return error;
        }
      })
    );

    const failedProcess = responses
      .map((el, index) => {
        if (el instanceof Error) {
          return { marketName: marketsToUpdate[index].market_name, error: el };
        }
        return null;
      })
      .filter((el) => el != null);

    if (failedProcess.length) {
      const errorMessage = failedProcess.reduce(
        (
          acc: React.JSX.Element,
          { marketName, error }: { marketName: string; error: $TSFixMe }
        ) => {
          const newAcc = (
            <>
              {acc}
              <br />
              <span>
                Market: {marketName}, error:{' '}
                {error?.response?.body?.error?.details || error.message}
              </span>
            </>
          );
          return newAcc;
        },
        null
      );
      message.error(
        <span className="text-left">
          Some records failed, here the list of failed records: {errorMessage}
        </span>
      );
    } else {
      await queryClient.invalidateQueries(['GetMarketsForAdmin']);
      setMarkets([]);
      setSelectedMarkets({});
      message.success('Markets updated successfully!');
    }
    setProcessMarketsRefresh(false);
  };

  const refreshMarket = (idMarket: number) => async (event: $TSFixMe) => {
    event.stopPropagation();
    setMarketRefreshDict({ ...isMarketRefreshingDict, [idMarket]: true });

    const market = markets.find((el) => el.marketId === idMarket);

    const marketToUpdate = {
      id_market: market.marketId,
      market_review_id: market.marketReviewId,
      market_name: market.marketName,
      market_description: market.marketDescription,
      id_industries: market.industries.map((i) => i.industryId),
    };

    await upsertMarketMutation.mutateAsync(marketToUpdate, {
      onError: (error: $TSFixMe) => {
        message.error(
          <span>
            An error occurs when processing record <br />{' '}
            {error?.response?.body?.error?.details || error.message}
          </span>
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['GetMarketsForAdmin']);
        setMarkets([]);
        message.success('Market updated successfully!');
      },
    });
    setMarketRefreshDict({ ...isMarketRefreshingDict, [idMarket]: false });
  };

  return {
    isMarketRefreshingDict,
    areMarketsRefreshing,
    selectMarketToRefresh,
    selectedMarkets,
    refreshMarket,
    refreshMarkets,
    areMarketsCompetitorRefreshing,
    refreshMarketsCompetitors,
    refreshMarketsCompetitor,
  };
};
