import React from 'react';
import { RouteObject } from 'react-router-dom';

const UniversityAdmin = React.lazy(
  () => import('./containers/UniversityAdmin')
);

export const universitiesRoutes: RouteObject = {
  path: 'universities',
  element: (
    <React.Suspense fallback={undefined}>
      <UniversityAdmin />
    </React.Suspense>
  ),
};
