import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Button, message } from 'antd';
import { readBlobFile } from 'client/modules/cbi-entity/services/general-info/apis/PostCompanyLogo';
import ImageUploader from 'client/modules/common/components/Image/ImageUploader';
import { useUser } from 'client/utils/user-event/hooks/useIdUser';
import React, { useState } from 'react';
import { usePostCompanyLogoMutation } from '../../services/general-info/hooks/usePostCompanyLogoMutation';
import { usePostInvestorLogoMutation } from '../../services/general-info/hooks/usePostInvestorLogoMutation';
import { usePostEntityDraftLogo } from '../../services/general-info/hooks/usePostEntityDraftLogo';

type EditCompanyLogoButtonProps = {
  idCompany?: number;
  idInvestor?: number;
  idEntityDraft?: string;
  entityName: string;
  entityUrl: string;
  onEditLogoSuccessively: () => void;
};

export const handleSuccess =
  ({ logoType = 'company', callback = () => null }) =>
  () => {
    message.success({
      content: `${logoType} logo updated successfully`,
      style: {
        marginTop: '3vh',
      },
    });
    callback();
  };

export const handleError = (error: $TSFixMe) => {
  message.error({
    content:
      error?.response?.body?.error?.details ||
      error?.message ||
      'An error has ocurred, please contact an Administrator',
    style: {
      marginTop: '3vh',
    },
  });
};

export const EditCompanyLogoButton = ({
  idCompany,
  idInvestor,
  idEntityDraft,
  entityName,
  onEditLogoSuccessively,
  entityUrl,
}: EditCompanyLogoButtonProps) => {
  const { idUser } = useUser();
  const postCompanyLogo = usePostCompanyLogoMutation();
  const postInvestorLogo = usePostInvestorLogoMutation();
  const postEntityDraftLogo = usePostEntityDraftLogo();

  const [showLogoUploader, setShowLogo] = useState(false);
  const toogleLogoModal = () => setShowLogo(!showLogoUploader);

  const handleLogoSave = async (logoFile) => {
    const readedBlob = readBlobFile(logoFile);
    await readedBlob.then((bytes) => {
      if (idEntityDraft) {
        postEntityDraftLogo.mutate(
          {
            idEntityDraft,
            image: bytes as $TSFixMe,
            imageName: logoFile.name,
          },
          {
            onSuccess: handleSuccess({
              logoType: 'draft',
              callback: onEditLogoSuccessively,
            }),
            onError: handleError,
          }
        );
      }
      if (!idEntityDraft && idCompany) {
        postCompanyLogo.mutate(
          {
            idUser,
            idCompany,
            image: bytes as $TSFixMe,
            imageName: logoFile.name,
            setAsDefault: true,
          },
          {
            onSuccess: handleSuccess({
              callback: onEditLogoSuccessively,
            }),
            onError: handleError,
          }
        );
      }
      if (!idEntityDraft && idInvestor) {
        postInvestorLogo.mutate(
          {
            idUser,
            idInvestor,
            image: bytes as $TSFixMe,
            imageName: logoFile.name,
            setAsDefault: true,
          },
          {
            onSuccess: handleSuccess({
              logoType: 'investor',
              callback: onEditLogoSuccessively,
            }),
            onError: handleError,
          }
        );
      }

      toogleLogoModal();
    });
  };

  return (
    <>
      <Button onClick={toogleLogoModal}>
        <EditOutlined className="text-blue-500" />
      </Button>
      <ImageUploader
        showModal={showLogoUploader}
        onCancel={toogleLogoModal}
        onSave={handleLogoSave}
        isGoogleSearchEnabled
        companyLogo={{
          companyName: entityName,
          primaryUrl: entityUrl,
        }}
      />
    </>
  );
};
