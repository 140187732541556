import { isEmpty, filter } from 'lodash';
import * as tabName from 'client/modules/markets/utils/tab-names';
import {
  setFailureNotification,
  setSuccessNotification,
} from 'client/modules/common/redux/actions/status-notification';
import { marketSuggestions } from '../utils/api-requests-helper';
import {
  getSelectedRecords,
  getRecordsInfo,
  getMarketIdAndNameOnly,
} from '../utils/records-helper';
import { makeSearch } from './search-bar';

export const ADD_SELECTED_MARKET =
  'client/modules/markets/merge-markets/ADD_SELECTED_MARKET';
export const ADD_SELECTED_MARKETS_BY_CHECKBOX =
  'client/modules/markets/merge-markets/ADD_SELECTED_MARKETS_BY_CHECKBOX';
export const CLEAR_MERGE_MARKETS =
  'client/modules/markets/merge-markets/CLEAR_MERGE_MARKETS';
export const DELETE_SELECTED_MARKET =
  'client/modules/markets/merge-markets/DELETE_SELECTED_MARKET';
export const SELECT_TARGET_MARKET =
  'client/modules/markets/merge-markets/SELECT_TARGET_MARKET';
export const UPDATE_MARKET_TO_MERGE_SEARCH =
  'client/modules/markets/merge-markets/UPDATE_MARKET_TO_MERGE_SEARCH';
export const UPDATE_MARKET_TO_MERGE_SUGGESTIONS =
  'client/modules/markets/merge-markets/UPDATE_MARKET_TO_MERGE_SUGGESTIONS';
export const UPDATE_TARGET_MARKET =
  'client/modules/markets/merge-markets/UPDATE_TARGET_MARKET';
export const UPDATE_TARGET_MARKET_SUGGESTIONS =
  'client/modules/markets/merge-markets/UPDATE_TARGET_MARKET_SUGGESTIONS';

export const updateMarketToMergeSearch = (searchId, searchTerm) => {
  return {
    type: UPDATE_MARKET_TO_MERGE_SEARCH,
    searchTerm,
    searchId,
  };
};

export const updateTargetMarket = (searchId, searchTerm) => {
  return {
    type: UPDATE_TARGET_MARKET,
    searchTerm,
    searchId,
  };
};

export const addSelectedMarketHelper = (searchId, searchTerm) => {
  return {
    type: ADD_SELECTED_MARKET,
    searchTerm,
    searchId,
  };
};

export const addSelectedMarket = (searchId, searchTerm) => {
  return (dispatch) => {
    if (!searchId) {
      return dispatch(
        setFailureNotification('The market you selected doesn\'t exist.')
      );
    }
    return dispatch(addSelectedMarketHelper(searchId, searchTerm));
  };
};

export const updateMarketToMergeSuggestionsHelper = (searchTerm, markets) => {
  return {
    type: UPDATE_MARKET_TO_MERGE_SUGGESTIONS,
    searchTerm,
    markets,
  };
};

export const updateMarketToMergeSuggestions = (searchTerm) => {
  return (dispatch) => {
    dispatch(marketSuggestions(searchTerm)).then(
      (data) => {
        dispatch(
          updateMarketToMergeSuggestionsHelper(searchTerm, data.markets)
        );
      },
      () => {
        dispatch(
          setFailureNotification(
            'Failed to get suggestions for the market, please try again!'
          )
        );
      }
    );
  };
};

export const updateTargetMarketSuggestionsHelper = (searchTerm, markets) => {
  return {
    type: UPDATE_TARGET_MARKET_SUGGESTIONS,
    searchTerm,
    markets,
  };
};

export const updateTargetMarketSuggestions = (searchTerm) => {
  return (dispatch) => {
    dispatch(marketSuggestions(searchTerm)).then(
      (data) => {
        dispatch(updateTargetMarketSuggestionsHelper(searchTerm, data.markets));
      },
      () => {
        dispatch(
          setFailureNotification(
            'Failed to get suggestions for the market, please try again!'
          )
        );
      }
    );
  };
};

export const deleteSelectedMarket = (id) => {
  return {
    type: DELETE_SELECTED_MARKET,
    id,
  };
};

export const selectTargetMarket = (marketId, marketName) => {
  return {
    type: SELECT_TARGET_MARKET,
    marketId,
    marketName,
  };
};

export const clearMergeMarkets = () => {
  return {
    type: CLEAR_MERGE_MARKETS,
  };
};

export const addSelectedMarketByCheckboxHelper = (selectedMarkets) => {
  return {
    type: ADD_SELECTED_MARKETS_BY_CHECKBOX,
    selectedMarkets,
  };
};

export const addSelectedMarketByCheckbox = () => {
  return (dispatch, getState) => {
    const listOfMarkets = getState().markets.tabData[
      tabName.LIST_OF_MARKETS_DETAILS
    ];

    // Get all the selected ids
    const selectedIds = getSelectedRecords(listOfMarkets.selectedRecords);

    // Get selected markets data
    const selectedMarkets = getRecordsInfo(selectedIds, listOfMarkets.records);

    // If there are markets been selected, we can dispatch this action otherwise no need.
    if (!isEmpty(selectedMarkets)) {
      dispatch(
        addSelectedMarketByCheckboxHelper(
          getMarketIdAndNameOnly(selectedMarkets)
        )
      );
    }
  };
};

export const postMergeMarkets = (marketChildren, marketParent) => ({
  types: [null, null],
  service: {
    endpoint: 'MergeMarkets',
    name: 'marketservice',
    body: {
      marketChildren,
      marketParent,
    },
  },
});

export const removeTargetMarketFromSelectedMarkets = (
  selectedMarkets,
  targetMarketId
) => {
  return filter(
    selectedMarkets,
    (selectedMarket) => selectedMarket.id !== targetMarketId
  );
};

export const mergeMarkets = (callback) => {
  return (dispatch, getState) => {
    const mergeMarketsStore = getState().markets.mergeMarkets;
    const {selectedMarkets} = mergeMarketsStore;
    const targetMarket = {
      id: mergeMarketsStore.targetMarket.searchId,
      name: mergeMarketsStore.targetMarket.searchTerm,
    };

    if (isEmpty(selectedMarkets)) {
      return dispatch(
        setFailureNotification('Please select at least one market.')
      );
    }

    if (!targetMarket.id) {
      return dispatch(
        setFailureNotification('The market you are merging to doesn\'t exist.')
      );
    }

    const cleanedSelectedMarkets = removeTargetMarketFromSelectedMarkets(
      selectedMarkets,
      targetMarket.id
    );
    return dispatch(
      postMergeMarkets(cleanedSelectedMarkets, targetMarket)
    ).then(
      () => {
        callback();
        dispatch(setSuccessNotification('Markets merged successfully!'));
        dispatch(clearMergeMarkets());
        dispatch(makeSearch());
      },
      () => {
        dispatch(
          setFailureNotification('Failed to merge markets, please try again.')
        );
      }
    );
  };
};
