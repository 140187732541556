import React, { Component } from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Radio from 'client/component-library/Radio/MuiRadio';
import RadioButtonUncheckedIcon from 'client/component-library/Radio/RadioButtonUncheckedIcon';
import RadioButtonCheckedIcon from 'client/component-library/Radio/RadioButtonCheckedIcon';
import { TableCell } from 'client/component-library/Table';
import { withStyles } from 'client/component-library/styles';
import compose from 'recompose/compose';
import radioHeaderStyles from '../../styles/material-ui/radioHeaderStyles';
import { bulkUpdateTeamMemberPermissions } from '../../../redux/actions/tab-data';

class RadioHeaderCell extends Component {
  state = {
    selectedValue: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleChange = (event) => {
    if (event.target.type === 'radio') {
      this.setState({ selectedValue: event.target.value });
      this.props.bulkUpdateTeamMemberPermissions(
        this.props.outerTableRowId,
        event.target.name
      );
      this.setState({ selectedValue: '' });
    }
  };

  render() {
    const { dataTest, name, classes, headerWidth } = this.props;
    if (name === 'No Access') {
      return (
        <TableCell
          key={dataTest}
          classes={{ tableCell: classes.tableCell }}
          width={headerWidth}
        >
          <div>{name}</div>
        </TableCell>
      );
    }

    return (
      <TableCell
        key={dataTest}
        classes={{ tableCell: classes.tableCell }}
        width={headerWidth}
      >
        <div className={classes.label}>
          <div>{name}</div>
          <Radio
            className={classes.radio}
            checked={this.state.selectedValue === name}
            onChange={this.handleChange}
            value={name}
            data-test={`${dataTest}-${name}-radio-header`}
            name={name}
            color="default"
            icon={<RadioButtonUncheckedIcon className={classes.sizeIcon} />}
            checkedIcon={
              <RadioButtonCheckedIcon className={classes.sizeIcon} />
            }
          />
        </div>
      </TableCell>
    );
  }
}

RadioHeaderCell.propTypes = {
  dataTest: PropTypes.string,
  name: PropTypes.string,
  bulkUpdateTeamMemberPermissions: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  outerTableRowId: PropTypes.string.isRequired,
  headerWidth: PropTypes.string,
};

RadioHeaderCell.defaultProps = {
  dataTest: '',
  name: '',
  headerWidth: '',
};

export default compose(
  withStyles(radioHeaderStyles),
  connect(null, {
    bulkUpdateTeamMemberPermissions,
  })
)(RadioHeaderCell);
