import { TypedUseSelectorHook, useSelector } from 'react-redux';
import adminUsersReducer, {
  AdminUsers,
} from 'client/modules/admin-user-admin/redux/reducers';
import cbiEntityReducer, {
  CbiEntity,
} from 'client/modules/cbi-entity/redux/reducers';
import commonReducer, {
  CommonState,
} from 'client/modules/common/redux/reducers';
import tableReducer, {
  TableState,
} from 'client/modules/common/components/Table/ConnectedTableComponents/redux/reducers';

import { UserData } from 'client/modules/user/redux/reducers/user';
import accountSharingDetectionReducer from 'client/modules/account-sharing-detection/redux/reducers';
import { combineReducers } from 'redux';
import dataPlatformReducer from 'client/modules/data-platform/redux/reducers';
import { reducer as formReducer } from 'redux-form';
import geographyReducer from 'client/modules/common/redux/reducers/geography.reducer';
import marketReducer from 'client/modules/markets/redux/reducers';
import navigationReducer from 'client/modules/navigation/redux/reducers';
import newsQueueReducer from 'client/modules/news/queue/redux/reducers';
import newsUserStatusReducer from 'client/modules/news/user-stats/redux/reducers';
import packagesReducer from 'client/modules/packages/redux/reducers';
import paginationReducer from 'client/modules/common/redux/reducers/pagination.reducer';
import profilesQueueReducer from 'client/modules/third-party-admin/profile-queue/redux/reducers';
import storiesReducer from 'client/modules/stories/redux/reducers';
import taxonomyReducer from 'client/modules/common/redux/reducers/taxonomy.reducer';
import universityDisplayReducer from 'client/modules/university-display/redux/reducers';
import userReducer from 'client/modules/user/redux/reducers';

export interface AppState {
  form: $TSFixMe;
  markets: $TSFixMe;
  navigation: $TSFixMe;
  table: TableState;
  user: UserData;
  adminUsers: AdminUsers;
  common: CommonState;
  packages: $TSFixMe;
  cbiEntity: CbiEntity;
  stories: $TSFixMe;
  universityDisplay: $TSFixMe;
  dataPlatform: $TSFixMe;
  accountSharingDetection: $TSFixMe;
  profilesQueue: $TSFixMe;
  newsQueue: $TSFixMe;
  newsUserStats: $TSFixMe;
  pagination: $TSFixMe;
  news: $TSFixMe;
  taxonomy: $TSFixMe;
  geography: $TSFixMe;
  newsFilterGroups: $TSFixMe;
}

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

/**
 * @deprecated
 * redux is deprecated in this project, we suggest the use of react-query
 * or zustand for global management
 */
const reducer = combineReducers({
  form: formReducer,
  markets: marketReducer,
  navigation: navigationReducer,
  table: tableReducer,
  user: userReducer,
  adminUsers: adminUsersReducer,
  common: commonReducer,
  packages: packagesReducer,
  cbiEntity: cbiEntityReducer,
  stories: storiesReducer,
  universityDisplay: universityDisplayReducer,
  dataPlatform: dataPlatformReducer,
  accountSharingDetection: accountSharingDetectionReducer,
  profilesQueue: profilesQueueReducer,
  newsQueue: newsQueueReducer,
  newsUserStats: newsUserStatusReducer,
  pagination: paginationReducer,
  taxonomy: taxonomyReducer,
  geography: geographyReducer,
});

export default reducer;
