import ErrorBoundary from 'client/modules/common/components/FormFieldErrorBoundary';
import React from 'react';
import { FormikContainer, FormikContainerProps } from './FormikContainer';
import { FormUtilsContextProvider } from './FormUtilsContextProvider';

export const FormContainerWithErrorBoundary = <
  TOptionalFormProps extends Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TSchema extends object
>({
  errorMessage,
  ...props
}: FormikContainerProps<TOptionalFormProps, TSchema> & {
  errorMessage: string;
}) => {
  return (
    <ErrorBoundary errorMessage={errorMessage}>
      <FormUtilsContextProvider>
        <FormikContainer {...props} />
      </FormUtilsContextProvider>
    </ErrorBoundary>
  );
};
