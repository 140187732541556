import { ListPackagesResponse } from '@cbinsights/userv2service/userv2service';
import { useQuery, UseQueryResult } from 'react-query';
import { UserTrialServiceKeys } from '.';
import { listPackages } from '../api/ListPackages';

type Params = Parameters<typeof listPackages>[0];
type Return = ListPackagesResponse;

export const useGetPackagesQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [UserTrialServiceKeys, Params]>(
    ['LisUserTrialKey', params],
    () => listPackages(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
