const styles = (theme) => ({
  duplicateText: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#000',
  },
  duplicateIconWrapper: {
    marginTop: '10px',
    color: '#f50057',
  },
  expandIconWrapper: {
    marginTop: '10px',
    color: '#000',
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '1.2rem',
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontWeight: 'bold',
  },
  duplicateDisplay: {
    display: 'inline-block',
  },
  duplicateDisplayText: {
    display: 'inline-block',
    fontSize: '0.75rem',
    marginLeft: '6px',
  },
  duplicateLinkText: {
    color: '#000',
  },
  duplicateStatusText: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#000',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
});

export default styles;
