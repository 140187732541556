// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-markets-components-styles-css-___markets-buttons__marketsBtn___V2EIx {
  width: 110px;
  height: 12px;
  min-height: 30px !important;
  margin: 2px 0 !important;
  padding: 5px 15px 0px 15px !important;
}

.client-modules-markets-components-styles-css-___markets-buttons__marketsBtnIcon___nrFip {
  margin-right: 5px;
  font-size: 15px !important;
  height: 1.5em !important;
}

.client-modules-markets-components-styles-css-___markets-buttons__btnLabel___GVvq1 {
  font-size: 11px;
  font-weight: 300;
  text-transform: capitalize;
  padding-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./client/modules/markets/components/styles/css/markets-buttons.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,wBAAwB;EACxB,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".marketsBtn {\n  width: 110px;\n  height: 12px;\n  min-height: 30px !important;\n  margin: 2px 0 !important;\n  padding: 5px 15px 0px 15px !important;\n}\n\n.marketsBtnIcon {\n  margin-right: 5px;\n  font-size: 15px !important;\n  height: 1.5em !important;\n}\n\n.btnLabel {\n  font-size: 11px;\n  font-weight: 300;\n  text-transform: capitalize;\n  padding-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketsBtn": `client-modules-markets-components-styles-css-___markets-buttons__marketsBtn___V2EIx`,
	"marketsBtnIcon": `client-modules-markets-components-styles-css-___markets-buttons__marketsBtnIcon___nrFip`,
	"btnLabel": `client-modules-markets-components-styles-css-___markets-buttons__btnLabel___GVvq1`
};
export default ___CSS_LOADER_EXPORT___;
