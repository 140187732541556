import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Popover,
  Space,
  Spin,
  Tabs,
  TabsProps,
} from 'antd';

import { AntdInputField } from 'client/modules/common/antDesign/form/fields/AntdInputField';
import React from 'react';
import { flushSync } from 'react-dom';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  MarketFormProps,
  MarketFormikFormProps,
} from '../hooks/useGetMarketDetailsValues';
import {
  useGetMarketCompaniesPermissions,
  useGetMarketReportsPermissions,
} from '../permissions/useGetMarketReportsPermissions';
import { EditMarketTab, MarketReportsRoutes } from '../RouterHelper';
import { DeleteMarketButton } from './DeleteMarketButton';
import { DiscardChangesButton } from './DiscardMarketButton';
import { MarketCompaniesTable } from './MarketCompaniesTable';
import { MarketInfo } from './MarketForm';

export const MarketReportsForm = ({
  values,
  status,
  submitForm,
  isSubmitting,
  isValidating,
  isDataLoading,
  setStatus,
}: MarketFormikFormProps) => {
  const navigate = useNavigate();

  const { activeTab, id } = useParams() as {
    [MarketReportsRoutes.MarketActiveTab]:
      | typeof EditMarketTab.MarketInfo
      | typeof EditMarketTab.Companies;
    [MarketReportsRoutes.MarketId]: string;
  };

  const [URLSearchParams] = useSearchParams();
  const isPendingReview =
    URLSearchParams.get(MarketReportsRoutes.IsPendingReview) === 'true';

  const { hasDraftPermission, hasPublishPermission } =
    useGetMarketReportsPermissions();

  const { hasMarketCompanyDraftPermission, hasMarketCompanyPublishPermission } =
    useGetMarketCompaniesPermissions();

  const items: TabsProps['items'] = [
    {
      label: (
        <span className="text-xl">
          Market Info
          {values[MarketFormProps.MarketId] &&
            Object.values(values.fieldWithChanges).some(
              (el) => el === true
            ) && (
              <sup className="pl-2 text-cbi-green">
                <ExclamationCircleOutlined />
              </sup>
            )}
        </span>
      ),
      key: EditMarketTab.MarketInfo,
      children: isDataLoading ? (
        <div className="flex justify-center pt-3">
          <Spin size="large" />
        </div>
      ) : (
        <MarketInfo />
      ),
    },
    {
      label: (
        <span className="text-xl">
          Companies
          {values.companiesPendingReview && (
            <sup className="pl-2 text-cbi-green">
              <ExclamationCircleOutlined />
            </sup>
          )}
        </span>
      ),
      key: EditMarketTab.Companies,
      children: isDataLoading ? (
        <div className="flex justify-center pt-3">
          <Spin size="large" />
        </div>
      ) : (
        <MarketCompaniesTable />
      ),
    },
  ];

  if (isDataLoading) {
    return (
      <div className="flex justify-center py-3">
        <Spin size="large" />
      </div>
    );
  }

  const isInReview = values[MarketFormProps.MarketReviewId] !== '0';

  const handleChangeTab = (activeKey: string) => {
    if (id !== undefined) {
      navigate(
        `/${MarketReportsRoutes.MarketReports}/${id}/${activeKey}${
          isPendingReview ? `?${MarketReportsRoutes.IsPendingReview}=true` : ''
        }`
      );
    }
  };

  return (
    <Form name="market-reports" layout="vertical">
      <Card
        title={
          <div className="flex justify-between">
            <Space>
              <DiscardChangesButton showAsIcon />
              {values[MarketFormProps.IsNew] ||
              status[MarketFormProps.IsNameEditable] ? (
                <AntdInputField
                  className="py-0 !shadow-none !min-w-[565px]"
                  name={MarketFormProps.MarketName}
                  placeholder="Market Name"
                  validateOnBlur
                  required
                />
              ) : (
                <>
                  <span>{values[MarketFormProps.MarketName]}</span>
                  {values[MarketFormProps.FieldWithChanges][
                    MarketFormProps.MarketName
                  ] && (
                    <Popover
                      content={
                        values?.publishedValues?.[MarketFormProps.MarketName]
                      }
                      title="Published"
                      trigger="hover"
                    >
                      <span className="text-cbi-green">
                        <ExclamationCircleOutlined />
                      </span>
                    </Popover>
                  )}
                </>
              )}

              {isPendingReview && (
                <span className="text-sm text-cbi-green">
                  <ExclamationCircleOutlined /> Pending Changes
                </span>
              )}
            </Space>
            {values.usersWhoModified && (
              <span className="text-gray-500 text-base">
                Updated by {values.usersWhoModified}
              </span>
            )}
          </div>
        }
        className="mb-5"
        bodyStyle={{
          paddingTop: '0px',
        }}
      >
        <Tabs activeKey={activeTab} items={items} onChange={handleChangeTab} />
        <Form.Item className="mb-0">
          <div className="mt-[16px] flex justify-between">
            <div>
              {(hasDraftPermission ||
                hasPublishPermission ||
                hasMarketCompanyDraftPermission ||
                hasMarketCompanyPublishPermission) && (
                <Button
                  data-test="save-button"
                  type="primary"
                  onClick={() => {
                    flushSync(() =>
                      setStatus({
                        ...status,
                        [MarketFormProps.SubmitType]: 'REVIEW',
                      })
                    );
                    submitForm();
                  }}
                  disabled={isSubmitting || isValidating}
                >
                  Save
                </Button>
              )}
              <DiscardChangesButton />
            </div>
            <div>
              {(hasPublishPermission || hasMarketCompanyPublishPermission) && (
                <Button
                  data-test="publish-button"
                  type="primary"
                  onClick={() => {
                    flushSync(() =>
                      setStatus({
                        ...status,
                        [MarketFormProps.SubmitType]: 'PUBLISH',
                      })
                    );
                    submitForm();
                  }}
                  disabled={isSubmitting || isValidating}
                >
                  Publish
                </Button>
              )}
              {!values[MarketFormProps.IsNew] &&
                (hasPublishPermission ||
                  ((hasDraftPermission || hasMarketCompanyDraftPermission) &&
                    isInReview)) && (
                  <DeleteMarketButton
                    idMarket={Number(values[MarketFormProps.MarketId])}
                    idMarketReview={Number(
                      values[MarketFormProps.MarketReviewId]
                    )}
                    isInReview={isInReview}
                  />
                )}
            </div>
          </div>
        </Form.Item>
      </Card>
    </Form>
  );
};
