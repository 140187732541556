import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ClusterCard } from 'client/modules/news/queue/components/ClusterCard';
import Loading from 'client/component-library/LoadingIcon';
import { useUserEvents } from 'client/utils/user-event/hooks/useUserEvent';
import { useFormUtilsContext } from 'client/modules/common/form/containers/hooks/useFormUtilsContext';
import { EntitiesSlideOverWrapper } from '../components/EntitiesSlideOver';

export function NewsQueueList({ clusters, isLoading }) {
  const {
    customContext: { handleRemoveArticle, onDiscard, onArchive, onBlockCik },
  } = useFormUtilsContext();

  const { values } = useFormikContext();
  const { recordUserEvent } = useUserEvents();
  const [activeClusterSideMenu, setActiveClusterSideMenu] = useState({});

  const _onArchive = (cluster) => {
    recordUserEvent({
      type: 'admin.nq.cluster.archiveClick',
      details: {
        idCluster: cluster.id,
      },
    });

    onArchive(cluster, values);
  };
  const _onDiscard = (cluster) => {
    recordUserEvent({
      type: 'admin.nq.cluster.discardClick',
      details: {
        idCluster: cluster.id,
      },
    });
    onDiscard(cluster, values);
  };

  const _onBlockCik = (cluster) => {
    onBlockCik(cluster, values);
  };

  const toggleSlideOver = (id) => {
    const cluster = clusters.find((cl) => cl.id === id) || {};
    setActiveClusterSideMenu({ ...cluster });
  };

  useEffect(() => {
    if (activeClusterSideMenu.id) {
      toggleSlideOver(activeClusterSideMenu.id);
    }
  }, [clusters]);

  if (isLoading) {
    return <Loading />;
  }

  if (!clusters.length) {
    return (
      <div className="flex w-full justify-center">
        There are no Clusters to view right now
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-row w-full font-bold text-xs">
        <div className="w-1/12 px-1 flex justify-center">DATE</div>
        <div className="w-1/12 px-1 flex justify-center">TYPE</div>
        <div className="w-1/12 pl-2 flex justify-center">ID</div>
        <div className="w-7/12 pl-8 flex justify-start">CONTENT</div>
        <div className="w-2/12 px-1 flex justify-start">ACTIONS</div>
      </div>
      {clusters.map((cluster) => {
        return (
          <div key={cluster.id}>
            <ClusterCard
              {...cluster}
              toggleSlideOver={toggleSlideOver}
              onRemoveArticle={handleRemoveArticle}
              onArchive={_onArchive}
              onBlockCik={_onBlockCik}
              onDiscard={_onDiscard}
            />
          </div>
        );
      })}
      {activeClusterSideMenu.entities && (
        <EntitiesSlideOverWrapper
          clusterId={activeClusterSideMenu.id}
          clusterType={activeClusterSideMenu.type}
          entities={activeClusterSideMenu.entities.filter(
            ({ isSuggestedEntity }) => !isSuggestedEntity
          )}
          onArchive={_onArchive}
          handleClose={toggleSlideOver}
        />
      )}
    </div>
  );
}

NewsQueueList.propTypes = {
  clusters: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  appendCluster: PropTypes.func,
};

export default NewsQueueList;
