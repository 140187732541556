/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-else-return */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import styles from 'client/assets/styles/common-styles.css';
import createNumberMask from './masks/createNumberMask';
import { eventWasEnterKey } from '../../modules/common/utils/domEventChecks';

const NOP = () => null;
const classes = `bg-white focus:outline-none focus:shadow-outline border border-gray-400 hover:border-gray-500 rounded py-1 px-2 block appearance-none leading-normal shadow ${styles.afsInput}`;
const labelClasses = 'text-xs text-left';

/**
 * @deprecated
 * If the input is a textarea, return TextareaAutosize, if it's a masked input, return MaskedInput,
 * otherwise return null
 * @returns A function that takes an object with a textarea and maskOptions property.
 */
function getSpecialInput({ textarea, maskOptions }) {
  if (textarea) {
    return TextareaAutosize;
  } else if (maskOptions && Object.keys(maskOptions).length) {
    return MaskedInput;
  }

  return null;
}

/**
 * @deprecated
 * It returns a React component that renders an input element, with some additional features
 * @param props - The props that are passed to the component.
 * @returns A function that takes in props and returns a div.
 */
const Input = (props) => {
  const {
    error,
    label,
    required,
    textarea,
    isDestructive,
    dataTest = `input-${props.name}`,
    maskOptions,
    shouldHighlight,
    onEnter,
    onKeyDown,
    noShadow,
    expandOnFocus,
    inputStyles,
    className,
    ...inputProps
  } = props;

  const inputClassName = cx('w-full afsInput', {
    [classes]: !isDestructive,
    [styles.cleanInput]: isDestructive,
    [styles.highlight]: shouldHighlight && !props.disabled,
    'border-red-600 hover:border-red-600': error,
    'h-20': textarea,
    [styles.expandableTextArea]: expandOnFocus,
  });

  const SpecialInput = getSpecialInput({ textarea, maskOptions });
  const inputMask = createNumberMask(maskOptions);
  const _onKeyDown = (event) => {
    if (eventWasEnterKey(event)) {
      onEnter(event);
    }
    onKeyDown(event);
  };

  return (
    <div className={`relative${inputStyles ? ` ${inputStyles}` : ''}`}>
      {label && (
        <div className={labelClasses}>
          {label}&nbsp;
          {required && <span className="text-xs text-red-600">*</span>}
        </div>
      )}

      {SpecialInput ? (
        <div className={!noShadow ? 'rounded shadow' : ''}>
          <SpecialInput
            {...inputProps}
            className={`${inputClassName} ${className}`}
            data-test={dataTest}
            mask={inputMask}
            onKeyDown={_onKeyDown}
          />
        </div>
      ) : (
        <div className={!noShadow ? 'rounded shadow' : ''}>
          <input
            {...inputProps}
            className={inputClassName}
            data-test={dataTest}
            onKeyDown={_onKeyDown}
          />
        </div>
      )}
    </div>
  );
};

export const InputProps = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnter: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  textarea: PropTypes.bool,
  isDestructive: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  maskOptions: PropTypes.object,
  shouldHighlight: PropTypes.bool,
  noShadow: PropTypes.bool,
  expandOnFocus: PropTypes.bool,
  inputStyles: PropTypes.string,
  className: PropTypes.string,
};

Input.propTypes = InputProps;

Input.defaultProps = {
  value: '',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  disabled: false,
  required: false,
  textarea: false,
  isDestructive: false,
  onChange: NOP,
  onBlur: NOP,
  maskOptions: {},
  shouldHighlight: false,
  onEnter: NOP,
  onKeyDown: NOP,
  noShadow: false,
  expandOnFocus: false,
  inputStyles: '',
  className: '',
};

export default Input;
