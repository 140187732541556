const styles = () => ({
  icon: {
    width: '20px',
    height: '20px',
    marginTop: '-4px',
  },
  copyIcon: {
    padding: '0 !important',
    minHeight: '0',
    minWidth: '90px',
    float: 'right',
  },
});

export default styles;
