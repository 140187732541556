/**
 * debounce functions that returns promises
 * @param {function} debounceFunction
 * @param {Number} options.delay
 */
export default function asyncDebounce(debounceFunction, options = {}) {
  const { delay = 300 } = options;
  let timeout = null;

  return (...args) => {
    clearTimeout(timeout);
    return new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        try {
          resolve(debounceFunction(...args));
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
}
