import React, { ReactElement, useMemo } from 'react';
import {
  Alert,
  Button,
  Col,
  Empty,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import { createUserTrial } from '../services/api/CreateUserTrial';
import { updateUserTrial } from '../services/api/UpdateUserTrial';
import { DATE_FORMAT, TRIAL_DURATION } from '../constants';
import useTrialPackageForm, {
  TrialPackageForm,
} from '../hooks/useTrialPackageForm';

const fieldValues = {
  userId: {
    name: 'userId',
    pattern: /^[0-9]+$/,
  },
  package: 'package',
};

const { Text } = Typography;

type Props = {
  defaultUserId?: string;
  disabledUserId?: boolean;
};

export const TrialPackagePage = ({
  defaultUserId,
  disabledUserId,
}: Props): ReactElement => {
  const {
    form,
    resetForm,
    values: {
      packageOptions,
      packageSearchValue,
      userTrialDetail,
      activeTrial,
      initialValues,
      packageId,
      userId,
    },
    setValues: { handlePackageSearch, setPackageId, setUserId },
  } = useTrialPackageForm({ defaultUserId });

  const disabledSave = useMemo(() => {
    return form.getFieldsError().some(({ errors }) => errors.length);
  }, [form.getFieldsValue()]);

  const cancelTrial = () => {
    updateUserTrial({
      ...userTrialDetail,
      ts_trial_package_end: moment()
        .startOf('day')
        .subtract(1, 'days')
        .format(DATE_FORMAT),
    })
      .then(() => {
        resetForm();
        message.success('Success! The trial has been successfully canceled.');
      })
      .catch(() => {
        message.error(
          'Error! Failed to cancel trial information. Please check your inputs and try again later.'
        );
      });
  };

  const updateEntry = (packageStartDate: string, packageEndDate: string) => {
    updateUserTrial({
      ...userTrialDetail,
      ts_trial_package_start: packageStartDate,
      ts_trial_package_end: packageEndDate,
    })
      .then(() => {
        resetForm(!activeTrial);
        message.success(
          'Success! Your trial information has been successfully submitted.'
        );
      })
      .catch(() => {
        message.error(
          'Error! Failed to create trial information. Please check your inputs and try again later.'
        );
      });
  };

  const createEntry = (
    values: TrialPackageForm,
    packageStartDate: string,
    packageEndDate: string
  ) => {
    createUserTrial({
      id_package_trial: parseInt(values.package, 10),
      id_user: parseInt(values.userId, 10),
      ts_trial_package_start: packageStartDate,
      ts_trial_package_end: packageEndDate,
    })
      .then(() => {
        message.success(
          'Success! Your trial information has been successfully submitted.'
        );
        resetForm();
      })
      .catch(() => {
        message.error(
          'Error! Failed to create trial information. Please check your inputs and try again later.'
        );
      });
  };

  const handleFetch = (values: TrialPackageForm) => {
    const now = moment().startOf('day');

    const packageStartDate = now.format(DATE_FORMAT);
    const packageEndDate = now.add(TRIAL_DURATION, 'days').format(DATE_FORMAT);

    if (userTrialDetail) {
      updateEntry(packageStartDate, packageEndDate);
    } else {
      createEntry(values, packageStartDate, packageEndDate);
    }
  };

  return (
    <Row className="pt-6">
      <Col
        xs={{ push: 1, span: 22 }}
        md={{ push: 2, span: 20 }}
        className="max-w-xl"
      >
        <Alert
          className="mb-3"
          message="Changes in the trial package may take up to 5 minutes to be reflected in the user's information."
          type="info"
        />
        <Typography.Title level={4} className="!font-medium ">
          Trial Package
        </Typography.Title>
        <Form
          form={form}
          onFinish={handleFetch}
          initialValues={initialValues}
          labelCol={{ sm: { span: 24 }, xl: { span: 4 } }}
          wrapperCol={{ sm: { span: 24 }, xl: { span: 18 } }}
        >
          <Form.Item
            className="mb-3"
            name="userId"
            label="User ID"
            rules={[
              { required: true },
              {
                pattern: fieldValues.userId.pattern,
                message: 'Enter a valid UserId',
              },
            ]}
          >
            <Input
              onBlur={(e) => setUserId(parseInt(e.target.value, 10))}
              placeholder="Enter a User ID"
              value={userId}
              disabled={disabledUserId}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="package"
            label="Package"
            rules={[{ required: !activeTrial }]}
          >
            {activeTrial ? (
              <Text>{activeTrial.name}</Text>
            ) : (
              <Select
                showSearch
                placeholder="Select a Package"
                filterOption={false}
                onSearch={handlePackageSearch}
                options={packageOptions}
                notFoundContent={packageSearchValue ? <Empty /> : null}
                onSelect={(key) => setPackageId(key)}
                value={packageId}
              />
            )}
          </Form.Item>
          <Form.Item className="mb-5" label="End Date" shouldUpdate>
            <Row>
              <Col span={12} className="flex items-center">
                <Text>
                  {activeTrial && userTrialDetail
                    ? moment(userTrialDetail.ts_trial_package_end).format(
                        'MM-DD-YY'
                      )
                    : ''}
                </Text>
              </Col>
              <Col span={12} className="flex justify-end">
                {activeTrial && (
                  <Popconfirm
                    placement="top"
                    title="are you sure you want to cancel this trial?"
                    onConfirm={cancelTrial}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="text" danger>
                      Cancel Trial
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item className="mb-4" label="">
            <Button type="primary" htmlType="submit" disabled={disabledSave}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
