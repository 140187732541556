import { DefaultOptionType } from 'antd/lib/select';
import { FormikFormProps } from 'client/modules/common/form/containers/FormikContainer';
import {
  GetMarketDetailsForAdminResponse,
  Industry,
  MarketEntityInReview,
  MarketEntityInReviewStatus,
  MarketInReview,
} from '@cbinsights/espmatrixservice/espmatrixservice';
import {
  GeneralCbiEntitiesInfo,
  GeneralCbiEntityInfo,
  IdType,
} from '@cbinsights/cbientityservice/cbientityservice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { mapIndustries } from './useIndustryList';
import { useGeneralCbiEntitiesInfo } from '../service/hooks/useGetGeneralCbiEntitiesInfo';
import { useGetMarketDetailsForAdmin } from '../service/hooks/useGetMarketDetails';
import { useListIndustries } from '../service/hooks/useListIndustries';
import { EditMarketTab, MarketReportsRoutes } from '../RouterHelper';
import { useGetMarketsForAdmin } from '../service/hooks/useGetMarkets';

export const MarketFormProps = {
  IsNew: 'isNew',
  IsNameEditable: 'isNameEditable',
  MarketId: 'idMarket',
  MarketReviewId: 'marketReviewId',
  MarketName: 'marketName',
  MarketDescription: 'marketDescription',
  Industry: 'industry',
  PublishedValues: 'publishedValues',
  FieldWithChanges: 'fieldWithChanges',
  SubIndustry1: 'subIndustry1',
  SubIndustry2: 'subIndustry2',
  Companies: 'companies',
  ConsiderForScoring: 'considerForScoring',
  SubmitType: 'submitType',
  UsersWhoModified: 'usersWhoModified',
} as const;

export const initialNewMarketStatus: MarketFormStatus = {
  [MarketFormProps.IsNew]: true,
  [MarketFormProps.IsNameEditable]: true,
  [MarketFormProps.SubmitType]: undefined,
};
export const initialEditMarketStatus: MarketFormStatus = {
  [MarketFormProps.IsNew]: false,
  [MarketFormProps.IsNameEditable]: false,
  [MarketFormProps.SubmitType]: undefined,
};

export const initialValuesNew: MarketFormValues = {
  [MarketFormProps.MarketDescription]: '',
  [MarketFormProps.MarketName]: '',
  [MarketFormProps.Industry]: undefined,
  [MarketFormProps.SubIndustry1]: undefined,
  [MarketFormProps.SubIndustry2]: undefined,
  [MarketFormProps.Companies]: [],
  [MarketFormProps.PublishedValues]: {
    [MarketFormProps.MarketName]: undefined,
    [MarketFormProps.MarketDescription]: undefined,
    [MarketFormProps.Industry]: undefined,
    [MarketFormProps.SubIndustry1]: undefined,
    [MarketFormProps.SubIndustry2]: undefined,
  },
  [MarketFormProps.FieldWithChanges]: {
    [MarketFormProps.MarketName]: undefined,
    [MarketFormProps.MarketDescription]: undefined,
    [MarketFormProps.Industry]: undefined,
    [MarketFormProps.SubIndustry1]: undefined,
    [MarketFormProps.SubIndustry2]: undefined,
  },
  [MarketFormProps.UsersWhoModified]: undefined,
};

export type MarketCompany = {
  idCbiEntity: number;
  entityName: string;
  url: string;
  status?: MarketEntityInReviewStatus;
  [MarketFormProps.ConsiderForScoring]: boolean;
  idCompany?: number;
  idInvestor?: number;
};

export type MarketFormValues = {
  [MarketFormProps.MarketId]?: string;
  [MarketFormProps.MarketReviewId]?: string;
  [MarketFormProps.MarketName]: string;
  [MarketFormProps.MarketDescription]: string;
  [MarketFormProps.Industry]: DefaultOptionType;
  [MarketFormProps.SubIndustry1]?: DefaultOptionType & { parentId: string };
  [MarketFormProps.SubIndustry2]?: DefaultOptionType & { parentId: string };
  [MarketFormProps.Companies]: MarketCompany[];
  [MarketFormProps.PublishedValues]?: {
    [MarketFormProps.MarketName]: string;
    [MarketFormProps.MarketDescription]: string;
    [MarketFormProps.Industry]: number;
    [MarketFormProps.SubIndustry1]?: number;
    [MarketFormProps.SubIndustry2]?: number;
  };
  [MarketFormProps.FieldWithChanges]?: {
    [MarketFormProps.MarketName]: boolean;
    [MarketFormProps.MarketDescription]: boolean;
    [MarketFormProps.Industry]: boolean;
    [MarketFormProps.SubIndustry1]?: boolean;
    [MarketFormProps.SubIndustry2]?: boolean;
  };
  [MarketFormProps.UsersWhoModified]: string;
  companiesPendingReview?: boolean;
  originalValues?: GetMarketDetailsForAdminResponse;
};
export type MarketFormStatus = {
  [MarketFormProps.IsNew]: boolean;
  [MarketFormProps.IsNameEditable]: boolean;
  [MarketFormProps.SubmitType]: 'REVIEW' | 'PUBLISH';
};

export type MarketFormikFormProps = FormikFormProps<
  { isDataLoading: boolean },
  MarketFormValues,
  MarketFormStatus
>;

const VALID_ENTITY_STATUS = [
  MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_ADDED,
  MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_DELETED,
];

const mapMarketEntityInfo = (
  entity: MarketEntityInReview,
  entitiesData: GeneralCbiEntitiesInfo
) => {
  const foundEntity: GeneralCbiEntityInfo = entitiesData?.entities?.find(
    (el) => Number(el.idCbiEntity) === Number(entity.id_cbi_entity)
  );
  return {
    entityName: foundEntity?.entityNameInfo?.primaryName || 'Entity Name',
    url: foundEntity?.urlInfo?.primaryUrl || 'www.domain.com',
    idCompany: foundEntity?.idCompany || undefined,
    idInvestor: foundEntity?.idInvestor || undefined,
  };
};

const mapMarketToFormData = (
  market: MarketInReview,
  industries: Industry[],
  entitiesData: GeneralCbiEntitiesInfo
): Omit<MarketFormValues, 'originalValues'> => {
  const industryList = mapIndustries(market?.id_industries, industries);
  return {
    [MarketFormProps.MarketId]: market?.id_market.toString(),
    [MarketFormProps.MarketReviewId]: market?.id_market_review.toString(),
    [MarketFormProps.MarketName]: market?.market_name,
    [MarketFormProps.MarketDescription]: market?.market_description,
    [MarketFormProps.Industry]: {
      label: industryList[0]?.industryName,
      value: industryList[0]?.industryId,
    },
    [MarketFormProps.SubIndustry1]: industryList[1]
      ? {
          label: industryList[1]?.industryName,
          value: industryList[1]?.industryId,
          parentId: industryList[1]?.parentIndustryId.toString(),
        }
      : undefined,
    [MarketFormProps.SubIndustry2]: industryList[2]
      ? {
          label: industryList[2]?.industryName,
          value: industryList[2]?.industryId,
          parentId: industryList[2]?.parentIndustryId.toString(),
        }
      : undefined,

    [MarketFormProps.Companies]: market?.market_entities_in_review
      ? market?.market_entities_in_review.map(
          (entity): MarketCompany => ({
            idCbiEntity: Number(entity.id_cbi_entity),
            status: VALID_ENTITY_STATUS.includes(entity?.status)
              ? entity.status
              : undefined,
            [MarketFormProps.ConsiderForScoring]: entity.consider_for_scoring,
            ...mapMarketEntityInfo(entity, entitiesData),
          })
        )
      : [],
    companiesPendingReview:
      market?.market_entities_in_review.filter((el) =>
        VALID_ENTITY_STATUS.includes(el.status)
      ).length > 0,
    [MarketFormProps.UsersWhoModified]: market?.modifiers.join(','),
  };
};

const mapEntitiesInReview = (
  market_entities_in_review: MarketEntityInReview[]
) =>
  market_entities_in_review?.map((entity) => ({
    id: entity.id_cbi_entity,
    idType: IdType.CBI_ENTITY,
  })) || undefined;

export const useGetMarketDetailsValues = () => {
  const { data: marketList, isFetched } = useGetMarketsForAdmin({
    enabled: true,
  });
  const { id } = useParams<{ [MarketReportsRoutes.MarketId]: string }>();
  const [URLSearchParams] = useSearchParams();
  const isPendingReview = URLSearchParams.get(
    MarketReportsRoutes.IsPendingReview
  );

  const navigation = useNavigate();

  // redirect if the market_id has review_market_id
  useEffect(() => {
    if (!isPendingReview && isFetched) {
      const marketReview = marketList.markets.find(
        (el) => Number(el.id_market_review) !== 0
      );
      if (
        marketReview &&
        !marketReview?.id_market &&
        marketReview?.id_market_review
      ) {
        navigation(
          `/${MarketReportsRoutes.MarketReports}/${marketReview.id_market_review}/${EditMarketTab.MarketInfo}?${MarketReportsRoutes.IsPendingReview}=true`
        );
      }
    }
  }, [isFetched]);

  const { data, isFetched: isFetchedMarketDetails } =
    useGetMarketDetailsForAdmin({
      id_market: isPendingReview ? null : Number(id),
      id_market_review: isPendingReview ? Number(id) : null,
    });

  const { data: industries } = useListIndustries({ enabled: false });

  const { data: entitiesData, isFetching: isFetchingEntitiesInfo } =
    useGeneralCbiEntitiesInfo(
      {
        entities:
          mapEntitiesInReview(
            data?.market_in_review?.market_entities_in_review
          ) ||
          mapEntitiesInReview(data?.market?.market_entities_in_review) ||
          [],
        includeTier2Entity: true,
      },
      data?.market_in_review?.market_entities_in_review?.length > 0 ||
        data?.market?.market_entities_in_review?.length > 0
    );

  const optionalFormProps = {
    isDataLoading: !isFetchedMarketDetails || isFetchingEntitiesInfo,
  };

  const initialValues: MarketFormValues = {
    ...mapMarketToFormData(
      Number(data?.market_in_review?.id_market_review)
        ? data?.market_in_review
        : data?.market,
      industries,
      entitiesData
    ),
    [MarketFormProps.PublishedValues]: {
      [MarketFormProps.MarketName]: data?.market?.market_name,
      [MarketFormProps.MarketDescription]: data?.market?.market_description,
      [MarketFormProps.Industry]: data?.market?.id_industries[0],
      [MarketFormProps.SubIndustry1]: data?.market?.id_industries[1],
      [MarketFormProps.SubIndustry2]: data?.market?.id_industries[2],
    },
    [MarketFormProps.FieldWithChanges]:
      Number(data?.market?.id_market) &&
      Number(data?.market_in_review?.id_market_review)
        ? {
            [MarketFormProps.MarketName]:
              data?.market?.market_name !== data?.market_in_review?.market_name,
            [MarketFormProps.MarketDescription]:
              data?.market?.market_description !==
              data?.market_in_review?.market_description,
            [MarketFormProps.Industry]:
              data?.market?.id_industries[0] !==
              data?.market_in_review?.id_industries[0],
            [MarketFormProps.SubIndustry1]:
              data?.market?.id_industries[1] !==
              data?.market_in_review?.id_industries[1],
            [MarketFormProps.SubIndustry2]:
              data?.market?.id_industries[2] !==
              data?.market_in_review?.id_industries[2],
          }
        : {
            [MarketFormProps.MarketName]: false,
            [MarketFormProps.MarketDescription]: false,
            [MarketFormProps.Industry]: false,
            [MarketFormProps.SubIndustry1]: false,
            [MarketFormProps.SubIndustry2]: false,
          },
    originalValues: data,
  };

  return {
    optionalFormProps,
    initialValues,
  };
};
