const getLabelToSideBarProps = (label, defaultRercordOrder) => {
  const propMap = {
    processor: ['service_name', 'job_mode', 'create_date', 'last_updated_date'],
    topic: ['topic_name', 'arn', 'create_date', 'last_updated_date'],
    queue: ['queue_name', 'queue_url', 'create_date', 'last_updated_date'],
    s3_bucket: [
      'bucket_name',
      'bucket_creation_date',
      'create_date',
      'last_updated_date',
    ],
    table: ['table_name', 'database', 'create_date', 'last_updated_date'],
    pipeline: ['pipeline_name', 'create_date', 'last_updated_date'],
  };
  if (label in propMap) {
    return propMap[label];
  }
  return defaultRercordOrder;
};

export default getLabelToSideBarProps;
