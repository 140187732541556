/* eslint-disable react/no-unused-state */
/* eslint-disable react/default-props-match-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Modal from 'client/component-library/modals/MaterialUIModal';
import { Input } from '@material-ui/core';
import Tooltip from 'client/component-library/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Button from 'client/component-library/buttons/Button';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import TableFooter from 'client/modules/common/components/Table/TableComponents/TableFooter';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from 'client/component-library/ExpansionPanel';
import SelectWithSearch from 'client/component-library/Select/SearchableSelect/SelectWithSearch';
import styles from 'client/component-library/Input/input.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withReactRouter } from 'client/routes/withReactRouter';
import iconButtonStyle from '../containers/styles/material-ui/buttonStyle';

import {
  fetchAdditionalComponentCandidate,
  removeAdditionalComponentCandidate,
  clearAdditionalComponentCandidates,
  clearSuggestedPipelineVertexes,
  createPipeline,
  getSuggestedPipelineVertexes,
  setDisplayNewVertexes,
  addSuggestedExcludeLabel,
  clearSuggestedExcludeLabels,
  allSuggestedExcludeLabels,
  getAllIds,
} from '../redux/actions/search';
import {
  outerStyle,
  modalHeaderTextStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import { PIPELINE_GRAPH_ROUTE } from './utils/get-tab-options';
import {
  candidateColumns,
  suggestedComponentColumns,
} from './Table/utils/table-columns';
import {
  updateSuggestionRecordsPerPage,
  updateSuggestionPageNumber,
  initializeSuggestionDataDetailsFromUrlParams,
  updateNewVertexesPageNumber,
  updateNewVertexesRecordsPerPage,
  initializeNewVertexesDataDetailsFromUrlParams,
} from '../redux/actions/details';
import ExcludeLabelsSelect from './ExcludeLabelsSelect';
import {
  getUsersWithAccessToDataPlatform,
  resetManagePermissionsPage,
  setDisplayNewUsers,
  setDisplayAdminUsers,
} from '../redux/actions/users';
import PipelinePermissionsFlex from './PipelinePermissionsFlex';

const errorStyle = {
  color: 'red',
  fontSize: '12px',
  position: 'absolute',
  bottom: '4px',
};

class AddPipeline extends Component {
  popOverDivStyle = {
    ...outerStyle,
    width: '1200px',
    borderColor: '#4cabce',
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddNodePopover: false,
      searchTerm: '',
      expanded: true,
      pipelineName: '',
      searchError: '',
      searchLabel: -1,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initializeSuggestionDataDetailsFromUrlParams(
      this.props.location.query
    );
    this.props.initializeNewVertexesDataDetailsFromUrlParams(
      this.props.location.query
    );
    this.props.getUsersWithAccessToDataPlatform();
  }

  setSearchLabel = ({ id }) => {
    this.setState({ searchLabel: id });
    this.props.getAllIds({ labels: [id] });
  };

  setSearchTerm = ({ id }) => {
    this.setState({ searchTerm: id });
  };

  setPipelineName = (e) => {
    this.setState({ pipelineName: e.target.value });
  };

  fetchAdditionalComponentCandidate = () => {
    this.props
      .fetchAdditionalComponentCandidate({
        idVertex: this.state.searchTerm,
        pageNumber: this.props.newVertexesPageNumber,
        recordsPerPage: this.props.newVertexesRecordsPerPage,
        excludeLabels: this.props.suggestedExcludeLabels,
      })
      .then(() => this.setState({ searchError: '' }))
      .catch(() => this.setState({ searchError: 'Not Found!' }));
  };

  openAddNodePopover = () => {
    this.props.setDisplayAdminUsers({
      adminUsers: this.props.adminUsers,
      pipelineUsers: {},
      newUsers: this.props.newUsers,
      pageNumber: this.props.adminUserPageNumber,
      recordsPerPage: this.props.adminUserRecordsPerPage,
      filterTerm: this.props.adminUserFilterTerm,
    });
    this.props.setDisplayNewUsers(
      this.props.newUsers,
      this.props.newUserPageNumber,
      this.props.newUserRecordsPerPage
    );
    this.setState({ showAddNodePopover: true });
  };

  closeAddNodePopover = () => {
    this.setState({ showAddNodePopover: false });
  };

  clearAdditionalComponentCandidates = () => {
    this.props.clearAdditionalComponentCandidates();
    this.props.clearSuggestedPipelineVertexes();
    this.props.updateSuggestionPageNumber(0);
    this.props.updateNewVertexesPageNumber(0);
  };

  handleChangeSuggestionPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.newVertexes.length) {
      this.props.updateSuggestionPageNumber(page);
      this.props.getSuggestedPipelineVertexes({
        vertexes: this.props.newVertexes,
        pageNumber: page,
        recordsPerPage: this.props.suggestionRecordsPerPage,
        excludeLabels: this.props.suggestedExcludeLabels,
      });
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  handleUpdateSuggestionRecordsPerPage = (event) => {
    if (this.props.newVertexes.length) {
      this.props.updateSuggestionRecordsPerPage(event.target.value);
      this.props.updateSuggestionPageNumber(0);
      this.props.getSuggestedPipelineVertexes({
        vertexes: this.props.newVertexes,
        pageNumber: 0,
        recordsPerPage: event.target.value,
        excludeLabels: this.props.suggestedExcludeLabels,
      });
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  handleChangeNewVertexesPageNumber = (event, page) => {
    // This action is fired on page load, with a null event and page = 1.
    // We are ignoring it, and instead using what's in the state.
    if (event === null) {
      return;
    }
    if (this.props.newVertexes.length) {
      this.props.updateNewVertexesPageNumber(page);
      this.props.setDisplayNewVertexes(
        page,
        this.props.newVertexesRecordsPerPage
      );
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  handleUpdateNewVertexesRecordsPerPage = (event) => {
    if (this.props.newVertexes.length) {
      this.props.updateNewVertexesRecordsPerPage(event.target.value);
      this.props.updateNewVertexesPageNumber(0);
      this.props.setDisplayNewVertexes(0, event.target.value);
    } else {
      window.alert(`Please add some vertexes first!`);
    }
  };

  createPipeline = () => {
    if (this.props.newVertexes.length < 2) {
      /* eslint-disable max-len */
      window.alert(
        'You must include at least two vertexes to create a pipeline. Try looking through suggested components or searching components by ID'
      );
      /* eslint-enable max-len */
    } else if (this.state.pipelineName.length === 0) {
      window.alert('You must include a pipeline name');
    } else {
      this.props.createPipeline({
        pipelineName: this.state.pipelineName,
        vertexes: this.props.newVertexes,
        sortField: this.props.sortField,
        sortDirection: this.props.sortDirection,
        recordsPerPage: this.props.recordsPerPage,
        pageNumber: this.props.pageNumber,
        navigation: this.props.navigation,
        tabName: `${PIPELINE_GRAPH_ROUTE}`,
        users: this.props.newUsers,
        filterByCallingUser: this.props.filterByCallingUser,
      });
    }
  };

  excludeLabelGetSuggestedPipelineVertexes = () => {
    this.props.getSuggestedPipelineVertexes({
      vertexes: this.props.newVertexes,
      pageNumber: 0,
      recordsPerPage: this.props.suggestionRecordsPerPage,
      excludeLabels: this.props.suggestedExcludeLabels,
    });
  };

  clearSuggestedExcludeLabels = () => {
    this.props.clearSuggestedExcludeLabels(
      0,
      this.props.suggestionRecordsPerPage
    );
  };

  allSuggestedExcludeLabels = () => {
    this.props.allSuggestedExcludeLabels(
      0,
      this.props.suggestionRecordsPerPage,
      this.props.labels
    );
  };

  toggleExpandedState = (event, expanded) => {
    this.setState({ expanded });
  };

  cancelAddPipelines = () => {
    this.setState({ showAddNodePopover: false });
    this.clearAdditionalComponentCandidates();
    this.clearSuggestedExcludeLabels();
    this.props.resetManagePermissionsPage();
  };

  render() {
    return (
      <div id="you-mad">
        <Tooltip title="Create New Pipeline">
          <IconButton
            style={{ ...iconButtonStyle, backgroundColor: '#006699' }}
            onClick={this.openAddNodePopover}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <Icon
              name="add"
              family="material"
              className={styles.iconClassPossitionHack}
            />
          </IconButton>
        </Tooltip>
        <Modal
          classes={{ paperScrollPaper: styles.modalClassName }}
          id="add-node-popover"
          open={this.state.showAddNodePopover}
          handleCloseModal={this.closeAddNodePopover}
        >
          <div style={this.headerStyle} id="headerStyle-123">
            <div style={{ backgroundColor: '#4cabce' }}>
              <p
                style={{
                  ...modalHeaderTextStyle,
                  width: '160px',
                  display: 'inline-block',
                }}
              >
                Create a New Pipeline
              </p>
              <Input
                onChange={this.setPipelineName}
                id="data-platform-new-pipeline-name"
                placeholder="Enter Name Here..."
                autoFocus
                className={styles.white}
              />
            </div>
            <div
              style={{ padding: '8px', height: '70vh', overflowY: 'scroll' }}
            >
              <div className="flex">
                <div className="flex-grow">
                  <div className="flex flex-col">
                    <div>
                      <div className="flex">
                        <div className="flex flex-col flex-shrink">
                          <p>2. Add Components</p>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              Search Components by ID
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <SelectWithSearch
                                value={this.state.searchLabel}
                                options={this.props.labels.map((label) => ({
                                  id: label,
                                  name: label,
                                }))}
                                onChange={this.setSearchLabel}
                                className={styles.componentSearch}
                              />
                              <SelectWithSearch
                                value={this.state.searchTerm}
                                options={this.props.labelsIds.map((label) => ({
                                  id: label,
                                  name: label,
                                }))}
                                onChange={this.setSearchTerm}
                                className={styles.componentSearch}
                              />
                              <Button
                                onClick={this.fetchAdditionalComponentCandidate}
                              >
                                Add
                              </Button>
                              {this.state.searchError && (
                                <span style={errorStyle}>
                                  {' '}
                                  {this.state.searchError}
                                </span>
                              )}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <ExpansionPanel
                            defaultExpanded={
                              this.props.suggestedVertexRecordOrder.length > 0
                            }
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <p style={{ fontFamily: 'Roboto' }}>
                                Suggested Components
                              </p>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <div className="flex flex-shrink">
                                <ExcludeLabelsSelect
                                  excludeLabels={
                                    this.props.suggestedExcludeLabels
                                  }
                                  addExcludeLabel={
                                    this.props.addSuggestedExcludeLabel
                                  }
                                  clearExcludeLabels={
                                    this.clearSuggestedExcludeLabels
                                  }
                                  allExcludeLabels={
                                    this.allSuggestedExcludeLabels
                                  }
                                  filter={
                                    this
                                      .excludeLabelGetSuggestedPipelineVertexes
                                  }
                                />
                              </div>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                              <Table>
                                <TableHeader
                                  columnNames={suggestedComponentColumns}
                                />
                                <TableBody
                                  records={this.props.suggestedVertexRecords}
                                  columnNames={suggestedComponentColumns}
                                  recordsOrder={
                                    this.props.suggestedVertexRecordOrder
                                  }
                                  recordCellStyles={
                                    this.props.suggestedVertexRecordCellStyles
                                  }
                                />
                                <TableFooter
                                  totalRecords={this.props.totalSuggestions}
                                  recordsPerPage={
                                    this.props.suggestionRecordsPerPage
                                  }
                                  pageNumber={this.props.suggestionPageNumber}
                                  handleChangePageNumber={
                                    this.handleChangeSuggestionPageNumber
                                  }
                                  handleUpdateRecordsPerPage={
                                    this.handleUpdateSuggestionRecordsPerPage
                                  }
                                  rowsPerPageOptions={[3, 6, 9]}
                                />
                              </Table>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                        <div className="flex flex-col flex-grow">
                          <p style={{ fontFamily: 'Roboto' }}>
                            3.Review Components
                            <Button
                              onClick={this.clearAdditionalComponentCandidates}
                            >
                              Remove All
                            </Button>
                          </p>
                          <Table>
                            <TableHeader columnNames={candidateColumns} />
                            <TableBody
                              records={this.props.newVertexRecords}
                              columnNames={candidateColumns}
                              recordsOrder={this.props.newVertexRecordsOrder}
                              recordCellStyles={
                                this.props.newVertexRecordCellStyle
                              }
                            />
                            <TableFooter
                              totalRecords={this.props.numNewVertexes}
                              recordsPerPage={
                                this.props.newVertexesRecordsPerPage
                              }
                              pageNumber={this.props.newVertexesPageNumber}
                              handleChangePageNumber={
                                this.handleChangeNewVertexesPageNumber
                              }
                              handleUpdateRecordsPerPage={
                                this.handleUpdateNewVertexesRecordsPerPage
                              }
                              rowsPerPageOptions={[3, 6, 9]}
                            />
                          </Table>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        paddingTop: '5px',
                      }}
                    />
                    <PipelinePermissionsFlex
                      manageHeader="4. Manage Users"
                      newHeader="5. Review Users"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button onClick={this.createPipeline}>Save</Button>
              <Button onClick={this.cancelAddPipelines}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    newPipelineComponents: dataPlatform.search.newPipelineComponents,
    newVertexRecords: dataPlatform.search.newVertexRecords,
    newVertexRecordsOrder: dataPlatform.search.newVertexRecordsOrder,
    newVertexes: dataPlatform.search.newVertexes,
    suggestedVertexRecords: dataPlatform.search.suggestedVertexRecords,
    suggestedVertexRecordOrder: dataPlatform.search.suggestedVertexRecordOrder,
    latestNewVertex: dataPlatform.search.latestNewVertex,
    newVertexRecordCellStyle: dataPlatform.search.newVertexRecordCellStyle,
    totalSuggestions: dataPlatform.search.totalSuggestions,
    suggestionRecordsPerPage: dataPlatform.details.suggestionRecordsPerPage,
    suggestionPageNumber: dataPlatform.details.suggestionPageNumber,
    suggestedVertexRecordCellStyles:
      dataPlatform.search.suggestedVertexRecordCellStyles,
    numNewVertexes: dataPlatform.search.numNewVertexes,
    newVertexesRecordsPerPage: dataPlatform.details.newVertexesRecordsPerPage,
    newVertexesPageNumber: dataPlatform.details.newVertexesPageNumber,
    pageNumber: dataPlatform.details.pageNumber,
    recordsPerPage: dataPlatform.details.recordsPerPage,
    suggestedExcludeLabels: dataPlatform.search.suggestedExcludeLabels,
    labels: dataPlatform.search.labels,
    labelsIds: dataPlatform.search.labelsIds,
    newUsers: dataPlatform.users.newUsers,
    newUserPageNumber: dataPlatform.users.newUserPageNumber,
    newUserRecordsPerPage: dataPlatform.users.newUserRecordsPerPage,
    adminUsers: dataPlatform.users.adminUsers,
    adminUserPageNumber: dataPlatform.users.adminUserPageNumber,
    adminUserRecordsPerPage: dataPlatform.users.adminUserRecordsPerPage,
    adminUserFilterTerm: dataPlatform.users.adminUserFilterTerm,
    filterByCallingUser: dataPlatform.search.filterByCallingUser,
  };
}

AddPipeline.propTypes = {
  createPipeline: PropTypes.func.isRequired,
  newVertexRecords: PropTypes.object,
  newVertexRecordsOrder: PropTypes.arrayOf(PropTypes.string),
  fetchAdditionalComponentCandidate: PropTypes.func.isRequired,
  getAllIds: PropTypes.func.isRequired,
  newVertexes: PropTypes.arrayOf(PropTypes.object),
  suggestedVertexRecords: PropTypes.object,
  suggestedVertexRecordOrder: PropTypes.arrayOf(PropTypes.string),
  clearAdditionalComponentCandidates: PropTypes.func.isRequired,
  navigation: PropTypes.func.isRequired,
  suggestionPageNumber: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  suggestionRecordsPerPage: PropTypes.number.isRequired,
  newVertexRecordCellStyle: PropTypes.object,
  suggestedVertexRecordCellStyles: PropTypes.object,
  clearSuggestedPipelineVertexes: PropTypes.func.isRequired,
  updateSuggestionPageNumber: PropTypes.func.isRequired,
  updateSuggestionRecordsPerPage: PropTypes.func.isRequired,
  initializeSuggestionDataDetailsFromUrlParams: PropTypes.func.isRequired,
  totalSuggestions: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  getSuggestedPipelineVertexes: PropTypes.func.isRequired,
  numNewVertexes: PropTypes.number.isRequired,
  newVertexesRecordsPerPage: PropTypes.number.isRequired,
  newVertexesPageNumber: PropTypes.number.isRequired,
  updateNewVertexesPageNumber: PropTypes.func.isRequired,
  updateNewVertexesRecordsPerPage: PropTypes.func.isRequired,
  initializeNewVertexesDataDetailsFromUrlParams: PropTypes.func.isRequired,
  setDisplayNewVertexes: PropTypes.func.isRequired,
  suggestedExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  labelsIds: PropTypes.arrayOf(PropTypes.object),
  addSuggestedExcludeLabel: PropTypes.func.isRequired,
  clearSuggestedExcludeLabels: PropTypes.func.isRequired,
  allSuggestedExcludeLabels: PropTypes.func.isRequired,
  getUsersWithAccessToDataPlatform: PropTypes.func.isRequired,
  resetManagePermissionsPage: PropTypes.func.isRequired,
  setDisplayNewUsers: PropTypes.func.isRequired,
  setDisplayAdminUsers: PropTypes.func.isRequired,
  newUsers: PropTypes.object,
  newUserPageNumber: PropTypes.number.isRequired,
  newUserRecordsPerPage: PropTypes.number.isRequired,
  adminUsers: PropTypes.object,
  adminUserPageNumber: PropTypes.number.isRequired,
  adminUserRecordsPerPage: PropTypes.number.isRequired,
  adminUserFilterTerm: PropTypes.string,
  filterByCallingUser: PropTypes.bool.isRequired,
};

AddPipeline.defaultProps = {
  newVertexes: [],
  newVertexRecords: {},
  newPipelineComponents: [],
  newVertexRecordsOrder: [],
  suggestedVertexRecords: {},
  suggestedVertexRecordOrder: [],
  newVertexRecordCellStyle: {},
  suggestedVertexRecordCellStyles: {},
  totalSuggestions: 0,
  pageNumber: 0,
  suggestionPageNumber: 0,
  numNewVertexes: 0,
  newVertexesPageNumber: 0,
  newVertexesRecordsPerPage: 3,
  suggestedExcludeLabels: [],
  labels: [],
  labelsIds: ['Select Label First'],
  newUsers: {},
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
  adminUsers: {},
  adminUserPageNumber: 0,
  adminUserRecordsPerPage: 3,
  adminUserFilterTerm: '',
  filterByCallingUser: false,
};

export default compose(
  withReactRouter,
  connect(mapStateToProps, {
    fetchAdditionalComponentCandidate,
    removeAdditionalComponentCandidate,
    clearAdditionalComponentCandidates,
    createPipeline,
    clearSuggestedPipelineVertexes,
    updateSuggestionRecordsPerPage,
    updateSuggestionPageNumber,
    initializeSuggestionDataDetailsFromUrlParams,
    getSuggestedPipelineVertexes,
    updateNewVertexesPageNumber,
    updateNewVertexesRecordsPerPage,
    initializeNewVertexesDataDetailsFromUrlParams,
    setDisplayNewVertexes,
    addSuggestedExcludeLabel,
    clearSuggestedExcludeLabels,
    allSuggestedExcludeLabels,
    getUsersWithAccessToDataPlatform,
    resetManagePermissionsPage,
    setDisplayNewUsers,
    setDisplayAdminUsers,
    getAllIds,
  })
)(AddPipeline);
