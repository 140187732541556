import {
  SET_PAGE,
  SET_ITEMS_PER_PAGE,
  SETUP_PAGINATION,
} from 'client/modules/common/redux/actions/pagination-actions';

function setupPagination(page, itemsPerPage) {
  const currentPageItems = page * itemsPerPage;

  return {
    page,
    itemsPerPage,
    startIndex: currentPageItems - itemsPerPage,
    startItem: currentPageItems + 1 - itemsPerPage,
  };
}

export default function (state = setupPagination(1, 50), action) {
  switch (action.type) {
    case SET_PAGE:
      return {
        
        ...state,
        ...setupPagination(action.page, state.itemsPerPage, state.totalItems)
      };
    case SET_ITEMS_PER_PAGE:
      return {
        
        ...state,
        ...setupPagination(state.page, action.itemsPerPage, state.totalItems)
      };
    case SETUP_PAGINATION:
      return {
        
        ...state,
        ...setupPagination(action.page, action.itemsPerPage, action.totalItems)
      };
    default:
      return state;
  }
}
