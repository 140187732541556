import { Email, TokenInfo } from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const createPasswordResetToken = (
  req: Partial<Email>
): Promise<TokenInfo> =>
  request({
    url: '/service/userv2service/CreatePasswordResetToken',
    body: req,
  }).then((r) => r.body);
