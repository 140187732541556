/**
 * @deprecated
 * It returns a promise that resolves to an array of the image's width and height
 * @param src - The URL of the image to load.
 * @returns A promise that resolves to an array of two numbers.
 */
export default function getImageDimensionsFromSrc(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve([image.width, image.height]);
    image.onerror = reject;
    image.src = src;
  });
}
