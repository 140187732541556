import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import { updateRelatedMarkets } from 'client/modules/markets/redux/actions/data';
import { REJECTED } from 'client/modules/markets/redux/actions/record-status-filters';

class RejectIconCell extends Component {
  handleReject = (id) => () => {
    const [recordId] = id.split('-');
    this.props.updateRelatedMarkets(recordId, REJECTED);
  };

  render() {
    const { id, status, disabled } = this.props;
    return (
      <BaseIcon
        handleOnClick={this.handleReject(id)}
        disabled={status === REJECTED || disabled}
        dataTest={`${id}`}
        iconName="Thumb Down"
        iconClassName="rejectIcon"
        iconText="Reject"
      />
    );
  }
}

RejectIconCell.propTypes = {
  id: PropTypes.string,
  updateRelatedMarkets: PropTypes.func.isRequired,
  status: PropTypes.string,
  disabled: PropTypes.bool,
};

RejectIconCell.defaultProps = {
  id: '',
  status: '',
  disabled: false,
};

export default connect(null, { updateRelatedMarkets })(RejectIconCell);
