import { map } from 'lodash';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import * as tabName from 'client/modules/packages/utils/tab-names';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import {
  setSuccessNotification,
  setFailureNotification,
} from 'client/modules/common/redux/actions/status-notification';
import { getCurrentTab } from '../utils/records-helper';
import {
  createNewPackageRequest,
  getFeatureInfoForPackageRequest,
  getPackageInfoForFeatureRequest,
  updatePackageRequest,
} from '../utils/api-request-helper';
import { getAllPackages } from './all-packages';

export const DIFF_RECORDS =
  'client/modules/packages/actions/tab-data/DIFF_RECORDS';
export const FILTERING_RECORDS =
  'client/modules/packages/actions/tab-data/FILTERING_RECORDS';
export const SET_ALL_PACKAGES_WITH_THE_FEATURE =
  'client/modules/packages/actions/tab-data/SET_ALL_PACKAGES_WITH_THE_FEATURE';
export const SET_PACKAGE_INFO_TO_CREATE_PACKAGE =
  'client/modules/packages/actions/tab-data/SET_PACKAGE_INFO_TO_CREATE_PACKAGE';
export const SET_PACKAGE_INFO_TO_COMPARE_PACKAGE =
  'client/modules/packages/actions/tab-data/SET_PACKAGE_INFO_TO_COMPARE_PACKAGE';
export const SET_PACKAGE_INFO_TO_UPDATE_PACKAGE =
  'client/modules/packages/actions/tab-data/SET_PACKAGE_INFO_TO_UPDATE_PACKAGE';
export const UPDATE_PACKAGE_COMMON_INFO =
  'client/modules/packages/actions/tab-data/UPDATE_PACKAGE_COMMON_INFO';
export const UPDATE_RECORD =
  'client/modules/packages/actions/tab-data/UPDATE_RECORD';
export const UPDATE_SEARCH_INFO =
  'client/modules/packages/actions/tab-data/UPDATE_SEARCH_INFO';
export const UPDATE_SECOND_SEARCH_INFO =
  'client/modules/packages/actions/tab-data/UPDATE_SECOND_SEARCH_INFO';
export const UPDATE_SUGGESTION =
  'client/modules/packages/actions/tab-data/UPDATE_SUGGESTION';
export const UPDATE_SECOND_SUGGESTION =
  'client/modules/packages/actions/tab-data/UPDATE_SECOND_SUGGESTION';
export const UPDATE_SELECTED_SEARCH_TERM =
  'client/modules/packages/actions/tab-data/UPDATE_SELECTED_SEARCH_TERM';

export const updateRecord = (recordId, fieldName, newValue) => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_RECORD,
      recordId,
      fieldName,
      newValue,
      currentTab,
    };
  });
};

export const mapFeaturesInfo = (features) => {
  return map(features, (feature) => {
    return {
      id: feature.id,
      name: feature.name ? feature.name.trim() : '',
      access: feature.access === 'yes',
      totalResults: parseInt(feature.totalResults, 10) || 0,
      quota: parseInt(feature.quota, 10) || 0,
      resultsPerPage: parseInt(feature.resultsPerPage, 10) || 0,
    };
  });
};

export const mapPackageCommonInfo = (commonInfo) => {
  return {
    id: commonInfo.id,
    name: commonInfo.name ? commonInfo.name.trim() : '',
    packageType: commonInfo.packageType,
    annual: commonInfo.annual === 'yes',
    duration: commonInfo.duration,
  };
};

export const updatePackageRecords = () => {
  return (dispatch, getState) => {
    const {packages} = getState();
    const {currentTab} = packages;
    const currentTabData = packages.tabData[currentTab];

    switch (currentTab) {
      case tabName.CREATE_PACKAGE: {
        if (
          getKeyValueFromListViaOtherKey(
            packages.packagesInfo,
            'name',
            currentTabData.commonInfo.name,
            'id'
          )
        ) {
          return dispatch(
            setFailureNotification('Package name should be unique!')
          );
        }
        return dispatch(
          createNewPackageRequest({
            ...mapPackageCommonInfo(currentTabData.commonInfo),
            featureInfo: mapFeaturesInfo(currentTabData.records),
          })
        ).then(
          () => {
            dispatch(getAllPackages());
            dispatch(setSuccessNotification('Package created successfully!'));
          },
          () => {
            dispatch(setFailureNotification('Failed to update package!'));
          }
        );
      }
      case tabName.UPDATE_PACKAGE: {
        if (
          currentTabData.commonInfo.name !==
            currentTabData.originalCommonInfo.name &&
          getKeyValueFromListViaOtherKey(
            packages.packagesInfo,
            'name',
            currentTabData.commonInfo.name,
            'id'
          )
        ) {
          return dispatch(
            setFailureNotification('Package name should be unique!')
          );
        }

        return dispatch(
          updatePackageRequest({
            ...mapPackageCommonInfo(currentTabData.commonInfo),
            featureInfo: mapFeaturesInfo(currentTabData.records),
          })
        ).then(
          () => {
            dispatch(getAllPackages());
            dispatch(setSuccessNotification('Package updated successfully!'));
          },
          () => {
            dispatch(setFailureNotification('Failed to update package!'));
          }
        );
      }
      case tabName.PACKAGE_FEATURES:
      case tabName.COMPARE_PACKAGE:
      default:
        return null;
    }
  };
};

export const updatePackageCommonInfo = (key, value) => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_PACKAGE_COMMON_INFO,
      key,
      value,
      currentTab,
    };
  });
};

/**
 * Filter actions
 */
export const filteringRecords = (filter) => {
  return getCurrentTab((currentTab) => {
    return {
      type: FILTERING_RECORDS,
      filter,
      currentTab,
    };
  });
};

/**
 * Searching actions
 */

export const updateSearchInfo = (searchId, searchTerm) => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SEARCH_INFO,
      currentTab,
      searchId,
      searchTerm,
    };
  });
};

export const updateSecondSearchInfo = (searchId, searchTerm) => {
  return getCurrentTab((currentTab) => {
    return {
      type: UPDATE_SECOND_SEARCH_INFO,
      currentTab,
      searchId,
      searchTerm,
    };
  });
};

export const updateSelectedSearchTerm = (currentTab, secondSearchBox) => {
  return {
    type: UPDATE_SELECTED_SEARCH_TERM,
    currentTab,
    secondSearchBox,
  };
};

export const setPackageInfoToCreatePackage = (response, featuresInfo) => {
  return {
    type: SET_PACKAGE_INFO_TO_CREATE_PACKAGE,
    response,
    featuresInfo,
  };
};

export const setPackageInfoToUpdatePackage = (response, featuresInfo) => {
  return {
    type: SET_PACKAGE_INFO_TO_UPDATE_PACKAGE,
    response,
    featuresInfo,
  };
};

export const setAllPackagesWithTheFeature = (response) => {
  return {
    type: SET_ALL_PACKAGES_WITH_THE_FEATURE,
    response,
  };
};

export const setPackageInfoToComparePackage = (
  response,
  featuresInfo,
  secondSearchBox
) => {
  return {
    type: SET_PACKAGE_INFO_TO_COMPARE_PACKAGE,
    response,
    featuresInfo,
    secondSearchBox,
  };
};

export const diffRecords = (checked) => {
  return {
    type: DIFF_RECORDS,
    checked,
  };
};

export const onSelectOrEnterSearch = (
  searchId,
  searchTerm,
  secondSearchBox
) => {
  return (dispatch, getState) => {
    const {packages} = getState();
    const {currentTab} = packages;
    const {featuresInfo} = packages;

    if (secondSearchBox) {
      updateUrlParams('secondSearchId', searchId);
      updateUrlParams('secondSearchTerm', searchTerm);
    } else if (currentTab !== tabName.CREATE_PACKAGE) {
      updateUrlParams('searchId', searchId);
      updateUrlParams('searchTerm', searchTerm);
    }

    dispatch(updateSelectedSearchTerm(currentTab, secondSearchBox));
    switch (currentTab) {
      case tabName.CREATE_PACKAGE: {
        return dispatch(getFeatureInfoForPackageRequest(searchId)).then(
          (response) => {
            return dispatch(
              setPackageInfoToCreatePackage(response, featuresInfo)
            );
          }
        );
      }
      case tabName.UPDATE_PACKAGE: {
        return dispatch(getFeatureInfoForPackageRequest(searchId)).then(
          (response) => {
            return dispatch(
              setPackageInfoToUpdatePackage(response, featuresInfo)
            );
          }
        );
      }
      case tabName.PACKAGE_FEATURES: {
        return dispatch(getPackageInfoForFeatureRequest(searchId)).then(
          (response) => {
            dispatch(
              setAllPackagesWithTheFeature(response.featureAndPackageInfo)
            );
            const filterValue = packages.tabDetails[currentTab].filterInfo;
            return dispatch(filteringRecords(filterValue));
          }
        );
      }
      case tabName.COMPARE_PACKAGE: {
        const {showDiff} = packages.tabDetails[tabName.COMPARE_PACKAGE];
        return dispatch(getFeatureInfoForPackageRequest(searchId))
          .then((response) => {
            dispatch(
              setPackageInfoToComparePackage(
                response,
                featuresInfo,
                secondSearchBox
              )
            );
          })
          .then(() => {
            if (showDiff) {
              dispatch(diffRecords(showDiff));
            }
          });
      }
      default:
        return null;
    }
  };
};

/**
 * Update autocomplete suggestions
 */

export const updateSuggestionsHelper = (
  currentTab,
  suggestions,
  searchTerm
) => {
  return {
    type: UPDATE_SUGGESTION,
    currentTab,
    suggestions,
    searchTerm,
  };
};

export const updateSecondSuggestionsHelper = (suggestions, searchTerm) => {
  return {
    type: UPDATE_SECOND_SUGGESTION,
    suggestions,
    searchTerm,
  };
};

export const updateSuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    if (!searchTerm) {
      return null;
    }

    const {packages} = getState();
    const {currentTab} = packages;
    const allFeatures = packages.featuresInfo;
    const allPackages = packages.packagesInfo;

    switch (currentTab) {
      case tabName.CREATE_PACKAGE: {
        const filteredPackages = filterListOfObjects({
          value: searchTerm,
          listOfItems: allPackages,
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredPackages, searchTerm)
        );
      }
      case tabName.UPDATE_PACKAGE: {
        const filteredPackages = filterListOfObjects({
          value: searchTerm,
          listOfItems: allPackages,
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredPackages, searchTerm)
        );
      }
      case tabName.PACKAGE_FEATURES: {
        const filteredFeatures = filterListOfObjects({
          value: searchTerm,
          listOfItems: allFeatures,
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredFeatures, searchTerm)
        );
      }
      case tabName.COMPARE_PACKAGE: {
        const filteredPackages = filterListOfObjects({
          value: searchTerm,
          listOfItems: allPackages,
        });
        return dispatch(
          updateSuggestionsHelper(currentTab, filteredPackages, searchTerm)
        );
      }
      default:
        return null;
    }
  };
};

export const updateSecondSuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    if (!searchTerm) {
      return;
    }
    const {packages} = getState();
    const allPackages = packages.packagesInfo;

    const filteredPackages = filterListOfObjects({
      value: searchTerm,
      listOfItems: allPackages,
    });
    dispatch(updateSecondSuggestionsHelper(filteredPackages, searchTerm));
  };
};
