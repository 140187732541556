export const UPDATE_TAB_NAME =
  'client/modules/admin-user-admin/actions/UPDATE_TAB_NAME';

export function updateTabName(tabName) {
  return {
    type: UPDATE_TAB_NAME,
    tabName,
  };
}

export default updateTabName;
