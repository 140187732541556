/* eslint-disable max-len */
import { setCustomNotification } from 'client/modules/common/redux/actions/status-notification';
import * as tabName from 'client/modules/cbi-entity/utils/tab-names';
import { notificationTypes } from 'client/modules/common/components/Messages/utils/constants';
import {
  getEntityFundingsRequest,
  getCbiEntityVcFundsRequest,
  fetchCbiEntityEmployees,
  fetchCbiEntitySalesMetrics,
} from '../utils/api-request-helper';
/* eslint-enable max-len */

export const GET_ENTITY_FUNDINGS =
  'client/modules/cbi-entity/actions/GET_ENTITY_FUNDINGS';
export const SET_SALES_METRICS =
  'client/modules/cbi-entity/actions/SET_SALES_METRICS';
export const DELETE_FUNDING =
  'client/modules/cbi-entity/actions/DELETE_FUNDING';
export const DELETE_ENTITY_MENTIONS =
  'client/modules/cbi-entity/actions/DELETE_ENTITY_MENTIONS';
export const DELETE_VC_SOURCE =
  'client/modules/cbi-entity/actions/DELETE_VC_SOURCE';
export const DELETE_ENTITY_INVESTOR =
  'client/modules/cbi-entity/actions/DELETE_ENTITY_INVESTOR';
export const DELETE_BOARD_OF_DIRECTORS_PERSON =
  'client/modules/cbi-entity/actions/DELETE_BOARD_OF_DIRECTORS_PERSON';
export const DELETE_SALES_METRIC =
  'client/modules/cbi-entity/actions/DELETE_SALES_METRIC';

export const INITIALIZE_HQ_ADDRESS =
  'client/modules/cbi-entity/actions/INITIALIZE_HQ_ADDRESS';
export const ADD_MENTION = 'client/modules/cbi-entity/actions/ADD_MENTION';
export const ADD_VC_SOURCE = 'client/modules/cbi-entity/actions/ADD_VC_SOURCE';
export const ADD_NEW_INVESTOR =
  'client/modules/cbi-entity/actions/ADD_NEW_INVESTOR';
export const ADD_NEW_BOARD_OF_DIRECTORS_PERSON =
  'client/modules/cbi-entity/actions/ADD_NEW_BOARD_OF_DIRECTORS_PERSON';
export const ADD_SALES_METRIC_SOURCE =
  'client/modules/cbi-entity/actions/ADD_SALES_METRIC_SOURCE';
export const ADD_EMPTY_RECORD =
  'client/modules/cbi-entity/actions/ADD_EMPTY_RECORD';
export const UPDATE_RECORD = 'client/modules/cbi-entity/actions/UPDATE_RECORD';
export const GET_ENTITY_VC_FUNDINGS =
  'client/modules/cbi-entity/actions/GET_ENTITY_VC_FUNDINGS';
export const SET_COMPETITORS =
  'client/modules/cbi-entity/actions/SET_COMPETITORS';
export const SET_SUGGESTED_COMPETITORS =
  'client/modules/cbi-entity/actions/SET_SUGGESTED_COMPETITORS';
export const SET_EMPLOYEES = 'client/modules/cbi-entity/actions/SET_EMPLOYEES';
export const SET_PRIMARY_INVESTOR =
  'client/modules/cbi-entity/actions/SET_PRIMARY_INVESTOR';
export const UPDATE_COMPANY_STATUS_FROM_FUNDING =
  'client/modules/cbi-entity/actions/UPDATE_COMPANY_STATUS_FROM_FUNDING';
export const RESET_TABLES_EDIT_STATUS =
  'client/modules/cbi-entity/actions/RESET_TABLES_EDIT_STATUS';
export const DELETE_VC_FUND =
  'client/modules/cbi-entity/actions/DELETE_VC_FUND';
export const DELETE_VC_CIK = 'client/modules/cbi-entity/actions/DELETE_VC_CIK';
export const ADD_VC_CIK = 'client/modules/cbi-entity/actions/ADD_VC_CIK';
export const SET_VC_FUNDS_TABLE_ERRORS =
  'client/modules/cbi-entity/actions/SET_VC_FUNDS_TABLE_ERRORS';
export const APPEND_COMPETITORS =
  'client/modules/cbi-entity/actions/APPEND_COMPETITORS';
export const REMOVE_COMPETITORS =
  'client/modules/cbi-entity/actions/REMOVE_COMPETITORS';
export const EMPLOYEES_TAB_DATA_MUTATION =
  'client/modules/cbi-entity/actions/EMPLOYEES_TAB_DATA_MUTATION';
export const ADDRESS_TAB_DATA_MUTATION =
  'client/modules/cbi-entity/actions/ADDRESS_TAB_DATA_MUTATION';
export const UDPATE_FUNDING_LOCAL =
  'client/modules/cbi-entity/actions/UDPATE_FUNDING_LOCAL';
export const RESET_SUGGESTED_COMPETITORS =
  'client/modules/cbi-entity/actions/RESET_SUGGESTED_COMPETITORS';

export const updateFundingAct = (deletedIds, newIdFundMap) => {
  return {
    type: UDPATE_FUNDING_LOCAL,
    deletedIds,
    newIdFundMap,
  };
};

export const mutateEmployeeTabData = (mutationFunction) => {
  return {
    type: EMPLOYEES_TAB_DATA_MUTATION,
    mutation: mutationFunction,
  };
};

export const mutateAddressTabData = (mutationFunction) => {
  return {
    type: ADDRESS_TAB_DATA_MUTATION,
    mutation: mutationFunction,
  };
};

export const setVcFundsTableErrors = (errors) => {
  return {
    type: SET_VC_FUNDS_TABLE_ERRORS,
    errors,
  };
};

export const addRecord = (tab) => {
  return {
    type: ADD_EMPTY_RECORD,
    tab,
  };
};

export const updateRecord = (
  recordId,
  fieldName,
  newValue,
  tableOverRide = null
) => {
  return {
    type: UPDATE_RECORD,
    recordId,
    fieldName,
    newValue,
    tableOverRide,
  };
};

export const addNewInvestor = (recordId, idInvestor, name, idCbiEntity) => {
  return {
    type: ADD_NEW_INVESTOR,
    idInvestor,
    name,
    recordId,
    idCbiEntity,
  };
};

export const setPrimaryInvestor = (idFunding, idInvestor) => {
  return {
    type: SET_PRIMARY_INVESTOR,
    idFunding,
    idInvestor,
  };
};

export const addNewBoardOfDirectorsPerson = (recordId, id, name) => {
  return {
    type: ADD_NEW_BOARD_OF_DIRECTORS_PERSON,
    id, // idInvestorPerson
    name, // fname + lname
    recordId, // rowId
  };
};

export const deleteFunding = (recordId) => {
  return {
    type: DELETE_FUNDING,
    recordId,
  };
};

export const deleteSalesMetric = (recordId) => {
  return {
    type: DELETE_SALES_METRIC,
    recordId,
  };
};

export const deleteEntityInvestor = (recordId, investorId) => {
  return {
    type: DELETE_ENTITY_INVESTOR,
    recordId,
    investorId,
  };
};

export const deleteEntityMentions = (recordId, mentionId) => {
  return {
    type: DELETE_ENTITY_MENTIONS,
    recordId,
    mentionId,
  };
};

export const deleteVcSource = (recordId, sourceId) => {
  return {
    type: DELETE_VC_SOURCE,
    recordId,
    sourceId,
  };
};

export const deleteVcCik = (recordId, cikId) => {
  return {
    type: DELETE_VC_CIK,
    recordId,
    cikId,
  };
};

export const deleteEntityBoardOfDirectors = (
  recordId,
  boardOfDirectorPersonId
) => {
  return {
    type: DELETE_BOARD_OF_DIRECTORS_PERSON,
    recordId,
    boardOfDirectorPersonId,
  };
};

export const addMention = (recordId, value) => {
  return {
    type: ADD_MENTION,
    recordId,
    value,
  };
};

export const addVcSource = (recordId, value) => {
  return {
    type: ADD_VC_SOURCE,
    recordId,
    value,
  };
};

export const addVcCik = (recordId, value) => {
  return {
    type: ADD_VC_CIK,
    recordId,
    value,
  };
};

export const addSalesMetricSource = (recordId, value) => {
  return {
    type: ADD_SALES_METRIC_SOURCE,
    recordId,
    value,
  };
};

export const getEntityFundingsHelper = (fundingInfo, currentTab) => {
  return {
    type: GET_ENTITY_FUNDINGS,
    fundingInfo,
    currentTab,
  };
};

export const initializeHqAddress = () => {
  return {
    type: INITIALIZE_HQ_ADDRESS,
  };
};

export const updateCompanyStatusFromFunding = (value = true) => {
  return {
    type: UPDATE_COMPANY_STATUS_FROM_FUNDING,
    value,
  };
};

export const resetTablesEditStatus = () => {
  return { type: RESET_TABLES_EDIT_STATUS };
};

export const getEntityFundings = (id) => {
  return (dispatch, getState) => {
    const idCbiEntity = parseInt(
      id || getState().cbiEntity.generalInfo.entityInfo.idCbiEntity,
      10
    );
    const currentTab = tabName.FUNDINGS;
    return dispatch(getEntityFundingsRequest(idCbiEntity)).then(
      (response) => {
        return dispatch(
          getEntityFundingsHelper(response.fundingInfo, currentTab)
        );
      },
      () => {
        return dispatch(
          setCustomNotification(
            'Failed to fetch funding records!',
            notificationTypes.FAILURE
          )
        );
      }
    );
  };
};

export const getCbiEntityVcFundsHelper = (vcFundInfo, currentTab) => {
  return {
    type: GET_ENTITY_VC_FUNDINGS,
    vcFundInfo,
    currentTab,
  };
};

export const getCbiEntityVcFunds = (id) => {
  return (dispatch) => {
    const cbiEntityId = parseInt(id, 10) || 2;
    const currentTab = tabName.VC_FUNDS;
    return dispatch(getCbiEntityVcFundsRequest(cbiEntityId)).then(
      (response) => {
        return dispatch(
          getCbiEntityVcFundsHelper(response.vcFundInfo, currentTab)
        );
      },
      () => {
        return dispatch(
          setCustomNotification(
            'Failed to fetch vc funding records!',
            notificationTypes.FAILURE
          )
        );
      }
    );
  };
};

export const deleteCbiEntityVcFund = (recordId) => {
  return {
    type: DELETE_VC_FUND,
    recordId,
  };
};

export const setCbiEntityEmployees = (employees, currentTab) => {
  return {
    type: SET_EMPLOYEES,
    employees,
    currentTab,
  };
};

export const getCbiEntityEmployees = (id) => {
  return (dispatch) => {
    const cbiEntityId = parseInt(id, 10) || 2;
    const currentTab = tabName.EMPLOYEES;
    return dispatch(fetchCbiEntityEmployees(cbiEntityId)).then(
      (response) => {
        return dispatch(
          setCbiEntityEmployees(response.cbiEntityEmployeeInfo, currentTab)
        );
      },
      () => {
        return dispatch(
          setCustomNotification(
            'Failed to fetch employees!',
            notificationTypes.FAILURE
          )
        );
      }
    );
  };
};

export const setEntitySalesMetrics = (salesMetricInfo, currentTab) => {
  return {
    type: SET_SALES_METRICS,
    salesMetricInfo,
    currentTab,
  };
};

export const getEntitySalesMetric = (id) => {
  return (dispatch, getState) => {
    const idCbiEntity = parseInt(
      id || getState().cbiEntity.generalInfo.entityInfo.idCbiEntity,
      10
    );
    const currentTab = tabName.SALES_METRICS;
    return dispatch(fetchCbiEntitySalesMetrics(idCbiEntity)).then(
      (response) => {
        return dispatch(
          setEntitySalesMetrics(response.salesMetricInfo, currentTab)
        );
      },
      () => {
        return dispatch(
          setCustomNotification(
            'Failed to fetch sales metrics!',
            notificationTypes.FAILURE
          )
        );
      }
    );
  };
};

export const updateCompetitorRecord = (records, idCbiEntity, flags) => {
  // Find if records is in list
  const record = records.find((c) => c.idCbiEntityCompetitor === idCbiEntity);

  if (!record) return null;

  // remove record
  const newRecords = records.filter(
    (c) => c.idCbiEntityCompetitor !== record.idCbiEntityCompetitor
  );

  // return records and add removed record back with updated flags
  return [...newRecords, { ...record, ...flags }];
};
