export const relatedMarketsTableColumnNames = [
  {
    name: '',
    width: '180px',
    key: 'relatedMarkets',
    includes: [{ type: 'text', key: 'childMarket' }],
  },
  {
    name: '',
    width: '58px',
    key: 'approveIcon',
    includes: [{ type: 'markets-approveIcon', key: 'approveIcon' }],
  },
  {
    name: '',
    width: '58px',
    key: 'rejectIcon',
    includes: [{ type: 'markets-rejectIcon', key: 'rejectIcon' }],
  },
  {
    name: '',
    width: '58px',
    key: 'deletePendingRelatedMarketIcon',
    includes: [
      {
        type: 'markets-deletePendingRelatedMarketIcon',
        key: 'deletePendingRelatedMarketIcon',
      },
    ],
  },
  {
    name: '',
    width: '600px',
    key: 'empty',
    includes: [{ type: 'text', key: 'empty' }],
  },
];

export default relatedMarketsTableColumnNames;
