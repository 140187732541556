import { RemoveUsersFromTeamRequest } from '@cbinsights/adminservice/adminservice';
import { useMutation } from 'react-query';
import { removeUsersFromTeam } from '../services/api/RemoveUsersFromTeam';

export const useRemoveUsersFromTeam = () => {
  const removeUsersFromTeamMutation = useMutation(removeUsersFromTeam);

  const onSubmit = (data: Partial<RemoveUsersFromTeamRequest>) => {
    return removeUsersFromTeamMutation.mutateAsync(data);
  };

  return {
    onSubmit,
  };
};
