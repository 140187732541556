export const SET_MODIFIED_RECORDS_DIFF =
  'client/modules/common/modified-records/SET_MODIFIED_RECORDS_DIFF';
export const CLEAR_MODIFIED_RECORDS_DIFF =
  'client/modules/common/modified-records/CLEAR_MODIFIED_RECORDS_DIFF';

export const setModifiedRecordsDiff = (modifiedRecords) => {
  return {
    type: SET_MODIFIED_RECORDS_DIFF,
    modifiedRecords,
  };
};

export const clearModifiedRecordsDiff = () => {
  return {
    type: CLEAR_MODIFIED_RECORDS_DIFF,
  };
};
