import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { TableCell } from 'client/component-library/Table';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import Popover from 'client/component-library/Popover/Popover';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
} from 'client/component-library/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from 'client/component-library/Divider';
import Button from 'client/component-library/buttons/Button';
import TextField from 'client/component-library/TextField';
import { InputAdornment } from 'client/component-library/Input';
import Icon from 'client/component-library/icons/Icon';
import {
  setSortInfo,
  setFilterInfo,
  clearFilterInfo,
} from 'client/modules/stories/redux/actions/tab-data';
import { updatePageNumber } from 'client/modules/stories/redux/actions/tab-details';
import styles from '../../styles/material-ui/tableHeaderStyles';

const collapseProps = {
  style: {
    zIndex: 10,
    marginTop: '4px',
    width: '250px',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2),' +
      '0px 1px 1px 0px rgba(0, 0, 0, 0.14),' +
      '0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
};
const panelSummaryStyle = { minHeight: '48px', padding: '0' };
const iconStyle = { fontSize: '16px', color: 'gray' };
const dividerStyle = { margin: '10px 0', width: '200px' };

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

class HeaderCell extends Component {
  constructor(props) {
    super(props);
    this.toggleSort = this.toggleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleClearFilterInfo = this.handleClearFilterInfo.bind(this);
    this.handleFilterAction = this.handleFilterAction.bind(this);
    this.toggleExpandedState = this.toggleExpandedState.bind(this);

    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleChange(event) {
    const { name, dispatch } = this.props;
    dispatch(setFilterInfo(name, event.target.value));
  }

  handleOnEnter(event) {
    const { dispatch, filterAction } = this.props;

    if (event.key === 'Enter') {
      dispatch(updatePageNumber(0));
      filterAction();
      this.setState({ expanded: false });
    }
  }

  handleClearFilterInfo() {
    const { name, dispatch, filterAction } = this.props;
    dispatch(clearFilterInfo(name));
    dispatch(updatePageNumber(0));
    filterAction();
    this.setState({ expanded: false });
  }

  handleFilterAction() {
    const { dispatch, filterAction } = this.props;
    dispatch(updatePageNumber(0));
    filterAction();
    this.setState({ expanded: false });
  }

  handleOnBlur() {
    this.setState({ expanded: false });
  }

  toggleExpandedState(event, expanded) {
    this.setState({ expanded });
  }

  toggleSort(direction) {
    const { name, dispatch, filterAction } = this.props;
    dispatch(setSortInfo(name, direction));
    filterAction();
  }

  render() {
    const { classes, name, filterValue, sortInfo, width } = this.props;
    const labelClass = { label: classes.label };
    const panelSummaryClasses = {
      root: classes.expansionPanelSummaryRoot,
      content: classes.expansionPanelSummaryContent,
      expandIcon: classes.expansionPanelSummaryExpandIcon,
    };
    const panelActionClasses = { root: classes.expensionPanelActionRoot };
    const sortByTeamName = sortInfo.sortField === name;
    const withValue = filterValue || sortInfo.sortField === name;

    return (
      <TableCell width={width} classes={{ tableCell: classes.tableCell }}>
        <Popover onBlur={this.handleOnBlur}>
          <ExpansionPanel
            onChange={(event, value) => this.toggleExpandedState(event, value)}
            expanded={this.state.expanded}
            classes={{
              root: withValue
                ? classes.expensionPanelWithValue
                : classes.expensionPanel,
            }}
            data-test={`expand-panel-${name}`}
            CollapseProps={collapseProps}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              style={panelSummaryStyle}
              classes={panelSummaryClasses}
            >
              {name}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div
                style={{
                  fontSize: '10px',
                  color: 'gray',
                  fontWeight: 'lighter',
                }}
              >
                <div>Sort by</div>
                <BaseIcon
                  customIconClass={classes.icon}
                  customIconButtonClass={classes.iconButton}
                  customSpanClass={
                    sortByTeamName && sortInfo.sortDirection === 'asc'
                      ? classes.textSelected
                      : classes.text
                  }
                  customWrapperClass={
                    sortByTeamName && sortInfo.sortDirection === 'asc'
                      ? classes.addIconWrapperSelected
                      : classes.addIconWrapper
                  }
                  dataTest={`sort-by-${name}-asc`}
                  handleOnClick={() => this.toggleSort('asc')}
                  iconName="sort_by_alpha"
                  iconText="Alphabetical"
                  isIconBlock={false}
                  classes={labelClass}
                />
                <BaseIcon
                  customIconClass={classes.icon}
                  customIconButtonClass={classes.iconButton}
                  customSpanClass={
                    sortByTeamName && sortInfo.sortDirection === 'desc'
                      ? classes.textSelected
                      : classes.text
                  }
                  customWrapperClass={
                    sortByTeamName && sortInfo.sortDirection === 'desc'
                      ? classes.addIconWrapperSelected
                      : classes.addIconWrapper
                  }
                  dataTest={`sort-by-${name}-desc`}
                  handleOnClick={() => this.toggleSort('desc')}
                  iconName="sort_by_alpha"
                  iconText="Reverse Alphabetical"
                  isIconBlock={false}
                  classes={labelClass}
                />
                <Divider style={dividerStyle} />
                <TextField
                  id="input-with-icon-textfield"
                  label="Filter"
                  value={filterValue || ''}
                  placeholder={`Search ${name}`}
                  data-test={`filter-by-${name}`}
                  onChange={(event) => this.handleChange(event)}
                  onKeyPress={this.handleOnEnter}
                  InputProps={{
                    style: { fontSize: '1.3em', width: '200px' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          name="search"
                          family="material"
                          styles={iconStyle}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </ExpansionPanelDetails>
            <ExpansionPanelActions classes={panelActionClasses}>
              <MuiThemeProvider theme={theme}>
                <Button
                  size="small"
                  onClick={this.handleClearFilterInfo}
                  color="secondary"
                  dataTest="clear"
                >
                  Clear
                </Button>
              </MuiThemeProvider>
              <Button
                size="small"
                onClick={this.handleFilterAction}
                color="primary"
                dataTest="apply"
              >
                Apply
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </Popover>
      </TableCell>
    );
  }
}

function mapStateToProps({ stories }, ownProps) {
  const { currentTab } = stories;
  return {
    filterValue: stories.tabData[currentTab].filterInfo[ownProps.name],
    sortInfo: stories.tabData[currentTab].sortInfo,
  };
}

HeaderCell.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  sortInfo: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  filterAction: PropTypes.func,
};

HeaderCell.defaultProps = {
  filterValue: '',
  filterAction: () => {},
};

export default withStyles(styles)(connect(mapStateToProps)(HeaderCell));
