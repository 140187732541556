import React, { Component } from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import DisplayNewsContent from 'client/modules/common/components/DisplayNewsContent/DisplayNewsContent';
import { setSelectedArticleMarketRowId } from 'client/modules/markets/redux/actions/article-content';

class MarketsArticleContent extends Component {
  constructor(props) {
    super(props);

    this.hideContent = this.hideContent.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  hideContent(e) {
    e.preventDefault();
    this.props.dispatch(setSelectedArticleMarketRowId('0'));
  }

  render() {
    const {
      id,
      contentWidth,
      selectedRecordId,
      selectedSentence,
      content,
      url,
      title,
    } = this.props;
    const [idRecord] = id.split('-');
    if (idRecord !== selectedRecordId) {
      return <div />;
    }

    return (
      <div key={id} style={{ width: contentWidth }}>
        <DisplayNewsContent
          selectedSentences={[selectedSentence]}
          content={content}
          url={url}
          title={title}
          onHide={this.hideContent}
        />
      </div>
    );
  }
}

function mapStateToProps({ markets }) {
  return {
    selectedRecordId: markets.articleContent.selectedRecordId.split('-')[0],
    selectedSentence: markets.articleContent.selectedSentence,
    content: markets.articleContent.content,
    url: markets.articleContent.url,
    title: markets.articleContent.title,
  };
}

MarketsArticleContent.propTypes = {
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  contentWidth: PropTypes.string,
  selectedRecordId: PropTypes.string,
  selectedSentence: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
};

MarketsArticleContent.defaultProps = {
  contentWidth: '1200px',
  selectedRecordId: '0',
  selectedSentence: '',
  content: '',
  url: '',
  title: '',
};

export default connect(mapStateToProps)(MarketsArticleContent);
