import { LeftOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MarketReportsRoutes } from '../RouterHelper';

export const DiscardChangesButton = ({
  showAsIcon = false,
}: {
  showAsIcon?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { dirty, resetForm } = useFormikContext();
  const navigate = useNavigate();
  const handleClick = (e: unknown) => {
    (e as Event).preventDefault();
    if (dirty) {
      setOpen(!open);
    } else {
      navigate(`/${MarketReportsRoutes.MarketReports}`);
    }
  };
  const handleOk = () => {
    resetForm();
    setOpen(!open);
    navigate(`/${MarketReportsRoutes.MarketReports}`);
  };
  const handleCancel = () => {
    setOpen(!open);
  };
  return (
    <>
      {!showAsIcon && <Button onClick={handleClick}>Cancel</Button>}
      {showAsIcon && (
        <Link
          to={`/${MarketReportsRoutes.MarketReports}`}
          className="text-blue-600"
          onClick={handleClick}
        >
          <LeftOutlined />
        </Link>
      )}
      <Modal
        open={open}
        title="Discard Changes"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        This will discard all changes made, do you wish to continue?
      </Modal>
    </>
  );
};
