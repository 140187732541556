import { useListQuartrCompanies } from 'client/modules/cbi-entity/services/financials/hooks/usePublicFinancialService';

import { AntdSearchableField } from 'client/modules/common/antDesign/form/fields/AntdSearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import { ListQuartrCompaniesResponse } from '@cbinsights/publicfinancialservice/publicfinancialservice';
import { SearchOutlined } from '@ant-design/icons';
import { SectionFieldName } from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import { Spin } from 'antd';
import { useFormikContext } from 'formik';
import { IsTickerInUse } from './IsTickerInUse';
import {
  FinancialFormFields,
  FinancialFormValues,
} from './hooks/useFinancials';

import './SearchableTickerField.css';

export const mapCompaniesToOptions = (
  quartrCompanies: ListQuartrCompaniesResponse
) => {
  const companyOptions: (DefaultOptionType & { key: number })[] = [];
  const companyData: Record<
    string,
    {
      label: string;
      idQuartrCompany: number;
      idCbiEntity: number;
      companyName: string;
      companyUrl: string;
      description: string;
      eventCount: number;
    }
  > = {};

  quartrCompanies?.quartrCompanies?.forEach((el) => {
    const label = `${el.companyTicker} (${el.companyName}, ${el.companyUrl})`;
    const companyKey = el.idQuartrCompany;

    companyOptions.push({
      label,
      value: label,
      key: companyKey,
    });

    companyData[companyKey] = {
      label,
      idQuartrCompany: el?.idQuartrCompany,
      idCbiEntity: el?.idCbiEntity,
      companyName: el?.companyName,
      companyUrl: el?.companyUrl,
      description: el?.companyDescription.substring(0, 500),
      eventCount: el?.eventsCount,
    };
  });

  return {
    companyOptions,
    companyData,
  };
};

export const SearchableQuartrCompanyField = ({ index }: { index: number }) => {
  const { values, setFieldValue } = useFormikContext<FinancialFormValues>();

  const selectedQuartrCompany = String(values?.company[0]?.quartrCompany?.key);

  const { data: quartrCompanies, isFetched } = useListQuartrCompanies({
    enabled: true,
  });

  const { companyOptions, companyData } =
    mapCompaniesToOptions(quartrCompanies);

  if (!quartrCompanies) {
    return (
      <div className="flex justify-right">
        <Spin />
      </div>
    );
  }

  const selectedQuartrCompanyIdCbiEntity = selectedQuartrCompany
    ? companyData[selectedQuartrCompany]?.idCbiEntity
    : undefined;

  const quartrOptions = companyOptions?.map((el) => ({
    label: (
      <span
        className={`overflow-auto whitespace-normal break-words ${
          companyData[el.key].label
        }`}
        key={String(el.label)}
      >
        {el.label} events: {companyData[el.key].eventCount}
        <br />
        {companyData[el.key].description}
      </span>
    ),
    value: el.value,
    key: el.key,
    idQuartrCompany: companyData[el.key]?.idQuartrCompany,
  }));

  const filterTickers = (input, option) =>
    companyData[option.key].label.toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <AntdSearchableField
        debounceSearch
        allowClear
        onClear={() => {
          setFieldValue(
            `${FinancialFormFields.Company}.${index}.${FinancialFormFields.QuartrCompany}`,
            ''
          );
        }}
        suffixIcon={<SearchOutlined />}
        className={`min-w-[500px] max-w-[500px] searchable-quartr-field ${companyData[selectedQuartrCompany]?.idQuartrCompany}`}
        options={quartrOptions}
        filterOption={filterTickers}
        validateOnBlur
        name={`${FinancialFormFields.Company}.${index}.${FinancialFormFields.QuartrCompany}`}
      />

      {isFetched && (
        <IsTickerInUse
          idCbiEntity={values[SectionFieldName.IdCbiEntity]}
          selectedIdCBiEntity={selectedQuartrCompanyIdCbiEntity}
        />
      )}
    </>
  );
};
