import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { MaterialUILink } from 'client/component-library/Link';
import { withReactRouter } from 'client/routes/withReactRouter';
import { viewPipeline } from '../redux/actions/search';

class PipelineLink extends Component {
  onClickPipeline = () => {
    this.props.viewPipeline(this.props.pipelineName, this.props.navigation);
  };

  render() {
    return (
      <MaterialUILink onClick={this.onClickPipeline}>
        {this.props.pipelineName}
      </MaterialUILink>
    );
  }
}

PipelineLink.propTypes = {
  pipelineName: PropTypes.string,
  navigation: PropTypes.object.isRequired,
  viewPipeline: PropTypes.func.isRequired,
};

PipelineLink.defaultProps = {
  pipelineName: '',
};

export default compose(
  withReactRouter,
  connect(null, { viewPipeline })
)(PipelineLink);
