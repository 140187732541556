import PropTypes from 'prop-types';
import React from 'react';

const ManagementQueueTable = ({ action, children }) => (
  <table className="text-black">
    <thead
      data-test="tableHeader"
      className="border-b border-solid border-gray-500"
    >
      <tr>
        <th className="px-4 py-2 w-1/12"> </th>
        <th className="px-4 py-2 w-1/12">{action} Date (UTC)</th>
        <th className="px-4 py-2 w-1/12">Published Date</th>
        <th className="px-4 py-2 w-1/12">Type</th>
        <th className="px-4 py-2 w-1/12">Source</th>
        <th className="px-4 py-2 w-1/6">Title</th>
        <th className="px-4 py-2 w-5/12">Content</th>
      </tr>
    </thead>
    <tbody className="align-baseline">{children}</tbody>
  </table>
);

ManagementQueueTable.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.string.isRequired,
};

export default ManagementQueueTable;
