import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { PermissionsRoutes } from './RouterHelper';

const PermissionsPage = React.lazy(
  () => import('./containers/PermissionsPage')
);
const EditRolePage = React.lazy(() => import('./containers/EditRolePage'));
const EditPermissionUserPage = React.lazy(
  () => import('./containers/EditPermissionUserPage')
);
const CreateRolePage = React.lazy(() => import('./containers/CreateRolePage'));
const CreateTeamPage = React.lazy(() => import('./containers/CreateTeamPage'));
const EditTeamPage = React.lazy(() => import('./containers/EditTeamPage'));

export const adminPermissionsRoutes: RouteObject = {
  path: PermissionsRoutes.Permissions,
  element: (
    <PermissionsProtectedRoute
      pageId={66}
      currentPath={PermissionsRoutes.Permissions}
    />
  ),
  children: [
    {
      path: `/${PermissionsRoutes.Permissions}`,
      element: (
        <Navigate
          to={`/${PermissionsRoutes.Permissions}/${PermissionsRoutes.Roles}`}
        />
      ),
    },
    {
      path: `:${PermissionsRoutes.PermissionsKindId}`,
      element: (
        <React.Suspense fallback={undefined}>
          <PermissionsPage />
        </React.Suspense>
      ),
    },
    {
      path: `${PermissionsRoutes.User}/:${PermissionsRoutes.UserId}`,
      element: (
        <React.Suspense fallback={undefined}>
          <EditPermissionUserPage />
        </React.Suspense>
      ),
    },
    {
      path: PermissionsRoutes.Role,
      children: [
        {
          path: PermissionsRoutes.New,
          element: (
            <React.Suspense fallback={undefined}>
              <CreateRolePage />
            </React.Suspense>
          ),
        },
        {
          path: `:${PermissionsRoutes.RoleId}`,
          element: (
            <React.Suspense fallback={undefined}>
              <EditRolePage />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: PermissionsRoutes.Team,
      children: [
        {
          path: PermissionsRoutes.New,
          element: (
            <React.Suspense fallback={undefined}>
              <CreateTeamPage />
            </React.Suspense>
          ),
        },
        {
          path: `:${PermissionsRoutes.TeamId}`,
          element: (
            <React.Suspense fallback={undefined}>
              <EditTeamPage />
            </React.Suspense>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ],
};
