import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import { getEntityByName } from 'client/modules/common/apis/getEntityByName';
import { FIELD_SIZE_KEYS } from 'client/modules/common/form/fields/UtilFields';
import { ChipedValueField } from 'client/modules/common/form/fields/chipped-value-field';
import { InvestorChipField } from 'client/modules/common/form/fields/chipped-value-field/InvestorChipField';
import { shallowEqual, useSelector } from 'react-redux';
import InvestorOption from './InvestorOption';

const { FULL } = FIELD_SIZE_KEYS;

export const onCreateNewEntity = (entityName) => {
  const newWindow = window.open(
    `/cbi-entity/0/general-info?entityName=${entityName}`,
    '_blank'
  );
  newWindow.focus();
};

const InvestorSearchField = ({
  name = 'investors',
  disabled = false,
  required = false,
  size = FULL,
  label = '',
  placeholder = 'Investor',
  inputName = 'searchInvestor',
  searchDrafts = false,
  rounds = [],
  selectedRound = {},
}) => {
  const { investorCategoryMapping } = useSelector(
    ({
      cbiEntity: {
        generalInfo: { allInvestorCategoryValues },
      },
    }) => {
      return {
        investorCategoryMapping: allInvestorCategoryValues,
      };
    },
    shallowEqual
  );

  const categoryDict = useMemo(() => {
    const dict = investorCategoryMapping.reduce((acc, el) => {
      acc[el.idInvestorCategory] = el.name;
      return acc;
    }, {});
    dict[0] = undefined;
    return dict;
  }, [investorCategoryMapping]);

  const { values, setFieldValue } = useFormikContext();

  const isNotRequired = rounds.some((el) => el.id === selectedRound.id);

  const warnIfNotInvestor = (option) => {
    const { isInvestor, id } = option;
    return (
      !isInvestor &&
      `Warning - this will create an investor profile for ${option.name} (id: ${id})`
    );
  };

  const toggleLeadInvestor = (option) => {
    const currentValues = get(values, option.name, {});
    setFieldValue(option.name, {
      ...currentValues,
      isLeadInvestor: !get(currentValues, 'isLeadInvestor', false),
    });
  };

  const isNotInvestorEntity = (option) => !option.data.isInvestor;
  const isLeadInvestor = (option) => option.value.isLeadInvestor;

  const getInvestorSorted = (data) =>
    getEntityByName(data, 3, false, searchDrafts).then((results) => {
      const completeResults = results.map((el) => {
        return {
          ...el,
          investorType: el?.idInvestorCategory
            ? categoryDict[el.idInvestorCategory]
            : '',
          investorTypeId: el?.idInvestorCategory || 0,
        };
      });
      return [].concat(
        completeResults.filter((entity) => entity.isInvestor),
        completeResults.filter((entity) => !entity.isInvestor)
      );
    });

  useEffect(() => {
    if (isNotRequired) {
      setFieldValue(name, []);
    }
  }, [isNotRequired]);

  return (
    <div>
      <ChipedValueField
        isCreatable
        onCreateOption={onCreateNewEntity}
        size={size}
        disabled={isNotRequired || disabled}
        inputName={inputName}
        name={name}
        label={label}
        required={isNotRequired && required}
        placeholder={placeholder}
        loadOptions={getInvestorSorted}
        renderComp={{ Option: InvestorOption }}
        chipComponent={InvestorChipField}
        onChipClick={toggleLeadInvestor}
        shouldHighlightChip={isLeadInvestor}
        shouldHighlightOption={isNotInvestorEntity}
        handleWarning={warnIfNotInvestor}
        modalTitle="Remove Investor"
        modalBody="Are you sure you would like to remove this investor?"
      />
    </div>
  );
};

InvestorSearchField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputName: PropTypes.string,
  rounds: PropTypes.object,
  selectedRound: PropTypes.object,
  searchDrafts: PropTypes.bool,
};

export default InvestorSearchField;
