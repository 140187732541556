import {
  HasPendingFundingDraftsRequest,
  HasPendingFundingDraftsResponse,
} from '@cbinsights/fundingservice/fundingservice';

import { request } from 'client/modules/common/utils/request';

export const hasPendingFundingDrafts = (
  req: HasPendingFundingDraftsRequest
): Promise<HasPendingFundingDraftsResponse> =>
  request({
    url: '/service/fundingservice/HasPendingFundingDrafts',
    body: { ...req },
  }).then((r) => r.body);
