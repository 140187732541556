import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Checkbox from 'client/component-library/Checkbox';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';

const styles = () => ({
  checkbox: {
    width: '13px',
    height: 'auto',
    marginTop: '-4px',
    fontSize: '1rem',
  },
  tableCell: {},
  root: {
    padding: 0,
  },
});

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

/** @deprecated It's a React component that renders a checkbox */
class CheckboxCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, checked, classes, disabled, dataTest } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Checkbox
          classes={{ root: classes.root }}
          className={classes.checkbox}
          id={id}
          checked={checked}
          disabled={disabled}
          data-test={dataTest}
          color="secondary"
        />
      </MuiThemeProvider>
    );
  }
}

CheckboxCell.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  checked: PropTypes.bool,
  dataTest: PropTypes.string,
};

CheckboxCell.defaultProps = {
  disabled: false,
  id: '',
  checked: false,
  dataTest: '',
};

/** @deprecated */
export default withStyles(styles)(CheckboxCell);
