/* eslint-disable react/static-property-placement */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  startItem: PropTypes.number.isRequired,
  totalItems: PropTypes.number,
  setPage: PropTypes.func.isRequired,
};

const baseStyles = 'block text-blue border-r border-grey-light px-3 py-2';
const selectedStyles = 'bg-blue-600 text-white cursor-default';
const unselectedStyles = 'bg-white text-blue-600 hover:bg-gray-300';

const defaultProps = {
  totalItems: 0,
};

/** 
 * @deprecated 
 * It renders a pagination component that displays a maximum of 15 pages at a time, with the current
page in the middle 
 */
export default class Pagination extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.handleClick.bind(this);
  }

  handleClick(e, i) {
    e.preventDefault();
    const { setPage, totalItems, itemsPerPage } = this.props;
    const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 0;

    if (i < 1 || i > totalPages) {
      return;
    }

    setPage(i);
  }

  render() {
    const { page, itemsPerPage, startItem, totalItems } = this.props;
    const visiblePageMax = 15;
    const visibleHalfMax = Math.floor(visiblePageMax / 2);
    const currentPageItems = page * itemsPerPage;
    const endItem =
      totalItems && currentPageItems > totalItems
        ? totalItems
        : currentPageItems;
    const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 0;
    const pageItems = [];
    let visibleStartPage;
    let visibleEndPage;

    if (totalPages <= visiblePageMax) {
      visibleStartPage = 1;
      visibleEndPage = totalPages;
    } else if (totalPages - page < visibleHalfMax) {
      visibleStartPage = totalPages - visiblePageMax;
      visibleEndPage = totalPages;
    } else if (page > visibleHalfMax) {
      visibleStartPage = page - visibleHalfMax;
      visibleEndPage = page + visibleHalfMax;
    } else {
      visibleStartPage = 1;
      visibleEndPage = visiblePageMax;
    }

    for (let i = visibleStartPage; i <= visibleEndPage; i++) {
      const isSelectedPage = i === page;
      const selectionStyles = isSelectedPage
        ? selectedStyles
        : unselectedStyles;
      pageItems.push(
        <li key={i}>
          <a
            className={`${baseStyles} ${selectionStyles}`}
            href={`/${i}`}
            onClick={(e) => this.handleClick(e, i)}
          >
            <span>{i}</span>
          </a>
        </li>
      );
    }

    return (
      <div className="flex flex-col justify-center mt-4 mb-8">
        <ul className="flex list-reset border border-grey-light rounded m-auto">
          <li>
            <a
              className={`${baseStyles} ${
                page <= 1 ? 'cursor-not-allowed' : 'hover:bg-gray-300'
              }`}
              href={`/${page - 1}`}
              onClick={(e) => this.handleClick(e, page - 1)}
            >
              <span aria-label="Previous">&larr;</span>
            </a>
          </li>
          {pageItems}
          <li>
            <a
              className={`${baseStyles} ${
                page >= totalPages ? 'cursor-not-allowed' : 'hover:bg-gray-300'
              }`}
              href={`/${page + 1}`}
              onClick={(e) => this.handleClick(e, page + 1)}
            >
              <span aria-label="Next">&rarr;</span>
            </a>
          </li>
        </ul>
        <div className="self-center mt-2">
          Showing {startItem}-{endItem} of {totalItems}
        </div>
      </div>
    );
  }
}
