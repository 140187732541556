import React from 'react';
import { RouteObject } from 'react-router-dom';

const CompanyOfTheDay = React.lazy(
  () => import('./containers/CompanyOfTheDay')
);

export const industryLandingPagesRoutes: RouteObject = {
  path: 'company-of-the-day',
  element: (
    <React.Suspense fallback={undefined}>
      <CompanyOfTheDay />
    </React.Suspense>
  ),
};
