/**
 * @author Michael Citro
 * @summary We can use this as a normal select menu. It has built in search within it options.
 *          It will not make API call for possible other options, if that is required we can create a HOC for that.
 * @example <SelectWithSearch
              options={[{id, name}]}
              onChange={this.handleSelection}
              value={value}
              dataTest={someValue}
              optionIdKey="id"
              optionLabelKey="name"
              rootClass="noMargin"
              name='some-form-field-name'
            />
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { MenuItem } from '@material-ui/core';
import TextField from 'client/component-library/TextField';
import { withStyles } from 'client/component-library/styles';
import shallowCompare from 'react-addons-shallow-compare';
import SearchableSelect from './SearchableSelect';
import {
  GENERAL_CONFIG_MAP,
  FIELD_TYPES,
} from '../../../modules/cbi-entity/utils/form-fields-config';
import styles from './styles/SelectWithSearchStyles';
import {
  eventWasTabKey,
  isAlphaNumeric,
  eventWasShiftKey,
} from '../../../modules/common/utils/domEventChecks';
import { getNextFocusableElementInForm } from '../../Form';

const DEFAULT_VALUE = 'Select One';
const defaultProps = GENERAL_CONFIG_MAP[FIELD_TYPES.DROPDOWN]().compProps;

function renderOptions(options, name, id) {
  return options.map((option) => {
    return (
      <MenuItem key={option[id]} value={option[id]}>
        {option[name]}
      </MenuItem>
    );
  });
}

export class SelectWithSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      selectRef: null,
      inputRef: null,
      searchValueDefault: null,
      selectedValue: null, // If we are not using redux to store value this component will do it internally
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  onSelectKeyPress = (event) => {
    const isExcludeKey = eventWasTabKey(event) || eventWasShiftKey(event);

    if (!isExcludeKey && !this.state.isFocused) {
      this.openSearchSelection(event);
    }
  };

  getSelectRef = (ref) => {
    this.setState({ selectRef: ref });
  };

  getInputRef = (ref) => {
    this.setState({ inputRef: ref });
    this.focusSearchField();
  };

  getSelectedValue = () => {
    const { options, optionLabelKey, optionIdKey, value } = this.props;
    const selection = options.find((option) => option[optionIdKey] === value);
    const name = (selection && selection[optionLabelKey]) || DEFAULT_VALUE;
    const key = (selection && selection[optionIdKey]) || DEFAULT_VALUE;

    if (!options.length) {
      // will not display anything is no options
      return 'no-values-to-select';
    }
    if (!this.state.isFocused) {
      // if not focused will display selection or 'SELECT ONE'
      return this.state.selectedValue || key;
    }

    // While Focused will display name, due to field not being select but text
    return name;
  };

  focusSearchField = () => {
    // setTimeout due to waiting for render cycle to complete. placing call to the back of callback queue
    setTimeout(() => {
      if (this.state.inputRef) {
        this.state.inputRef.focus();
      }
    });
  };

  handleSelectUpdate = (valueObj = {}) => {
    const { onChange, optionIdKey } = this.props;

    if (onChange) {
      onChange(valueObj);
    } else {
      this.setState({ selectedValue: valueObj[optionIdKey] });
    }
    this.closeSearchSelection();

    setTimeout(() => {
      const nextElement = getNextFocusableElementInForm();
      if (nextElement && nextElement.focus) {
        nextElement.focus();
      }
    });
  };

  openSearchSelection = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (this.props.options.length && !this.props.disabled) {
      this.setState({
        isFocused: true,
        searchValueDefault: isAlphaNumeric(event) ? event.key : null,
      });
      this.focusSearchField();
    }
  };

  closeSearchSelection = () => {
    this.setState({ isFocused: false });

    // after render cycle refocus the select field
    setTimeout(() => {
      this.state.selectRef.focus();
    });
  };

  /**
   * @deprecated
   * We're using the `TextField` component from `material-ui` to render the select dropdown. We're
   * using the `Popover` component from `material-ui` to render the searchable select dropdown
   * @returns A React Fragment with a TextField and a Popover.
   */
  render() {
    const {
      options,
      optionLabelKey,
      optionIdKey,
      classes,
      rootClass,
      isTableCell,
      dataTest,
      defaultOptionText,
      renderOption,
      isFilteringDisabled,
      onSearchChange,
      disabled,
      textInputProps,
      ...selectProps
    } = this.props;
    const { isFocused, selectRef } = this.state;
    const selectedValue = this.getSelectedValue();

    return (
      <React.Fragment>
        <TextField
          {...defaultProps}
          {...selectProps}
          value={selectedValue}
          onClick={this.openSearchSelection}
          onKeyDown={this.onSelectKeyPress}
          inputRef={this.getSelectRef}
          select={!isFocused}
          classes={{ root: classes[rootClass] }}
          disabled={!options.length || disabled}
          InputProps={
            isTableCell ? { style: styles.tableText } : textInputProps
          }
        >
          <MenuItem value={DEFAULT_VALUE}> {defaultOptionText} </MenuItem>
          {renderOptions(options, optionLabelKey, optionIdKey)}
        </TextField>

        <Popover
          anchorEl={selectRef}
          open={isFocused}
          onClose={this.closeSearchSelection}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SearchableSelect
            menuItems={options}
            onSearchChange={onSearchChange}
            handleSelect={this.handleSelectUpdate}
            value={this.state.searchValueDefault} // dont default to anything
            inputRef={this.getInputRef}
            renderOption={renderOption}
            isFilteringDisabled={isFilteringDisabled}
            filterOptions={{
              valueFilterKey: optionIdKey,
              nameFilterKey: optionLabelKey,
            }}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

export const SelectWithSearchProps = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rootClass: PropTypes.oneOf(['noMargin', 'rootDefault', 'widthAuto']),
  optionLabelKey: PropTypes.string,
  optionIdKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  onSearchChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isTableCell: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  defaultOptionText: PropTypes.string,
  isFilteringDisabled: PropTypes.bool,
  textInputProps: PropTypes.object,
};

SelectWithSearch.propTypes = SelectWithSearchProps;

SelectWithSearch.defaultProps = {
  renderOption: null,
  options: [],
  rootClass: 'rootDefault',
  isTableCell: false,
  isFilteringDisabled: false,
  disabled: false,
  dataTest: '',
  optionLabelKey: 'name',
  optionIdKey: 'id',
  defaultOptionText: 'Select One',
  onSearchChange: () => {},
  textInputProps: {},
};

const StyledSelectWithSearch = withStyles(styles)(SelectWithSearch);

export default StyledSelectWithSearch;
