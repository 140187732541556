import { UseQueryResult, useQuery } from 'react-query';

import { request } from 'client/modules/common/utils/request';

type PageId = number;
type AccessLevel = number;

type GetUserInfoResponse = {
  idUser: number;
  firstName: string;
  lastName: string;
  email: string;
  adminPagePermissions: Record<PageId, AccessLevel>;
};

const getUserInfo = async () =>
  request({
    method: 'GET',
    url: '/getUserInfo',
  }).then((r) => r.body as GetUserInfoResponse);

export const useGetUserInfoQuery = (): UseQueryResult<
  GetUserInfoResponse,
  Error
> =>
  useQuery<GetUserInfoResponse, Error, GetUserInfoResponse, ['GetUserInfo']>(
    ['GetUserInfo'],
    getUserInfo,
    {
      notifyOnChangeProps: ['data', 'isFetching'], // prevent rerenders, only update selected props
    }
  );
