import {
  GetEntityInfoRequest,
  GetEntityInfoResponse,
} from '@cbinsights/cbientityservice/cbientityservice';
import { request } from 'client/modules/common/utils/request';

export const getEntityInfo = (
  req: Partial<GetEntityInfoRequest>
): Promise<GetEntityInfoResponse> => {
  return request({
    url: '/service/cbientityservice/GetEntityInfo',
    body: {
      ...req,
    },
  }).then((r): GetEntityInfoResponse => r.body);
};
