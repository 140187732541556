import { useMemo, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import { SuggestionResult } from '@cbinsights/adminservice/adminservice';
import { useGetSuggestUsersAndTeams } from '../services/hooks/useSuggestUsersAndTeams';
import SearchUtils, { SearchEntry } from '../utils/search';

export const useSearchTeam = () => {
  const [teamSearchValue, setTeamSearchValue] = useState('');
  const [isloading, setisloading] = useState(false);
  const {
    data: suggestList,
    refetch: refetchSuggestList,
    isFetching: isLoadingSuggestList,
  } = useGetSuggestUsersAndTeams({
    enabled: false,
    params: { term: teamSearchValue },
  });

  const getUserDisplayName = (user: SuggestionResult['user']) => {
    if (!user.first_name || !user.last_name) {
      return user.email;
    }

    return `${user.first_name} ${user.last_name} - ${user.email}`;
  };

  const handleSearchClick = (entry: SearchEntry) => {
    SearchUtils.addSearchEntry(entry);
  };

  const getOptionType = (result: SuggestionResult) => {
    if (result.team) {
      return {
        type: 'team',
        id: result.team.id_team,
        value: `team-${result.team.id_team}`,
        label: (
          <Link
            to={`/teams/${result.team.id_team}`}
            onClick={() =>
              handleSearchClick({
                type: 'team',
                id: result.team.id_team,
                text: result.team.name,
              })
            }
          >
            <div className="flex justify-between">
              <span className="flex justify-between">
                <BaseIcon iconName="groups" isIconBlock={false} />
                <span className="ml-2">{result.team.name}</span>
              </span>
              <span>id: {result.team.id_team}</span>
            </div>
          </Link>
        ),
      };
    }

    if (result.user)
      return {
        type: 'user',
        id: result.user.id_user,
        value: `user-${result.user.id_user}`,
        label: (
          <Link
            to={`/users/${result.user.id_user}`}
            onClick={() =>
              handleSearchClick({
                type: 'user',
                id: result.user.id_user,
                text: getUserDisplayName(result.user),
              })
            }
          >
            <div className="flex justify-between">
              <span className="flex justify-between">
                <BaseIcon iconName="person" isIconBlock={false} />
                <span className="ml-2">{getUserDisplayName(result.user)}</span>
              </span>
              <span>id: {result.user.id_user}</span>
            </div>
          </Link>
        ),
      };

    return { value: '', label: '' };
  };

  const loadOptions = useMemo(() => {
    return debounce(() => refetchSuggestList(), 1000);
  }, []);

  const searchOptions: Array<DefaultOptionType> = useMemo(() => {
    if (!suggestList) {
      setisloading(true);
      return null;
    }
    setisloading(false);
    const options = suggestList.results;
    return options.map((item) => getOptionType(item));
  }, [suggestList]);

  const handleTeamSearch = (value: string) => {
    setTeamSearchValue(value);
    if (value?.length < 3) {
      return;
    }
    loadOptions();
  };

  return {
    handleTeamSearch,
    searchOptions,
    teamSearchValue,
    isLoadingSuggestList: isLoadingSuggestList || isloading,
  };
};
