import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import { setToastError } from 'client/modules/third-party-admin/profile-queue/utils';

export function clearNewsClusters() {
  return request({
    url: 'service/newsqueueservice/ClearNewsQueueClusters',
    body: {},
  })
    .then(({ body: { success } }) => {
      return success;
    })
    .catch((error) => {
      setToastError('Error Clearing Queue');
      logInfo({
        error,
        message: 'News Queue: Failed To Clear Queue',
        method: 'clearNewsClusters',
      });
      throw error;
    });
}
