import React, { isValidElement, cloneElement } from 'react';
import { get } from 'lodash';
import { connect } from 'formik';

import { useDerivedChildProps } from 'client/modules/third-party-admin/valuations/hooks/useDerivedChildProps';
import { FormField } from './UtilFields';

/** @deprecated A function that takes in a formik object and returns a component. */
export const ContextField = connect(({ children, size, name, formik }) => {
  const arrayError = Object.values(
    get(formik, `errors.${name}[0]`, { error: '' })
  )[0];

  // accounts for errors as objects, strings, and components
  let error = get(formik, `errors.${name}`, '') || arrayError;
  if (error && typeof error === 'object' && !isValidElement(error)) {
    error = Object.values(error).pop();
  }

  const warning = get(formik, `values.warnings.${name}`, '');
  const fieldMetaData = formik.getFieldMeta(name);

  const derivedChildProps = useDerivedChildProps(fieldMetaData, name);

  return (
    <FormField size={size}>
      {React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids uncaught exception error
        if (isValidElement(child)) {
          return cloneElement(child, derivedChildProps);
        }
        return child;
      })}
      {error && (
        <div
          className="text-red-600 text-xs text-left"
          data-test={`error-${name}`}
        >
          {error}
        </div>
      )}
      {warning && (
        <div className="text-yellow-600 text-xs text-left">{warning}</div>
      )}
    </FormField>
  );
});
