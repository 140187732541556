import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { PermissionsProtectedRoute } from 'client/routes/PermissionsProtectedRoute';
import { TeamRoutes } from './RouteHelpers';
import { TeamPage } from './components/TeamPage';
import { TeamsPage } from './containers/TeamsPage';
import { teamPermissions } from './permissions/Team';

// This page will be hidden for the time being
export const teamRoutes: RouteObject = {
  path: 'teams',
  element: (
    <PermissionsProtectedRoute
      pageId={teamPermissions.pageId}
      currentPath={TeamRoutes.Team}
    />
  ),
  children: [
    {
      path: TeamRoutes.PageIndex,
      element: <Navigate to="/search" replace />,
    },
    {
      path: TeamRoutes.Team,
      element: <TeamPage />,
    },
  ],
};

export const search: RouteObject = {
  path: 'search',
  element: (
    <PermissionsProtectedRoute
      pageId={teamPermissions.pageId}
      currentPath={TeamRoutes.Search}
    />
  ),
  children: [
    {
      path: TeamRoutes.Search,
      element: <TeamsPage />,
    },
  ],
};
