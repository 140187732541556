import { pickBy } from 'lodash';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import filterListOfObjects from 'client/modules/common/utils/filterListOfObjects';
import {
  updateSuggestionsHelper,
  updateSuggestionTypeError,
  updateInputInfo,
} from 'client/modules/common/components/Table/ConnectedTableComponents/redux/actions/autocomplete-input';
import {
  updateNewRecordWithUserInfo,
  updateNewRecordWithPageInfo,
} from 'client/modules/admin-user-admin/redux/actions/tab-data';

export const adminUserAdminSuggestionHandler = (
  dispatch,
  getState,
  inputId,
  term,
  suggestionType
) => {
  const userAdmin = getState().adminUsers;
  const [recordId] = inputId.split('-'); // get the record id
  if (suggestionType === 'adminUser') {
    const allAdminUsers = userAdmin.userInfo;
    const filteredAdminUsers = filterListOfObjects({
      value: term,
      listOfItems: allAdminUsers,
      fields: ['name', 'email'],
    });
    const userId =
      getKeyValueFromListViaOtherKey(filteredAdminUsers, 'name', term, 'id') ||
      0;
    if (userId) {
      dispatch(
        updateNewRecordWithUserInfo({
          recordId,
          userId,
          userName: term,
          email: getKeyValueFromListViaOtherKey(
            filteredAdminUsers,
            'id',
            userId,
            'email'
          ),
        })
      );
      dispatch(updateInputInfo(inputId, term, userId));
    }
    return dispatch(updateSuggestionsHelper(term, inputId, filteredAdminUsers));
  } if (suggestionType === 'adminPage') {
    let adminPages = userAdmin.pageInfo;
    const {isTpaUser} = userAdmin.tabData[userAdmin.currentTab].searchBox;
    // Filter allAdminPages to return 3pv pages when user is a third party admin user
    if (isTpaUser === true) {
      adminPages = pickBy(adminPages, (value) => value.isTpaPage);
    }

    const filteredAdminPages = filterListOfObjects({
      value: term,
      listOfItems: adminPages,
    });
    const pageId =
      getKeyValueFromListViaOtherKey(filteredAdminPages, 'name', term, 'id') ||
      0;
    const isTpaPage =
      getKeyValueFromListViaOtherKey(
        filteredAdminPages,
        'name',
        term,
        'isTpaPage'
      ) || false;
    if (pageId) {
      dispatch(
        updateNewRecordWithPageInfo({
          recordId,
          pageId,
          pageName: term,
          isTpaPage,
        })
      );
      dispatch(updateInputInfo(inputId, term, pageId));
    }
    return dispatch(updateSuggestionsHelper(term, inputId, filteredAdminPages));
  }
  return dispatch(updateSuggestionTypeError());
};

export default adminUserAdminSuggestionHandler;
