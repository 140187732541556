/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Divider from '@material-ui/core/Divider';
import CropIcon from '@material-ui/icons/Crop';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ReactCrop from 'react-image-crop';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './image-editor.css';

const srcToFile = (src, fileName) =>
  fetch(src)
    .then((res) => res.arrayBuffer())
    .then((buffer) => new File([buffer], fileName, { type: 'image/png' }));

/** 
 * @deprecated 
 * It renders a ReactCrop component, which is a wrapper around the cropper.js library. It also renders
a couple of buttons, one to cancel the edit and one to save the image 
*/
export default class EditScreen extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    image: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    updateCrop: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      crop: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.image.preview !== this.props.image.preview) {
      this.setState({ crop: {} });
    }
  }

  componentWillUnmount() {
    if (this.saveDisableTimeout) {
      clearTimeout(this.saveDisableTimeout);
    }
  }

  handleCrop = (crop) => this.setState({ crop });

  initCrop = () =>
    this.setState({
      crop: {
        height: 99,
        width: 99,
      },
    });

  handleSave = () => {
    this.setState({ saveDisabled: true });
    this.saveDisableTimeout = setTimeout(() => {
      this.setState({ saveDisabled: false });
    }, 3000);
    if (this.props.image instanceof File) {
      return this.props.onSave(
        this.props.image,
        this.props.image,
        this.state.crop
      );
    }
    return srcToFile(this.props.image.src, 'upload.png').then((file) => {
      // The image can come from the cropping lib (src) or the drag and drop lib (preview)
      file.preview = this.props.image.src || this.props.image.preview; // eslint-disable-line no-param-reassign
      this.props.onSave(file, this.props.image, this.state.crop);
    });
  };

  cropAndSave = () => {
    this.props.updateCrop(this.state.crop, false).then(this.handleSave);
  };

  render() {
    const { image, onCancel, updateCrop } = this.props;
    const hasActiveCrop = !isEqual(this.state.crop, {});
    const rightBtn = {};
    if (hasActiveCrop) {
      rightBtn.action = this.cropAndSave;
      rightBtn.datatest = 'mm-modal-crop';
      rightBtn.text = 'Apply';
    } else {
      rightBtn.action = this.handleSave;
      rightBtn.datatest = 'mm-modal-save';
      rightBtn.text = 'Apply';
    }
    return (
      <div>
        <DialogContent className="mm-modal-content image-cropper">
          <ReactCrop
            crop={this.state.crop}
            src={image.preview}
            onChange={this.handleCrop}
            onComplete={this.handleCrop}
            minWidth={15}
            minHeight={15}
            imageStyle={{ maxHeight: 'none' }}
            data-test="mm-react-crop-img"
          />
          <DialogActions style={{ justifyContent: 'center' }}>
            <div
              onClick={() => updateCrop(this.state.crop)}
              className="crop-btn"
              data-test="mm-modal-crop"
            >
              <CropIcon />
              <span>crop</span>
            </div>
          </DialogActions>
        </DialogContent>
        <div style={{ flex: '1 1 auto' }} />
        <Divider />
        <small>Please do not upload offensive or illegal content.</small>
        <div className={styles['image-editor-actions']}>
          <Button
            onClick={onCancel}
            className="modal-back-button text-gray"
            theme="ghost"
            data-test="mm-modal-cancel"
          >
            Back
          </Button>
          <Button
            disabled={this.state.saveDisabled}
            onClick={rightBtn.action}
            className="material-btn-primary modal-bottom-button"
            data-test={rightBtn.datatest}
          >
            {rightBtn.text}
          </Button>
        </div>
      </div>
    );
  }
}
