import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/** @deprecated This class is a React component that renders a link to a URL */
class LinkCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { value, className, dataTest, onClick, linkText, name } = this.props;
    return (
      <Link
        to={`${value}`}
        data-test={dataTest}
        className={className}
        onClick={onClick}
      >
        <span>{linkText || name}</span>
      </Link>
    );
  }
}

LinkCell.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  linkText: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

LinkCell.defaultProps = {
  className: '',
  dataTest: 'link-cell',
  linkText: '',
  name: '',
  onClick: () => {},
  value: '',
};

export default LinkCell;
