import moment, { Moment } from 'moment';
import { Form } from 'antd';
import {
  ListPackagesResponse,
  ListUserTrialResponse,
} from '@cbinsights/userv2service/userv2service';
import { DefaultOptionType } from 'antd/lib/select';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFuseSearch from 'client/modules/team/hooks/useFuseSearch';
import { useGetPackagesQuery } from '../services/hooks/useGetPackagesQuery';
import { useGetUserTrialQuery } from '../services/hooks/useGetUserTrialQuery';

export type TrialPackageForm = {
  userId: string;
  package: string;
  endDate: Moment;
};

export type FormData = {
  userId: number;
  packageId: number;
};

const searchParamKeys = {
  userId: 'userId',
};

const initialValues: TrialPackageForm = {
  userId: null,
  package: null,
  endDate: null,
};

type Props = {
  defaultUserId?: string;
};

export default function useTrialPackageForm({ defaultUserId }: Props) {
  const [form] = Form.useForm<TrialPackageForm>();
  const [packageOptions, setPackageOptions] = useState<
    Array<DefaultOptionType>
  >([]);
  const [activeTrial, setActiveTrial] =
    useState<ListPackagesResponse['packages'][0]>(null);
  const [userTrialDetail, setUserTrialDetail] =
    useState<ListUserTrialResponse['user_trials'][0]>(null);

  const [searchParams] = useSearchParams();

  const { results, setSearchQuery } = useFuseSearch({
    data: packageOptions,
    options: { keys: ['label'], shouldSort: true, threshold: 0.3 },
  });

  const initialUserId =
    searchParams.get(searchParamKeys.userId) ||
    initialValues.userId ||
    defaultUserId;

  const [formData, setFormData] = useState<FormData>({
    userId: initialUserId ? parseInt(initialUserId, 10) : null,
    packageId: null,
  });

  const { userId } = formData;
  const { packageId } = formData;

  const setUserId = useCallback((newUserId: number) => {
    setFormData((prevState) => ({ ...prevState, userId: newUserId }));
  }, []);

  const setPackageId = useCallback((newPackageId: number) => {
    setFormData((prevState) => ({ ...prevState, packageId: newPackageId }));
  }, []);

  const { data: packagesData } = useGetPackagesQuery({
    enabled: true,
    params: {},
  });

  useEffect(() => {
    if (!packagesData) {
      return;
    }

    setPackageOptions(
      packagesData.packages.map((item) => ({
        label: item.name,
        value: item.id_package,
      }))
    );
  }, [packagesData]);

  const {
    data: userTrialsInfo,
    error: getUserTrialError,
    refetch: refetchUserTrial,
  } = useGetUserTrialQuery({
    enabled: !!userId,
    params: {
      id_user: userId,
    },
  });

  useEffect(() => {
    if (userId && getUserTrialError) {
      form.setFields([
        {
          name: 'userId',
          errors: ['Invalid UserID'],
        },
      ]);
    }
  }, [getUserTrialError]);

  useEffect(() => {
    if (activeTrial) {
      setPackageId(activeTrial.id_package);
    }
  }, [activeTrial]);

  useEffect(() => {
    if (!userTrialsInfo) {
      return;
    }

    const userTrialInfo = userTrialsInfo?.user_trials.find((trial) => {
      return (
        moment(trial.ts_trial_package_end).isAfter(moment().startOf('day')) &&
        trial.id_user === userId
      );
    });

    const newActiveTrial = packagesData?.packages.find(
      (item) => item.id_package === userTrialInfo?.id_package_trial
    );

    setActiveTrial(newActiveTrial);
  }, [userTrialsInfo, packagesData, userId]);

  useEffect(() => {
    if (!packageId) {
      return;
    }

    const item = userTrialsInfo?.user_trials.find(
      (trial) =>
        trial.id_package_trial === packageId && trial.id_user === userId
    );

    if (!item) {
      setUserTrialDetail(null);
      return;
    }

    setUserTrialDetail(item);
  }, [userId, userTrialsInfo, packageId]);

  const resetForm = (resetTrialInfo = true) => {
    form.resetFields();
    setFormData({
      packageId: null,
      userId: initialUserId ? parseInt(initialUserId, 10) : null,
    });
    refetchUserTrial();
    if (resetTrialInfo) {
      setActiveTrial(null);
      setUserTrialDetail(null);
    }
    setPackageOptions([]);
    setSearchQuery('');
  };

  return {
    form,
    resetForm,
    values: {
      packageOptions: results,
      packageSearchValue: setSearchQuery,
      userTrialDetail,
      userId,
      packageId,
      activeTrial,
      initialValues: { ...initialValues, userId },
    },
    requests: {
      packagesData,
      userTrialsInfo,
      getUserTrialError,
    },
    setValues: {
      setUserId,
      handlePackageSearch: setSearchQuery,
      setPackageId,
    },
  };
}
