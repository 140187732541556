export const PIPELINE_GRAPH_CONFIG = {
  nodeHighlightBehavior: true,
  directed: true,
  height: 500,
  width: 900,
  minZoom: 0.1,
  maxZoom: 3,
  node: {
    renderLabel: true,
    labelProperty: 'displayLabel',
  },
  link: {
    color: '#bbdbe5',
    highlightColor: '#4cabce',
  },
};

export const COMPONENT_GRAPH_CONFIG = {
  ...PIPELINE_GRAPH_CONFIG,
  width: 1200,
  link: {
    color: '#bbdbe5',
    highlightColor: '#4cabce',
  },
};
