const styles = (theme) => ({
  tableCell: {},
  required: {},
  details: {
    alignItems: 'center',
  },
  expansionPanelSummaryRoot: {
    minHeight: '48px',
    padding: '0',
  },
  expansionPanelSummaryContent: {
    margin: '0 !important',
  },
  expansionPanelSummaryExpandIcon: {
    width: '20px',
    padding: 0,
  },
  expensionPanel: {
    boxShadow: '0 0px 1px -1px rgba(0, 0, 0, 0.2)', // meant to override the original boxShadow
  },
  expensionPanelWithValue: {
    backgroundColor: '#eff0f3 !important',
    boxShadow: '0 0px 1px -1px rgba(0, 0, 0, 0.2)',
  },
  expensionPanelActionRoot: {
    padding: '8px 8px',
  },
  text: {
    ...theme.typography.caption,
    display: 'inline',
  },
  addIconWrapper: {
    marginTop: '10px',
  },
  textSelected: {
    ...theme.typography.caption,
    display: 'inline',
    color: '#006699',
  },
  addIconWrapperSelected: {
    marginTop: '10px',
    color: '#006699',
  },
  iconButton: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    fontSize: '1.2rem',
    margin: '0 10px',
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  textField: {
    marginBottom: '10px',
    width: '128px',
  },
});

export default styles;
