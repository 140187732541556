import React from 'react';
import { Modal, Switch, message } from 'antd';
import { useUpdateTeam } from '../../hooks/useUpdateTeam';

type Props = {
  mfaStatus?: boolean;
  teamId: number;
  refetchTeam: () => void;
};

const MFA = ({ mfaStatus, teamId, refetchTeam }: Props) => {
  const { onSubmit } = useUpdateTeam(refetchTeam);
  const confirmChange = () => {
    onSubmit(
      {
        mfa_enabled: !mfaStatus,
      },
      teamId
    )
      .then(() => {
        message.success("Success! The team's MFA status has been updated.");
        refetchTeam();
      })
      .catch(() => {
        message.error(
          "Error! Failed to update the team's MFA status. Please try again later."
        );
      });
  };

  const handleChange = () => {
    Modal.confirm({
      title: 'Change MFA status',
      content: `Are you sure you want to ${
        mfaStatus ? 'disable' : 'enable'
      } this setting?`,
      onOk: confirmChange,
    });
  };

  return <Switch checked={mfaStatus} onChange={handleChange} />;
};

export default MFA;
