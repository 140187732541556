import React, { useEffect } from 'react';
import { Alert, Button, Modal, Tooltip, message } from 'antd';
import { useCopyTextToClipboard } from 'client/utils/copy';
import { useMutation } from 'react-query';
import { buildEnvURL } from 'client/utils/env';
import { useGetPasswordResetToken } from '../services/hooks/useGetPasswordResetToken';
import { createPasswordResetToken } from '../services/api/CreatePasswordResetToken';

type Props = {
  email: string;
  isOpen: boolean;
  handleClose: () => void;
};

const ResetPassword = ({ isOpen, handleClose, email }: Props) => {
  const {
    data,
    refetch: refetchResetToken,
    isFetching: isFetchingResetToken,
  } = useGetPasswordResetToken({
    enabled: Boolean(email) && isOpen,
    params: { email },
  });

  const { isLoading, copyTextToClipboard } = useCopyTextToClipboard();

  const createPasswordResetTokenQuery = useMutation(createPasswordResetToken);

  const handleGetResetPasswordLink = () => {
    createPasswordResetTokenQuery.mutate(
      { email },
      {
        onError: () => {
          message.error(
            'Unable to generate a new reset password link. Please try again later.'
          );
        },
        onSuccess: () => {
          message.success("User's reset password link has been updated");
          refetchResetToken();
        },
      }
    );
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (data && !data.token) {
      handleGetResetPasswordLink();
    }
  }, [isOpen, data]);

  const ResetPasswordURL = React.useMemo(() => {
    const code = data?.token;
    if (!code) {
      return '';
    }
    return buildEnvURL(`reset-password?reset-token=${code}`);
  }, [data?.token]);

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      width="50vw"
      footer={
        <div>
          <Button onClick={handleClose}>Close</Button>
        </div>
      }
      title="Reset Password Link"
    >
      <>
        <div className="flex">
          <div className="mr-2 flex-1 rounded border border-solid border-charcoal-300 p-[6px] whitespace-nowrap overflow-hidden">
            {ResetPasswordURL}
          </div>
          <div className="flex">
            <div className="mr-2">
              <Button
                type="default"
                disabled={!ResetPasswordURL}
                className="h-full"
                loading={
                  isLoading ||
                  isFetchingResetToken ||
                  createPasswordResetTokenQuery.isLoading
                }
                onClick={() => copyTextToClipboard(ResetPasswordURL)}
              >
                Copy
              </Button>
            </div>
            <div>
              <Tooltip title="Generating a new password reset link will invalidate any existing reset links, including those in welcome emails.">
                <Button
                  className="h-full"
                  onClick={handleGetResetPasswordLink}
                  loading={
                    isLoading ||
                    isFetchingResetToken ||
                    createPasswordResetTokenQuery.isLoading
                  }
                >
                  Generate New Link
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <Alert
          className="mt-2"
          message="Reset password links do not expire over time; they only expire when used or if a new link is generated."
          type="info"
          showIcon
        />
      </>
    </Modal>
  );
};

export default ResetPassword;
