import {
  ListUserTrialRequest,
  ListUserTrialResponse,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const listUserTrial = (
  req: IndexSignature<ListUserTrialRequest>
): Promise<ListUserTrialResponse> =>
  request({
    url: '/service/userv2service/ListUserTrial',
    body: req,
  }).then((r) => r.body);
