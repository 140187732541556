/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import AutocompleteDropdown from 'client/modules/common/components/AutocompleteDropdown';
import DROPDOWN_LIMIT from 'client/modules/common/components/AutocompleteDropdown/utils/constants';
import { updateSuggestions } from './redux/actions/updateSuggestions';

import styles from './styles/css/autocomplete-input-cell.css';
import { AutocompletePropTypes } from '../../AutocompleteDropdown/Autocomplete';
import { updateInputInfo } from './redux/actions/autocomplete-input';

const createFixedItem = (showFixedItemAtTheTop, fixedItemText) => {
  if (!showFixedItemAtTheTop) {
    return null;
  }
  return (
    <div>
      <b>{`${fixedItemText}`}</b>
    </div>
  );
};

/** 
 * @deprecated 
 * It renders an AutocompleteDropdown component, which is a wrapper around a Material-UI Autocomplete
component 
 */
class AutocompleteInputCell extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSelectOrEnter = this.handleSelectOrEnter.bind(this);
    this.debouncedUpdateSuggestions = debounce(this.updateSuggestions, 333);
  }

  updateSuggestions(searchTerm) {
    const { dispatch, inputId, suggestionType, suggestionHandler } = this.props;
    dispatch(
      updateSuggestions(inputId, searchTerm, suggestionType, suggestionHandler)
    );
  }

  handleOnChange(row) {
    const { dispatch, inputId, filterFieldName } = this.props;
    const searchTerm = row.target ? row.target.value : row[filterFieldName];
    const searchId = row.id || 0;

    dispatch(updateInputInfo(inputId, searchTerm, searchId));
    this.debouncedUpdateSuggestions(searchTerm);
  }

  handleSelectOrEnter(row) {
    this.handleOnChange(row);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleSelection(row);
  }

  render() {
    const {
      items,
      currentSearchTerm,
      width,
      placeholder,
      name,
      filterFieldName,
      showFixedItemAtTheTop,
      fixedItemText,
      startAdornment,
      endAdornment,
      disabled,
      showAllItems,
      dataTest,
      multiline,
      helperText,
      dropdownType,
      dropdownBoxWidth,
      handleFixedItemClick,
      initialSelectedIndex,
      inlineActions,
      inputClasses,
    } = this.props;
    return (
      <AutocompleteDropdown
        dropdownType={dropdownType}
        autocompleteDropdownWrapper={styles.autocompleteDropdownWrapper}
        customStyles={styles}
        dataTest={dataTest}
        disabled={disabled}
        dropdownBoxWidth={dropdownBoxWidth}
        dropdownItemClassName={styles.dropdownItem}
        endAdornment={endAdornment}
        filterFieldName={filterFieldName}
        fixedItem={() => createFixedItem(showFixedItemAtTheTop, fixedItemText)}
        handleFixedItemClick={handleFixedItemClick}
        helperText={helperText}
        items={items}
        maxNumberOfVisibleResults={DROPDOWN_LIMIT}
        multiline={multiline}
        name={name}
        numOfCharsNeededToDisplayResults={1}
        onChange={this.handleOnChange}
        onEnter={this.handleSelectOrEnter}
        onSelect={this.handleSelectOrEnter}
        placeholder={placeholder}
        showAllItems={showAllItems}
        showFixedItem={showFixedItemAtTheTop}
        showFixedItemAtTheTop={showFixedItemAtTheTop}
        startAdornment={startAdornment}
        value={currentSearchTerm}
        width={width}
        initialSelectedIndex={initialSelectedIndex}
        inlineActions={inlineActions}
        inputClasses={inputClasses}
      />
    );
  }
}

AutocompleteInputCell.propTypes = {
  currentSearchTerm: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  dropdownBoxWidth: PropTypes.number,
  dropdownType: PropTypes.string,
  endAdornment: PropTypes.node,
  filterFieldName: PropTypes.string,
  fixedItemText: PropTypes.string,
  handleFixedItemClick: PropTypes.func,
  handleSelection: PropTypes.func,
  helperText: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  initialSelectedIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showAllItems: PropTypes.bool,
  showFixedItemAtTheTop: PropTypes.bool,
  startAdornment: PropTypes.node,
  suggestionHandler: PropTypes.string.isRequired,
  suggestionType: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inlineActions: AutocompletePropTypes.inlineActions,
  inputClasses: PropTypes.object,
};

AutocompleteInputCell.defaultProps = {
  currentSearchTerm: '',
  dataTest: '',
  disabled: false,
  dropdownBoxWidth: 300,
  dropdownType: '',
  endAdornment: null,
  filterFieldName: 'name',
  fixedItemText: '',
  handleFixedItemClick: () => {},
  handleSelection: () => {},
  helperText: '',
  initialSelectedIndex: 0,
  items: [],
  multiline: true,
  name: '',
  placeholder: '',
  showAllItems: false,
  showFixedItemAtTheTop: false,
  startAdornment: null,
  width: 200,
  inputClasses: {},
};

/* eslint-disable max-len */
function mapStateToProps({ table }, props) {
  const stateValues = table.autocompleteInput;
  return {
    items: stateValues.suggestions[props.inputId],
    currentSearchTerm: stateValues.inputValues[props.inputId]
      ? stateValues.inputValues[props.inputId].term
      : props.currentSearchTerm,
  };
}

/** @deprecated */
export default connect(mapStateToProps)(AutocompleteInputCell);
