import React, { useState } from 'react';
import { Button, Descriptions, Drawer, Tag, Typography } from 'antd';
import { GeneralCbiEntityInfo } from '@cbinsights/cbientityservice/cbientityservice';

const { Title } = Typography;

type EntityLinkingProps = {
  entity: GeneralCbiEntityInfo;
};
const EntityLinking: React.FC<EntityLinkingProps> = ({ entity }) => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleOpenDrawer = () => setDrawerVisible(true);
  const handleCloseDrawer = () => setDrawerVisible(false);

  return (
    <>
      {entity?.idCbiEntity && (
        <Button type="link" onClick={handleOpenDrawer}>
          {entity.entityNameInfo.primaryName || entity.idCbiEntity}
        </Button>
      )}
      {entity && (
        <Drawer
          title={`Entity Details - ${
            entity?.entityNameInfo?.primaryName || 'Unknown'
          }`}
          placement="right"
          onClose={handleCloseDrawer}
          visible={isDrawerVisible}
          width={600}
        >
          <Title level={4}>General Information</Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Description">
              {entity.description}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{entity.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              {entity.status?.status || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Founded Year">
              {entity.foundedYear}
            </Descriptions.Item>
            <Descriptions.Item label="Total Funding">
              {entity.totalFunding.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="VC Backed">
              <Tag color={entity.vcBacked ? 'green' : 'red'}>
                {entity.vcBacked ? 'Yes' : 'No'}
              </Tag>
            </Descriptions.Item>
          </Descriptions>

          <Title level={4} style={{ marginTop: '16px' }}>
            Related Entities
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Parent Entities">
              {entity.parentEntities.map((parent) => (
                <Tag key={parent.idCbiEntity}>
                  {parent.entityNameInfo.primaryName}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Child Entities">
              {entity.childEntities.map((child) => (
                <Tag key={child.idCbiEntity}>
                  {child.entityNameInfo.primaryName}
                </Tag>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Drawer>
      )}
    </>
  );
};

export default EntityLinking;
