import { keyBy } from 'lodash';
import { mapAdminUsersResponse } from '../utils/records-helper';
import { FETCH_ALL_ADMIN_USERS } from '../actions/admin-users';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_ADMIN_USERS: {
      const adminInfo = mapAdminUsersResponse(
        action.adminInfo,
        action.fetchInactiveUsers
      );
      return keyBy(adminInfo, 'id');
    }
    default:
      return state;
  }
}
