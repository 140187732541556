import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'client/component-library/IconButton';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dropzone from 'react-dropzone';
import CloudIcon from '@cbinsights/fds/lib/icons/react/CloudIcon';
import Input from 'client/component-library/Input/afsInput';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import GoogleSearchImage from 'client/modules/common/components/Image/GoogleSearchImage';
import styles from './image-editor.css';

const stopPropagation = (event) => event.stopPropagation();

const srcToFile = (src, fileName) =>
  fetch(src)
    .then((res) => res.arrayBuffer())
    .then((buffer) => new File([buffer], fileName, { type: 'image/png' }));

/**
 * @deprecated
 * It renders a dialog with a dropzone, a text input, and a few buttons
 */
const UploadScreen = ({
  url = '',
  onUrlChange,
  onNext,
  onSelectImage,
  image = null,
  onPaste,
  onSave,
  onCancel,
  isGoogleSearchEnabled = false,
  canPasteURL = true,
  companyLogo,
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const placeholder = isFirefox ? 'Paste URL' : 'Paste image or URL';

  const handleSave = () => {
    if (!image) {
      return null;
    }
    if (image instanceof File) {
      return onSave(image, image);
    }
    return srcToFile(image.src, 'upload.png').then((file) => {
      // The image can come from the cropping lib (src) or the drag and drop lib (preview)
      file.preview = image.src || image.preview; // eslint-disable-line no-param-reassign
      return onSave(file, image);
    });
  };

  return (
    <div>
      <DialogContent className="mm-modal-content">
        {isGoogleSearchEnabled && (
          <GoogleSearchImage companyLogo={companyLogo} />
        )}
        <Dropzone
          className="file-drop-target"
          activeClassName="file-drop-target-active"
          rejectClassName="file-drop-target-reject"
          onDrop={onSelectImage}
          accept="image/png, image/jpeg, image/svg+xml"
          multiple={false}
        >
          {image ? (
            <div className="upload-image-wrap">
              <img
                src={image.preview}
                className="image-upload-preview"
                alt="user-upload"
              />
            </div>
          ) : (
            <div className="file-drop-message" onDrop={onPaste}>
              <IconButton className="cloud-upload-icon">
                <CloudIcon size="xl" />
              </IconButton>
              <DialogContentText>
                Drag {canPasteURL && ', add URL'} or <b>browse</b> to upload
              </DialogContentText>
              <small>PNG, JPEG, and SVG are supported</small>
              <small>Make sure your files are not more than 3MB</small>
              {!isSafari && canPasteURL && (
                <p className="">{placeholder} to retrieve image:</p>
              )}
              {!isSafari && canPasteURL && (
                <Input
                  value={url}
                  onChange={(event) => onUrlChange(event.target.value)}
                  type="text"
                  onClick={stopPropagation}
                  onPaste={onPaste}
                />
              )}
              <small>Please do not upload offensive or illegal content.</small>
            </div>
          )}
        </Dropzone>
      </DialogContent>
      <div style={{ flex: '1 1 auto' }} />
      <Divider />

      <DialogActions className={styles['image-editor-actions']}>
        {!isSafari && (
          <Fragment>
            <Button
              theme="ghost"
              onClick={onCancel}
              data-test="mm-modal-cancel"
              className="material-btn-primary modal-bottom-button"
            >
              Cancel
            </Button>
            <Button
              theme="ghost"
              disabled={!image}
              onClick={onNext}
              className={`material-btn-primary modal-bottom-button
              ${!image ? 'disabled-button' : ''}`}
              data-test="mm-modal-next"
            >
              Crop
            </Button>
          </Fragment>
        )}
        <Button
          disabled={!image}
          onClick={handleSave}
          className="material-btn-primary modal-bottom-button blue-background"
          data-test="mm-modal-next"
        >
          Apply
        </Button>
      </DialogActions>
    </div>
  );
};

UploadScreen.propTypes = {
  image: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  url: PropTypes.string,
  onUrlChange: PropTypes.func.isRequired,
  isGoogleSearchEnabled: PropTypes.bool,
  canPasteURL: PropTypes.bool,
  companyLogo: PropTypes.shape({
    companyName: PropTypes.string,
    primaryUrl: PropTypes.string,
  }),
};

export default UploadScreen;
