/* eslint-disable import/no-import-module-exports */
import { mapCustomTableComponents } from 'client/modules/common/components/Table/TableComponents/mapCustomTableComponents';
import reducer from 'client/root-reducer';
import { AppRoot } from '../components/Root';
import { configureStore } from '../components/store/configure-store';
import { ldConfigProvider } from '../components/LDConfigProvider';
import render from '../components/Render';

import 'antd/dist/antd.min.css';
import './global-antd.css';
import 'tailwindcss/tailwind.css';

const initialState = window.__INITIAL_STATE__;
/** @deprecated */
const store = configureStore(reducer, initialState);

const bootstrap = async () => {
  try {
    const LDProvider = await ldConfigProvider();
    render(
      <LDProvider>
        <AppRoot store={store} />
      </LDProvider>,
      'app'
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to initialize app', error);
  }
};
/** Reload application when Hot Module replacement is active (dev only)
 * @link:https://webpack.js.org/api/hot-module-replacement/ *
 */
if (module.hot) {
  const replaceStore = async () => {
    const [LDProvider, { AppRoot: AppRootNext }] = await Promise.all([
      ldConfigProvider(),
      import('../components/Root'), // lazy loading module https://webpack.js.org/guides/lazy-loading/
    ]);

    return render(
      <LDProvider>
        <AppRootNext store={store} />
      </LDProvider>,
      'app'
    );
  };
  module.hot.accept('../components/Root', () => {
    replaceStore();
  });

  const replaceReducer = async () => {
    const nextReducer = await import('client/root-reducer');
    store.replaceReducer(nextReducer);
  };

  module.hot.accept('client/root-reducer', () => replaceReducer());
}

window.addEventListener('DOMContentLoaded', () => {
  bootstrap();
});

window._store = store;
window._circularDependency = window._circularDependency || {};

// doing this due to some of our old compontents have some very bad circular-dependency-plugin
window._circularDependency.mapCustomTableComponents = mapCustomTableComponents;
export default store;
