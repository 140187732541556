import { useLocation } from 'react-router-dom';

type Props = {
  tabItems: Array<string>;
};

const useSelectedTab = ({ tabItems }: Props) => {
  const location = useLocation();

  const onChangeTab = (newTabKey: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tab', newTabKey);
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${queryParams.toString()}`
    );
  };

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get('tab') || tabItems[0];

  return {
    defaultActiveKey,
    onChangeTab,
  };
};

export default useSelectedTab;
