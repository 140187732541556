/* eslint-disable react/no-deprecated */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Paper from 'client/component-library/Paper';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import Button from 'client/component-library/buttons/Button';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import { setFailureNotification } from 'client/modules/common/redux/actions/status-notification';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';
import getTableStructure from 'client/modules/stories/utils/table-structures/get-table-structures';
import { validateInnerTableRecords } from 'client/modules/stories/redux/utils/records-helper';
import {
  cancelEditingPermissions,
  toggleInnerTable,
  updateTeamMemberPermission,
  updateTeamContentOwner,
  updateSortOrder,
  updatePermissionsInTeam,
} from '../../redux/actions/tab-data';
import styles from '../styles/material-ui/tableStyle';

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

const additionalPropNames = ['originalPermission'];

class StoriesInnerTable extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      open: false,
      tableStructure: getTableStructure('storiesInnerTable'),
    });
  }

  handleChange = (rowId) => (event) => {
    if (event.target.type === 'radio' && event.target.name === 'permission') {
      this.props.updateTeamMemberPermission(
        this.props.id,
        rowId,
        event.target.name,
        event.target.value
      );
    } else if (
      event.target.type === 'radio' &&
      event.target.name === 'contentOwner'
    ) {
      this.props.updateTeamContentOwner(this.props.id, rowId);
    }
  };

  handleSortClick = (sortField, sortDirection) => () => {
    this.props.updateSortOrder(this.props.id, sortField, sortDirection);
  };

  handleOpenDialog = () => {
    const validationMessage = validateInnerTableRecords(
      this.props.innerTableRecords
    );
    if (validationMessage) {
      return this.props.setFailureNotification(validationMessage);
    }
    return this.setState({ open: true });
  };

  handleSaveClick = () => {
    this.setState({ open: false });
    return this.props.updatePermissionsInTeam(this.props.id);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCancelClick = () => {
    this.props.toggleInnerTable(this.props.id);
    this.props.cancelEditingPermissions(this.props.id);
  };

  render() {
    const {
      classes,
      innerTableRecords,
      innerTableRecordsOrder,
      sortField,
      sortDirection,
      id,
    } = this.props;
    if (!innerTableRecordsOrder.length) {
      return null;
    }
    return (
      <Paper className={classes.root}>
        <div className={classes.storiesInnerTable}>
          <Table className={classes.table}>
            <TableHeader
              columnNames={this.state.tableStructure}
              sortField={sortField}
              sortDirection={sortDirection}
              onSortClick={this.handleSortClick}
              outerTableRowId={id}
            />
            <TableBody
              additionalPropNames={additionalPropNames}
              columnNames={this.state.tableStructure}
              records={innerTableRecords}
              recordsOrder={innerTableRecordsOrder}
              handleChange={this.handleChange}
              tBodyStyles={{ maxHeight: '450px', overflow: 'auto' }}
            />
          </Table>
          <div
            style={{
              height: '48px',
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <span>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={this.handleCancelClick}
                  color="secondary"
                  dataTest="cancel"
                >
                  CANCEL
                </Button>
              </MuiThemeProvider>
            </span>
            <span>
              <Button
                onClick={this.handleOpenDialog}
                color="primary"
                dataTest="save"
              >
                SAVE
              </Button>
              <Dialog open={this.state.open} onClose={this.handleClose}>
                <DialogTitle>
                  Are you sure you would like to provision access to this story?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This change cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MuiThemeProvider theme={theme}>
                    <Button
                      onClick={this.handleClose}
                      color="secondary"
                      dataTest="dialog-cancel"
                    >
                      CANCEL
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    onClick={this.handleSaveClick}
                    color="primary"
                    dataTest="dialog-save"
                  >
                    SAVE
                  </Button>
                </DialogActions>
              </Dialog>
            </span>
          </div>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps({ stories }, ownProps) {
  const recordId = parseInt(ownProps.id, 10);
  const innerTableRecords = {};
  const innerTableRecordsOrder = [];
  if (!stories.tabData[stories.currentTab].innerTable[recordId]) {
    return {
      currentTab: stories.currentTab,
      innerTableRecords,
      innerTableRecordsOrder,
      sortDirection:
        stories.tabData[stories.currentTab].innerTableSortInfo.sortDirection,
      sortField:
        stories.tabData[stories.currentTab].innerTableSortInfo.sortField,
    };
  }
  return {
    currentTab: stories.currentTab,
    innerTableRecords:
      stories.tabData[stories.currentTab].innerTable[recordId]
        .innerTableRecords,
    innerTableRecordsOrder:
      stories.tabData[stories.currentTab].innerTable[recordId]
        .innerTableRecordsOrder,
    sortDirection:
      stories.tabData[stories.currentTab].innerTableSortInfo.sortDirection,
    sortField: stories.tabData[stories.currentTab].innerTableSortInfo.sortField,
  };
}

StoriesInnerTable.propTypes = {
  cancelEditingPermissions: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  innerTableRecords: PropTypes.object.isRequired,
  innerTableRecordsOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
  setFailureNotification: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string,
  toggleInnerTable: PropTypes.func.isRequired,
  updateTeamContentOwner: PropTypes.func.isRequired,
  updateTeamMemberPermission: PropTypes.func.isRequired,
  updateSortOrder: PropTypes.func,
  updatePermissionsInTeam: PropTypes.func.isRequired,
};

StoriesInnerTable.defaultProps = {
  sortField: '',
  sortDirection: '',
  updateSortOrder: () => {},
};

export default compose(
  withStyles(styles, { name: 'StoriesInnerTable' }),
  connect(mapStateToProps, {
    cancelEditingPermissions,
    setFailureNotification,
    toggleInnerTable,
    updateTeamContentOwner,
    updateTeamMemberPermission,
    updateSortOrder,
    updatePermissionsInTeam,
  })
)(StoriesInnerTable);
