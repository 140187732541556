import { useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import moment from 'moment';
import { LoginEventType } from '@cbinsights/userv2service/userv2service';
import { CbiEntityRoutes } from 'client/modules/cbi-entity/RoutesHelpers';
import { useGetUserTrialQuery } from 'client/modules/trial-package/services/hooks/useGetUserTrialQuery';
import { useGetPackagesQuery } from 'client/modules/trial-package/services/hooks/useGetPackagesQuery';
import { useGetUserLoginHistory } from '../services/hooks/useGetUserLoginHistory';
import { useGetUserQuery } from '../services/hooks/useGetUserQuery';
import { useGetUserPackageHistory } from '../services/hooks/useGetUserPackageHistory';
import { TrialInfo } from '../types/trial';

export const loginHistory = {
  columns: [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => (
        <span>
          <Tooltip
            title={`UTC ${moment(date)
              .format('HH:mm:ss MM/DD/YYYY')
              .toString()}`}
          >
            {moment.utc(date).local().format('HH:mm:ss MM/DD/YYYY')}
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Success/Failure',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        if (status === LoginEventType.LOGIN_SUCCESS) {
          return <span className="text-green-500">Successful Login</span>;
        }
        if (status === LoginEventType.LOGOUT) {
          return <span className="text-yellow-500">Logout</span>;
        }
        if (status === LoginEventType.UNKNOWN_LOGIN_EVENT) {
          return <span>Unknow login Event</span>;
        }
        if (status === LoginEventType.LOGIN_FAILURE) {
          return <span className="text-red-500">Login Failure</span>;
        }
        return <span>{status}</span>;
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ],
};
export const packageHistory = {
  columns: [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => (
        <span>
          <Tooltip
            title={`UTC ${moment(date)
              .format('HH:mm:ss MM/DD/YYYY')
              .toString()}`}
          >
            {moment.utc(date).local().format('HH:mm:ss MM/DD/YYYY')}
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Action By',
      dataIndex: 'actionBy',
      key: 'actionBy',
      render: (text, { actionById }) => (
        <NavLink
          to={`/users/${actionById}`}
          target="_blank"
          rel="noopener noreferrer"
          className="type--link"
        >
          {text}
        </NavLink>
      ),
    },
  ],
};

export const useGetUserInformation = () => {
  const { id } = useParams<typeof CbiEntityRoutes.Id>();

  const userId = useMemo(() => {
    const value = Number.parseInt(id, 10);

    if (Number.isNaN(value)) {
      return null;
    }

    return value;
  }, [id]);

  const {
    data,
    isLoading,
    refetch: refetchUserQuery,
  } = useGetUserQuery({
    enabled: Boolean(userId),
    params: { id_user: userId },
  });

  const { data: userTrialsInfo } = useGetUserTrialQuery({
    enabled: !!userId,
    params: {
      id_user: userId,
    },
  });

  const { data: packagesData } = useGetPackagesQuery({
    enabled: true,
    params: {},
  });

  const { data: loginHistoryData } = useGetUserLoginHistory({
    enabled: true,
    params: {
      id_user: userId,
    },
  });

  const { data: packageHistoryData, refetch: refetchPackageHistory } =
    useGetUserPackageHistory({
      enabled: true,
      params: {
        id_user: userId,
      },
    });

  const refetchUserInformation = () => {
    refetchUserQuery();
    refetchPackageHistory();
  };

  const packageHistorySource = useMemo(() => {
    if (!packageHistoryData?.user_package_histories) {
      return [];
    }

    return packageHistoryData.user_package_histories.map((entry) => ({
      date: moment(entry.ts_log).format('HH:mm:ss MM/DD/YYYY'),
      package: entry.package_name,
      startDate: moment(entry.package_start).format('MM/DD/YYYY'),
      endDate: moment(entry.package_end).format('MM/DD/YYYY'),
      actionBy: entry.first_name
        ? `${entry.first_name} ${entry.last_name}`
        : entry.initiator_email,
      actionById: entry.initiator_id,
    }));
  }, [packageHistoryData]);

  const userTrialInfo: TrialInfo = useMemo(() => {
    const activeTrial = userTrialsInfo?.user_trials.find((trial) => {
      return (
        moment(trial.ts_trial_package_end).isAfter(moment().startOf('day')) &&
        trial.id_user === userId
      );
    });

    if (
      !userTrialsInfo ||
      userTrialsInfo?.user_trials.length === 0 ||
      !packagesData ||
      !activeTrial
    ) {
      return {
        packageName: 'N/A',
        originalPackageName: 'N/A',
        startDate: 'N/A',
        endDate: 'N/A',
        isActive: false,
      };
    }

    const packageName = packagesData.packages.find(
      (packageDetail) =>
        packageDetail.id_package === activeTrial.id_package_trial
    );

    const originalPackageName = packagesData.packages.find(
      (packageDetail) =>
        packageDetail.id_package === activeTrial.id_package_trial_original
    );

    const startDate = activeTrial.ts_trial_package_start
      ? moment(activeTrial.ts_trial_package_start).format('MM/DD/YYYY')
      : 'N/A';
    const endDate = activeTrial.ts_trial_package_start
      ? moment(activeTrial.ts_trial_package_end).format('MM/DD/YYYY')
      : 'N/A';

    return {
      packageName: packageName?.name ?? 'N/A',
      originalPackageName: originalPackageName?.name ?? 'N/A',
      startDate,
      endDate,
      isActive: true,
    };
  }, [userTrialsInfo, packagesData]);

  const loginHistoryTableData = useMemo(() => {
    if (!loginHistoryData || loginHistoryData?.login_attempts.length === 0) {
      return [];
    }

    return loginHistoryData.login_attempts.map((event) => ({
      date: moment(event.ts_event).format('HH:mm:ss MM/DD/YYYY'),
      status: event.event_type,
      message: event.msg,
    }));
  }, [loginHistoryData]);

  return {
    isLoading,
    id: userId,
    user: data?.user,
    userTrialInfo,
    loginHistory,
    loginHistoryTableData,
    packageHistory,
    refetchUserQuery: refetchUserInformation,
    packageHistorySource,
  };
};
