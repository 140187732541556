/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './afsButton-styles.css';

const CLASSES_MAP = {
  themeBase: 'font-bold py-1 px-4 rounded w-full text-sm',
  themeBaseInline: 'py-1 px-2 tracking-widest rounded text-xs',
  primary:
    'text-white border-b-2 bg-blue-700 hover:bg-blue-600 border-blue-700 hover:border-blue-700 ',
  danger:
    'text-white border-b-2 bg-red-700 hover:bg-red-600 border-red-700 hover:border-red-700',
  gray: 'text-black border-b-2 bg-gray-400 hover:bg-gray-300 border-gray-400 hover:border-gray-400',
  success:
    'text-white border-b-2 bg-green-500 hover:bg-green-400 border-green-500 hover:border-green-400',
  light:
    'text-black bg-white hover:bg-gray-400 border border-solid border-gray-300 hover:border-gray-400',
  ghost: 'text-black',
  ghostDanger: 'text-red-600 hover:text-red-500',
  link: 'text-blue-700',
  disabled: 'focus:outline-none opacity-50 cursor-not-allowed',
};

/**
 * @deprecated
 * It takes in a bunch of props, and returns a button with the appropriate classes
 * @param props - This is the props object that is passed to the component.
 * @returns A button element with the className of the classes variable.
 */
const Button = (props) => {
  const {
    children,
    disabled,
    theme,
    isDestructive,
    inline,
    fullHeight,
    ...buttonProps
  } = props;

  const classes = cx({
    [CLASSES_MAP.themeBase]: !isDestructive && !inline,
    [CLASSES_MAP.themeBaseInline]: !isDestructive && inline,
    [CLASSES_MAP[theme]]: !isDestructive,
    [CLASSES_MAP.disabled]: disabled,
    [styles.inline]: inline,
    'h-full': fullHeight,
  });

  return (
    <button {...buttonProps} className={classes} disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  theme: PropTypes.oneOf([
    'primary',
    'danger',
    'ghost',
    'success',
    'link',
    'light',
    'gray',
    'ghostDanger',
  ]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  'data-test': PropTypes.string,
  isDestructive: PropTypes.bool,
  inline: PropTypes.bool,
  fullHeight: PropTypes.bool,
  style: PropTypes.object,
};

Button.defaultProps = {
  theme: 'primary',
  type: 'button',
  'data-test': 'fds-button',
  disabled: false,
  isDestructive: false,
  inline: false,
  fullHeight: false,
};

export default Button;
