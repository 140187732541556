import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Hook to type feature flags values
 * @returns A function that returns the value of the flag.
 */
export const useTypedFlags = () => {
  const flags = useFlags();

  /**
   * functions that get flag value and type it as required
   * @param {string} flagName - The name of the flag.
   * @returns The value of the flag, default type it's boolean.
   */
  const getFlag = <TFlagValue = boolean>(flagName: string): TFlagValue => {
    const value = flags[flagName] ?? false;
    return value as TFlagValue;
  };

  return { getFlag };
};
