export const createFullName = (fName, lName, email) => {
  if (fName && lName) {
    return `${fName} ${lName}`;
  } if (fName) {
    return fName;
  } if (lName) {
    return lName;
  } if (email) {
    return email.split('@')[0];
  }
  return '';
};

export default createFullName;
