export const CbiEntityRoutes = {
  PageIndex: 'cbi-entity',
  Home: 'home',
  Id: 'id',
  GeneralInfo: 'general-info',
  Fundings: 'fundings',
  SalesMetrics: 'sales-metrics',
  Financials: 'financials',
  VCFunds: 'vc-funds',
  Competitors: 'competitors',
  Employees: 'employees',
  New: 'new',
} as const;
