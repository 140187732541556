export { default as FormControl } from './FormControl';
export { default as FormControlLabel } from './FormControlLabel';

/**
 * @deprecated
 * get the form that the current focused element is in.
 * Ideally the react comonent should have a ref to the form and we should not need this.
 */
function getClosestForm() {
  let currentElement = document.activeElement || document.getElementById('app');

  while (currentElement.tagName !== 'BODY') {
    const { dataset, tagName } = currentElement;
    if (dataset.tagType === 'form' || tagName === 'FORM') {
      return currentElement;
    }
    currentElement = currentElement.parentElement;
  }

  return null;
}

/**
 * @deprecated
 * Hacky function to help override browsers tabindexing.
 * Pass formRef when possible, it will make this more accurate.
 * This function will return the next focusable element within a form-like
 * section. If formRef is not passed the function will try to derive it, but this is not to
 * performant.
 */
export function getNextFocusableElementInForm(formRef) {
  let formElements = [];
  let activeElementIndex;

  try {
    const _formRef = formRef || getClosestForm();

    if (!_formRef) {
      return null; // unable to get form
    }

    const { activeElement } = document;
    formElements = Array.from(
      _formRef.querySelectorAll('input, select, textarea, div[tabindex]')
    ).filter((element) => element.type !== 'hidden');

    formElements.forEach((element, index) => {
      if (element === activeElement) {
        activeElementIndex = index;
      }
    });
  } catch (error) {
    console.log(error);
  }

  return formElements[activeElementIndex + 1];
}

/**
 * @deprecated
 * Pass in the formRef if possible as this will speed things up.
 * This function will auto focus the next element in the DOM if you currently have an element selected.
 */
export function autoFocusNextElement(formRef) {
  const nextElement = getNextFocusableElementInForm(formRef);
  if (nextElement && nextElement.focus) {
    nextElement.focus();
  }
}
