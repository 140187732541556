import {
  GetCompanyLogosRequest,
  GetCompanyLogosResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const getCompanyLogos = (
  req: Partial<GetCompanyLogosRequest>
): Promise<GetCompanyLogosResponse> => {
  return request({
    url: '/service/logoservice/GetCompanyLogos',
    body: { ...req },
  }).then((r): GetCompanyLogosResponse => r.body);
};
