import { UseQueryResult, useQuery } from 'react-query';
import { GetPortalsResponse } from '@cbinsights/userv2service/userv2service';
import { GetPortals } from '.';
import { getPortals } from '../api/GetPortals';

type Return = GetPortalsResponse;

export const CBI_PORTAL_NAME = 'CB Insights';

export const useGetPortals = ({
  enabled = false,
}: {
  enabled: boolean;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetPortals]>(
    ['GetPortals'],
    () => getPortals(),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
