import React, { ReactElement, useCallback } from 'react';
import { debounce } from 'lodash';
import { AntdSelectField, AntdSelectFieldProps } from './AntdSelectField';

export type AntdSearchableFieldProps = {
  debounceSearch?: boolean;
  debounceWait?: number;
} & Omit<AntdSelectFieldProps, 'showSearch'>;

export const labelFilter = (filter, option) =>
  option.label.toString().toLowerCase().includes(filter);

export const AntdSearchableField = ({
  onSearch = () => null,
  debounceSearch = true,
  debounceWait = 300,
  labelInValue = true,
  optionFilterProp = 'label',
  ...props
}: AntdSearchableFieldProps): ReactElement => {
  const handleSearchWithDebounce = useCallback(
    debounce((searchTerm: string) => {
      onSearch(searchTerm);
    }, debounceWait),
    []
  );

  const handleSearch = (searchTerm: string) => {
    onSearch(searchTerm);
  };

  return (
    <AntdSelectField
      showSearch
      {...props}
      optionFilterProp={optionFilterProp}
      labelInValue={labelInValue}
      onSearch={debounceSearch ? handleSearchWithDebounce : handleSearch}
    />
  );
};
