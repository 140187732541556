import React from 'react';
import PropTypes from 'prop-types';
import { MaterialUILink } from 'client/component-library/Link';
import Button from 'client/component-library/buttons/Button';
import Grid from 'client/component-library/Grid';
import styles from './styles/dropdownItem.css';

const conditionallApplySelectedStyle = (isSelected) => {
  return isSelected ? styles.selected : '';
};

const createDropdownStyle = (height) => {
  return {
    height: height ? `${height}px` : 'inherit',
    margin: 0,
    width: '100%',
  };
};

const createEllipsisStyle = (isSelected) => {
  return isSelected ? styles.selectedEllipsis : styles.notSelectedEllipsis;
};

/**
 * @deprecated
 * It renders a dropdown item for a company
 * @returns A React component
 */
const CompanyDropdownItem = ({
  item,
  text,
  selected,
  onMouseOver,
  onMouseDown,
  height,
  className,
  dropdownItemId,
}) => {
  const dataTestName = item.id ? item.id : text;
  const companyUrl = item.url || item.profileUrl;
  const { alias } = item;
  return (
    <Grid
      container
      spacing={1}
      className={`${className} ${
        styles.dropdownItem
      } ${conditionallApplySelectedStyle(selected)}`}
      onMouseOver={onMouseOver}
      onMouseDown={onMouseDown}
      data-test={`admin-${dataTestName}`}
      style={createDropdownStyle(height)}
    >
      <Grid
        item
        xs={10}
        style={selected ? { padding: 0, maxWidth: '80%' } : { padding: 0 }}
      >
        <span id={dropdownItemId}>{item.name}</span>
        <span style={{ marginLeft: '5px' }}>
          <MaterialUILink
            href={`//${companyUrl}`}
            className={selected ? styles.selected : styles.notSelected}
            onMouseDown={onMouseDown}
          >
            {companyUrl}
          </MaterialUILink>
        </span>
        <div>
          {alias ? (
            <span>
              <b>alias:</b> {alias}
            </span>
          ) : null}
        </div>
        <span className={createEllipsisStyle(selected)}>
          {item.description}
        </span>
      </Grid>
      {selected && (
        <Grid
          item
          xs={2}
          style={
            selected ? { height: '34px', maxWidth: '20%' } : { height: '34px' }
          }
        >
          <Button variant="outlined" className={styles.btnStyle} dataTest="add">
            Add
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

CompanyDropdownItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  height: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  dropdownItemId: PropTypes.string,
};

CompanyDropdownItem.defaultProps = {
  item: {},
  selected: false,
  onMouseOver: () => {},
  onMouseDown: () => {},
  height: 0,
  className: '',
  text: '',
  dropdownItemId: '',
};

export default CompanyDropdownItem;
