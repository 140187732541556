import {
  DraftStatus,
  GeneralCbiEntityInfo,
  IdType,
} from '@cbinsights/cbientityservice/cbientityservice';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { FormikFormProps } from 'client/modules/common/form/containers/FormikContainer';
import Loading from 'client/component-library/LoadingIcon';
import { Modal } from 'antd';
import { SectionLayout, TabBarAdditionalContentProps } from './SectionLayout';
import { useFormikIsDirty } from './global-state/useFormIsDirty';

export type EntityMeta = {
  isCompany: GeneralCbiEntityInfo['isCompany'];
  isInvestor: GeneralCbiEntityInfo['isInvestor'];
  idCompany: GeneralCbiEntityInfo['idCompany'];
  idInvestor: GeneralCbiEntityInfo['idInvestor'];
  idType: IdType;
  isNew?: boolean;
  createCompany?: boolean;
  createInvestor?: boolean;
  draftVersion: number;
  draftStatus: DraftStatus;
};

export type ReadonlySectionFieldName = Readonly<{
  IdCbiEntity: 'idCbiEntity';
  IdEntityDraft: 'idEntityDraft';
  EntityName: 'entityName';
  Url: 'url';
  Meta: 'meta';
}>;

export const SectionFieldName: ReadonlySectionFieldName = {
  IdCbiEntity: 'idCbiEntity',
  IdEntityDraft: 'idEntityDraft',
  EntityName: 'entityName',
  Url: 'url',
  Meta: 'meta',
};

// All CBI entity section should implement this model
export type CBIEntityFormModel = {
  [SectionFieldName.IdCbiEntity]: number;
  [SectionFieldName.IdEntityDraft]: string;
  [SectionFieldName.EntityName]: string;
  [SectionFieldName.Url]: string;
  [SectionFieldName.Meta]: EntityMeta;
};

export type CBISectionForm<
  TModel extends CBIEntityFormModel,
  TOptionalProps extends Record<string, unknown>,
  TStatus extends Record<symbol, unknown>
> = FormikFormProps<TOptionalProps, TModel, TStatus>;

type CBISectionLayoutFormProps<
  TOptional extends Record<string, unknown>,
  TStatus extends Record<symbol, unknown> = Record<symbol, unknown>
> = FormikFormProps<
  {
    FormHeader: FunctionComponent<
      CBISectionForm<CBIEntityFormModel, TOptional, TStatus>
    >;
    FormContent: FunctionComponent<
      CBISectionForm<CBIEntityFormModel, TOptional, TStatus>
    >;
    CallToAction: FunctionComponent<
      CBISectionForm<
        CBIEntityFormModel,
        TOptional & {
          onLeavePage: (args: { redirect: () => void }) => void;
        },
        TStatus
      >
    >;
    optionalProps?: TOptional;
    TabBarAdditionalContent?: TabBarAdditionalContentProps[];
  },
  CBIEntityFormModel
>;

export const CBISectionFormLayout = ({
  CallToAction,
  FormHeader,
  FormContent,
  optionalProps,
  TabBarAdditionalContent = [],
  ...props
}: CBISectionLayoutFormProps<
  {
    formIsLoading: boolean;
    visibleTabs?: boolean;
  } & Record<string, unknown>
>): ReactElement => {
  const { setFormIsDirty } = useFormikIsDirty();
  const [isVisible, setIsVisible] = useState(false);
  const [redirection, setRedirect] = useState<{ redirect: () => void }>(
    undefined
  );

  useEffect(() => {
    setFormIsDirty({ isFormDirty: props.dirty });
  }, [props.dirty]);

  const onLeaveCurrentSection = (args: { redirect: () => void }) => {
    if (props.dirty) {
      setIsVisible(true);
      setRedirect(args);
      return;
    }
    args.redirect();
  };

  return (
    <>
      <SectionLayout
        onLeavePage={onLeaveCurrentSection}
        CallToActions={
          <CallToAction
            {...optionalProps}
            {...props}
            onLeavePage={onLeaveCurrentSection}
          />
        }
        FormHeader={<FormHeader {...optionalProps} {...props} />}
        FormContent={
          optionalProps.formIsLoading ? (
            <Loading className="!mt-5" />
          ) : (
            <FormContent {...optionalProps} {...props} />
          )
        }
        visibleTabs={optionalProps.visibleTabs ?? true}
        TabBarAdditionalContent={TabBarAdditionalContent}
      />
      <Modal
        visible={isVisible}
        title="Unsaved Changes"
        onOk={() => redirection.redirect()}
        onCancel={() => setIsVisible(false)}
      >
        <p>You have unsaved changes on this page. Do you wish to continue?</p>
      </Modal>
    </>
  );
};
