import FDS from '@cbinsights/fds/lib/dictionary/js/styleConstants';

export const styles = {
  menuItem: {
    minHeight: '1rem',
    fontSize: '0.9225rem',
    height: 'auto',
    color: FDS.COLOR_CHARCOAL,
    // border: `solid 1px ${FDS.COLOR_SLATE}`,
    minWidth: '150px',
    width: 'auto',
    cursor: 'pointer',
  },
  menuItemHover: {
    backgroundColor: FDS.COLOR_NAVY,
    color: FDS.COLOR_HAZE,
  },
  textField: {
    width: '150px',
    height: '15px',
    fontSize: '0.9225rem',
    color: FDS.COLOR_CHARCOAL,
  },
  menuContainer: {
    minWidth: '150px',
    width: 'auto',
    backgroundColor: FDS.COLOR_WHITE,
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: 10,
  },
  container: {
    minWidth: '150px',
    width: 'auto',
  },
};

export default styles;
