const styles = () => ({
  root: {
    margin: '20px auto',
    border: 0,
    boxShadow: 'none',
    width: '1200px',
  },
  storiesInnerTable: {
    width: '1000px',
    margin: '0 auto',
    border: '1px solid #eee',
  },
  table: {
    width: '1000px',
    margin: '0 auto',
  },
  buttons: {
    float: 'right',
  },
});

export default styles;
