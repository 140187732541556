/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Paper from 'client/component-library/Paper';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import { withStyles } from 'client/component-library/styles';
import { getTableStructure } from 'client/modules/markets/utils/table-structures/get-table-structures';
import styles from '../styles/material-ui/tableStyle';

const additionalPropNames = [
  'parentId',
  'highlightStartSize',
  'highlightStartYear',
  'highlightEndSize',
  'highlightEndYear',
];

class MarketsInnerTable extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      tableStructure: getTableStructure('marketsInnerTable'),
    });
  }

  render() {
    const {
      id,
      classes,
      innerTableRecords,
      innerTableRecordsOrder,
      editingDisabled,
    } = this.props;

    if (!innerTableRecordsOrder.length) {
      return null;
    }

    return (
      <Paper className={classes.root}>
        <div>
          <Table className={classes.table}>
            <TableHeader
              columnNames={this.state.tableStructure}
              editingDisabled={editingDisabled}
            />
            <TableBody
              id={id}
              additionalPropNames={additionalPropNames}
              records={innerTableRecords}
              recordsOrder={innerTableRecordsOrder}
              columnNames={this.state.tableStructure}
              tBodyStyles={{ borderTop: '1px solid #eee' }}
              parentId={id}
            />
          </Table>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const recordId = parseInt(ownProps.id, 10);
  if (markets.tabData.marketSizingDetails.innerTable[recordId]) {
    return {
      innerTableRecords:
        markets.tabData.marketSizingDetails.innerTable[recordId]
          .innerTableRecords,
      innerTableRecordsOrder:
        markets.tabData.marketSizingDetails.innerTable[recordId]
          .innerTableRecordsOrder,
    };
  }
  return {};
}

MarketsInnerTable.propTypes = {
  editingDisabled: PropTypes.bool,
  id: PropTypes.string,
  innerTableRecords: PropTypes.object,
  innerTableRecordsOrder: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object.isRequired,
};

MarketsInnerTable.defaultProps = {
  editingDisabled: false,
  id: '',
  innerTableRecords: {},
  innerTableRecordsOrder: [],
};

export default compose(
  withStyles(styles, { name: 'MarketsInnerTable' }),
  connect(mapStateToProps)
)(MarketsInnerTable);
