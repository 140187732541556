import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select } from 'antd';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EditMarketTab, MarketReportsRoutes } from '../RouterHelper';
import { MarketReport } from '../hooks/useMarketsList';
import { useGenerateMarketsCSV } from '../hooks/useGenerateMarketsCSV';
import { useListIndustries } from '../service/hooks/useListIndustries';

const applyFilterInMarketList = (
  value: ChangeEvent<HTMLInputElement>,
  marketsList: MarketReport[],
  filter: 'marketName' | 'marketId' | 'industry' | 'industryId',
  setFilteredMarkets: (filter: MarketReport[]) => void,
  showInReviewOnly: boolean
) => {
  const filteredValues = marketsList.filter((market) => {
    if (showInReviewOnly && market.isPendingReview !== true) {
      return false;
    }
    const targetValue = value?.target?.value?.toLowerCase();
    if (targetValue === '') {
      return true;
    }
    if (filter === 'marketName') {
      return market.marketName.toLowerCase().includes(targetValue);
    }
    if (filter === 'industry') {
      return market?.industries?.some((industry) =>
        industry?.industryName?.toLowerCase().includes(targetValue)
      );
    }
    if (filter === 'industryId') {
      const idsToCheck = value.target.value
        .split(',')
        .map((id) => id.trim())
        .map((id) => Number(id));

      return market.industries.some((industry) =>
        idsToCheck.includes(industry.industryId)
      );
    }
    if (filter === 'marketId') {
      return value.target.value
        .split(',')
        .map((id) => id.trim())
        .includes(String(market.marketId));
    }

    return true;
  });
  setFilteredMarkets(filteredValues);
};

export const MarketListHeader = ({
  markets,
  setFilteredMarkets,
  areMarketsFetched,
  areMarketsRefreshing,
  refreshMarkets,
  areMarketsCompetitorRefreshing,
  refreshMarketsCompetitors,
  selectedMarkets,
}: {
  markets: MarketReport[];
  setFilteredMarkets: (filter: MarketReport[]) => void;
  areMarketsFetched: boolean;
  areMarketsRefreshing: boolean;
  refreshMarkets: () => Promise<void>;
  areMarketsCompetitorRefreshing: boolean;
  refreshMarketsCompetitors: () => Promise<void>;
  selectedMarkets: Record<number, boolean>;
}) => {
  const { isFetching: isIndustryFetching } = useListIndustries({
    enabled: false,
  });

  const [filterType, setFilter] = useState<
    'marketName' | 'marketId' | 'industry' | 'industryId'
  >('marketName');

  const [reviewOnly, setReviewOnly] = useState(false);

  const { handleGenerateCSV } = useGenerateMarketsCSV();

  const handleSearchWithDebounce = useCallback(
    debounce((searchTerm: ChangeEvent<HTMLInputElement>) => {
      applyFilterInMarketList(
        searchTerm,
        markets,
        filterType,
        setFilteredMarkets,
        reviewOnly
      );
    }, 300),
    [markets, filterType, reviewOnly]
  );

  const handleFilter = (value) => setFilter(value || 'marketName');
  const handleCSV = () => handleGenerateCSV(markets);
  const handleInReviewFilter = (e: CheckboxChangeEvent) => {
    setReviewOnly(e.target.checked);
    applyFilterInMarketList(
      undefined,
      markets,
      null,
      setFilteredMarkets,
      e.target.checked
    );
  };

  return (
    <div className="flex ">
      <Link
        to={`${MarketReportsRoutes.New}/${EditMarketTab.MarketInfo}`}
        className="mr-1"
      >
        <Button type="primary">New Market</Button>
      </Link>
      <Button
        className="bg-cbi-green text-white hover:bg-cbi-green hover:text-white focus:bg-cbi-green focus:text-white mr-1"
        disabled={
          areMarketsRefreshing ||
          areMarketsCompetitorRefreshing ||
          !Object.values(selectedMarkets).some((el) => el === true)
        }
        onClick={refreshMarkets}
        icon={areMarketsRefreshing && <LoadingOutlined />}
      >
        Run ESP Scoring (
        {Object.values(selectedMarkets).filter((el) => el).length})
      </Button>
      <Button
        className="bg-cbi-green text-white hover:bg-cbi-green hover:text-white focus:bg-cbi-green focus:text-white mr-1"
        disabled={
          areMarketsRefreshing ||
          areMarketsCompetitorRefreshing ||
          !Object.values(selectedMarkets).some((el) => el === true)
        }
        onClick={refreshMarketsCompetitors}
        icon={areMarketsCompetitorRefreshing && <LoadingOutlined />}
      >
        Run Suggestion Algo (
        {Object.values(selectedMarkets).filter((el) => el).length})
      </Button>
      <Select
        placeholder="Filter by"
        className=" min-w-[100px]"
        onChange={handleFilter}
        options={[
          {
            label: 'Market',
            value: 'marketName',
          },
          {
            label: 'Id Market',
            value: 'marketId',
          },
          {
            label: 'Industry',
            value: 'industry',
          },
          {
            label: 'Id Industry',
            value: 'industryId',
          },
        ]}
        allowClear
      />
      <Input
        placeholder="Search..."
        className="flex-grow mr-1"
        onChange={handleSearchWithDebounce}
      />
      <Checkbox
        onChange={handleInReviewFilter}
        className="whitespace-nowrap leading-[2.3]"
      >
        Pending Changes Only
      </Checkbox>
      <Button
        type="primary"
        onClick={handleCSV}
        icon={<DownloadOutlined />}
        disabled={!areMarketsFetched || isIndustryFetching}
      >
        Market Table
      </Button>
    </div>
  );
};
