import { RequestOrigin } from '@cbinsights/userv2service/userv2service';
import { useMutation } from 'react-query';
import { setTeamUsersQuotas } from '../services/api/SetTeamUsersQuotasRequest';
import { setUserFeatureCredits } from '../services/api/SetUserFeatureCredits';

type Props = {
  idTeam: number;
  featureId: number;
};

const useUpdateUserCredit = ({ idTeam, featureId }: Props) => {
  const setUserQuotasQuery = useMutation(setTeamUsersQuotas);
  const setUserFeatureCreditsQuery = useMutation(setUserFeatureCredits);

  const onSetUserQuotas = (idUser: number, quota: number) => {
    return setUserQuotasQuery.mutateAsync({
      team_user_quotas: [
        {
          id_feature: featureId,
          id_team: idTeam,
          id_user: idUser,
          quota,
          remove_quota: false,
        },
      ],
    });
  };

  const onUpdateFeatureCredits = (idUser: number, usedCredits: number) => {
    return setUserFeatureCreditsQuery.mutateAsync({
      idUser,
      featureToCreditUsage: { [featureId]: usedCredits },
      origin: RequestOrigin.ADMIN,
    });
  };

  return {
    onSetUserQuotas,
    onUpdateFeatureCredits,
  };
};

export default useUpdateUserCredit;
