/** @deprecated */
const styles = () => ({
  tableCell: {
    padding: '1em 0.4em',
    verticalAlign: 'top',
    border: 0,
  },
});

export default styles;
