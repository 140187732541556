import React from 'react';
import PropTypes from 'prop-types';
import RadioCell from 'client/modules/common/components/Table/TableComponents/CellComponents/RadioCell';
import StoriesInnerTable from '../StoriesInnerTable';
import ExpandIconCell from '../table-components/ExpandIconCell';
import ExpandSlugs from '../table-components/ExpandSlugs';

export const mapStoriesTableComponents = (type, props, commonProps) => {
  const {
    rowId,
    checked,
    checkValue,
    originalPermission,
    idStory,
    idTeam,
  } = props;
  switch (type) {
    case 'story-inner-table':
      return <StoriesInnerTable {...commonProps} id={rowId} />;
    case 'stories-team-expand-icon':
      return (
        <ExpandIconCell {...commonProps} idStory={idStory} idTeam={idTeam} />
      );
    case 'stories-radio':
      return (
        <RadioCell
          {...commonProps}
          id={rowId}
          checked={checked}
          checkValue={checkValue}
          radioDisabled={
            originalPermission !== 'noAccess' && checkValue === 'noAccess'
          }
        />
      );
    case 'stories-slugs':
      return <ExpandSlugs {...commonProps} id={rowId} />;
    default:
      return null;
  }
};

mapStoriesTableComponents.propTypes = {
  rowId: PropTypes.string,
  checked: PropTypes.bool,
  checkValue: PropTypes.string.isRequired,
  originalPermission: PropTypes.string,
  idStory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idTeam: PropTypes.number,
};

mapStoriesTableComponents.defaultProps = {
  rowId: '',
  checked: false,
  originalPermission: '',
  idStory: '',
  idTeam: 0,
};

export default mapStoriesTableComponents;
