import React from 'react';
import PropTypes from 'prop-types';

const DangerouslySetHtmlCell = ({ value, dataTest }) => {
  return (
    <div
      data-test={dataTest}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

DangerouslySetHtmlCell.propTypes = {
  value: PropTypes.string,
  dataTest: PropTypes.string,
};

DangerouslySetHtmlCell.defaultProps = {
  value: '',
  dataTest: '',
};

/** @deprecated */
export default DangerouslySetHtmlCell;
