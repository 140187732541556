import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { toggleInnerTable } from 'client/modules/stories/redux/actions/tab-data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import compose from 'recompose/compose';
import Button from 'client/component-library/buttons/Button';
import { withStyles } from 'client/component-library/styles';
import { setSuccessNotification } from 'client/modules/common/redux/actions/status-notification';
import styles from '../../styles/material-ui/expandIcon';
import ConfirmSlugCreationDialog from '../../ConfirmSlugCreationDialog';

class ExpandSlugs extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      inputValue: '',
      open: false,
    });
  }

  handleToggle = (id) => () => {
    const [recordId] = id.split('-');
    this.props.toggleInnerTable(recordId);
  };

  handleCopyUrl = (slug) => () => {
    const baseUrl = `${window.envVars.APP_DOMAIN}/stories/`;
    const textField = document.createElement('textarea');
    textField.innerText = baseUrl.concat(slug);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.props.setSuccessNotification('Copied!');
  };

  showFirstSlug = (isExpanded) => {
    const { id, classes, slugs } = this.props;
    const iconName = isExpanded ? 'remove' : 'add';

    return (
      <div>
        <span style={{ float: 'left' }}>
          <BaseIcon
            handleOnClick={this.handleToggle(id)}
            dataTest={`${id}-expand-icon`}
            iconName={iconName}
            isIconBlock={false}
            customIconButtonClass={classes.icon}
          />
        </span>
        <span style={{ marginLeft: '6px' }} data-test="primary-slug">
          {slugs[0]}
        </span>
        {slugs[0] && (
          <Button
            className={classes.copyIcon}
            onClick={this.handleCopyUrl(slugs[0])}
            color="primary"
            dataTest="copy-url"
          >
            Copy URL
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      id,
      showHiddenRowDict,
      slugs,
      disabled,
      name,
      isPrivate,
    } = this.props;
    const isExpanded = showHiddenRowDict[id.split('-')[0]];

    if (isExpanded) {
      return (
        <div>
          {this.showFirstSlug(isExpanded)}
          {slugs.length > 1 &&
            map(slugs.slice(1), (slug, key) => {
              return (
                <p
                  key={key}
                  style={{ marginLeft: '26px' }}
                  data-test={`${key}-slug`}
                >
                  {slug}
                </p>
              );
            })}
          {!isPrivate && (
            <ConfirmSlugCreationDialog
              id={id}
              disabled={disabled}
              name={name}
            />
          )}
        </div>
      );
    }

    return this.showFirstSlug(isExpanded);
  }
}

function mapStateToProps({ stories }, ownProps) {
  const recordId = parseInt(ownProps.id, 10);
  return {
    showHiddenRowDict: stories.tabData[stories.currentTab].showHiddenRowDict,
    slugs: stories.tabData[stories.currentTab].records[recordId].slugs,
    isPrivate: stories.tabData[stories.currentTab].records[recordId].isPrivate,
  };
}

ExpandSlugs.propTypes = {
  toggleInnerTable: PropTypes.func.isRequired,
  id: PropTypes.string,
  showHiddenRowDict: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  isPrivate: PropTypes.bool.isRequired,
  setSuccessNotification: PropTypes.func.isRequired,
};

ExpandSlugs.defaultProps = {
  id: '',
  disabled: false,
  name: '',
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    toggleInnerTable,
    setSuccessNotification,
  })
)(ExpandSlugs);
