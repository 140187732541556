import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, slice, orderBy, find } from 'lodash';
import { filter } from 'lodash/fp';
import ManagementQueueTable from 'client/modules/news/queue/components/ManagementQueueTable';
import ManagementQueueRow from 'client/modules/news/queue/components/ManagementQueueRow';
import SortToggleButton from 'client/modules/news/queue/components/SortToggleButton';
import Pagination from 'client/modules/common/components/Pagination';
import Select from 'client/modules/common/components/forms/Select';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';
import Input from 'client/component-library/Input/afsInput';
import {
  fetchNewsForManagement,
  fetchLanguageFilters,
  fetchFundingClassifierFilterStatus,
  updateCategoryFilter,
  updateSourceFilter,
  updateLanguageFilter,
  restoreArticle,
  unblockCIK,
  discardItem,
  archiveItem,
  toggleDateSort,
  setTitleSearchText,
  setSourceSearchText,
  updateQueueWeek,
  updateArticleTypeFilter,
  updateFundingClassifierFilterStatus,
  DISCARDED,
  ARCHIVED,
  BLOCKED_CIKS,
  NOT_REVIEWED,
  SEC,
} from 'client/modules/news/queue/redux/reducers/news-queue-management';
import { setPage } from 'client/modules/common/redux/actions/pagination-actions';

export class QueueManagement extends Component {
  constructor(props) {
    super(props);
    this.onChangeArticleTypeFilter = this.onChangeArticleTypeFilter.bind(this);
    this.onChangeCategoryFilter = this.onChangeCategoryFilter.bind(this);
    this.onChangeSourceFilter = this.onChangeSourceFilter.bind(this);
    this.onChangeLanguageFilter = this.onChangeLanguageFilter.bind(this);
    this.handleRestoreItem = this.handleRestoreItem.bind(this);
    this.handleDiscardItem = this.handleDiscardItem.bind(this);
    this.handleArchiveItem = this.handleArchiveItem.bind(this);
    this.handleDateToggle = this.handleDateToggle.bind(this);
    this.handleTitleSearch = this.handleTitleSearch.bind(this);
    this.handleSourceSearch = this.handleSourceSearch.bind(this);
    this.handleSetPage = this.handleSetPage.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeFundingClassifierFilterToggle = this.onChangeFundingClassifierFilterToggle.bind(
      this
    );
  }

  componentDidMount() {
    this.props.dispatch(fetchNewsForManagement());
    this.props.dispatch(fetchLanguageFilters());
    this.props.dispatch(fetchFundingClassifierFilterStatus());
  }

  onChangeArticleTypeFilter(articleTypeFilterId) {
    const articleFilter = find(this.props.management.articleTypeFilters, [
      'id',
      articleTypeFilterId,
    ]);
    this.props.dispatch(updateArticleTypeFilter(articleFilter));
  }

  onChangeCategoryFilter(categoryFilter) {
    this.props.dispatch(updateCategoryFilter(categoryFilter));
  }

  onChangeSourceFilter(sourceFilter) {
    this.props.dispatch(updateSourceFilter(sourceFilter));
  }

  onChangeLanguageFilter(idLanguageFilter) {
    this.props.dispatch(updateLanguageFilter(idLanguageFilter));
  }

  onChangeDate(date) {
    this.props.dispatch(updateQueueWeek(date));
  }

  onChangeFundingClassifierFilterToggle(event) {
    const isEnabledFundingClassiferFilter = event.currentTarget.checked;
    this.props.dispatch(
      updateFundingClassifierFilterStatus(isEnabledFundingClassiferFilter)
    );
  }

  handleRestoreItem(id, source) {
    this.props.dispatch(restoreArticle(id, source));
  }

  handleUnblockItem(id, cik) {
    this.props.dispatch(unblockCIK(id, cik));
  }

  handleDiscardItem(id) {
    this.props.dispatch(discardItem(id));
  }

  handleArchiveItem(id) {
    this.props.dispatch(archiveItem(id));
  }

  handleDateToggle() {
    this.props.dispatch(toggleDateSort());
    this.props.dispatch(setPage(1));
  }

  handleTitleSearch(e) {
    this.props.dispatch(setTitleSearchText(e.target.value));
    this.props.dispatch(setPage(1));
  }

  handleSourceSearch(e) {
    this.props.dispatch(setSourceSearchText(e.target.value));
    this.props.dispatch(setPage(1));
  }

  handleSetPage(page) {
    this.props.dispatch(setPage(page));
  }

  render() {
    const {
      queue,
      management: {
        isLoading,
        sourceFilter,
        categoryFilter,
        languageFilter,
        sourceFilters,
        categoryFilters,
        languageFilters,
        articleTypeFilters,
        selectedArticleTypeFilter,
        dateSort,
        titleSearchText,
        sourceSearchText,
        weekOptions,
        weekSelection,
      },
      pagination: { page, itemsPerPage, startIndex, startItem },
    } = this.props;

    const paginatedItems = slice(queue, startIndex, startIndex + itemsPerPage);

    let action = '';

    switch (categoryFilter.type) {
      case DISCARDED:
        action = 'Discarded';
        break;
      case ARCHIVED:
        action = 'Archived';
        break;
      case BLOCKED_CIKS:
        action = 'Blocked';
        break;
      default:
        action = 'Processed';
    }

    const loading = (
      <div className="text-center" data-test="fetchingNews">
        <h4>
          <i className="glyphicon glyphicon-cog gly-spin" /> Fetching Queue
        </h4>
      </div>
    );

    const table = (
      <div>
        <ManagementQueueTable action={action}>
          {map(paginatedItems, (item, index) => (
            <ManagementQueueRow key={item.id} newsItem={item} index={index}>
              {categoryFilter === DISCARDED && (
                <Button
                  onClick={() => this.handleRestoreItem(item.id, item.source)}
                  data-test="restoreButton"
                  theme="success"
                  style={{ height: '42px', width: '140px' }}
                >
                  Restore
                </Button>
              )}
              {categoryFilter === BLOCKED_CIKS && (
                <Button
                  onClick={() => this.handleUnblockItem(item.id, item.content)}
                  data-test="unblockButton"
                  theme="success"
                  style={{ height: '42px', width: '140px' }}
                >
                  Unblock
                </Button>
              )}
              {categoryFilter === NOT_REVIEWED && sourceFilter !== SEC && (
                <div>
                  <Button
                    onClick={() => this.handleDiscardItem(item.id)}
                    data-test="discardButton"
                    theme="danger"
                    style={{ height: '42px', width: '140px' }}
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => this.handleArchiveItem(item.id)}
                    disabled={false}
                    data-test="archive"
                    theme="success"
                    style={{
                      height: '42px',
                      width: '140px',
                      marginTop: '16px',
                    }}
                  >
                    Archive
                  </Button>
                </div>
              )}
            </ManagementQueueRow>
          ))}
        </ManagementQueueTable>
        <Pagination
          page={page}
          itemsPerPage={itemsPerPage}
          startItem={startItem}
          totalItems={queue ? queue.length : 0}
          setPage={this.handleSetPage}
        />
      </div>
    );

    return (
      <div className="container mx-auto mt-8 mb-4">
        <div className="text-center text-3xl text-black font-medium py-2">
          News Queue Management
        </div>
        <div
          className="flex flex-col justify-between pt-4 sticky bg-white pb-2"
          style={{ top: '48px' }}
        >
          <div className="flex">
            <div className="w-1/12 px-2">
              <SortToggleButton
                text="Date "
                direction={dateSort}
                onClick={this.handleDateToggle}
              />
            </div>
            <div className="w-1/6 px-2">
              <Select
                options={weekOptions}
                value={weekSelection}
                textField="name"
                onChange={this.onChangeDate}
                data-test="dateSelect"
              />
            </div>
            <div className="w-1/6 px-2">
              <Select
                options={sourceFilters}
                value={sourceFilter}
                textField="text"
                valueField="type"
                onChange={this.onChangeSourceFilter}
                data-test="sourceSelect"
              />
            </div>
            <div className="w-1/12 px-2">
              <div className="flex justify-between cursor-pointer">
                <input
                  className="h-6"
                  id="classifier-checkbox"
                  type="checkbox"
                  checked={this.props.isEnabledFundingClassiferFilter}
                  onClick={this.onChangeFundingClassifierFilterToggle}
                />
                <label htmlFor="classifier-checkbox-label">Classifier</label>
              </div>
            </div>
            <div className="w-1/6 px-2">
              <Select
                options={categoryFilters}
                value={categoryFilter}
                textField="text"
                valueField="type"
                onChange={this.onChangeCategoryFilter}
                data-test="categorySelect"
              />
            </div>
            <div className="w-1/6 px-2">
              <Select
                options={articleTypeFilters}
                value={selectedArticleTypeFilter.id}
                textField="name"
                valueField="id"
                onChange={this.onChangeArticleTypeFilter}
                data-test="articleTypeSelect"
              />
            </div>
            <div className="w-1/6 px-2">
              <Select
                options={languageFilters}
                value={languageFilter.id}
                textField="name"
                valueField="id"
                onChange={this.onChangeLanguageFilter}
                data-test="languageSelect"
              />
            </div>
          </div>
          <div className="flex justify-between pt-4">
            <div className="w-1/2 px-2">
              <Input
                value={titleSearchText}
                onChange={this.handleTitleSearch}
                placeholder="Search by Title"
                data-test="searchInput"
                style={{ paddingHorizontal: '16px' }}
              />
            </div>
            <div className="w-1/2 px-2">
              <Input
                value={sourceSearchText}
                onChange={this.handleSourceSearch}
                placeholder="Search by Source"
                data-test="searchSourceInput"
              />
            </div>
          </div>
        </div>
        <div>{isLoading ? loading : table}</div>
      </div>
    );
  }
}

export function mapStateToProps({ newsQueue, pagination }) {
  const {isEnabledFundingClassiferFilter} = newsQueue;
  let {queue} = newsQueue.management;
  const titleTerm = new RegExp(
    newsQueue.management.titleSearchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    'i'
  );
  const sourceTerm = new RegExp(
    newsQueue.management.sourceSearchText.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    ),
    'i'
  );

  if (newsQueue.management.titleSearchText.length) {
    queue = filter((item) => item.title && item.title.match(titleTerm))(queue);
  }

  if (newsQueue.management.sourceSearchText.length) {
    queue = filter(
      (item) => item.newsSource && item.newsSource.match(sourceTerm)
    )(queue);
  }

  if (newsQueue.management.idLanguageFilter !== -1) {
    // Doesn't === 'All Languages'
    queue = filter(
      (article) =>
        article.idLanguageGroup === newsQueue.management.idLanguageFilter,
      queue
    );
  }

  queue = orderBy(queue, ['actionDate'], [newsQueue.management.dateSort]);
  return {
    management: newsQueue.management,
    queue,
    isEnabledFundingClassiferFilter,
    pagination,
  };
}

QueueManagement.propTypes = {
  management: PropTypes.object.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  isEnabledFundingClassiferFilter: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    startIndex: PropTypes.number.isRequired,
    startItem: PropTypes.object.isRequired,
  }).isRequired,
};

QueueManagement.defaultProps = {
  pagination: undefined,
};

export default connect(mapStateToProps)(QueueManagement);
