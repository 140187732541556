// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-business-relationships-components-GenericComponents-Icon-___styles__icon___JB2TA {
  font-family: 'Material Icons';
}
`, "",{"version":3,"sources":["webpack://./client/modules/business-relationships/components/GenericComponents/Icon/styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":[".icon {\n  font-family: 'Material Icons';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `client-modules-business-relationships-components-GenericComponents-Icon-___styles__icon___JB2TA`
};
export default ___CSS_LOADER_EXPORT___;
