import {
  PostEntityDraftLogoRequest,
  PostEntityDraftLogoResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const postEntityDraftLogo = (
  req: Partial<PostEntityDraftLogoRequest>
): Promise<PostEntityDraftLogoResponse> => {
  return request({
    url: '/service/logoservice/PostEntityDraftLogo',
    body: { ...req },
  }).then((r): PostEntityDraftLogoResponse => r.body);
};
