import React, { Component } from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { InputAdornment } from 'client/component-library/Input';
import Icon from 'client/component-library/icons/Icon';
import {
  setArticleContent,
  setSelectedArticleMarketRowId,
} from 'client/modules/markets/redux/actions/article-content';
import styles from '../styles/table-styles.css';

class MarketNewsArticleInputAdornment extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  fetchNews = () => (e) => {
    e.preventDefault();
    const { dispatch, news, recordId, selectedRecordId } = this.props;

    const [newRecordId] = recordId.split('-');
    if (selectedRecordId === newRecordId) {
      return dispatch(setSelectedArticleMarketRowId('0')); // unset if news already selected!
    }

    return dispatch(setArticleContent(news.id)).then(() => {
      dispatch(setSelectedArticleMarketRowId(recordId));
    });
  };

  render() {
    const { news, recordId, selectedRecordId } = this.props;

    let iconName = 'zoom_out_map';
    if (selectedRecordId === recordId.split('-')[0]) {
      iconName = 'cancel';
    } else if (news.tsLinkTested.length && news.linkStatusCode !== 200) {
      iconName = 'error';
    }

    return (
      <InputAdornment position="start">
        <a
          onClick={this.fetchNews()}
          href="#openNews"
          className={styles.inputAdornment}
        >
          <Icon
            name={iconName}
            family="material"
            styles={{ fontSize: '16px' }}
          />
        </a>
      </InputAdornment>
    );
  }
}

function mapStateToProps({ markets }) {
  return {
    selectedRecordId: markets.articleContent.selectedRecordId.split('-')[0],
  };
}

MarketNewsArticleInputAdornment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  recordId: PropTypes.string,
  news: PropTypes.object.isRequired,
  selectedRecordId: PropTypes.string,
};

MarketNewsArticleInputAdornment.defaultProps = {
  recordId: '',
  selectedRecordId: '0',
};

export default connect(mapStateToProps)(MarketNewsArticleInputAdornment);
