import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTeamsByStory,
  getStoriesByName,
} from 'client/modules/stories/redux/actions/tab-data';
import {
  UPDATE_ACCESS_BY_STORY,
  MANAGE_SLUGS,
} from 'client/modules/stories/utils/tab-names';
import DateHeaderCell from './DateHeaderCell';

class DateHeaderCellWrapper extends Component {
  filterAction = () => {
    const { currentTab } = this.props;
    switch (currentTab) {
      case UPDATE_ACCESS_BY_STORY:
        return this.props.getTeamsByStory;
      case MANAGE_SLUGS:
        return this.props.getStoriesByName;
      default:
        return null;
    }
  };

  render() {
    return (
      <DateHeaderCell {...this.props} filterAction={this.filterAction()} />
    );
  }
}

function mapStateToProps({ stories }) {
  return {
    currentTab: stories.currentTab,
  };
}

DateHeaderCellWrapper.propTypes = {
  currentTab: PropTypes.string.isRequired,
  getTeamsByStory: PropTypes.func.isRequired,
  getStoriesByName: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getTeamsByStory,
  getStoriesByName,
})(DateHeaderCellWrapper);
