export const PAGEID = 61;
// note 10 will be backend and 1 will be new record placeholder
export const RECORDS_PER_PAGE = 11;
export const NEW_RECORDS_PER_PAGE = 1;

export const SP_VENDORS = { idPage: PAGEID, idPackages: [651] };

export const ROUTES = {
  root: {
    path: 'service-provider',
    uri: '/service-provider',
  },
  profilePage: {
    path: ':id',
    uri: '/service-provider/:id',
  },
  clientTable: {
    path: 'client-table',
    uri: '/service-provider/:id/client-table',
  },
  providerTable: {
    path: 'provider-table',
    uri: '/service-provider/:id/provider-table',
  },
};

export const TABLE_TYPES = {
  CLIENT: 'sp-clients-table',
  PROVIDER: 'sp-provider-table',
};
export const FIELDS = {
  ID: 'id',
  ENTITY: 'entity',
  TIED_TRAN: 'tiedToTransaction',
  ASSO_TRAN: 'associatedTransaction',
  SERV_TYPE: 'serviceType',
  SOURCE: 'source',
  ROUND: 'round',
  ACQ_SIDE: 'acquisitionSide',
  DELETE: 'toDelete',
};

export const TIED_TRANS_VALUES = { YES: 'YES', NO: 'NO' };
export const ACQ_SIDE_VALUES = { BUY: 'buy', SELL: 'sell' };

export const TIED_TRANS_OPTIONS = [
  {
    label: 'Yes',
    value: TIED_TRANS_VALUES.YES,
  },
  {
    label: 'No',
    value: TIED_TRANS_VALUES.NO,
  },
];

export const ACQ_SIDE_OPTIONS = [
  {
    label: 'Buy Side',
    value: ACQ_SIDE_VALUES.BUY,
  },
  {
    label: 'Sell Side',
    value: ACQ_SIDE_VALUES.SELL,
  },
];
