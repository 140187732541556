/**
 * Simple wrapper over superagent
 */
import superagent, { SuperAgentRequest } from 'superagent';
import get from 'lodash/get';

let mocReponse = {};

// TODO: remove this, always rewire in tests, from the file that imports this
export function setMocReponse(mocData) {
  mocReponse = mocData;
}

interface RequestArgs {
  method?: string;
  url: string;
  body?: Record<string, unknown>;
  reqHandler?: (req: SuperAgentRequest) => void;
}

export function request({
  method = 'post',
  url,
  body,
  reqHandler,
}: RequestArgs): SuperAgentRequest {
  if (get(process, 'env.NODE_ENV') === 'test') {
    const mockRes = Promise.resolve({
      body: { ...body, ...mocReponse },
    }) as SuperAgentRequest;
    mockRes.abort = () => {};
    return mockRes;
  }

  const req: SuperAgentRequest = superagent[method.toLowerCase()](url);

  if (reqHandler) {
    reqHandler(req);
  }

  if (body) {
    req.send(body);
  }

  return req;
}
