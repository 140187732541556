import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Graph } from '@cbinsights/react-d3-graph';

import IconButton from 'client/component-library/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Tooltip from 'client/component-library/Tooltip/Tooltip';
import Table from 'client/modules/common/components/Table';
import TableHeader from 'client/modules/common/components/Table/TableComponents/TableHeader';
import TableBody from 'client/modules/common/components/Table/TableComponents/TableBody';
import SelectWithSearch from 'client/component-library/Select/SearchableSelect/SelectWithSearch';
import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import styles from 'client/component-library/Input/input.css';

import { withReactRouter } from 'client/routes/withReactRouter';
import DataPlatformWrapper from '../components/DataPlatformWrapper';
import {
  updateSearchTerm,
  fetchNodeDetails,
  setPipeline,
  setGraphExcludeLabels,
  addGraphExcludeLabel,
  clearGraphExcludeLabels,
  allGraphExcludeLabels,
  toggleComponentSettingsSideBar,
  getAllIds,
  getAllLabels,
} from '../redux/actions/search';
import { COMPONENT_GRAPH_CONFIG } from '../components/utils/get-graph-config';
import { graphContainerStyle } from '../components/styles/material-ui/dataPlatformCommonStyles';
import { componentPropsColumns } from '../components/Table/utils/table-columns';
import HealthIcon from '../components/HealthIcon';
import GraphDisplaySettingsSideBar from '../components/GraphDisplaySettingsSideBar';
import iconButtonStyle from './styles/material-ui/buttonStyle';
import convertStringToArray from './utils/data_utils';

class ComponentDetails extends Component {

  toggleComponentSettingsSideBarStyle = {
    ...iconButtonStyle,
    backgroundColor: '#006699',
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      searchLabel: -1,
    };
  }

  UNSAFE_componentWillMount() {
    const { queryParams } = this.props;

    let excludeLabels = this.props.graphExcludeLabels;
    if (queryParams.graphExcludeLabels && queryParams.graphExcludeLabels.length) {
      excludeLabels = convertStringToArray(queryParams.graphExcludeLabels);
      this.props.setGraphExcludeLabels(excludeLabels);
    }
    if (queryParams.componentId && queryParams.componentId.length) {
      this.fetchNodeDetails(queryParams.componentId, excludeLabels);
    }
    if (queryParams.pipelineName && queryParams.pipelineName.length) {
      this.props.setPipeline(queryParams.pipelineName);
    }
    this.props.getAllLabels();
  }

  onClickNode = (nodeId) => {
    if (nodeId !== this.props.centralVertexId) {
      this.fetchNodeDetails(nodeId, this.props.graphExcludeLabels);
    }
  };

  setSearchTerm = (e) => {
    this.setState({ searchTerm: e.id });
    this.props.updateSearchTerm(e.id);
  };

  setSearchLabel = ({ id }) => {
    this.setState({ searchLabel: id });
    this.props.getAllIds({ labels: [id] });
  };

  fetchNodeDetails = (nodeId, excludeLabels) => {
    this.props.fetchNodeDetails(nodeId, excludeLabels);
    updateUrlParams('componentId', nodeId);
    updateUrlParams('graphExcludeLabels', excludeLabels);
  };

  searchNode = () => {
    this.onClickNode(this.props.searchTerm, this.props.graphExcludeLabels);
  };

  clearGraphExcludeLabels = () => {
    this.props.clearGraphExcludeLabels();
    this.fetchNodeDetails(this.props.centralVertexId, []);
  };

  allGraphExcludeLabels = () => {
    this.props.allGraphExcludeLabels();
    this.fetchNodeDetails(this.props.centralVertexId, this.props.labels);
  };

  filterGraphExcludeLabels = () => {
    this.fetchNodeDetails(
      this.props.centralVertexId,
      this.props.graphExcludeLabels
    );
  };

  toggleSettings = () => {
    this.props.toggleComponentSettingsSideBar();
  };

  render() {
    return (
      <DataPlatformWrapper>
        {this.props.graphNodes &&
          !!this.props.graphNodes.length &&
          this.props.vertexProps ? (
          <div>
            <div>
              <div className="flex justify-center">
                <div className="flex flex-col">
                  <div className="flex">
                    <div>
                      <label htmlFor="data-platform-component-details-search">
                        Get Component Details by ID:
                        <SelectWithSearch
                          value={this.state.searchLabel}
                          options={this.props.labels.map((label) => ({
                            id: label,
                            name: label,
                          }))}
                          onChange={this.setSearchLabel}
                          className={styles.componentSearch}
                        />
                        <SelectWithSearch
                          value={this.state.searchTerm}
                          options={this.props.labelsIds.map((label) => ({
                            id: label,
                            name: label,
                          }))}
                          onChange={this.setSearchTerm}
                          className={styles.componentSearch}
                        />
                        <button onClick={this.searchNode}>Search</button>
                      </label>
                    </div>
                    {this.props.graphNodes &&
                      !!this.props.graphNodes.length &&
                      this.props.vertexProps && (
                        <Tooltip title="Show/Hide Graph Settings">
                          <IconButton
                            style={this.toggleComponentSettingsSideBarStyle}
                            onClick={this.toggleSettings}
                          >
                            {this.props.showComponentSettingsSideBar ? (
                              <Icon
                                className={styles.iconClassPossitionHack}
                                name="settings"
                                family="material"
                              />
                            ) : (
                              <Icon
                                className={styles.iconClassPossitionHack}
                                name="settings_applications"
                                family="material"
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                  </div>
                  <div className="flex">
                    <div className="flex flex-shrink">
                      <HealthIcon health={this.props.centralVertexHealth} />
                    </div>
                    <div className="flex flex-shrink">
                      <h3>
                        Current Component ID - {this.props.centralVertexId}
                      </h3>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        ...graphContainerStyle,
                        width: this.props.componentGraphWidth,
                      }}
                    >
                      <Graph
                        id="component-graph" // id is mandatory, if no id is defined rd3g will throw an error
                        config={COMPONENT_GRAPH_CONFIG}
                        data={{
                          nodes: this.props.graphNodes,
                          links: this.props.graphEdges,
                        }}
                        onClickNode={this.onClickNode}
                      />
                    </div>
                    {this.props.showComponentSettingsSideBar && (
                      <div style={{ marginLeft: '15px' }}>
                        <GraphDisplaySettingsSideBar
                          excludeLabels={this.props.graphExcludeLabels}
                          addExcludeLabel={this.props.addGraphExcludeLabel}
                          clearExcludeLabels={this.clearGraphExcludeLabels}
                          allExcludeLabels={this.allGraphExcludeLabels}
                          filter={this.filterGraphExcludeLabels}
                        />
                      </div>
                    )}
                  </div>
                  <Table>
                    <TableHeader columnNames={componentPropsColumns} />
                    <TableBody
                      records={this.props.vertexProps.records}
                      columnNames={componentPropsColumns}
                      recordsOrder={this.props.vertexProps.recordOrder}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div>
              <label htmlFor="data-platform-component-details-search">
                Get Component Details by ID:
                <SelectWithSearch
                  value={this.state.searchTerm}
                  options={this.props.labelsIds.map((label) => ({
                    id: label,
                    name: label,
                  }))}
                  onChange={this.setSearchTerm}
                  className={styles.componentSearch}
                />
                <button onClick={this.searchNode}>Search</button>
              </label>
            </div>
            {this.props.graphNodes &&
              !!this.props.graphNodes.length &&
              this.props.vertexProps && (
                <Tooltip title="Show/Hide Graph Settings">
                  <IconButton
                    style={this.toggleComponentSettingsSideBarStyle}
                    onClick={this.toggleSettings}
                  >
                    {this.props.showComponentSettingsSideBar ? (
                      <Icon
                        className={styles.iconClassPossitionHack}
                        name="settings"
                        family="material"
                      />
                    ) : (
                      <Icon
                        className={styles.iconClassPossitionHack}
                        name="settings_applications"
                        family="material"
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
          </div>
        )}
      </DataPlatformWrapper>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    searchTerm: dataPlatform.search.searchTerm,
    graphNodes: dataPlatform.search.graphNodes,
    graphEdges: dataPlatform.search.graphEdges,
    vertexProps: dataPlatform.search.vertexProps,
    centralVertexId: dataPlatform.search.centralVertexId,
    centralVertexHealth: dataPlatform.search.centralVertexHealth,
    graphExcludeLabels: dataPlatform.search.graphExcludeLabels,
    labels: dataPlatform.search.labels,
    labelsIds: dataPlatform.search.labelsIds,
    showComponentSettingsSideBar:
      dataPlatform.search.showComponentSettingsSideBar,
    componentGraphWidth: dataPlatform.search.componentGraphWidth,
  };
}

ComponentDetails.propTypes = {
  searchTerm: PropTypes.string,
  updateSearchTerm: PropTypes.func.isRequired,
  fetchNodeDetails: PropTypes.func.isRequired,
  graphNodes: PropTypes.arrayOf(PropTypes.object),
  graphEdges: PropTypes.arrayOf(PropTypes.object),
  vertexProps: PropTypes.object,
  centralVertexId: PropTypes.string,
  centralVertexHealth: PropTypes.string,
  queryParams: PropTypes.object.isRequired,
  setPipeline: PropTypes.func.isRequired,
  graphExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  labelsIds: PropTypes.arrayOf(PropTypes.object),
  addGraphExcludeLabel: PropTypes.func.isRequired,
  clearGraphExcludeLabels: PropTypes.func.isRequired,
  toggleComponentSettingsSideBar: PropTypes.func.isRequired,
  showComponentSettingsSideBar: PropTypes.bool,
  componentGraphWidth: PropTypes.number,
  getAllIds: PropTypes.func.isRequired,
  getAllLabels: PropTypes.func.isRequired,
  setGraphExcludeLabels: PropTypes.func.isRequired,
  allGraphExcludeLabels: PropTypes.func.isRequired,
};

ComponentDetails.defaultProps = {
  searchTerm: '',
  graphNodes: [],
  centralVertexId: '',
  graphEdges: [],
  vertexProps: {},
  centralVertexHealth: '',
  labels: [],
  labelsIds: ['Select Label First'],
  graphExcludeLabels: [],
  showComponentSettingsSideBar: false,
  componentGraphWidth: 1200,
};

export default withReactRouter(connect(mapStateToProps, {
  updateSearchTerm,
  fetchNodeDetails,
  setPipeline,
  setGraphExcludeLabels,
  addGraphExcludeLabel,
  clearGraphExcludeLabels,
  allGraphExcludeLabels,
  toggleComponentSettingsSideBar,
  getAllIds,
  getAllLabels,
})(ComponentDetails));
