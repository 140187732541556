import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  toggleInnerTable,
  getClientTeamMembers,
} from 'client/modules/stories/redux/actions/tab-data';
import BaseIcon from 'client/modules/common/components/Icons/BaseIcon';
import compose from 'recompose/compose';
import { withStyles } from 'client/component-library/styles';
import styles from '../../styles/material-ui/expandIcon';

class ExpandIconCell extends Component {
  handleToggle = (id, isExpanded) => () => {
    const { idStory, idTeam } = this.props;
    const [recordId] = id.split('-');
    if (!isExpanded) {
      this.props.getClientTeamMembers(recordId, idStory, idTeam);
    }
    this.props.toggleInnerTable(recordId);
  };

  render() {
    const { id, showHiddenRowDict, classes } = this.props;
    const isExpanded = showHiddenRowDict[id.split('-')[0]];

    return (
      <div>
        {isExpanded ? (
          <BaseIcon
            handleOnClick={this.handleToggle(id, isExpanded)}
            dataTest={`${id}-expand-icon`}
            iconName="Remove"
            isIconBlock={false}
            customIconButtonClass={classes.icon}
          />
        ) : (
          <BaseIcon
            handleOnClick={this.handleToggle(id, isExpanded)}
            dataTest={`${id}-expand-icon`}
            iconName="Add"
            isIconBlock={false}
            customIconButtonClass={classes.icon}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ stories }) {
  return {
    showHiddenRowDict: stories.tabData[stories.currentTab].showHiddenRowDict,
  };
}

ExpandIconCell.propTypes = {
  toggleInnerTable: PropTypes.func.isRequired,
  id: PropTypes.string,
  showHiddenRowDict: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getClientTeamMembers: PropTypes.func.isRequired,
  idStory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  idTeam: PropTypes.number.isRequired,
};

ExpandIconCell.defaultProps = {
  id: '',
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    toggleInnerTable,
    getClientTeamMembers,
  })
)(ExpandIconCell);
