import PropTypes from 'prop-types';
import React from 'react';
import Button from 'client/component-library/buttons/ButtonAdmin/afsButton';

const FetchQueueButton = ({ onClick, isFetchingNews }) => (
  <div className="">
    <div className="mt-4 w-32">
      <Button
        onClick={onClick}
        disabled={isFetchingNews}
        data-test="fetchNewsButton"
      >
        {isFetchingNews ? (
          <span data-test="fetchingNews">
            <i className="glyphicon glyphicon-cog gly-spin" />
            &nbsp; Fetching...
          </span>
        ) : (
          <span data-test="notFetchingNews">Fetch News</span>
        )}
      </Button>
    </div>
  </div>
);

export default FetchQueueButton;

FetchQueueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isFetchingNews: PropTypes.bool.isRequired,
};
