import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tooltip from 'client/component-library/Tooltip/Tooltip';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'client/component-library/icons/Icon';
import Button from 'client/component-library/buttons/Button';
import styles from 'client/component-library/Input/input.css';
import iconButtonStyle from '../containers/styles/material-ui/buttonStyle';

import {
  outerStyle,
  modalHeaderTextStyle,
} from './styles/material-ui/dataPlatformCommonStyles';
import { updatePipelineUsers } from '../redux/actions/search';
import {
  getUsersWithAccessToDataPlatform,
  resetManagePermissionsPage,
  setDisplayNewUsers,
  setDisplayPipelineUsers,
  setDisplayAdminUsers,
  initializePipelineUsers,
} from '../redux/actions/users';
import PipelinePermissionsFlex from './PipelinePermissionsFlex';

const { iconClassPossitionHack } = styles;

class ManagePermissions extends Component {
  state = {
    showManagePermissionsPopover: false,
    expanded: true,
  };

  UNSAFE_componentWillMount() {
    this.props.getUsersWithAccessToDataPlatform();
  }

  openManagePermissionsPopover = () => {
    this.props.setDisplayPipelineUsers(
      this.props.pipelineUsers,
      this.props.pipelineUserPageNumber,
      this.props.pipelineUserRecordsPerPage
    );
    this.props.setDisplayAdminUsers({
      adminUsers: this.props.adminUsers,
      pipelineUsers: this.props.graphPipelineUsers,
      newUsers: this.props.newUsers,
      pageNumber: this.props.adminUserPageNumber,
      recordsPerPage: this.props.adminUserRecordsPerPage,
      filterTerm: this.props.adminUserFilterTerm,
    });
    this.props.setDisplayNewUsers(
      this.props.newUsers,
      this.props.newUserPageNumber,
      this.props.newUserRecordsPerPage
    );
    this.setState({ showManagePermissionsPopover: true });
  };

  closeManagePermissionsPopover = () => {
    this.setState({ showManagePermissionsPopover: false });
  };

  popOverDivStyle = {
    ...outerStyle,
    width: '800px',
    borderColor: '#4cabce',
  };

  addUsers = () => {
    this.props.updatePipelineUsers({
      pipelineName: this.props.pipelineName,
      excludeLabels: this.props.graphExcludeLabels,
      newUsers: this.props.newUsers,
    });
    this.setState({ showManagePermissionsPopover: false });
  };

  toggleExpandedState = (event, expanded) => {
    this.setState({ expanded });
  };

  cancelAddUsers = () => {
    this.props.resetManagePermissionsPage();
    this.props.initializePipelineUsers(this.props.graphPipelineUsers);
    this.setState({ showManagePermissionsPopover: false });
  };

  render() {
    return (
      <div>
        <Tooltip title="Manage Pipeline Permissions">
          <IconButton
            style={{ ...iconButtonStyle, backgroundColor: '#4cabce' }}
            onClick={this.openManagePermissionsPopover}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <Icon
              className={iconClassPossitionHack}
              name="lock"
              family="material"
            />
          </IconButton>
        </Tooltip>
        <Popover
          id="manage-permissions-popover"
          open={this.state.showManagePermissionsPopover}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          onClose={this.closeManagePermissionsPopover}
        >
          <div style={this.headerStyle}>
            <div style={{ backgroundColor: '#4cabce' }}>
              <p style={modalHeaderTextStyle}>
                Manage Permissions for {this.props.pipelineName}
              </p>
            </div>
            <div style={{ padding: '8px' }}>
              <PipelinePermissionsFlex showPipelineUsers />
            </div>
            <div>
              <Button onClick={this.addUsers}>Save</Button>
              <Button onClick={this.cancelAddUsers}>Cancel</Button>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    pipelineUsers: dataPlatform.users.pipelineUsers,
    pipelineUserPageNumber: dataPlatform.users.pipelineUserPageNumber,
    pipelineUserRecordsPerPage: dataPlatform.users.pipelineUserRecordsPerPage,
    newUsers: dataPlatform.users.newUsers,
    newUserPageNumber: dataPlatform.users.newUserPageNumber,
    newUserRecordsPerPage: dataPlatform.users.newUserRecordsPerPage,
    adminUsers: dataPlatform.users.adminUsers,
    adminUserPageNumber: dataPlatform.users.adminUserPageNumber,
    adminUserRecordsPerPage: dataPlatform.users.adminUserRecordsPerPage,
    graphExcludeLabels: dataPlatform.search.graphExcludeLabels,
    graphPipelineUsers: dataPlatform.search.pipelineUsers,
    adminUserFilterTerm: dataPlatform.users.adminUserFilterTerm,
  };
}

ManagePermissions.propTypes = {
  pipelineName: PropTypes.string.isRequired,
  getUsersWithAccessToDataPlatform: PropTypes.func.isRequired,
  resetManagePermissionsPage: PropTypes.func.isRequired,
  setDisplayNewUsers: PropTypes.func.isRequired,
  setDisplayPipelineUsers: PropTypes.func.isRequired,
  setDisplayAdminUsers: PropTypes.func.isRequired,
  pipelineUsers: PropTypes.object,
  pipelineUserPageNumber: PropTypes.number.isRequired,
  pipelineUserRecordsPerPage: PropTypes.number.isRequired,
  newUsers: PropTypes.object,
  newUserPageNumber: PropTypes.number.isRequired,
  newUserRecordsPerPage: PropTypes.number.isRequired,
  adminUsers: PropTypes.object,
  adminUserPageNumber: PropTypes.number.isRequired,
  adminUserRecordsPerPage: PropTypes.number.isRequired,
  initializePipelineUsers: PropTypes.func.isRequired,
  updatePipelineUsers: PropTypes.func.isRequired,
  graphExcludeLabels: PropTypes.arrayOf(PropTypes.string),
  graphPipelineUsers: PropTypes.object,
  adminUserFilterTerm: PropTypes.string,
};

ManagePermissions.defaultProps = {
  pipelineUsers: {},
  numPipelineUsers: 0,
  pipelineUserPageNumber: 0,
  pipelineUserRecordsPerPage: 3,
  pipelineUserRecords: {},
  pipelineUserRecordOrder: [],
  pipelineUserRecordCellStyle: {},
  newUsers: {},
  numNewUsers: 0,
  newUserPageNumber: 0,
  newUserRecordsPerPage: 3,
  newUserRecords: {},
  newUserRecordOrder: [],
  newUserRecordCellStyle: {},
  adminUsers: {},
  numAdminUsers: 0,
  adminUserPageNumber: 0,
  adminUserRecordsPerPage: 3,
  adminUserRecords: {},
  adminUserRecordOrder: [],
  adminUserRecordCellStyle: {},
  graphExcludeLabels: [],
  graphPipelineUsers: {},
  adminUserFilterTerm: '',
};

export default connect(mapStateToProps, {
  getUsersWithAccessToDataPlatform,
  resetManagePermissionsPage,
  setDisplayNewUsers,
  setDisplayPipelineUsers,
  setDisplayAdminUsers,
  initializePipelineUsers,
  updatePipelineUsers,
})(ManagePermissions);
