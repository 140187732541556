/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'client/component-library/styles';
import { map, forEach } from 'lodash';
import { TableBody as MuiBody } from 'client/component-library/Table';
import TableRow from './TableRow';
import tableBodyStyles from '../styles/material-ui/tableBodyStyles';

const getRecordsOrderAndRowStyle = (recordsOrder, classes, hasHiddenRow) => {
  let updatedRecordsOrder = recordsOrder;
  let rowStyle = classes.row;
  if (hasHiddenRow) {
    rowStyle = classes.hiddenRow;
    updatedRecordsOrder = [];
    forEach(recordsOrder, (id) => {
      updatedRecordsOrder.push(id);
      updatedRecordsOrder.push(`${id}-hidden`);
    });
  }
  return { updatedRecordsOrder, rowStyle };
};

const displayNone = { display: 'none' };

/**
 * @deprecated
 * It renders a table body with rows that are either hidden or not
 * @param props - {
 * @returns A table body component
 */
const TableBody = (props) => {
  const {
    additionalPropNames,
    records,
    recordsOrder,
    recordStyles,
    selected,
    columnNames,
    hiddenRowColumns,
    handleChange,
    cellSubtexts,
    classes,
    editingDisabled,
    recordCellStyles,
    showAllArticles,
    showHiddenRowDict,
    tBodyStyles,
    generateCustomTableComponents,
  } = props;
  const hasHiddenRow = !!hiddenRowColumns.length;
  const { updatedRecordsOrder, rowStyle } = getRecordsOrderAndRowStyle(
    recordsOrder,
    classes,
    hasHiddenRow
  );
  return (
    <MuiBody style={tBodyStyles}>
      {map(updatedRecordsOrder, (id, index) => {
        // Need make sure its string
        const realId = id.toString().split('-hidden')[0];
        const isHiddenRow = hasHiddenRow && index % 2;
        return (
          <TableRow
            generateCustomTableComponents={generateCustomTableComponents}
            additionalPropNames={additionalPropNames}
            cellSubtexts={cellSubtexts}
            className={rowStyle}
            columnNames={isHiddenRow ? hiddenRowColumns : columnNames}
            editingDisabled={editingDisabled}
            id={realId}
            key={id}
            handleChange={handleChange}
            record={records[realId]}
            recordCellStyles={recordCellStyles[realId]}
            selected={selected}
            showAllArticles={showAllArticles[realId]}
            style={
              isHiddenRow && !showHiddenRowDict[realId]
                ? displayNone
                : recordStyles[realId]
            }
            tableRowId={id}
          />
        );
      })}
    </MuiBody>
  );
};

TableBody.propTypes = {
  additionalPropNames: PropTypes.arrayOf(PropTypes.string),
  hiddenRowColumns: PropTypes.arrayOf(PropTypes.object),
  editingDisabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  columnNames: PropTypes.arrayOf(PropTypes.object),
  records: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  selected: PropTypes.object,
  recordsOrder: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  recordStyles: PropTypes.object,
  cellSubtexts: PropTypes.object,
  recordCellStyles: PropTypes.object,
  tBodyStyles: PropTypes.object,
  showAllArticles: PropTypes.object,
  showHiddenRowDict: PropTypes.object,
};

TableBody.defaultProps = {
  additionalPropNames: [],
  hiddenRowColumns: [],
  editingDisabled: false,
  handleChange: () => {},
  columnNames: [],
  records: {},
  selected: {},
  recordsOrder: [],
  recordStyles: {},
  cellSubtexts: {},
  recordCellStyles: {},
  tBodyStyles: {},
  showAllArticles: {},
  showHiddenRowDict: {},
};

/** @deprecated */
export default withStyles(tableBodyStyles)(TableBody);
