import React from 'react';
import PropTypes from 'prop-types';
import RevokeIconCell from '../TableComponents/RevokeIconCell';
import DeleteIconCell from '../TableComponents/DeleteIconCell';

const mapAdminTableComponents = (type, props) => {
  const { id, status } = props;
  switch (type) {
    case 'admin-revokeIcon':
      return <RevokeIconCell id={id} status={status} />;
    case 'admin-deleteIcon':
      return <DeleteIconCell id={id} />;
    default:
      return null;
  }
};

mapAdminTableComponents.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
};

mapAdminTableComponents.defaultProps = {
  id: '',
  status: '',
};

export default mapAdminTableComponents;
