// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-markets-components-Table-styles-___table-styles__spanBold___UaCs6 {
  font-weight: bold;
}

.client-modules-markets-components-Table-styles-___table-styles__inputAdornment___yrT1u {
  position: relative;
  top: 3px;
}

.client-modules-markets-components-Table-styles-___table-styles__listOfMarketsNameCellWrapper___cBUvT {
  position: relative;
  top: -15px;
  height: 17px;
}

.client-modules-markets-components-Table-styles-___table-styles__inlineBlock___KpHlz {
  position: relative;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./client/modules/markets/components/Table/styles/table-styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".spanBold {\n  font-weight: bold;\n}\n\n.inputAdornment {\n  position: relative;\n  top: 3px;\n}\n\n.listOfMarketsNameCellWrapper {\n  position: relative;\n  top: -15px;\n  height: 17px;\n}\n\n.inlineBlock {\n  position: relative;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spanBold": `client-modules-markets-components-Table-styles-___table-styles__spanBold___UaCs6`,
	"inputAdornment": `client-modules-markets-components-Table-styles-___table-styles__inputAdornment___yrT1u`,
	"listOfMarketsNameCellWrapper": `client-modules-markets-components-Table-styles-___table-styles__listOfMarketsNameCellWrapper___cBUvT`,
	"inlineBlock": `client-modules-markets-components-Table-styles-___table-styles__inlineBlock___KpHlz`
};
export default ___CSS_LOADER_EXPORT___;
