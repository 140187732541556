import {
  DeleteEntityDraftLogoRequest,
  Success,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const deleteEntityDraftLogo = (
  req: Partial<DeleteEntityDraftLogoRequest>
): Promise<Success> => {
  return request({
    url: '/service/logoservice/DeleteEntityDraftLogo',
    body: { ...req },
  }).then((r): Success => r.body);
};
