import { GetTeamResponse } from '@cbinsights/adminservice/adminservice';
import { UseQueryResult, useQuery } from 'react-query';
import { TeamServiceKeys } from '.';
import { getTeam } from '../api/GetTeam';

type Params = Parameters<typeof getTeam>[0];
type Return = GetTeamResponse;

export const useGetTeamQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [TeamServiceKeys, Params]>(
    ['GetTeam', params],
    () => getTeam(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

export default useGetTeamQuery;
