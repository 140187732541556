import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import { fetchUserSuspiciousSessions } from '../utils/api-request-helper';

export const UPDATE_PAGE_NUMBER =
  'client/modules/account-sharing-details/actions/session-data/UPDATE_PAGE_NUMBER';
export const UPDATE_RECORDS_PER_PAGE =
  'client/modules/account-sharing-details/actions/session-data/UPDATE_RECORDS_PER_PAGE';
export const SET_USER_SUSPICIOUS_SESSIONS =
  'client/modules/account-sharing-details/actions/session-data/SET_USER_SUSPICIOUS_SESSIONS';
export const RESET_USER_SUSPICIOUS_SESSIONS =
  'client/modules/account-sharing-details/actions/session-data/RESET_USER_SUSPICIOUS_SESSIONS';

export const updatePageNumber = (pageNumber) => {
  updateUrlParams('pageNumber', pageNumber);
  return { type: UPDATE_PAGE_NUMBER, pageNumber };
};

export const updateRecordsPerPage = (recordsPerPage) => {
  updateUrlParams('recordsPerPage', recordsPerPage);
  return { type: UPDATE_RECORDS_PER_PAGE, recordsPerPage };
};

export const resetUserSuspiciousSessionsData = () => {
  return { type: RESET_USER_SUSPICIOUS_SESSIONS };
};

export const fetchUserSuspiciousSessionsHelper = (
  payload,
  startDate,
  endDate
) => {
  return { type: SET_USER_SUSPICIOUS_SESSIONS, payload, startDate, endDate };
};

export const updateUserSuspiciousSessionsData = (
  searchId,
  searchType,
  startDate,
  endDate
) => {
  return (dispatch, getState) => {
    const {searchBar} = getState().accountSharingDetection;
    const realSearchType = searchType || searchBar.currentSearchType;
    // Only search when the type is User!
    if (parseInt(realSearchType, 10) !== 1) {
      return null;
    }
    const realSearchId = searchId || searchBar.searchId;
    const realStartDate = startDate || searchBar.startDate;
    const realEndDate = endDate || searchBar.endDate;

    return dispatch(
      fetchUserSuspiciousSessions(realSearchId, realStartDate, realEndDate)
    ).then((response) => {
      return dispatch(
        fetchUserSuspiciousSessionsHelper(response, realStartDate, realEndDate)
      );
    });
  };
};
