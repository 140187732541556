import { create } from 'zustand';

export const useFormikIsDirty = create<{
  isFormDirty: boolean;
  setFormIsDirty: (state: { isFormDirty: boolean }) => void;
}>((set) => ({
  isFormDirty: false,
  setFormIsDirty: (updatedState) =>
    set(() => ({ isFormDirty: updatedState.isFormDirty })),
}));
