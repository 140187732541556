import React, { ReactElement } from 'react';
import get from 'lodash/get';
import { useField, useFormikContext } from 'formik';

export const FieldErrorAndWarningMessage = ({
  name,
  className = '',
  title = '',
  showErrorOnLoad = false,
}: {
  name: string;
  className?: string;
  title?: string;
  showErrorOnLoad?: boolean;
}): ReactElement => {
  const [field, meta] = useField(name);
  const { values } = useFormikContext();
  const warning = get(values, `warnings.${field.name}`, '');
  return (
    <>
      {(meta.touched || showErrorOnLoad) && meta.error && (
        <div
          className={`${className} text-red-600 text-xs text-left`}
          title={title ? `${title}-error` : ''}
        >
          {meta.error}
        </div>
      )}
      {warning && (
        <div
          className={`${className} text-yellow-600 text-xs text-left`}
          title={title ? `${title}-warning` : ''}
        >
          {warning}
        </div>
      )}
    </>
  );
};
