import {
  ListUserTrialResponse,
  ListUserTrialRequest,
} from '@cbinsights/userv2service/userv2service';
import { useQuery, UseQueryResult } from 'react-query';
import { UserTrialServiceKeys } from '.';
import { listUserTrial } from '../api/ListUserTrial';

type Params = ListUserTrialRequest;
type Return = ListUserTrialResponse;

export const useGetUserTrialQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [UserTrialServiceKeys, number]>(
    ['ListPackages', params.id_user],
    () => listUserTrial(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
