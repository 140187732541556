import {
  AllAliasTypeValues,
  AllCountryValues,
  AllInvestorCategoryValues,
  AllSectorValues,
} from '@cbinsights/cbientityservice/cbientityservice';
import { UseQueryResult, useQuery } from 'react-query';
import { getAllAliasTypeValues } from '../apis/GetAllAliasTypeValues';
import { getAllCountryValues } from '../apis/GetAllCountryValues';
import { getAllInvestorCategoryValues } from '../apis/GetAllInvestorCategoryValues';
import { getAllSectorValues } from '../apis/GetAllSectorValues';
import { EntityServicesKeys } from './UseSearchForEntity';

type GeneralFormDependencies = {
  allAliasTypeValues: AllAliasTypeValues;
  allCountryValues: AllCountryValues;
  allInvestorCategoryValues: AllInvestorCategoryValues;
  allSectorValues: AllSectorValues;
};

export const useGeneralFormDependencies = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<GeneralFormDependencies, Error> =>
  useQuery<
    GeneralFormDependencies,
    Error,
    GeneralFormDependencies,
    [EntityServicesKeys]
  >(
    ['GeneralInfoDependencies'],
    () =>
      Promise.all([
        getAllAliasTypeValues(),
        getAllCountryValues(),
        getAllInvestorCategoryValues(),
        getAllSectorValues(),
      ]).then(
        ([
          allAliasTypeValues,
          allCountryValues,
          allInvestorCategoryValues,
          allSectorValues,
        ]) => ({
          allAliasTypeValues,
          allCountryValues,
          allInvestorCategoryValues,
          allSectorValues,
        })
      ),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );

export const useGetAllCountryValues = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<AllCountryValues, Error> =>
  useQuery<AllCountryValues, Error, AllCountryValues, [EntityServicesKeys]>(
    ['GetAllCountryValues'],
    () => getAllCountryValues(),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );

export const useGetAllSectorValues = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<AllSectorValues, Error> =>
  useQuery<AllSectorValues, Error, AllSectorValues, [EntityServicesKeys]>(
    ['GetAllSectorValues'],
    () => getAllSectorValues(),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'isFetchedAfterMount'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
