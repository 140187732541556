import { AllUserInfo } from '@cbinsights/userv2service/userv2service';
import { UseQueryResult, useQuery } from 'react-query';
import { GetUsersByEmailKey } from '.';
import { getUser } from '../api/GetUsersByEmail';

type Params = Parameters<typeof getUser>[0];
type Return = AllUserInfo;

export const useGetUsersByEmailQuery = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUsersByEmailKey, Params]>(
    ['GetUsersByEmail', params],
    () => getUser(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
