import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTES } from './constants';

const PeopleFormTable = React.lazy(
  () => import('./components/PeopleFormTable')
);
const PeopleNav = React.lazy(() => import('./components/PeopleNav'));

const { root, tableView } = ROUTES;

export const peopleRoutes: RouteObject = {
  path: root.path,
  children: [
    {
      path: root.uri,
      element: <Navigate to={tableView.uri} />,
    },
    {
      element: (
        <React.Suspense fallback={undefined}>
          <PeopleNav />
        </React.Suspense>
      ),

      children: [
        {
          path: tableView.path,
          element: (
            <React.Suspense fallback={undefined}>
              <PeopleFormTable />
            </React.Suspense>
          ),
        },
      ],
    },
  ],
};
