import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { DateTextField } from 'client/modules/common/components/TextField';
import { withStyles } from 'client/component-library/styles';

const styles = () => ({
  textField: {
    width: '128px',
  },
});

/** @deprecated This class is a wrapper around a DateTextField that only re-renders when the props change. */
class DateCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { name, value, id, classes, disabled, dataTest, ...props } =
      this.props;
    return (
      <DateTextField
        {...props}
        classes={classes}
        name={name}
        value={value}
        id={id}
        disabled={disabled}
        dataTest={dataTest}
      />
    );
  }
}

DateCell.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

DateCell.defaultProps = {
  dataTest: '',
  disabled: false,
  name: '',
  value: '',
  id: '',
  onChange: () => {},
};

/** @deprecated */
export default withStyles(styles)(DateCell);
