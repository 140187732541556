/**
 *
 * @param {Object} event - DOM event
 */
export function eventWasEnterKey(event) {
  return event.keyCode === 13;
}

export function eventWasDownKey(event) {
  return event.keyCode === 40;
}

export function eventWasTabKey(event) {
  return event.keyCode === 9;
}

export function eventWasShiftKey(event) {
  return event.keyCode === 16;
}

export function isAlphaNumeric(event) {
  return (
    (event.keyCode >= 48 && event.keyCode <= 57) ||
    (event.keyCode >= 65 && event.keyCode <= 90)
  );
}

export function eventWasSpacebarKey() {
  return false;
}
