import {
  GetRelationshipCountsResponse,
  GetRelationshipsRequest,
} from '@cbinsights/businessrelationshipservice/businessrelationshipservice';
import { request } from 'client/modules/common/utils/request';

export const getRelationshipsCounts = (
  req: Partial<GetRelationshipsRequest>
) => {
  return request({
    url: '/service/businessrelationshipservice/GetRelationshipCounts',
    body: req,
  }).then((r): GetRelationshipCountsResponse => r.body);
};
