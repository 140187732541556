import { SET_CURRENT_TAB } from '../actions/tab';
import { ACCOUNT_SHARING_DASHBOARD_TAB_NAME } from '../../utils/get-tab-options';

const initialState = ACCOUNT_SHARING_DASHBOARD_TAB_NAME;

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return action.currentTab;
    }
    default:
      return state;
  }
}
