// Use DefinePlugin (Webpack) or loose-envify (Browserify)
// together with Uglify to strip the dev branch in prod build.

import '@babel/polyfill';

import { configureProdStore } from './configure-store.prod';
import { configureDevStore } from './configure-store.dev';

export const configureStore = (reducer: $TSFixMe, initialState: $TSFixMe) =>
  process.env.NODE_ENV === 'production'
    ? configureProdStore(reducer, initialState)
    : configureDevStore(reducer, initialState);
