import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import TextField from 'client/component-library/TextField';
import { FormControl } from 'client/component-library/Form';

/** @deprecated This class is a React component that renders a text area */
class TextAreaCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      id,
      name,
      value,
      numberOfRows,
      classes,
      placeholder,
      textAreaWidth,
      width,
      maxWidth,
      maxLength,
      resize,
      height,
      disabled,
      dataTest,
    } = this.props;
    return (
      <FormControl>
        <TextField
          id={id}
          disabled={disabled}
          name={name}
          value={value}
          className={classes.input}
          rows={numberOfRows}
          placeholder={placeholder}
          inputProps={{
            maxLength,
            style: {
              fontSize: '0.9225rem',
              width: textAreaWidth || width,
              height,
              maxWidth,
              resize,
            },
          }}
          data-test={dataTest}
          multiline
        />
      </FormControl>
    );
  }
}

TextAreaCell.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.string,
  maxWidth: PropTypes.string,
  numberOfRows: PropTypes.number,
  resize: PropTypes.string,
  textAreaWidth: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTest: PropTypes.string,
};

TextAreaCell.defaultProps = {
  disabled: false,
  numberOfRows: 5,
  classes: {},
  id: '',
  name: '',
  value: '',
  placeholder: '',
  width: 'inherit',
  maxWidth: '600px',
  maxLength: '',
  resize: 'vertical',
  textAreaWidth: '',
  height: '',
  dataTest: '',
};

export default TextAreaCell;
