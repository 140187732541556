import { MarketReport } from './useMarketsList';

export const useGenerateMarketsCSV = () => {
  const mapDataToCSV = (data: MarketReport[]) => {
    return data
      .sort(
        (a, b) =>
          new Date(b.publishedDate).getTime() -
          new Date(a.publishedDate).getTime()
      )
      .map((market) => {
        const mainIndustry = market?.industries?.find(
          (el) => el.parentIndustryId === 0
        );
        const subIndustries = market?.industries?.filter(
          (el) => el.parentIndustryId !== 0
        );
        const subIndustry1 = subIndustries[0];
        const subIndustry2 = subIndustries[1];
        return {
          id_market: String(market.marketId),
          market_name: market.marketName,
          market_description: market.marketDescription,
          id_industry: mainIndustry?.industryId
            ? String(mainIndustry?.industryId)
            : '',
          industry: mainIndustry?.industryName || '',
          id_subindustry1: subIndustry1?.industryId
            ? String(subIndustry1?.industryId)
            : '',
          subindustry1: subIndustry1?.industryName || '',
          id_subindustry2: subIndustry2?.industryId
            ? String(subIndustry2?.industryId)
            : '',
          subindustry2: subIndustry2?.industryName || '',
          lastUpdated: market?.publishedDate,
        };
      });
  };

  const convertToCSV = (data: Record<string, string>[]) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) =>
      Object.values(row)
        .map((value: string) => (value?.includes(',') ? `"${value}"` : value))
        .join(',')
    );
    return `${header}\n${rows.join('\n')}`;
  };

  const handleGenerateCSV = (data: MarketReport[]) => {
    const mappedData = mapDataToCSV(data);
    const csvData = convertToCSV(mappedData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const csvName = `Markets-${new Date()
      .toISOString()
      .replace(/T|:|-|\./g, '-')
      .slice(0, -1)}.csv`;
    a.style.display = 'none';
    a.href = url;
    a.download = csvName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return { handleGenerateCSV };
};
