const styles = () => ({
  radio: {
    width: '1.3em',
    height: '1.3em',
    marginTop: '-4px',
    fontSize: '0.6rem',
  },
  sizeIcon: {
    height: '0.6em',
    width: '0.6em',
  },
  label: {
    marginTop: '14px',
    textAlign: 'center',
  },
  tableCell: {
    verticalAlign: 'inherit',
    color: '#404040',
    fontWeight: 'bold',
    padding: '0em 0.4em',
    paddingRight: '0 !important',
    textAlign: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

export default styles;
