/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
// https://react-select.com/home#getting-started
import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import CheckEmptyIcon from '@cbinsights/fds/lib/icons/react/CheckEmptyIcon';
import CheckFilledIcon from '@cbinsights/fds/lib/icons/react/CheckFilledIcon';
import { SimpleSelect, Input } from './SimpleSelect';

/* Renders The Selected Options Chips */
/**
 * @deprecated
 * If the user is typing, or the selected value is not the first option, don't render anything.
 * Otherwise, render the first option's label
 * @param props - The props passed to the component
 * @returns A function that returns a div
 */
function MultiValueContainer(props) {
  const {
    selectProps: { inputValue, value },
    data,
  } = props;

  const selectedValues = value || [];

  // Only Render 1st option and if user is not typing
  if (
    inputValue ||
    get(data, 'value', null) !== get(selectedValues, '[0].value', null)
  ) {
    return null;
  }

  const singleSelectionLabel =
    selectedValues[0].label ||
    selectedValues[0].name ||
    `(${selectedValues.length}) Selected`;

  return (
    <div
      className="absolute multiSelect__selectionText"
      style={{ top: 5, color: '#333333' }}
    >
      {selectedValues.length === 1
        ? singleSelectionLabel
        : `(${selectedValues.length}) Selected`}
    </div>
  );
}

/* Renders The Placeholder */
function Placeholder() {
  return null;
}

/* Renders The Option */
/**
 * @deprecated
 * It returns a div that contains a checkbox and the label of the option
 * @param props - {
 * @returns A div with a className of 'flex p-1 text-xs items-center'
 */
function Option(props) {
  const className = cx('flex p-1 text-xs items-center', {
    'bg-blue-200': props.isFocused,
    'cursor-pointer': !props.isDisabled,
    'cursor-not-allowed': props.isDisabled,
  });

  return (
    <div
      {...props.innerProps}
      className={className}
      data-test="multi-select-option"
    >
      <div className="pl-2">
        {props.isSelected ? (
          <CheckFilledIcon size="xs" />
        ) : (
          <CheckEmptyIcon size="xs" />
        )}
      </div>
      <div className="pl-1">{props.data.label}</div>
    </div>
  );
}

/**
 * @deprecated
 * It's a wrapper around the SimpleSelect component that adds some extra props and overrides some of
 * the default components
 * @param props - This is the props that are passed to the component.
 * @returns A SimpleSelect component with the following props:
 *   isMulti: true
 *   isSearchable: true
 *   hideSelectedOptions: false
 *   backspaceRemovesValue: false
 *   closeMenuOnSelect: false
 *   components: {
 *     MultiValueContainer,
 *     Placeholder,
 *     Option,
 *     Input,
 *   }
 */
export function MultiSelect(props) {
  return (
    <SimpleSelect
      isMulti
      isSearchable
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      {...props}
      components={{
        MultiValueContainer,
        Placeholder,
        Option,
        Input,
      }}
    />
  );
}
