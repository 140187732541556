import { combineReducers } from 'redux';
import currentTab from './tab';
import searchBars from './search-bars';
import tabData from './tab-data';
import tabDetails from './tab-details';
import allTeams from './all-teams';

const stories = combineReducers({
  currentTab,
  searchBars,
  tabData,
  tabDetails,
  allTeams,
});

export default stories;
