import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import TextField from 'client/component-library/TextField';
import { FormControl } from 'client/component-library/Form';

class MarketDescriptionCell extends Component {
  constructor(props) {
    super(props);

    this.showDescription = this.showDescription.bind(this);
    this.state = { showDescription: false };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  showDescription(e) {
    e.preventDefault();
    this.setState({ showDescription: true });
  }

  render() {
    const {
      id,
      name,
      value,
      numberOfRows,
      classes,
      placeholder,
      textAreaWidth,
      width,
      maxWidth,
      resize,
      height,
      disabled,
      dataTest,
    } = this.props;

    if (!value && !this.state.showDescription) {
      return (
        <a
          rel="noopener noreferrer"
          onClick={(e) => this.showDescription(e)}
          data-test={`${id}-add-description`}
          href="#addDescription"
          style={{ color: 'black' }}
        >
          + add description
        </a>
      );
    }

    return (
      <FormControl>
        <TextField
          id={id}
          disabled={disabled}
          name={name}
          value={value}
          className={classes.input}
          rows={numberOfRows}
          placeholder={placeholder}
          inputProps={{
            style: {
              fontSize: '0.9225rem',
              width: textAreaWidth || width,
              height,
              maxWidth,
              resize,
            },
          }}
          data-test={dataTest}
          multiline
        />
      </FormControl>
    );
  }
}

MarketDescriptionCell.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.string,
  numberOfRows: PropTypes.number,
  resize: PropTypes.string,
  textAreaWidth: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTest: PropTypes.string,
};

MarketDescriptionCell.defaultProps = {
  disabled: false,
  numberOfRows: 5,
  classes: {},
  name: '',
  value: '',
  id: '',
  placeholder: '',
  width: 'inherit',
  maxWidth: '600px',
  resize: 'vertical',
  textAreaWidth: '',
  height: '',
  dataTest: '',
};

export default MarketDescriptionCell;
