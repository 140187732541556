export const SET_ROLE_PERMISSIONS =
  'src/client/modules/third-party-admin/profile-queue/redux/actions/role-permissions/SET_ROLE_PERMISSIONS';

export const formatRolePermissions = (features) => {
  return {
    InternalPendingApproval: features.indexOf('InternalPendingApproval') !== -1,
    externalReview: features.indexOf('ExternalReview') !== -1,
    externalQAReview: features.indexOf('ExternalQAManager') !== -1,
  };
};

export const setRolePermissions = (idPage) => {
  return (dispatch) => {
    return dispatch({
      types: [null, null],
      service: {
        endpoint: 'GetUserAdminFeaturesByIdPageForRole',
        name: 'userv2service',
        body: { idPage },
      },
    }).then((res) => {
      const formattedRolePermissions = formatRolePermissions(res.features);
      return dispatch({
        type: SET_ROLE_PERMISSIONS,
        features: formattedRolePermissions,
      });
    });
  };
};
