/**
 * @summary The util function to use when we want to loging to Kibana, but can be extended to other places if needed.
 */
import { request } from './request';

/**
 * @param {String} [type = error] - Can be one of [error, warn, info, debug].
 * @param {String} message - The message you should like to show in Kibana.
 * @param {String} error - An Error Object or a String.
 * @param {String} [method] - Can be a module name, function name, component name, exc...
 * @param {String} [traceId] - We can use this if we want to have error relationships.
 */
export function logInfo(args) {
  const { message, error = {}, method, traceId, type = 'error' } = args;
  const errorDetails = error.message || error;

  if (typeof errorDetails !== 'string' && type === 'error') {
    return Promise.reject('error must be an error object or a string');
  }

  /* Leaving this so we can also see logs in console */
  console.log('AFS logInfo: ', args);

  return request({
    url: '/logInfo',
    body: {
      type,
      message,
      errorDetails,
      method,
      traceId,
    },
  }).then(({ body }) => body);
}
