// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__dropdownOuterDiv___t65f6 {
  position: relative;
  right: 5px;
}

.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__dropdownBox___mpk6o {
  max-height: 300px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #bbb;
  margin: -1px 5px 2px;
  border-radius: 0 0 3px 3px;
  overflow-y: scroll;
  text-align: left;
  z-index: 5;
}

.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__selectedItemDiv___kVx8V {
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  width: 415px;
  margin-left: 0px;
}

.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__autocompleteInput___Ri1yN {
  width: 200px;
  padding-left: 10px;
}

.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__fixedItemSelected___EnnyZ {
  color: white;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]};
}

.client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__fixedItemNotSelected___wVEX0 {
  color: #08c;
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/AutocompleteDropdown/styles/autocompleteDropdown.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,oBAAoB;EACpB,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,8DAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value blue from colors;\n\n.dropdownOuterDiv {\n  position: relative;\n  right: 5px;\n}\n\n.dropdownBox {\n  max-height: 300px;\n  position: absolute;\n  background-color: #fff;\n  border: 1px solid #bbb;\n  margin: -1px 5px 2px;\n  border-radius: 0 0 3px 3px;\n  overflow-y: scroll;\n  text-align: left;\n  z-index: 5;\n}\n\n.selectedItemDiv {\n  border-top: 1px solid #bbb;\n  border-left: 1px solid #bbb;\n  border-right: 1px solid #bbb;\n  width: 415px;\n  margin-left: 0px;\n}\n\n.autocompleteInput {\n  width: 200px;\n  padding-left: 10px;\n}\n\n.fixedItemSelected {\n  color: white;\n  background-color: blue;\n}\n\n.fixedItemNotSelected {\n  color: #08c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"blue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]}`,
	"dropdownOuterDiv": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__dropdownOuterDiv___t65f6`,
	"dropdownBox": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__dropdownBox___mpk6o`,
	"selectedItemDiv": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__selectedItemDiv___kVx8V`,
	"autocompleteInput": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__autocompleteInput___Ri1yN`,
	"fixedItemSelected": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__fixedItemSelected___EnnyZ`,
	"fixedItemNotSelected": `client-modules-common-components-AutocompleteDropdown-styles-___autocompleteDropdown__fixedItemNotSelected___wVEX0`
};
export default ___CSS_LOADER_EXPORT___;
