import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'client/component-library/icons/Icon';
import IconButton from 'client/component-library/IconButton';
import { withStyles } from 'client/component-library/styles';
import styles from './styles/css/action-icons.css';
import customStyles from './styles/material-ui/actionIcons';

/**
 * @deprecated
 * It renders an icon button with an icon and text
 * @param props - {
 * @returns A React component
 */
const BaseIcon = (props) => {
  const {
    disabled,
    dataTest,
    handleOnClick,
    iconButtonStyles,
    iconClassName,
    iconName,
    iconId,
    iconStyles,
    iconText,
    isIconBlock,
    customWrapperStyles,
    customWrapperClass,
    customIconClass,
    customSpanClass,
    customIconButtonClass,
    classes,
    disableRipple,
    children,
    isTabable,
  } = props;
  return (
    <div
      className={`${
        customWrapperClass || (isIconBlock ? styles.iconBlock : '')
      }`}
      style={customWrapperStyles}
    >
      <IconButton
        onClick={handleOnClick}
        disabled={disabled}
        className={customIconButtonClass}
        style={iconButtonStyles}
        classes={classes}
        disableRipple={disableRipple}
        data-test={`button-${dataTest}`}
        tabIndex={isTabable ? 0 : -1}
      >
        <Icon
          id={iconId || iconName}
          name={iconName}
          className={`${customIconClass || styles[iconClassName]}`}
          color={`${disabled ? 'disabled' : 'inherit'}`}
          family="material"
          styles={iconStyles}
          dataTest={dataTest}
        />
        {(iconText || children) && (
          <div
            className={`${customSpanClass || styles.iconLabel} ${
              disabled ? styles.disabled : ''
            }`}
          >
            {iconText}
            {children}
          </div>
        )}
      </IconButton>
    </div>
  );
};

BaseIcon.propTypes = {
  children: PropTypes.node,
  customWrapperClass: PropTypes.string,
  customIconClass: PropTypes.string,
  customSpanClass: PropTypes.string,
  customIconButtonClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  handleOnClick: PropTypes.func,
  iconButtonStyles: PropTypes.object,
  iconClassName: PropTypes.string,
  iconName: PropTypes.string,
  iconId: PropTypes.string,
  iconText: PropTypes.string,
  iconStyles: PropTypes.object,
  isIconBlock: PropTypes.bool,
  customWrapperStyles: PropTypes.object,
  disableRipple: PropTypes.bool,
  isTabable: PropTypes.bool,
};

BaseIcon.defaultProps = {
  children: null,
  customWrapperClass: '',
  customIconClass: '',
  customSpanClass: '',
  customIconButtonClass: '',
  handleOnClick: () => {},
  disabled: false,
  dataTest: '',
  iconButtonStyles: {},
  iconClassName: '',
  iconName: '',
  iconId: '',
  iconStyles: {},
  iconText: '',
  isIconBlock: true,
  customWrapperStyles: {},
  disableRipple: false,
  isTabable: true,
};

export default withStyles(customStyles)(BaseIcon);
