import { map, forEach, sortBy, reduce, keyBy } from 'lodash';

const numberFields = ['quota', 'resultsPerPage', 'totalResults'];

export function getCurrentTab(callback) {
  return (dispatch, getState) => {
    const {currentTab} = getState().packages;
    return dispatch(callback(currentTab));
  };
}

export function getPackageCommonInfo(response) {
  return {
    id: response.id,
    annual: response.annual ? 'yes' : 'no',
    duration: response.duration,
    name: response.name,
    packageType: response.packageType,
    numberOfUsers: response.numberOfUsers,
  };
}

export function getPackageFeatureInfo(response, featuresInfo) {
  return map(response.featureInfo, (feature) => {
    return {
      ...feature,
      quota: feature.quota.toString(),
      resultsPerPage: feature.resultsPerPage.toString(),
      totalResults: feature.totalResults.toString(),
      access: feature.access ? 'yes' : 'no',
      description: featuresInfo[feature.id]
        ? featuresInfo[feature.id].description
        : '',
    };
  });
}

export function setFeatureDescription(response, featuresInfo) {
  return map(response.featureInfo, (feature) => {
    return {
      ...feature,
      description: featuresInfo[feature.id]
        ? featuresInfo[feature.id].description
        : '',
    };
  });
}

export function updatePackageCommonInfo(info, key, value) {
  return {
    ...info,
    [key]: value,
  };
}

export function sortList(records, sortKey, sortDirection) {
  const sortedRecords = sortBy(records, [
    (item) => {
      if (numberFields.indexOf(sortKey) !== -1) {
        return parseInt(item[sortKey], 10) || 0;
      }
      return item[sortKey];
    },
  ]);
  const sortedIds = map(sortedRecords, (row) => row.id);
  return sortDirection === 'desc' ? sortedIds.reverse() : sortedIds;
}

export function mapPackagesWithTheFeature(response) {
  return map(response, (record) => {
    return {
      ...record,
      id: record.idPackage,
      access: record.access ? 'yes' : 'no',
      quota: record.quota.toString(),
      resultsPerPage: record.resultsPerPage.toString(),
      totalResults: record.totalResults.toString(),
    };
  });
}

export function createRecordStyles(records) {
  const accessStyles = {
    background: '#dff0d8',
    borderTop: '1px solid darkgrey',
  };
  const noAccessStyles = {
    background: '#f2dede',
    borderTop: '1px solid darkgrey',
  };
  const recordStyles = {};
  forEach(records, (record) => {
    recordStyles[record.id] =
      record.access === 'yes' ? accessStyles : noAccessStyles;
  });
  return recordStyles;
}

export function createRecordCellStyles(records) {
  const accessStyles = {
    background: '#dff0d8',
    borderTop: '1px solid darkgrey',
  };
  const noAccessStyles = {
    background: '#f2dede',
    borderTop: '1px solid darkgrey',
  };
  const recordCellStyles = {};
  forEach(records, (record) => {
    recordCellStyles[record.id] = {};
    recordCellStyles[record.id].quota =
      record.access === 'yes' ? accessStyles : noAccessStyles;
    recordCellStyles[record.id].secondQuota =
      record.secondAccess === 'yes' ? accessStyles : noAccessStyles;
  });
  return recordCellStyles;
}

export function addToPreviousRecords(firstPackage, secondPackage) {
  return map(firstPackage, (features) => {
    let access = 'no';
    if (secondPackage[features.id] && secondPackage[features.id].access) {
      access = secondPackage[features.id].access;
    }
    return {
      ...features,
      secondQuota: secondPackage[features.id]
        ? secondPackage[features.id].quota.toString()
        : 'N/A',
      secondAccess: access,
    };
  });
}

export function updateCompareRecords(oldFeatures, newFeatures) {
  return map(newFeatures, (feature) => {
    return {
      ...feature,
      secondQuota: oldFeatures[feature.id]
        ? oldFeatures[feature.id].secondQuota
        : 'N/A',
      secondAccess: oldFeatures[feature.id]
        ? oldFeatures[feature.id].secondAccess
        : 'no',
    };
  });
}

export function diffRecords(records, diff) {
  if (!diff) {
    return records;
  }
  return keyBy(
    reduce(
      records,
      (result, value) => {
        if (
          value.quota !== value.secondQuota ||
          value.access !== value.secondAccess
        ) {
          result.push(value);
        }
        return result;
      },
      []
    ),
    'id'
  );
}

export default getCurrentTab;
