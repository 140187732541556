// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__displayNewsContentWrapper___UpdTP {
  white-space: pre-wrap;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__relative___YzLRR {
  position: relative;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__cancelIcon___XKBcW {
  font-size: 16px !important;
  color: red;
  position: relative;
  top: 3px;
  left: 2px;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__closeArticle___q9djw {
  font-size: 16px !important;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]};
  position: relative;
  top: 3px;
  left: 2px;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__inline___mGd1g {
  display: inline-block;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__inlineHref___Y1nQW {
  display: inline-block;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]};
  text-decoration: none;
}

.client-modules-common-components-DisplayNewsContent-styles-___display-news-content__noContent___Cw2N7 {
  /* background-image: url('/admin-frontend-service-images/noContent.jpg'); */
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  margin-top: 80px;
  background-size: 100%;
}

.${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]} {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]};
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/DisplayNewsContent/styles/display-news-content.css"],"names":[],"mappings":"AAGA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,mDAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,mDAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,2EAA2E;EAC3E,4BAA4B;EAC5B,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,mDAAW;AACb","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value blue from colors;\n\n.displayNewsContentWrapper {\n  white-space: pre-wrap;\n}\n\n.relative {\n  position: relative;\n}\n\n.cancelIcon {\n  font-size: 16px !important;\n  color: red;\n  position: relative;\n  top: 3px;\n  left: 2px;\n}\n\n.closeArticle {\n  font-size: 16px !important;\n  color: blue;\n  position: relative;\n  top: 3px;\n  left: 2px;\n}\n\n.inline {\n  display: inline-block;\n}\n\n.inlineHref {\n  display: inline-block;\n  color: blue;\n  text-decoration: none;\n}\n\n.noContent {\n  /* background-image: url('/admin-frontend-service-images/noContent.jpg'); */\n  background-repeat: no-repeat;\n  height: 450px;\n  width: 100%;\n  margin-top: 80px;\n  background-size: 100%;\n}\n\n.blue {\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"blue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"]}`,
	"displayNewsContentWrapper": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__displayNewsContentWrapper___UpdTP`,
	"relative": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__relative___YzLRR`,
	"cancelIcon": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__cancelIcon___XKBcW`,
	"closeArticle": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__closeArticle___q9djw`,
	"inline": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__inline___mGd1g`,
	"inlineHref": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__inlineHref___Y1nQW`,
	"noContent": `client-modules-common-components-DisplayNewsContent-styles-___display-news-content__noContent___Cw2N7`
};
export default ___CSS_LOADER_EXPORT___;
