import { SetTeamTypeRequest_TeamType } from '@cbinsights/userv2service/userv2service';
import { useMutation } from 'react-query';
import { setTeamType } from '../services/api/SetTeamType';

type Props = {
  teamId: number;
};

const useSetTeamType = ({ teamId }: Props) => {
  const setTeamTypeQuery = useMutation(setTeamType);

  const setCopilotTeam = () => {
    return setTeamTypeQuery.mutateAsync({
      team_type: SetTeamTypeRequest_TeamType.COPILOT,
      id_team: teamId,
    });
  };

  const setRegularTeam = () => {
    return setTeamTypeQuery.mutateAsync({
      team_type: SetTeamTypeRequest_TeamType.REGULAR,
      id_team: teamId,
    });
  };

  return {
    setCopilotTeam,
    setRegularTeam,
    isLoading: setTeamTypeQuery.isLoading,
  };
};

export default useSetTeamType;
