import {
  isReviewQueue,
  isPendingQueue,
  isQAQueue,
  FILTER_RESTRICTIONS,
} from './constants';

export function setToastError(message) {
  if (window.controllers) {
    window.controllers.GeneralController.setFailure(message);
  }
}

export function setToastSuccess(message) {
  if (window.controllers) {
    window.controllers.GeneralController.setSuccess(message);
  }
}

export function batchRequest(request) {
  let requestBodyQ = [];
  let timeout = null;
  let promiseQ = [];

  return (body) => {
    requestBodyQ.push(body);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      const newPromiseQ = [...promiseQ];

      request(requestBodyQ)
        .then((res) => {
          newPromiseQ.forEach((promise) => setTimeout(() => promise[0](res)));
        })
        .catch((res) => {
          newPromiseQ.forEach((promise) => setTimeout(() => promise[1](res)));
        });

      promiseQ = [];
      requestBodyQ = [];
    }, 500);

    return new Promise((resolve, reject) => {
      promiseQ.push([resolve, reject]);
    });
  };
}

export function getValidationErrorMessage(status) {
  const errorMessage = (step) => {
    return `Error ${
      step || 'submitting'
    } record. Please resolve all errors within the form.`;
  };

  if (status === 'approve') {
    if (isReviewQueue() || isQAQueue()) {
      return errorMessage('approving');
    }
    if (isPendingQueue()) {
      return errorMessage('publishing');
    }
  }

  if (status === 'reject') {
    if (isReviewQueue() || isQAQueue()) {
      return errorMessage('rejecting');
    }
    if (isPendingQueue()) {
      return errorMessage('archiving');
    }
  }

  return errorMessage();
}

export function preserveFilters(name, query) {
  let filterValues = {};

  /* preserve the current filters in the url */
  const allowed = FILTER_RESTRICTIONS[name];
  if (allowed) {
    filterValues = Object.keys(query)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = query[key];
        return obj;
      }, {});
  }

  /* preserve the limit if in the url */
  if (query.limit) {
    filterValues.limit = query.limit;
  }

  /* preserve filter/search during limit change */
  if (name === 'limit') {
    filterValues = {
      ...query,
    };
  }
  return filterValues;
}
