import { findIndex, filter, forEach, map } from 'lodash/fp';
import moment from 'moment';
import cbiUtils, { handleAsyncError } from 'client/modules/common/utils/cbi';
import {
  setSuccessNotification,
  setFailureNotification,
} from 'client/modules/common/redux/actions/status-notification';

export const NOT_REVIEWED = 'Not Reviewed';
export const DISCARDED = 'Discarded';
export const ARCHIVED = 'Archived';
export const BLOCKED_CIKS = 'Blocked CIKs';

export const RSS_NEWS = 'rssNews';
export const SEC = 'sec';

const sourceFilters = [
  { type: RSS_NEWS, text: 'Articles' },
  { type: SEC, text: 'SEC Forms' },
];

const categoryFilters = [
  { type: NOT_REVIEWED, text: 'Not Reviewed' },
  { type: DISCARDED, text: 'Discarded' },
  { type: ARCHIVED, text: 'Archived' },
];

const articleTypeFilters = [
  { id: 1, name: 'Funding' },
  { id: 2, name: 'M&A' },
];

export const UNBLOCK_CIK = 'Unblock CIK';
export const RESTORE_ARTICLE = 'Restore Article';
export const DISCARD_ARTICLE = 'Discard Article';

export const SET_MANAGEMENT_QUEUE =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_MANAGEMENT_QUEUE';
export const SET_CATEGORY_FILTER =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/UPDATE_CATEGORY_FILTER';
export const SET_SOURCE_FILTER =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/UPDATE_SOURCE_FILTER';
export const SET_ARTICLE_TYPE_FILTER =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_ARTICLE_TYPE_FILTER';
export const UPDATE_LANGUAGE_FILTER =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/UPDATE_LANGUAGE_FILTER';
export const SET_FUNDING_CLASSIFIER_FILTER_STATUS =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_FUNDING_CLASSIFIER_FILTER_STATUS';
export const SET_LANGUAGE_FILTERS =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_LANGUAGE_FILTERS';
export const LOADING_NEWS = 'ADMIN/NEWS/QUEUE/MANAGEMENT/LOADING_NEWS';
export const TOGGLE_DATE_SORT = 'ADMIN/NEWS/QUEUE/MANAGEMENT/TOGGLE_DATE_SORT';
export const SET_TITLE_SEARCH_TEXT =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_TITLE_SEARCH_TEXT';
export const SET_SOURCE_SEARCH_TEXT =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_SOURCE_SEARCH_TEXT';
export const DONE_LOADING_NEWS =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/DONE_LOADING_NEWS';
export const SET_WEEK_SELECTION =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/SET_WEEK_SELECTION';
export const REMOVE_ARTICLE_FROM_QUEUE =
  'ADMIN/NEWS/QUEUE/MANAGEMENT/REMOVE_ARTICLE_FROM_QUEUE';

export function toggleDateSort() {
  return { type: TOGGLE_DATE_SORT };
}

export function setTitleSearchText(text) {
  return { type: SET_TITLE_SEARCH_TEXT, text };
}

export function setSourceSearchText(text) {
  return { type: SET_SOURCE_SEARCH_TEXT, text };
}

export function setWeekSelection(date) {
  return { type: SET_WEEK_SELECTION, date };
}

export function setManagementQueue(queue) {
  return { type: SET_MANAGEMENT_QUEUE, queue };
}

export function setCategoryFilter(categoryFilter) {
  return { type: SET_CATEGORY_FILTER, categoryFilter };
}

export function setSourceFilter(sourceFilter) {
  return { type: SET_SOURCE_FILTER, sourceFilter };
}

export function setArticleTypeFilter(articleTypeFilter) {
  return { type: SET_ARTICLE_TYPE_FILTER, articleTypeFilter };
}

export function updateLanguageFilter(idLanguageFilter) {
  return { type: UPDATE_LANGUAGE_FILTER, idLanguageFilter };
}

export function setFundingClassifierFilterStatus(
  isEnabledFundingClassiferFilter
) {
  return {
    type: SET_FUNDING_CLASSIFIER_FILTER_STATUS,
    isEnabledFundingClassiferFilter,
  };
}

export function setLanguageFilters(languageFilters) {
  // Add the default 'All Languages' filter to the front of the filters
  let updatedLanguageFilters = [
    { name: 'All Languages', id: -1 },
    ...languageFilters,
  ];

  // Remove the word "term" from the filer names
  updatedLanguageFilters = map((langFilter) => {
    return {
      ...langFilter,
      name: langFilter.name.replace('Terms', ''),
    };
  }, updatedLanguageFilters);
  return {
    type: SET_LANGUAGE_FILTERS,
    languageFilters: updatedLanguageFilters,
  };
}

export function loadingNews() {
  return { type: LOADING_NEWS };
}

export function doneLoadingNews() {
  return { type: DONE_LOADING_NEWS };
}

export function removeArticleFromQueue(id) {
  return { type: REMOVE_ARTICLE_FROM_QUEUE, id };
}

export function fetchNewsForManagement() {
  return (dispatch, getState) => {
    const state = getState();
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue-management',
        method: 'GET',
        data: {
          type: state.newsQueue.management.categoryFilter,
          source: state.newsQueue.management.sourceFilter,
          startDate: state.newsQueue.management.weekSelection.value.startDate,
          endDate: state.newsQueue.management.weekSelection.value.endDate,
          articleType: state.newsQueue.management.selectedArticleTypeFilter.id,
        },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((json) => {
        dispatch(setManagementQueue(json));
        dispatch(doneLoadingNews());
      });
  };
}

export function fetchLanguageFilters() {
  return (dispatch) => {
    return cbiUtils
      .apiCall({
        url: 'admin/news/filter-groups',
        method: 'GET',
        data: {},
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then((json) => {
        dispatch(setLanguageFilters(json));
      });
  };
}

export function fetchFundingClassifierFilterStatus() {
  return (dispatch) => {
    return cbiUtils
      .apiCall({
        url: '/admin/news/queue_preferences',
        method: 'GET',
        data: {
          name: 'funding_classifier',
        },
      })
      .then((response) => {
        dispatch(handleAsyncError(response)).then((json) => {
          dispatch(setFundingClassifierFilterStatus(json.isEnabled));
        });
      });
  };
}

export function updateFundingClassifierFilterStatus(
  isEnabledFundingClassiferFilter
) {
  return (dispatch, getState) => {
    dispatch(loadingNews());
    const state = getState();
    return cbiUtils
      .apiCall({
        url: '/admin/news/queue_preferences',
        method: 'PUT',
        data: {
          name: 'funding_classifier',
          idUser: state.user.idUser,
          isEnabled: isEnabledFundingClassiferFilter,
        },
      })
      .then((response) => {
        dispatch(handleAsyncError(response)).then((json) => {
          dispatch(setFundingClassifierFilterStatus(json.isEnabled));
          dispatch(fetchNewsForManagement());
          dispatch(doneLoadingNews());
        });
      });
  };
}

export function restoreArticle(id) {
  return (dispatch, getState) => {
    const state = getState();
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue-management',
        method: 'PATCH',
        data: {
          id,
          source: state.newsQueue.management.sourceFilter,
          action: RESTORE_ARTICLE,
        },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        dispatch(removeArticleFromQueue(id));
        dispatch(setSuccessNotification());
      });
  };
}

export function unblockCIK(id, cik) {
  return (dispatch) =>
    cbiUtils
      .apiCall({
        url: 'admin/news/queue-management',
        method: 'PATCH',
        data: { id: parseInt(cik, 10), action: UNBLOCK_CIK },
      })
      .then((response) => dispatch(handleAsyncError(response)))
      .then(() => {
        dispatch(removeArticleFromQueue(id));
        dispatch(setSuccessNotification());
      });
}

export function discardItem(id) {
  return (dispatch, getState) => {
    const state = getState();
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue-management',
        method: 'PATCH',
        data: {
          id,
          source: RSS_NEWS,
          action: DISCARD_ARTICLE,
          idUser: state.user.idUser,
        },
      })
      .then((response) => {
        return dispatch(handleAsyncError(response));
      })
      .then((json) => {
        if (json.success) {
          dispatch(setSuccessNotification());
        } else {
          dispatch(
            setFailureNotification('Article already assigned. Reloading...')
          );
        }
        dispatch(removeArticleFromQueue(id));
      });
  };
}

export function archiveItem(id) {
  return (dispatch) => {
    return cbiUtils
      .apiCall({
        url: 'admin/news/queue',
        method: 'PATCH',
        data: {
          ids: [id],
          source: RSS_NEWS,
          action: 'archive',
        },
      })
      .then((response) => {
        return dispatch(handleAsyncError(response));
      })
      .then((json) => {
        if (json.success) {
          dispatch(setSuccessNotification());
        } else {
          dispatch(
            setFailureNotification('Article already assigned. Reloading...')
          );
        }
        dispatch(removeArticleFromQueue(id));
      });
  };
}

export function updateCategoryFilter(categoryFilter) {
  return (dispatch) => {
    dispatch(loadingNews());
    dispatch(setCategoryFilter(categoryFilter));
    dispatch(fetchNewsForManagement());
  };
}

export function updateSourceFilter(sourceFilter) {
  return (dispatch) => {
    dispatch(loadingNews());
    dispatch(setSourceFilter(sourceFilter));
    dispatch(fetchNewsForManagement());
  };
}

export function updateQueueWeek(date) {
  return (dispatch) => {
    dispatch(loadingNews());
    dispatch(setWeekSelection(date));
    dispatch(fetchNewsForManagement());
  };
}

export function updateArticleTypeFilter(articleTypeFilter) {
  return (dispatch) => {
    dispatch(loadingNews());
    dispatch(setArticleTypeFilter(articleTypeFilter));
    dispatch(fetchNewsForManagement());
  };
}

function generateWeekOptions() {
  const start = moment().utc().startOf('isoWeek');
  const end = moment().utc().subtract(90, 'days').startOf('isoWeek');

  const dateOptions = [];

  dateOptions.push({
    name: 'Current Week',
    value: {
      startDate: start.format('YYYY-MM-DD'),
      endDate: moment().utc().format('YYYY-MM-DD'),
    },
  });

  const lastTwelveWeeks = {
    name: 'Last 12 Weeks',
    value: {
      startDate: end.format('YYYY-MM-DD'),
      endDate: start.format('YYYY-MM-DD'),
    },
  };

  start.subtract(7, 'days');
  while (start >= end) {
    dateOptions.push({
      name: `Week of ${start.format('MMMM DD YYYY')}`,
      value: {
        startDate: start.format('YYYY-MM-DD'),
        endDate: moment(start).add(6, 'days').format('YYYY-MM-DD'),
      },
    });
    start.subtract(7, 'days');
  }

  dateOptions.push(lastTwelveWeeks);
  return dateOptions;
}

export function getLanguageFilterFromId(languageFilters, idLanguageFilter) {
  let languageFilter = { name: 'All Languages', id: -1 };
  forEach((langFilterInfo) => {
    if (langFilterInfo.id === idLanguageFilter) {
      languageFilter = langFilterInfo;
    }
  }, languageFilters);
  return languageFilter;
}

export default function newsQueueManagement(
  state = {
    sourceFilters,
    categoryFilters,
    articleTypeFilters,
    sourceFilter: sourceFilters[0].type,
    categoryFilter: categoryFilters[0].type,
    languageFilter: { name: 'All Languages', id: -1 },
    idLanguageFilter: -1,
    languageFilters: [{ name: 'All Languages', id: -1 }],
    selectedArticleTypeFilter: articleTypeFilters[0],
    queue: [],
    isLoading: true,
    dateSort: 'desc',
    titleSearchText: '',
    sourceSearchText: '',
    weekOptions: generateWeekOptions(),
    weekSelection: generateWeekOptions()[0],
  },
  action
) {
  switch (action.type) {
    case SET_MANAGEMENT_QUEUE: {
      return { ...state, queue: action.queue };
    }
    case SET_SOURCE_FILTER: {
      let newCategoryFilters;
      let newCategoryFilter = state.categoryFilter;

      if (action.sourceFilter === SEC) {
        newCategoryFilters = [
          ...state.categoryFilters,
          { type: BLOCKED_CIKS, text: 'Blocked CIKs' },
        ];
      } else {
        newCategoryFilters = filter(
          (category) => category.type !== BLOCKED_CIKS,
          state.categoryFilters
        );

        if (state.categoryFilter === BLOCKED_CIKS) {
          newCategoryFilter = newCategoryFilters[0].type;
        }
      }

      return {
        ...state,
        sourceFilter: action.sourceFilter,
        categoryFilters: newCategoryFilters,
        categoryFilter: newCategoryFilter,
      };
    }
    case SET_LANGUAGE_FILTERS: {
      return {
        ...state,
        languageFilters: action.languageFilters,
      };
    }
    case SET_ARTICLE_TYPE_FILTER: {
      return {
        ...state,
        selectedArticleTypeFilter: action.articleTypeFilter,
      };
    }
    case UPDATE_LANGUAGE_FILTER: {
      const languageFilter = getLanguageFilterFromId(
        state.languageFilters,
        action.idLanguageFilter
      );
      return {
        ...state,
        languageFilter,
        idLanguageFilter: action.idLanguageFilter,
      };
    }
    case SET_CATEGORY_FILTER: {
      return { ...state, categoryFilter: action.categoryFilter };
    }
    case REMOVE_ARTICLE_FROM_QUEUE: {
      const index = findIndex((item) => item.id === action.id)(state.queue);
      const queue = [
        ...state.queue.slice(0, index),
        ...state.queue.slice(index + 1),
      ];
      if (index === -1) return state;
      return { ...state, queue };
    }
    case LOADING_NEWS: {
      return { ...state, isLoading: true };
    }
    case DONE_LOADING_NEWS: {
      return { ...state, isLoading: false };
    }
    case TOGGLE_DATE_SORT: {
      const sort = state.dateSort === 'asc' ? 'desc' : 'asc';
      return { ...state, dateSort: sort };
    }
    case SET_TITLE_SEARCH_TEXT: {
      return { ...state, titleSearchText: action.text };
    }
    case SET_SOURCE_SEARCH_TEXT: {
      return { ...state, sourceSearchText: action.text };
    }
    case SET_WEEK_SELECTION: {
      return { ...state, weekSelection: action.date };
    }
    default:
      return state;
  }
}
