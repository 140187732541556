import React, { ReactElement } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Col, Input, Row, Table } from 'antd';
import { TeamMemberData } from '../../hooks/useTeamMembers';
import useShowPagination from '../../hooks/useShowPagination';
import DownloadTeam from './DownloadTeam';

type Props = {
  columns: ColumnsType<TeamMemberData>;
  data: TeamMemberData[];
  idTeam: number;

  onSetTeamMemberFilter: (string) => void;
};

export const TeamMembers = ({
  columns,
  data,
  idTeam,
  onSetTeamMemberFilter,
}: Props): ReactElement => {
  const showPagination = useShowPagination({ data, config: { pageSize: 25 } });

  return (
    <>
      <Row className="mb-3" justify="end">
        <Col className="mr-2">
          <DownloadTeam members={data} idTeam={idTeam} />
        </Col>
        <Col span={4}>
          <Input
            placeholder="Search for Users on Team"
            onChange={(e) => onSetTeamMemberFilter(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-10">
        <Col xs={{ span: 24 }}>
          <Table
            bordered
            columns={columns}
            dataSource={data}
            onRow={(record) => ({
              style: {
                background: record.expired ? '#fff2f0' : '',
              },
            })}
            pagination={showPagination}
          />
        </Col>
      </Row>
    </>
  );
};

export default TeamMembers;
