import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Radio from 'client/component-library/Radio/MuiRadio';
import RadioButtonUncheckedIcon from 'client/component-library/Radio/RadioButtonUncheckedIcon';
import RadioButtonCheckedIcon from 'client/component-library/Radio/RadioButtonCheckedIcon';
import { withStyles } from 'client/component-library/styles';

const styles = () => ({
  radio: {
    width: '1.3em',
    height: '1.3em',
    marginTop: '-4px',
    fontSize: '0.6rem',
  },
  sizeIcon: {
    height: '0.6em',
    width: '0.6em',
  },
  radioWrapper: {
    verticalAlign: 'inherit',
    color: '#404040',
    fontWeight: 'bold',
    padding: '0em 0.4em',
    paddingRight: '0 !important',
    textAlign: 'center',
  },
});

/** @deprecated RadioCell is a React component that renders a radio button */
class RadioCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, value, classes, radioDisabled, dataTest, checkValue, name } =
      this.props;

    return (
      <div key={dataTest} className={classes.radioWrapper}>
        <Radio
          className={classes.radio}
          id={id}
          checked={value === checkValue}
          value={checkValue.toString()}
          disabled={radioDisabled}
          data-test={`${value}-selected-${dataTest}-${checkValue}-radio`}
          name={name}
          color="default"
          icon={<RadioButtonUncheckedIcon className={classes.sizeIcon} />}
          checkedIcon={<RadioButtonCheckedIcon className={classes.sizeIcon} />}
        />
      </div>
    );
  }
}

RadioCell.propTypes = {
  radioDisabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataTest: PropTypes.string,
  checkValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  name: PropTypes.string,
};

RadioCell.defaultProps = {
  radioDisabled: false,
  id: '',
  value: '',
  dataTest: '',
  name: '',
};

/** @deprecated */
export default withStyles(styles)(RadioCell);
