import {
  getQueryParams,
  getSearchFromQueryParams,
} from 'client/routes/useQueryParams';
import { history } from './historyWrapper';
/**
 *
 * @deprecated use useQuery instead, should not use history library directly
 *
 * @param {string} [paramName='query param name']
 * @param {string} [value='query param value']
 * @param {object} [arrayParams={current query params value in object format}]
 * @return {object} - new query param in object format
 */
export const updateUrlParams = (
  paramName = '',
  value = '',
  arrayParams = {}
) => {
  if (window.isTestEnv) {
    return null;
  }
  const { location } = window;
  const search = getSearchFromQueryParams({
    ...getQueryParams(location.search),
    ...arrayParams,
    [paramName]: value,
  });

  history.replace({
    ...location,
    search,
  });

  return { [paramName]: value };
};
