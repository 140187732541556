import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistState } from '@redux-devtools/core';
import createImmutableInvariant from 'redux-immutable-state-invariant';
import apiMiddleware from 'client/modules/common/redux/middleware/apiMiddleware';
import serviceMiddleware from 'client/modules/common/redux/middleware/serviceMiddleware';
import DevTools from '../DevTools';

function getDebugSessionKey() {
  // You can write custom logic here!
  // By default we try to read the key from ?debug_session=<key> in the address bar
  const matches = window.location.href.match(/[?&]debug_session=([^&]+)\b/);
  return matches && matches.length > 0 ? matches[1] : null;
}

function configureDevStore(reducer, initialState) {
  const loggerMiddleware = createLogger();
  const immutableInvariantMiddleware = createImmutableInvariant();
  const middleware = [
    apiMiddleware,
    serviceMiddleware,
    thunkMiddleware,
    loggerMiddleware,
    immutableInvariantMiddleware,
  ];

  let enhancers = [
    applyMiddleware(...middleware),
    persistState(getDebugSessionKey()),
  ];

  if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers = [...enhancers, DevTools.instrument()];
  }

  const composeEnhancers = composeWithDevTools({
    name: 'ADMIN_FRONTEND',
    secure: false,
    suppressConnectErrors: false,
    sendOn: 'DO_REPORT_ERROR',
    sendOnError: 1,
    realtime: true,
  });
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  window.store = store;

  return store;
}

export { configureDevStore };
