import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const CreateNewCollection = React.lazy(
  () => import('./containers/CreateNewCollection')
);

export const collectionsRoutes: RouteObject = {
  path: 'collections',
  children: [
    {
      path: '/collections',
      element: <Navigate to="/collections/collection-creation" />,
    },
    {
      path: 'collection-creation',
      element: (
        <React.Suspense fallback={undefined}>
          <CreateNewCollection />
        </React.Suspense>
      ),
    },
  ],
};
