// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-virtual-list .ant-select-item-option:has(.is-primary),.is-primary .ant-select-selector{
    background-color: rgba(198, 246, 213 ) !important;

}
.rc-virtual-list .ant-select-item-option:has(.not-primary),.not-primary .ant-select-selector{
    background-color: rgba(254, 252, 191) !important;

}`, "",{"version":3,"sources":["webpack://./client/modules/cbi-entity/containers/pages/financials/SearchableTickerField.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;;AAErD;AACA;IACI,gDAAgD;;AAEpD","sourcesContent":[":global .rc-virtual-list .ant-select-item-option:has(.is-primary),:global  .is-primary .ant-select-selector{\n    background-color: rgba(198, 246, 213 ) !important;\n\n}\n:global .rc-virtual-list .ant-select-item-option:has(.not-primary),:global .not-primary .ant-select-selector{\n    background-color: rgba(254, 252, 191) !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
