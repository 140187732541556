import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React, { FunctionComponent, ReactElement } from 'react';
import { Location } from 'history';
import { QueryParams, useQueryParams } from './useQueryParams';

export type RoutedComponent = {
  updateQueryParams: (param: QueryParams) => void;
  queryParams: QueryParams;
  params: Readonly<Params<string>>;
  location: Location;
  navigation: NavigateFunction;
};

/**
 * withReactRouter it's a HOC, that replaces withRouter HOC, it wraps objects from react router hooks
 * @param Component: component that will be wrapped with router objects
 * @returns wrapped components following props
 * @property {function} updateQueryParams - function to update query param
 * @property {object} queryParams         - conversion from URL search, into an object
 * @property {object} params              - params defined in react router route
 * @property {function} navigation        - functions to change between routes (defined in react router routes) in app
 * @property {object} location            - current location object
 */
export const withReactRouter = <
  TComponentProps extends Record<string, unknown>
>(
  Component: FunctionComponent<RoutedComponent & TComponentProps>
) => {
  return (props: TComponentProps): ReactElement => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigation = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const params = useParams();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { queryParams, updateQueryParams } = useQueryParams();
    return (
      <Component
        updateQueryParams={updateQueryParams}
        queryParams={queryParams}
        params={params}
        location={location}
        navigation={navigation}
        {...props}
      />
    );
  };
};
