import {
  SetTeamUsersQuotasRequest,
  SuccessStatus,
} from '@cbinsights/userv2service/userv2service';
import { request } from 'client/modules/common/utils/request';

export const setTeamUsersQuotas = (
  req: Partial<SetTeamUsersQuotasRequest>
): Promise<SuccessStatus> =>
  request({
    url: '/service/userv2service/SetTeamUsersQuotas',
    body: req,
  }).then((r) => r.body);
