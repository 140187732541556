import { keyBy } from 'lodash';
import { createRecordCellStyles } from 'client/modules/data-platform/components/Table/utils/records-helper';
import {
  DISPLAY_SEARCH_REQUEST,
  FETCH_NODE_DETAILS,
  FETCH_PIPELINE_LIST,
  FETCH_PIPELINE_GRAPH,
  TOGGLE_PIPELINE_COMPONENT_SIDEBAR,
  TOGGLE_PIPELINE_SETTINGS_SIDEBAR,
  TOGGLE_COMPONENT_SETTINGS_SIDEBAR,
  FETCH_ADDITIONAL_COMPONENT_CANDIDATE,
  ADD_PIPELINE_VERTEX,
  CREATE_PIPELINE,
  GET_SUGGESTED_PIPELINE_VERTEXES,
  CLEAR_SUGGESTED_PIPELINE_VERTEXES,
  CLEAR_ADDITIONAL_COMPONENT_CANDIDATES,
  REMOVE_ADDITIONAL_COMPONENT_CANDIDATE,
  FETCH_ENTIRE_GRAPH,
  REMOVE_PIPELINE_VERTEX,
  SET_DISPLAY_NEW_VERTEXES,
  UPDATE_PIPELINE_EMAIL_ALERTING,
  SET_PIPELINE,
  SET_COMPONENT,
  SET_GRAPH_EXCLUDE_LABELS,
  GET_ALL_LABELS,
  ADD_GRAPH_EXCLUDE_LABEL,
  CLEAR_GRAPH_EXCLUDE_LABELS,
  ALL_GRAPH_EXCLUDE_LABELS,
  ADD_SUGGESTED_EXCLUDE_LABEL,
  CLEAR_SUGGESTED_EXCLUDE_LABELS,
  ALL_SUGGESTED_EXCLUDE_LABELS,
  SET_FILTER_BY_CALLING_USER,
  GET_ALL_ID,
} from '../actions/search';
import {
  formatNodes,
  extractOrder,
  formatPipelines,
  formatLinks,
  fetchAdditionalComponentCandidate,
  removeMatchingElementByObjectID,
} from '../utils/graph-format-helper';

const initialState = {
  searchTerm: '',
  payload: [],
  showPipelineComponentSideBar: true,
  showPipelineSettingsSideBar: false,
  pipelineGraphWidth: 900,
  showComponentSettingsSideBar: false,
  componentGraphWidth: 1200,
  filterByCallingUser: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DISPLAY_SEARCH_REQUEST: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    }
    case FETCH_NODE_DETAILS: {
      const formattedNodes = formatNodes(
        action.payload.vertexes,
        action.payload.idVertexCenter
      );
      const formattedLinks = formatLinks(
        action.payload.edges,
        formattedNodes.nodeMap
      );
      // TODO replace this with getVertexPropsRecordOrder(formattedNodes.nodeMap[action.payload.idVertexCenter].label)
      const extractedOrder = extractOrder(formattedNodes.centralProps);
      return {
        ...state,
        vertexProps: {
          records: formattedNodes.centralProps,
          recordOrder: extractedOrder,
        },
        graphNodes: formattedNodes.nodes,
        graphEdges: formattedLinks.formattedLinks,
        centralVertexId: action.payload.idVertexCenter,
        centralVertexHealth: formattedNodes.centralVertexHealth,
      };
    }
    case FETCH_PIPELINE_LIST: {
      const pipeLineTableData = formatPipelines(action.payload.pipelines);
      return {
        ...state,
        pipelines: {
          records: pipeLineTableData.records,
          order: pipeLineTableData.order,
          total: parseInt(action.payload.totalPipelines, 0),
        },
      };
    }
    case FETCH_PIPELINE_GRAPH: {
      const formattedPipelineNodes = formatNodes(action.payload.vertexes, '');
      const formattedPipelineLinks = formatLinks(
        action.payload.edges,
        formattedPipelineNodes.nodeMap
      );
      let updatedState = {};

      if (action.payload.pipelineMetaData) {
        const pipelinePropsRecords = {
          created_by: {
            label: 'created_by',
            value: action.payload.pipelineMetaData.createdBy,
          },
          last_updated_by: {
            label: 'last_updated_by',
            value: action.payload.pipelineMetaData.lastUpdatedBy,
          },
          last_updated_date: {
            label: 'last_updated_date',
            value: action.payload.pipelineMetaData.lastUpdatedTs,
          },
        };
        const extractedOrder = Object.keys(pipelinePropsRecords);
        updatedState = {
          pipelineRawVertexes: action.payload.vertexes,
          pipelinEmailAlerts: action.payload.pipelineMetaData.emailAlerting,
          pipelineGraphNodes: formattedPipelineNodes.nodes,
          pipelineGraphEdges: formattedPipelineLinks.formattedLinks,
          pipelineName: action.payload.pipelineMetaData.pipelineName,
          pipelineHealth: action.payload.pipelineMetaData.health,
          pipelineLastUpdated: action.payload.pipelineMetaData.lastUpdatedTs,
          pipelineCreatedBy: action.payload.pipelineMetaData.createdBy,
          pipelineLastUpdatedBy: action.payload.pipelineMetaData.lastUpdatedBy,
          pipelineUsers: keyBy(action.payload.users, 'idUser'),
          nodeMap: formattedPipelineNodes.nodeMap,
          linkMap: formattedPipelineLinks.linkMap,
          pipelineProps: {
            records: pipelinePropsRecords,
            recordOrder: extractedOrder,
          },
        };
      } else {
        updatedState = {
          pipelineRawVertexes: [],
          pipelineGraphNodes: [],
          pipelineGraphEdges: [],
          pipelineName: 'Pipeline Not Found',
          pipelineHealth: '',
          pipelineLastUpdated: null,
          pipelineCreatedBy: null,
          pipelineLastUpdatedBy: null,
          nodeMap: {},
          pipelineProps: {},
          pipelineUsers: {},
        };
      }
      return {
        ...state,
        ...updatedState,
      };
    }
    case FETCH_ENTIRE_GRAPH: {
      const formattedPipelineNodes = formatNodes(action.payload.vertexes, '');
      const formattedPipelineLinks = formatLinks(
        action.payload.edges,
        formattedPipelineNodes.nodeMap
      );
      return {
        ...state,
        pipelineRawVertexes: action.payload.vertexes,
        pipelineGraphNodes: formattedPipelineNodes.nodes,
        pipelineGraphEdges: formattedPipelineLinks.formattedLinks,
        pipelineName: 'All Components in Data Platform',
        nodeMap: formattedPipelineNodes.nodeMap,
        linkMap: formattedPipelineLinks.linkMap,
      };
    }
    case TOGGLE_PIPELINE_COMPONENT_SIDEBAR: {
      const open = !state.showPipelineComponentSideBar;
      return {
        ...state,
        showPipelineComponentSideBar: open,
        showPipelineSettingsSideBar: false,
        pipelineGraphWidth: open ? 900 : 1200,
      };
    }
    case TOGGLE_PIPELINE_SETTINGS_SIDEBAR: {
      const open = !state.showPipelineSettingsSideBar;
      return {
        ...state,
        showPipelineSettingsSideBar: open,
        showPipelineComponentSideBar: false,
        pipelineGraphWidth: open ? 900 : 1200,
      };
    }
    case TOGGLE_COMPONENT_SETTINGS_SIDEBAR: {
      const open = !state.showComponentSettingsSideBar;
      return {
        ...state,
        showComponentSettingsSideBar: open,
        componentGraphWidth: open ? 900 : 1200,
      };
    }
    case CREATE_PIPELINE: {
      const successStatus = action.payload.success;
      if (successStatus) {
        const formattedPipelineNodes = formatNodes(
          action.payload.pipelineGraph.vertexes,
          ''
        );
        const formattedPipelineLinks = formatLinks(
          action.payload.pipelineGraph.edges,
          formattedPipelineNodes.nodeMap
        );
        return {
          ...state,
          pipelineRawVertexes: action.payload.pipelineGraph.vertexes,
          createVertexesSuccessStatus: successStatus,
          createVertexesErrorMessage: action.payload.errorMessage,
          pipelineGraphNodes: formattedPipelineNodes.nodes,
          pipelineGraphEdges: formattedPipelineLinks.formattedLinks,
          pipelineName:
            action.payload.pipelineGraph.pipelineMetaData.pipelineName,
          pipelineHealth: action.payload.pipelineGraph.pipelineMetaData.health,
          pipelineLastUpdated:
            action.payload.pipelineGraph.pipelineMetaData.lastUpdatedTs,
          nodeMap: formattedPipelineNodes.nodeMap,
        };
      }
      return {
        ...state,
        createVertexesSuccessStatus: successStatus,
        createVertexesErrorMessage: action.payload.errorMessage,
      };
    }
    case ADD_PIPELINE_VERTEX: {
      const successStatus = action.payload.success;
      let out = {};
      if (successStatus) {
        const formattedPipelineNodes = formatNodes(
          action.payload.pipelineGraph.vertexes,
          ''
        );
        const formattedPipelineLinks = formatLinks(
          action.payload.pipelineGraph.edges,
          formattedPipelineNodes.nodeMap
        );
        out = {
          ...state,
          pipelineRawVertexes: action.payload.pipelineGraph.vertexes,
          addVertexesSuccessStatus: successStatus,
          addVertexesErrorMessage: action.payload.errorMessage,
          pipelineGraphNodes: formattedPipelineNodes.nodes,
          pipelineGraphEdges: formattedPipelineLinks.formattedLinks,
          pipelineName:
            action.payload.pipelineGraph.pipelineMetaData.pipelineName,
          pipelineHealth: action.payload.pipelineGraph.pipelineMetaData.health,
          pipelineLastUpdated:
            action.payload.pipelineGraph.pipelineMetaData.lastUpdatedTs,
          nodeMap: formattedPipelineNodes.nodeMap,
          linkMap: formattedPipelineLinks.linkMap,
        };
      } else {
        out = {
          ...state,
          addVertexesSuccessStatus: successStatus,
          addVertexesErrorMessage: action.payload.errorMessage,
        };
      }
      return out;
    }
    case REMOVE_PIPELINE_VERTEX: {
      const successStatus = action.payload.success;
      let out = {};
      if (successStatus) {
        const formattedPipelineNodes = formatNodes(
          action.payload.pipelineGraph.vertexes,
          ''
        );
        const formattedPipelineLinks = formatLinks(
          action.payload.pipelineGraph.edges,
          formattedPipelineNodes.nodeMap
        );
        out = {
          ...state,
          pipelineRawVertexes: action.payload.pipelineGraph.vertexes,
          removeVertexesSuccessStatus: successStatus,
          removeVertexesErrorMessage: action.payload.errorMessage,
          pipelineGraphNodes: formattedPipelineNodes.nodes,
          pipelineGraphEdges: formattedPipelineLinks.formattedLinks,
          pipelineName:
            action.payload.pipelineGraph.pipelineMetaData.pipelineName,
          pipelineHealth: action.payload.pipelineGraph.pipelineMetaData.health,
          pipelineLastUpdated:
            action.payload.pipelineGraph.pipelineMetaData.lastUpdatedTs,
          nodeMap: formattedPipelineNodes.nodeMap,
          linkMap: formattedPipelineLinks.linkMap,
        };
      } else {
        out = {
          ...state,
          removeVertexesSuccessStatus: successStatus,
          removeVertexesErrorMessage: action.payload.errorMessage,
        };
      }
      return out;
    }
    case FETCH_ADDITIONAL_COMPONENT_CANDIDATE: {
      const newVertexes = fetchAdditionalComponentCandidate(
        action.payload.id,
        action.payload.label,
        state.newVertexes
      );
      const numNewVertexes = newVertexes.length;

      let offset = action.pageNumber * action.recordsPerPage;
      if (offset >= numNewVertexes) {
        offset = Math.floor(numNewVertexes / action.recordsPerPage) - 1; // jumps back to beginning of last page
      }
      const newVertexesSlice = newVertexes.slice(
        offset,
        offset + action.recordsPerPage
      );
      const newVertexRecords = keyBy(newVertexesSlice, 'id');
      const newVertexRecordsOrder = Object.keys(newVertexRecords);
      const newVertexRecordCellStyle = createRecordCellStyles(newVertexRecords);
      const latestNewVertex = newVertexes.length
        ? newVertexes[newVertexes.length - 1]
        : {};

      return {
        ...state,
        latestNewVertex,
        newVertexes,
        newVertexRecords,
        newVertexRecordsOrder,
        newVertexRecordCellStyle,
        numNewVertexes,
      };
    }
    case GET_SUGGESTED_PIPELINE_VERTEXES: {
      const suggestedVertexRecords = keyBy(action.payload.vertexes, 'id');
      const suggestedVertexRecordOrder = Object.keys(suggestedVertexRecords);
      const suggestedVertexRecordCellStyles = createRecordCellStyles(
        suggestedVertexRecords
      );
      return {
        ...state,
        suggestedVertexRecords,
        suggestedVertexRecordOrder,
        suggestedVertexRecordCellStyles,
        totalSuggestions: parseInt(action.payload.totalSuggestedVertexes, 0),
      };
    }
    case CLEAR_ADDITIONAL_COMPONENT_CANDIDATES: {
      return {
        ...state,
        newVertexes: [],
        newVertexRecords: {},
        newVertexRecordsOrder: [],
        newVertexRecordCellStyle: {},
        latestNewVertex: {},
        numNewVertexes: 0,
      };
    }
    case UPDATE_PIPELINE_EMAIL_ALERTING: {
      return {
        ...state,
        pipelinEmailAlerts:
          action.payload.pipelineGraph.pipelineMetaData.emailAlerting,
      };
    }
    case CLEAR_SUGGESTED_PIPELINE_VERTEXES: {
      return {
        ...state,
        suggestedVertexRecords: {},
        suggestedVertexRecordOrder: [],
        suggestedVertexRecordCellStyles: {},
        totalSuggestions: 0,
      };
    }
    case REMOVE_ADDITIONAL_COMPONENT_CANDIDATE: {
      const existingVertexes = { ...state.newVertexes };
      const newVertexes = removeMatchingElementByObjectID(
        action.idVertex,
        existingVertexes
      );
      const numNewVertexes = newVertexes.length;

      let offset = action.pageNumber * action.recordsPerPage;
      if (offset >= numNewVertexes) {
        offset = Math.floor(numNewVertexes / action.recordsPerPage) - 1; // jumps back to beginning of last page
      }
      const newVertexesSlice = newVertexes.slice(
        offset,
        offset + action.recordsPerPage
      );
      const newVertexRecords = keyBy(newVertexesSlice, 'id');
      const newVertexRecordsOrder = Object.keys(newVertexRecords);
      const newVertexRecordCellStyle = createRecordCellStyles(newVertexRecords);
      const latestNewVertex = newVertexes.length
        ? newVertexes[newVertexes.length - 1]
        : {};

      return {
        ...state,
        latestNewVertex,
        newVertexes,
        newVertexRecords,
        newVertexRecordsOrder,
        newVertexRecordCellStyle,
        numNewVertexes,
      };
    }
    case SET_DISPLAY_NEW_VERTEXES: {
      const {newVertexes} = state;
      const numNewVertexes = newVertexes.length;

      let offset = action.pageNumber * action.recordsPerPage;
      if (offset >= numNewVertexes) {
        offset = Math.floor(numNewVertexes / action.recordsPerPage) - 1; // jumps back to beginning of last page
      }
      const newVertexesSlice = newVertexes.slice(
        offset,
        offset + action.recordsPerPage
      );
      const newVertexRecords = keyBy(newVertexesSlice, 'id');
      const newVertexRecordsOrder = Object.keys(newVertexRecords);
      const newVertexRecordCellStyle = createRecordCellStyles(newVertexRecords);
      const latestNewVertex = newVertexes.length
        ? newVertexes[newVertexes.length - 1]
        : {};

      return {
        ...state,
        latestNewVertex,
        newVertexes,
        newVertexRecords,
        newVertexRecordsOrder,
        newVertexRecordCellStyle,
        numNewVertexes,
      };
    }
    case SET_PIPELINE: {
      return { ...state, pipelineName: action.pipelineName };
    }
    case SET_COMPONENT: {
      return { ...state, centralVertexId: action.componentId };
    }
    case SET_GRAPH_EXCLUDE_LABELS: {
      return { ...state, graphExcludeLabels: action.graphExcludeLabels };
    }
    case GET_ALL_LABELS: {
      return { ...state, labels: action.payload.labels };
    }
    case GET_ALL_ID: {
      return { ...state, labelsIds: action.payload.ids };
    }
    case ADD_GRAPH_EXCLUDE_LABEL: {
      return { ...state, graphExcludeLabels: action.label.value };
    }
    case CLEAR_GRAPH_EXCLUDE_LABELS: {
      return { ...state, graphExcludeLabels: [] };
    }
    case ALL_GRAPH_EXCLUDE_LABELS: {
      return { ...state, graphExcludeLabels: state.labels };
    }
    case ADD_SUGGESTED_EXCLUDE_LABEL: {
      return { ...state, suggestedExcludeLabels: action.label.value };
    }
    case CLEAR_SUGGESTED_EXCLUDE_LABELS: {
      return { ...state, suggestedExcludeLabels: [] };
    }
    case ALL_SUGGESTED_EXCLUDE_LABELS: {
      return { ...state, suggestedExcludeLabels: state.labels };
    }
    case SET_FILTER_BY_CALLING_USER: {
      return { ...state, filterByCallingUser: action.filterByCallingUser };
    }
    default:
      return state;
  }
}
