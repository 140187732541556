import { AntPageLayoutWithHeader } from 'client/modules/permissions/components/AntPageLayoutWithHeader';
import { FormContainerWithErrorBoundary } from 'client/modules/common/form/containers/FormContainerWithErrorBoundary';
import React from 'react';
import { MarketReportsForm } from '../components/MarketReportsForm';
import {
  initialEditMarketStatus,
  useGetMarketDetailsValues,
} from '../hooks/useGetMarketDetailsValues';
import { useSubmitMarketDetails } from '../hooks/useSubmitMarketDetails';

export const EditMarketReport = () => {
  const { optionalFormProps, initialValues } = useGetMarketDetailsValues();
  const { handleSubmit, handleReject } = useSubmitMarketDetails();

  return (
    <AntPageLayoutWithHeader title="Market reports">
      <FormContainerWithErrorBoundary
        Form={MarketReportsForm}
        enableReinitialize
        initialStatus={initialEditMarketStatus}
        optionalFormProps={optionalFormProps}
        onSubmit={handleSubmit}
        onReject={handleReject}
        initialValues={initialValues}
        errorMessage="Something seems to have gone wrong with this form, please report below error to the BIO team"
      />
    </AntPageLayoutWithHeader>
  );
};
