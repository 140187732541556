import {
  SuggestUsersAndTeamsRequest,
  SuggestUsersAndTeamsResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getSuggestUsersAndTeams = (
  req: Partial<SuggestUsersAndTeamsRequest>
): Promise<SuggestUsersAndTeamsResponse> =>
  request({
    url: '/service/adminservice/SuggestUsersAndTeams',
    body: req,
  }).then((r) => r.body);
