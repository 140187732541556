import { CbiEntityRoutes } from '../RoutesHelpers';

export const CBI_ENTITY_TAB_PATH = {
  GeneralInfo: CbiEntityRoutes.GeneralInfo,
  Fundings: CbiEntityRoutes.Fundings,
  VCFunds: CbiEntityRoutes.VCFunds,
  Competitors: CbiEntityRoutes.Competitors,
  Employees: CbiEntityRoutes.Employees,
  SalesMetrics: CbiEntityRoutes.SalesMetrics,
  Financials: CbiEntityRoutes.Financials,
} as const;

export type CbiEntityTabPathType =
  (typeof CBI_ENTITY_TAB_PATH)[keyof typeof CBI_ENTITY_TAB_PATH];

export const CBI_ENTITY_SECTION_ID = {
  GeneralInfo: 1,
  Fundings: 2,
  VCFunds: 3,
  Competitors: 4,
  Employees: 5,
  SalesMetrics: 8,
  Financials: 10,
} as const;

// ids should be same as in database
export const ENTITY_ADMIN_PERMISSIONS = {
  pageId: 56,
  name: 'CBI Entity',
  path: 'cbi-entity',
  sections: {
    [CBI_ENTITY_SECTION_ID.GeneralInfo]: {
      name: 'General Info',
      sectionId: CBI_ENTITY_SECTION_ID.GeneralInfo,
      path: CBI_ENTITY_TAB_PATH.GeneralInfo,
    },
    [CBI_ENTITY_SECTION_ID.Fundings]: {
      name: 'Fundings',
      sectionId: CBI_ENTITY_SECTION_ID.Fundings,
      path: CBI_ENTITY_TAB_PATH.Fundings,
    },
    [CBI_ENTITY_SECTION_ID.VCFunds]: {
      name: 'Fund',
      sectionId: CBI_ENTITY_SECTION_ID.VCFunds,
      path: CBI_ENTITY_TAB_PATH.VCFunds,
    },
    [CBI_ENTITY_SECTION_ID.Competitors]: {
      name: 'Competitors',
      sectionId: CBI_ENTITY_SECTION_ID.Competitors,
      path: CBI_ENTITY_TAB_PATH.Competitors,
    },
    [CBI_ENTITY_SECTION_ID.Employees]: {
      name: 'People',
      sectionId: CBI_ENTITY_SECTION_ID.Employees,
      path: CBI_ENTITY_TAB_PATH.Employees,
    },
    [CBI_ENTITY_SECTION_ID.SalesMetrics]: {
      name: 'Sales Metrics',
      sectionId: CBI_ENTITY_SECTION_ID.SalesMetrics,
      path: CBI_ENTITY_TAB_PATH.SalesMetrics,
    },
    [CBI_ENTITY_SECTION_ID.Financials]: {
      name: 'Financials',
      sectionId: CBI_ENTITY_SECTION_ID.Financials,
      path: CBI_ENTITY_TAB_PATH.Financials,
    },
  },
} as const;
