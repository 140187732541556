import { useEffect, useState } from 'react';
import getProfileUrl, {
  COMPANY_ENTITY,
  INVESTOR_ENTITY,
} from 'client/modules/cbi-entity/utils/getProfileUrl';
import { EntityMeta } from '../CBISectionFormLayout';

export const useGetCbiProfileLink = (
  meta: EntityMeta
): { profileLink: string } => {
  const [profileLink, setProfileLink] = useState('/');

  useEffect(() => {
    if (meta) {
      setProfileLink(
        getProfileUrl(
          meta?.isCompany === true
            ? { idCompanyOrIdInvestor: meta?.idCompany, type: COMPANY_ENTITY }
            : { idCompanyOrIdInvestor: meta?.idInvestor, type: INVESTOR_ENTITY }
        )
      );
    }
  }, [meta]);
  return { profileLink };
};
