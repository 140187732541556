import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import Divider from './Divider';

const UsageDetails = () => {
  return (
    <>
      <Row justify="space-between" className="mb-3">
        <Col span="12">
          <Typography.Text className="!font-medium">Last Login</Typography.Text>
        </Col>
        <Col span="12" className="text-right">
          <Typography.Text className="!font-medium">TBD</Typography.Text>
        </Col>
      </Row>
      <Row justify="space-between" className="mb-3">
        <Col span="12">
          <Typography.Text className="!font-medium">
            # of Logins
          </Typography.Text>
        </Col>
        <Col span="12" className="text-right">
          <Typography.Text className="!font-medium">TBD</Typography.Text>
        </Col>
      </Row>
      <Row justify="center" className="mb-1">
        <Col span="6">
          <Typography.Title level={3} className="!font-medium">
            User Actions
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Divider
            orientationMargin={0}
            className="border-t-2 border-black border-solid"
          />
        </Col>
      </Row>
      <Row>
        <Col span="24" className="flex justify-center">
          <Button>Login as User</Button>
        </Col>
      </Row>
    </>
  );
};

export default UsageDetails;
