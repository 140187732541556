import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextCell from 'client/modules/common/components/Table/TableComponents/CellComponents/TextCell';
import MaterialUILink from 'client/component-library/Link';
import validateUrl from 'client/modules/common/utils/validateUrl';

class VertexPropertyField extends Component {
  onClickComponent = (e) => {
    e.preventDefault();
    window.open(this.props.value);
  };

  isLink = () => {
    return (
      this.props.blacklistFields.indexOf(this.props.label) < 0 &&
      validateUrl({ url: this.props.value, isCaseSensitive: false })
    );
  };

  render() {
    if (this.isLink()) {
      return (
        <MaterialUILink onClick={this.onClickComponent}>
          {this.props.value.length > this.props.displayStringLength
            ? `${this.props.value.substring(
                0,
                this.props.displayStringLength
              )}...`
            : this.props.value}
        </MaterialUILink>
      );
    }
    return <TextCell value={this.props.value} />;
  }
}

VertexPropertyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayStringLength: PropTypes.number,
  blacklistFields: PropTypes.arrayOf(PropTypes.string),
};

VertexPropertyField.defaultProps = {
  value: '',
  displayStringLength: 75,
  blacklistFields: ['hostname', 'queue_url', 'bucket_name'],
};

export default VertexPropertyField;
