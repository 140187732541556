import {
  GetInvestorLogosRequest,
  GetInvestorLogosResponse,
} from '@cbinsights/logoservice/logoservice';
import { request } from 'client/modules/common/utils/request';

export const getInvestorLogos = (
  req: Partial<GetInvestorLogosRequest>
): Promise<GetInvestorLogosResponse> => {
  return request({
    url: '/service/logoservice/GetInvestorLogos',
    body: { ...req },
  }).then((r): GetInvestorLogosResponse => r.body);
};
