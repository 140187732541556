export const outerStyle = {
  borderStyle: 'solid',
  borderColor: '#006699',
  // float: 'right',
  backgroundColor: 'white',
  boxShadow: '0 0 30px rgba(39, 43, 55, 0.15)',
  borderRadius: '25px',
  width: '280px',
  height: '500px',
  overflowY: 'auto',
};

export const headerStyle = {
  backgroundColor: '#006699',
  padding: '10px',
};

export const modalHeaderTextStyle = {
  color: 'white',
  marginTop: 0,
  fontSize: '18px',
  fontFamily: 'Roboto',
  padding: '10px',
};

export const graphContainerStyle = {
  borderStyle: 'solid',
  borderColor: '#006699',
  boxShadow: '0 0 30px rgba(39, 43, 55, 0.15)',
  borderRadius: '25px',
};

export const selectStyle = {
  fontSize: '0.9225rem',
  fontFamily: 'Roboto',
  padding: '10px',
  minWidth: '120px',
  maxWidth: '250px',
};

export const formControlStyle = {
  marginLeft: '10px',
};
