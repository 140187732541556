import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateIndividualRecord } from 'client/modules/markets/redux/actions/data';
import {
  APPROVED,
  REJECTED,
  IS_DUPLICATE,
  SAVED,
  NEEDS_APPROVAL_CODE,
  APPROVED_CODE,
  REJECTED_CODE,
} from 'client/modules/markets/redux/actions/record-status-filters';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'client/component-library/Dialog';
import Icon from 'client/component-library/icons/Icon';
import Button from 'client/component-library/buttons/Button';
import {
  MuiThemeProvider,
  createMuiTheme,
} from 'client/component-library/styles';
import { adminTheme } from 'client/app/components/styles/adminMaterialTheme';
import styles from '../../styles/css/markets-buttons.css';

const theme = createMuiTheme({
  ...adminTheme,
  palette: {
    ...adminTheme.palette,
    secondary: {
      main: '#f50057',
    },
  },
});

class ActionIconsCell extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      open: false,
    });
  }

  handleApprove = () => {
    const { id } = this.props;
    const [recordId] = id.split('-');
    this.props.updateIndividualRecord(recordId, APPROVED);
  };

  handleReject = () => {
    const { id } = this.props;
    const [recordId] = id.split('-');
    this.props.updateIndividualRecord(recordId, REJECTED);
  };

  handleDuplicate = () => {
    const { id } = this.props;
    const [recordId] = id.split('-');
    this.props.updateIndividualRecord(recordId, IS_DUPLICATE);
    this.setState({ open: false });
  };

  handleSave = () => {
    const { id } = this.props;
    const [recordId] = id.split('-');
    this.props.updateIndividualRecord(recordId, SAVED);
  };

  handleOpenDialog = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { id, selectedRecordStatusFilterId, record } = this.props;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          {selectedRecordStatusFilterId !== NEEDS_APPROVAL_CODE && (
            <Button
              className={styles.marketsBtn}
              variant="contained"
              dataTest={`${id}-save`}
              style={{ backgroundColor: '#43A047', color: '#fff' }}
              onClick={this.handleSave}
            >
              <span className={styles.btnLabel}>Save</span>
            </Button>
          )}
          {selectedRecordStatusFilterId !== APPROVED_CODE && (
            <Button
              className={styles.marketsBtn}
              variant="contained"
              color="primary"
              dataTest={`${id}-approve`}
              onClick={this.handleApprove}
            >
              <Icon
                family="material"
                name="Thumb Up"
                className={styles.marketsBtnIcon}
              />
              <span className={styles.btnLabel}>Approve</span>
            </Button>
          )}
          {selectedRecordStatusFilterId !== REJECTED_CODE && (
            <Button
              className={styles.marketsBtn}
              variant="contained"
              color="secondary"
              dataTest={`${id}-reject`}
              onClick={this.handleReject}
            >
              <Icon
                family="material"
                name="Thumb Down"
                className={styles.marketsBtnIcon}
              />
              <span className={styles.btnLabel}>Reject</span>
            </Button>
          )}
          <div>
            <Button
              className={styles.marketsBtn}
              variant="contained"
              dataTest={`${id}-mark-as-duplicate`}
              onClick={
                record.status === APPROVED
                  ? this.handleOpenDialog
                  : this.handleDuplicate
              }
            >
              <span className={styles.btnLabel}>Mark As Dupe</span>
            </Button>
            <Dialog open={this.state.open} onClose={this.handleCloseDialog}>
              <DialogTitle>
                Are you sure you would like to mark an approved forecast record
                as a duplicate?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Doing so will remove it from the CBI platform.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialog}
                  color="secondary"
                  dataTest="dialog-cancel"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleDuplicate}
                  color="primary"
                  dataTest="dialog-mark-as-duplicate"
                >
                  Mark As Dupe
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

function mapStateToProps({ markets }, ownProps) {
  const recordId = parseInt(ownProps.id, 10);

  return {
    record: markets.tabData.marketSizingDetails.records[recordId],
    selectedRecordStatusFilterId:
      markets.recordStatusFilters.selectedRecordStatusFilterId,
  };
}

ActionIconsCell.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
  selectedRecordStatusFilterId: PropTypes.number,
  updateIndividualRecord: PropTypes.func.isRequired,
};

ActionIconsCell.defaultProps = {
  id: '',
  record: {},
  selectedRecordStatusFilterId: 0,
};

export default connect(mapStateToProps, { updateIndividualRecord })(
  ActionIconsCell
);
