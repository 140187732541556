import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const PublicPageRegenerator = React.lazy(
  () => import('./containers/PublicPageRegenerator')
);

export const publicPagesRoutes: RouteObject = {
  path: 'public-pages',
  children: [
    {
      path: '/public-pages',
      element: <Navigate to="/public-pages/regenerate-page" />,
    },
    {
      path: 'regenerate-page',
      element: (
        <React.Suspense fallback={undefined}>
          <PublicPageRegenerator />
        </React.Suspense>
      ),
    },
  ],
};
