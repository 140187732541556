import React from 'react';
import PropTypes from 'prop-types';
import { MaterialUILink } from 'client/component-library/Link';
import Grid from 'client/component-library/Grid';
import styles from './styles/dropdownItem.css';

const conditionallApplySelectedStyle = (isSelected) => {
  return isSelected ? styles.selected : '';
};

const createDropdownStyle = (height) => {
  return {
    height: height ? `${height}px` : 'inherit',
    margin: 0,
    width: '100%',
  };
};

const createEllipsisStyle = (isSelected) => {
  return isSelected ? styles.selectedEllipsis : styles.notSelectedEllipsis;
};

/**
 * @deprecated
 * It renders a dropdown item for the entity search
 * @returns A function that returns a component
 */
const EntityDropdownItem = ({
  item,
  text,
  selected,
  onMouseOver,
  onMouseDown,
  height,
  className,
  dropdownItemId,
}) => {
  const dataTestName = item.id ? item.id : text;
  const companyUrl = item.url || item.profileUrl;
  const { alias } = item;
  const { altUrl } = item;
  const { vcFund } = item;
  return (
    <Grid
      container
      spacing={1}
      className={`${className} ${
        styles.dropdownItem
      } ${conditionallApplySelectedStyle(selected)}`}
      onMouseOver={onMouseOver}
      onMouseDown={onMouseDown}
      data-test={`admin-${dataTestName}`}
      style={createDropdownStyle(height)}
    >
      <Grid
        item
        xs={12}
        style={selected ? { padding: 0, maxWidth: '100%' } : { padding: 0 }}
      >
        <span id={dropdownItemId}>
          <b>{item.name}</b>
        </span>
        <span style={{ marginLeft: '5px' }}>
          <MaterialUILink
            href={`//${companyUrl}`}
            className={selected ? styles.selected : styles.notSelected}
            onMouseDown={onMouseDown}
          >
            {companyUrl}
          </MaterialUILink>
        </span>
        <div>
          {alias ? (
            <span>
              <b>alias:</b> {alias}
            </span>
          ) : null}
          {altUrl ? (
            <span>
              <b>alternate url:</b> {altUrl}
            </span>
          ) : null}
          {vcFund ? (
            <span>
              <b>VC Fund:</b> {vcFund}{' '}
            </span>
          ) : null}
        </div>
        <span className={createEllipsisStyle(selected)}>
          {item.description}
        </span>
      </Grid>
    </Grid>
  );
};

EntityDropdownItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  height: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  dropdownItemId: PropTypes.string,
};

EntityDropdownItem.defaultProps = {
  item: {},
  selected: false,
  onMouseOver: () => {},
  onMouseDown: () => {},
  height: 0,
  className: '',
  text: '',
  dropdownItemId: '',
};

export default EntityDropdownItem;
