import React from 'react';
import PropTypes from 'prop-types';
import InputCell from 'client/modules/common/components/Table/TableComponents/CellComponents/InputCell';
import ReviewerCell from '../TableComponents/ReviewerCell';
import ReviewerNoteCell from '../TableComponents/ReviewerNoteCell';
import ApproveIconCell from '../TableComponents/ApproveIconCell';
import RejectIconCell from '../TableComponents/RejectIconCell';
import DeleteIconCell from '../TableComponents/DeleteIconCell';
import ListOfMarketsNameCell from '../TableComponents/ListOfMarketsNameCell';
import RecordIdCell from '../TableComponents/RecordIdCell';
import MarketsArticleContent from '../TableComponents/MarketsArticleContent';
import MarketDescriptionCell from '../TableComponents/MarketDescriptionCell';
import DeletePendingRelatedMarketIconCell from '../TableComponents/DeletePendingRelatedMarketIconCell';
import MarketNewsArticleInputAdornment from '../TableComponents/MarketNewsArticleInputAdornment';
import ActionIconsCell from '../TableComponents/ActionIconsCell';
import DuplicateCountCell from '../TableComponents/DuplicateCountCell';
import MarketsInnerTable from '../MarketsInnerTable';
import InnerTableMarketNameCell from '../TableComponents/InnerTableMarketNameCell';
import InnerTableNewsArticleCell from '../TableComponents/InnerTableNewsArticleCell';
import InnerTableHistoryCell from '../TableComponents/InnerTableHistoryCell';
import InnerTableTextCell from '../TableComponents/InnerTableTextCell';
import ReadOnlyDate from '../TableComponents/ReadOnlyDate';

const mapMarketComponents = (type, props, commonProps) => {
  const {
    id,
    reviewer,
    reviewDate,
    status,
    height,
    contentWidth,
    resize,
    textAreaWidth,
    numberOfRows,
    news,
    parentMarketId,
    parentMarketName,
    rowId,
    parentId,
    highlightStartSize,
    highlightStartYear,
    highlightEndSize,
    highlightEndYear,
  } = props;
  const { disabled } = commonProps;
  switch (type) {
    case 'markets-reviewer':
      return (
        <ReviewerCell
          {...commonProps}
          reviewer={reviewer}
          id={id}
          reviewDate={reviewDate}
        />
      );
    case 'markets-reviewerNote':
      return (
        <ReviewerNoteCell {...commonProps} height={height} id={id} multiline />
      );
    case 'markets-actionIcons':
      return <ActionIconsCell {...commonProps} id={id} />;
    case 'markets-deleteIcon':
      return <DeleteIconCell {...commonProps} id={id} />;
    case 'markets-deletePendingRelatedMarketIcon':
      return (
        <DeletePendingRelatedMarketIconCell
          id={id}
          status={status}
          disabled={disabled}
        />
      );
    case 'markets-approveIcon':
      return <ApproveIconCell id={id} status={status} disabled={disabled} />;
    case 'markets-rejectIcon':
      return <RejectIconCell id={id} status={status} disabled={disabled} />;
    case 'markets-listOfMarketsName':
      return (
        <ListOfMarketsNameCell
          {...commonProps}
          id={id}
          parentMarketId={parentMarketId}
          parentMarketName={parentMarketName}
        />
      );
    case 'markets-recordId':
      return <RecordIdCell recordId={id} />;
    case 'markets-articleContent':
      return (
        <MarketsArticleContent
          {...commonProps}
          id={id}
          contentWidth={contentWidth}
        />
      );
    case 'markets-marketDescription':
      return (
        <MarketDescriptionCell
          {...commonProps}
          id={id}
          height={height}
          resize={resize}
          textAreaWidth={textAreaWidth}
          numberOfRows={numberOfRows}
          multiline
        />
      );
    case 'markets-articleLink':
      return (
        <InputCell
          {...commonProps}
          multiline={false}
          startAdornment={(
            <MarketNewsArticleInputAdornment
              value={commonProps.value}
              news={news}
              recordId={id}
            />
          )}
        />
      );
    case 'markets-duplicateCount':
      return <DuplicateCountCell {...commonProps} id={id} />;
    case 'markets-innerTable':
      return <MarketsInnerTable {...commonProps} id={rowId} />;
    case 'markets-marketNameInnerTable':
      return (
        <InnerTableMarketNameCell
          {...commonProps}
          id={id}
          parentId={parentId}
        />
      );
    case 'markets-articleLinkInnerTable':
      return (
        <InputCell
          {...commonProps}
          multiline={false}
          startAdornment={(
            <MarketNewsArticleInputAdornment
              value={commonProps.value}
              news={news}
              recordId={id}
            />
          )}
          disabled
        />
      );
    case 'markets-articleSentenceInnerTable':
      return (
        <InnerTableNewsArticleCell
          {...commonProps}
          id={id}
          parentId={parentId}
        />
      );
    case 'markets-innerTableHistory':
      return (
        <InnerTableHistoryCell {...commonProps} id={id} parentId={parentId} />
      );
    case 'markets-innerTableStartSize':
      return (
        <InnerTableTextCell
          {...commonProps}
          id={id}
          value={commonProps.value}
          highlightText={highlightStartSize}
        />
      );
    case 'markets-innerTableStartYear':
      return (
        <InnerTableTextCell
          {...commonProps}
          id={id}
          value={commonProps.value}
          highlightText={highlightStartYear}
        />
      );
    case 'markets-innerTableEndSize':
      return (
        <InnerTableTextCell
          {...commonProps}
          id={id}
          value={commonProps.value}
          highlightText={highlightEndSize}
        />
      );
    case 'markets-innerTableEndYear':
      return (
        <InnerTableTextCell
          {...commonProps}
          id={id}
          value={commonProps.value}
          highlightText={highlightEndYear}
        />
      );
    case 'markets-readOnlyDate':
      return <ReadOnlyDate {...commonProps} id={id} parentId={parentId} />;
    default:
      return null;
  }
};

mapMarketComponents.propTypes = {
  id: PropTypes.string,
  height: PropTypes.string,
  highlightStartYear: PropTypes.string,
  highlightStartSize: PropTypes.number,
  highlightEndYear: PropTypes.string,
  highlightEndSize: PropTypes.number,
  news: PropTypes.object,
  numberOfRows: PropTypes.number,
  reviewer: PropTypes.object,
  reviewDate: PropTypes.string,
  resize: PropTypes.string,
  rowId: PropTypes.string,
  status: PropTypes.string,
  contentWidth: PropTypes.string,
  textAreaWidth: PropTypes.string,
  parentId: PropTypes.string,
  parentMarketId: PropTypes.string,
  parentMarketName: PropTypes.string,
};

mapMarketComponents.defaultProps = {
  id: '',
  height: '',
  highlightStartYear: 'N/A',
  highlightStartSize: 0,
  highlightEndYear: 'N/A',
  highlightEndSize: 0,
  news: {},
  numberOfRows: 5,
  resize: 'vertical',
  reviewer: {},
  reviewDate: '',
  rowId: '',
  status: '',
  contentWidth: '1200px',
  textAreaWidth: '',
  parentId: '',
  parentMarketId: 0,
  parentMarketName: '',
};

export default mapMarketComponents;
