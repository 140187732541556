import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Popover extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(event) {
    const { onBlur, closeOnInternalClick } = this.props;

    if (
      this.wrapperRef &&
      (!this.wrapperRef.contains(event.target) || closeOnInternalClick)
    ) {
      event.stopPropagation();
      onBlur();
    }
  }

  /**
   * @deprecated
   *  Rendering the children of the component.
   *  */
  render() {
    const { children } = this.props;

    return (
      <div
        ref={(node) => {
          this.wrapperRef = node;
        }}
      >
        {children}
      </div>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  onBlur: PropTypes.func.isRequired,
  closeOnInternalClick: PropTypes.bool,
};

Popover.defaultProps = {
  closeOnInternalClick: false,
};

export default Popover;
