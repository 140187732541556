import { GetUserRoleFeaturesResponse } from '@cbinsights/businessrelationshipservice/businessrelationshipservice';
import { useQuery } from 'react-query';
import { getUserRoleFeatures } from '../GetUserRoleFeatures';
import { QueryKeys } from './useGetRelationshipQuery';

export interface TabRolePermissionsState {
  featurePermissionReview: boolean;
  featurePermissionPendingApproval: boolean;
  featurePermissionPendingRejection: boolean;
  featurePermissionApproved: boolean;
  featurePermissionRejected: boolean;
}
export interface ActionsRolePermissionsState {
  featurePermissionApproveRecords: boolean;
  featurePermissionAuditVendors: boolean;
  featurePermissionRejectRecords: boolean;
  featurePermissionSavePendingRecords: boolean;
  featurePermissionPreApproveRecords: boolean;
  featurePermissionPreRejectRecords: boolean;
  featurePermissionMergeRecords: boolean;
  featurePermissionDuplicateRecords: boolean;
  featurePermissionBulkActions: boolean;
}
export interface DisplayRolePermissionsState {
  featurePermissionDisplayDuplicates: boolean;
  featurePermissionAddNewRecords: boolean;
  featurePermissionCreatePreApprovedRecord: boolean;
  featurePermissionCreateApprovedRecord: boolean;
  featurePermissionUpdateNews: boolean;
  featurePermissionSearchByEntity: boolean;
  featurePermissionViewEntityLinks: boolean;
  featurePermissionShowNoteToAdministrator: boolean;
  featurePermissionShowHistory: boolean;
  featurePermissionVerticalFilterAccess: boolean;
  featurePermissionShowPriorityFilter: boolean;
  featurePermissionShowUrlParams: boolean;
  featurePermissionPlayerFilterAccess: boolean;
  featurePermissionPlayerRoleFilterAccess: boolean;
  featurePermissionShowDates: boolean;
  featurePermissionShowAuditPlayerRadio: boolean;
  featurePermissionShowAuditRelationshipRadio: boolean;
}

type InternalPowerUser = 1;
type ExternalUser = 3;
type ExternalQAManager = 5;

type AdminRole = InternalPowerUser | ExternalUser | ExternalQAManager;

export const AdminRoles: Readonly<{
  InternalPowerUser: InternalPowerUser;
  ExternalUser: ExternalUser;
  ExternalQAManager: ExternalQAManager;
}> = {
  InternalPowerUser: 1,
  ExternalUser: 3,
  ExternalQAManager: 5,
};

export interface BRRolePermissions {
  tabs: TabRolePermissionsState;
  actions: ActionsRolePermissionsState;
  display: DisplayRolePermissionsState;
  role: AdminRole;
}

export const formatRolePermissions = (
  permissionsResponse: GetUserRoleFeaturesResponse
): BRRolePermissions => {
  const { idRole, features: permissions } = permissionsResponse;
  return {
    tabs: {
      featurePermissionReview:
        permissions.indexOf('ReviewRecordsAccess') !== -1,
      featurePermissionPendingApproval:
        permissions.indexOf('PendingApprovalRecordsAccess') !== -1,
      featurePermissionPendingRejection:
        permissions.indexOf('PendingRejectionRecordsAccess') !== -1,
      featurePermissionApproved:
        permissions.indexOf('ApprovedRecordsAccess') !== -1,
      featurePermissionRejected:
        permissions.indexOf('RejectedRecordsAccess') !== -1,
    },
    actions: {
      featurePermissionApproveRecords:
        permissions.indexOf('ApproveRecord') !== -1,
      featurePermissionAuditVendors: permissions.indexOf('AuditVendors') !== -1,
      featurePermissionRejectRecords:
        permissions.indexOf('RejectRecord') !== -1,
      featurePermissionSavePendingRecords:
        permissions.indexOf('SavePendingRecord') !== -1,
      featurePermissionPreApproveRecords:
        permissions.indexOf('PreApproveRecord') !== -1,
      featurePermissionPreRejectRecords:
        permissions.indexOf('PreRejectRecord') !== -1,
      featurePermissionMergeRecords: permissions.indexOf('MergeRecords') !== -1,
      featurePermissionDuplicateRecords:
        permissions.indexOf('DuplicateRecords') !== -1,
      featurePermissionBulkActions: permissions.indexOf('BulkActions') !== -1,
    },
    display: {
      featurePermissionDisplayDuplicates:
        permissions.indexOf('DetectDuplicates') !== -1,
      featurePermissionAddNewRecords:
        permissions.indexOf('AddNewRecords') !== -1,
      featurePermissionUpdateNews: permissions.indexOf('UpdateNews') !== -1,
      featurePermissionCreatePreApprovedRecord:
        permissions.indexOf('CreatePreApprovedRecord') !== -1,
      featurePermissionCreateApprovedRecord:
        permissions.indexOf('CreateApprovedRecord') !== -1,
      featurePermissionSearchByEntity:
        permissions.indexOf('SearchByEntity') !== -1,
      featurePermissionViewEntityLinks:
        permissions.indexOf('ViewEntityLinks') !== -1,
      featurePermissionShowNoteToAdministrator:
        permissions.indexOf('ShowNotes') !== -1,
      featurePermissionShowHistory: permissions.indexOf('ShowHistory') !== -1,
      featurePermissionVerticalFilterAccess:
        permissions.indexOf('VerticalFilterAccess') !== -1,
      featurePermissionShowPriorityFilter:
        permissions.indexOf('ShowPriorityFilter') !== -1,
      featurePermissionShowUrlParams:
        permissions.indexOf('ShowUrlParams') !== -1,
      featurePermissionPlayerFilterAccess:
        permissions.indexOf('PlayerFilterAccess') !== -1,
      featurePermissionPlayerRoleFilterAccess:
        permissions.indexOf('PlayerRoleFilterAccess') !== -1,
      featurePermissionShowDates: permissions.indexOf('ShowDates') !== -1,
      featurePermissionShowAuditPlayerRadio:
        permissions.indexOf('ShowAuditPlayerRadio') !== -1,
      featurePermissionShowAuditRelationshipRadio:
        permissions.indexOf('ShowAuditRelationshipRadio') !== -1,
    },
    role: idRole as AdminRole,
  };
};

export const useGetBRUserPermissions = (enabled = false) => {
  // A query function can be literally any function that returns a promise.
  const query = useQuery<
    GetUserRoleFeaturesResponse,
    Error,
    BRRolePermissions,
    [QueryKeys]
  >(
    // query is used to identify a cached version, change on this array, will cause refetch a promise
    ['GetUserRoleFeatures'],
    () => getUserRoleFeatures(),
    {
      select: (response) => formatRolePermissions(response),
      notifyOnChangeProps: ['status', 'isFetched', 'data'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );

  return query;
};
