import { forEach } from 'lodash';
import { saveRecordsOrder } from 'client/modules/common/utils/recordsHelper';
import * as actions from '../actions/all-session-data';
import { SET_ALL_SESSION_DATA } from '../utils/api-request-helper';

const baseUrl = '/account-sharing-detection/suspicious-user-sessions';
export const createUrl = (idUser, email, startDate, endDate) => {
  return `${baseUrl}?startDate=${startDate}&endDate=${endDate}&searchId=${idUser}&searchType=1&searchTerm=${email}`;
};

export const formatPayload = (payload) => {
  let counter = 0;
  const records = {};
  forEach(payload.suspiciousUserSessionsOverview, (info) => {
    counter += 1;
    records[counter] = {
      ...info,
      id: counter.toString(),
      email: info.user.email,
      team: info.teamInfo.name,
      csm: info.teamInfo.csm,
      numberOfSuspiciousSessions: info.numberOfSuspiciousSessions,
      sessionDetails: createUrl(
        info.user.idUser,
        info.user.email,
        payload.startDate,
        payload.endDate
      ),
    };
  });
  return records;
};

const initialState = {
  allSessionData: {},
  records: {},
  recordsOrder: [],
  pageNumber: 0,
  recordsPerPage: 10,
  totalNumberOfRecords: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ALL_SESSION_DATA: {
      const formattedResponse = formatPayload(action.payload);
      return {
        ...state,
        records: formatPayload(action.payload),
        recordsOrder: saveRecordsOrder(formattedResponse),
        totalNumberOfRecords: action.payload.totalNumberOfUsers,
      };
    }
    case actions.RESET_ALL_SESSION_DATA: {
      return initialState;
    }
    case actions.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: parseInt(action.pageNumber, 10),
      };
    case actions.UPDATE_RECORDS_PER_PAGE:
      return {
        ...state,
        recordsPerPage: parseInt(action.recordsPerPage, 10),
      };
    default:
      return state;
  }
}
