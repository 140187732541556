import { UseQueryResult, useQuery } from 'react-query';
import { GetPasswordResetTokenResponse } from '@cbinsights/userv2service/userv2service';
import { GetPasswordResetToken } from '.';
import { getPasswordResetToken } from '../api/GetPasswordResetToken';

type Params = Parameters<typeof getPasswordResetToken>[0];
type Return = GetPasswordResetTokenResponse;

export const useGetPasswordResetToken = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetPasswordResetToken, Params]>(
    ['GetPasswordResetToken', params],
    () => getPasswordResetToken(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
