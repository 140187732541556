const smoothScrollUp = (currentY, destination, scrollSpeed) => {
  if (currentY > destination) {
    setTimeout(() => {
      window.scrollTo(0, currentY - 20);
      smoothScrollUp(currentY - 20, destination, scrollSpeed);
    }, scrollSpeed);
  }
};

const smoothScrollDown = (currentY, destination, scrollSpeed) => {
  if (currentY < destination) {
    setTimeout(() => {
      window.scrollTo(0, currentY + 20);
      smoothScrollDown(currentY + 20, destination, scrollSpeed);
    }, scrollSpeed);
  }
};

const smoothScroll = ({ destination, direction, scrollSpeed }) => {
  const currentY = window.pageYOffset || document.documentElement.scrollTop;
  if (!direction || direction === 'up') {
    return smoothScrollUp(currentY, destination || 0, scrollSpeed || 5);
  }
  const pageHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight
  );
  return smoothScrollDown(
    currentY,
    destination || pageHeight,
    scrollSpeed || 5
  );
};

export default smoothScroll;
