import React from 'react';
import { RouteObject } from 'react-router-dom';

import { UserProtectedRoute } from 'client/routes/UserProtectedRoute';
import { trialPackagePermissions } from './permissions/TrialPackage';
import { TrialPackageRoutes } from './RouteHelpers';
import { TrialPackagePage } from './components/TrialPackagePage';

export const trialPackageRoutes: RouteObject = {
  path: TrialPackageRoutes.PageIndex,
  element: (
    <UserProtectedRoute pageId={trialPackagePermissions.pageId}>
      <TrialPackagePage />
    </UserProtectedRoute>
  ),
};
