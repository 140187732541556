const abbreviateNumber = (originalNumber) => {
  const number = parseInt(originalNumber, 10) || null;
  if (number === null) {
    return number;
  }
  if (number < 1000) {
    return number.toString();
  }

  const units = ['k', 'M', 'B', 'T'];
  const unit = Math.floor((number.toFixed(0).length - 1) / 3) * 3;

  // Calculate the remainder
  const num = number / `1e${unit}`;
  const unitname = units[Math.floor(unit / 3) - 1];
  return num + unitname;
};

export default abbreviateNumber;
