import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';

class RecordIdCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const [recordId] = this.props.recordId.split('-');
    return (
      <span style={{ paddingTop: '10px' }} data-test={`${recordId}-record-id`}>
        {`ID: ${recordId}`}
      </span>
    );
  }
}

RecordIdCell.propTypes = {
  recordId: PropTypes.string,
};

RecordIdCell.defaultProps = {
  recordId: 'New Record',
};

export default RecordIdCell;
