import { combineReducers } from 'redux';
import {
  AutocompleteInputState,
  autocompleteInputReducer,
} from './autocomplete-input';
import {
  RecordValidationState,
  recordsValidationReducer,
} from './records-validation';
import { modifiedRecordsReducer } from './modified-records';

export interface TableState {
  autocompleteInput: AutocompleteInputState;
  recordsValidation: RecordValidationState;
  modifiedRecords: $TSFixMe;
}

const table = combineReducers({
  autocompleteInput: autocompleteInputReducer,
  recordsValidation: recordsValidationReducer,
  modifiedRecords: modifiedRecordsReducer,
});

export default table;
