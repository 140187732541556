export const candidateColumns = [
  {
    name: 'Component Type',
    key: 'label',
    sort: false,
    includes: [{ type: 'text', key: 'label' }],
  },
  {
    name: 'Component ID',
    key: 'id',
    sort: false,
    includes: [{ type: 'text', key: 'id' }],
  },
  {
    name: 'Remove',
    key: 'whodafuqcares',
    sort: false,
    includes: [{ type: 'removeComponentCandidateLink', key: 'id' }],
  },
];

export const suggestedComponentColumns = [
  {
    name: 'Component Type',
    key: 'label',
    sort: false,
    includes: [{ type: 'text', key: 'label' }],
  },
  {
    name: 'Component ID',
    key: 'id',
    sort: false,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    includes: [{ type: 'text', key: 'id' }],
  },
  {
    name: 'Add',
    key: 'whodafuqcares',
    sort: false,
    includes: [{ type: 'suggestedComponentLink', key: 'id' }],
  },
];

export const componentSideBarColumns = [
  {
    name: 'Property',
    key: 'label',
    sort: false,
    width: '50%',
    includes: [{ type: 'text', key: 'label' }],
  },
  {
    name: 'Value',
    key: 'value',
    sort: false,
    width: '50%',
    includes: [{ type: 'text', key: 'value' }],
  },
];

export const componentPropsColumns = [
  {
    name: 'Property',
    key: 'label',
    sort: false,
    width: '25%',
    includes: [{ type: 'text', key: 'label' }],
  },
  {
    name: 'Value',
    key: 'value',
    sort: false,
    width: '75%',
    includes: [{ type: 'vertexPropertyField', key: 'value' }],
  },
];

export const pipelineListColumns = [
  {
    name: 'Pipeline Name',
    key: 'pipelineName',
    sortKey: 'pipeline_name',
    width: '170px',
    sort: true,
    includes: [{ type: 'pipelineLink', key: 'pipelineName' }],
  },
  {
    name: 'Health',
    key: 'health',
    sortKey: 'health',
    width: '90px',
    sort: true,
    includes: [{ type: 'healthColumn', key: 'health' }],
  },
  {
    name: 'Processors',
    key: 'processorCount',
    width: '80px',
    sort: false,
    includes: [{ type: 'text', key: 'processorCount' }],
  },
  {
    name: 'Tables',
    key: 'tableCount',
    width: '80px',
    sort: false,
    includes: [{ type: 'text', key: 'tableCount' }],
  },
  {
    name: 'Queues',
    key: 'queueCount',
    width: '80px',
    sort: false,
    includes: [{ type: 'text', key: 'queueCount' }],
  },
  {
    name: 'Topics',
    key: 'topicCount',
    width: '80px',
    sort: false,
    includes: [{ type: 'text', key: 'topicCount' }],
  },
  {
    name: 'Created By',
    key: 'createdBy',
    sortKey: 'created_by',
    width: '170px',
    sort: true,
    includes: [{ type: 'text', key: 'createdBy' }],
  },
  {
    name: 'Last Updated By',
    key: 'lastUpdatedBy',
    sortKey: 'last_updated_by',
    width: '170px',
    sort: true,
    includes: [{ type: 'text', key: 'lastUpdatedBy' }],
  },
  {
    name: 'Last Updated',
    key: 'lastUpdatedTs',
    sortKey: 'last_updated_date',
    width: '100px',
    sort: true,
    includes: [{ type: 'text', key: 'lastUpdatedTs' }],
  },
  {
    name: 'Remove',
    key: 'dummy',
    width: '51px',
    sort: false,
    includes: [{ type: 'removePipelineLink', key: 'piplineName' }],
  },
];

export const pipelineUserColumns = [
  {
    name: 'Name',
    key: 'fullName',
    sort: false,
    includes: [{ type: 'text', key: 'fullName' }],
  },
  {
    name: 'Remove',
    key: 'remove',
    sort: false,
    includes: [{ type: 'removeExistingPipelineUserLink', key: 'idUser' }],
  },
];

export const adminUserColumns = [
  {
    name: 'Name',
    key: 'fullName',
    sort: false,
    includes: [{ type: 'text', key: 'fullName' }],
  },
  {
    name: 'Add',
    key: 'add',
    sort: false,
    includes: [{ type: 'addNewUserLink', key: 'idUser' }],
  },
];

export const newUserColumns = [
  {
    name: 'Name',
    key: 'fullName',
    sort: false,
    includes: [{ type: 'text', key: 'fullName' }],
  },
  {
    name: 'Operation',
    key: 'actionType',
    sort: false,
    includes: [{ type: 'text', key: 'actionType' }],
  },
  {
    name: 'Remove',
    key: 'remove',
    sort: false,
    includes: [{ type: 'removeNewUserLink', key: 'idUser' }],
  },
];
