import { initializeThirdPartyResources } from 'client/modules/third-party-resources/index';
import { request } from 'client/modules/common/utils/request';

export const SET_USER_AUTHENTICATION_INFO =
  'client/modules/user/actions/user/SET_USER_AUTHENTICATION_INFO';
export const FETCH_USER_DATA =
  'client/modules/user/actions/user/FETCH_USER_DATA';

export const fetchUserDataHelper = () => ({
  types: [null, FETCH_USER_DATA],
  apicall: {
    endpoint: '/getUserInfo',
    method: 'GET',
  },
});

export const fetchUserData = () => {
  return (dispatch) => {
    return request({
      method: 'get',
      url: '/getUserInfo',
    }).then(({ body: userInfo }) => {
      dispatch({
        type: FETCH_USER_DATA,
        payload: userInfo,
      });
      initializeThirdPartyResources(userInfo);
    });
  };
};

export const setUserAuthenticationInfo = (payload) => {
  return { type: SET_USER_AUTHENTICATION_INFO, payload };
};

export const validateAuthenticationResponse = (response) => {
  if (!response) {
    return false;
  }
  if (!response.isLoggedIn) {
    if (window.envVars.BUILD_MODE === 'local') {
      if (!window.location.href.includes('/local-login?goto=')) {
        window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/local-login?goto=${window.location.href}`;
      }
    } else if (!window.location.href.includes('/login?goto=')) {
      window.location.href = `${window.envVars.APP_DOMAIN}/login?goto=${window.location.href}`;
    }
    return false;
  }
  if (!response.isAdminUser) {
    if (!window.location.href.includes('/feed')) {
      window.location.href = `${window.envVars.APP_DOMAIN}/feed`;
    }
    return false;
  }
  return true;
};
