import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ROUTES } from './constants';

const DomainBlockPage = React.lazy(
  () => import('./components/DomainBlockPage')
);
const { root } = ROUTES;

export const domainBlockRoutes: RouteObject = {
  path: root.path,
  element: (
    <React.Suspense fallback={undefined}>
      <DomainBlockPage />
    </React.Suspense>
  ),
};
