import {
  GetUserPackageHistoryRequest,
  GetUserPackageHistoryResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUserPackageHistory = (
  req: Partial<GetUserPackageHistoryRequest>
): Promise<GetUserPackageHistoryResponse> =>
  request({
    url: '/service/adminservice/GetUserPackageHistory',
    body: req,
  }).then((r) => r.body);
