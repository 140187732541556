import React, { ReactElement } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Col, Row, Table } from 'antd';
import { FormerTeamMemberData } from '../../hooks/useTeamMembers';
import useShowPagination from '../../hooks/useShowPagination';

type Props = {
  columns: ColumnsType<FormerTeamMemberData>;
  data: FormerTeamMemberData[];
};

export const FormerTeamMembers = ({ columns, data }: Props): ReactElement => {
  const showPagination = useShowPagination({ data, config: { pageSize: 25 } });

  return (
    <Row className="mb-10">
      <Col span="24">
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={showPagination}
        />
        <br />
      </Col>
    </Row>
  );
};

export default FormerTeamMembers;
