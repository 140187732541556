import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from 'client/component-library/Grid';
import Tabs from 'client/modules/common/components/Tabs';
import compose from 'recompose/compose';
import { withStyles } from 'client/component-library/styles';
import { getKeyValueFromListViaOtherKey } from 'client/modules/common/utils/objectHelper';
import { withReactRouter } from 'client/routes/withReactRouter';
import { setCurrentTab } from '../redux/actions/tab';
import {
  getTabOptions,
  PIPELINE_LIST,
  PIPELINE_GRAPH,
  COMPONENT_DETAILS,
  PIPELINE_LIST_ROUTE,
  PIPELINE_GRAPH_ROUTE,
  COMPONENT_DETAILS_ROUTE,
} from './utils/get-tab-options';
import styles from './styles/material-ui/dataPlatformTabsStyles';

class DataPlatformTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: `/data-platform/${PIPELINE_LIST_ROUTE}`,
      tabOptions: getTabOptions(props.tabs),
    };

    this.updateCurrentTab = this.updateCurrentTab.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { dispatch, location } = this.props;
    let currentTab = PIPELINE_LIST;
    if (location.pathname === `/data-platform/${PIPELINE_LIST_ROUTE}`) {
      currentTab = PIPELINE_LIST;
    } else if (location.pathname === `/data-platform/${PIPELINE_GRAPH_ROUTE}`) {
      currentTab = PIPELINE_GRAPH;
    } else if (
      location.pathname === `/data-platform/${COMPONENT_DETAILS_ROUTE}`
    ) {
      currentTab = COMPONENT_DETAILS;
    }
    return dispatch(setCurrentTab(currentTab));
  }

  updateCurrentTab(event, value) {
    /* eslint-disable max-len */
    // Will need once we add pagination to pipeline list
    // this.props.dispatch(updatePageNumber(0));
    const route = `/data-platform/${value.toLowerCase()}?pipelineName=${
      this.props.pipelineName || ''
    }&componentId=${this.props.currentComponentId || ''}`;
    /* eslint-enable max-len */
    this.props.navigation(route);
  }

  render() {
    const { currentTab, classes } = this.props;
    const currentRoute = getKeyValueFromListViaOtherKey(
      this.state.tabOptions,
      'tabName',
      currentTab,
      'route'
    );
    return (
      <Grid container spacing={1} className={classes.dpTabs}>
        <Grid item xs={12}>
          <Tabs
            classes={classes}
            onChange={this.updateCurrentTab}
            options={this.state.tabOptions}
            current={currentRoute}
          />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTab: state.dataPlatform.tab,
    pipelineName: state.dataPlatform.search.pipelineName,
    currentComponentId: state.dataPlatform.search.centralVertexId,
  };
}

DataPlatformTabs.propTypes = {
  location: PropTypes.object.isRequired,
  navigation: PropTypes.func.isRequired,
  currentTab: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.object,
  pipelineName: PropTypes.string,
  currentComponentId: PropTypes.string,
};

DataPlatformTabs.defaultProps = {
  currentTab: PIPELINE_LIST,
  tabs: {},
  pipelineName: '',
  currentComponentId: '',
};

export default compose(
  withReactRouter,
  withStyles(styles, { name: 'DataPlatformTabs' }),
  connect(mapStateToProps)
)(DataPlatformTabs);
