import React from 'react';
import PropTypes from 'prop-types';

import UserPermissionWrapper from 'client/modules/user/components/UserPermissionWrapper';
import {
  CustomNotification,
  FailureNotification,
  SuccessNotification,
} from 'client/modules/common/components/Messages/';

import NewsQueueContainer from 'client/modules/news/queue/containers/NewsQueueContainer';
import QueueComponent from 'client/modules/news/queue/containers/UserQueue';
import QueueManagementComponent from 'client/modules/news/queue/containers/QueueManagement';
import UserStatusComponent from 'client/modules/news/user-stats/containers/UserStats';
import { ManageFilterGroups } from './filter-groups/containers/ManageFilterGroups';

// values from db table cbi_user.admin_page_master
const NEWS_QUEUE_CLUSTERS = 62;
const NEWS_QUEUE = 17;
const USER_STATS = 35;
const QUEUE_MANAGEMENT = 38;
const MANAGE_GROUPS = 39;

const NewsWrapper = ({ component, idPage }) => (
  <UserPermissionWrapper idPage={idPage}>
    <SuccessNotification />
    <FailureNotification />
    <CustomNotification />
    {component}
  </UserPermissionWrapper>
);

NewsWrapper.propTypes = {
  component: PropTypes.element.isRequired,
  idPage: PropTypes.number.isRequired,
};

export const Clusters = () => (
  <NewsWrapper
    component={<NewsQueueContainer />}
    idPage={NEWS_QUEUE_CLUSTERS}
  />
);

export const QueueManagement = () => (
  <NewsWrapper
    component={<QueueManagementComponent />}
    idPage={QUEUE_MANAGEMENT}
  />
);

export const Queue = () => (
  <NewsWrapper component={<QueueComponent />} idPage={NEWS_QUEUE} />
);

export const UserStatus = () => (
  <NewsWrapper component={<UserStatusComponent />} idPage={USER_STATS} />
);

export const FilterGroups = () => (
  <NewsWrapper component={<ManageFilterGroups />} idPage={MANAGE_GROUPS} />
);
