/* eslint-disable no-else-return */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { InitialPageLayout } from 'client/modules/common/containers/InitialPageLayout';
// Important note: if a user doesn't have access to the admin, they'll be
// redirected to the feed when adminPagePermissions is initially set.

export class UserPermissionWrapper extends Component {
  render() {
    const {
      adminPagePermissions,
      children,
      idPage,
      addPadding,
      defaultTailwindFont,
    } = this.props;
    const oldWayAccess = adminPagePermissions[idPage] === 1;
    const newWayAccess =
      adminPagePermissions[idPage] &&
      adminPagePermissions[idPage].access === true;
    if (isEmpty(adminPagePermissions)) {
      return null;
    } else if (!oldWayAccess && !newWayAccess) {
      window.location.href = '/403';
      return null;
    }
    return (
      <InitialPageLayout
        addPadding={addPadding}
        defaultTailwindFont={defaultTailwindFont}
      >
        {children}
      </InitialPageLayout>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    adminPagePermissions: user.data.adminPagePermissions,
  };
}

UserPermissionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  idPage: PropTypes.number.isRequired,
  adminPagePermissions: PropTypes.object,
  addPadding: PropTypes.bool,
  defaultTailwindFont: PropTypes.bool,
};

UserPermissionWrapper.defaultProps = {
  adminPagePermissions: {},
  addPadding: true,
  defaultTailwindFont: true,
};

export default connect(mapStateToProps)(UserPermissionWrapper);
