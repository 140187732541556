import { GetUserRoleFeaturesResponse } from '@cbinsights/businessrelationshipservice/businessrelationshipservice';
import { request } from 'client/modules/common/utils/request';

export const getUserRoleFeatures = () => {
  return request({
    url: '/service/businessrelationshipservice/GetUserRoleFeatures',
  }).then((r) => {
    return r.body as GetUserRoleFeaturesResponse;
  });
};
