import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { useRemoveUsersFromTeam } from '../../../hooks/useRemoveUsersFromTeam';

type Props = {
  isOpen: boolean;
  email: string;
  userId: number;
  teamId: number;
  closeModal: () => void;
  refetchTeamMembers: () => void;
};

const RemoveUserModal = ({
  isOpen,
  email,
  userId,
  teamId,
  closeModal,
  refetchTeamMembers,
}: Props) => {
  const [confirmEmail, setConfirmEmail] = useState('');
  const { onSubmit } = useRemoveUsersFromTeam();

  const handleChange = (event) => {
    setConfirmEmail(event.target.value);
  };

  const handleClose = () => {
    setConfirmEmail('');
    closeModal();
  };

  const handleOk = () => {
    const users = [{ idUser: userId, idTeam: teamId }];
    onSubmit({ users })
      .then(() => {
        message.success('User has been removed');
        refetchTeamMembers();
      })
      .catch(() => {
        message.error('Unable to remove user. Please try again later.');
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Modal
      title="Are you sure you want to remove this user?"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      okButtonProps={{ disabled: confirmEmail !== email }}
      width={750}
    >
      <div>
        <p>{`To remove this user from the team, type "${email}" and click "OK".`}</p>
        <input
          type="text"
          value={confirmEmail}
          onChange={handleChange}
          className="mt-3 w-full"
          width="100%"
        />
      </div>
    </Modal>
  );
};

export default RemoveUserModal;
