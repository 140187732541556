import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MaterialUILink } from 'client/component-library/Link';
import { fetchAdditionalComponentCandidate } from '../redux/actions/search';

class SuggestedComponentLink extends Component {
  onClickComponent = () => {
    this.props.fetchAdditionalComponentCandidate({
      idVertex: this.props.componentId,
      pageNumber: this.props.newVertexesPageNumber,
      recordsPerPage: this.props.newVertexesRecordsPerPage,
      excludeLabels: this.props.suggestedExcludeLabels,
    });
  };

  render() {
    return <MaterialUILink onClick={this.onClickComponent}>add</MaterialUILink>;
  }
}

function mapStateToProps({ dataPlatform }) {
  return {
    newVertexesPageNumber: dataPlatform.details.newVertexesPageNumber,
    newVertexesRecordsPerPage: dataPlatform.details.newVertexesRecordsPerPage,
    suggestedExcludeLabels: dataPlatform.search.suggestedExcludeLabels,
  };
}

SuggestedComponentLink.propTypes = {
  componentId: PropTypes.string.isRequired,
  fetchAdditionalComponentCandidate: PropTypes.func.isRequired,
  newVertexesPageNumber: PropTypes.number.isRequired,
  newVertexesRecordsPerPage: PropTypes.number.isRequired,
  suggestedExcludeLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SuggestedComponentLink.defaultProps = {
  suggestedExcludeLabels: [],
};

export default connect(mapStateToProps, { fetchAdditionalComponentCandidate })(
  SuggestedComponentLink
);
