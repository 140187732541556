// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__dropdownItem___Q__jN {
  padding: 2px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 13px;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__highlightedDropdownItem___FhJP7 {
  padding: 2px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 13px;
  background-color: #00986b;
  color: white;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__dropdownItemNoCursor___o8MwH {
  padding: 2px 10px;
  border-bottom: 1px solid #eee;
  font-size: 13px;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__selected___V98Yb {
  background-color: #005aae;
  color: white;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__notSelected___PkoyB {
  color: #005aae;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__ellipsis___Ux4Wc {
  display: -webkit-box;
  max-width: 100%;
  height: 3em;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__notSelectedEllipsis___XbV3V {
  color: darkgrey;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__selectedEllipsis___bfPDR {
  color: lightgrey;
}

.client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__btnStyle___ki7LV {
  background-color: white !important;
  min-width: 50px !important;
  margin-top: -3px !important;
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/AutocompleteDropdown/styles/dropdownItem.css"],"names":[],"mappings":"AAIA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AAEjB;;AAEA;EACE,gBAAgB;AAElB;;AAEA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value darkGrey from colors;\n@value lightGrey from colors;\n\n.dropdownItem {\n  padding: 2px 10px;\n  border-bottom: 1px solid #eee;\n  cursor: pointer;\n  font-size: 13px;\n}\n\n.highlightedDropdownItem {\n  padding: 2px 10px;\n  border-bottom: 1px solid #eee;\n  cursor: pointer;\n  font-size: 13px;\n  background-color: #00986b;\n  color: white;\n}\n\n.dropdownItemNoCursor {\n  padding: 2px 10px;\n  border-bottom: 1px solid #eee;\n  font-size: 13px;\n}\n\n.selected {\n  background-color: #005aae;\n  color: white;\n}\n\n.notSelected {\n  color: #005aae;\n}\n\n.ellipsis {\n  display: -webkit-box;\n  max-width: 100%;\n  height: 3em;\n  line-height: 1.4;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.notSelectedEllipsis {\n  color: darkgrey;\n  composes: ellipsis;\n}\n\n.selectedEllipsis {\n  color: lightgrey;\n  composes: ellipsis;\n}\n\n.btnStyle {\n  background-color: white !important;\n  min-width: 50px !important;\n  margin-top: -3px !important;\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"darkGrey": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["darkGrey"]}`,
	"lightGrey": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["lightGrey"]}`,
	"dropdownItem": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__dropdownItem___Q__jN`,
	"highlightedDropdownItem": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__highlightedDropdownItem___FhJP7`,
	"dropdownItemNoCursor": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__dropdownItemNoCursor___o8MwH`,
	"selected": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__selected___V98Yb`,
	"notSelected": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__notSelected___PkoyB`,
	"ellipsis": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__ellipsis___Ux4Wc`,
	"notSelectedEllipsis": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__notSelectedEllipsis___XbV3V client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__ellipsis___Ux4Wc`,
	"selectedEllipsis": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__selectedEllipsis___bfPDR client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__ellipsis___Ux4Wc`,
	"btnStyle": `client-modules-common-components-AutocompleteDropdown-styles-___dropdownItem__btnStyle___ki7LV`
};
export default ___CSS_LOADER_EXPORT___;
