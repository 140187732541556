import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';

const highlightTextStyle = { color: '#f50057', fontWeight: 'bold' };

class InnerTableTextCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { value, highlightText } = this.props;

    return <div style={highlightText ? highlightTextStyle : null}>{value}</div>;
  }
}

InnerTableTextCell.propTypes = {
  value: PropTypes.string,
  highlightText: PropTypes.bool,
};

InnerTableTextCell.defaultProps = {
  value: '',
  highlightText: false,
};

export default InnerTableTextCell;
