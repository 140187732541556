import {
  getElementByObjectKey,
  getTabOptions,
} from '../../components/utils/get-tab-options';
import { resetPageNumber } from '../utils/admin-user-helpers';

/* eslint-disable max-len */
export const FETCH_ALL_DATA_PLATFORM_USERS =
  'client/modules/data-platform/actions/search/FETCH_ALL_DATA_PLATFORM_USERS';
export const UPDATE_ADMIN_USER_PAGE_NUMBER =
  'client/modules/data-platform/actions/UPDATE_ADMIN_USER_PAGE_NUMBER';
export const UPDATE_ADMIN_USER_RECORDS_PER_PAGE =
  'client/modules/data-platform/actions/UPDATE_ADMIN_USER_RECORDS_PER_PAGE';
export const UPDATE_NEW_USER_PAGE_NUMBER =
  'client/modules/data-platform/actions/UPDATE_NEW_USER_PAGE_NUMBER';
export const UPDATE_NEW_USER_RECORDS_PER_PAGE =
  'client/modules/data-platform/actions/UPDATE_NEW_USER_RECORDS_PER_PAGE';
export const SET_DISPLAY_NEW_USERS =
  'client/modules/data-platform/actions/SET_DISPLAY_NEW_USERS';
export const UPDATE_PIPELINE_USER_PAGE_NUMBER =
  'client/modules/data-platform/actions/UPDATE_PIPELINE_USER_PAGE_NUMBER';
export const UPDATE_PIPELINE_USER_RECORDS_PER_PAGE =
  'client/modules/data-platform/actions/UPDATE_PIPELINE_USER_RECORDS_PER_PAGE';
export const SET_DISPLAY_PIPELINE_USERS =
  'client/modules/data-platform/actions/SET_DISPLAY_PIPELINE_USERS';
export const SET_DISPLAY_ADMIN_USERS =
  'client/modules/data-platform/actions/SET_DISPLAY_ADMIN_USERS';
export const ADD_NEW_ADMIN_USER =
  'client/modules/data-platform/actions/ADD_NEW_ADMIN_USER';
export const REMOVE_EXISTING_PIPELINE_USER =
  'client/modules/data-platform/actions/REMOVE_EXISTING_PIPELINE_USER';
export const CLEAR_NEW_USERS =
  'client/modules/data-platform/actions/CLEAR_NEW_USERS';
export const INITIALIZE_PIPELINE_USERS =
  'client/modules/data-platform/actions/INITIALIZE_PIPELINE_USERS';
export const REMOVE_NEW_USER =
  'client/modules/data-platform/actions/REMOVE_NEW_USER';
export const SET_ADMIN_USER_FILTER_TERM =
  'client/modules/data-platform/actions/SET_ADMIN_USER_FILTER_TERM';
/* eslint-enable max-len */

export const getUsersWithAccessToDataPlatformAction = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetInfoForUsersWithAccessToPage',
    name: 'adminservice',
    body: { idPage: 52 },
  },
});

export const getUsersWithAccessToDataPlatformHelper = (users) => {
  return { type: FETCH_ALL_DATA_PLATFORM_USERS, users };
};

export const getUsersWithAccessToDataPlatform = () => {
  return (dispatch, getState) => {
    if (getState().users && getState().users.length) {
      return null;
    }
    return dispatch(getUsersWithAccessToDataPlatformAction()).then((data) => {
      return dispatch(getUsersWithAccessToDataPlatformHelper(data.userInfo));
    });
  };
};

export function updateAdminUserPageNumber(pageNumber) {
  return { type: UPDATE_ADMIN_USER_PAGE_NUMBER, pageNumber };
}

export function updateAdminUserRecordsPerPage(recordsPerPage) {
  return { type: UPDATE_ADMIN_USER_RECORDS_PER_PAGE, recordsPerPage };
}

export function updateNewUserPageNumber(pageNumber) {
  return { type: UPDATE_NEW_USER_PAGE_NUMBER, pageNumber };
}

export function updateNewUserRecordsPerPage(recordsPerPage) {
  return { type: UPDATE_NEW_USER_RECORDS_PER_PAGE, recordsPerPage };
}

export const setDisplayNewUsers = (users, pageNumber, recordsPerPage) => {
  return { type: SET_DISPLAY_NEW_USERS, users, pageNumber, recordsPerPage };
};

export function updatePipelineUserPageNumber(pageNumber) {
  return { type: UPDATE_PIPELINE_USER_PAGE_NUMBER, pageNumber };
}

export function updatePipelineUserRecordsPerPage(recordsPerPage) {
  return { type: UPDATE_PIPELINE_USER_RECORDS_PER_PAGE, recordsPerPage };
}

export const setDisplayPipelineUsers = (users, pageNumber, recordsPerPage) => {
  return {
    type: SET_DISPLAY_PIPELINE_USERS,
    users,
    pageNumber,
    recordsPerPage,
  };
};

export const setDisplayAdminUsers = ({
  adminUsers,
  pipelineUsers,
  newUsers,
  pageNumber,
  recordsPerPage,
  filterTerm,
}) => {
  return {
    type: SET_DISPLAY_ADMIN_USERS,
    adminUsers,
    pipelineUsers,
    newUsers,
    pageNumber,
    recordsPerPage,
    adminUserFilterTerm: filterTerm,
  };
};

export const setAdminUserFilterTerm = (filterTerm) => {
  return { type: SET_ADMIN_USER_FILTER_TERM, filterTerm };
};

export const addNewAdminUserAction = ({
  idUser,
  adminUsers,
  numAdminUsers,
  adminUserPageNumber,
  adminUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
  adminUserFilterTerm,
  pipelineUsers,
}) => {
  return {
    type: ADD_NEW_ADMIN_USER,
    idUser,
    adminUsers,
    numAdminUsers,
    adminUserPageNumber,
    adminUserRecordsPerPage,
    newUsers,
    numNewUsers,
    newUserPageNumber,
    newUserRecordsPerPage,
    adminUserFilterTerm,
    pipelineUsers,
  };
};

export const addNewAdminUser = ({
  idUser,
  adminUsers,
  numAdminUsers,
  adminUserPageNumber,
  adminUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
  adminUserFilterTerm,
}) => {
  return (dispatch, getState) => {
    const newPageNumber = resetPageNumber(
      numAdminUsers - 1,
      adminUserPageNumber,
      adminUserRecordsPerPage
    );
    const state = getState();
    const currentTabOptions = getElementByObjectKey(
      'tabName',
      state.dataPlatform.tab,
      getTabOptions()
    );
    let pipelineUsers = {};
    if (
      currentTabOptions.usePipelineState &&
      state.dataPlatform.users.pipelineUsers
    ) {
      pipelineUsers = state.dataPlatform.users.pipelineUsers;
    }
    dispatch(
      addNewAdminUserAction({
        idUser,
        adminUsers,
        numAdminUsers,
        adminUserPageNumber: newPageNumber,
        adminUserRecordsPerPage,
        newUsers,
        numNewUsers,
        newUserPageNumber,
        newUserRecordsPerPage,
        adminUserFilterTerm,
        pipelineUsers,
      })
    );
    dispatch(updateAdminUserPageNumber(newPageNumber));
  };
};

export const removeExistingPipelineUserAction = ({
  idUser,
  pipelineUsers,
  numPipelineUsers,
  pipelineUserPageNumber,
  pipelineUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
}) => {
  return {
    type: REMOVE_EXISTING_PIPELINE_USER,
    idUser,
    pipelineUsers,
    numPipelineUsers,
    pipelineUserPageNumber,
    pipelineUserRecordsPerPage,
    newUsers,
    numNewUsers,
    newUserPageNumber,
    newUserRecordsPerPage,
  };
};

export const removeExistingPipelineUser = ({
  idUser,
  pipelineUsers,
  numPipelineUsers,
  pipelineUserPageNumber,
  pipelineUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
}) => {
  return (dispatch) => {
    const newPageNumber = resetPageNumber(
      numPipelineUsers - 1,
      pipelineUserPageNumber,
      pipelineUserRecordsPerPage
    );
    dispatch(
      removeExistingPipelineUserAction({
        idUser,
        pipelineUsers,
        numPipelineUsers,
        pipelineUserPageNumber: newPageNumber,
        pipelineUserRecordsPerPage,
        newUsers,
        numNewUsers,
        newUserPageNumber,
        newUserRecordsPerPage,
      })
    );
    dispatch(updatePipelineUserPageNumber(newPageNumber));
  };
};

export const initializePipelineUsers = (users) => {
  return { type: INITIALIZE_PIPELINE_USERS, users };
};

export const removeNewUserAction = ({
  idUser,
  adminUsers,
  numAdminUsers,
  adminUserPageNumber,
  adminUserRecordsPerPage,
  pipelineUsers,
  numPipelineUsers,
  pipelineUserPageNumber,
  pipelineUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
  adminUserFilterTerm,
}) => {
  return {
    type: REMOVE_NEW_USER,
    idUser,
    adminUsers,
    numAdminUsers,
    adminUserPageNumber,
    adminUserRecordsPerPage,
    pipelineUsers,
    numPipelineUsers,
    pipelineUserPageNumber,
    pipelineUserRecordsPerPage,
    newUsers,
    numNewUsers,
    newUserPageNumber,
    newUserRecordsPerPage,
    adminUserFilterTerm,
  };
};

export const removeNewUser = ({
  idUser,
  adminUsers,
  numAdminUsers,
  adminUserPageNumber,
  adminUserRecordsPerPage,
  pipelineUsers,
  numPipelineUsers,
  pipelineUserPageNumber,
  pipelineUserRecordsPerPage,
  newUsers,
  numNewUsers,
  newUserPageNumber,
  newUserRecordsPerPage,
  adminUserFilterTerm,
}) => {
  return (dispatch) => {
    const newPageNumber = resetPageNumber(
      numNewUsers - 1,
      newUserPageNumber,
      newUserRecordsPerPage
    );
    dispatch(
      removeNewUserAction({
        idUser,
        adminUsers,
        numAdminUsers,
        adminUserPageNumber,
        adminUserRecordsPerPage,
        pipelineUsers,
        numPipelineUsers,
        pipelineUserPageNumber,
        pipelineUserRecordsPerPage,
        newUsers,
        numNewUsers,
        newUserPageNumber: newPageNumber,
        newUserRecordsPerPage,
        adminUserFilterTerm,
      })
    );
    dispatch(updateNewUserPageNumber(newPageNumber));
  };
};

export const clearNewUsers = () => {
  return { type: CLEAR_NEW_USERS };
};

export const resetManagePermissionsPage = () => {
  return (dispatch) => {
    dispatch(clearNewUsers());
    dispatch(getUsersWithAccessToDataPlatform());
    dispatch(updateNewUserPageNumber(0));
    dispatch(updateAdminUserPageNumber(0));
    dispatch(updatePipelineUserPageNumber(0));
    dispatch(setAdminUserFilterTerm(''));
  };
};
