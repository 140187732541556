// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../assets/styles/global-colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-modules-common-components-Messages-SuccessNotification-___success-notification-style__successNotification___lyEEW {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["green"]} !important;
}
`, "",{"version":3,"sources":["webpack://./client/modules/common/components/Messages/SuccessNotification/success-notification-style.css"],"names":[],"mappings":"AAGA;EACE,yEAAkC;AACpC","sourcesContent":["@value colors: \"client/assets/styles/global-colors.css\";\n@value green from colors;\n\n.successNotification {\n  background-color: green !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"client/assets/styles/global-colors.css"`,
	"green": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["green"]}`,
	"successNotification": `client-modules-common-components-Messages-SuccessNotification-___success-notification-style__successNotification___lyEEW`
};
export default ___CSS_LOADER_EXPORT___;
