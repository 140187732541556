// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
`, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `#FF0050`,
	"blue": `#006699`,
	"orange": `#FF6633`,
	"green": `#66BB6A`,
	"black": `#404040`,
	"yellow": `#FFF176`,
	"grey": `#BDBDBD`,
	"darkGrey": `#A9A9A9`,
	"lightGrey": `#E0E0E0`
};
export default ___CSS_LOADER_EXPORT___;
