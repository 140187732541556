import {
  InfoRequest,
  GeneralCbiEntityInfo,
} from '@cbinsights/cbientityservice/cbientityservice';
import { request } from 'client/modules/common/utils/request';

export const getGeneralCbiEntityInfo = (
  req: InfoRequest
): Promise<GeneralCbiEntityInfo> => {
  return request({
    url: '/service/cbientityservice/GetGeneralCbiEntityInfo',
    body: {
      ...req,
    },
  }).then((r): GeneralCbiEntityInfo => r.body);
};
