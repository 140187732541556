export const requestAllAdminUsers = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllAdminUsers',
    name: 'adminservice',
    body: {},
  },
});

export const requestAllCompanyEmployees = ({ includeUserFilters }) => ({
  types: [null, null],
  service: {
    endpoint: 'GetUsers',
    name: 'userv2service',
    body: { includeUserFilters },
  },
});

export const requestAllAdminPages = () => ({
  types: [null, null],
  service: {
    endpoint: 'GetAllAdminPages',
    name: 'adminservice',
    body: {},
  },
});

export const requestCreateAdminUser = ({ idUser, email, isActive }) => ({
  types: [null, null],
  service: {
    endpoint: 'CreateAdminUser',
    name: 'adminservice',
    body: { idUser, email, isActive },
  },
});

export const requestGetPagesUserCanAccess = (idUser) => ({
  types: [null, null],
  service: {
    endpoint: 'GetPagesUserCanAccess',
    name: 'adminservice',
    body: { idUser },
  },
});

export const requestGetUsersWithAccessToPage = (idPage) => ({
  types: [null, null],
  service: {
    endpoint: 'GetUsersWithAccessToPage',
    name: 'adminservice',
    body: { idPage },
  },
});

export const requestDeactivateAdminUser = (idUser) => ({
  types: [null, null],
  service: {
    endpoint: 'DeactivateAdminUser',
    name: 'adminservice',
    body: { idUser },
  },
});

export const requestUpdateAdminUserPagePermissions = ({
  idUser,
  pagePermissions,
}) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdateAdminUserPagePermissions',
    name: 'adminservice',
    body: { idUser, pagePermissions },
  },
});

export const requestUpdatePermissionsForPage = ({ idPage, userRoleInfo }) => ({
  types: [null, null],
  service: {
    endpoint: 'UpdatePermissionsForPage',
    name: 'adminservice',
    body: { idPage, userRoleInfo },
  },
});

export default requestAllAdminUsers;
