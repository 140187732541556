/*
  Turns an abbreviated number into it's numeric equivalent.
  Examples:
    5m -> 5000000
    2.5k -> 2500
    7b -> 7000000000
    1t -> 1000000000000

  Notes:
    1) The conversion is case insenstive, and strips spaces
    2) The function unabbreviate numbers that are above 1 thousand.
*/

const moveOverDecimalPlace = (indexOfDot, num, zerosToAdd) => {
  const newIndexOfDot = indexOfDot + zerosToAdd + 1;
  return `${num.slice(0, indexOfDot)}${num.slice(
    indexOfDot + 1,
    newIndexOfDot
  )}.${num.slice(newIndexOfDot)}`;
};

const convertAbbreviation = (
  numberToUnabbreviate,
  abbreviation,
  zerosToAdd
) => {
  const indexOfLetter = numberToUnabbreviate.indexOf(abbreviation);
  if (indexOfLetter === -1) {
    return null;
  }

  const indexOfDot = numberToUnabbreviate.indexOf('.');
  if (indexOfDot === -1) {
    return (
      (parseInt(numberToUnabbreviate.slice(0, indexOfLetter), 10) || 0) *
      10 ** zerosToAdd
    );
  }

  let zerosToAddString = '';
  for (let i = 0; i < zerosToAdd; i++) {
    zerosToAddString += '0';
  }

  const numberWithExtraZeros = `${numberToUnabbreviate.slice(
    0,
    indexOfLetter
  )}${zerosToAddString}`;
  const newNumber = moveOverDecimalPlace(
    indexOfDot,
    numberWithExtraZeros,
    zerosToAdd
  );
  return parseInt(newNumber, 10);
};

const unabbreviateAndConvertNumber = (numberToUnabbreviate) => {
  let validUnabbreviatedNumber = convertAbbreviation(
    numberToUnabbreviate,
    'k',
    3
  );
  if (validUnabbreviatedNumber) {
    return validUnabbreviatedNumber;
  }

  validUnabbreviatedNumber = convertAbbreviation(numberToUnabbreviate, 'm', 6);
  if (validUnabbreviatedNumber) {
    return validUnabbreviatedNumber;
  }

  validUnabbreviatedNumber = convertAbbreviation(numberToUnabbreviate, 'b', 9);
  if (validUnabbreviatedNumber) {
    return validUnabbreviatedNumber;
  }
  return convertAbbreviation(numberToUnabbreviate, 't', 12);
};

const checkIfInvalidNumber = (abbreviation) => {
  return isNaN(Number(abbreviation.slice(0, abbreviation.length - 1)));
};

const unabbreviateNumber = (abbreviatedNumber) => {
  if (typeof abbreviatedNumber === 'number') {
    return abbreviatedNumber;
  } if (typeof abbreviatedNumber !== 'string') {
    return null;
  }

  // If it doesn't have an abbreviation, just return the string as a number
  const containsAbbreviation = /[kmbt]/i.test(abbreviatedNumber);
  if (!containsAbbreviation) {
    const num = Number(abbreviatedNumber);
    return isNaN(num) ? null : num;
  }

  const formattedNumberToUnabbreviate = abbreviatedNumber
    .replace(/\s/g, '')
    .toLowerCase();
  if (checkIfInvalidNumber(formattedNumberToUnabbreviate)) {
    return null;
  }
  return unabbreviateAndConvertNumber(formattedNumberToUnabbreviate);
};

export default unabbreviateNumber;
