import {
  GetUserLoginHistoryRequest,
  GetUserLoginHistoryResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUserLoginHistory = (
  req: Partial<GetUserLoginHistoryRequest>
): Promise<GetUserLoginHistoryResponse> =>
  request({
    url: '/service/adminservice/GetUserLoginHistory',
    body: req,
  }).then((r) => r.body);
