import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useGetUserEmailEvents } from '../services/hooks/useGetUserEmailEvents';

type EntryType = {
  timestamp: string;
  subject: string;
  fromEmail: string;
};

const columns: ColumnsType<EntryType> = [
  {
    title: 'Subjet',
    key: 'subject',
    dataIndex: 'subject',
  },
  {
    title: 'From',
    key: 'fromEmail',
    dataIndex: 'fromEmail',
  },
  {
    title: 'Timestamp',
    key: 'timestamp',
    dataIndex: 'timestamp',
  },
];

const useUserKeyEventsTable = (idUser: number, enabled: boolean) => {
  const { data, isLoading } = useGetUserEmailEvents({
    enabled,
    params: { idUser },
  });

  const dataSource: Array<EntryType> = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.userEmailEvents.map((event) => ({
      timestamp: event.timestamp,
      subject: event.subject,
      fromEmail: event.fromEmail,
    }));
  }, [data]);

  return {
    columns,
    dataSource,
    isLoading,
  };
};

export default useUserKeyEventsTable;
