import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { withStyles } from 'client/component-library/styles';
import { FormControl } from 'client/component-library/Form';
import Selector from 'client/modules/common/components/Selector';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: '8px 0px',
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

/** @deprecated This class is a React component that renders a Selector component */
class SelectCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { classes, name, value, row, options, disabled, dataTest } =
      this.props;
    return (
      <FormControl className={classes.formControl}>
        <Selector
          disabled={disabled}
          name={name}
          value={value}
          row={row}
          options={options}
          dataTest={dataTest}
        />
      </FormControl>
    );
  }
}

SelectCell.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  row: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  dataTest: PropTypes.string,
};

SelectCell.defaultProps = {
  disabled: false,
  classes: {},
  name: '',
  value: '',
  row: {},
  options: [],
  dataTest: '',
};

/** @deprecated */
export default withStyles(styles)(SelectCell);
