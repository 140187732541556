import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const Unauthorized = React.lazy(
  () => import('./containers/ErrorPages/Unauthorized')
);
const NotFound = React.lazy(() => import('./containers/ErrorPages/NotFound'));
const InternalError = React.lazy(
  () => import('./containers/ErrorPages/InternalError')
);
const Home = React.lazy(() => import('./containers/Home/Home'));

export const commonRoutes: RouteObject[] = [
  { path: '*', element: <Navigate to="/404" /> },
  { path: '/', element: <Navigate to="/home" /> },
  {
    path: '/home',
    element: (
      <React.Suspense fallback={undefined}>
        <Home />
      </React.Suspense>
    ),
  },
  {
    path: '/404',
    element: (
      <React.Suspense fallback={undefined}>
        <NotFound />
      </React.Suspense>
    ),
  },
  {
    path: '/403',
    element: (
      <React.Suspense fallback={undefined}>
        <Unauthorized />
      </React.Suspense>
    ),
  },
  {
    path: '/500',
    element: (
      <React.Suspense fallback={undefined}>
        <InternalError />
      </React.Suspense>
    ),
  },
];
