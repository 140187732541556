import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';

const UTCTooltip = ({ date }: { date: string | number | Date }) => {
  const parsedDate = moment(date);

  if (!parsedDate.isValid()) {
    return null;
  }

  return (
    <Tooltip
      title={`UTC ${moment(date).format('HH:mm:ss MM/DD/YYYY').toString()}`}
    >
      {moment.utc(date).local().format('HH:mm:ss MM/DD/YYYY')}
    </Tooltip>
  );
};

export default UTCTooltip;
