import fetch from 'isomorphic-fetch';
import { handleAsyncError, parseJSON } from './utils';

/** @deprecated */
export default function serviceMiddleware() {
  return (next) => (action) => {
    const { service, types, notifications, ...rest } = action;
    let NOTIFICATION_SUCCESS;
    let NOTIFICATION_FAILURE;

    if (!service || !service.name) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;

    if (notifications) {
      [NOTIFICATION_SUCCESS, NOTIFICATION_FAILURE] = notifications;
    }

    if (REQUEST && typeof REQUEST === 'string') {
      next({ ...rest, type: REQUEST });
    } else if (REQUEST && typeof REQUEST === 'function') {
      next(REQUEST(rest));
    }

    const { call, body, name, endpoint } = service;
    const bodyRequest = { call, ...body };
    const formattedEndpoint = endpoint ? `/${endpoint}` : '';

    return fetch(`/service/${name}${formattedEndpoint}`, {
      body: JSON.stringify(bodyRequest),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(handleAsyncError)
      .then(parseJSON)
      .then((payload) => {
        if (notifications && NOTIFICATION_SUCCESS) {
          next({ ...rest, payload, type: NOTIFICATION_SUCCESS });
        }

        if (SUCCESS && typeof SUCCESS === 'string') {
          next({ ...rest, payload, type: SUCCESS });
        } else if (SUCCESS && typeof SUCCESS === 'function') {
          next(SUCCESS(payload, rest));
        }

        return Promise.resolve(payload);
      })
      .catch((error) => {
        if (notifications && NOTIFICATION_FAILURE) {
          next({ ...rest, error, type: NOTIFICATION_FAILURE });
        }

        if (FAILURE) {
          next({ ...rest, error, type: FAILURE });
        }

        return Promise.reject(error);
      });
  };
}
