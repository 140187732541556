import PropTypes from 'prop-types';
import React from 'react';

import CaretDownIcon from '@cbinsights/fds/lib/icons/react/CaretDownIcon';
import CaretUpIcon from '@cbinsights/fds/lib/icons/react/CaretUpIcon';

const SortToggleButton = ({ direction, onClick, text }) => {
  const CaretIcon = direction === 'desc' ? CaretDownIcon : CaretUpIcon;
  return (
    <button
      data-test="sortButton"
      className="border border-solid border-gray-500 bg-white text-black rounded-lg text-sm px-2"
      onClick={onClick}
    >
      {text ? (
        <span>
          {text} <CaretIcon />
        </span>
      ) : (
        <CaretIcon />
      )}
    </button>
  );
};

SortToggleButton.propTypes = {
  direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

SortToggleButton.defaultProps = {
  text: '',
};

export default SortToggleButton;
