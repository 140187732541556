import { getAllRoundValuesRequest } from '../utils/api-request-helper';

export const GET_ALL_FUNDING_ROUND =
  'client/modules/cbi-entity/redux/actions/funding-round/GET_ALL_FUNDING_ROUND';

export function getAllFundingRoundHelper(roundInfo) {
  return { type: GET_ALL_FUNDING_ROUND, roundInfo };
}

export function getAllFundingRound() {
  return (dispatch, getState) => {
    const {fundingRound} = getState().cbiEntity;

    if (fundingRound && fundingRound.length) {
      return new Promise(() => {});
    }
    return dispatch(getAllRoundValuesRequest()).then((data) => {
      dispatch(getAllFundingRoundHelper(data.roundInfo));
    });
  };
}
