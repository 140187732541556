import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { LogoInfo, DraftLogoInfo } from '@cbinsights/logoservice/logoservice';
import { Button, Modal } from 'antd';
import { useUser } from 'client/utils/user-event/hooks/useIdUser';
import React, { useState } from 'react';
import { useDeleteEntityDraftLogoMutation } from '../../services/general-info/hooks/useDeleteEntityDraftLogoMutation';
import { useUpdateDefaultLogoMutation } from '../../services/general-info/hooks/useUpdDefaultLogoMutation';

type CompanyLogoVisorProps = {
  idEntity: string | number;
  logoInfo: LogoInfo | DraftLogoInfo;
  onDeleteLogoSuccessfully: () => void;
};

export const CompanyLogoVisor = ({
  idEntity,
  logoInfo,
  onDeleteLogoSuccessfully,
}: CompanyLogoVisorProps) => {
  const { idUser } = useUser();
  const updateLogo = useUpdateDefaultLogoMutation();
  const deleteDraftLogo = useDeleteEntityDraftLogoMutation();
  const [showDeleteLogo, setShowConfirmDeleteLogo] = useState(false);
  const toogleDeleteLogoModal = () => setShowConfirmDeleteLogo(!showDeleteLogo);
  let entityLogoInfo: LogoInfo;
  if (!('idEntityDraftLogo' in logoInfo)) {
    entityLogoInfo = logoInfo;
  }

  const handleDeleteLogo = () => {
    toogleDeleteLogoModal();
    if (typeof idEntity === 'string') {
      deleteDraftLogo.mutate(
        {
          idEntityDraft: idEntity,
        },
        {
          onSuccess() {
            toogleDeleteLogoModal();
            onDeleteLogoSuccessfully();
          },
        }
      );
    } else {
      updateLogo.mutate(
        {
          idUser,
          idLogo: entityLogoInfo?.idLogo,
          idEntity,
          entityType: 'entity',
          unsetDefaultLogo: true,
        },
        {
          onSuccess() {
            toogleDeleteLogoModal();
            onDeleteLogoSuccessfully();
          },
        }
      );
    }
  };

  return (
    <div className="flex justify-center ">
      <div
        style={{
          width: '135px',
        }}
      >
        <img
          style={{
            height: '32px',
          }}
          id="entity-image"
          src={logoInfo?.url}
          alt="entity-logo"
          className="mr-auto ml-auto"
        />
      </div>
      {!logoInfo?.noLogoFound && (
        <Button type="default" className="" onClick={toogleDeleteLogoModal}>
          <DeleteOutlined className="text-red-500" />
        </Button>
      )}
      <Modal
        title="Remove Logo"
        visible={showDeleteLogo}
        data-test="remove-logo"
        onOk={handleDeleteLogo}
        onCancel={toogleDeleteLogoModal}
      >
        <p>Are you sure you would like to remove the logo from this profile?</p>
      </Modal>
    </div>
  );
};
