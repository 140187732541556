import {
  GetTeamRequest,
  GetTeamResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getTeam = (
  req: Partial<GetTeamRequest>
): Promise<GetTeamResponse> =>
  request({
    url: '/service/adminservice/GetTeam',
    body: req,
  }).then((r) => r.body);
