import { Col, Row } from 'antd';
import React, { ReactElement, ReactNode } from 'react';

import Layout from 'antd/lib/layout/layout';
import { CBIEntityTabs } from './CBIEntityTabs';
import { CbiEntityTabPathType } from '../../permissions/EntityAdmin';

export type TabBarAdditionalContentProps = {
  tabPath: CbiEntityTabPathType;
  AdditionalContent: ReactNode;
};

type SectionLayoutProps = {
  CallToActions?: ReactNode;
  FormContent: ReactNode;
  FormHeader: ReactNode;
  visibleTabs: boolean;
  onLeavePage: (args: { redirect: () => void }) => void;
  TabBarAdditionalContent: TabBarAdditionalContentProps[];
};

export const SectionLayout = ({
  CallToActions,
  FormHeader,
  FormContent,
  onLeavePage,
  visibleTabs,
  TabBarAdditionalContent,
}: SectionLayoutProps): ReactElement => {
  return (
    <Layout className="bg-white px-[30px] pt-[32px] pb-[26px] rounded-sm">
      <Row gutter={16}>
        <Col span={12}>{FormHeader}</Col>
        <Col span={12}>
          <div className="flex justify-end">{CallToActions}</div>
        </Col>
      </Row>
      {visibleTabs && (
        <Row>
          <Col span={24}>
            <CBIEntityTabs
              onLeavePage={onLeavePage}
              TabContent={FormContent}
              TabBarAdditionalContent={TabBarAdditionalContent}
            />
          </Col>
        </Row>
      )}
      {
        // It renders the form content directly when there are no tabs
        !visibleTabs && FormContent
      }
    </Layout>
  );
};
