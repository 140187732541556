import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { FormControl } from 'client/component-library/Form';
import Input from 'client/component-library/Input';
import { withStyles } from 'client/component-library/styles';

const styles = () => ({
  input: {
    fontSize: '0.9225rem',
    color: '#404040',
  },
});

/** @deprecated InputCell is a React component that renders an input field. */
class InputCell extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const {
      id,
      name,
      value,
      classes,
      multiline,
      placeholder,
      width,
      startAdornment,
      disabled,
      dataTest,
      customStyles,
      onChange,
      fullWidth,
    } = this.props;

    return (
      <FormControl fullWidth={fullWidth}>
        <Input
          id={id}
          name={name}
          value={value}
          className={classes.input}
          multiline={multiline}
          placeholder={placeholder}
          style={{ width, ...customStyles }}
          startAdornment={startAdornment}
          disabled={disabled}
          data-test={dataTest}
          onChange={onChange}
        />
      </FormControl>
    );
  }
}

InputCell.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customStyles: PropTypes.object,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  startAdornment: PropTypes.node,
  dataTest: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

InputCell.defaultProps = {
  disabled: false,
  name: '',
  value: '',
  customStyles: {},
  multiline: false,
  placeholder: '',
  width: 'inherit',
  startAdornment: null,
  dataTest: '',
  onChange: () => {},
  fullWidth: false,
};

/** @deprecated */
export default withStyles(styles)(InputCell);
