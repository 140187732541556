import { useMutation, useQueryClient } from 'react-query';
import { User } from '@cbinsights/userv2service/userv2service';
import { GetUserResponse } from '@cbinsights/adminservice/adminservice';
import { updateUser } from '../services/api/UpdateUser';

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: updateUser,
    onMutate: async (newUserInformation: { user: GetUserResponse['user'] }) => {
      const params = { id_user: newUserInformation.user.id_user };

      await queryClient.cancelQueries({
        queryKey: ['GetUser', newUserInformation.user.id_user],
      });

      const prevState: GetUserResponse['user'] = queryClient.getQueryData([
        'GetUser',
        params,
      ]);

      queryClient.setQueryData(
        ['GetUser', params],
        (prevGetUser: { user: GetUserResponse['user'] }) => {
          return { user: { ...prevGetUser.user, ...newUserInformation.user } };
        }
      );

      return { prevState };
    },
  });

  const onSubmit = (id: number, data: Partial<User>) => {
    const updateMask = {
      paths: Object.keys(data),
    };
    return new Promise((resolve, reject) => {
      updateMutation.mutate(
        {
          update_mask: updateMask,
          user: <User>{
            id_user: id,
            ...data,
          },
        },
        {
          onSuccess: () => {
            resolve(true);
          },
          onError: (e) => {
            reject(e);
          },
        }
      );
    });
  };

  return {
    onSubmit,
  };
};
