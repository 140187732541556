import React from 'react';
import UserQueueDetails from 'client/modules/news/queue/components/UserQueueDetails';
import { NewsQueueFilter } from 'client/modules/news/queue/components/NewsQueueFilter';
import {
  ClassifierOptions,
  LanguageOptions,
  SourceOptions,
} from '../redux/model/FilterOptionsForNews';
import NewsQueueList from './NewsQueueList';

type ClusterData = {
  clusters: [];
  totalCount: number;
  isLoading: boolean;
};

type NewsQueueProps = {
  clusterData: ClusterData;
  handleSubmit: () => void;
  handleClear: () => void;
  user: object;
  options: {
    languageOptions: LanguageOptions;
    sourceOptions: SourceOptions;
    classifierOptions: ClassifierOptions;
  };
  values: object;
};

export const NewsQueue = ({
  clusterData,
  handleSubmit,
  handleClear,
  user,
  options,
  values,
}: NewsQueueProps) => {
  const { clusters = [], totalCount = 0, isLoading }: ClusterData = clusterData;

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between mb-8 border-b-2 border-gray-300">
        <div className="w-1/4">
          <UserQueueDetails
            user={user}
            remainingCount={totalCount}
            type="Total"
          />
        </div>
        <div className="w-3/4">
          <NewsQueueFilter
            options={options}
            handleSubmit={handleSubmit}
            handleClear={handleClear}
            totalCount={clusters.length}
            formValues={values}
            isLoading={isLoading}
          />
        </div>
      </div>
      <NewsQueueList
        clusters={clusters}
        isLoading={isLoading} // TODO we can remove if we include removeCik in new apis
      />
    </React.Fragment>
  );
};
