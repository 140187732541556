import { combineReducers } from 'redux';
import userSessionDataReducer from './user-session-data';
import allSessionDataReducer from './all-session-data';
import searchBarReducer from './search-bar';
import tabReducer from './tab';

const accountSharingDetection = combineReducers({
  tab: tabReducer,
  userSessionData: userSessionDataReducer,
  allSessionData: allSessionDataReducer,
  searchBar: searchBarReducer,
});

export default accountSharingDetection;
