import { useMutation, useQueryClient } from 'react-query';
import { ToggleTeamAddonRequest } from '@cbinsights/userv2service/userv2service';
import { GetTeamResponse } from '@cbinsights/adminservice/adminservice';
import { toggleTeamAddon } from '../services/api/ToggleTeamAddon';

export const useToggleTeamAddon = () => {
  const queryClient = useQueryClient();

  const toggleTeamAddonQuery = useMutation({
    mutationFn: toggleTeamAddon,
    onMutate: async (newTeamInformation: ToggleTeamAddonRequest) => {
      const params = { id_team: newTeamInformation.id_team };

      await queryClient.cancelQueries({ queryKey: ['GetTeam', params] });

      const prevState = queryClient.getQueryData(['GetTeam', params]);

      queryClient.setQueryData(
        ['GetTeam', params],
        (prevGetTeam: GetTeamResponse) => {
          const result = { ...prevGetTeam };

          if (newTeamInformation.id_feature) {
            result.addons = prevGetTeam.addons.map((addon) => {
              if (addon.id_feature === newTeamInformation.id_feature) {
                return {
                  ...addon,
                  enabled: newTeamInformation.enabled,
                };
              }
              return addon;
            });
          }

          return result;
        }
      );

      return { prevState };
    },
  });

  const submit = ({
    enabled,
    idFeature,
    idTeam,
  }: {
    enabled: boolean;
    idFeature: number;
    idTeam: number;
  }) => {
    return toggleTeamAddonQuery.mutateAsync({
      enabled,
      id_feature: idFeature,
      id_team: idTeam,
    });
  };

  return {
    submit,
    toggleTeamAddonQuery,
  };
};

export default useToggleTeamAddon;
