/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ConfirmationModal } from 'client/component-library/modals/ConfirmationModal';
import { useRequest } from 'client/modules/common/hooks/useRequest/useRequest';
import { SimpleSelect } from 'client/component-library/Select/SimpleSelect';
import { addHttpsToUrl } from 'client/modules/common/utils/formatUrl';

const MAX_DESC_LEN = 280;
const loadingClasses = 'bg-gray-200 shadow rounded py-1 px-2 block';

export function Description({ description = '', className = '' }) {
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => setIsReadMore(!isReadMore);

  const descLen = description.length;

  return (
    <p style={{ maxWidth: 600 }} className={className}>
      {description.substring(0, isReadMore ? descLen : MAX_DESC_LEN)}{' '}
      {description.length > MAX_DESC_LEN && (
        <span className="inline-block">
          <button
            type="button"
            className="text-blue-500"
            onClick={toggleReadMore}
            data-test="description-read-eta"
          >
            {isReadMore ? 'less' : 'more'}
          </button>
        </span>
      )}
    </p>
  );
}

export function EntityInfoHeader(props) {
  const {
    url,
    serviceProviderType = {},
    logo,
    entityName,
    description,
    handleTypeChange = () => {},
    disableType = false,
    loading,
  } = props;

  const [state, setState] = useState({
    providerTypeVal: serviceProviderType,
    updatedValues: null,
  });

  useEffect(() => {
    setState({
      ...state,
      providerTypeVal: serviceProviderType,
    });
  }, [serviceProviderType.value]);

  const { body: serviceProviderTypes = [] } = useRequest(
    '/service/cbientityservice/ListServiceProviderTypes',
    {
      method: 'get',
      cache: true,
      transformer: (body) => {
        return body.serviceProviderTypes
          .map((type) => ({
            label: type.serviceProviderType,
            value: type.idServiceProviderType,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      },
    }
  );

  const openModal = (update) => {
    if (!state.providerTypeVal.value) {
      handleTypeChange(update);
    } else {
      setState({
        ...state,
        updatedValues: update,
      });
    }
  };

  const closeModal = () => {
    setState({
      providerTypeVal: { ...serviceProviderType },
      updatedValues: null,
    });
  };

  const handleSubmit = () => {
    setState({
      ...state,
      updatedValues: null,
    });
    handleTypeChange(state.updatedValues);
  };

  return (
    <div className="entityInfoHeader bg-white flex flex-row p-5 border-t">
      <ConfirmationModal
        width={510}
        isOpen={state.updatedValues}
        title="Are You Sure?"
        body="This Action Can Not Be Undone"
        onCancel={closeModal}
        onSubmit={handleSubmit}
      />
      {loading ? (
        <div className={`${loadingClasses} w-1/12 mr-5 h-20`} />
      ) : (
        <div className="w-1/12 mr-5">
          <img src={logo} alt="company logo" />
        </div>
      )}
      {loading ? (
        <div className={`${loadingClasses} w-11/12 mr-5`}>
          <div className="h-24" />
          <div className="h-24" />
        </div>
      ) : (
        <div className="flex flex-col w-11/12">
          <div className="flex flex-row items-center">
            <h1 className="mr-3 text-xl font-bold">{entityName}</h1>
            <a
              className="text-blue-700"
              href={addHttpsToUrl(url)}
              target="_blank"
              rel="noreferrer"
            >
              {url}
            </a>
          </div>
          <Description description={description} />
          <div className="mt-3" style={{ width: 220 }}>
            <SimpleSelect
              name="provider-type"
              options={serviceProviderTypes}
              label={<lable className="font-bold">SERVICE PROVIDER TYPE</lable>}
              isDisabled={disableType}
              onChange={openModal}
              value={state.providerTypeVal}
            />
          </div>
        </div>
      )}
    </div>
  );
}
