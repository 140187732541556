import { updateUrlParams } from 'client/modules/common/utils/updateUrlParams';
import * as tabName from 'client/modules/markets/utils/tab-names';
import * as actions from '../actions/data-details';

const initialState = {
  [tabName.LIST_OF_MARKETS_DETAILS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
  },
  [tabName.MARKET_SIZING_DETAILS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: { sortField: 'market', sortDirection: 'desc' },
    editingDisabled: false,
  },
  [tabName.RELATED_MARKETS_DETAILS]: {
    pageNumber: 0,
    recordsPerPage: 10,
    totalRecords: 20,
    sortInfo: {}, // { sortField: marketName, sortDirection:desc }
    editingDisabled: false,
    isChildMarket: false,
  },
};

export const getSortOrder = (sortField, sortDirection) => {
  return sortField
    ? { sortField, sortDirection: sortDirection || 'asc' }
    : null;
};

export const updateSortOrder = (sortField, sortDirection) => {
  return { sortField, sortDirection };
};

// TODO -> when update pagnation, need to make an api call, and put the data back into the state!
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INITIALZE_DATA_DETAILS:
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          pageNumber:
            parseInt(action.pageNumber, 10) ||
            initialState[action.currentTab].pageNumber,
          recordsPerPage:
            parseInt(action.recordsPerPage, 10) ||
            initialState[action.currentTab].recordsPerPage,
          sortInfo:
            getSortOrder(action.sortField, action.sortDirection) ||
            initialState[action.currentTab].sortInfo,
        },
      };
    case actions.CLEAR_DATA_DETAILS: {
      updateUrlParams('pageNumber', 0);
      updateUrlParams('sortField', '');
      updateUrlParams('sortDirection', '');
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          sortInfo: {},
          pageNumber: 0,
        },
      };
    }
    case actions.UPDATE_PAGE_NUMBER:
      updateUrlParams('pageNumber', action.pageNumber);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          pageNumber: parseInt(action.pageNumber, 10),
        },
      };
    case actions.UPDATE_RECORDS_PER_PAGE:
      updateUrlParams('recordsPerPage', action.recordsPerPage);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          recordsPerPage: parseInt(action.recordsPerPage, 10),
        },
      };
    case actions.UPDATE_SORT_ORDER:
      updateUrlParams('sortField', action.sortField);
      updateUrlParams('sortDirection', action.sortDirection);
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          sortInfo: updateSortOrder(action.sortField, action.sortDirection),
        },
      };
    case actions.UPDATE_TOTAL_RECORDS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          totalRecords: parseInt(action.totalRecords, 10),
        },
      };
    }
    case actions.DISABLE_EDITING_ROWS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          editingDisabled: true,
        },
      };
    }
    case actions.ENABLE_EDITING_ROWS: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          editingDisabled: false,
        },
      };
    }
    case actions.UPDATE_IS_CHILD_MARKET: {
      return {
        ...state,
        [action.currentTab]: {
          ...state[action.currentTab],
          isChildMarket: action.isChild,
          editingDisabled: action.isChild,
        },
      };
    }
    default:
      return state;
  }
}
