import { useMutation } from 'react-query';
import { RequestOrigin } from '@cbinsights/userv2service/userv2service';
import { setTeamUsersQuotas } from '../services/api/SetTeamUsersQuotas';
import { setUserFeatureCredits } from '../services/api/SetUserFeatureCredits';

export const useUpdateFeatureUsage = () => {
  const updateFeatureUsageMutation = useMutation(setUserFeatureCredits);
  const updateUserQuotaMutation = useMutation(setTeamUsersQuotas);

  const updateFeatureUsage = (
    id: number,
    idFeature: number,
    newUsage: number
  ) => {
    const featureToCreditUsage = {};
    featureToCreditUsage[idFeature] = newUsage;
    return updateFeatureUsageMutation.mutateAsync({
      idUser: id,
      featureToCreditUsage,
      origin: RequestOrigin.ADMIN,
    });
  };

  const updateUserQuota = (
    id: number,
    idTeam: number,
    idFeature: number,
    newQuota: number
  ) => {
    const teamUserQuotas = [];
    teamUserQuotas.push({
      id_user: id,
      id_team: idTeam,
      id_feature: idFeature,
      quota: Number(newQuota),
    });

    return updateUserQuotaMutation.mutateAsync({
      team_user_quotas: teamUserQuotas,
    });
  };

  return {
    updateFeatureUsage,
    updateUserQuota,
  };
};
