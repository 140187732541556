import FDS from '@cbinsights/fds/lib/dictionary/js/styleConstants';

const MuiButton = {
  root: {
    lineHeight: 'normal',
    backgroundColor: 'normal',
  },
};

const MuiIconButton = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const COLOR = {
  navy: {
    50: '#73aefa',
    100: '#3f93f9',
    200: '#0a79f9',
    300: '#0362cc',
    400: '#024b99',
    500: '#003366',
    600: '#002d57',
    700: '#002747',
    800: '#002038',
    900: '#001829',
    A100: '#2190ff',
    A200: '#00458a',
    A400: '#001e3c',
    A700: '#001122',
  },
  blue: {
    50: '#80cafa',
    100: '#53bafa',
    200: '#26abfa',
    300: '#049bef',
    400: '#0181c4',
    500: '#006699',
    600: '#005b85',
    700: '#004f70',
    800: '#00435c',
    900: '#003647',
    A100: '#3cbeff',
    A200: '#007bb9',
    A400: '#00496d',
    A700: '#00354f',
  },
  red: {
    50: '#fae1e1',
    100: '#f5bfc0',
    200: '#f09c9f',
    300: '#ec797e',
    400: '#e8565e',
    500: '#E5323E',
    600: '#e51e2f',
    700: '#d7172c',
    800: '#c7132a',
    900: '#b60f28',
    A100: '#f9adb2',
    A200: '#f93a47',
    A400: '#e90111',
    A700: '#c9000d',
  },
  orange: {
    50: '#fddfd2',
    100: '#fdc9b3',
    200: '#fdb294',
    300: '#fd9a74',
    400: '#fe8054',
    500: '#FF6633',
    600: '#ff420a',
    700: '#e02f00',
    800: '#b82200',
    900: '#8f1800',
    A100: '#ffbea9',
    A200: '#ff784b',
    A400: '#ff4304',
    A700: '#de3800',
  },
};

// FDS have removed this
const DEPRECATED_CONSTS = {
  palette: {
    type: 'light',
    primary: COLOR.blue,
    secondary: COLOR.navy,
    accent: COLOR.orange,
    error: COLOR.red,
    divider: FDS.FONT_COLOR_HINT,
    text: {
      primary: FDS.FONT_COLOR_PRIMARY,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: FDS.FONT_FAMILY_DEFAULT,
  },
  overrides: {
    MuiButton,
    MuiIconButton,
  },
  zIndex: {
    modal: 9999,
  },
};

export const adminTheme = {
  overrides: DEPRECATED_CONSTS.overrides,
  typography: DEPRECATED_CONSTS.typography,
  palette: DEPRECATED_CONSTS.palette,
};
