import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'client/component-library/icons/Icon';
import styles from './styles/display-news-content.css';
import { calculateIndexes } from './utils/display-news-content-helpers';

const formatContent = (
  key,
  content,
  previousEnd,
  currentStart,
  currentEnd,
  nextStart,
  hightlightColor
) => {
  return (
    <span key={key}>
      <span>{content.slice(previousEnd, currentStart)}</span>
      <span style={{ background: hightlightColor }}>
        {content.slice(currentStart, currentEnd)}
      </span>
      {!nextStart && <span>{content.slice(currentEnd)}</span>}
    </span>
  );
};

const renderTextWithHighlighting = (allIndexes, content, hightlightColor) => {
  if (!allIndexes || !allIndexes.length) {
    return (
      <span>
        <span>{content}</span>
      </span>
    );
  }
  const allNodes = [];
  let previousEnd = 0;
  for (let i = 0; i < allIndexes.length; i++) {
    const nextStart =
      i === allIndexes.length - 1 ? false : allIndexes[i + 1].indexOfStart;
    const indexes = allIndexes[i];
    const node = formatContent(
      i.toString(),
      content,
      previousEnd,
      indexes.indexOfStart,
      indexes.indexOfEnd,
      nextStart,
      hightlightColor
    );
    allNodes.push(node);
    previousEnd = indexes.indexOfEnd;
  }
  return <span>{allNodes}</span>;
};

/**
 * @deprecated
 *  It takes in a string, and an array of indexes, and returns a React component with the string split
 * into spans, with the spans at the indexes highlighted
 * @param props - {
 * @returns A function that returns a div.
 */
const DisplayNewsContent = (props) => {
  const {
    selectedSentences,
    content,
    url,
    title,
    hightlightColor,
    onHide,
    customWrapperClass,
  } = props;
  if (!content) {
    return <div className={styles.noContent} />;
  }

  const newTitle = JSON.stringify(title).replace(/-----/g, '').slice(1, -1);
  const allTitleIndexes = calculateIndexes(newTitle, selectedSentences);

  const newContent = JSON.stringify(content)
    .replace(/\\n/g, '\n\n')
    .slice(1, -1);
  const allContentIndexes = calculateIndexes(newContent, selectedSentences);
  return (
    <div className={customWrapperClass || styles.displayNewsContentWrapper}>
      <div className={styles.relative}>
        <a
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          className={`${styles.inline} ${styles.blue}`}
        >
          {renderTextWithHighlighting(
            allTitleIndexes,
            newTitle,
            hightlightColor
          )}
        </a>
      </div>

      {renderTextWithHighlighting(
        allContentIndexes,
        newContent,
        hightlightColor
      )}

      <div>
        <a href="hideIcon" onClick={onHide} className={styles.inlineHref}>
          <Icon
            name="unfold_less"
            family="material"
            className={styles.closeArticle}
          />
          Close article
        </a>
      </div>
    </div>
  );
};

DisplayNewsContent.propTypes = {
  customWrapperClass: PropTypes.string,
  selectedSentences: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.string.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  hightlightColor: PropTypes.string,
  onHide: PropTypes.func,
};

DisplayNewsContent.defaultProps = {
  customWrapperClass: '',
  selectedSentences: [],
  url: '',
  title: '',
  hightlightColor: 'yellow',
  onHide: () => {},
};

export default DisplayNewsContent;
