import { recordEvent } from '../api/userEventService';
import { AFSUserEvent } from './event-types';
import { useUser } from './useIdUser';

export const useUserEvents = (): {
  recordUserEvent: (userEvent: AFSUserEvent) => void;
} => {
  const { idUser } = useUser();

  const recordUserEvent = ({ type, details }: AFSUserEvent) => {
    recordEvent({
      eventKey: type,
      browser: navigator.userAgent,
      device: navigator.platform,
      url: window.location.pathname,
      referrerUrl: document.referrer,
      idUser,
      details: JSON.stringify(details),
    });
  };

  return { recordUserEvent };
};
