import { request } from 'client/modules/common/utils/request';
import { logInfo } from 'client/modules/common/utils/logInfo';
import {
  setToastError,
  setToastSuccess,
} from 'client/modules/third-party-admin/profile-queue/utils';
import { parseClusterResponse } from './GetNewsClusters';

export function removeArticleFromCluster({ id, clusterType }) {
  return request({
    url: '/service/newsqueueservice/CreateNewsQueueClusters',
    body: { idNews: [id], itemType: clusterType },
  })
    .then(({ body: { newsQueueCluster } }) => {
      setToastSuccess('Article Successfully Removed From Cluster');
      return parseClusterResponse([newsQueueCluster]);
    })
    .catch((error) => {
      setToastError('Error Removing Article From Cluster');
      logInfo({
        error,
        message: 'News Queue: Failed To Removing Article',
        method: 'removeArticleFromCluster',
      });
      throw error;
    });
}
